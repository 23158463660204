import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'

import {
  CUSTOM_TOKEN_LOCAL_STORAGE_KEY,
  EMAIL_LOGIN_INSTRUCTION_LINK,
  PlatformOS,
} from 'root-constants'

export const getDeepLink = (
  customToken: string | null,
  downloadLinks: {
    ios: string
    android: string
  } | null,
): string => {
  let autoLoginLink = EMAIL_LOGIN_INSTRUCTION_LINK

  if (downloadLinks) {
    const isAndroid = getMobileOperatingSystem() === PlatformOS.ANDROID
    autoLoginLink =
      downloadLinks[isAndroid ? 'android' : 'ios'] ||
      EMAIL_LOGIN_INSTRUCTION_LINK
  }

  return autoLoginLink.replace(
    CUSTOM_TOKEN_LOCAL_STORAGE_KEY,
    customToken || '',
  )
}
