import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { useGetPriceWithCurrencyFormatting } from 'modules/purchase/hooks/useGetPriceWithCurrencyFormatting'
import { usePriceWithVatAdjustment } from 'modules/purchase/hooks/usePriceWithVatAdjustment'

import { ISubscription } from 'models/subscriptions.model'

import { StyledIntroOfferSelectSubscriptionItemVertical as S } from './IntroOfferSelectSubscriptionItemVertical.styles'

type TProps = {
  subscription: ISubscription
  isSelected: boolean
}
export const IntroOfferSelectSubscriptionItemVertical: React.FC<TProps> = ({
  subscription: { id, isDefault, mainPrices, trialPrices, currency },
  isSelected,
}) => {
  const { t } = useTranslation()

  const { getPriceWithCurrencyFormatting } = useGetPriceWithCurrencyFormatting()

  const { getPriceWithVatAdjustment } = usePriceWithVatAdjustment()

  return (
    <S.Wrapper isSelected={isSelected} key={id}>
      <S.Content isSelected={isSelected}>
        <S.Caption>
          {isDefault
            ? t('purchaseRefund.bestSeller')
            : `${t('purchaseRefund.discount')} 35%`}
        </S.Caption>
        <S.Top>
          <S.PlanPeriod isSelected={isSelected}>
            <span>{mainPrices.periodQuantity}</span>
            <br />
            {t('purchaseIntroOffer.monthlyPeriodV2')}
          </S.PlanPeriod>

          <S.CustomPrice isSelected={isSelected}>
            {t('purchaseIntroOffer.subscription.price', {
              currency: CURRENCY_SYMBOLS[currency],
              price: getPriceWithVatAdjustment(trialPrices.daily),
            })}
          </S.CustomPrice>
          <S.Daily>
            {t('purchase1.subscription.per', { context: 'day' })}
            {t`purchase1.subscription.day`}
          </S.Daily>
        </S.Top>

        <S.Bottom>
          <S.CurrentPrice>
            <Trans
              i18nKey="purchaseIntroOffer.subscription.price"
              values={{
                price: getPriceWithCurrencyFormatting(
                  getPriceWithVatAdjustment(trialPrices.fullPrice),
                ),
                currency: CURRENCY_SYMBOLS[currency],
              }}
            />
            <p>
              {t('purchase1.subscription.per')}
              {t('purchase1.subscription.month_one')}
            </p>
          </S.CurrentPrice>
        </S.Bottom>
      </S.Content>
    </S.Wrapper>
  )
}
