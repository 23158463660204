import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, buttonTheme } from 'storybook-ui'

import { Container } from 'components/Container'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { selectAnswers } from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import doubleChinAppearance from 'assets/images/double-chin-appearance.png'
import harvardMedicalSchool from 'assets/images/harvard-medical-school-logo-v2.png'
import northwesternUniversity from 'assets/images/northwestern-university-logo-v2.png'

import { SandyBackgroundGlobalStyles } from 'common-styles'
import { PageId } from 'page-constants'
import { I18N_CONTEXT_COHORT_MAP, UserGoal } from 'root-constants'

import { StyledReduceYourDoubleChin as S } from './ReduceYourDoubleChin.styles'
import { CHARTS_MAP, QUESTION, QUESTIONS_MAP, TITLES_MAP } from './constants'

export const ReduceYourDoubleChin: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)

  const cohortToUse = useCohortToUse()
  const { isLuvlyChinCohort, isLuvlyGeneralCohort } =
    useActiveCohortIdentifier()
  const userGoal = userAnswers?.[PageId.INTRO] || UserGoal.WRINKLE_FREE_SKIN

  const handleContinue = useNextStep({
    pageId,
    question: isLuvlyChinCohort ? QUESTION : QUESTIONS_MAP[userGoal],
    nextPagePath,
  })

  const handleNextClick = () => handleContinue('')

  const getTitleKey = () => {
    if (isLuvlyChinCohort) return 'onboarding.reduceYourDoubleChin.title'

    return TITLES_MAP[userGoal]
  }

  const renderChart = () => {
    if (isLuvlyGeneralCohort) {
      return (
        <img src={CHARTS_MAP[userGoal || UserGoal.REDUCE_FACE_FAT]} alt="" />
      )
    }
    return <img src={doubleChinAppearance} alt="Double chin appearance" />
  }

  return (
    <S.Root>
      <Container>
        <S.Title>
          <Trans i18nKey={getTitleKey()} components={[<strong />]} />
        </S.Title>
        <S.Chart>{renderChart()}</S.Chart>
        <S.Card>
          <S.ImageContainer>
            <img src={harvardMedicalSchool} alt="" />
          </S.ImageContainer>
          <p>
            <Trans
              i18nKey="onboarding.reverseYourWrinkles.option1"
              components={[<strong />]}
              context={I18N_CONTEXT_COHORT_MAP[cohortToUse]}
            />
          </p>
        </S.Card>
        <S.Card>
          <S.ImageContainer>
            <img src={northwesternUniversity} alt="" />
          </S.ImageContainer>
          <p>
            <Trans
              i18nKey="onboarding.reverseYourWrinkles.option2"
              components={[<strong />]}
              context={I18N_CONTEXT_COHORT_MAP[cohortToUse]}
            />
          </p>
        </S.Card>
      </Container>

      <StickyButtonContainer centerMode>
        <Button onClick={handleNextClick} theme={buttonTheme.LUVLY_SECONDARY}>
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
      <SandyBackgroundGlobalStyles />
    </S.Root>
  )
}
