export const QUESTION =
  'As your sleep habits can affect the appearance of a double chin, how do you usually sleep?'

const OPTION_VALUES = {
  ON_STOMACH: 'on_stomach',
  ON_SIDE: 'on_side',
  ON_BACK: 'on_back',
  OTHER: 'other',
}

export const SLEEP_HABITS_ANSWERS = [
  {
    value: OPTION_VALUES.ON_STOMACH,
    text: 'onboarding.sleepHabits.onStomach',
  },
  {
    value: OPTION_VALUES.ON_SIDE,
    text: 'onboarding.sleepHabits.onSide',
  },
  {
    value: OPTION_VALUES.ON_BACK,
    text: 'onboarding.sleepHabits.onBack',
  },
  {
    value: OPTION_VALUES.OTHER,
    text: 'onboarding.sleepHabits.otherPosition',
  },
]
