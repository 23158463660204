import React, { useEffect, useRef, useState } from 'react'

import { StyledStickyButtonContainer as S } from './StickyButtonContainer.styles'

export type TStickyButtonContainer = {
  customBackground?: string
  centerMode?: boolean
  children: React.ReactNode
}

export const StickyButtonContainer: React.FC<TStickyButtonContainer> = ({
  children,
  customBackground,
  centerMode,
}) => {
  const [isStuck, setIsStuck] = useState(false)
  const buttonRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (isStuck) return undefined
    const handleScroll = () => {
      if (!buttonRef.current) return
      const rect = buttonRef.current.getBoundingClientRect()
      setIsStuck(rect.bottom >= window.innerHeight)
    }
    handleScroll()

    document.body.addEventListener('scroll', handleScroll)

    return () => {
      document.body.removeEventListener('scroll', handleScroll)
    }
  }, [isStuck])

  return (
    <S.Root
      isStuck={isStuck}
      ref={buttonRef}
      customBackground={customBackground}
      centerMode={centerMode}
    >
      {children}
    </S.Root>
  )
}
