import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'
import { Option } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useIsNavigationOn } from 'hooks/useIsNavigationOn'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { goBack } from 'browser-history'
import { I18N_CONTEXT_COHORT_MAP, OptionType } from 'root-constants'

import { StyledGender as S } from './Gender.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const Gender: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const [answer, setAnswer] = useState<string>('')
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const includeNavigation = useIsNavigationOn()
  const cohortToUse = useCohortToUse()
  const { isSeniorCohort } = useActiveCohortIdentifier()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => {
    eventLogger.logUserGenderSelected(answer)
    handleContinue(answer)
  }

  const handleAnswerChange = (value: string) => {
    if (includeNavigation) {
      setAnswer(value)
      return
    }

    eventLogger.logUserGenderSelected(value)
    handleChange(value, handleContinue)
    setIsAnswersDisabled(true)
  }

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: handleAnswerChange,
  }

  return (
    <div>
      <Container>
        <S.TitleContainer>
          <PageTitle>
            <Trans
              i18nKey="onboarding.gender.question"
              context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
            />
          </PageTitle>
        </S.TitleContainer>
        <Option
          {...optionProps}
          disabled={isAnswersDisabled}
          value={OPTION_VALUES.FEMALE}
        >
          <AnswerButton>{t`onboarding.gender.female`}</AnswerButton>
        </Option>
        <Option
          {...optionProps}
          disabled={isAnswersDisabled}
          value={OPTION_VALUES.MALE}
        >
          <AnswerButton>{t`onboarding.gender.male`}</AnswerButton>
        </Option>
        {isSeniorCohort && (
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.OTHER}
          >
            <AnswerButton>{t`onboarding.gender.other`}</AnswerButton>
          </Option>
        )}
      </Container>
      {includeNavigation && (
        <NavigationButtons
          onBackClick={goBack}
          onNextClick={handleNextClick}
          disabled={!answer}
        />
      )}
    </div>
  )
}
