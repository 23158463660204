import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { AnswerButton, AnswerButtonHeight } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'
import { Option } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { setAnswersAction } from 'root-redux/actions/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useIsNavigationOn } from 'hooks/useIsNavigationOn'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { goBack } from 'browser-history'
import { PageId } from 'page-constants'
import { I18N_CONTEXT_COHORT_MAP, OptionType } from 'root-constants'

import { StyledSkinType as S } from './SkinType.styles'
import {
  OPTION_VALUES,
  QUESTION,
  SKIN_TYPE_DEFAULT_OPTIONS,
  SKIN_TYPE_OPTIONS_MAP,
} from './constants'

export const SkinType: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [answer, setAnswer] = useState<string>('')
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const includeNavigation = useIsNavigationOn()
  const { isCosmeticCohort } = useActiveCohortIdentifier()

  const cohortToUse = useCohortToUse()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleAnswerChange = (value: string) => {
    const payload = {
      answers: '',
      pageId: PageId.SKIN_TYPE_EXPLANATION,
    }

    if (includeNavigation) {
      setAnswer(value)
      dispatch(setAnswersAction(payload))
      return
    }

    handleChange(value, handleContinue)
    setIsAnswersDisabled(true)
    dispatch(setAnswersAction(payload))
  }

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: handleAnswerChange,
  }

  const handleNextClick = () => handleContinue(answer)

  const handleSkipLinkClick = () => handleContinue(OPTION_VALUES.DONT_KNOW)

  const skinTypeOptions: {
    value: OPTION_VALUES
    text: string
  }[] = SKIN_TYPE_OPTIONS_MAP[cohortToUse] || SKIN_TYPE_DEFAULT_OPTIONS

  return (
    <div>
      <Container>
        <S.TitleContainer>
          <PageTitle>
            <Trans
              i18nKey="onboarding.skinType.question"
              context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
            />
          </PageTitle>
        </S.TitleContainer>
        <S.OptionsContainer>
          {skinTypeOptions.map(({ value, text }) => (
            <Option
              key={value}
              {...optionProps}
              disabled={isAnswersDisabled}
              value={value}
            >
              <AnswerButton
                {...(isCosmeticCohort && {
                  height: AnswerButtonHeight.M,
                })}
              >
                <Trans i18nKey={text} components={[<strong />, <br />]} />
              </AnswerButton>
            </Option>
          ))}
        </S.OptionsContainer>
        {!isCosmeticCohort && (
          <S.LinkContainer>
            <S.Link onClick={handleSkipLinkClick}>
              {t`onboarding.skinType.dontKnow`}
            </S.Link>
          </S.LinkContainer>
        )}
      </Container>
      {includeNavigation && (
        <NavigationButtons
          onBackClick={goBack}
          onNextClick={handleNextClick}
          disabled={!answer}
        />
      )}
    </div>
  )
}
