import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import { IntroHeader } from 'components/IntroHeader'
import { IntroHeaderColor } from 'components/IntroHeader/constants'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { selectLanguage } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'
import { useSendIntroPageCompletedAnalytics } from 'hooks/useSendIntroPageCompletedAnalytics'
import { useStartSession } from 'hooks/useStartSession'
import { useTitleFromUrl } from 'hooks/useTitleFromUrl'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import faceAnimation from 'assets/animation/faceAnimation.json'

import { PageId } from 'page-constants'
import { Locale, OptionType } from 'root-constants'

import { StyledAgeAsIntroWithAnimation as S } from './AgeAsIntroWithAnimation.styles'
import { AGE_OPTIONS, AGE_OPTION_VALUES_MAP, QUESTION } from './constants'

export const AgeAsIntroWithAnimation: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)
  const animationContainerRef = useRef<HTMLDivElement | null>(null)

  const { sendIntroPageCompletedAnalytics } =
    useSendIntroPageCompletedAnalytics()

  useStartSession()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const { headline } = useTitleFromUrl()
  const screenWidth = window.innerWidth

  useEffect(() => {
    if (animationContainerRef && animationContainerRef.current) {
      lottie.loadAnimation({
        container: animationContainerRef.current,
        animationData: faceAnimation,
        loop: true,
      })
    }

    return () => lottie.destroy()
  }, [screenWidth])

  const handleContinue = useNextStep({
    pageId: PageId.AGE,
    question: QUESTION,
    nextPagePath,
  })

  const handleAnswerChange = (value: string) => {
    eventLogger.logUserAgeSelected(AGE_OPTION_VALUES_MAP[value])
    handleChange(value, handleContinue)
    setIsAnswersDisabled(true)
  }

  const optionProps = {
    name: PageId.AGE,
    type: OptionType.RADIO,
    disabled: isAnswersDisabled,
    onChange: (value: string) => {
      sendIntroPageCompletedAnalytics()

      eventLogger.logFirstPageCompleted({
        question: QUESTION,
        answers: value,
      })

      handleAnswerChange(value)
    },
  }

  return (
    <S.Root>
      <IntroHeader
        colorTheme={IntroHeaderColor.DARK}
        isSupportActionVisible={false}
        centeredLogo
      />
      <S.AnimationContainer ref={animationContainerRef} />
      <S.Container>
        <div>
          <S.Title
            isFontSizeAdjustable={
              language !== Locale.ENGLISH && language !== Locale.JAPANESE
            }
          >
            {headline || t('onboarding.introSaleAgeAsIntro.title')}
          </S.Title>
          <S.Subtitle>
            {t('onboarding.introSaleAgeAsIntro.description')}
          </S.Subtitle>
          <S.Actions>
            {AGE_OPTIONS.map(({ value, text }) => (
              <S.StyledOption {...optionProps} value={value} key={value}>
                <S.Action>
                  <S.AnswerWrapper>
                    <S.AnswerValue>{text}</S.AnswerValue>
                    <S.ChevronRight />
                  </S.AnswerWrapper>
                </S.Action>
              </S.StyledOption>
            ))}
          </S.Actions>
        </div>
        <S.Terms>
          <p>{t`onboarding.intro.termsTitle`}</p>
          <TermsOfUseLink />
          <S.Separator />
          <PrivacyPolicyLink />
        </S.Terms>
      </S.Container>
    </S.Root>
  )
}
