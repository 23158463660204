import firstExpertPicture from 'assets/images/face-yoga-expert-1.png'
import secondExpertPicture from 'assets/images/face-yoga-expert-2-rect.png'
import thirdExpertPicture from 'assets/images/face-yoga-expert-3-rect.png'

export const QUESTION =
  'Luvly programs are developed by professional cosmetologists and face yoga experts'

export const EXPERTS = [
  {
    image: firstExpertPicture,
    name: 'onboarding.faceYogaExperts.expertName1',
    description: 'onboarding.faceYogaExperts.expertDescription1',
  },
  {
    image: secondExpertPicture,
    name: 'onboarding.faceYogaExperts.expertName2',
    description: 'onboarding.faceYogaExperts.expertDescription2',
  },
  {
    image: thirdExpertPicture,
    name: 'onboarding.faceYogaExperts.expertName3',
    description: 'onboarding.faceYogaExperts.expertDescription3',
  },
]
