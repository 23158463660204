import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

export type TWrapperProps = {
  marginBottom: number
}

export type TInputProps = {
  inputHeight: number
}

export const StyledInputWithFloatPlaceholder = {
  Wrapper: styled.div<TWrapperProps>`
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};

    &::after {
      display: block;
      position: absolute;
      left: 16px;
      color: ${Color.ERROR};
      font-size: 13px;
      line-height: 20px;
    }

    &[data-valid='false']::after {
      content: attr(data-validation-text);
    }
  `,
  Input: styled.input<TInputProps>`
    display: block;
    width: 100%;
    height: ${({ inputHeight }) => `${inputHeight}px`};
    padding: 32px 52px 8px 16px;
    color: ${Color.DARK};
    font-size: 15px;
    font-weight: ${({ theme }) => theme.common.semiBoldFontWeight};
    line-height: 1;
    border-radius: 10px;
    border: 1px solid ${Color.LIGHT};
    background-color: ${Color.WHITE};
    outline: none;
    transition: border 0.2s ease-out;
    &:valid,
    &:focus {
      & + span {
        top: 15px;
        color: ${Color.DARK};
        font-size: 13px;
        line-height: 1;
      }
    }
  `,
  Label: styled.span`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    color: ${Color.LIGHT};
    font-size: 16px;
    transition-property: top, color, font-size, line-height;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    pointer-events: none;
    cursor: text;
  `,
  SvgIcon: styled(SvgImage)`
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  `,
}
