export const SUBSCRIPTION_BENEFITS = [
  {
    id: 1,
    label: 'purchase7.subscriptionsWeekly.option1',
  },
  {
    id: 2,
    label: 'purchase7.subscriptionsWeekly.option2',
  },
  {
    id: 3,
    label: 'purchase7.subscriptionsWeekly.option3',
  },
  {
    id: 4,
    label: 'purchase7.subscriptionsWeekly.option4',
  },
]
