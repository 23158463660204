import styled from 'styled-components'

import { Color } from 'root-constants'

import { SwitchSize, TInputTheme } from './Switch'

type TSizeProps = {
  size: SwitchSize
}

const ROOT_SIZE_MAP = {
  [SwitchSize.SMALL]: { width: '44px', height: '24px' },
  [SwitchSize.MEDIUM]: { width: '54px', height: '31px' },
  [SwitchSize.LARGE]: { width: '60px', height: '32px' },
}

const BUTTON_DIMENSIONS_MAP = {
  [SwitchSize.SMALL]: { size: '20px', offset: '2px' },
  [SwitchSize.MEDIUM]: { size: '24px', offset: '3px' },
  [SwitchSize.LARGE]: { size: '26px', offset: '3px' },
}

export const StyledSwitch = {
  Root: styled.label<TSizeProps>`
    display: flex;
    cursor: pointer;
    width: ${({ size }) => ROOT_SIZE_MAP[size].width};
    height: ${({ size }) => ROOT_SIZE_MAP[size].height};
    position: relative;
    -webkit-tap-highlight-color: transparent;
  `,
  Input: styled.input<TInputTheme>`
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: -1;

    &:checked + span {
      transform: translateX(calc(100% + 1px));
    }

    &:checked ~ div {
      background-color: ${({ themeColor }) => themeColor || Color.PRIMARY};
      border: ${({ themeColor }) =>
        themeColor ? `1px solid ${themeColor}` : `1px solid ${Color.PRIMARY}`};
    }
  `,
  Track: styled.div`
    width: 100%;
    transition: 0.2s;
    border: 1px solid ${Color.ASH_GRAY};
    border-radius: 16px;
    background: ${Color.GRAY_LIGHT};
    transition-property: background, border;
  `,
  Button: styled.span<TSizeProps>`
    position: absolute;
    top: ${({ size }) => BUTTON_DIMENSIONS_MAP[size].offset};
    left: ${({ size }) => BUTTON_DIMENSIONS_MAP[size].offset};
    width: ${({ size }) => BUTTON_DIMENSIONS_MAP[size].size};
    height: ${({ size }) => BUTTON_DIMENSIONS_MAP[size].size};
    border-radius: 50%;
    transition: 0.2s;
    background: #fff;
    transition-property: transform;
  `,
}
