export const QUESTION = 'How sensitive is your skin?'

export const OPTION_VALUES = {
  ALWAYS: 'always',
  SOMETIMES: 'sometimes',
  RARELY: 'rarely',
}

export const SKIN_SENSITIVITY_ANSWERS = [
  {
    value: OPTION_VALUES.ALWAYS,
    text: 'onboarding.skinSensitivity.option1',
  },
  {
    value: OPTION_VALUES.SOMETIMES,
    text: 'onboarding.skinSensitivity.option2',
  },
  {
    value: OPTION_VALUES.RARELY,
    text: 'onboarding.skinSensitivity.option3',
  },
]
