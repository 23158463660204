import React, { lazy, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation, useRoutes } from 'react-router-dom'

import { useGrowthBook } from '@growthbook/growthbook-react'
import { PaypalProxy } from 'pages/paypalProxy'
import { ProxyToStart } from 'pages/proxyToStart'
import { LikeChatResult } from 'pages/result'

import { AnimationProvider } from 'components/AnimationProvider'
import { ProtectedRoute } from 'components/ProtectedRoute'
import { SubscribeFlow } from 'components/SubscribeFlow'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
  selectLanguage,
} from 'root-redux/selects/common'
import { selectActiveUpsellPages, selectUUID } from 'root-redux/selects/user'

import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useUserStatus } from 'hooks/useUserStatus'

import { getPathFromPageId } from 'helpers/getPathFromPageId'

import { Login } from 'modules/login'
import {
  PurchaseUpgrade,
  PurchaseUpgradePDF,
  UpsellWellnessBundle,
} from 'modules/purchase/pages'

import {
  EMAIL_CONSENT_PAGES,
  ID_TO_PAGE_MAP,
  ID_TO_PAGE_MAP_V3,
  PURCHASE_PAGES,
  PageId,
  UPSELL_PAGES_MAP,
} from 'page-constants'
import { COHORT_TO_TEST_MAP, Cohort } from 'root-constants'

import { Download } from './pages/download'
import { FinishingTouches } from './pages/finishingTouches'

const PaymentProvider = lazy(() => import('components/PaymentProvider'))

export const RouteList: React.FC = () => {
  const availableRoute = useUserStatus()
  const { search } = useLocation()

  const {
    currentVariantPageList,
    isUpsell,
    isPurchase,
    isUpgradeAvailable,
    isEmailConsent,
    isWellnessAppsBundleAvailable,
  } = useGetPageInfo()

  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const cohortToUse = parentCohort || cohort || Cohort.LUVLY_INTRO_FAST
  const uuid = useSelector(selectUUID)
  const language = useSelector(selectLanguage)
  const growthBook = useGrowthBook()
  const dynamicUpsellPages = useSelector(selectActiveUpsellPages)

  const pageMapId = useMemo(() => {
    switch (cohortToUse) {
      case Cohort.LUVLY_MARISSA:
        return ID_TO_PAGE_MAP_V3
      default:
        return ID_TO_PAGE_MAP
    }
  }, [cohortToUse])

  const isPagesMapTestRunning = useMemo(
    () => Object.keys(COHORT_TO_TEST_MAP).includes(cohortToUse),
    [cohortToUse],
  )

  const isQuizProtected = useMemo(() => {
    return cohortToUse !== Cohort.LUVLY_GIFT
  }, [cohortToUse])

  const firstPagePath = useMemo(
    () =>
      getPathFromPageId({
        pageId: currentVariantPageList[0].id,
        cohort,
        uuid,
        language,
        currentSearch: search,
      }),
    [currentVariantPageList, cohort, uuid, language, search],
  )

  const routes = useRoutes([
    { index: true, element: <Navigate to={firstPagePath} /> },
    {
      element: <SubscribeFlow />,
      children: currentVariantPageList.map(({ id: pageId }, index, arr) => {
        const CurrentPage = pageMapId[pageId]

        const nextPagePath = getPathFromPageId({
          pageId: arr[index + 1]?.id,
          cohort,
          uuid,
          language,
          currentSearch: search,
        })

        if (isQuizProtected) {
          return {
            path: pageId,
            element: (
              <ProtectedRoute isAvailable={!availableRoute}>
                <AnimationProvider>
                  <CurrentPage pageId={pageId} nextPagePath={nextPagePath} />
                </AnimationProvider>
              </ProtectedRoute>
            ),
          }
        }

        return {
          path: pageId,
          element: <CurrentPage pageId={pageId} nextPagePath={nextPagePath} />,
        }
      }),
    },
    {
      path: PageId.OB_RESULT,
      element: (
        <AnimationProvider>
          <LikeChatResult />
        </AnimationProvider>
      ),
    },
    ...EMAIL_CONSENT_PAGES.map(({ id }) => {
      const EmailConsentPage = pageMapId[id]

      return {
        path: id,
        element: (
          <ProtectedRoute isAvailable={isEmailConsent}>
            <EmailConsentPage nextPagePath="/" pageId={id} />
          </ProtectedRoute>
        ),
      }
    }),
    ...PURCHASE_PAGES.map(({ id, isProtected }) => {
      const PurchasePage = pageMapId[id]

      return {
        path: id,
        element: isProtected ? (
          <ProtectedRoute isAvailable={isPurchase}>
            <PaymentProvider>
              <PurchasePage nextPagePath="/" pageId={id} />
            </PaymentProvider>
          </ProtectedRoute>
        ) : (
          <ProtectedRoute isAvailable={!availableRoute || isPurchase}>
            <PaymentProvider>
              <PurchasePage nextPagePath="/" pageId={id} />
            </PaymentProvider>
          </ProtectedRoute>
        ),
      }
    }),
    {
      path: PageId.PURCHASE_UPGRADE,
      element: (
        <ProtectedRoute isAvailable={isUpgradeAvailable}>
          <PaymentProvider>
            <PurchaseUpgrade />
          </PaymentProvider>
        </ProtectedRoute>
      ),
    },
    {
      path: PageId.PURCHASE_UPGRADE_PDF,
      element: (
        <ProtectedRoute isAvailable={isUpgradeAvailable}>
          <PaymentProvider>
            <PurchaseUpgradePDF />
          </PaymentProvider>
        </ProtectedRoute>
      ),
    },
    {
      path: PageId.WELLNESS_BUNDLE_PAYWALL,
      element: (
        <ProtectedRoute isAvailable={isWellnessAppsBundleAvailable}>
          <PaymentProvider>
            <UpsellWellnessBundle />
          </PaymentProvider>
        </ProtectedRoute>
      ),
    },
    {
      path: PageId.WELLNESS_BUNDLE_CANCEL_PAYWALL,
      element: (
        <ProtectedRoute isAvailable={isWellnessAppsBundleAvailable}>
          <PaymentProvider>
            <UpsellWellnessBundle />
          </PaymentProvider>
        </ProtectedRoute>
      ),
    },
    ...UPSELL_PAGES_MAP[cohortToUse].map((pageId, index, arr) => {
      const UpsellPage = pageMapId[pageId]
      return {
        path: pageId,
        element: (
          <ProtectedRoute
            isAvailable={dynamicUpsellPages?.includes(pageId) && isUpsell}
          >
            <UpsellPage nextPagePath={arr[index + 1]} pageId={pageId} />
          </ProtectedRoute>
        ),
      }
    }),
    {
      path: PageId.FINISHING_TOUCHES,
      element: (
        <ProtectedRoute
          isAvailable={isUpsell || availableRoute === PageId.FINISHING_TOUCHES}
        >
          <FinishingTouches />
        </ProtectedRoute>
      ),
    },
    {
      path: PageId.LOGIN,
      element: (
        <ProtectedRoute
          isAvailable={isUpsell || availableRoute === PageId.FINISHING_TOUCHES}
        >
          <AnimationProvider>
            <Login />
          </AnimationProvider>
        </ProtectedRoute>
      ),
    },
    {
      path: PageId.DOWNLOAD,
      element: (
        <ProtectedRoute isAvailable={availableRoute === PageId.DOWNLOAD}>
          <AnimationProvider>
            <Download />
          </AnimationProvider>
        </ProtectedRoute>
      ),
    },
    {
      path: PageId.PROXY_TO_START,
      element: (
        <ProtectedRoute isAvailable={availableRoute === PageId.PROXY_TO_START}>
          <AnimationProvider>
            <ProxyToStart />
          </AnimationProvider>
        </ProtectedRoute>
      ),
    },
    {
      path: PageId.PAYPAL_PROXY,
      element: (
        <ProtectedRoute isAvailable={isPurchase}>
          <PaypalProxy />
        </ProtectedRoute>
      ),
    },
    { path: '*', element: <Navigate to={firstPagePath} /> },
  ])

  if (isPagesMapTestRunning) {
    return growthBook?.ready ? routes : null
  }

  return routes
}
