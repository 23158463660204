import styled from 'styled-components'

import { MediaBreakpoint } from 'root-constants'

export const StyledDailyWaterVariant2 = {
  DescriptionContainer: styled.div`
    display: flex;
    align-items: center;
    height: 32px;
    margin-bottom: 24px;
  `,
  Description: styled.span`
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  `,
  Image: styled.img`
    width: 32px;
    height: 32px;
    margin-right: 8px;
    border-radius: 8px;
  `,
  GlassesContainer: styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      align-items: center;
    }
  `,
}
