import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, buttonTheme } from 'storybook-ui'

import { Container } from 'components/Container'

import { selectAnswers } from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import cup from 'assets/images/cup-icon.png'
import graph from 'assets/images/double-chin-chart.png'

import {
  DoubleChinTitle,
  SandyBackgroundGlobalStyles,
  StickyButtonContainer,
} from 'common-styles'
import { PageId } from 'page-constants'
import { I18N_CONTEXT_COHORT_MAP, UserGoal } from 'root-constants'

import { StyledDoubleChinReduction as S } from './DoubleChinReduction.styles'
import { GRAPH_TITLES_MAP, QUESTION, QUESTIONS_MAP } from './constants'

export const DoubleChinReduction: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}: TPageProps) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const { isLuvlyChinCohort } = useActiveCohortIdentifier()
  const cohortToUse = useCohortToUse()
  const userGoal = userAnswers?.[PageId.INTRO] || UserGoal.WRINKLE_FREE_SKIN

  const handleContinue = useNextStep({
    pageId,
    question: QUESTIONS_MAP[cohortToUse] || QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue('')

  const getGraphTitle = () => {
    if (isLuvlyChinCohort) return t('onboarding.doubleChinReduction.graphTitle')

    return t(GRAPH_TITLES_MAP[userGoal])
  }

  return (
    <div>
      <Container>
        <DoubleChinTitle>
          <Trans
            i18nKey="onboarding.doubleChinReduction.title"
            context={I18N_CONTEXT_COHORT_MAP[cohortToUse]}
            components={[<strong />]}
          />
        </DoubleChinTitle>
        <S.GraphContainer>
          <S.GraphTitle>{getGraphTitle()}</S.GraphTitle>
          <S.Graph src={graph} alt="graph" />
          <S.GraphNote>
            {t('onboarding.doubleChinReduction.graphNote')}
          </S.GraphNote>
        </S.GraphContainer>
        <S.NoteContainer>
          <S.Cup src={cup} alt="cup" />
          <S.NoteTitle>
            {t('onboarding.doubleChinReduction.noteTitle')}
          </S.NoteTitle>
          <S.NoteDescription>
            <Trans
              i18nKey="onboarding.doubleChinReduction.noteDescription"
              context={I18N_CONTEXT_COHORT_MAP[cohortToUse]}
            />
          </S.NoteDescription>
        </S.NoteContainer>
      </Container>
      <StickyButtonContainer customBackground="transparent" centerMode>
        <Button onClick={handleNextClick} theme={buttonTheme.LUVLY_SECONDARY}>
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
      <SandyBackgroundGlobalStyles />
    </div>
  )
}
