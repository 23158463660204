export const QUESTION = 'What concerns you most about current beauty solutions?'

const CONCERNS_VALUES = {
  SHORT_TERM_RESULTS: 'short_term_results',
  HIGH_COST: 'high_cost',
  SAFETY_ISSUES: 'safety_issues',
  UNDERWHELMING_RESULTS: 'underwhelming_results',
}

export const BEAUTY_SOLUTIONS_CONCERNS_ANSWERS = [
  {
    value: CONCERNS_VALUES.SHORT_TERM_RESULTS,
    label: 'onboarding.beautySolutionsConcerns.option1',
    detailed: 'onboarding.beautySolutionsConcerns.detailed1',
  },
  {
    value: CONCERNS_VALUES.HIGH_COST,
    label: 'onboarding.beautySolutionsConcerns.option2',
    detailed: 'onboarding.beautySolutionsConcerns.detailed2',
  },
  {
    value: CONCERNS_VALUES.SAFETY_ISSUES,
    label: 'onboarding.beautySolutionsConcerns.option3',
    detailed: 'onboarding.beautySolutionsConcerns.detailed3',
  },
  {
    value: CONCERNS_VALUES.UNDERWHELMING_RESULTS,
    label: 'onboarding.beautySolutionsConcerns.option4',
    detailed: 'onboarding.beautySolutionsConcerns.detailed4',
  },
]
