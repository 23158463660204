import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AnswerButtonWithImage } from 'components/AnswerButtonWithImage'
import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'
import { Option } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useIsNavigationOn } from 'hooks/useIsNavigationOn'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { goBack } from 'browser-history'
import { OptionType } from 'root-constants'

import { StyledFaceShape as S } from './FaceShape.styles'
import { OPTIONS, QUESTION } from './constants'

export const FaceShape: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const [answer, setAnswer] = useState<string>('')
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const includeNavigation = useIsNavigationOn()
  const { isSeniorCohort } = useActiveCohortIdentifier()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue(answer)

  const handleAnswerChange = (value: string) => {
    if (includeNavigation) {
      setAnswer(value)
      return
    }

    handleChange(value, handleContinue)
    setIsAnswersDisabled(true)
  }

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: handleAnswerChange,
  }

  return (
    <div>
      <Container>
        <S.TitleContainer>
          <PageTitle>{t`onboarding.faceShape.question`}</PageTitle>
        </S.TitleContainer>

        <S.OptionsContainer>
          {OPTIONS.map(({ value, image }) => {
            return (
              <Option
                {...optionProps}
                disabled={isAnswersDisabled}
                value={value}
                key={value}
              >
                <AnswerButtonWithImage
                  imageSrc={image}
                  withCheckbox={!isSeniorCohort}
                />
              </Option>
            )
          })}
        </S.OptionsContainer>
      </Container>
      {includeNavigation && (
        <NavigationButtons
          onBackClick={goBack}
          onNextClick={handleNextClick}
          disabled={!answer}
        />
      )}
    </div>
  )
}
