import styled from 'styled-components'

import { CustomBackgroundStyles, customBackgroundStyles } from 'common-styles'
import { Color } from 'root-constants'

export const StyledWeWantYouLookBest = {
  Root: styled.div<{ background: CustomBackgroundStyles }>`
    ${customBackgroundStyles}
  `,
  Hero: styled.div`
    min-height: 256px;
    position: relative;
    margin-bottom: 24px;
  `,
  Title: styled.h1`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 16px;
  `,
  Subtitle: styled.p`
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.GRAY};
  `,
}
