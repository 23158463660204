import styled from 'styled-components'

import introDesktopBg from 'assets/images/intro-like-chat-desktop-bg.jpg'
import introBg from 'assets/images/intro-like-chat-mobile-bg.jpg'

import { BottomButtonContainer } from 'common-styles'
import { Color, MediaBreakpoint } from 'root-constants'

export const StyledIntroLikeChat = {
  Root: styled.div`
    position: relative;
    background: url(${introBg}) no-repeat;
    background-position: top center;
    background-size: cover;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      background: url(${introDesktopBg}) no-repeat;
      background-position: top center;
      background-size: cover;
    }
  `,
  Container: styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
    min-height: calc(var(--full-height) - 56px);
    height: 100%;
    padding: 0 16px;
  `,
  Title: styled.h1`
    color: ${Color.DARK};
    text-align: center;
    font-family: Rubik;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 8px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      margin: 0 auto 16px auto;
    }
  `,
  Subtitle: styled.p`
    color: ${Color.GRAY};
    text-align: center;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  `,
  ButtonContainer: styled(BottomButtonContainer)`
    background: transparent;
  `,
}
