import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  CURRENCY_SYMBOLS,
  Currency,
  DECORATING_DISCOUNT,
  ONE_HUNDRED_PERCENTAGE,
} from 'modules/purchase/constants'
import { useGetPriceWithCurrencyFormatting } from 'modules/purchase/hooks/useGetPriceWithCurrencyFormatting'
import { usePriceWithVatAdjustment } from 'modules/purchase/hooks/usePriceWithVatAdjustment'
import { selectDynamicDiscountPercentage } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import { StyledSelectSubscriptionItemV4 as S } from './SelectSubscriptionItemV4.styles'

type TProps = {
  subscription: ISubscription
  isSelected: boolean
}

export const SelectSubscriptionItemV4: React.FC<TProps> = ({
  subscription: { id, trialPrices, mainPrices, currency },
  isSelected,
}) => {
  const { t } = useTranslation()
  const dynamicDiscountPercentage = useSelector(selectDynamicDiscountPercentage)
  const { getPriceWithCurrencyFormatting } = useGetPriceWithCurrencyFormatting()

  const isMxnCurrency = currency === Currency.MXN

  const { getPriceWithVatAdjustment } = usePriceWithVatAdjustment()

  const getOldPrice = () => {
    const calculatedOldPrice =
      trialPrices.fullPrice /
      ((ONE_HUNDRED_PERCENTAGE - dynamicDiscountPercentage) /
        ONE_HUNDRED_PERCENTAGE)

    if (currency === Currency.JPY) {
      return Math.ceil(getPriceWithVatAdjustment(calculatedOldPrice))
    }

    return (
      Math.ceil(getPriceWithVatAdjustment(calculatedOldPrice)) -
      DECORATING_DISCOUNT
    ).toFixed(2)
  }

  return (
    <S.Root isSelected={isSelected} key={id}>
      <S.Period>
        <Trans
          i18nKey="purchaseIntroOffer.monthlyPeriod"
          values={{ count: mainPrices.periodQuantity }}
        />
      </S.Period>
      <div>
        <S.OldPrice>
          {!isMxnCurrency && (
            <Trans
              i18nKey="purchaseIntroOffer.subscription.oldPrice"
              values={{
                oldPrice: getPriceWithCurrencyFormatting(getOldPrice()),
                currency: CURRENCY_SYMBOLS[currency],
              }}
            />
          )}
        </S.OldPrice>
        <S.MainPrice>
          <Trans
            i18nKey="purchaseIntroOffer.subscription.price"
            values={{
              price: getPriceWithCurrencyFormatting(
                getPriceWithVatAdjustment(trialPrices.fullPrice),
              ),
              currency: CURRENCY_SYMBOLS[currency],
            }}
          />
        </S.MainPrice>
        <S.Text>/{t('reTargetingPurchase.recurringPeriod')}</S.Text>
      </div>
    </S.Root>
  )
}
