import { DefaultTheme } from 'styled-components'

export const DEFAULT_THEME: DefaultTheme = {
  common: {
    semiBoldFontWeight: 500,
  },
}

export const CORAL_THEME: DefaultTheme = {
  common: {
    semiBoldFontWeight: 700,
  },
}
