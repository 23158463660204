import { AnswerWithIconAndCheckbox } from 'storybook-ui'
import styled from 'styled-components'

import { BottomButtonContainer as ButtonContainer } from 'common-styles'
import { MediaBreakpoint } from 'root-constants'

export const StyledSkinConcerns = {
  TitleContainer: styled.header`
    max-width: 360px;
    padding: 0 10px;
    margin: 0 auto 16px;
  `,
  OptionsContainer: styled.div``,
  BottomButtonContainer: styled(ButtonContainer)`
    padding: 40px 16px;
  `,
  OptionsContainer2: styled.div`
    margin-top: 24px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      margin-top: 32px;
    }
  `,
  AnswerWithIcon: styled(AnswerWithIconAndCheckbox)`
    & span {
      border-radius: 8px;
    }
  `,
}
