import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AGE_ANSWERS_MAP } from 'pages/questions/Age/constants'

import {
  selectAnswers,
  selectLanguage,
  selectUserGoal,
} from 'root-redux/selects/common'

import { useABTest } from 'hooks/useABTest'
import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useTitleFromUrl } from 'hooks/useTitleFromUrl'

import { selectDynamicDiscount } from 'modules/purchase/redux/selects/common'

import smallAvatar from 'assets/images/small-avatar.png'

import { PageId } from 'page-constants'
import {
  DEFAULT_USER_AGE,
  DynamicDiscountType,
  Locale,
  UserGoal,
} from 'root-constants'

import { InfluencersDiscount } from '../InfluencersDiscount'
import { LongPaywallStaticBanner } from '../LongPaywallStaticBanner'
import { PurchaseTimer } from '../PurchaseTimer'
import { StyledLongPaywallShortUserProfile as S } from './LongPaywallShortUserProfile.styles'
import { USER_GOAL_TITLES_MAP, USER_LEVELS_MAP, UserLevel } from './constants'

type TLongPaywallShortUserProfileProps = {
  timerRef: React.RefObject<HTMLElement>
  title?: string
}

export const LongPaywallShortUserProfile: React.FC<
  TLongPaywallShortUserProfileProps
> = ({ title, timerRef }: TLongPaywallShortUserProfileProps) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const userGoal = useSelector(selectUserGoal)
  const language = useSelector(selectLanguage)
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const { headline } = useTitleFromUrl()

  const userLevel = userAnswers?.[PageId.HAVE_YOU_HEARD] || UserLevel.NOT_SURE

  const isEnLocale = language === Locale.ENGLISH

  const {
    isDynamicDiscountCohort,
    isRefundCohort,
    isLuvlyIntroSaleCohort,
    isLuvlyEmailSaleCohort,
    isLuvlyChinCohort,
    isLuvlyInfluencersCohort,
    isLuvlyW2WCohort,
  } = useActiveCohortIdentifier()
  const { isStaticBannerActive } = useABTest()

  const isDynamicDiscountType =
    dynamicDiscount?.type !== DynamicDiscountType.STATIC

  const hasDynamicDiscount = isDynamicDiscountType && isDynamicDiscountCohort
  const isStaticBannerTestInactive =
    isLuvlyIntroSaleCohort && isDynamicDiscountType && !isStaticBannerActive

  const hasTimer =
    !isDynamicDiscountCohort ||
    (hasDynamicDiscount && !isLuvlyIntroSaleCohort) ||
    isStaticBannerTestInactive

  const hasStaticBanner =
    (!isDynamicDiscountType && isDynamicDiscountCohort) ||
    (isDynamicDiscountType && isLuvlyIntroSaleCohort && isStaticBannerActive)

  const renderInfluencersDiscount = () => {
    if (!isLuvlyInfluencersCohort) {
      return null
    }

    return <InfluencersDiscount />
  }

  const renderContainer = () => {
    if (hasStaticBanner) {
      return <LongPaywallStaticBanner />
    }

    if (hasTimer) {
      return <PurchaseTimer timerRef={timerRef} />
    }

    return null
  }

  const getTitle = () => {
    if (title) return t(title)
    if ((isLuvlyEmailSaleCohort || isLuvlyChinCohort) && headline) {
      return headline
    }

    return t(USER_GOAL_TITLES_MAP[userGoal || UserGoal.WRINKLE_FREE_SKIN])
  }

  const renderContent = () => {
    if (!isLuvlyW2WCohort) {
      return (
        <S.ContentWrapper isEnLocaleStyle={isEnLocale}>
          <S.ContentBlock isEnLocaleStyle={isEnLocale}>
            <S.ContentImage src={smallAvatar} alt="small-avatar" />
            <S.LevelContentWrapper>
              <S.ContentSubtitle isEnLocaleStyle={isEnLocale}>
                {t('purchase7.userProfile.age')}
              </S.ContentSubtitle>
              <S.UserProfileText isEnLocaleStyle={isEnLocale}>
                {isRefundCohort ? (
                  AGE_ANSWERS_MAP[userAnswers?.[PageId.AGE] || DEFAULT_USER_AGE]
                ) : (
                  <Trans
                    i18nKey="purchase7.userProfile.ageValue"
                    values={{
                      age: userAnswers?.[PageId.AGE] || DEFAULT_USER_AGE,
                    }}
                  />
                )}
              </S.UserProfileText>
            </S.LevelContentWrapper>
          </S.ContentBlock>
          <S.ContentBlock isEnLocaleStyle={isEnLocale}>
            <S.ContentImage
              src={USER_LEVELS_MAP[userLevel].image}
              alt="small-avatar"
            />
            <S.LevelContentWrapper>
              <S.ContentSubtitle isEnLocaleStyle={isEnLocale}>
                {t('purchase7.userProfile.faceYogaLevel')}
              </S.ContentSubtitle>
              <S.UserProfileText isEnLocaleStyle={isEnLocale}>
                {t(USER_LEVELS_MAP[userLevel].text)}
              </S.UserProfileText>
            </S.LevelContentWrapper>
          </S.ContentBlock>
        </S.ContentWrapper>
      )
    }

    return null
  }

  return (
    <S.Wrapper>
      <S.Title>{getTitle()}</S.Title>
      {renderContent()}
      {renderInfluencersDiscount()}
      {renderContainer()}
    </S.Wrapper>
  )
}
