import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, buttonTheme } from 'storybook-ui'

import { Container } from 'components/Container'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import {
  CustomBackgroundStyles,
  DoubleChinTitle,
  StickyButtonContainer,
} from 'common-styles'
import { PageId } from 'page-constants'
import { Gender, SKIN_COLOR_OPTION_VALUES, UserGoal } from 'root-constants'

import { StyledEmbracingAge as S } from './EmbracingAge.styles'
import { EMBRACING_AGE_TEXT_MAP, QUESTIONS_MAP } from './constants'

export const EmbracingAge: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const userGender = userAnswers?.[PageId.GENDER]
  const skinColor = userAnswers?.[PageId.IDENTIFY_SKIN_COLOR]
  const userGoal = userAnswers?.[PageId.INTRO] || UserGoal.WRINKLE_FREE_SKIN

  const handleContinue = useNextStep({
    pageId,
    question: QUESTIONS_MAP[userGoal],
    nextPagePath,
  })

  const handleNextClick = () => handleContinue('')

  const beforeAfterImage = () => {
    const { images } = EMBRACING_AGE_TEXT_MAP[userGoal]
    if (skinColor === SKIN_COLOR_OPTION_VALUES.BROWN_OR_BLACK_BROWN) {
      return userGender === Gender.MALE ? images.man2 : images.woman2
    }

    return userGender === Gender.MALE ? images.man1 : images.woman1
  }

  return (
    <S.Root background={CustomBackgroundStyles.TRANSPARENT_PINK_TO_YELLOW}>
      <Container>
        <DoubleChinTitle>
          {t(EMBRACING_AGE_TEXT_MAP[userGoal].title)}
        </DoubleChinTitle>
        <S.Description>
          {t(EMBRACING_AGE_TEXT_MAP[userGoal].description)}
        </S.Description>
        <S.BeforeAfterImage src={beforeAfterImage()} alt="before-after" />
      </Container>
      <StickyButtonContainer customBackground="transparent" centerMode>
        <Button onClick={handleNextClick} theme={buttonTheme.LUVLY_SECONDARY}>
          {t('actions.soundsGood')}
        </Button>
      </StickyButtonContainer>
    </S.Root>
  )
}
