import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledCheckoutPurchaseDetails = {
  PriceDescription: styled.div`
    margin: 12px auto 0;
    padding-bottom: 10px;
    border-bottom: 1px solid #e9eaed;

    & > p:first-of-type {
      margin-bottom: 4px;
    }
  `,
  PriceInfo: styled.p`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  PriceBlockText: styled.span`
    color: ${Color.LIGHT};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  `,
  TotalDiscount: styled.span<{ errorTheme?: boolean }>`
    color: ${({ errorTheme }) => (errorTheme ? Color.LIGHT : Color.PRIMARY)};
    font-size: 14px;
    font-weight: ${({ errorTheme }) => (errorTheme ? 400 : 600)};
    line-height: 20px;
  `,
  DailyPayment: styled.div`
    margin: 14px auto 0;
    border-radius: 8px;
    background-color: #f5faf7;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  DailyText: styled.span`
    color: ${Color.DARK};
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  `,
  DailyAmount: styled.span`
    color: #7fb89d;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  `,
  TotalPayment: styled.div`
    margin: 32px 8px;
  `,
  TotalDescription: styled.p`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${Color.DARK};
    font-size: 16px;
    line-height: 24px;
  `,
  TotalText: styled.span`
    font-weight: 600;
  `,
  TotalAmount: styled.span<{ errorTheme?: boolean }>`
    color: ${({ errorTheme }) => (errorTheme ? Color.LIGHT : Color.DARK)};
    font-weight: 400;

    & > strong {
      color: ${({ errorTheme }) => (errorTheme ? Color.PRIMARY : Color.DARK)};
      font-weight: 600;
    }
  `,
  SaveText: styled.p`
    color: ${Color.PRIMARY};
    text-align: right;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  `,
}
