import styled, { css } from 'styled-components'

import { AnswerButtonMini as AnswerButton } from 'components/AnswerButtonMini'

import messageTailIcon from 'assets/images/message-tail.png'

import { Color } from 'root-constants'

type TStatementQuestionProps = {
  isLargeSize?: boolean
}

export const StyledStatementQuestion = {
  Root: styled.div`
    min-height: calc(100dvh - 88px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 32px;

    & > div {
      width: 100%;
    }
  `,
  TitleContainer: styled.header`
    margin-bottom: 40px;
  `,
  Title: styled.h1<TStatementQuestionProps>`
    font-weight: 500;
    text-align: center;

    ${({ isLargeSize }) =>
      isLargeSize
        ? css`
            font-size: 24px;
            line-height: 32px;
            color: ${Color.BLUE_DARK};
            margin-bottom: 28px;
          `
        : css`
            font-size: 19px;
            line-height: 22px;
            color: ${Color.DARK};
            margin-bottom: 25px;
          `}
  `,
  TitleVariant2: styled.h1`
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.DARK};
    margin-bottom: 24px;
  `,
  Question: styled.h2<TStatementQuestionProps>`
    text-align: center;
    font-weight: 400;

    ${({ isLargeSize }) =>
      isLargeSize
        ? css`
            font-size: 18px;
            line-height: 24px;
            color: ${Color.BLUE_DARK};
          `
        : css`
            font-size: 19px;
            line-height: 22px;
            color: ${Color.DARK};
          `}
  `,
  QuestionVarian2: styled.h2`
    border-radius: 32px;
    background: ${Color.WHITE};
    padding: 24px;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.BLUE_DARK};
    box-shadow: 0px 4px 14px 0px rgba(8, 4, 23, 0.03);
    position: relative;

    &::after {
      display: block;
      content: '';
      width: 52px;
      height: 64px;
      background: url(${messageTailIcon}) no-repeat center;
      background-size: cover;
      position: absolute;
      left: -6px;
      bottom: -6px;
      z-index: -1;
    }
  `,
  OptionsContainer: styled.div`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px;
  `,
  AnswerButtonMini: styled(AnswerButton)<TStatementQuestionProps>`
    ${({ isLargeSize }) =>
      isLargeSize &&
      css`
        color: ${Color.BLUE_DARK};
        font-weight: 500;
      `}
  `,
  Scale: styled.div<TStatementQuestionProps>`
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    line-height: 20px;
    color: ${({ isLargeSize }) => (isLargeSize ? Color.GRAY : Color.DARK)};

    & span {
      max-width: 110px;
    }
  `,
}
