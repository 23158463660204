import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, buttonTheme } from 'storybook-ui'

import { Container } from 'components/Container'

import { selectUserGoal } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { DoubleChinTitle, StickyButtonContainer } from 'common-styles'
import { UserGoal } from 'root-constants'

import { StyledPersonalizedProgram as S } from './PersonalizedProgram.styles'
import { CONTENT, QUESTION } from './constants'

export const PersonalizedProgram: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const userGoal = useSelector(selectUserGoal)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue('')

  return (
    <Container>
      <S.Hero>
        <img src={CONTENT[userGoal || UserGoal.REDUCE_FACE_FAT].image} alt="" />
      </S.Hero>
      <DoubleChinTitle>
        <Trans
          i18nKey={CONTENT[userGoal || UserGoal.REDUCE_FACE_FAT].goalTitle}
        />
      </DoubleChinTitle>
      <S.List>
        <S.Item>
          {t(CONTENT[userGoal || UserGoal.REDUCE_FACE_FAT].goalDescription)}
        </S.Item>
        <S.Item>
          {t('onboarding.personalizedProgram.descriptionDermatologist')}
        </S.Item>{' '}
        <S.Item>{t('onboarding.personalizedProgram.descriptionVideo')}</S.Item>
      </S.List>
      <StickyButtonContainer customBackground="transparent" centerMode>
        <Button onClick={handleNextClick} theme={buttonTheme.LUVLY_SECONDARY}>
          {t('actions.gotIt3')}
        </Button>
      </StickyButtonContainer>
    </Container>
  )
}
