import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { ChatMessage } from 'components/ChatMessage'
import {
  ChatAuthor,
  ChatStep,
  DELAY_BEFORE_SHOW,
} from 'components/ChatMessage/ChatMessage'
import { Container } from 'components/Container'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import challenges from 'assets/images/like-chat-challenges.png'

import { DelayedBottomContainer } from 'common-styles'

import { QUESTION_2 } from './constants'

export const LikeChatChallenges2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [chatStep, setChatStep] = useState<ChatStep>(ChatStep.EXPERT_QUESTION)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION_2,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue('')

  return (
    <Container fullHeight>
      <ChatMessage
        author={ChatAuthor.EXPERT}
        delayBeforeShow={DELAY_BEFORE_SHOW}
        hasJustNowLabel
      >
        <Trans
          i18nKey="onboarding.likeChatChallenges2.thanksForSharing"
          components={[<br />, <strong />]}
        />
      </ChatMessage>
      <ChatMessage
        author={ChatAuthor.EXPERT}
        delayBeforeShow={2000}
        isImage
        hasHeader={false}
        onTransitionEnd={() => setChatStep(ChatStep.FINISH)}
      >
        <img src={challenges} alt="challenges" />
      </ChatMessage>
      <DelayedBottomContainer
        isShown={chatStep === ChatStep.FINISH}
        pinedToBottom
      >
        <Button onClick={handleNextClick}>{t('actions.great')}</Button>
      </DelayedBottomContainer>
    </Container>
  )
}
