import React from 'react'
import { useTranslation } from 'react-i18next'

import { Answer, answerTheme } from 'storybook-ui'

import { AdaptiveContainer } from 'components/AdaptiveContainer'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import desktopBackground from 'assets/images/intro-fast-girl-10-desktop.png'
import mobileBackground from 'assets/images/intro-fast-girl-10-mobile.png'

import { OptionType } from 'root-constants'

import { StyledPreferredFood as S } from './PreferredFood.styles'
import { DEFAULT_PREFERRED_FOOD_OPTIONS, QUESTION } from './constants'

export const PreferredFoodV2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    theme: answerTheme.LUVLY_SOLID,
    disabled: isAnswersDisabled,
    fontWeight: '600',
    maxWidth: '488px',
    onChange: (value: string) => {
      setIsAnswersDisabled(true)
      handleChange(value, handleContinue)
    },
  }

  return (
    <AdaptiveContainer
      mobileBackground={mobileBackground}
      desktopBackground={desktopBackground}
      fullWidth
    >
      <S.Title>{t('onboarding.preferredFood.question')}</S.Title>
      <S.OptionsContainer2>
        {DEFAULT_PREFERRED_FOOD_OPTIONS.map(({ value, text }) => {
          return (
            <Answer {...optionProps} key={value} value={value}>
              {t(text)}
            </Answer>
          )
        })}
      </S.OptionsContainer2>
    </AdaptiveContainer>
  )
}
