import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import product from 'assets/images/product-ingredient.png'

import { StyledProductIngredientFeedback as S } from './ProductIngredientFeedback.styles'
import { QUESTION } from './constants'

export const ProductIngredientFeedback: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue('')
  return (
    <S.Root>
      <S.ImageContainer>
        <S.Image src={product} alt="" />
      </S.ImageContainer>
      <Container>
        <S.Title>
          <Trans
            i18nKey="onboarding.productIngredientFeedback.question"
            components={[<br />]}
          />
        </S.Title>
        <S.Description>
          <Trans
            i18nKey="onboarding.productIngredientFeedback.description"
            components={[<br />]}
          />
        </S.Description>
        <StickyButtonContainer>
          <Button onClick={handleNextClick}>{t('actions.next')}</Button>
        </StickyButtonContainer>
      </Container>
    </S.Root>
  )
}
