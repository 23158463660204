import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { SwitchSize } from 'components/Switch'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'

import { setIsPrintablePdfEnabled } from 'modules/purchase/redux/actions/common'
import { selectIsPrintablePdfEnabled } from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import girlImage from 'assets/images/girl-icon.png'

import { StyledPrintableFaceYogaPDF as S } from './PrintableFaceYogaPDF.styles'

const PRINTABLE_FACE_YOGA_PDF = 'printable_face_yoga_PDF'

export const PrintableFaceYogaPDF: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isLuvlyPrintableCohort } = useActiveCohortIdentifier()
  const isPrintablePdfEnabled = useSelector(selectIsPrintablePdfEnabled)

  if (!isLuvlyPrintableCohort) {
    return null
  }

  const handleChange = () => {
    if (!isPrintablePdfEnabled) {
      dispatch(setIsPrintablePdfEnabled(true))
      eventLogger.logPlanPageToggleEnabled()

      return
    }

    dispatch(setIsPrintablePdfEnabled(false))
    eventLogger.logPlanPageToggleDisabled()
  }

  return (
    <S.Root isEnabled={isPrintablePdfEnabled}>
      <S.Image src={girlImage} alt="icon" />
      <S.Content>
        <S.GetPDF>{t('purchaseRefund.freePDF.title')}</S.GetPDF>
        <S.PrintableChallenge>
          {t('purchaseRefund.freePDF.description')}
        </S.PrintableChallenge>
      </S.Content>
      <S.Switch
        value={PRINTABLE_FACE_YOGA_PDF}
        onChange={handleChange}
        themeColor="#A8D5B2"
        isChecked={isPrintablePdfEnabled}
        size={SwitchSize.MEDIUM}
      />
    </S.Root>
  )
}
