import React from 'react'
import { useTranslation } from 'react-i18next'

import { Switch } from 'components/Switch'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { useGetPriceWithCurrencyFormatting } from 'modules/purchase/hooks/useGetPriceWithCurrencyFormatting'

import { ISubscription } from 'models/subscriptions.model'

import { StyledUpsellSubscriptionItemSwitchers as S } from './UpsellSubscriptionItemSwitchers.styles'

type TProps = {
  subscription: ISubscription
  isSelected: boolean
  onChange: (value) => void
  value: string
}

export const UpsellSubscriptionItemSwitchers: React.FC<TProps> = ({
  subscription: { product, id, mainPrices, currency },
  isSelected,
  onChange,
  value,
}) => {
  const { t } = useTranslation()
  const { getPriceWithCurrencyFormatting } = useGetPriceWithCurrencyFormatting()

  return (
    <S.Offer isSelected={isSelected} key={id}>
      <Switch value={value} onChange={onChange} isChecked={isSelected} />
      <S.OfferCaption>
        {t(`upsell.subscriptionsBlock.${product}`)}
      </S.OfferCaption>
      <S.OfferPrice>
        {t('purchase1.subscription.price', {
          currency: CURRENCY_SYMBOLS[currency],
          price: getPriceWithCurrencyFormatting(mainPrices.fullPrice),
        })}
      </S.OfferPrice>
    </S.Offer>
  )
}
