import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AnswerWithCheckbox,
  Button,
  answerWithCheckboxTheme,
  buttonTheme,
} from 'storybook-ui'

import { AdaptiveContainer } from 'components/AdaptiveContainer'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import checkIcon from 'assets/images/check-white.svg'

import { PageId } from 'page-constants'
import { OptionType } from 'root-constants'
import { PROBLEMATIC_AREAS } from 'shared-options'

import { PROBLEMATIC_AREAS_DEFAULT_OPTIONS, QUESTION } from './constants'

export const ProblematicAreasV3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<{ value: string; label: string }[]>([])

  const handleContinue = useNextStep({
    pageId: PageId.PROBLEMATIC_AREA,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => {
    const uniqueAnswers = answers.map((answer) => answer.label)
    handleContinue(answers, uniqueAnswers)
  }

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    iconSrc: checkIcon,
    iconSize: '12px',
    theme: answerWithCheckboxTheme.LUVLY,
    margin: '0 0 8px 0',
    onChange: (value: string, isChecked: boolean) => {
      if (isChecked) {
        const userAnswer = PROBLEMATIC_AREAS.find(
          (item) => item.label === value,
        )
        userAnswer && setAnswers([...answers, userAnswer])
      } else {
        setAnswers(answers.filter((item) => item.label !== value))
      }
    },
  }

  return (
    <AdaptiveContainer title="onboarding.problematicAreas.question_v3">
      {PROBLEMATIC_AREAS_DEFAULT_OPTIONS.map(({ value, text }) => (
        <AnswerWithCheckbox {...optionProps} value={value} key={value}>
          {t(text)}
        </AnswerWithCheckbox>
      ))}
      <StickyButtonContainer centerMode>
        <Button
          onClick={handleNextClick}
          disabled={!answers.length}
          theme={buttonTheme.LUVLY_SECONDARY}
          maxWidth="488px"
        >
          {t`actions.next`}
        </Button>
      </StickyButtonContainer>
    </AdaptiveContainer>
  )
}
