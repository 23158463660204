import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Spinner } from 'components/Spinner'

import { selectScreenName } from 'root-redux/selects/common'

import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useScrollToTop } from 'hooks/useScrollToTop'

import { createProductId } from 'helpers/createProductId'

import { CardPaymentFormSeparateCheckout2 } from 'modules/purchase/components/CardPaymentFormSeparateCheckout'
import { CheckoutPurchaseDetails } from 'modules/purchase/components/CheckoutPurchaseDetails'
import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { StripeSeparatePaymentRequestButton } from 'modules/purchase/components/StripeSeparatePaymentRequestButton'
import { PaymentMethod } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'

import SecuritySystems from 'assets/images/security-systems.png'

import { SandyBackgroundGlobalStyles } from 'common-styles'
import { Cohort, ScreenName } from 'root-constants'

import { StyledCheckoutSeparateMethods2 as S } from './CheckoutSeparatedMethods2.styles'
import { PAYMENT_METHODS_IMAGES } from './constants'

type TCheckoutExtended = {
  handleCloseCheckout: () => void
}
type TAlternativePaymentMethod =
  | PaymentMethod.APPLE_PAY
  | PaymentMethod.GOOGLE_PAY

export const CheckoutSeparateMethods2: React.FC<TCheckoutExtended> = ({
  handleCloseCheckout,
}: TCheckoutExtended) => {
  const { t } = useTranslation()
  const screenName = useSelector(selectScreenName)

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethod | null>(null)
  const [alternativePaymentMethod, setAlternativePaymentMethod] =
    useState<TAlternativePaymentMethod | null>(null)
  const [isPaymentWaitingShown, setIsPaymentWaitingShown] =
    useState<boolean>(false)
  useScrollToTop()
  const {
    periodName,
    periodQuantity,
    trialPrice,
    price,
    cohort,
    stripeAccountId,
    stripeAccountName,
    fetchingActionsList,
    threeDSecureIframeUrl,
    selectedSubscription,
  } = usePurchaseStore()

  const isCheckoutReady = !fetchingActionsList.includes(
    CHECK_PAYMENT_REQUEST_BUTTON,
  )

  const isCardSelected = selectedPaymentMethod === PaymentMethod.CREDIT_CARD

  const productId = createProductId({
    periodName,
    periodQuantity,
    price: trialPrice || price,
  })

  useLayoutEffect(() => {
    if (alternativePaymentMethod) {
      setSelectedPaymentMethod(alternativePaymentMethod)
      return
    }
    setSelectedPaymentMethod(PaymentMethod.CREDIT_CARD)
  }, [alternativePaymentMethod])

  useAmplitudeInitialization(cohort as Cohort, ScreenName.CHECKOUT)

  useEffect(() => {
    if (productId) {
      eventLogger.logPurchaseShown({
        productId,
        screenName,
        stripeAccountId,
        stripeAccountName,
      })
    }
  }, [productId, screenName, stripeAccountId, stripeAccountName])

  const setPaymentMethod = (paymentMethod: PaymentMethod) => {
    setSelectedPaymentMethod(paymentMethod)
    eventLogger.logPaymentMethodSelected(paymentMethod)
  }

  if (threeDSecureIframeUrl) {
    return <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
  }

  return (
    selectedSubscription && (
      <S.Root>
        {!isCheckoutReady && <Spinner />}
        <S.ContentContainer isVisible={isCheckoutReady}>
          <S.PaymentMethodsTitle>
            {alternativePaymentMethod
              ? t('checkoutExtended.selectMethods')
              : t('checkoutExtended.checkoutTitle')}
          </S.PaymentMethodsTitle>
          <S.CloseButton
            data-testid="close-btn"
            onClick={handleCloseCheckout}
          />
          {alternativePaymentMethod && (
            <S.PaymentMethodsWrapper>
              <S.PaymentMethod
                isSelected={isCardSelected}
                onClick={() => setPaymentMethod(PaymentMethod.CREDIT_CARD)}
              >
                <S.PaymentMethodText>
                  {t('checkoutExtended.creditCard')}
                </S.PaymentMethodText>
                <S.PaymentMethodImage
                  isSelected={isCardSelected}
                  src={PAYMENT_METHODS_IMAGES[PaymentMethod.CREDIT_CARD]}
                  alt="credit-card-img"
                />
              </S.PaymentMethod>
              <S.PaymentMethod
                isSelected={!isCardSelected}
                onClick={() => setPaymentMethod(alternativePaymentMethod)}
              >
                <S.AlternativePaymentMethodImage
                  isSelected={!isCardSelected}
                  src={PAYMENT_METHODS_IMAGES[alternativePaymentMethod]}
                  alt="credit-card-img"
                />
              </S.PaymentMethod>
            </S.PaymentMethodsWrapper>
          )}

          <CheckoutPurchaseDetails />

          <S.PaymentFormWrapper>
            <S.StripePaymentWrapper>
              <S.StripePaymentContainer
                isVisible={selectedPaymentMethod === PaymentMethod.CREDIT_CARD}
              >
                <S.PaymentFormTitleContainer>
                  <S.PaymentFormTitle>
                    {t('purchase1.paymentForm.paymentInfo')}
                  </S.PaymentFormTitle>
                  <S.ProtectedLabel>
                    {t('purchase1.paymentForm.protected')}
                  </S.ProtectedLabel>
                </S.PaymentFormTitleContainer>

                <CardPaymentFormSeparateCheckout2 />
              </S.StripePaymentContainer>
              <S.StripePaymentContainer
                isVisible={selectedPaymentMethod !== PaymentMethod.CREDIT_CARD}
              >
                <StripeSeparatePaymentRequestButton
                  borderRadius={30}
                  setAlternativePaymentMethodCallback={
                    setAlternativePaymentMethod
                  }
                />
              </S.StripePaymentContainer>
            </S.StripePaymentWrapper>
          </S.PaymentFormWrapper>
          <S.PaymentsSystemImage src={SecuritySystems} alt="security-systems" />
          <S.PaymentsSystemText>{t`purchase2.checkout.moneyBackGuarantee`}</S.PaymentsSystemText>
        </S.ContentContainer>

        <PaymentWaitingModal
          isPaymentWaitingShown={isPaymentWaitingShown}
          setIsPaymentWaitingShown={setIsPaymentWaitingShown}
          buttonText={t`purchase1.paymentWaiting.proceedToPlan`}
        />
        <SandyBackgroundGlobalStyles />
      </S.Root>
    )
  )
}
