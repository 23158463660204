export const QUESTION = 'Choose your focus'

const OPTION_VALUES = {
  FACE_SCULPTING: 'face_sculpting',
  FRESH_COMPLEXION: 'fresh_complexion',
  HEALTHY_HABITS: 'healthy_habits',
  COLLAGEN_BOOST: 'collagen_boost',
  CONSISTENT_ROUTINE: 'consistent_routine',
  EVEN_SKIN_TONE: 'even_skin_tone',
  BLEMISH_REDUCTION: 'blemish_reduction',
  MENTAL_HEALTH: 'mental_health',
  DOUBLE_CHIN_REDUCTION: 'double_chin_reduction',
  STRESS_RELIEF: 'stress_relief',
  FACE_LIFTING: 'face_lifting',
  DETOX: 'detox',
}

export const TAGS = [
  {
    value: OPTION_VALUES.FACE_SCULPTING,
    label: 'onboarding.obTags.faceSculpting',
  },
  {
    value: OPTION_VALUES.FRESH_COMPLEXION,
    label: 'onboarding.obTags.freshComplexion',
  },
  {
    value: OPTION_VALUES.HEALTHY_HABITS,
    label: 'onboarding.obTags.healthyHabits',
  },
  {
    value: OPTION_VALUES.COLLAGEN_BOOST,
    label: 'onboarding.obTags.collagenBoost',
  },
  {
    value: OPTION_VALUES.CONSISTENT_ROUTINE,
    label: 'onboarding.obTags.consistentRoutine',
  },
  {
    value: OPTION_VALUES.EVEN_SKIN_TONE,
    label: 'onboarding.obTags.skinTone',
  },
  {
    value: OPTION_VALUES.BLEMISH_REDUCTION,
    label: 'onboarding.obTags.blemishReduction',
  },
  {
    value: OPTION_VALUES.MENTAL_HEALTH,
    label: 'onboarding.obTags.mentalHealth',
  },
  {
    value: OPTION_VALUES.DOUBLE_CHIN_REDUCTION,
    label: 'onboarding.obTags.doubleChinReduction',
  },
  {
    value: OPTION_VALUES.STRESS_RELIEF,
    label: 'onboarding.obTags.stressRelief',
  },
  {
    value: OPTION_VALUES.FACE_LIFTING,
    label: 'onboarding.obTags.faceLifting',
  },
  {
    value: OPTION_VALUES.DETOX,
    label: 'onboarding.obTags.detox',
  },
]

export const TAGS_2 = [
  {
    value: OPTION_VALUES.FACE_SCULPTING,
    label: 'onboarding.obTags.faceSculpting',
  },
  {
    value: OPTION_VALUES.FRESH_COMPLEXION,
    label: 'onboarding.obTags.freshComplexion',
  },
  {
    value: OPTION_VALUES.HEALTHY_HABITS,
    label: 'onboarding.obTags.healthyHabits',
  },
  {
    value: OPTION_VALUES.COLLAGEN_BOOST,
    label: 'onboarding.obTags.collagenBoost',
  },
  {
    value: OPTION_VALUES.CONSISTENT_ROUTINE,
    label: 'onboarding.obTags.consistentRoutine',
  },
  {
    value: OPTION_VALUES.MENTAL_HEALTH,
    label: 'onboarding.obTags.mentalHealth',
  },
  {
    value: OPTION_VALUES.BLEMISH_REDUCTION,
    label: 'onboarding.obTags.blemishReduction',
  },
  {
    value: OPTION_VALUES.EVEN_SKIN_TONE,
    label: 'onboarding.obTags.skinTone',
  },
  {
    value: OPTION_VALUES.DOUBLE_CHIN_REDUCTION,
    label: 'onboarding.obTags.doubleChinReduction',
  },
  {
    value: OPTION_VALUES.DETOX,
    label: 'onboarding.obTags.detox',
  },
  {
    value: OPTION_VALUES.FACE_LIFTING,
    label: 'onboarding.obTags.faceLifting',
  },
  {
    value: OPTION_VALUES.STRESS_RELIEF,
    label: 'onboarding.obTags.stressRelief',
  },
]
