import { useLocation } from 'react-router-dom'

import { COHORT_QUERY_PARAM } from 'root-constants'

export const useIsChildCohortFromQueryParams = (
  cohortsList: string[],
): boolean => {
  const { search } = useLocation()

  const URLParams = new URLSearchParams(search)
  const cohortQueryParam = URLParams.get(COHORT_QUERY_PARAM)
  return !!cohortQueryParam && cohortsList.includes(cohortQueryParam)
}
