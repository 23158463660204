import { useSelector } from 'react-redux'

import {
  selectCurrentVariantCohort,
  selectCurrentVariantParentCohort,
} from 'root-redux/selects/common'
import {
  selectActiveUpsellPages,
  selectUserStatus,
} from 'root-redux/selects/user'

import {
  EMAIL_CONSENT_PAGES_MAP,
  PURCHASE_PAGES_MAP,
  PageId,
  UPSELL_PAGES_MAP,
} from 'page-constants'
import { Cohort } from 'root-constants'

import { useActiveCohortIdentifier } from './useActiveCohortIdentifier'

export const useUserStatus = (): string => {
  const userStatus = useSelector(selectUserStatus)
  const cohort = useSelector(selectCurrentVariantCohort)
  const parentCohort = useSelector(selectCurrentVariantParentCohort)
  const dynamicUpsellPages = useSelector(selectActiveUpsellPages)
  const { isLuvlyWellhubCohort, isLuvlyEmailCoaching } =
    useActiveCohortIdentifier()

  const cohortToUse = (parentCohort || cohort) as Cohort

  if (userStatus?.hasSubscription && isLuvlyEmailCoaching) {
    return UPSELL_PAGES_MAP[Cohort.LUVLY_EMAIL_COACHING][0]
  }

  if (userStatus?.account.hasAccount) {
    return isLuvlyWellhubCohort ? PageId.WELLHUB_DOWNLOAD : PageId.DOWNLOAD
  }

  if (userStatus?.hasSubscription && !dynamicUpsellPages.length) {
    return PageId.FINISHING_TOUCHES
  }

  if (userStatus?.hasSubscription && dynamicUpsellPages.length) {
    return dynamicUpsellPages[0]
  }

  if (userStatus?.email.hasEmail && userStatus?.email.isEmailConsentTried) {
    return PURCHASE_PAGES_MAP[cohortToUse] || PageId.PROXY_TO_START
  }

  if (userStatus?.email.hasEmail && !userStatus?.email.isEmailConsentTried) {
    return EMAIL_CONSENT_PAGES_MAP[cohortToUse] || PageId.EMAIL_CONSENT
  }

  return ''
}
