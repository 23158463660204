import { wellhubApi } from 'api'
import firebase from 'firebase/app'
import 'firebase/auth'
import i18n from 'i18next'

import {
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import { bindUserAction } from 'root-redux/actions/user'
import { selectWellhubPromoCode } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import {
  IAction,
  IAppState,
  TAppActionThunk,
  TAppDispatchThunk,
} from 'models/store.model'

import { eventLogger } from 'services/eventLogger.service'

import { FirebaseSignUpErrorCode } from 'root-constants'

const MODULE_NAME = 'LOGIN'
const REGISTER_WITH_EMAIL_FIREBASE = `${MODULE_NAME}/REGISTER_WITH_EMAIL_FIREBASE`

export const SET_EMAIL_ERROR_MESSAGE = `${MODULE_NAME}/SET_EMAIL_ERROR_MESSAGE`
export const RESET_EMAIL_ERROR_MESSAGE = `${MODULE_NAME}/RESET_EMAIL_ERROR_MESSAGE`

export const SET_PASSWORD_ERROR_MESSAGE = `${MODULE_NAME}/SET_PASSWORD_ERROR_MESSAGE`
export const RESET_PASSWORD_ERROR_MESSAGE = `${MODULE_NAME}/RESET_PASSWORD_ERROR_MESSAGE`
const ACTIVATE_WELLHUB_PROMO_CODE = `${MODULE_NAME}/ACTIVATE_WELLHUB_PROMO_CODE`

export const resetEmailErrorMessageAction = (): IAction<never> => ({
  type: RESET_EMAIL_ERROR_MESSAGE,
})

export const setEmailErrorMessageAction = (
  message: string,
): IAction<string> => ({
  type: SET_EMAIL_ERROR_MESSAGE,
  payload: message,
})

export const resetPasswordErrorMessageAction = (): IAction<never> => ({
  type: RESET_PASSWORD_ERROR_MESSAGE,
})

export const setPasswordErrorMessageAction = (
  message: string,
): IAction<string> => ({
  type: SET_PASSWORD_ERROR_MESSAGE,
  payload: message,
})

export function registerWithEmailFirebaseAction({
  email,
  password,
}: {
  email: string
  password: string
}): TAppDispatchThunk<any> {
  return async (dispatch) => {
    dispatch(startFetching(REGISTER_WITH_EMAIL_FIREBASE))

    try {
      await firebase.auth().createUserWithEmailAndPassword(email, password)
    } catch (error: any) {
      if (error.code === FirebaseSignUpErrorCode.EMAIL_EXISTS) {
        eventLogger.logAccountCreationFailed({
          error: error.message || i18n.t('login.emailExists'),
        })
        dispatch(setEmailErrorMessageAction(i18n.t('login.emailExists')))
      }
    }

    dispatch(stopFetching(REGISTER_WITH_EMAIL_FIREBASE))
  }
}

export function applyWellhubPromoCodeAction(
  token: string,
): TAppActionThunk<any> {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)
    const promoCode = selectWellhubPromoCode(state)

    if (!promoCode) {
      dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
      return
    }

    dispatch(startFetching(ACTIVATE_WELLHUB_PROMO_CODE))

    try {
      const response = await wellhubApi.activatePromoCode({ promoCode, uuid })

      if (response.success) {
        dispatch(bindUserAction(token))
      }
    } catch (e) {
      dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
    }

    dispatch(stopFetching(ACTIVATE_WELLHUB_PROMO_CODE))
  }
}
