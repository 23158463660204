import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { ChatAnswerButton } from 'components/ChatAnswerButton'
import { ChatMessage } from 'components/ChatMessage'
import {
  ChatAuthor,
  ChatStep,
  DELAY_BEFORE_SHOW,
} from 'components/ChatMessage/ChatMessage'
import { Container } from 'components/Container'
import { Option } from 'components/Option'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { DelayedBottomContainer } from 'common-styles'
import { PageId } from 'page-constants'
import { OptionType } from 'root-constants'

import { StyledLikeChatGoal as S } from './LikeChatGoal.styles'
import { GOAL_ANSWERS, OPTION_VALUES, QUESTION } from './constants'

export const LikeChatGoal: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answer, setAnswer] = useState<string>('')
  const [chatStep, setChatStep] = useState<ChatStep>(ChatStep.EXPERT_QUESTION)

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: PageId.INTRO,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue(answer)

  const handleSetAnswer = (value) => {
    setAnswer(value)
  }

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    disabled: isAnswersDisabled,
    onChange: (value: string) => {
      setIsAnswersDisabled(true)
      handleChange(value, handleSetAnswer)
    },
  }

  return (
    <Container fullHeight>
      <S.ContentContainer>
        <div>
          <ChatMessage
            author={ChatAuthor.EXPERT}
            delayBeforeShow={DELAY_BEFORE_SHOW}
            onTransitionEnd={() => setChatStep(ChatStep.USER_ANSWER)}
            hasJustNowLabel={
              chatStep === ChatStep.EXPERT_QUESTION ||
              (chatStep === ChatStep.USER_ANSWER && !answer)
            }
          >
            {t('onboarding.likeChatGoal.title')}
          </ChatMessage>
          <ChatMessage
            author={ChatAuthor.USER}
            {...(!!answer && {
              delayBeforeShow: DELAY_BEFORE_SHOW,
            })}
            onTransitionEnd={() => setChatStep(ChatStep.EXPERT_EXPRESSION)}
          >
            {answer && (
              <S.AnswerWrapper>
                <S.Icon src={GOAL_ANSWERS[answer].icon} alt="icon" />
                {t(GOAL_ANSWERS[answer].text)}
              </S.AnswerWrapper>
            )}
          </ChatMessage>
          <ChatMessage
            author={ChatAuthor.EXPERT}
            {...(chatStep === ChatStep.EXPERT_EXPRESSION && {
              delayBeforeShow: DELAY_BEFORE_SHOW,
            })}
            onTransitionEnd={() => setChatStep(ChatStep.FINISH)}
            hasJustNowLabel={
              chatStep === ChatStep.EXPERT_EXPRESSION ||
              chatStep === ChatStep.FINISH
            }
          >
            {t('onboarding.likeChatGoal.expression')}
          </ChatMessage>
        </div>
        <DelayedBottomContainer isShown={chatStep === ChatStep.FINISH}>
          <Button onClick={handleNextClick}>{t('actions.gotIt2')}</Button>
        </DelayedBottomContainer>
        <DelayedBottomContainer
          isShown={!answer && chatStep === ChatStep.USER_ANSWER}
        >
          <Option {...optionProps} value={OPTION_VALUES.TIGHTEN_SKIN}>
            <ChatAnswerButton
              iconSrc={GOAL_ANSWERS[OPTION_VALUES.TIGHTEN_SKIN].icon}
            >
              {t('onboarding.goal.tightenSkin')}
            </ChatAnswerButton>
          </Option>
          <Option {...optionProps} value={OPTION_VALUES.REDUCE_FAT}>
            <ChatAnswerButton
              iconSrc={GOAL_ANSWERS[OPTION_VALUES.REDUCE_FAT].icon}
            >
              {t('onboarding.goal.loseFat')}
            </ChatAnswerButton>
          </Option>
          <Option {...optionProps} value={OPTION_VALUES.GET_RID}>
            <ChatAnswerButton
              iconSrc={GOAL_ANSWERS[OPTION_VALUES.GET_RID].icon}
            >
              {t('onboarding.goal.getRid2')}
            </ChatAnswerButton>
          </Option>
        </DelayedBottomContainer>
      </S.ContentContainer>
    </Container>
  )
}
