import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button, buttonTheme } from 'storybook-ui'

import { Container } from 'components/Container'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import doubleChinAppearance from 'assets/images/double-chin-appearance.png'
import harvardMedicalSchool from 'assets/images/harvard-medical-school-logo-v2.png'
import northwesternUniversity from 'assets/images/northwestern-university-logo-v2.png'

import {
  SandyBackgroundGlobalStyles,
  StickyButtonContainer,
} from 'common-styles'

import { StyledReduceYourDoubleChin as S } from './ReduceYourDoubleChin.styles'
import { QUESTION } from './constants'

export const ReduceYourDoubleChin: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue('')

  return (
    <S.Root>
      <Container>
        <S.Title>
          <Trans
            i18nKey="onboarding.reduceYourDoubleChin.title"
            components={[<strong />]}
          />
        </S.Title>
        <S.Chart>
          <img src={doubleChinAppearance} alt="Double chin appearance" />
        </S.Chart>
        <S.Card>
          <S.ImageContainer>
            <img src={harvardMedicalSchool} alt="" />
          </S.ImageContainer>

          <p>
            <Trans
              i18nKey="onboarding.reverseYourWrinkles.option1"
              components={[<strong />]}
            />
          </p>
        </S.Card>
        <S.Card>
          <S.ImageContainer>
            <img src={northwesternUniversity} alt="" />
          </S.ImageContainer>
          <p>
            <Trans
              i18nKey="onboarding.reverseYourWrinkles.option2"
              components={[<strong />]}
            />
          </p>
        </S.Card>
      </Container>

      <StickyButtonContainer
        centerMode
        customBackground="linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.0001) 0.25%,
      #ffffff 26.14%
    )"
      >
        <Button onClick={handleNextClick} theme={buttonTheme.LUVLY_SECONDARY}>
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
      <SandyBackgroundGlobalStyles />
    </S.Root>
  )
}
