import styled from 'styled-components'

import { Color } from 'root-constants'

type TProps = {
  isSelected: boolean
}

export const StyledIntroOfferSelectSubscriptionItemVertical = {
  Wrapper: styled.div<TProps>`
    width: 100%;
    max-width: 110px;
  `,

  Content: styled.div<TProps>`
    background: ${({ isSelected }) =>
      isSelected
        ? 'linear-gradient(96deg, #DBA8FF 0%, #9A64FF 100%)'
        : '#c4c4c4'};
    padding: 8px 2px 2px;
    border-radius: 12px;
    text-align: center;
    color: ${({ isSelected }) => (isSelected ? Color.DARK : Color.GRAY)};
  `,
  Caption: styled.h4`
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${Color.WHITE};
    margin-bottom: 4px;
  `,
  Top: styled.div`
    padding: 8px 0;
    background-color: ${Color.WHITE};
    border-radius: 12px 12px 0 0;
    margin-bottom: 1px;
  `,
  Bottom: styled.div`
    padding: 8px 0;
    background-color: ${Color.WHITE};
    border-radius: 0 0 12px 12px;
  `,
  PlanPeriod: styled.p<TProps>`
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 133.333% */
    letter-spacing: 0.5px;
    text-transform: uppercase;

    & span {
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 32px;
    }
  `,
  CustomPrice: styled.p<TProps>`
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 141.176% */
    letter-spacing: 0.5px;
    text-transform: uppercase;
  `,
  Daily: styled.p`
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
  `,

  CurrentPrice: styled.div`
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: ${Color.GRAY};

    & p {
      font-size: 9px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px; /* 133.333% */
      text-transform: none;
      letter-spacing: 0.5px;
    }
  `,
}
