import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { SvgImage } from 'components/SvgImage'

import { selectAnswers } from 'root-redux/selects/common'

import problematicAreasMale from 'assets/images/problematic-area-source-male.png'
import problematicAreasFemale from 'assets/images/problematic-area-source.png'

import { PageId } from 'page-constants'
import { Gender } from 'root-constants'

import { OPTION_VALUES } from '../../constants'
import { StyledAnimatedFace as S } from './AnimatedFace.styles'
import { PROBLEMATIC_AREAS_MAN, PROBLEMATIC_AREAS_WOMAN } from './constants'

type TAnimatedFaceProps = {
  getIconCurrentColor: (value: OPTION_VALUES) => string
}

export const AnimatedFace: React.FC<TAnimatedFaceProps> = ({
  getIconCurrentColor,
}: TAnimatedFaceProps) => {
  const userAnswers = useSelector(selectAnswers)
  const userGender = userAnswers?.[PageId.GENDER]

  const { problematicAreasSource, areas } = useMemo(() => {
    if (userGender === Gender.MALE) {
      return {
        problematicAreasSource: problematicAreasMale,
        areas: PROBLEMATIC_AREAS_MAN,
      }
    }

    return {
      problematicAreasSource: problematicAreasFemale,
      areas: PROBLEMATIC_AREAS_WOMAN,
    }
  }, [userGender])

  return (
    <S.ImageContainer>
      <img src={problematicAreasSource} alt="source" />
      {areas.map(({ value, width, top, left, icon }) => (
        <S.Area
          key={value}
          color={getIconCurrentColor(value)}
          top={top}
          left={left}
        >
          <SvgImage svg={icon} width={width} />
        </S.Area>
      ))}
    </S.ImageContainer>
  )
}
