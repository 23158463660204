import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

type TProgressProps = {
  color?: string
  background?: string
}

const progressValueStyles = css`
  background-color: rgba(255, 130, 118, 0.5);
  transition: all 0.2s ease-out;
`

export const StyledConnectToDBProgress = {
  ProgressWrapper: styled.div<{ maxWidth?: string }>`
    position: relative;
    max-width: ${({ maxWidth }) => maxWidth || '314px'};
    margin: 0 auto;
    border-radius: 12px;
    overflow: hidden;

    &:before {
      content: attr(data-progress-value);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
  `,
  Label: styled.p`
    padding-bottom: 10px;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
  `,
  Progress: styled.progress<TProgressProps>`
    appearance: none;
    border: none;
    display: block;
    width: 100%;
    height: 36px;
    background: ${({ background }) => background || `${Color.CORAL_GRAY}`};
    color: ${({ color }) => color || Color.DARK};

    &::-webkit-progress-bar {
      background: ${({ background }) => background || `${Color.CORAL_GRAY}`};
      color: ${({ color }) => color || Color.DARK};
    }
    &::-webkit-progress-value {
      ${progressValueStyles}
    }
    &::-moz-progress-bar {
      ${progressValueStyles}
    }
    &::-ms-fill {
      ${progressValueStyles}
    }
  `,
}
