import React from 'react'

import { useCohortToUse } from 'hooks/useCohortToUse'

import {
  AnswerButtonHeight,
  StyledAnswerButton as S,
} from './AnswerButton.styles'

type TProps = {
  children?: React.ReactNode
  iconSrc?: string
  iconWidth?: number
  iconHeight?: number
  withCheckbox?: boolean
  height?: AnswerButtonHeight
}

export const AnswerButton: React.FC<TProps> = ({
  children,
  iconSrc,
  withCheckbox,
  iconWidth,
  iconHeight,
  height = AnswerButtonHeight.S,
}) => {
  const cohortToUse = useCohortToUse()

  return (
    <S.Wrapper withCheckbox={withCheckbox} cohort={cohortToUse}>
      <S.Content height={height}>
        {iconSrc && (
          <S.Icon
            src={iconSrc}
            iconWidth={iconWidth}
            iconHeight={iconHeight}
            alt=""
          />
        )}
        <S.Text>{children}</S.Text>
      </S.Content>
    </S.Wrapper>
  )
}
