export const QUESTION = 'Analyzing answers'

export const STOP_COUNTER_AT = 80
export const LOW_SWITCH_BREAKPOINT = 60
export const HIGH_SWITCH_BREAKPOINT = 100
export const STATIC_ANIMATION_DURATION = 4000
export const MODAL_HIDING_DELAY = 500
export const FIRST_MODAL_DISABLE_POINT = 0
export const SECOND_MODAL_DISABLE_POINT = 1

export const ANALYZING_MESSAGES = [1, 2, 3, 4]

export const MESSAGE_APPEARANCE_DELAY = 1750

export const REVIEWS = [
  {
    text: 'onboarding.analyzingAnswers.reviews.firstText',
    author: 'onboarding.analyzingAnswers.reviews.firstAuthor',
  },
  {
    text: 'onboarding.analyzingAnswers.reviews.secondText',
    author: 'onboarding.analyzingAnswers.reviews.secondAuthor',
  },
  {
    text: 'onboarding.analyzingAnswers.reviews.thirdText',
    author: 'onboarding.analyzingAnswers.reviews.thirdAuthor',
  },
]
