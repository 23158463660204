import styled from 'styled-components'

import { AdaptiveContainer } from 'components/AdaptiveContainer'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledCosmetologistVsLuvly2 = {
  Subtitle: styled(AdaptiveContainer.Subtitle)`
    & strong {
      color: ${Color.BLUE_DARK};
    }
  `,
  Note: styled.h2`
    color: ${Color.GRAY};
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 8px;

    & strong {
      color: ${Color.BLUE_DARK};
    }

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      margin-top: 16px;
      text-align: center;
    }
  `,
  GraphContainer: styled.div`
    aspect-ratio: 343/172;
    width: 100%;
    max-width: 343px;
    height: auto;
    margin: 24px auto;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      margin-top: 16px;
      text-align: center;
      margin: 32px auto;
    }
  `,
}
