import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button, buttonTheme } from 'storybook-ui'

import { Container } from 'components/Container'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { CustomBackgroundStyles, StickyButtonContainer } from 'common-styles'

import { StyledFaceYogaRoutine as S } from './FaceYogaRoutine.styles'
import { ANSWERS, QUESTION } from './constants'

export const FaceYogaRoutine: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue('')

  return (
    <S.Root background={CustomBackgroundStyles.GREEN_TO_SANDY}>
      <Container>
        <S.Title>{t('onboarding.faceYogaRoutine.title')}</S.Title>
        <S.Subtitle>{t('onboarding.faceYogaRoutine.subtitle')}</S.Subtitle>
        {ANSWERS.map(({ label, icon, graph, percentage }) => {
          return (
            <S.Answer key={label}>
              <img src={icon} alt="" />
              <span>{t(label)}</span>
              <img src={graph} alt="" />
              <span>{t(percentage)}</span>
            </S.Answer>
          )
        })}
      </Container>

      <StickyButtonContainer
        customBackground="linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.0001) 0.25%,
      #ffffff 26.14%
    )"
        centerMode
      >
        <Button onClick={handleNextClick} theme={buttonTheme.LUVLY_SECONDARY}>
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
    </S.Root>
  )
}
