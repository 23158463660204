import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, buttonTheme } from 'storybook-ui'

import { Container } from 'components/Container'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { selectAnswers, selectUserAge } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import lookBetterMan1 from 'assets/images/look-better-man-1.png'
import lookBetterMan2 from 'assets/images/look-better-man-2.png'
import lookBetterWoman1 from 'assets/images/look-better-woman-1.png'
import lookBetterWoman2 from 'assets/images/look-better-woman-2.png'

import { CustomBackgroundStyles } from 'common-styles'
import { PageId } from 'page-constants'
import { Gender, UserAge } from 'root-constants'

import { StyledWeWantYouLookBest2 as S } from './WeWantYouLookBest2.styles'
import { QUESTION } from './constants'

export const WeWantYouLookBest2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const userAge = useSelector(selectUserAge)
  const userGender = userAnswers?.[PageId.GENDER]

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue('')

  const getImage = () => {
    if (userAge === UserAge.TWENTY || userAge === UserAge.THIRTY) {
      return userGender === Gender.MALE ? lookBetterMan1 : lookBetterWoman1
    }

    return userGender === Gender.MALE ? lookBetterMan2 : lookBetterWoman2
  }

  return (
    <S.Root background={CustomBackgroundStyles.PURPLE_TO_SANDY}>
      <Container>
        <S.Hero>
          <img src={getImage()} alt="" />
        </S.Hero>
        <S.Title>{t('onboarding.weWantYouLookBest2.title')}</S.Title>
        <S.Subtitle>{t('onboarding.weWantYouLookBest2.subtitle')}</S.Subtitle>
      </Container>
      <StickyButtonContainer centerMode>
        <Button onClick={handleNextClick} theme={buttonTheme.LUVLY_SECONDARY}>
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
    </S.Root>
  )
}
