import userBenefitImage1 from 'assets/images/user-get-benefit-1.png'
import userBenefitImage2 from 'assets/images/user-get-benefit-2.png'
import userBenefitImage3 from 'assets/images/user-get-benefit-3.png'
import userBenefitImage4 from 'assets/images/user-get-benefit-4.png'
import userBenefitImage5 from 'assets/images/user-get-benefit-5.png'

export const DAILY_SCHEDULE_IMAGE_PATH = '/images/daily-schedule'

export const USER_BENEFITS = [
  {
    id: '1',
    image: userBenefitImage1,
    bgColor: 'rgba(255, 234, 236, 1)',
    text: 'purchase7.userBenefits.option1',
    textV2: 'purchase8.userBenefits.option1',
  },
  {
    id: '2',
    image: userBenefitImage2,
    bgColor: 'rgba(248, 230, 198, 1)',
    text: 'purchase7.userBenefits.option2',
    textV2: 'purchase8.userBenefits.option2',
  },
  {
    id: '3',
    image: userBenefitImage3,
    bgColor: 'rgba(150, 135, 224, 0.2)',
    text: 'purchase7.userBenefits.option3',
    textV2: 'purchase8.userBenefits.option3',
  },
  {
    id: '4',
    image: userBenefitImage4,
    bgColor: 'rgba(236, 249, 213, 1)',
    text: 'purchase7.userBenefits.option4',
    textV2: 'purchase8.userBenefits.option4',
  },
  {
    id: '5',
    image: userBenefitImage5,
    bgColor: 'rgba(155, 230, 233, 0.6)',
    text: 'purchase7.userBenefits.option5',
    textV2: 'purchase8.userBenefits.option5',
  },
]
