import React, { useCallback, useEffect, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Button, buttonTheme } from 'storybook-ui'

import { Container } from 'components/Container'
import { LinearProgressBar } from 'components/LinearProgressBar'
import { SvgImage } from 'components/SvgImage'

import {
  sendUserAnswersAction,
  sendUserConfigAction,
} from 'root-redux/actions/user'
import { selectDownloadLinks, selectLanguage } from 'root-redux/selects/common'

import { getDeepLink } from 'helpers/getDeepLink'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import logoSvg from 'assets/images/sprite/logo.svg'

import { SandyBackgroundGlobalStyles } from 'common-styles'
import {
  CDN_FOLDER_LINK,
  CUSTOM_TOKEN_LOCAL_STORAGE_KEY,
  Cohort,
  PROGRESS_STEPS_LENGTH_MAP,
} from 'root-constants'

import { StyledWellhubDownload as S } from './WellhubDownload.styles'
import { APP_SCREEN_PATH } from './constants'

export const WellhubDownload: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const downloadLinks = useSelector(selectDownloadLinks)
  const language = useSelector(selectLanguage)

  const downloadAppLink = useMemo(() => {
    const customToken = localStorage.getItem(CUSTOM_TOKEN_LOCAL_STORAGE_KEY)

    return getDeepLink(customToken, downloadLinks)
  }, [downloadLinks])

  useEffect(() => {
    eventLogger.logGettingAppShown()

    window.axon && window.axon('track', 'page_view')
  }, [])

  useEffect(() => {
    dispatch(sendUserAnswersAction(null, true))
    dispatch(
      sendUserConfigAction({
        is_download_visited: true,
      }),
    )
  }, [dispatch])

  const handleContinue = useCallback(() => {
    window.location.href = downloadAppLink
  }, [downloadAppLink])

  const handleClick = useCallback(() => {
    googleAnalyticsLogger.logDownloadButtonPressed()
    eventLogger.logDownloadClicked(handleContinue)
  }, [handleContinue])

  return (
    <div>
      <S.Header>
        <SvgImage svg={logoSvg} width={58} height={24} />
        <S.PageNumberContainer>
          <span>{PROGRESS_STEPS_LENGTH_MAP[Cohort.LUVLY_WELLHUB]}</span>
          <span>/</span>
          <span>{PROGRESS_STEPS_LENGTH_MAP[Cohort.LUVLY_WELLHUB]}</span>
        </S.PageNumberContainer>
      </S.Header>
      <LinearProgressBar progressStatus={11} />
      <Container>
        <S.Title>
          <Trans i18nKey="gettingApp.downloadToContinue" />
        </S.Title>
        <S.Image
          src={`${CDN_FOLDER_LINK}${APP_SCREEN_PATH}_${language}.png`}
          alt="app-screen"
        />
        <Button
          type="button"
          data-deep-link={downloadAppLink}
          theme={buttonTheme.LUVLY_SECONDARY}
          onClick={handleClick}
          maxWidth="375px"
        >
          {t`gettingApp.download`}
        </Button>

        <SandyBackgroundGlobalStyles />
      </Container>
    </div>
  )
}
