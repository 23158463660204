import styled from 'styled-components'

export const StyledProblematicAreasV2 = {
  Root: styled.div`
    position: relative;
    max-width: 430px;
    margin: 0 auto;
  `,
  SplittedOptionsContainer: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;

    & > label:first-of-type {
      grid-column: 1;
    }

    & > label:nth-child(n + 2) {
      grid-column: 2;
    }
  `,
}
