import styled, { css } from 'styled-components'

import { AdaptiveContainer } from 'components/AdaptiveContainer'

import { MediaBreakpoint } from 'root-constants'

type TPreferredFoodProps = {
  isSeniorCohortStyles: boolean
}

type TOptionsContainerProps = {
  textAlign?: 'left' | 'center'
}

export const StyledPreferredFood = {
  TitleContainer: styled.header<TPreferredFoodProps>`
    margin-bottom: 16px;
    font-weight: 500;
    text-align: center;

    ${({ isSeniorCohortStyles }) =>
      isSeniorCohortStyles
        ? css`
            font-size: 24px;
            line-height: 32px;
          `
        : css`
            font-size: 17px;
            line-height: 26px;
          `}
  `,
  OptionsContainer: styled.div<TOptionsContainerProps>`
    text-align: ${({ textAlign = 'center' }) => textAlign};
  `,
  Title: styled(AdaptiveContainer.Title)`
    font-size: 18px;
    margin-bottom: 24px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 32px;
    }
  `,
  OptionsContainer2: styled.div`
    max-width: 256px;
    margin-top: 24px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: unset;
      margin-top: 32px;
    }
  `,
}
