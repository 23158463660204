import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { GROWTHBOOK_EXPERIMENT } from 'root-constants'

export const useABTest = () => {
  const isPrimerActive = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.LV_27)
  const areAgeHighPricesActive = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.LV_242)
  const isPaypalPrimerButtonActive = useFeatureIsOn(
    GROWTHBOOK_EXPERIMENT.LV_758,
  )
  const isStaticBannerActive = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.LV_770)
  const isCoachingUpsellTestActive = useFeatureIsOn(
    GROWTHBOOK_EXPERIMENT.LV_938,
  )
  const isHigherPricingRefundActive = useFeatureIsOn(
    GROWTHBOOK_EXPERIMENT.LV_1109,
  )
  const isReTargetingPageMapVariantBActive = useFeatureIsOn(
    GROWTHBOOK_EXPERIMENT.LV_1002,
  )
  const isChinPageSetVariantBActive = useFeatureIsOn(
    GROWTHBOOK_EXPERIMENT.LV_1113,
  )
  const isRefundNormalizedPricingActive = useFeatureIsOn(
    GROWTHBOOK_EXPERIMENT.LV_1171,
  )

  return {
    isPrimerActive,
    areAgeHighPricesActive,
    isPaypalPrimerButtonActive,
    isStaticBannerActive,
    isCoachingUpsellTestActive,
    isHigherPricingRefundActive,
    isReTargetingPageMapVariantBActive,
    isChinPageSetVariantBActive,
    isRefundNormalizedPricingActive,
  }
}
