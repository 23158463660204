import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Button, buttonTheme } from 'storybook-ui'

import { DomainsContainer } from 'components/DomainsContainer'
import { InputWithFloatPlaceholder } from 'components/InputWithFloatPlaceholder'
import { Modal } from 'components/Modal'
import { Spinner } from 'components/Spinner'
import { SvgImage } from 'components/SvgImage'

import { resetErrorAction } from 'root-redux/actions/common'
import { TAppDispatch } from 'root-redux/store/store'

import grayLock from 'assets/images/gray-lock.png'
import redWarningIcon from 'assets/images/sprite/red-warning.svg'

import { StyledEmail as S } from './Email.styles'
import { useEmail } from './hooks/useEmail'

export const EmailVariant3: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()

  const {
    handlePrefilledEmail,
    handleBlur,
    handleFocus,
    handleChange,
    handleSubmit,
    errorText,
    isStatusFetching,
    isEmailValid,
    error,
    isErrorModalShown,
    isAutoCompleteVisible,
    email,
    domainsList,
    setIsErrorModalShown,
  } = useEmail()

  return isStatusFetching ? (
    <Spinner />
  ) : (
    <S.AdaptiveContainer
      title="onboarding.email.title"
      subtitle="onboarding.email.subtitle"
    >
      <form onSubmit={handleSubmit}>
        <S.InputContainerV3>
          <InputWithFloatPlaceholder
            value={email.value}
            data-testid="email-input"
            isValid={isEmailValid}
            labelName={t`onboarding.email.inputPlaceholderV2`}
            hasValidationIcon
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          {errorText && (
            <S.Error>
              <SvgImage svg={redWarningIcon} width={16} />{' '}
              <span>{errorText}</span>
            </S.Error>
          )}
          {isAutoCompleteVisible && (
            <DomainsContainer>
              {domainsList.map((item) => (
                <button
                  type="button"
                  key={item}
                  value={item}
                  onClick={handlePrefilledEmail}
                >
                  {item}
                </button>
              ))}
            </DomainsContainer>
          )}
        </S.InputContainerV3>
        <S.DisclaimerV2>
          <img src={grayLock} alt="" />
          <div>
            <Trans i18nKey="onboarding.email.disclaimerV2" />
          </div>
        </S.DisclaimerV2>
        <S.ButtonContainer>
          <Button
            type="submit"
            disabled={!email.value || !isEmailValid}
            theme={buttonTheme.LUVLY_SECONDARY}
            data-testid="confirm-email-btn"
            maxWidth="488px"
          >
            {t`actions.getMyPlanV2`}
          </Button>
        </S.ButtonContainer>
      </form>
      <Modal
        onClose={() => {
          dispatch(resetErrorAction())
          setIsErrorModalShown(false)
        }}
        isShown={isErrorModalShown}
        error={error}
      />
    </S.AdaptiveContainer>
  )
}
