import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { FeaturesReady } from '@growthbook/growthbook-react'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import {
  DEFAULT_CUSTOMER_REVIEWS_V5,
  DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS_V2,
} from 'components/CustomerReviews/constants'
import { Spinner } from 'components/Spinner'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { startFetching } from 'root-redux/actions/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useAxonPurchaseEvent } from 'hooks/useAxonPurchaseEvent'
import { useVatInfo } from 'hooks/useHasVatInfo'
import { useScrollToTarget } from 'hooks/useScrollToTarget'
import { useTrackPageScrollDepth } from 'hooks/useTrackPageScrollDepth'

import { LongPaywallAppGallery } from 'modules/purchase/components/LongPaywallAppGallery'
import { LongPaywallCustomerReviews } from 'modules/purchase/components/LongPaywallCustomerReviews'
import { LongPaywallShortUserProfile } from 'modules/purchase/components/LongPaywallShortUserProfile'
import { LongPaywallUserBenefits } from 'modules/purchase/components/LongPaywallUserBenefits'
import { LongPaywallUserGoalsGallery } from 'modules/purchase/components/LongPaywallUserGoals'
import { LongPaywallUserTarget } from 'modules/purchase/components/LongPaywallUserTarget'
import { MoneyBackGuarantee } from 'modules/purchase/components/MoneyBackGuarantee'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'
import { SubscriptionsBlockV2 } from 'modules/purchase/components/SubscriptionsBlockV2'
import {
  CURRENCY_SYMBOLS,
  PURCHASE_DISCLAIMER,
  PURCHASE_DISCLAIMER_WITHOUT_TAXES,
  SUBSCRIPTION_DISCOUNTS,
  TEN_MINUTES,
} from 'modules/purchase/constants'
import {
  useDefaultSubscription,
  usePricesStatus,
  usePurchaseAnalytics,
  usePurchaseStore,
  useTimerForTarget,
} from 'modules/purchase/hooks'
import { useGetSubscriptionsList } from 'modules/purchase/hooks/useGetSubscriptionsList'
import { usePriceWithVatAdjustment } from 'modules/purchase/hooks/usePriceWithVatAdjustment'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'
import { selectSubscription } from 'modules/purchase/redux/selects/common'

import { CDN_FOLDER_LINK, Locale, ScreenName } from 'root-constants'

import { useScrollEvent } from '../../hooks'
import { CheckoutList } from '../CheckoutList'
import { StyledPurchaseNY as S } from './PurchaseNY.styles'
import {
  LONG_PAYWALL_TRACKED_BREAKPOINTS,
  NUMBER_ONE_APP_IMAGE_NY_PATH,
} from './constants'

export const PurchaseNY: React.FC = () => {
  const [isCheckoutShown, setIsCheckoutShown] = useState<boolean>(false)

  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const topSubscriptionsBlock = useRef<HTMLDivElement | null>(null)
  const bottomSubscriptionsBlock = useRef<HTMLDivElement | null>(null)
  const timerElementRef = useRef<HTMLParagraphElement | null>(null)
  const topDuplicateTimerElementRef = useRef<HTMLSpanElement | null>(null)
  const bottomDuplicateTimerElementRef = useRef<HTMLSpanElement | null>(null)

  const subscription = useSelector(selectSubscription)

  const { arePricesReady } = usePricesStatus()
  const hasVatInfo = useVatInfo()
  const { language, periodQuantity, price, currency } = usePurchaseStore()

  const { scrollToTarget } = useScrollToTarget()

  useDefaultSubscription()

  const { scrollEvent } = useScrollEvent(ScreenName.PURCHASE)

  const { sendGeneralPurchaseEvents } = usePurchaseAnalytics(
    ScreenName.PURCHASE,
  )

  const isEnLocale = useMemo(() => language === Locale.ENGLISH, [language])

  const localizationKeys = useMemo(() => {
    if (hasVatInfo) {
      return PURCHASE_DISCLAIMER_WITHOUT_TAXES
    }
    return PURCHASE_DISCLAIMER
  }, [hasVatInfo])

  const disclaimerTextPath = useMemo(() => {
    if (!periodQuantity) return ''

    return localizationKeys[periodQuantity]
  }, [localizationKeys, periodQuantity])

  useAxonPurchaseEvent()

  useGetSubscriptionsList()

  useTimerForTarget(timerElementRef, TEN_MINUTES.IN_SECONDS, [
    topDuplicateTimerElementRef,
    bottomDuplicateTimerElementRef,
  ])

  useTrackPageScrollDepth({
    trackedBreakpoints: LONG_PAYWALL_TRACKED_BREAKPOINTS,
    eventCallback: scrollEvent,
    preventSendingEvents: isCheckoutShown,
  })

  const { getPriceWithVatAdjustment } = usePriceWithVatAdjustment()

  const handleShowCheckout = useCallback(async () => {
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))

    setIsCheckoutShown(true)

    sendGeneralPurchaseEvents(false)
  }, [sendGeneralPurchaseEvents, dispatch])

  return arePricesReady ? (
    <>
      {!isCheckoutShown && (
        <S.Wrapper>
          <S.TimerContainer>
            <S.TimerContent>
              <S.TimerText>
                <p>
                  {subscription?.mainPrices.oldPrices.percentOfDiscount ||
                    SUBSCRIPTION_DISCOUNTS.DEFAULT}
                  % {t('purchase7.timer')}
                </p>
                <S.CountdownTime ref={timerElementRef}>
                  {!timerElementRef?.current?.hasAttribute('data-value')
                    ? TEN_MINUTES.AS_STRING
                    : null}
                </S.CountdownTime>
              </S.TimerText>
              <S.TopButton
                onClick={() => scrollToTarget(topSubscriptionsBlock)}
              >
                {t('actions.getMyPlan')}
              </S.TopButton>
            </S.TimerContent>
          </S.TimerContainer>
          <LongPaywallUserTarget />
          <S.ContainerWrapper isEnLocaleStyle={isEnLocale}>
            <LongPaywallShortUserProfile
              title="purchase9.title"
              timerRef={topDuplicateTimerElementRef}
            />
            <S.SubscriptionsContainer ref={topSubscriptionsBlock}>
              <SubscriptionsBlockV2 />
            </S.SubscriptionsContainer>
            <S.ButtonContainer>
              <Button data-testid="start-plan-btn" onClick={handleShowCheckout}>
                {t`actions.getMyPlan`}
              </Button>
            </S.ButtonContainer>
            <S.Disclaimer>
              <Trans
                i18nKey={disclaimerTextPath}
                values={{
                  price: getPriceWithVatAdjustment(price),
                  currency: CURRENCY_SYMBOLS[currency],
                  context: currency,
                }}
                components={[<br />]}
              />{' '}
              <TermsOfUseLink />.
            </S.Disclaimer>
          </S.ContainerWrapper>
          <LongPaywallAppGallery />
          <S.NumberOneImage
            src={`${CDN_FOLDER_LINK}${NUMBER_ONE_APP_IMAGE_NY_PATH}_${language}.png`}
            alt="number-one-app"
          />
          <Container>
            <Button onClick={() => scrollToTarget(bottomSubscriptionsBlock)}>
              {t('actions.getMyPlan')}
            </Button>
            <LongPaywallUserBenefits />
          </Container>
          <LongPaywallCustomerReviews
            title="purchase9.reviewsSubtitle"
            customerReviews={DEFAULT_CUSTOMER_REVIEWS_V5}
            additionalCustomerReviews={DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS_V2}
          />
          <Container>
            <LongPaywallUserGoalsGallery />
          </Container>
          <Container>
            <LongPaywallShortUserProfile
              title="purchase9.visibleResultSubtitle"
              timerRef={bottomDuplicateTimerElementRef}
            />
            <S.SubscriptionsContainer ref={bottomSubscriptionsBlock}>
              <SubscriptionsBlockV2 />
              <S.ButtonContainer>
                <Button onClick={handleShowCheckout}>
                  {t`actions.getMyPlan`}
                </Button>
              </S.ButtonContainer>
              <S.Disclaimer>
                <Trans
                  i18nKey={disclaimerTextPath}
                  values={{
                    price: getPriceWithVatAdjustment(price),
                    currency: CURRENCY_SYMBOLS[currency],
                    context: currency,
                  }}
                  components={[<br />]}
                />{' '}
                <TermsOfUseLink />.
              </S.Disclaimer>
            </S.SubscriptionsContainer>
            <MoneyBackGuarantee />
            <SecurityInfo />
          </Container>
        </S.Wrapper>
      )}
      {isCheckoutShown && (
        <FeaturesReady fallback={<Spinner />}>
          <CheckoutList
            setIsCheckoutShown={setIsCheckoutShown}
            isCancelOffer={false}
          />
        </FeaturesReady>
      )}
    </>
  ) : null
}
