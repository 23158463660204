import styled from 'styled-components'

import { CustomBackgroundStyles, customBackgroundStyles } from 'common-styles'
import { Color } from 'root-constants'

export const StyledEmbracingAge = {
  Root: styled.div<{ background: CustomBackgroundStyles }>`
    ${customBackgroundStyles}
  `,
  Description: styled.p`
    color: ${Color.BLUE_DARK};
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: -16px 0 24px;
  `,
  BeforeAfterImage: styled.img`
    width: 100%;
    height: auto;
    margin-bottom: 16px;
    box-shadow: 0px 8px 20px 0px #e8e8e8;
    border-radius: 16px;
    aspect-ratio: 343/212;
  `,
}
