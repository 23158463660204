import React, { useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import {
  selectAnswers,
  selectLanguage,
  selectUserGoal,
} from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useIsCurrentRoute } from 'hooks/useIsCurrentRoute'

import { PageId } from 'page-constants'
import {
  CDN_FOLDER_LINK,
  Cohort,
  ES_IMAGES_COHORTS,
  Gender,
  I18N_CONTEXT_COHORT_MAP,
  Locale,
  SKIN_COLOR_OPTION_VALUES,
  UserGoal,
} from 'root-constants'

import {
  LevelIndication,
  StyledLongPaywallUserTarget as S,
} from './LongPaywallUserTarget.styles'
import {
  ANIMATION_DOUBLE_CHIN_FEMALE,
  ANIMATION_DOUBLE_CHIN_FEMALE_2,
  ANIMATION_DOUBLE_CHIN_MALE,
  ANIMATION_DOUBLE_CHIN_MALE_2,
  ANIMATION_NEW_YEAR,
  ANIMATION_PATH,
  LEVEL_INDICATION_BLANK,
  USER_GOAL_CONFIG_MAP,
} from './constants'

export const LongPaywallUserTarget: React.FC = () => {
  const { t } = useTranslation()
  const userGoal = useSelector(selectUserGoal)
  const language = useSelector(selectLanguage)
  const userAnswers = useSelector(selectAnswers)

  const {
    isLuvlyChinCohort,
    isLuvlyGeneralCohort,
    isLuvlyEmailNYCohort,
    isSeniorCohort,
  } = useActiveCohortIdentifier()
  const cohortToUse = useCohortToUse()

  const animationContainerRef = useRef<HTMLDivElement | null>(null)

  const isCancelOfferRoute = useIsCurrentRoute(
    PageId.PURCHASE_INTRO_CANCEL_OFFER,
  )

  const animationPath = useMemo(() => {
    const isMale = userAnswers?.[PageId.GENDER] === Gender.MALE
    const isDarkSkin =
      userAnswers?.[PageId.IDENTIFY_SKIN_COLOR] ===
      SKIN_COLOR_OPTION_VALUES.BROWN_OR_BLACK_BROWN

    if (isLuvlyChinCohort && isDarkSkin) {
      return `${CDN_FOLDER_LINK}${
        isMale ? ANIMATION_DOUBLE_CHIN_MALE_2 : ANIMATION_DOUBLE_CHIN_FEMALE_2
      }.json`
    }

    if (isLuvlyChinCohort) {
      return `${CDN_FOLDER_LINK}${
        isMale ? ANIMATION_DOUBLE_CHIN_MALE : ANIMATION_DOUBLE_CHIN_FEMALE
      }.json`
    }

    if (isLuvlyGeneralCohort && isMale) {
      return `${CDN_FOLDER_LINK}${ANIMATION_PATH}_male.json`
    }

    const isEsLocale = language === Locale.SPANISH
    const isEsTargeted =
      isEsLocale &&
      [
        ...ES_IMAGES_COHORTS,
        Cohort.LUVLY_INTRO_SALE,
        Cohort.LUVLY_INTRO_FAST,
      ].includes(cohortToUse)

    if (isEsTargeted) {
      return `${CDN_FOLDER_LINK}${ANIMATION_PATH}_${language}.json`
    }

    if (isLuvlyEmailNYCohort) {
      return `${CDN_FOLDER_LINK}${ANIMATION_NEW_YEAR}.json`
    }

    return `${CDN_FOLDER_LINK}${ANIMATION_PATH}_en.json`
  }, [
    userAnswers,
    isLuvlyChinCohort,
    isLuvlyGeneralCohort,
    language,
    cohortToUse,
    isLuvlyEmailNYCohort,
  ])

  useEffect(() => {
    if (animationContainerRef && animationContainerRef.current) {
      lottie.loadAnimation({
        container: animationContainerRef.current,
        path: animationPath,
        loop: true,
      })
    }

    return () => lottie.destroy()
  }, [animationPath])

  const userGoalConfig =
    USER_GOAL_CONFIG_MAP[userGoal || UserGoal.WRINKLE_FREE_SKIN]

  const renderGoalLeftLabel = () => {
    if (isLuvlyEmailNYCohort) {
      return t('purchase9.goalLeftLabel')
    }

    return t('purchase7.facialCondition.nowLabel')
  }

  const renderGoalRightLabel = () => {
    if (isLuvlyEmailNYCohort) {
      return t('purchase9.goalRightLabel')
    }

    return t('purchase7.facialCondition.goalLabel')
  }

  const renderGoal = () => {
    if (isLuvlyChinCohort) {
      return t('purchase8.facialCondition.doubleChinGoal')
    }

    return (
      <Trans
        i18nKey={userGoalConfig.goalText}
        context={I18N_CONTEXT_COHORT_MAP[cohortToUse]}
      />
    )
  }

  return (
    <S.Root>
      {!isSeniorCohort ? (
        <S.AnimationContainer
          data-testid="paywall-target-img"
          ref={animationContainerRef}
          isCancelOfferRoute={isCancelOfferRoute}
        />
      ) : (
        <S.ImageContainer />
      )}
      <S.Wrapper>
        <S.FacialCondition>
          <S.Subtitle>{renderGoalLeftLabel()}</S.Subtitle>
          <S.LevelContent>
            <S.UserGoal>{renderGoal()}</S.UserGoal>
            <S.LevelIndicationWrapper>
              {LEVEL_INDICATION_BLANK.map((item) => (
                <S.LevelIndicationUnit
                  key={item}
                  type={
                    userGoalConfig.currentLevelIndicator >= item
                      ? LevelIndication.CURRENT
                      : LevelIndication.DEFAULT
                  }
                />
              ))}
            </S.LevelIndicationWrapper>
          </S.LevelContent>
        </S.FacialCondition>
        <S.FacialCondition>
          <S.Subtitle>{renderGoalRightLabel()}</S.Subtitle>
          <S.LevelContent>
            <S.UserGoal>{renderGoal()}</S.UserGoal>
            <S.LevelIndicationWrapper>
              {LEVEL_INDICATION_BLANK.map((item) => (
                <S.LevelIndicationUnit
                  key={item}
                  type={
                    userGoalConfig.goalLevelIndicator >= item
                      ? LevelIndication.GOAL
                      : LevelIndication.DEFAULT
                  }
                />
              ))}
            </S.LevelIndicationWrapper>
          </S.LevelContent>
        </S.FacialCondition>
      </S.Wrapper>
    </S.Root>
  )
}
