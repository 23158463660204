import { Cohort, UserGoal } from 'root-constants'

export const QUESTION = 'Leave no chance for the double chin with Luvly'

export const GRAPH_TITLES_MAP = {
  [UserGoal.TIGHTEN_SKIN]: 'onboarding.doubleChinReduction.graphTitleSagging',
  [UserGoal.REDUCE_FACE_FAT]:
    'onboarding.doubleChinReduction.graphTitleFaceFat',
  [UserGoal.WRINKLE_FREE_SKIN]:
    'onboarding.doubleChinReduction.graphTitleWrinkles',
}

export const QUESTIONS_MAP = {
  [Cohort.LUVLY_CHIN]: 'Leave no chance for the double chin with Luvly',
  [Cohort.LUVLY_GENERAL]: 'Leave no chance for insecurities',
}
