import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, buttonTheme } from 'storybook-ui'

import { Container } from 'components/Container'
import { StickyButtonContainer } from 'components/StickyButtonContainer'
import { SvgImage } from 'components/SvgImage'

import {
  selectAnswers,
  selectLanguage,
  selectUserAge,
} from 'root-redux/selects/common'

import { getButtonTapEventData } from 'helpers/getButtonTapEventData'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import aim from 'assets/images/aim-icon.png'
import harvardIcon from 'assets/images/harvard-logo-shadow.png'
import joinLuvlyFemale from 'assets/images/join-luvly-female.png'
import joinLuvlyMale from 'assets/images/join-luvly-male.png'
import sandClock from 'assets/images/sand-clock.png'
import skinTypeIcon from 'assets/images/skin-type-icon.png'
import starSvg from 'assets/images/sprite/star.svg'
import videoLessons from 'assets/images/video-lessons-icon.png'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { CDN_FOLDER_LINK, Gender } from 'root-constants'
import { SKIN_TYPE_ANSWERS_MAP } from 'shared-options'

import { AGE_OPTION_VALUES_MAP } from '../Age/constants'
import { StyledPersonalizedPlan as S } from './PersonalizedPlan.styles'
import {
  GENDER_IMAGE,
  PAYWALL_HERO_MAN_IMAGE_PATH,
  PAYWALL_HERO_WOMAN_IMAGE_PATH,
  USER_STORIES,
  VISIBLE_RESULTS_MAN_IMAGE_PATH,
  VISIBLE_RESULTS_WOMAN_IMAGE_PATH,
} from './constants'

export const PersonalizedPlan: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const userAge = useSelector(selectUserAge)
  const language = useSelector(selectLanguage)

  const userGender = userAnswers?.[PageId.GENDER]
  const skinTypeAnswer = userAnswers?.[PageId.SKIN_TYPE]

  useEffect(() => {
    eventLogger.logWellhubPlanPageShown()
  }, [])

  const getImageLink = (path: string) => {
    return `${CDN_FOLDER_LINK}${path}_${language}.png`
  }

  const goToNextPage = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      const { buttonOrder } = getButtonTapEventData(event)
      eventLogger.logWellhubPlanButtonTap(buttonOrder)

      window.axon && window.axon('track', 'page_view')
      goTo(nextPagePath)
    },
    [nextPagePath],
  )

  return (
    <S.Root>
      <S.Hero>
        <img
          src={getImageLink(
            userGender === Gender.MALE
              ? PAYWALL_HERO_MAN_IMAGE_PATH
              : PAYWALL_HERO_WOMAN_IMAGE_PATH,
          )}
          alt=""
        />
      </S.Hero>
      <Container>
        <S.Title>{t('onboarding.personalizedPlan.title')}</S.Title>
        <S.Subtitle>{t('onboarding.personalizedPlan.subtitle')}</S.Subtitle>
        <S.UserInfo>
          <S.UserInfoCard>
            <img src={sandClock} alt="" />
            <S.UserAnswer>
              {AGE_OPTION_VALUES_MAP[userAge] || '18-29'}
            </S.UserAnswer>
            <span>{t('onboarding.personalizedPlan.userAge')}</span>
          </S.UserInfoCard>
          <S.UserInfoCard>
            <img src={skinTypeIcon} alt="" />
            <S.UserAnswer>
              {t(SKIN_TYPE_ANSWERS_MAP[skinTypeAnswer])}
            </S.UserAnswer>
            <span>{t('onboarding.personalizedPlan.userSkinType')}</span>
          </S.UserInfoCard>
          <S.UserInfoCard>
            <img src={GENDER_IMAGE[userGender]} alt="" />
            <S.UserAnswer>
              {t(`onboarding.gender.${userGender || Gender.FEMALE}`)}
            </S.UserAnswer>
            <span>{t('onboarding.personalizedPlan.userGender')}</span>
          </S.UserInfoCard>
        </S.UserInfo>
        <StickyButtonContainer centerMode customBackground="transparent">
          <Button
            theme={buttonTheme.LUVLY_SECONDARY}
            data-order={0}
            onClick={goToNextPage}
          >
            {t('actions.getStarted')}
          </Button>
        </StickyButtonContainer>
        <S.SecondaryTitle>
          {t('onboarding.personalizedPlan.resultsTitle')}
        </S.SecondaryTitle>
        <S.ResultsImage
          src={getImageLink(
            userGender === Gender.MALE
              ? VISIBLE_RESULTS_MAN_IMAGE_PATH
              : VISIBLE_RESULTS_WOMAN_IMAGE_PATH,
          )}
          alt=""
        />
        <S.List>
          <li>
            <S.Duration>
              {t('onboarding.personalizedPlan.resultsItem1')}
            </S.Duration>
            <S.Description>
              {t('onboarding.personalizedPlan.resultsText1')}
            </S.Description>
          </li>
          <li>
            <S.Duration>
              {t('onboarding.personalizedPlan.resultsItem2')}
            </S.Duration>
            <S.Description>
              {t('onboarding.personalizedPlan.resultsText2')}
            </S.Description>
          </li>
          <li>
            <S.Duration>
              {t('onboarding.personalizedPlan.resultsItem3')}
            </S.Duration>
            <S.Description>
              {t('onboarding.personalizedPlan.resultsText3')}
            </S.Description>
          </li>
        </S.List>
        <S.SecondaryTitle>
          {t('onboarding.personalizedPlan.unlockTitle')}
        </S.SecondaryTitle>
        <S.UnlockList>
          <S.UnlockOption>
            <img src={harvardIcon} alt="" />
            <p>{t('onboarding.personalizedPlan.unlockOption1')}</p>
          </S.UnlockOption>
          <S.UnlockOption>
            <img src={videoLessons} alt="" />
            <p>{t('onboarding.personalizedPlan.unlockOption2')}</p>
          </S.UnlockOption>
          <S.UnlockOption>
            <img src={aim} alt="" />
            <p>{t('onboarding.personalizedPlan.unlockOption3')}</p>
          </S.UnlockOption>
        </S.UnlockList>
        <S.JoinLuvlyBanner gender={userGender}>
          <S.JoinLuvlyImage
            src={userGender === Gender.MALE ? joinLuvlyMale : joinLuvlyFemale}
            alt=""
          />
          <S.PseudoButton>
            {t('onboarding.personalizedPlan.joinHappyUsers')}
          </S.PseudoButton>
        </S.JoinLuvlyBanner>
        <S.SecondaryTitle>
          {t('onboarding.personalizedPlan.reviews.title')}
        </S.SecondaryTitle>
        <S.Reviews>
          {USER_STORIES.map(({ review, name }) => (
            <S.Review key={name}>
              <S.Rating>
                <SvgImage svg={starSvg} width={16} />
                <SvgImage svg={starSvg} width={16} />
                <SvgImage svg={starSvg} width={16} />
                <SvgImage svg={starSvg} width={16} />
                <SvgImage svg={starSvg} width={16} />
              </S.Rating>
              <S.ReviewText>{t(review)}</S.ReviewText>
              <S.Reviewer>{t(name)}</S.Reviewer>
            </S.Review>
          ))}
        </S.Reviews>
        <Button
          theme={buttonTheme.LUVLY_SECONDARY}
          margin="0 auto 32px auto"
          data-order={1}
          onClick={goToNextPage}
        >
          {t('actions.getStarted')}
        </Button>
      </Container>
    </S.Root>
  )
}
