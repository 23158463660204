import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button, buttonTheme } from 'storybook-ui'

import { AdaptiveContainer } from 'components/AdaptiveContainer'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledWhatIsFaceYoga2 as S } from './WhatIsFaceYoga2.styles'
import { QUESTION } from './constants'

export const WhatIsFaceYoga2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(''),
    [handleContinue],
  )

  return (
    <AdaptiveContainer
      title="onboarding.whatIsFaceYoga.question"
      subtitle="onboarding.whatIsFaceYoga.description"
    >
      <S.ListTitle>{t`onboarding.whatIsFaceYoga.listTitle`}</S.ListTitle>
      <ul>
        <S.ListItem>
          <Trans i18nKey="onboarding.whatIsFaceYoga.targetYourFace" />
        </S.ListItem>
        <S.ListItem>
          <Trans i18nKey="onboarding.whatIsFaceYoga.massage" />
          <ul>
            <S.SubListItem>
              {t`onboarding.whatIsFaceYoga.boostCirculation`}
            </S.SubListItem>
            <S.SubListItem>
              {t`onboarding.whatIsFaceYoga.improveDrainage`}
            </S.SubListItem>
            <S.SubListItem>
              {t`onboarding.whatIsFaceYoga.releaseTension`}
            </S.SubListItem>
          </ul>
        </S.ListItem>
        <S.ListItem>
          <Trans i18nKey="onboarding.whatIsFaceYoga.acupressureTechniques" />
          <ul>
            <S.SubListItem>
              {t`onboarding.whatIsFaceYoga.preventingHeadaches`}
            </S.SubListItem>
            <S.SubListItem>
              {t`onboarding.whatIsFaceYoga.releasingSinuses`}
            </S.SubListItem>
            <S.SubListItem>
              {t`onboarding.whatIsFaceYoga.sleepQuality`}
            </S.SubListItem>
          </ul>
        </S.ListItem>
      </ul>
      <StickyButtonContainer centerMode>
        <Button
          onClick={handleNextClick}
          theme={buttonTheme.LUVLY_SECONDARY}
          maxWidth="488px"
        >
          {t`actions.gotIt`}
        </Button>
      </StickyButtonContainer>
    </AdaptiveContainer>
  )
}
