/* eslint-disable max-lines */
import { paymentApi } from 'api'
import i18n from 'i18next'

import {
  setErrorAction,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import {
  getUserStatusAction,
  sendUserConfigAction,
} from 'root-redux/actions/user'
import { selectCurrentVariantCohort } from 'root-redux/selects/common'
import {
  selectActiveUpsellPages,
  selectUUID,
  selectUserPaymentMethod,
  selectUserPaymentSystem,
} from 'root-redux/selects/user'

import { PaymentMethod, PaymentSystem } from 'modules/purchase/constants'
import { getStripePurchaseFailedEventParams } from 'modules/purchase/helpers/getStripePurchaseFailedEventParams'
import { getStripePurchaseStartedEventParams } from 'modules/purchase/helpers/getStripePurchaseStartedEventParams'
import { getStripePurchaseSuccessEventParams } from 'modules/purchase/helpers/getStripePurchaseSuccessEventParams'
import { getPaymentFailedError } from 'modules/purchase/helpers/rootHelpers'
import {
  selectCurrency,
  selectInAppId,
  selectProductId,
  selectSubscriptionFullPrice,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/purchase/redux/selects/common'

import { IAppState, TAppDispatchThunk } from 'models/store.model'

import { eventLogger } from 'services/eventLogger.service'

import { PageId } from 'page-constants'
import {
  Cohort,
  ScreenName,
  UPSELL_PRODUCT_EVENT_NAMES_MAP,
  UpsellProduct,
} from 'root-constants'

import { PURCHASE } from './common'

const MODULE_NAME = 'UPSELL'

export const MAKE_UPSELL = `${MODULE_NAME}/MAKE_UPSELL`
export const CHARGE_WELLNESS_BUNDLE = `${MODULE_NAME}/CHARGE_WELLNESS_BUNDLE`
export const APPLY_SECONDARY_SUBSCRIPTION = `${MODULE_NAME}/APPLY_SECONDARY_SUBSCRIPTION`

export const makeUpsellAction =
  (screenName: ScreenName): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)
    const currentPrice = selectSubscriptionFullPrice(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const productId = selectProductId(state) as UpsellProduct
    const paymentSystem = selectUserPaymentSystem(state)
    const paymentMethod = selectUserPaymentMethod(state) as PaymentMethod
    const cohort = selectCurrentVariantCohort(state) as Cohort
    const planId = selectInAppId(state)
    const dynamicUpsellPages = selectActiveUpsellPages(state)

    dispatch(startFetching(MAKE_UPSELL))

    try {
      const commonPurchaseStartedParams =
        getStripePurchaseStartedEventParams(state)
      const commonPurchaseSuccessParams =
        getStripePurchaseSuccessEventParams(state)
      const commonPurchaseFailedParams =
        getStripePurchaseFailedEventParams(state)

      eventLogger.logPurchaseStarted({
        ...commonPurchaseStartedParams,
        paymentMethod,
        paymentSystem,
        screenName,
        productName: productId,
      })

      const upsellResponse =
        paymentSystem === PaymentSystem.PRIMER
          ? await paymentApi.makePrimerUpsell({
              uuid,
              planId: Number(planId),
              productId,
              cohort,
            })
          : await paymentApi.makeUpsell({
              uuid,
              planId: Number(planId),
              productId,
              cohort,
            })

      const restUpsellPages = dynamicUpsellPages.filter(
        (pageId) =>
          pageId !== PageId.COACHING_PAYWALL &&
          pageId !== PageId.COACHING_CANCEL_PAYWALL &&
          pageId !== PageId.UPSELL_PAYWALL,
      )

      await dispatch(
        sendUserConfigAction({
          dynamic_upsell_pages: restUpsellPages,
        }),
      )

      if (upsellResponse.success && upsellResponse.data) {
        eventLogger.logPurchaseCompleted({
          ...commonPurchaseSuccessParams,
          productId: upsellResponse.data.subscription.product_id,
          priceDetails: {
            price: currentPrice,
            trial: !!trialPeriodDays,
            currency: commonPurchaseSuccessParams.currency,
          },
          paymentMethod,
          screenName,
          paymentSystem,
          productName: productId,
        })

        dispatch(
          sendUserConfigAction({
            upsell_product: `${
              UPSELL_PRODUCT_EVENT_NAMES_MAP[productId as UpsellProduct]
            }, ${currentPrice}`,
          }),
        )

        dispatch(getUserStatusAction(uuid))
        dispatch(stopFetching(MAKE_UPSELL))
        return
      }

      const errorText = i18n.t(
        getPaymentFailedError(upsellResponse.data?.decline_code),
      )

      eventLogger.logPurchaseFailed({
        ...commonPurchaseFailedParams,
        error: {
          type: upsellResponse.data?.error,
          code: upsellResponse.data?.decline_code,
          description: errorText,
        },
        paymentMethod,
        screenName,
        paymentSystem,
        productName: productId,
      })

      dispatch(setErrorAction(errorText))
      dispatch(getUserStatusAction(uuid))
      dispatch(stopFetching(MAKE_UPSELL))
    } catch (error: any) {
      dispatch(setErrorAction(error.toString()))
      dispatch(stopFetching(MAKE_UPSELL))
    }
  }

export const makeWellnessBundleUpsellAction =
  (screenName: ScreenName): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)
    const currentPrice = selectSubscriptionFullPrice(state)
    const trialPeriodDays = selectSubscriptionTrialPeriodDays(state)
    const productId = selectProductId(state) as UpsellProduct
    const paymentSystem = selectUserPaymentSystem(state)
    const cohort = selectCurrentVariantCohort(state) as Cohort
    const planId = selectInAppId(state)

    dispatch(startFetching(CHARGE_WELLNESS_BUNDLE))

    try {
      const commonPurchaseStartedParams =
        getStripePurchaseStartedEventParams(state)
      const commonPurchaseSuccessParams =
        getStripePurchaseSuccessEventParams(state)
      const commonPurchaseFailedParams =
        getStripePurchaseFailedEventParams(state)

      eventLogger.logPurchaseStarted({
        ...commonPurchaseStartedParams,
        paymentMethod: PaymentMethod.CREDIT_CARD,
        paymentSystem,
        screenName,
        productName: productId,
      })

      const upsellResponse =
        paymentSystem === PaymentSystem.PRIMER
          ? await paymentApi.makePrimerUpsell({
              uuid,
              planId: Number(planId),
              productId,
              cohort,
            })
          : await paymentApi.makeUpsell({
              uuid,
              planId: Number(planId),
              productId,
              cohort,
            })

      if (upsellResponse.success && upsellResponse.data) {
        eventLogger.logPurchaseCompleted({
          ...commonPurchaseSuccessParams,
          productId: upsellResponse.data.subscription.product_id,
          priceDetails: {
            price: currentPrice,
            trial: !!trialPeriodDays,
            currency: commonPurchaseSuccessParams.currency,
          },
          paymentMethod: PaymentMethod.CREDIT_CARD,
          screenName,
          paymentSystem,
          productName: productId,
        })

        dispatch(getUserStatusAction(uuid))
        dispatch(stopFetching(CHARGE_WELLNESS_BUNDLE))
        return
      }

      dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))

      eventLogger.logPurchaseFailed({
        ...commonPurchaseFailedParams,
        error: { type: upsellResponse.data },
        paymentMethod: PaymentMethod.CREDIT_CARD,
        screenName,
        paymentSystem,
        productName: productId,
      })

      dispatch(getUserStatusAction(uuid))
      dispatch(stopFetching(CHARGE_WELLNESS_BUNDLE))
    } catch (error: any) {
      dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
      dispatch(stopFetching(CHARGE_WELLNESS_BUNDLE))
    }
  }

export const applySecondarySubscriptionAction =
  (screenName: ScreenName): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)
    const fullPrice = selectSubscriptionFullPrice(state)
    const trialPrice = selectSubscriptionTrialPeriodPrice(state)
    const productId = selectProductId(state) as UpsellProduct
    const paymentSystem = selectUserPaymentSystem(state)
    const paymentMethod = selectUserPaymentMethod(state) as PaymentMethod
    const planId = selectInAppId(state)
    const dynamicUpsellPages = selectActiveUpsellPages(state)

    dispatch(startFetching(APPLY_SECONDARY_SUBSCRIPTION))
    dispatch(startFetching(PURCHASE))

    try {
      const commonPurchaseStartedParams =
        getStripePurchaseStartedEventParams(state)
      const commonPurchaseSuccessParams =
        getStripePurchaseSuccessEventParams(state)
      const commonPurchaseFailedParams =
        getStripePurchaseFailedEventParams(state)

      eventLogger.logPurchaseStarted({
        ...commonPurchaseStartedParams,
        paymentMethod,
        paymentSystem,
        screenName,
        productName: productId,
      })

      const response = await paymentApi.applySecondarySubscription({
        uuid,
        planId: Number(planId),
      })

      const restUpsellPages = dynamicUpsellPages.filter(
        (pageId) =>
          pageId !== PageId.COACHING_PAYWALL &&
          pageId !== PageId.COACHING_CANCEL_PAYWALL,
      )

      await dispatch(
        sendUserConfigAction({
          dynamic_upsell_pages: restUpsellPages,
        }),
      )

      if (response.status === 201 && !!response.success) {
        eventLogger.logPurchaseCompleted({
          ...commonPurchaseSuccessParams,
          productId,
          priceDetails: {
            price: trialPrice || fullPrice,
            currency: commonPurchaseSuccessParams.currency,
          },
          paymentMethod,
          screenName,
          paymentSystem,
          productName: productId,
        })

        dispatch(
          sendUserConfigAction({
            luvly_coaching: trialPrice || fullPrice,
          }),
        )

        dispatch(stopFetching(APPLY_SECONDARY_SUBSCRIPTION))
        dispatch(stopFetching(PURCHASE))

        return
      }

      const errorText = i18n.t(
        getPaymentFailedError(response.data?.decline_code),
      )

      eventLogger.logPurchaseFailed({
        ...commonPurchaseFailedParams,
        error: {
          type: response.data?.error,
          code: response.data?.decline_code,
          description: errorText,
        },
        paymentMethod,
        screenName,
        paymentSystem,
        productName: productId,
      })

      dispatch(setErrorAction(errorText))

      dispatch(stopFetching(APPLY_SECONDARY_SUBSCRIPTION))
      dispatch(stopFetching(PURCHASE))
    } catch (error: any) {
      dispatch(setErrorAction(error.toString()))
      dispatch(stopFetching(APPLY_SECONDARY_SUBSCRIPTION))
      dispatch(stopFetching(PURCHASE))
    }
  }

export const makeGiftCardUpsellAction =
  (): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)
    const fullPrice = selectSubscriptionFullPrice(state)
    const productId = selectProductId(state) as UpsellProduct
    const paymentMethod = selectUserPaymentMethod(state) as PaymentMethod
    const planId = selectInAppId(state)
    const currency = selectCurrency(state)

    dispatch(startFetching(MAKE_UPSELL))

    try {
      eventLogger.logInAppPurchaseStarted({
        price: fullPrice,
        currency,
        productId,
        screenName: ScreenName.UPSELL_GIFT_CARD,
        paymentMethod,
      })

      const response = await paymentApi.makeGiftCardUpsell({
        uuid,
        planId: Number(planId),
      })

      if (response.status === 200 && !!response.success) {
        await dispatch(
          sendUserConfigAction({
            gift_promo_code: response.data.code,
          }),
        )

        eventLogger.logInAppPurchaseCompleted({
          price: fullPrice,
          currency,
          productId,
          screenName: ScreenName.UPSELL_GIFT_CARD,
          paymentMethod,
        })

        dispatch(stopFetching(MAKE_UPSELL))

        return
      }

      const errorText = i18n.t(
        getPaymentFailedError(response.data?.decline_code),
      )

      eventLogger.logInAppPurchaseFailed({
        error: {
          type: response.data?.error,
          code: response.data?.decline_code,
          description: errorText,
        },
        price: fullPrice,
        currency,
        paymentMethod,
        screenName: ScreenName.UPSELL_GIFT_CARD,
        productId,
      })

      dispatch(setErrorAction(errorText))

      dispatch(stopFetching(MAKE_UPSELL))
    } catch (error: any) {
      dispatch(setErrorAction(error.toString()))
      dispatch(stopFetching(MAKE_UPSELL))
    }
  }
