import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'
import { Option } from 'components/Option'
import { PageTitle } from 'components/PageTitle'
import { PageTitleDescription } from 'components/PageTitleDescription'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { goBack } from 'browser-history'
import { OptionType } from 'root-constants'

import { StyledVisitToCosmetologist as S } from './VisitToCosmetologist.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const VisitToCosmetologist: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answer, setAnswer] = useState<string>('')
  const { isAnswersDisabled } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue(answer)

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      setAnswer(value)
    },
  }

  return (
    <div>
      <S.TitleContainer>
        <PageTitle>{t('onboarding.visitToCosmetologist.question')}</PageTitle>
      </S.TitleContainer>
      <Container>
        <S.DescriptionContainer>
          <PageTitleDescription>
            <Trans
              i18nKey="onboarding.visitToCosmetologist.description"
              components={[<br />]}
            />
          </PageTitleDescription>
        </S.DescriptionContainer>
        <S.OptionsContainer>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.ONCE_A_MONTH}
          >
            <AnswerButton>
              <Trans i18nKey="onboarding.visitToCosmetologist.oncePerMonth" />
            </AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.ONCE_A_SEVERAL_MONTH}
          >
            <AnswerButton>{t`onboarding.visitToCosmetologist.onceInSeveralMonth`}</AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.ONCE_A_YEAR}
          >
            <AnswerButton>{t`onboarding.visitToCosmetologist.oncePerYear`}</AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.NEVER}
          >
            <AnswerButton>{t`onboarding.visitToCosmetologist.never`}</AnswerButton>
          </Option>
        </S.OptionsContainer>
      </Container>
      <NavigationButtons
        onBackClick={goBack}
        onNextClick={handleNextClick}
        disabled={!answer}
      />
    </div>
  )
}
