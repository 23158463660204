import React, { useMemo } from 'react'

import { ThemeProvider } from 'styled-components'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'

import { CORAL_THEME, DEFAULT_THEME } from '../../styles/themes/common'
import {
  PURCHASE_VARIANT_7_THEME,
  PURCHASE_VARIANT_8_THEME,
} from '../../styles/themes/paywall'

type TProps = {
  children: React.ReactNode
}

export const Theme: React.FC<TProps> = ({ children }) => {
  const { isLuvlyChinCohort } = useActiveCohortIdentifier()

  const currentTheme = useMemo(() => {
    if (isLuvlyChinCohort) {
      return {
        ...CORAL_THEME,
        ...PURCHASE_VARIANT_8_THEME,
      }
    }

    return {
      ...DEFAULT_THEME,
      ...PURCHASE_VARIANT_7_THEME,
    }
  }, [isLuvlyChinCohort])

  return <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
}
