export const enum SkinType {
  NORMAL = 'normal',
  COMBINATION = 'combination',
  DRY = 'dry',
  OILY = 'oily',
  DONT_KNOW = 'dont_know',
}

export const SKIN_TYPE_ANSWERS_MAP = {
  [SkinType.NORMAL]: 'onboarding.skinType.normal',
  [SkinType.COMBINATION]: 'onboarding.skinType.combination',
  [SkinType.DRY]: 'onboarding.skinType.dry',
  [SkinType.OILY]: 'onboarding.skinType.oily',
}

export const AGE_OPTIONS = [
  { value: '20', label: '18-29' },
  { value: '30', label: '30-39' },
  { value: '40', label: '40-49' },
  { value: '50', label: '50-59' },
  { value: '60', label: '60-69' },
  { value: '70', label: '70+' },
]

export const SKIN_COLOR_OPTION_VALUES = {
  SAND_OR_WARM_IVORY: 'sand_or_warm_ivory',
  FAIR_OR_PALE_IVORY: 'fair_or_pale_ivory',
  OLIVE_OR_LIGHT_BROWN: 'olive_or_light_brown',
  BROWN_OR_BLACK_BROWN: 'brown_or_black_brown',
}

export const PROBLEMATIC_AREAS = [
  { value: 'forehead', label: 'Forehead' },
  { value: 'eyes', label: 'Temples' },
  { value: 'eyes', label: 'Eyes' },
  { value: 'cheeks', label: 'Cheeks' },
  { value: 'chin', label: 'Jawline' },
  { value: 'mouth', label: 'Mouth' },
  { value: 'chin', label: 'Chin' },
  { value: 'neck', label: 'Neck' },
  { value: 'neck', label: 'Décolleté (neckline)' },
]

export const TARGET_AREA_OPTIONS = [
  {
    value: 'forehead',
    label: 'onboarding.problematicAreas.forehead',
    key: 'Forehead',
  },
  {
    value: 'temples',
    label: 'onboarding.problematicAreas.temples',
    key: 'Temples',
  },
  { value: 'eyes', label: 'onboarding.problematicAreas.eyes', key: 'Eyes' },
  {
    value: 'cheeks',
    label: 'onboarding.problematicAreas.cheeks',
    key: 'Cheeks',
  },
  {
    value: 'jawline',
    label: 'onboarding.problematicAreas.jawline',
    key: 'Jawline',
  },
  { value: 'mouth', label: 'onboarding.problematicAreas.mouth', key: 'Mouth' },
  { value: 'chin', label: 'onboarding.problematicAreas.chin', key: 'Chin' },
  { value: 'neck', label: 'onboarding.problematicAreas.neck', key: 'Neck' },
  {
    value: 'neckline',
    label: 'onboarding.problematicAreas.neckline',
    key: 'Décolleté (neckline)',
  },
]

export const SCALE_OPTION_VALUES = {
  ONE: 'one',
  TWO: 'two',
  THREE: 'three',
  FOUR: 'four',
  FIVE: 'five',
}
