import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AnswerButtonMini2 } from 'components/AnswerButtonMini'
import { Container } from 'components/Container'
import { Option } from 'components/Option'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { DoubleChinTitle, SandyBackgroundGlobalStyles } from 'common-styles'
import { I18N_CONTEXT_COHORT_MAP, OptionType } from 'root-constants'

import { StyledManageStressLevels as S } from './ManageStressLevels.styles'
import { QUESTION, STRESS_LEVEL_ANSWERS } from './constants'

export const ManageStressLevels: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const cohortToUse = useCohortToUse()
  const { isLuvlyGeneralCohort } = useActiveCohortIdentifier()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleAnswerChange = (value: string) => {
    handleChange(value, handleContinue)
    setIsAnswersDisabled(true)
  }

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: handleAnswerChange,
    disabled: isAnswersDisabled,
  }

  return (
    <Container>
      <DoubleChinTitle>
        <Trans
          i18nKey="onboarding.stressLevels.question"
          context={I18N_CONTEXT_COHORT_MAP[cohortToUse]}
        />
      </DoubleChinTitle>
      {isLuvlyGeneralCohort && (
        <S.Description>
          {t('onboarding.stressLevels.description')}
        </S.Description>
      )}
      <S.OptionsContainer>
        {STRESS_LEVEL_ANSWERS.map(({ value, text, icon }) => {
          return (
            <Option {...optionProps} key={value} value={value}>
              <AnswerButtonMini2>
                <S.Content>
                  <S.Icon src={icon} alt="icon" />
                  <S.Text>{t(text)}</S.Text>
                </S.Content>
              </AnswerButtonMini2>
            </Option>
          )
        })}
      </S.OptionsContainer>
      <SandyBackgroundGlobalStyles />
    </Container>
  )
}
