import React from 'react'
import { Trans } from 'react-i18next'

import { AnswerButton, AnswerButtonHeight } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { Option } from 'components/Option'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { I18N_CONTEXT_COHORT_MAP, OptionType } from 'root-constants'

import { StyledPreferredFood as S } from './PreferredFood.styles'
import {
  DEFAULT_PREFERRED_FOOD_OPTIONS,
  PREFERRED_FOOD_OPTIONS_MAP,
  QUESTION,
} from './constants'

export const PreferredFood: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const cohortToUse = useCohortToUse()
  const { isSeniorCohort } = useActiveCohortIdentifier()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  const preferredFoodOptions: {
    value: string
    text: string
    height?: AnswerButtonHeight
  }[] =
    PREFERRED_FOOD_OPTIONS_MAP[cohortToUse] || DEFAULT_PREFERRED_FOOD_OPTIONS

  return (
    <div>
      <Container>
        <S.TitleContainer isSeniorCohortStyles={isSeniorCohort}>
          <Trans
            i18nKey="onboarding.preferredFood.question"
            context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
          />
        </S.TitleContainer>
        <S.OptionsContainer textAlign={isSeniorCohort ? 'left' : 'center'}>
          {preferredFoodOptions.map(({ value, text, height }) => (
            <Option
              disabled={isAnswersDisabled}
              {...optionProps}
              value={value}
              key={value}
            >
              <AnswerButton {...(height && { height })}>
                <Trans
                  i18nKey={text}
                  context={I18N_CONTEXT_COHORT_MAP[cohortToUse]}
                />
              </AnswerButton>
            </Option>
          ))}
        </S.OptionsContainer>
      </Container>
    </div>
  )
}
