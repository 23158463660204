import styled from 'styled-components'

import { Color } from 'root-constants'

type TStyledPurchaseDisclaimerProps = {
  marginBottom?: number
}

export const StyledPurchaseDisclaimer = {
  Wrapper: styled.div<TStyledPurchaseDisclaimerProps>`
    font-weight: 400;
    font-size: ${({ theme }) => theme.disclaimer.fontSize};
    line-height: ${({ theme }) => theme.disclaimer.lineHeight};
    text-align: center;
    color: ${({ theme }) => theme.disclaimer.color};
    margin-bottom: ${({ marginBottom }) => `${marginBottom ?? 24}px`};

    a {
      color: ${Color.LIGHT};
      text-decoration: underline;
    }
  `,
}
