import React, { useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import lottie from 'lottie-web/build/player/lottie_light'
import { Button, buttonTheme } from 'storybook-ui'

import { AdaptiveContainer } from 'components/AdaptiveContainer'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import skincareAnimation from 'assets/animation/regularSkincareAnimation.json'

import { StyledCosmetologistVsLuvly2 as S } from './CosmetologistVsLuvly2.styles'
import { QUESTION } from './constants'

export const CosmetologistVsLuvly2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const animationContainerRef = useRef<HTMLDivElement | null>(null)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue('')

  useEffect(() => {
    if (animationContainerRef && animationContainerRef.current) {
      lottie.loadAnimation({
        container: animationContainerRef.current,
        animationData: skincareAnimation,
        loop: true,
      })
    }

    return () => lottie.destroy()
  }, [])

  return (
    <AdaptiveContainer title="onboarding.cosmetologistVsLuvly.question2">
      <S.Subtitle>
        <Trans i18nKey="onboarding.cosmetologistVsLuvly.description" />
      </S.Subtitle>
      <S.GraphContainer ref={animationContainerRef} />
      <S.Note>
        <Trans i18nKey="onboarding.cosmetologistVsLuvly.extraDescription" />
      </S.Note>
      <StickyButtonContainer centerMode>
        <Button
          onClick={handleNextClick}
          theme={buttonTheme.LUVLY_SECONDARY}
          maxWidth="488px"
        >
          {t`actions.next`}
        </Button>
      </StickyButtonContainer>
    </AdaptiveContainer>
  )
}
