import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import {
  StripeCardCvcElement,
  StripeCardExpiryElement,
} from '@stripe/stripe-js'
import { Button, buttonTheme } from 'storybook-ui'

import { resetErrorAction } from 'root-redux/actions/common'
import { selectUserCountryCode } from 'root-redux/selects/user'

import { getPageIdFromPathName } from 'helpers/getPageIdFromPathName'

import {
  CARDHOLDER_NAME_MIN_LENGTH,
  CARDHOLDER_NAME_REGEX,
  EMPTY_FIELD_ERROR,
  THREEDS_REDIRECT_SEARCH_PARAM,
} from 'modules/purchase/constants'
import {
  getDefaultPaymentErrorsState,
  getPaymentErrorStateBySubmitWithUntouchedFields,
} from 'modules/purchase/helpers/rootHelpers'
import {
  check3DSecure,
  purchaseAction,
} from 'modules/purchase/redux/actions/common'
import { select3DSecureIframeUrl } from 'modules/purchase/redux/selects/common'
import { TCreditCardField, TPaymentErrorState } from 'modules/purchase/types'

import { CardPaymentFieldName, Country } from 'root-constants'

import {
  StyledCardPaymentFormSeparateCheckout2 as S,
  stripeElementStyle,
} from './CardPaymentFormSeparateCheckout2.styles'

export const CardPaymentFormSeparateCheckout2: React.FC = () => {
  const { t } = useTranslation()
  const { pathname, search } = useLocation()
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useDispatch()
  const cardExpiryElemRef = useRef<StripeCardExpiryElement | null>(null)
  const cvcElemRef = useRef<StripeCardCvcElement | null>(null)
  const cardholderNameElemRef = useRef<HTMLInputElement>(null)
  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)
  const userCountryCode = useSelector(selectUserCountryCode)

  const [errors, setErrors] = useState<TPaymentErrorState>(() =>
    getDefaultPaymentErrorsState(),
  )
  const [name, setName] = useState({
    value: '',
    error: false,
    isTouched: false,
  })
  const isCardholderNameRequired = userCountryCode === Country.MEXICO
  const hasErrors = useMemo(
    () => Object.values(errors).some((error) => error.isShown && error.error),
    [errors],
  )
  const hasUntouchedFields = useMemo(
    () =>
      Object.values(errors).some((error) => error.isShown && !error.isTouched),
    [errors],
  )
  const hasUncompletedFields = useMemo(
    () => Object.values(errors).some((field) => !field.isComplete),
    [errors],
  )
  const isFormValid = useMemo(
    () => !hasErrors && !hasUntouchedFields && !hasUncompletedFields,
    [hasErrors, hasUncompletedFields, hasUntouchedFields],
  )

  useEffect(() => {
    const URLParams = new URLSearchParams(search)
    const isSuccess = URLParams.has(THREEDS_REDIRECT_SEARCH_PARAM)

    if (!isSuccess || !threeDSecureIframeUrl || !stripe) return

    dispatch(check3DSecure(stripe))
  }, [dispatch, search, stripe, threeDSecureIframeUrl])

  const handleChange = useCallback(
    ({
      fieldName,
      isEmpty,
      hasError,
      isComplete,
      nextElemRef,
    }: {
      fieldName: CardPaymentFieldName
      isEmpty: boolean
      hasError: boolean
      isComplete: boolean
      nextElemRef?: RefObject<TCreditCardField>
    }) => {
      dispatch(resetErrorAction())

      let error = ''

      if (hasError) {
        error = 'is invalid'
      }

      if (isEmpty) {
        error = EMPTY_FIELD_ERROR
      }

      if (nextElemRef && isComplete) {
        nextElemRef.current?.focus()
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: { isTouched: true, error, isComplete },
      }))
    },
    [dispatch],
  )

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault()
      dispatch(resetErrorAction())

      if (hasUntouchedFields) {
        setErrors(getPaymentErrorStateBySubmitWithUntouchedFields(errors))
        return
      }

      if (hasErrors) return

      const card = elements?.getElement(CardNumberElement)
      const currentPageId = getPageIdFromPathName(pathname)

      if (!stripe || !card || !currentPageId) return

      dispatch(
        purchaseAction({
          stripe,
          card,
          name: name.value,
          paymentPageId: currentPageId,
        }),
      )
    },
    [
      dispatch,
      hasUntouchedFields,
      hasErrors,
      elements,
      stripe,
      name,
      pathname,
      errors,
    ],
  )

  return (
    <S.Wrapper>
      <S.Form onSubmit={handleSubmit}>
        <S.InputsContainer>
          <S.InputWrapper>
            <S.CardNumberElement
              onReady={(elem) => elem.focus()}
              options={{
                showIcon: false,
                placeholder: '',
                style: stripeElementStyle,
              }}
              onChange={({ empty, error, complete }) => {
                handleChange({
                  fieldName: CardPaymentFieldName.NUMBER,
                  isEmpty: empty,
                  hasError: !!error,
                  isComplete: complete,
                  nextElemRef: cardExpiryElemRef,
                })
              }}
            />
            <S.Label>{t`purchase1.paymentForm.cardNumber`}</S.Label>
          </S.InputWrapper>

          <S.CvvExpiryInputContainer>
            <S.CardExpiryContainer>
              <S.CardExpiryElement
                onReady={(elem) => {
                  cardExpiryElemRef.current = elem
                }}
                options={{
                  placeholder: '',
                  style: stripeElementStyle,
                }}
                onChange={({ empty, error, complete }) => {
                  handleChange({
                    fieldName: CardPaymentFieldName.EXPIRY,
                    isEmpty: empty,
                    hasError: !!error,
                    isComplete: complete,
                    nextElemRef: cvcElemRef,
                  })
                }}
              />
              <S.Label>{t`purchase1.paymentForm.cardExpiry`}</S.Label>
            </S.CardExpiryContainer>

            <S.CardCvcContainer>
              <S.CardCvcElement
                onReady={(elem) => {
                  cvcElemRef.current = elem
                }}
                options={{
                  placeholder: '',
                  style: stripeElementStyle,
                }}
                onChange={({ empty, error, complete }) => {
                  handleChange({
                    fieldName: CardPaymentFieldName.CVC,
                    isEmpty: empty,
                    hasError: !!error,
                    isComplete: complete,
                    nextElemRef: cardholderNameElemRef,
                  })
                }}
              />
              <S.Label>{t`purchase1.paymentForm.cvc`}</S.Label>
            </S.CardCvcContainer>
          </S.CvvExpiryInputContainer>
          <S.InputWrapper>
            <S.CardholderInput
              data-testid="cardholder-name-input"
              ref={cardholderNameElemRef}
              type="text"
              hasValue={!!name}
              data-invalid={
                name.error || !!errors[CardPaymentFieldName.NAME].error
              }
              onChange={(e) => {
                const value = e.target.value.trim()

                if (isCardholderNameRequired) {
                  const isNameValid =
                    CARDHOLDER_NAME_REGEX.test(value) &&
                    value.length > CARDHOLDER_NAME_MIN_LENGTH

                  if (!isNameValid) {
                    setName({
                      ...name,
                      error: true,
                    })

                    return
                  }
                }

                setName({
                  isTouched: true,
                  value,
                  error: false,
                })

                handleChange({
                  fieldName: CardPaymentFieldName.NAME,
                  isEmpty: false,
                  hasError: false,
                  isComplete: true,
                })
              }}
            />
            <S.Label>{t`purchase1.paymentForm.cardholderName`}</S.Label>
          </S.InputWrapper>
        </S.InputsContainer>
        <Button
          data-testid="checkout-btn"
          type="submit"
          disabled={
            !stripe ||
            !isFormValid ||
            (isCardholderNameRequired && (name.error || !name.isTouched))
          }
          theme={buttonTheme.LUVLY_SECONDARY}
          maxWidth="100%"
        >
          {t`actions.continue`}
        </Button>
      </S.Form>
    </S.Wrapper>
  )
}
