import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import i18n from 'i18next'

import { CookieConsent } from 'components/CookieConsent'
import { Header } from 'components/Header'
import { HeaderMinimalistic } from 'components/HeaderMinimalistic'
import { InContextControlPanel } from 'components/InContextControlPanel'
import { LinearProgressBar } from 'components/LinearProgressBar'
import { LiveChat } from 'components/LiveChat'
import { OnboardingProgressBar } from 'components/OnboardingProgressBar'
import { PostPaymentProgressBar } from 'components/PostPaymentProgressBar'
import { Theme } from 'components/Theme'

import {
  selectCurrentVariantCohort,
  selectLanguage,
} from 'root-redux/selects/common'
import {
  selectUserConfigData,
  selectUserCountryCode,
} from 'root-redux/selects/user'

import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useChatBotEmail } from 'hooks/useChatBotEmail'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useDynamicDiscount } from 'hooks/useDynamicDiscount'
import { useEventLoggerInitialization } from 'hooks/useEventLoggerInitialization'
import { useGAPageTracker } from 'hooks/useGAPageTracker'
import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { growthBook, useGrowthBook } from 'hooks/useGrowthBook'
import { useLiveChatAvailability } from 'hooks/useLiveChatAvailability'
import { useScrollToTop } from 'hooks/useScrollToTop'
import { useTestEnvQueryParameter } from 'hooks/useTestEnvQueryParameter'
import { useUtmTags } from 'hooks/useUtmTags'

import { getCurrentEnv } from 'helpers/getCurrentEnv'

import { OpenSansGlobalStyles } from 'common-styles'
import { Cohort, CurrentEnvironment } from 'root-constants'

import { RouteList } from '../../RouteList'
import { MINIMALISTIC_HEADER_COHORTS } from './constants'

export const LayoutProvider: React.FC = () => {
  const cohort = useSelector(selectCurrentVariantCohort)
  const language = useSelector(selectLanguage)
  const userCountryCode = useSelector(selectUserCountryCode)
  const config = useSelector(selectUserConfigData)

  const { hasLiveChat } = useLiveChatAvailability()
  const { isEUUser } = useCookieConsentAnswer()
  const { hasProgressBar, hasHeader, hasSubscription, useOpenSans } =
    useGetPageInfo()
  const { search, pathname } = useLocation()
  const cohortToUse = useCohortToUse()

  const isDevEnvironment = getCurrentEnv() === CurrentEnvironment.DEV

  const userCookieConsentAnswer = useMemo(() => {
    return config?.cookieConsent as string[]
  }, [config])

  const isCookieConsentAvailable = useMemo(() => {
    return !userCookieConsentAnswer && isEUUser
  }, [isEUUser, userCookieConsentAnswer])

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])

  useUtmTags()
  useDynamicDiscount()
  useGrowthBook()
  useGAPageTracker()
  useEventLoggerInitialization(cohort as Cohort)
  useAmplitudeInitialization(cohort as Cohort)
  useScrollToTop()
  useTestEnvQueryParameter()
  useChatBotEmail()

  useEffect(() => {
    growthBook.setAttributes({
      ...growthBook.getAttributes(),
      url: `${pathname}${search}`,
      country: userCountryCode,
      cohort,
      language,
    })
  }, [cohort, language, pathname, search, userCountryCode])

  const isMinimalisticHeaderCohort =
    MINIMALISTIC_HEADER_COHORTS.includes(cohortToUse)

  return (
    <Theme>
      {!isMinimalisticHeaderCohort && (
        <>
          {isDevEnvironment && <InContextControlPanel />}
          {hasHeader && <Header />}
          {hasSubscription ? (
            <PostPaymentProgressBar />
          ) : (
            <OnboardingProgressBar />
          )}
        </>
      )}
      {isMinimalisticHeaderCohort && (
        <>
          {isDevEnvironment && <InContextControlPanel />}
          {hasHeader && <HeaderMinimalistic />}
          {hasProgressBar && <LinearProgressBar />}
          {hasSubscription && <PostPaymentProgressBar />}
        </>
      )}
      {hasLiveChat && <LiveChat />}
      {isCookieConsentAvailable && <CookieConsent />}
      {useOpenSans && <OpenSansGlobalStyles />}
      <RouteList />
    </Theme>
  )
}
