import React, { InputHTMLAttributes } from 'react'

import checkIconSvg from 'assets/images/sprite/check-icon-green-2.svg'

import { StyledInputWithFloatPlaceholder2 as S } from './InputWithFloatPlaceholder2.styles'

type TProps = InputHTMLAttributes<HTMLInputElement> & {
  value?: string
  labelName: string
  isValid?: boolean
  validationText?: string
  type?: string
  hasValidationIcon?: boolean
  successIconSrc?: string
  iconSize?: number
  className?: string
  marginBottom?: number
  errorText?: string
}

export const InputWithFloatPlaceholder2: React.FC<TProps> = ({
  value,
  labelName,
  isValid = true,
  type = 'text',
  errorText,
  validationText = '',
  hasValidationIcon = false,
  successIconSrc = checkIconSvg,
  iconSize = 20,
  marginBottom = 12,
  className,
  ...props
}) => (
  <S.Wrapper
    data-valid={isValid}
    data-validation-text={validationText}
    className={className}
    marginBottom={marginBottom}
  >
    <S.Input required type={type} value={value} {...props} />
    <S.Label>{labelName}</S.Label>

    {hasValidationIcon && value && isValid && (
      <S.SvgIcon svg={successIconSrc} width={iconSize} />
    )}
    {value && !isValid && <S.Error>{errorText}</S.Error>}
  </S.Wrapper>
)
