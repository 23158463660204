import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

type TMentalHealthProps = {
  isSeniorCohortStyles: boolean
}

export const StyledMentalHealth = {
  TitleContainer: styled.header<TMentalHealthProps>`
    font-weight: 500;
    text-align: center;

    ${({ isSeniorCohortStyles }) =>
      isSeniorCohortStyles
        ? css`
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 8px;
          `
        : css`
            font-size: 17px;
            line-height: 26px;
            margin-bottom: 16px;
          `}
  `,
  TitleV2: styled.h1`
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 24px auto;
    color: ${Color.BLUE_DARK};
  `,
  DescriptionContainer: styled.div`
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${Color.LIGHT};
    margin-bottom: 16px;
  `,
}
