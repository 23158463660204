import React, { useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import { selectLanguage, selectUserGoal } from 'root-redux/selects/common'

import { addDaysToCurrentDate, getDateSequence } from 'helpers/date'

import { PageId } from 'page-constants'
import {
  CDN_FOLDER_LINK,
  DAYS_NUMBER,
  USER_GOALS_TITLE,
  UserGoal,
} from 'root-constants'

import { StyledUserGoalAnimatedGraph as S } from './UserGoalAnimatedGraph.styles'
import { ANIMATIONS_LINKS_MAP } from './constants'

type TProps = {
  pageId: PageId
}

export const UserGoalAnimatedGraph: React.FC<TProps> = ({ pageId }) => {
  const { t } = useTranslation()
  const goal = useSelector(selectUserGoal)
  const language = useSelector(selectLanguage)
  const animationContainerRef = useRef<HTMLDivElement | null>(null)

  const userGoal = goal || UserGoal.WRINKLE_FREE_SKIN

  const goalDate = useMemo(() => {
    const daysNumber = DAYS_NUMBER[pageId]
    return addDaysToCurrentDate(daysNumber, language)
  }, [pageId, language])

  const isStaticDate = pageId === PageId.SHORT_PROGRAM_DESCRIPTION

  const dateSequence = useMemo(() => {
    const daysNumber = DAYS_NUMBER[PageId.SHORT_PROGRAM_DESCRIPTION]
    return getDateSequence(daysNumber, language)
  }, [language])

  useEffect(() => {
    if (animationContainerRef && animationContainerRef.current) {
      lottie.loadAnimation({
        container: animationContainerRef.current,
        path: `${CDN_FOLDER_LINK}${
          ANIMATIONS_LINKS_MAP[userGoal || UserGoal.WRINKLE_FREE_SKIN]
        }`,
        loop: false,
      })
    }

    return () => lottie.destroy()
  }, [userGoal])

  return (
    <>
      <S.GoalDateContainer>
        <S.Goal>
          {t('onboarding.shortProgramDescription.goalDate', {
            goal: t(USER_GOALS_TITLE[userGoal]),
          })}
        </S.Goal>
        {isStaticDate ? (
          <S.GoalDate>
            {t('onboarding.shortProgramDescription.goalDateCounter', {
              day: goalDate.day,
              month: goalDate.month,
            })}
          </S.GoalDate>
        ) : (
          <S.AnimationContainer>
            {dateSequence.map(({ day, month }) => (
              <S.GoalDate key={day}>
                {t('onboarding.shortProgramDescription.goalDateCounter', {
                  day,
                  month,
                })}
              </S.GoalDate>
            ))}
          </S.AnimationContainer>
        )}
      </S.GoalDateContainer>
      <S.ProgramDescription>
        <Trans i18nKey="commonComponents.programDescription" />
      </S.ProgramDescription>
      <S.GraphContainer ref={animationContainerRef} />
    </>
  )
}
