import styled from 'styled-components'

import { Container } from 'components/Container'

import { Color } from 'root-constants'

export const StyledWellhubLogin = {
  CustomContainer: styled(Container)`
    min-height: calc(100vh - 80px);
    min-height: calc(100dvh - 80px);
  `,
  Title: styled.h1`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-align: center;
    margin-bottom: 24px;
  `,
  Form: styled.form`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  `,
  InputsContainer: styled.div`
    position: relative;
  `,
  BottomContainer: styled.div`
    padding-bottom: 32px;
  `,
  Disclaimer: styled.p`
    position: relative;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: ${Color.GRAY};
    margin-bottom: 24px;

    a {
      color: ${Color.GRAY};
      text-decoration: underline;
    }
  `,
}
