import styled from 'styled-components'

import sparklesIcon from 'assets/images/sparkles-emoji.png'

import { Color } from 'root-constants'

export const StyledCoffeeBanner = {
  Wrapper: styled.div`
    border-radius: 12px;
    background-color: ${Color.CORAL_GRAY};
    display: flex;
    align-items: flex-start;
    padding: 12px 8px;
    margin-bottom: 32px;
  `,
  Coffee: styled.img`
    width: 20px;
    height: 20px;
    margin-top: 2px;
    margin-right: 8px;
  `,
  Text: styled.p`
    color: ${Color.DARK};
    font-feature-settings: 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  `,
  Emoji: styled.span`
    display: inline-flex;
    width: 16px;
    height: 16px;
    position: relative;
    background: url(${sparklesIcon}) no-repeat center;
    background-size: contain;
  `,
}
