/* eslint-disable max-lines */
import { PageId } from './pages'

export const LIVE_CHAT_PUBLIC_KEY = '10826272'
export const FREE_USER_GROUP_ID = '69'
export const PAID_USER_GROUP_ID = '23'

export const enum CurrentEnvironment {
  DEV = 'dev',
  STAGE = 'stage',
  PROD = 'prod',
}

export const enum RequestMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
}

export const enum MediaBreakpoint {
  MIN_PHONE = 321,
  SMALL_PHONE = 360,
  MEDIUM_PHONE = 375,
  MAX_PHONE = 415,
  TABLET = 520,
}

export const enum CardPaymentFieldName {
  NAME = 'name',
  NUMBER = 'cardNumber',
  EXPIRY = 'cardExpiry',
  CVC = 'cardCvc',
}

export const SUPPORT_FORM_LINKS_MAP = {
  [CurrentEnvironment.PROD]: 'https://account.luvly.care/contact-form',
  [CurrentEnvironment.STAGE]: 'https://account-luvly.gistage.com/contact-form',
  [CurrentEnvironment.DEV]: 'https://account-luvly.gidev.xyz/contact-form',
}
export const TERMS_OF_USE_LINK = 'https://luvly.care/terms-of-use'
export const PRIVACY_POLICY_LINK = 'https://luvly.care/privacy-policy'
export const CDN_FOLDER_LINK =
  'https://cdn-prod-unified-content.gismart.xyz/34486912bbc40fd28752c59e42098ae09101436d76566d91d767d95811286b02'

export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const EMAIL_FROM_URL_REGEXP = /email=([^&]*)/
export const EMAIL_DOMAIN_REGEXP = /@(.*)/
export const EMAIL_USERNAME_REGEXP = /(.*)@/

export const enum LoginMethod {
  EMAIL = 'email',
}

export const enum LoginMethodRaw {
  EMAIL = 'password',
}

export const LOGIN_METHOD_RAW_MAP = {
  [LoginMethodRaw.EMAIL]: LoginMethod.EMAIL,
}

export const EMAIL_LOGIN_INSTRUCTION_LINK =
  'https://facetory.onelink.me/qTZZ?pid=web2web_payment_success&deep_link_value=custom_token&c=Facetory_DL_Password&af_dp=gismartfacetory%3A%2F%2F&deep_link_sub1=gismartfacetory%3A%2F%2F%3Flogin%3Dpassword'

export const enum FirebaseSignUpErrorCode {
  EMAIL_EXISTS = 'auth/email-already-in-use',
}

export const enum FirebaseSignInErrorCode {
  WRONG_PASSWORD = 'auth/wrong-password',
  USER_NOT_FOUND = 'auth/user-not-found',
  USER_DISABLED = 'auth/user-disabled',
  INVALID_EMAIL = 'auth/invalid-email',
}

export const enum Color {
  CORAL = '#ff7262',
  PRIMARY = '#ff8276',
  SUCCESS = '#7ad718',
  ERROR = '#ea5873',
  ERROR_SECONDARY = '#e74c3c',
  WHITE = '#fff',
  SANDY = '#fbf8f3',
  DARK = '#2d3240',
  DARK_SECONDARY = '#2c3131',
  LIGHT = '#81848c',
  BLUE_DARK = '#17202a',
  BLUE_GRAY = '#4C4C4C',
  MIDNIGHT_BLUE = '#292d3d',
  GRAY = '#626262',
  CORAL_GRAY = '#f2f3f5',
  GRAY_SECONDARY = '#aaacb2',
  GRAY_LIGHT = '#e9e9e9',
  SILVER_GRAY = '#dadadd',
  RED = '#e85e47',
  LIGHT_PINK = '#febfba',
  LIGHT_PURPLE = '#c5d1ff',
  DEFAULT_BACKGROUND = '#fdfdff',
  CREAMY = '#fae0d5',
  LIGHT_SANDY = '#f6f5f4',
  ASH_GRAY = '#c4c4c4',
}

export const enum TimeInterval {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export const enum EventLoggerInstanceName {
  GIA = 'gia-amplitude',
  AMPLITUDE = 'amplitude',
  USER_FLOW_TRACKER = 'user-flow-tracker',
}

export const enum PlatformOS {
  ANDROID = 'Android',
  IOS = 'iOS',
  UNKNOWN = 'unknown',
}

export const enum AppWithInAppBrowser {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
}

export const enum UserAgent {
  INSTAGRAM = 'instagram',
  FACEBOOK = 'facebook',
  OTHER = 'other',
}

export const enum Cohort {
  QUIZ_2 = 'quiz_2',
  LUVLY_EMAIL_7 = 'luvly_email_7',
  LUVLY_INTRO_FAST = 'luvly_intro_fast',
  LUVLY_CHAT_UPGRADE_12_PDF = 'luvly_chat_upgrade12_pdf',
  LUVLY_CHAT_UPGRADE_12_EX_PDF = 'luvly_chat_upgrade12ex_pdf',
  LUVLY_CHAT_UPGRADE_BUNDLE_PDF = 'luvly_chat_upgrade_bundle_pdf',
  LUVLY_CHAT_UPGRADE_WELLNESS_BUNDLE = 'luvly_chat_upgrade_wellness_bundle',
  LUVLY_EMAIL_8 = 'luvly_email_8',
  LUVLY_EMAIL_SALE = 'luvly_email_sale',
  LUVLY_EMAIL_INTRO_FAST = 'luvly_email_intro_fast',
  LUVLY_CANCEL_INTRO_FAST = 'luvly_cancel_intro_fast',
  LUVLY_MARISSA = 'luvly_marissa',
  LUVLY_COSMETIC_SCANNER = 'luvly_cosmetic_scanner',
  LUVLY_CHAT = 'luvly_chat',
  LUVLY_INTRO_SALE = 'luvly_intro_sale',
  LUVLY_INTRO3_SALE = 'luvly_intro3_sale',
  LUVLY_12_SALE = 'luvly_12_sale',
  LUVLY_REF = 'luvly_ref',
  LUVLY_CANCEL_INTRO_1 = 'luvly_cancel_intro_1',
  LUVLY_CHIN = 'luvly_chin',
  LUVLY_INFLUENCERS = 'luvly_influencers',
  LUVLY_GIFT = 'luvly_mobile_cohort',
  LUVLY_KOREAN = 'luvly_korean',
  LUVLY_EMAIL_NY = 'luvly_email_ny',
  LUVLY_WHEEL = 'luvly_wheel',
  LUVLY_21 = 'luvly_21',
  LUVLY_GENERAL = 'luvly_general',
}

export const INTRO_EMAIL_COHORTS: Cohort[] = [
  Cohort.LUVLY_EMAIL_INTRO_FAST,
  Cohort.LUVLY_CANCEL_INTRO_FAST,
]

export const enum PagePath {
  LOCALHOST = 'localhost',
}

export const enum Gender {
  MALE = 'male',
  FEMALE = 'female',
}

export const ALTERNATIVE_GENDER = 'other'

export const enum UserGoal {
  WRINKLE_FREE_SKIN = 'get_rid_of_wrinkles',
  REDUCE_FACE_FAT = 'reduce_face_fat',
  BOTH_OPTIONS = 'both_options',
  TIGHTEN_SKIN = 'tighten_skin',
}

export const enum UserAge {
  TWENTY = '20', // 18-29
  THIRTY = '30', // 30-39
  FOURTY = '40', // 40-49
  FIFTY = '50', // 50-59
  SIXTY = '60', // 60-69
  SEVENTY = '70', // 70+
}

export const enum SubscriptionListType {
  PURCHASE = 'purchase',
  UPGRADE = 'upgrade',
  UPSELL = 'upsell',
  ONE_TIME = 'one_time',
  SECONDARY = 'secondary',
  GIFT = 'gift',
}

export const enum SubscriptionTagsType {
  TAX = 'tax',
  NO_TAX = 'no_tax',
  CANCEL_OFFER = 'cancel',
  USD = 'us',
  WORLDWIDE = 'ww',
  AGE_30_59 = 'age_30_59',
  WELLNESS_BUNDLE = 'bundle',
  WELLNESS_BUNDLE_CANCEL = 'cancel_bundle',
  HAIR_GUIDE = 'hair_guide',
  COACHING = 'luvly_coaching',
  HIGHER_PRICES = 'higher_prices',
  DISCOUNT_50_OFF = '50_off',
  DISCOUNT_65_OFF = '65_off',
  ROUNDED_TO_99 = '99',
}

export const enum OptionType {
  RADIO = 'radio',
  CHECKBOX = 'checkbox',
}

export const enum UserAnswer {
  YES = 'yes',
  NO = 'no',
}

export const enum Country {
  USA = 'US',
  CANADA = 'CA',
  AUSTRALIA = 'AU',
}

export const enum LiveChatStatus {
  MAXIMIZED = 'maximized',
  MINIMIZED = 'minimized',
  HIDDEN = 'hidden',
}

export const enum Locale {
  IN_CONTEXT = 'af',
  ENGLISH = 'en',
  FRENCH = 'fr',
  SPANISH = 'es',
  ITALIAN = 'it',
  PORTUGUESE = 'pt',
  JAPANESE = 'ja',
  GERMAN = 'de',
}

export const APP_NAME = 'facetory'
export const CENTS_IN_DOLLAR = 100
export const INITIAL_PRICE = 0
export const DATES_NUMBER_FOR_ANIMATION = 8
export const PAGE_TRANSITION_DELAY = 500
export const CHAT_PAGE_TRANSITION_DELAY = 1500
export const ONBOARDING_INPUT_MIN_LENGTH = 5
export const CUSTOM_TOKEN_LOCAL_STORAGE_KEY = 'custom_token'
export const DEFAULT_CURRENCY = 'usd'
export const TEST_ENV_QUERY_PARAM = 'is_test_environment'
export const SNAPCHAT_QUERY_PARAM = 'is_snapchat_payment'
export const LANG_QUERY_PARAM = 'lang'
export const KEYWORD_PARAM = 'lp_dynamic'
export const COHORT_QUERY_PARAM = 'cohort'
export const UUID_QUERY_PARAM = 'uuid'
export const EMAIL_QUERY_PARAM = 'email'
export const INITIAL_LANGUAGE = 'en'
export const NO_GROWTH_BOOK_TEST = 'no_test'
export const EXPERIMENT_ID_NOT_SET = 'experiment_id_not_set'
export const MAX_VISIBLE_PROBLEM_AREAS_NUMBER = 2
export const PROGRESS_SECTION_STEP = 1

export const USER_GOALS_GRAPH: Record<UserGoal, string> = {
  [UserGoal.WRINKLE_FREE_SKIN]: 'commonComponents.wrinkleFreeSkin',
  [UserGoal.REDUCE_FACE_FAT]: 'commonComponents.slimFace',
  [UserGoal.BOTH_OPTIONS]: 'commonComponents.idealSkin',
  [UserGoal.TIGHTEN_SKIN]: 'commonComponents.idealSkin',
}

export const USER_GOALS_TITLE: Record<UserGoal, string> = {
  [UserGoal.WRINKLE_FREE_SKIN]: 'commonComponents.wrinkleFreeSkin',
  [UserGoal.REDUCE_FACE_FAT]: 'commonComponents.slimFace',
  [UserGoal.BOTH_OPTIONS]: 'commonComponents.perfectSkin',
  [UserGoal.TIGHTEN_SKIN]: 'commonComponents.perfectSkin',
}

export const DAYS_NUMBER = {
  [PageId.SHORT_PROGRAM_DESCRIPTION]: 30,
  [PageId.SHORT_PROGRAM_DESCRIPTION_SECOND]: 23,
  [PageId.PURCHASE_EMAIL]: 16,
  [PageId.PURCHASE]: 16,
}

export const enum SubscriptionStatus {
  ACTIVE = 'active',
  TRIAL = 'trial',
  CANCELLED = 'cancelled',
  NOT_ACTIVE = 'not_active',
}

export const enum BillingCycle {
  ONE_MONTH = 1,
  TWO_MONTHS = 2,
  THREE_MONTHS = 3,
  FOUR_MONTHS = 4,
  SIX_MONTHS = 6,
  NINE_MONTHS = 9,
  TWELVE_MONTHS = 12,
}

export const BILLING_CYCLE_TEXTS = {
  [BillingCycle.ONE_MONTH]: 'unsubscribe.subscriptionDetails.oneMonth',
  [BillingCycle.TWO_MONTHS]: 'unsubscribe.subscriptionDetails.twoMonths',
  [BillingCycle.THREE_MONTHS]: 'unsubscribe.subscriptionDetails.threeMonths',
  [BillingCycle.FOUR_MONTHS]: 'unsubscribe.subscriptionDetails.fourMonths',
  [BillingCycle.SIX_MONTHS]: 'unsubscribe.subscriptionDetails.sixMonths',
  [BillingCycle.NINE_MONTHS]: 'unsubscribe.subscriptionDetails.nineMonths',
  [BillingCycle.TWELVE_MONTHS]: 'unsubscribe.subscriptionDetails.twelveMonths',
}

export const enum TrialBillingCycle {
  SEVEN_DAYS = 7,
  ONE_MONTH = 30,
  THREE_MONTHS = 90,
  SIX_MONTHS = 180,
}

export const AGE_OPTIONS = [
  { value: '20', label: '18-29' },
  { value: '30', label: '30-39' },
  { value: '40', label: '40-49' },
  { value: '50', label: '50-59' },
  { value: '60', label: '60-69' },
  { value: '70', label: '70+' },
]

export const enum SubscriptionProduct {
  SEVEN_DAY = 7,
  ONE_MONTH = 30,
  THREE_MONTHS = 90,
  SIX_MONTHS = 180,
  TWO_MONTHS = 60,
  FOUR_MONTHS = 120,
}

export const TRIAL_BILLING_CYCLE_TEXTS = {
  [TrialBillingCycle.SEVEN_DAYS]: 'unsubscribe.subscriptionDetails.sevenDays',
  [TrialBillingCycle.ONE_MONTH]: 'unsubscribe.subscriptionDetails.oneMonth',
  [TrialBillingCycle.THREE_MONTHS]:
    'unsubscribe.subscriptionDetails.threeMonths',
  [TrialBillingCycle.SIX_MONTHS]: 'unsubscribe.subscriptionDetails.sixMonths',
}

export const enum ScreenName {
  LONG_START_AGE = 'long_start_age',
  PURCHASE = 'onboarding',
  EMAIL_OFFER = 'email_offer',
  CANCEL_OFFER = 'cancel_offer',
  CANCEL_OFFER_NY = 'cancel_offer_ny',
  CANCEL_OFFER_LONG = 'paywall_cancel_offer',
  UPSELL_1_PLAN = 'upsell_1_plan',
  UPSELL_3_PLANS = 'upsell_3_plans',
  UPSELL_CANCEL_OFFER = 'upsell_cancel_offer',
  UPSELL_DISCOUNT_PLAN = 'upsell_discount_plan',
  UPSELL_SWITCHER = 'upsell_switcher',
  UPSELL_COACHING = 'upsell_coaching',
  UPSELL_COACHING_CANCEL = 'upsell_coaching_cancel',
  LONG_PAYWALL = 'long_paywall',
  LIVECHAT_UPGRADE = 'livechat_upgrade',
  CHECKOUT = 'checkout',
  LIVECHAT_WELLNESS_BUNDLE = 'livechat_wellness_bundle',
  LIVECHAT_WELLNESS_BUNDLE_CANCEL_OFFER = 'livechat_wellness_bundle_cancel_offer',
  UPSELL_GIFT_CARD = 'upsell_gift_card',
}

export const enum UpsellProduct {
  MIX = 'doubleChinAndFaceLifting',
  CHIN_SHAPER = 'doubleChin',
  FACE_LIFTING = 'faceLifting',
  WELLNESS_APPS_BUNDLE = 'bundle',
  HAIR_GUIDE = 'hairCareUpsell',
  GIFT_CARD = 'giftCard',
}

export const UPSELL_PRODUCT_EVENT_NAMES_MAP = {
  [UpsellProduct.CHIN_SHAPER]: 'double_chin',
  [UpsellProduct.FACE_LIFTING]: 'face_lifting',
  [UpsellProduct.MIX]: 'double_chin_face_lifting',
  [UpsellProduct.WELLNESS_APPS_BUNDLE]: 'wellness_bundle',
  [UpsellProduct.HAIR_GUIDE]: 'hair_guide',
}

export const enum FaceScanSkipScreen {
  INFO_SCREEN = 'info_screen',
  MAIN_SCREEN = 'screen',
}

export const POST_PAYMENT_PAGES: string[] = [
  PageId.UPSELL_PAYWALL,
  PageId.COACHING_PAYWALL,
  PageId.COACHING_CANCEL_PAYWALL,
  PageId.FINISHING_TOUCHES,
  PageId.LOGIN,
  PageId.DOWNLOAD,
]

export const PAGES_WITHOUT_HEADER: string[] = [
  PageId.INTRO,
  PageId.INTRO_SALE_AGE,
  PageId.FACE_SCAN_PREVIEW,
  PageId.FACE_SCAN,
  PageId.FACE_SCAN_RESULTS,
  PageId.HOLIDAYS_PURCHASE_CANCEL_OFFER,
  PageId.PURCHASE_UPGRADE,
  PageId.AGE_AS_INTRO,
  PageId.LIKE_CHAT_INTRO,
  PageId.INTRO_REFUND,
  PageId.INTRO_INFLUENCERS,
  PageId.INTRO_GIFT_CARD,
  PageId.PERSONAL_KOREAN_PLAN,
  PageId.LOADING_INTRO,
  PageId.LOADING_INTRO_2,
  PageId.SKIPPABLE_INTRO,
]

export const SKIN_COLOR_OPTION_VALUES = {
  SAND_OR_WARM_IVORY: 'sand_or_warm_ivory',
  FAIR_OR_PALE_IVORY: 'fair_or_pale_ivory',
  OLIVE_OR_LIGHT_BROWN: 'olive_or_light_brown',
  BROWN_OR_BLACK_BROWN: 'brown_or_black_brown',
}

export const PROBLEMATIC_AREAS_MAP = [
  { value: 'forehead', label: 'Forehead' },
  { value: 'eyes', label: 'Temples' },
  { value: 'eyes', label: 'Eyes' },
  { value: 'cheeks', label: 'Cheeks' },
  { value: 'chin', label: 'Jawline' },
  { value: 'mouth', label: 'Mouth' },
  { value: 'chin', label: 'Chin' },
  { value: 'neck', label: 'Neck' },
  { value: 'neck', label: 'Décolleté (neckline)' },
]

export const TARGET_AREA_OPTIONS = [
  {
    value: 'forehead',
    label: 'onboarding.problematicAreas.forehead',
    key: 'Forehead',
  },
  {
    value: 'temples',
    label: 'onboarding.problematicAreas.temples',
    key: 'Temples',
  },
  { value: 'eyes', label: 'onboarding.problematicAreas.eyes', key: 'Eyes' },
  {
    value: 'cheeks',
    label: 'onboarding.problematicAreas.cheeks',
    key: 'Cheeks',
  },
  {
    value: 'jawline',
    label: 'onboarding.problematicAreas.jawline',
    key: 'Jawline',
  },
  { value: 'mouth', label: 'onboarding.problematicAreas.mouth', key: 'Mouth' },
  { value: 'chin', label: 'onboarding.problematicAreas.chin', key: 'Chin' },
  { value: 'neck', label: 'onboarding.problematicAreas.neck', key: 'Neck' },
  {
    value: 'neckline',
    label: 'onboarding.problematicAreas.neckline',
    key: 'Décolleté (neckline)',
  },
]

export const SCALE_OPTION_VALUES = {
  ONE: 'one',
  TWO: 'two',
  THREE: 'three',
  FOUR: 'four',
  FIVE: 'five',
}

export const SUPPORTED_LOCALES_MAP: Record<Cohort, string[]> = {
  [Cohort.QUIZ_2]: [Locale.ENGLISH, Locale.FRENCH, Locale.SPANISH],
  [Cohort.LUVLY_EMAIL_7]: [Locale.ENGLISH, Locale.FRENCH, Locale.SPANISH],
  [Cohort.LUVLY_CHAT_UPGRADE_12_PDF]: [Locale.ENGLISH, Locale.FRENCH],
  [Cohort.LUVLY_CHAT_UPGRADE_12_EX_PDF]: [Locale.ENGLISH, Locale.FRENCH],
  [Cohort.LUVLY_CHAT_UPGRADE_BUNDLE_PDF]: [Locale.ENGLISH, Locale.FRENCH],
  [Cohort.LUVLY_CHAT_UPGRADE_WELLNESS_BUNDLE]: [Locale.ENGLISH, Locale.FRENCH],
  [Cohort.LUVLY_MARISSA]: [Locale.ENGLISH],
  [Cohort.LUVLY_COSMETIC_SCANNER]: [Locale.ENGLISH],
  [Cohort.LUVLY_CHAT]: [Locale.ENGLISH],
  [Cohort.LUVLY_CHIN]: [Locale.ENGLISH],
  [Cohort.LUVLY_INFLUENCERS]: [Locale.ENGLISH, Locale.SPANISH],
  [Cohort.LUVLY_12_SALE]: [Locale.ENGLISH],
  [Cohort.LUVLY_GIFT]: [Locale.ENGLISH],
  [Cohort.LUVLY_KOREAN]: [Locale.ENGLISH],
  [Cohort.LUVLY_INTRO_FAST]: [
    Locale.ENGLISH,
    Locale.FRENCH,
    Locale.SPANISH,
    Locale.ITALIAN,
    Locale.PORTUGUESE,
    Locale.JAPANESE,
    Locale.GERMAN,
  ],
  [Cohort.LUVLY_EMAIL_8]: [
    Locale.ENGLISH,
    Locale.FRENCH,
    Locale.SPANISH,
    Locale.ITALIAN,
    Locale.PORTUGUESE,
    Locale.JAPANESE,
    Locale.GERMAN,
  ],
  [Cohort.LUVLY_EMAIL_SALE]: [
    Locale.ENGLISH,
    Locale.FRENCH,
    Locale.SPANISH,
    Locale.ITALIAN,
    Locale.PORTUGUESE,
  ],
  [Cohort.LUVLY_EMAIL_INTRO_FAST]: [
    Locale.ENGLISH,
    Locale.FRENCH,
    Locale.SPANISH,
  ],
  [Cohort.LUVLY_CANCEL_INTRO_FAST]: [
    Locale.ENGLISH,
    Locale.FRENCH,
    Locale.SPANISH,
  ],
  [Cohort.LUVLY_INTRO_SALE]: [
    Locale.ENGLISH,
    Locale.FRENCH,
    Locale.SPANISH,
    Locale.ITALIAN,
    Locale.PORTUGUESE,
    Locale.JAPANESE,
    Locale.GERMAN,
  ],
  [Cohort.LUVLY_INTRO3_SALE]: [
    Locale.ENGLISH,
    Locale.FRENCH,
    Locale.SPANISH,
    Locale.ITALIAN,
  ],
  [Cohort.LUVLY_REF]: [
    Locale.ENGLISH,
    Locale.FRENCH,
    Locale.SPANISH,
    Locale.ITALIAN,
    Locale.PORTUGUESE,
    Locale.GERMAN,
    Locale.JAPANESE,
  ],
  [Cohort.LUVLY_CANCEL_INTRO_1]: [
    Locale.ENGLISH,
    Locale.FRENCH,
    Locale.SPANISH,
  ],
  [Cohort.LUVLY_EMAIL_NY]: [
    Locale.ENGLISH,
    Locale.FRENCH,
    Locale.SPANISH,
    Locale.ITALIAN,
    Locale.PORTUGUESE,
  ],
  [Cohort.LUVLY_WHEEL]: [
    Locale.ENGLISH,
    Locale.SPANISH,
    Locale.PORTUGUESE,
    Locale.GERMAN,
  ],
  [Cohort.LUVLY_21]: [Locale.ENGLISH],
  [Cohort.LUVLY_GENERAL]: [Locale.ENGLISH],
}

export const SUPPORTED_LOCALES = [
  Locale.ENGLISH,
  Locale.SPANISH,
  Locale.FRENCH,
  Locale.ITALIAN,
  Locale.PORTUGUESE,
  Locale.JAPANESE,
  Locale.GERMAN,
]

export const EMAIL_DOMAINS = [
  'gmail.com',
  'hotmail.com',
  'yahoo.com',
  'outlook.com',
  'aol.com',
  'icloud.com',
  'live.com',
  'msn.com',
  'me.com',
  'mac.com',
  'googlemail.com',
  'facebook.com',
  'verizon.net',
  'rediffmail.com',
  'zohomail.com',
  'zoho.com',
  'mail.com',
  'google.com',
  'comcast.com',
  'hotmail.co.uk',
  'yahoo.co.uk',
  'att.net',
  'gmz.com',
]

export const enum CameraAccess {
  ALLOW = 'allow',
  DENY = 'deny',
}

export const ML_NO_PROBLEM_AREAS = 'not_found'

export const ML_DEFAULT_PROBLEM_AREAS = ['wrinkles']
export const DEFAULT_USER_AGE = UserAge.TWENTY

export const LIVECHAT_ENABLED_COHORTS: Cohort[] = [
  Cohort.QUIZ_2,
  Cohort.LUVLY_INTRO_FAST,
  Cohort.LUVLY_COSMETIC_SCANNER,
  Cohort.LUVLY_INTRO_SALE,
  Cohort.LUVLY_INTRO3_SALE,
  Cohort.LUVLY_REF,
  Cohort.LUVLY_CHIN,
  Cohort.LUVLY_KOREAN,
  Cohort.LUVLY_WHEEL,
  Cohort.LUVLY_GENERAL,
]

export const LIVECHAT_UPGRADE_COHORTS: Cohort[] = [
  Cohort.LUVLY_CHAT_UPGRADE_12_PDF,
  Cohort.LUVLY_CHAT_UPGRADE_12_EX_PDF,
  Cohort.LUVLY_CHAT_UPGRADE_BUNDLE_PDF,
]

export const OPEN_SANS_COHORTS: Cohort[] = [
  Cohort.LUVLY_CHIN,
  Cohort.LUVLY_GIFT,
  Cohort.LUVLY_21,
  Cohort.LUVLY_GENERAL,
]

export const TRIAL_SUBSCRIPTION_AVAILABLE_COHORTS: Cohort[] = [
  Cohort.LUVLY_INTRO_FAST,
  Cohort.LUVLY_COSMETIC_SCANNER,
  Cohort.LUVLY_INTRO_SALE,
  Cohort.LUVLY_REF,
  Cohort.LUVLY_CHIN,
  Cohort.LUVLY_KOREAN,
  Cohort.LUVLY_WHEEL,
]

export const enum PlanAddition {
  BONUS = 'Bonus',
}

export const SEVEN_DAY_TRIAL_DURATION = '7'

export const MINIMUM_COUNT_OF_MONTH = 1

export const FREE_BONUS_FOR_UPGRADE = '0.00'

export const VAT_INCLUDED_COUNTRY = ['US', 'CA', 'AU']

export const I18N_CONTEXT_COHORT_MAP: Partial<Record<Cohort, string>> = {
  [Cohort.LUVLY_INTRO_FAST]: 'intro_fast',
  [Cohort.LUVLY_COSMETIC_SCANNER]: 'cosmetic',
  [Cohort.LUVLY_CHAT]: 'chat',
  [Cohort.LUVLY_12_SALE]: 'senior',
  [Cohort.LUVLY_REF]: 'refund',
  [Cohort.LUVLY_CHIN]: 'chin',
  [Cohort.LUVLY_KOREAN]: 'korean',
  [Cohort.LUVLY_GENERAL]: 'general',
}

export const PROGRESS_STEPS_LENGTH_MAP: Partial<Record<Cohort, number>> = {
  [Cohort.LUVLY_CHAT]: 30,
  [Cohort.LUVLY_12_SALE]: 34,
  [Cohort.LUVLY_CHIN]: 31,
  [Cohort.LUVLY_GIFT]: 3,
  [Cohort.LUVLY_GENERAL]: 27,
}

export const EU_COUNTRY_CODES = [
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'GB',
]

export const PAYPAL_FREE_COUNTRY_CODES = [
  'AR',
  'CO',
  'PE',
  'CL',
  'DO',
  'UY',
  'EC',
  'GT',
  'VE',
  'PY',
  'BO',
  'NI',
  'HN',
  'CR',
  'PA',
  'SV',
  'BR',
  'IN',
  'ZA',
  'EG',
  'KE',
  'NG',
  'MA',
  'DZ',
  'UG',
  'TZ',
  'SN',
  'CM',
  'GH',
  'CI',
  'MG',
  'MW',
  'MZ',
  'ZW',
  'BJ',
  'RW',
  'NE',
  'ML',
  'BF',
  'AO',
  'SS',
  'SD',
  'GA',
  'LR',
  'CD',
  'GN',
  'ET',
  'CM',
  'TZ',
  'BI',
  'UG',
  'KE',
  'CM',
  'ML',
  'NG',
  'ZA',
  'DZ',
  'UG',
  'KW',
  'EH',
  'SO',
  'SL',
  'ZA',
  'NA',
  'TZ',
  'KE',
  'DJ',
  'LY',
  'MA',
  'TD',
  'CV',
  'ZW',
  'ST',
  'MG',
  'NE',
  'BF',
  'GA',
  'ZM',
  'CG',
  'GA',
  'TG',
  'SZ',
  'SC',
  'PH',
]

export const enum CookieConsentOption {
  STRICTLY_NECESSARY = 'necessary',
  ANALYTICS_AND_PERFORMANCE = 'analytics',
  ADVERTIZING_AND_TARGETING = 'advertising',
  FUNCTIONAL_COOKIES = 'functional_cookies',
  REJECT_ALL = 'reject_all',
}

export const enum GAConsent {
  GRANTED = 'granted',
  DENIED = 'denied',
}

export const enum FBConsent {
  REVOKE = 'revoke',
  GRANT = 'grant',
}

export const enum WellnessBundleApp {
  NUTRIMATE = 'NutriMate',
  DANCEBIT = 'Dancebit',
  FITME = 'FitMe',
}

export const enum HotJarClientId {
  PROD = '4966693',
  DEV = '3925319',
}

export const HOTJAR_AVAILABLE_COHORTS: Cohort[] = [
  Cohort.LUVLY_INTRO_FAST,
  Cohort.LUVLY_CHAT,
  Cohort.LUVLY_INTRO3_SALE,
  Cohort.LUVLY_REF,
  Cohort.LUVLY_CHIN,
  Cohort.LUVLY_KOREAN,
  Cohort.LUVLY_WHEEL,
  Cohort.LUVLY_21,
  Cohort.LUVLY_GENERAL,
]

export const enum DynamicDiscountType {
  DYNAMIC = 'dynamic',
  STATIC = 'static',
}

export const enum DynamicDiscountTheme {
  VIOLET = 'violet',
  CORAL = 'coral',
  BLUE = 'blue',
  BLACK = 'black',
}

export const NAVIGATION_FREE_COHORTS = [Cohort.LUVLY_12_SALE]

export const DYNAMIC_DISCOUNT_COHORTS = [
  Cohort.LUVLY_INTRO_SALE,
  Cohort.LUVLY_12_SALE,
  Cohort.LUVLY_INTRO3_SALE,
  Cohort.LUVLY_EMAIL_SALE,
  Cohort.LUVLY_CHIN,
  Cohort.LUVLY_KOREAN,
  Cohort.LUVLY_WHEEL,
  Cohort.LUVLY_21,
]

export const PINNED_PROGRESS_BAR_WITH_NAVIGATION = {
  hasMinimalisticHeader: true,
  hasNavigation: true,
  hasProgressBar: true,
}

export const ES_IMAGES_COHORTS = [
  Cohort.LUVLY_REF,
  Cohort.QUIZ_2,
  Cohort.LUVLY_INTRO3_SALE,
]

export const enum GiftCardType {
  GREEN = 'green',
  LIGHT_PINK = 'light_pink',
  YELLOW = 'yellow',
}

export const GIFT_CARDS = {
  [GiftCardType.GREEN]:
    'https://cdn-prod-unified-content.gismart.xyz/34486912bbc40fd28752c59e42098ae09101436d76566d91d767d95811286b02/gift-cards/giftcard-green.png',
  [GiftCardType.LIGHT_PINK]:
    'https://cdn-prod-unified-content.gismart.xyz/34486912bbc40fd28752c59e42098ae09101436d76566d91d767d95811286b02/gift-cards/giftcard-light-pink.png',
  [GiftCardType.YELLOW]:
    'https://cdn-prod-unified-content.gismart.xyz/34486912bbc40fd28752c59e42098ae09101436d76566d91d767d95811286b02/gift-cards/giftcard-yellow.png',
}

export const LUVLY_ONE_LINK = 'https://luvly.onelink.me/Mqac/u3hipgfp'

export const enum ABCTestVariants {
  VARIANT_A = 'variant_a',
  VARIANT_B = 'variant_b',
  VARIANT_C = 'variant_c',
}

export const GROWTHBOOK_EXPERIMENT = {
  LV_27: 'lv_27_primer_integration',
  LV_242: 'lv_242_age_test',
  LV_758: 'lv_758_primer_paypal_test',
  LV_770: 'lv_770_static_banner_test',
  LV_823: 'lv_823_hair_guide_test',
  LV_938: 'lv_938_coaching_upsell_test',
  LV_1109: 'lv_1109_pricing_from_fitMe_test',
  LV_1002: 'lv_1002_page_map_test',
  LV_1113: 'lv_1113_chin_page_set_test',
  LV_1171: 'lv_1171_prices_99_ending_test',
}

export const COHORT_TO_TEST_MAP: Partial<Record<Cohort, string>> = {
  [Cohort.LUVLY_CHIN]: GROWTHBOOK_EXPERIMENT.LV_1113,
}

export const enum AvailableFortuneWheelAttempts {
  ZERO,
  ONE,
  TWO,
}
