import styled from 'styled-components'

import { ConnectToDBProgressBar } from 'components/ConnectToDBProgressBar'

import { Color } from 'root-constants'

export const StyledEasyWithLuvly = {
  ImageContainer: styled.div`
    width: 100%;
    max-width: 343px;
    border-radius: 12px;
    overflow: hidden;
    margin: 0 auto 24px;
  `,
  Review: styled.div`
    border-radius: 16px;
    background-color: ${Color.WHITE};
    box-shadow: 0px 4px 14px 0px rgba(8, 4, 23, 0.03);
    padding: 12px;
    text-align: center;
    margin-bottom: 24px;
  `,
  Text: styled.p`
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 10px;
    color: ${Color.GRAY};
  `,
  Author: styled.h3`
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.BLUE_DARK};
  `,
  ProgressBar: styled(ConnectToDBProgressBar)`
    color: ${Color.GRAY};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
  `,
}
