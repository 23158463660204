import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { useVatInfo } from 'hooks/useHasVatInfo'

import { getSupportFormLink } from 'helpers/getSupportFormLink'

import {
  DISCLAIMERS,
  DISCLAIMERS_WITHOUT_TAXES,
} from 'modules/purchase/components/SecurityInfo/constants'
import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { usePriceWithVatAdjustment } from 'modules/purchase/hooks/usePriceWithVatAdjustment'
import {
  selectCurrency,
  selectSubscriptionFullPrice,
  selectSubscriptionTrialPeriodDays,
  selectSubscriptionTrialPeriodPrice,
} from 'modules/purchase/redux/selects/common'

import secureBadgeImg from 'assets/images/secure-badge.png'

import { StyledSecurityInfo as S } from './SecurityInfo.styles'

type TSecurityInfoProps = {
  withPricing?: boolean
  hasTermsOfUse?: boolean
  className?: string
  disclaimerText?: string
  hasSecureBadge?: boolean
}

export const SecurityInfo: React.FC<TSecurityInfoProps> = ({
  withPricing = false,
  hasTermsOfUse = false,
  disclaimerText,
  className = '',
  hasSecureBadge = false,
}) => {
  const { t } = useTranslation()
  const currentPrice = useSelector(selectSubscriptionFullPrice)
  const trialCurrentPrice = useSelector(selectSubscriptionTrialPeriodPrice)
  const currency = useSelector(selectCurrency)
  const trialPeriodDuration = useSelector(selectSubscriptionTrialPeriodDays)
  const hasVatInfo = useVatInfo()
  const contactFormLink = getSupportFormLink()

  const localizationKeys = useMemo(() => {
    if (hasVatInfo) {
      return DISCLAIMERS_WITHOUT_TAXES
    }
    return DISCLAIMERS
  }, [hasVatInfo])

  const { getPriceWithVatAdjustment } = usePriceWithVatAdjustment()

  return (
    <S.Wrapper className={className} hasSecureBadge={hasSecureBadge}>
      {hasSecureBadge && (
        <S.SecureBadgeContainer>
          <S.SecureBadgeImg src={secureBadgeImg} alt="secure-badge" />
        </S.SecureBadgeContainer>
      )}
      <S.Title>{t`purchase1.securityInfo.titleFirst`}</S.Title>
      <S.Text>{t('purchase1.securityInfo.textFirst')}</S.Text>
      <S.Title>{t`purchase1.securityInfo.titleSecond`}</S.Title>
      <S.Text>{t('purchase1.securityInfo.textSecond')}</S.Text>
      {withPricing && (
        <>
          <S.Title>{t`purchase1.securityInfo.titleLuvlyPlan`}</S.Title>
          <S.Text>
            <Trans
              i18nKey={disclaimerText || localizationKeys[trialPeriodDuration]}
              values={{
                currentPrice: getPriceWithVatAdjustment(currentPrice),
                trialCurrentPrice: getPriceWithVatAdjustment(trialCurrentPrice),
                currency: CURRENCY_SYMBOLS[currency],
                context: currency,
              }}
              components={[<br />]}
            />
            {hasTermsOfUse && (
              <>
                {t('commonComponents.learnMore')}
                <TermsOfUseLink />.
              </>
            )}
          </S.Text>
        </>
      )}
      <S.Title>{t`purchase1.securityInfo.titleThird`}</S.Title>
      <S.Text>
        <a target="_blank" rel="noopener noreferrer" href={contactFormLink}>
          {t`commonComponents.contactSupport`}
        </a>
      </S.Text>
    </S.Wrapper>
  )
}
