export const QUESTION = 'Visits to Cosmetologist'

export const OPTION_VALUES = {
  ONCE_A_MONTH: 'once_a_month',
  ONCE_A_SEVERAL_MONTH: 'once_a_several_monthy',
  ONCE_A_YEAR: 'once_a_year',
  NEVER: 'never',
}

export const VISIT_TO_COSMETOLOGIST_ANSWERS = [
  {
    text: 'onboarding.visitToCosmetologist.oncePerMonth',
    label: OPTION_VALUES.ONCE_A_MONTH,
  },
  {
    text: 'onboarding.visitToCosmetologist.onceInSeveralMonth',
    label: OPTION_VALUES.ONCE_A_SEVERAL_MONTH,
  },
  {
    text: 'onboarding.visitToCosmetologist.oncePerYear',
    label: OPTION_VALUES.ONCE_A_YEAR,
  },
  {
    text: 'onboarding.visitToCosmetologist.never',
    label: OPTION_VALUES.NEVER,
  },
]
