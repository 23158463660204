import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledCheckoutPurchaseDetails = {
  PriceDescription: styled.div`
    margin: 12px auto 0;
    padding-bottom: ${({ theme }) =>
      theme.checkout.priceDescription.paddingBottom};
    border-bottom: 1px solid
      ${({ theme }) => theme.checkout.priceDescription.borderBottomColor};

    & > p:first-of-type {
      margin-bottom: ${({ theme }) =>
        theme.checkout.priceDescription.marginBottom};
    }
  `,
  PriceInfo: styled.p`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  PriceBlockText: styled.span`
    color: ${({ theme }) => theme.checkout.priceDescription.color};
    font-size: ${({ theme }) => theme.checkout.priceDescription.fontSize};
    font-weight: 400;
    line-height: ${({ theme }) => theme.checkout.priceDescription.lineHeight};
  `,
  TotalDiscount: styled.span`
    color: ${({ theme }) => theme.checkout.totalDiscount.color};
    font-size: ${({ theme }) => theme.checkout.totalDiscount.fontSize};
    font-weight: 600;
    line-height: ${({ theme }) => theme.checkout.totalDiscount.lineHeight};
  `,
  DailyPayment: styled.div`
    margin: ${({ theme }) => theme.checkout.totalPerDay.margin};
    border-radius: 8px;
    background-color: ${({ theme }) =>
      theme.checkout.totalPerDay.backgroundColor};
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  DailyText: styled.span`
    color: ${({ theme }) => theme.checkout.totalPerDay.subtitle.color};
    font-size: ${({ theme }) => theme.checkout.totalPerDay.subtitle.fontSize};
    font-weight: 600;
    line-height: ${({ theme }) =>
      theme.checkout.totalPerDay.subtitle.lineHeight};
  `,
  DailyAmount: styled.span`
    color: ${({ theme }) => theme.checkout.totalPerDay.price.color};
    font-size: ${({ theme }) => theme.checkout.totalPerDay.price.fontSize};
    font-weight: 600;
    line-height: ${({ theme }) => theme.checkout.totalPerDay.price.lineHeight};
  `,
  TotalPayment: styled.div`
    margin: ${({ theme }) => theme.checkout.totalToPay.margin};
  `,
  TotalDescription: styled.p`
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${Color.DARK};
    font-size: 16px;
    line-height: 24px;
  `,
  TotalText: styled.span`
    font-weight: ${({ theme }) =>
      theme.checkout.totalToPay.subtitle.fontWeight};
    color: ${({ theme }) => theme.checkout.totalToPay.subtitle.color};
    text-transform: ${({ theme }) =>
      theme.checkout.totalToPay.subtitle.textTransform};

    & > strong {
      font-weight: 600;
      font-size: ${({ theme }) => theme.checkout.totalToPay.subtitle.fontSize};
      line-height: ${({ theme }) =>
        theme.checkout.totalToPay.subtitle.lineHeight};
      text-transform: none;
    }
  `,
  TotalAmount: styled.span`
    color: ${({ theme }) => theme.checkout.totalToPay.period.color};
    font-weight: 400;
    font-size: ${({ theme }) => theme.checkout.totalToPay.period.fontSize};
    line-height: ${({ theme }) => theme.checkout.totalToPay.period.lineHeight};

    & > strong {
      color: ${({ theme }) => theme.checkout.totalToPay.price.color};
      font-weight: ${({ theme }) => theme.checkout.totalToPay.price.fontWeight};
    }
  `,
  SaveText: styled.p`
    color: ${({ theme }) => theme.checkout.savedAmount.color};
    text-align: right;
    font-weight: 600;
    font-size: ${({ theme }) => theme.checkout.savedAmount.fontSize};
    line-height: ${({ theme }) => theme.checkout.savedAmount.lineHeight};
  `,
}
