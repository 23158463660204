import styled from 'styled-components'

import { InputWithFloatPlaceholder } from 'components/InputWithFloatPlaceholder'

import { Color } from 'root-constants'

export const StyledDietVariant2 = {
  Input: styled(InputWithFloatPlaceholder)`
    & input {
      border: 1px solid ${Color.GRAY_LIGHT};
    }
  `,
}
