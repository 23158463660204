import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'
import { Option } from 'components/Option'
import { PageTitle } from 'components/PageTitle'
import { PageTitleDescription } from 'components/PageTitleDescription'

import { useCohortToUse } from 'hooks/useCohortToUse'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { goBack } from 'browser-history'
import { OptionType } from 'root-constants'

import { StyledSunSafety as S } from './SunSafety.styles'
import {
  OPTION_VALUES,
  QUESTION,
  SUN_SAFETY_CONTEXT_TEXT_MAP,
  SUN_SAFETY_DEFAULT_TEXT,
} from './constants'

export const SunSafety: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const [answer, setAnswer] = useState<string>('')
  const { isAnswersDisabled } = useDelayedContinue()
  const cohortToUse = useCohortToUse()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue(answer)

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      setAnswer(value)
    },
  }

  const pageText: {
    question: string
    description?: string
    always: string
    onlyInSunnyWeather: string
    sometimes: string
    rarely: string
    never: string
  } = SUN_SAFETY_CONTEXT_TEXT_MAP[cohortToUse] || SUN_SAFETY_DEFAULT_TEXT

  return (
    <div>
      <Container>
        <S.TitleContainer>
          <PageTitle>{t(pageText.question)}</PageTitle>
        </S.TitleContainer>
        {pageText.description && (
          <S.DescriptionContainer>
            <PageTitleDescription>
              {t(pageText.description)}
            </PageTitleDescription>
          </S.DescriptionContainer>
        )}
        <S.OptionsContainer>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.ALWAYS}
          >
            <AnswerButton>{t(pageText.always)}</AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.IN_SUNNY_WEATHER}
          >
            <AnswerButton>{t(pageText.onlyInSunnyWeather)}</AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.SOMETIMES}
          >
            <AnswerButton>{t(pageText.sometimes)}</AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.RARELY}
          >
            <AnswerButton>{t(pageText.rarely)}</AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.NEVER}
          >
            <AnswerButton>{t(pageText.never)}</AnswerButton>
          </Option>
        </S.OptionsContainer>
      </Container>
      <NavigationButtons
        onBackClick={goBack}
        onNextClick={handleNextClick}
        disabled={!answer}
      />
    </div>
  )
}
