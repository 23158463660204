import styled from 'styled-components'

import { Option } from 'components/Option'

import chevronRightCoral from 'assets/images/chevron-right-coral.png'
import chevronRightWhite from 'assets/images/chevron-right-white.png'
import variant4DesktopBg from 'assets/images/introsale4-intro-desktop.jpg'
import variant4MobileBg from 'assets/images/introsale4-intro-mobile.jpg'

import { Color, MediaBreakpoint } from 'root-constants'

type TContainerProps = {
  hasSaleBanner: boolean
}

const INTRO_HEADER_HEIGHT = '56px'
const SALE_BANNER_HEIGHT = '40px'

export const StyledAgeAsIntroWithBackground = {
  Root: styled.div`
    position: relative;
    padding-top: ${INTRO_HEADER_HEIGHT};
    display: flex;
    flex-direction: column;
    background-position: top center;
    background-image: url(${variant4MobileBg});
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      background-image: url(${variant4DesktopBg});
      background-position: top center;
    }

    @media (min-aspect-ratio: 8/5) and (min-width: ${MediaBreakpoint.TABLET}px) {
      background-position: center top -80px;
    }
  `,
  Container: styled.div<TContainerProps>`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
    min-height: ${({ hasSaleBanner }) =>
      hasSaleBanner
        ? `calc(var(--full-height) - ${INTRO_HEADER_HEIGHT} - ${SALE_BANNER_HEIGHT})`
        : `calc(var(--full-height) - ${INTRO_HEADER_HEIGHT})`};
    padding: 0 12px;
  `,
  Title: styled.h1`
    color: ${Color.WHITE};
    text-align: center;
    font-family: Rubik;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px;
    margin: 0 auto 28px;
    max-width: 300px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      margin: 0 auto 16px;
      max-width: 520px;
    }
  `,
  Subtitle: styled.p`
    color: ${Color.WHITE};
    text-align: center;
    font-family: 'Open Sans';
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  `,
  Actions: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 48px 48px;
    gap: 16px;
    margin-top: 24px;

    & label {
      flex-basis: calc(50% - 8px);
      margin-bottom: 0;
    }

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      width: 360px;
      margin: 16px auto 0;
    }
  `,
  Action: styled.div`
    display: flex;
  `,
  AnswerWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.2s;
    border-radius: 16px;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
    font-weight: 600;
    font-family: 'Open Sans';
    height: 48px;
    width: 100%;
    padding: 12px;
    color: ${Color.RED};
    background-color: ${Color.WHITE};
  `,
  AnswerValue: styled.span``,
  ChevronRight: styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: relative;
    background-color: ${Color.RED};

    &::before {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      background-image: url(${chevronRightWhite});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  `,
  StyledOption: styled(Option)`
    border-radius: 16px;

    input:checked + div > div {
      background: ${Color.RED};
      color: ${Color.WHITE};

      & > div {
        background: ${Color.WHITE};

        &::before {
          background-image: url(${chevronRightCoral});
        }
      }
    }
  `,
  Terms: styled.div`
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.5px;
    color: ${Color.CORAL_GRAY};
    margin: 24px auto 16px;
    text-align: center;

    a {
      color: ${Color.CORAL_GRAY};
      text-decoration: underline;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: ${Color.CORAL_GRAY};
    margin: 0 5px;
    vertical-align: middle;
  `,
}
