import fireIcon from 'assets/images/fire-emoji.png'
import girlSkinIcon from 'assets/images/girl-skin-emoji.png'
import sparklesIcon from 'assets/images/sparkles-emoji.png'

export const QUESTION = 'Select your goal'

export const OPTION_VALUES = {
  GET_RID: 'get_rid_of_wrinkles',
  REDUCE_FAT: 'reduce_face_fat',
  BOTH_OPTIONS: 'both_options',
  TIGHTEN_SKIN: 'tighten_skin',
}

export const GOAL_ANSWERS = {
  [OPTION_VALUES.TIGHTEN_SKIN]: {
    text: 'onboarding.goal.tightenSkin',
    icon: girlSkinIcon,
  },
  [OPTION_VALUES.REDUCE_FAT]: {
    text: 'onboarding.goal.loseFat',
    icon: fireIcon,
  },
  [OPTION_VALUES.GET_RID]: {
    text: 'onboarding.goal.getRid2',
    icon: sparklesIcon,
  },
}

export const GOAL_V3_OPTION_LABELS = {
  MORE_CONFIDENT: 'more_confident',
  LOOK_YOUNG: 'look_young',
  MORE_SCULPTED: 'more_sculpted',
  REDUCE_FACE_FAT: 'face_fat',
}

export const GOAL_V3_ANSWERS = [
  {
    text: 'onboarding.goalVariant3.option1',
    detailed: 'onboarding.goalVariant3.detailed1',
    label: GOAL_V3_OPTION_LABELS.MORE_CONFIDENT,
  },
  {
    text: 'onboarding.goalVariant3.option2',
    detailed: 'onboarding.goalVariant3.detailed2',
    label: GOAL_V3_OPTION_LABELS.LOOK_YOUNG,
  },
  {
    text: 'onboarding.goalVariant3.option3',
    detailed: 'onboarding.goalVariant3.detailed3',
    label: GOAL_V3_OPTION_LABELS.MORE_SCULPTED,
  },
  {
    text: 'onboarding.goalVariant3.option4',
    detailed: 'onboarding.goalVariant3.detailed4',
    label: GOAL_V3_OPTION_LABELS.REDUCE_FACE_FAT,
  },
]
