import React from 'react'
import { Trans } from 'react-i18next'

import { TComponentProps } from 'models/common.model'

import { SandyBackgroundGlobalStyles } from 'common-styles'

import { StyledAdaptiveContainer as S } from './AdaptiveContainer.styles'

type TTextProps = {
  title?: string
  subtitle?: string
}

export type TProps = {
  mobileBackground?: string
  desktopBackground?: string
  fullWidth?: boolean
  hasCustomBackground?: boolean
  className?: string
}

type TAdaptiveContainerProps = TTextProps & TProps & TComponentProps

export const AdaptiveContainer = ({
  title,
  subtitle,
  children,
  mobileBackground,
  desktopBackground,
  hasCustomBackground = false,
  fullWidth = false,
  className,
}: TAdaptiveContainerProps) => {
  return (
    <S.Root
      mobileBackground={mobileBackground}
      desktopBackground={desktopBackground}
      className={className}
    >
      <S.Content>
        {title && (
          <S.Title>
            <Trans i18nKey={title} />
          </S.Title>
        )}
        {subtitle && (
          <S.Subtitle>
            <Trans i18nKey={subtitle} />
          </S.Subtitle>
        )}
        <S.OptionsContainer fullWidth={fullWidth || !mobileBackground}>
          {children}
        </S.OptionsContainer>
      </S.Content>
      {!hasCustomBackground && <SandyBackgroundGlobalStyles />}
    </S.Root>
  )
}

AdaptiveContainer.Title = S.Title
AdaptiveContainer.Subtitle = S.Subtitle
