import styled from 'styled-components'

import { Button } from 'components/Button'

import primaryCheck from 'assets/images/primary-check.png'

import { Color } from 'root-constants'

export const StyledUpsellBenefits = {
  Root: styled.div`
    position: relative;
  `,
  Benefits: styled.div`
    max-width: 320px;
    margin: 0 auto 81px auto;
    position: relative;
    box-shadow: 0 8px 20px #e8e8e8;
    background-color: ${Color.WHITE};
    padding-top: 17px;
    border-radius: 14px 14px 0 0;
  `,
  Title: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: ${Color.DARK};
    text-align: center;
    margin-bottom: 16px;
  `,
  List: styled.ul`
    padding: 0;
    margin: 0;
    padding-right: 25px;
  `,
  Item: styled.li`
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: ${Color.DARK};
    padding-left: 47px;
    margin-bottom: 12px;
    position: relative;

    &::after {
      display: block;
      content: '';
      width: 20px;
      height: 20px;
      background: url(${primaryCheck}) no-repeat center;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 17px;
      transform: translateY(-50%);
    }

    &:last-child {
      margin-bottom: 0;
    }
  `,
  WaveShapeItem: styled.li`
    height: 45px;
    background-color: transparent;
    display: flex;
    flex-direction: row-reverse;
    overflow: hidden;
    position: absolute;
    bottom: -45px;
    left: 0;
    right: 0;
    z-index: 1;

    &::after {
      display: block;
      content: '';
      width: 100%;
      height: 19px;
      background-color: ${Color.WHITE};
      z-index: 3;
      position: absolute;
      left: 0;
      top: 0;
    }
  `,
  WaveShapeSlice: styled.div`
    width: 16.666666%;
    height: 34px;
    border-radius: 50% 50% 50% 50% / 0% 0% 100% 100%;
    background-color: ${Color.WHITE};
    position: relative;
    box-shadow: 0 1px 7px 5px #e8e8e8;

    &:last-child {
      &::before {
        display: block;
        content: '';
        position: absolute;
        width: 19px;
        height: 18px;
        border: 7px solid ${Color.WHITE};
        border-top: none;
        border-bottom: none;
        border-right: none;
        border-radius: 120px/103px;
        box-sizing: content-box;
        -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        left: -12px;
        bottom: -4px;
      }
    }

    &::after {
      display: block;
      content: '';
      position: absolute;
      width: 19px;
      height: 18px;
      border: 6px solid ${Color.WHITE};
      border-top: none;
      border-bottom: none;
      border-right: none;
      border-radius: 120px/103px;
      box-sizing: content-box;
      -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
      transform: rotate(90deg);
      right: -12px;
      bottom: -5px;
    }
  `,
  Details: styled.div`
    max-width: 320px;
    margin: 0 auto 32px auto;
  `,
  Detailed: styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &:last-child {
      margin: 0;
    }
  `,
  DetailedInfo: styled.div`
    flex-grow: 1;
    &:first-child {
      margin-right: 16px;
    }
  `,
  DetailedPicture: styled.img`
    min-width: 88px;

    &:first-child {
      margin-right: 16px;
    }
  `,
  DetailedTitle: styled.h3`
    font-weight: ${({ theme }) => theme.common.semiBoldFontWeight};
    font-size: 18px;
    line-height: 21px;
    color: ${Color.DARK};
    margin-bottom: 8px;
  `,
  DetailedDescription: styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${Color.LIGHT};
  `,
  Disclaimer: styled.div`
    max-width: 320px;
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px #d1d6eb;
    border-radius: 14px;
    padding: 16px 8px;
    margin: 0 auto 24px auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${Color.LIGHT};
    text-align: center;

    a {
      color: #0091ff;
      text-decoration: underline;
    }
  `,
  Actions: styled.div`
    max-width: 320px;
    margin: 0 auto;
    text-align: center;
  `,
  ConfirmAction: styled(Button)`
    margin-bottom: 16px;
  `,
  DeclineAction: styled.button`
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    border: none;
    background: none;
    color: ${Color.LIGHT};
    text-decoration: underline;
    padding: 0;
  `,
  SupportLink: styled.a`
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #0091ff;
    text-decoration: underline;
  `,
}
