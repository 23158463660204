import React from 'react'
import { Trans } from 'react-i18next'

import coffeeImage from 'assets/images/coffee-cup.png'

import { StyledCoffeeBanner as S } from './CoffeeBanner.styles'

export const CoffeeBanner: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Coffee src={coffeeImage} alt="coffee" />

      <S.Text>
        <Trans
          i18nKey="purchaseRefund.coffeeBanner"
          components={{
            emoji: <S.Emoji />,
          }}
        />
      </S.Text>
    </S.Wrapper>
  )
}
