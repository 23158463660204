import { UserGoal } from 'root-constants'

export const USER_GOAL_CONFIG_MAP = {
  [UserGoal.TIGHTEN_SKIN]: {
    currentLevelIndicator: 1,
    goalLevelIndicator: 4,
    goalText: 'purchase7.facialCondition.goals.collagenLevel',
  },
  [UserGoal.BOTH_OPTIONS]: {
    currentLevelIndicator: 1,
    goalLevelIndicator: 4,
    goalText: 'purchase7.facialCondition.goals.collagenLevel',
  },
  [UserGoal.REDUCE_FACE_FAT]: {
    currentLevelIndicator: 4,
    goalLevelIndicator: 1,
    goalText: 'purchase7.facialCondition.goals.faceFatLevel',
  },
  [UserGoal.WRINKLE_FREE_SKIN]: {
    currentLevelIndicator: 4,
    goalLevelIndicator: 1,
    goalText: 'purchase7.facialCondition.goals.wrinklesLevel',
  },
}

export const LEVEL_INDICATION_BLANK = [1, 2, 3, 4, 5]

export const ANIMATION_PATH = '/animations/user-result-animation'
export const ANIMATION_PATH_MALE = '/animations/user-result-animation_male'

export const ANIMATION_DOUBLE_CHIN_FEMALE =
  '/animations/female-double-chin-result'
export const ANIMATION_DOUBLE_CHIN_MALE = '/animations/male-double-chin-result'
export const ANIMATION_DOUBLE_CHIN_FEMALE_2 =
  '/animations/female-double-chin-result-2'
export const ANIMATION_DOUBLE_CHIN_MALE_2 =
  '/animations/male-double-chin-result-2'
export const ANIMATION_NEW_YEAR = '/animations/new-year-result'
