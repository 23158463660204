import React, { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'

import { selectAnswers } from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import animationConfig from 'assets/animation/worldMapAnimation.json'

import { goBack } from 'browser-history'
import { PageId } from 'page-constants'
import { Gender, I18N_CONTEXT_COHORT_MAP } from 'root-constants'

import { StyledYouAreInGoodHands as S } from './YouAreInGoodHands.styles'
import { QUESTION } from './constants'

export const YouAreInGoodHands: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const animationContainerRef = useRef<HTMLDivElement>(null)
  const userAnswers = useSelector(selectAnswers)
  const cohortToUse = useCohortToUse()
  const { isSeniorCohort } = useActiveCohortIdentifier()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const [gender, age] = useMemo(() => {
    const userGender =
      userAnswers?.[PageId.GENDER] === Gender.MALE
        ? t`commonComponents.males`
        : t`commonComponents.females`
    const userAge = userAnswers?.[PageId.AGE] || 'age'

    return [userGender, userAge]
  }, [t, userAnswers])

  useEffect(() => {
    if (animationContainerRef.current) {
      lottie.loadAnimation({
        container: animationContainerRef.current,
        animationData: animationConfig,
        loop: false,
      })
    }

    return () => lottie.destroy()
  }, [])

  const handleNextClick = () => handleContinue('')

  return (
    <div>
      <Container>
        <S.Title>{t`onboarding.youAreInGoodHands.question`}</S.Title>
        <S.AnimationContainer ref={animationContainerRef} />
        <S.Subtitle>
          {isSeniorCohort && userAnswers?.[PageId.GENDER] === Gender.NON_BINARY
            ? t(`onboarding.youAreInGoodHands.subtitleOtherGender`)
            : t('onboarding.youAreInGoodHands.subtitle', {
                gender,
                age,
                context: I18N_CONTEXT_COHORT_MAP[cohortToUse] || '',
              })}
        </S.Subtitle>
      </Container>
      <NavigationButtons onBackClick={goBack} onNextClick={handleNextClick} />
    </div>
  )
}
