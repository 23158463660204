export const QUESTION =
  'As a busy man/woman in his 30s, how much time are you willing to spend on face yoga daily?'

export const OPTION_VALUES = {
  FIVE_MINUTES: 'five_minutes',
  TEN_MINUTES: 'ten_minutes',
  FIFTEEN_MINUTES: 'fifteen_minutes',
}

export const DAILY_YOGA_ANSWERS = [
  {
    value: OPTION_VALUES.FIVE_MINUTES,
    text: 'onboarding.dailyYoga.option1',
  },
  {
    value: OPTION_VALUES.TEN_MINUTES,
    text: 'onboarding.dailyYoga.option2',
  },
  {
    value: OPTION_VALUES.FIFTEEN_MINUTES,
    text: 'onboarding.dailyYoga.option3',
  },
]
