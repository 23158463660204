import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  AnswerAccordion,
  Button,
  answerAccordionTheme,
  buttonTheme,
} from 'storybook-ui'

import { Container } from 'components/Container'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import infoIcon from 'assets/images/information-icon.png'

import { DoubleChinTitle, SandyBackgroundGlobalStyles } from 'common-styles'
import { PageId } from 'page-constants'
import { OptionType } from 'root-constants'

import { QUESTION, SKIN_TYPE_VARIANT_2_ANSWERS } from './constants'

export const SkinTypeVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answer, setAnswer] = useState<string>('')

  const handleContinue = useNextStep({
    pageId: PageId.SKIN_TYPE,
    question: QUESTION,
    nextPagePath,
  })

  const handleAnswerChange = (value: string) => {
    setAnswer(value)
  }

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    theme: answerAccordionTheme.LUVLY,
    iconSrc: infoIcon,
    iconWidth: '24px',
    iconHeight: '24px',
    spacingBetweenIconAndContent: '12px',
    fontWeight: '600',
    onChange: handleAnswerChange,
  }

  const handleNextClick = () => handleContinue(answer)

  return (
    <div>
      <Container>
        <DoubleChinTitle>
          <Trans
            i18nKey="onboarding.skinType.questionV2"
            components={[<strong />]}
          />
        </DoubleChinTitle>
        {SKIN_TYPE_VARIANT_2_ANSWERS.map(({ detailed, value, label }) => {
          return (
            <AnswerAccordion
              {...optionProps}
              key={label}
              hiddenText={t(detailed)}
              value={value}
            >
              {t(label)}
            </AnswerAccordion>
          )
        })}

        <SandyBackgroundGlobalStyles />
      </Container>
      <StickyButtonContainer centerMode>
        <Button
          onClick={handleNextClick}
          disabled={!answer}
          theme={buttonTheme.LUVLY_SECONDARY}
        >
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
    </div>
  )
}
