import styled, { keyframes } from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import { Color, MediaBreakpoint } from 'root-constants'

const spinKeyframes = keyframes`
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
`

export const StyledPreparePlanV2 = {
  Root: styled.div`
    border-top: 0.5px solid rgba(0, 0, 0, 0.25);
  `,
  SpinnerContainer: styled.div`
    margin: 8px 0 24px;
    text-align: center;
  `,
  Text: styled.p`
    margin-top: -10px;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.GRAY};
  `,
  Circle: styled(SvgImage)`
    animation-name: ${spinKeyframes};
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 343/343;
  `,
  Image: styled.img`
    max-width: 100%;
  `,
  SlideText: styled.p`
    margin-top: 32px;
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    color: ${Color.BLUE_DARK};

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      margin-top: 40px;
    }
  `,
}
