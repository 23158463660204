import cheeksIconMan from 'assets/images/sprite/cheeks-2.svg'
import cheeksIconWoman from 'assets/images/sprite/cheeks.svg'
import chinIconMan from 'assets/images/sprite/chin-2.svg'
import chinIconWoman from 'assets/images/sprite/chin.svg'
import eyesIconMan from 'assets/images/sprite/eyes-2.svg'
import eyesIconWoman from 'assets/images/sprite/eyes.svg'
import foreheadIconMan from 'assets/images/sprite/forehead-2.svg'
import foreheadIconWoman from 'assets/images/sprite/forehead.svg'
import jawlineIconMan from 'assets/images/sprite/jawline-2.svg'
import jawlineIconWoman from 'assets/images/sprite/jawline.svg'
import mouthIconMan from 'assets/images/sprite/mouth-2.svg'
import mouthIconWoman from 'assets/images/sprite/mouth.svg'
import neckIconMan from 'assets/images/sprite/neck-2.svg'
import neckIconWoman from 'assets/images/sprite/neck.svg'

import { OPTION_VALUES } from '../../constants'

export const PROBLEMATIC_AREAS_WOMAN = [
  {
    value: OPTION_VALUES.CHEEKS,
    width: 78,
    top: '156px',
    left: '41px',
    icon: cheeksIconWoman,
  },
  {
    value: OPTION_VALUES.CHIN,
    width: 43,
    top: '214px',
    left: '119px',
    icon: chinIconWoman,
  },
  {
    value: OPTION_VALUES.EYES,
    width: 56,
    top: '104px',
    left: '59px',
    icon: eyesIconWoman,
  },
  {
    value: OPTION_VALUES.FOREHEAD,
    width: 69,
    top: '51px',
    left: '44px',
    icon: foreheadIconWoman,
  },
  {
    value: OPTION_VALUES.JAWLINE,
    width: 84,
    top: '185px',
    left: '29px',
    icon: jawlineIconWoman,
  },
  {
    value: OPTION_VALUES.MOUTH,
    width: 39,
    top: '176px',
    left: '124px',
    icon: mouthIconWoman,
  },
  {
    value: OPTION_VALUES.NECK,
    width: 72,
    top: '226px',
    left: '2px',
    icon: neckIconWoman,
  },
]

export const PROBLEMATIC_AREAS_MAN = [
  {
    value: OPTION_VALUES.CHEEKS,
    width: 73,
    top: '166px',
    left: '52px',
    icon: cheeksIconMan,
  },
  {
    value: OPTION_VALUES.CHIN,
    width: 45,
    top: '240px',
    left: '116px',
    icon: chinIconMan,
  },
  {
    value: OPTION_VALUES.EYES,
    width: 68,
    top: '124px',
    left: '63px',
    icon: eyesIconMan,
  },
  {
    value: OPTION_VALUES.FOREHEAD,
    width: 70,
    top: '73px',
    left: '60px',
    icon: foreheadIconMan,
  },
  {
    value: OPTION_VALUES.JAWLINE,
    width: 79,
    top: '206px',
    left: '33px',
    icon: jawlineIconMan,
  },
  {
    value: OPTION_VALUES.MOUTH,
    width: 40,
    top: '208px',
    left: '124px',
    icon: mouthIconMan,
  },
  {
    value: OPTION_VALUES.NECK,
    width: 72,
    top: '252px',
    left: '6px',
    icon: neckIconMan,
  },
]
