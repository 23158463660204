import styled, { keyframes } from 'styled-components'

import { CustomBackgroundStyles, customBackgroundStyles } from 'common-styles'
import { Color } from 'root-constants'

const scaleUp = keyframes` 
    0% {
      transform: scale(1);
    }
    5% {
      transform: scale(1.065);
    }
    10% {
      transform: scale(1);
    }
  `

export const StyledFaceYogaRoutine = {
  Root: styled.div<{ background: CustomBackgroundStyles }>`
    ${customBackgroundStyles}
  `,
  Title: styled.h1`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.WHITE};
    margin-bottom: 8px;
  `,
  Subtitle: styled.h3`
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.WHITE};
    margin-bottom: 20px;
  `,
  Answer: styled.div`
    display: grid;
    grid-template-columns: 40px 1fr 24px 35px;
    column-gap: 12px;
    border-radius: 12px;
    background: ${Color.WHITE};
    box-shadow: 0px 4px 14px 0px rgba(8, 4, 23, 0.03);
    padding: 8px 16px;
    align-items: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.BLUE_DARK};
    border: 2px solid transparent;
    transition: 0.15s all;
    margin-bottom: 12px;
    min-height: 60px;
    &:last-child {
      margin-bottom: 0;
    }

    &:nth-of-type(2) {
      animation: ${scaleUp} 6s ease-in-out 0.5s infinite;
    }

    &:nth-of-type(4) {
      animation: ${scaleUp} 6s ease-in-out 1.25s infinite;
    }
  `,
}
