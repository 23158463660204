import styled from 'styled-components'

import { FixedButton } from 'components/FixedButton'
import { LoadingButton } from 'components/LoadingButton'
import { PartnersContainer } from 'components/PartnersContainer'

import { HolidaysCancelOfferReviews } from 'modules/purchase/components/HolidaysCancelOfferReviews'
import { StyledHolidaysCancelOfferReviews } from 'modules/purchase/components/HolidaysCancelOfferReviews/HolidaysCancelOfferReviews.styles'

import rightArrow from 'assets/images/right-arrow.svg'

import {
  commonBackdropStyles,
  commonMenuCloseButtonStyles,
  commonMenuStyles,
} from 'common-styles'
import { Color, MediaBreakpoint } from 'root-constants'

type TMenuProps = {
  isMenuVisible: boolean
}

export const StyledIntroVariant2 = {
  Root: styled.div`
    background-color: #fef8f4;
  `,
  Header: styled.header`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    background: #fef8f4;
    padding: 11px 16px 11px 24px;
  `,
  Logo: styled.img`
    max-width: 58px;
    height: auto;
    margin: 0 auto 0 0;
  `,

  LoadingButton: styled(LoadingButton)`
    border: 1px solid ${Color.DARK_SECONDARY};
    color: ${Color.DARK_SECONDARY};

    & > div {
      background-color: ${Color.DARK_SECONDARY};
    }
  `,
  SupportAction: styled.button`
    border: none;
    background-color: #f9eae3;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    color: ${Color.DARK_SECONDARY};
    text-transform: uppercase;
    letter-spacing: 1.5px;
    margin: 0 8px 0 0;
    cursor: pointer;

    a {
      color: inherit;
      padding: 8px 8px 6px 8px;
      line-height: 28px;
    }
  `,
  Burger: styled.button`
    width: 20px;
    height: 17px;
    background: transparent;
    border: 0;
    border-radius: 0;
    overflow: hidden;
    position: relative;
    padding: 0;
    cursor: pointer;
  `,
  BurgerLine: styled.span`
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 14px;
    background-color: ${Color.DARK_SECONDARY};
    position: absolute;
    left: 0;
    top: 0;

    &:nth-child(2),
    &:nth-child(3) {
      top: calc(50% - 1px);
    }

    &:last-child {
      top: auto;
      bottom: 0;
    }
  `,
  Backdrop: styled.div<TMenuProps>`
    ${commonBackdropStyles};
    z-index: ${({ isMenuVisible }) => (isMenuVisible ? '4' : '-1')};
  `,
  Menu: styled.nav`
    ${commonMenuStyles};
  `,
  MenuCloseButton: styled.button`
    ${commonMenuCloseButtonStyles}
  `,
  StyledMenuLinkContainer: styled.div`
    font-size: 20px;
    line-height: 24px;
    color: rgb(45, 50, 64);
    margin-bottom: 35px;

    a {
      color: inherit;
    }
  `,
  BannerContainer: styled.div`
    max-width: ${MediaBreakpoint.MAX_PHONE}px;
    margin: 30px auto 2px;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
  Banner: styled.img`
    min-width: 360px;
    min-height: 204px;
    height: auto;
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    max-width: 360px;
    margin: 0 auto;
  `,
  Title: styled.h1`
    font-size: 28px;
    font-family: 'Libre Bodoni', sans-serif;
    line-height: 34px;
    text-align: center;
    margin: 0 0 12px 0;
    padding: 0 16px;

    & > strong {
      color: ${Color.PRIMARY};
      font-family: 'Libre Bodoni', sans-serif;
    }
  `,
  Subtitle: styled.p`
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: ${Color.LIGHT};
    margin: 0 0 21px 0;
    padding: 0 16px;
  `,
  AmbassadorCard: styled.div`
    display: flex;
    gap: 14px;
    align-items: center;
    margin: 0 0 17px 0;
    padding: 0 16px;
  `,
  AmbassadorPhoto: styled.div`
    width: 147px;
    min-height: 146px;
    height: auto;
  `,
  AmbassadorInfo: styled.div`
    max-width: 170px;
    text-align: left;
  `,
  AmbassadorTitle: styled.h2`
    font-size: 18px;
    line-height: 22px;
    color: ${Color.DARK};
    margin: 0 0 8px 0;
  `,
  AmbassadorResult: styled.p`
    font-size: 13px;
    line-height: 18px;
    color: ${Color.LIGHT};
  `,
  FixedButton: styled(FixedButton)`
    max-width: 320px;
    width: 100%;
    margin: 0 auto 48px;
    padding: 14px 16px;
    background-color: ${Color.PRIMARY};
  `,
  ButtonContent: styled.span`
    color: ${Color.WHITE};
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;

    &:after {
      position: absolute;
      content: url(${rightArrow});
      height: 24px;
      width: 24px;
      right: 0;
    }
  `,
  ReviewsContainer: styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 0 16px 0;
  `,
  ReviewsTitle: styled.h2`
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: ${Color.BLUE_DARK};
    margin: 0 0 16px 0;
    padding: 0 16px;
  `,
  ReviewsSubtitle: styled.p`
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    color: ${Color.LIGHT};
    margin: 0 0 24px 0;
    padding: 0 16px;

    & > strong {
      color: ${Color.PRIMARY};
    }
  `,
  Reviews: styled(HolidaysCancelOfferReviews)`
    max-width: 360px;
    margin: 0;
    padding: 0;
    ${StyledHolidaysCancelOfferReviews.Card} {
      background-color: ${Color.WHITE};
      box-shadow: 0 8px 20px #d1d6eb;
    }
  `,
  PartnersContainer: styled(PartnersContainer)`
    padding: 0 16px 0 16px;
  `,
  Terms: styled.div`
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: ${Color.LIGHT};
    margin: 0 auto 100px;

    a {
      color: ${Color.LIGHT};
      text-decoration: underline;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: ${Color.LIGHT};
    margin: 0 5px;
    vertical-align: middle;
  `,
}
