export const QUESTION = 'Eating patterns'

export const OPTION_VALUES = {
  LESS_THAN_THREE_TIMES: 'less_than_three_times',
  AT_LEAST_THREE_TIMES: 'at_least_three_times',
  DIFFERENTLY: 'differently',
}

export const EATING_PATTERNS_ANSWERS_MAP = {
  [OPTION_VALUES.LESS_THAN_THREE_TIMES]:
    'onboarding.eatingPatterns.lessThanThreeTimes',
  [OPTION_VALUES.AT_LEAST_THREE_TIMES]:
    'onboarding.eatingPatterns.atLeastThreeTimes',
  [OPTION_VALUES.DIFFERENTLY]: 'onboarding.eatingPatterns.differently',
}

export const EATING_PATTERNS_ANSWERS = [
  {
    text: 'onboarding.eatingPatterns.lessThanThreeTimes',
    label: OPTION_VALUES.LESS_THAN_THREE_TIMES,
  },
  {
    text: 'onboarding.eatingPatterns.atLeastThreeTimes',
    label: OPTION_VALUES.AT_LEAST_THREE_TIMES,
  },
  {
    text: 'onboarding.eatingPatterns.varies',
    label: OPTION_VALUES.DIFFERENTLY,
  },
]
