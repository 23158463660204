import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledWellhubDownload = {
  Header: styled.header`
    width: 100%;
    background-color: ${Color.WHITE};
    padding: 10px 16px 0;
    display: flex;
    align-items: center;
    position: relative;
    height: 52px;
    margin-bottom: 0;
    padding: 5px 16px;
    z-index: 3;
    justify-content: center;
  `,
  PageNumberContainer: styled.div`
    position: absolute;
    min-width: 55px;
    text-align: right;
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.5px;
    right: 16px;
    top: 16px;

    & > span:first-of-type {
      color: ${Color.CORAL};
    }

    & > span:not(:first-of-type) {
      color: ${Color.BLUE_DARK};
    }
  `,
  Title: styled.h1`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.BLUE_DARK};
    text-align: center;
    margin: 24px auto;
  `,
  Image: styled.img`
    max-width: 100%;
    margin-bottom: 32px;
    aspect-ratio: 343/318;
  `,
}
