import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useGrowthBook } from '@growthbook/growthbook-react'

import { Container } from 'components/Container'
import { Spinner } from 'components/Spinner'
import { SvgImage } from 'components/SvgImage'

import { getSubscriptionListAction } from 'root-redux/actions/common'
import {
  selectCurrentVariantCohort,
  selectDownloadLinks,
  selectLanguage,
} from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useABTest } from 'hooks/useABTest'
import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useVatInfo } from 'hooks/useHasVatInfo'
import { useIsCurrentRoute } from 'hooks/useIsCurrentRoute'
import { useTrackPageScrollDepth } from 'hooks/useTrackPageScrollDepth'

import { getButtonTapEventData } from 'helpers/getButtonTapEventData'
import { getDeepLink } from 'helpers/getDeepLink'

import { ConfirmPurchaseModal } from 'modules/purchase/components/ConfirmPurchaseModal'
import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'
import { SkipUpsellCoachingBlock } from 'modules/purchase/components/SkipUpsellCoachingBlock'
import { UpsellCoachingBeforeAfter } from 'modules/purchase/components/UpsellCoachingBeforeAfter'
import { UpsellCoachingBenefits } from 'modules/purchase/components/UpsellCoachingBenefits'
import { UpsellCoachingCancelOfferBanner } from 'modules/purchase/components/UpsellCoachingCancelOfferBanner'
import { UpsellCoachingFeatures } from 'modules/purchase/components/UpsellCoachingFeatures'
import { UpsellCoachingResults } from 'modules/purchase/components/UpsellCoachingResults'
import { UpsellCoachingReviews } from 'modules/purchase/components/UpsellCoachingReviews'
import { UpsellCoachingSteps } from 'modules/purchase/components/UpsellCoachingSteps'
import { UpsellCoachingSubscriptionBlock } from 'modules/purchase/components/UpsellCoachingSubscriptionBlock'
import { usePricesStatus, usePurchaseStore } from 'modules/purchase/hooks'
import { useUpsellScrollEvent } from 'modules/purchase/hooks/useUpsellScrollEvent'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import { applySecondarySubscriptionAction } from 'modules/purchase/redux/actions/upsell'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import arrowIcon from 'assets/images/sprite/right-arrow.svg'

import { browserHistory, goTo } from 'browser-history'
import { PageId, UPSELL_PAGES_MAP } from 'page-constants'
import {
  CUSTOM_TOKEN_LOCAL_STORAGE_KEY,
  Cohort,
  Locale,
  ScreenName,
  SubscriptionListType,
  SubscriptionTagsType,
} from 'root-constants'

import { StyledUpsellCoaching as S } from './UpsellCoaching.styles'
import { PERMANENT_AVAILABLE_COACHING_UPSELL_COHORTS } from './constants'

export const UpsellCoaching: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch: TAppDispatch = useDispatch()
  const growthBook = useGrowthBook()

  const {
    isCoachingUpsellActive,
    isRefundNormalizedPricingActive,
    isPurchaseWithoutConfirmationActive,
  } = useABTest()
  const { isLuvlyEmailCoaching } = useActiveCohortIdentifier()

  const [isPricesStartedFetching, setIsPricesStartedFetching] = useState(false)
  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState(false)
  const [isModalShown, setIsModalShown] = useState<boolean>(false)
  const [isSkipCoachingBlockShown, setIsSkipCoachingBlockShown] =
    useState<boolean>(false)

  const { arePricesReady } = usePricesStatus()
  const { subscriptions } = usePurchaseStore()
  const isCancelOffer = useIsCurrentRoute(PageId.COACHING_CANCEL_PAYWALL)
  const hasVatInfo = useVatInfo()
  const cohortToUse = useCohortToUse()
  const cohort = useSelector(selectCurrentVariantCohort) as Cohort
  const downloadLinks = useSelector(selectDownloadLinks)
  const language = useSelector(selectLanguage)

  const screenName = isCancelOffer
    ? ScreenName.UPSELL_COACHING_CANCEL
    : ScreenName.UPSELL_COACHING

  const { scrollEvent } = useUpsellScrollEvent(screenName)

  useTrackPageScrollDepth({
    trackedBreakpoints: [66, 100],
    eventCallback: scrollEvent,
    preventSendingEvents: isSkipCoachingBlockShown,
  })

  const getSubscriptionList = useCallback(() => {
    const pricesTags: SubscriptionTagsType[] = [
      SubscriptionTagsType.COACHING,
      hasVatInfo ? SubscriptionTagsType.TAX : SubscriptionTagsType.NO_TAX,
      ...(isCancelOffer ? [SubscriptionTagsType.CANCEL_OFFER] : []),
      ...(isRefundNormalizedPricingActive
        ? [SubscriptionTagsType.ROUNDED_TO_99]
        : []),
    ]

    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.SECONDARY,
        pricesTags.join(','),
      ),
    )
    setIsPricesStartedFetching(true)
  }, [dispatch, hasVatInfo, isCancelOffer, isRefundNormalizedPricingActive])

  // should check cohort without parent cohort
  const nonTestCoachingUpsellCohort =
    PERMANENT_AVAILABLE_COACHING_UPSELL_COHORTS.includes(cohort) &&
    language === Locale.ENGLISH

  useLayoutEffect(() => {
    if (nonTestCoachingUpsellCohort || !growthBook?.ready) return

    if (!isCoachingUpsellActive) {
      browserHistory.replace({
        pathname: nextPagePath || PageId.FINISHING_TOUCHES,
        search,
      })
      return
    }

    getSubscriptionList()
  }, [
    cohort,
    getSubscriptionList,
    growthBook?.ready,
    isCoachingUpsellActive,
    nextPagePath,
    nonTestCoachingUpsellCohort,
    search,
  ])

  useLayoutEffect(() => {
    if (nonTestCoachingUpsellCohort) getSubscriptionList()
  }, [getSubscriptionList, nonTestCoachingUpsellCohort])

  useEffect(() => {
    const coachingSubscription =
      subscriptions.find(
        (subscription) => subscription?.type === SubscriptionListType.SECONDARY,
      ) || subscriptions[0]

    dispatch(setSelectedSubscriptionAction(coachingSubscription))
  }, [dispatch, subscriptions])

  useEffect(() => {
    if (isPricesStartedFetching && arePricesReady && !subscriptions.length) {
      goTo({
        pathname: nextPagePath || PageId.FINISHING_TOUCHES,
        search,
      })
    }
  }, [
    subscriptions,
    search,
    arePricesReady,
    nextPagePath,
    isPricesStartedFetching,
  ])

  useEffect(() => {
    if (isPricesStartedFetching && arePricesReady && !!subscriptions.length) {
      eventLogger.logUpsellPurchaseShown(screenName)

      setIsPricesStartedFetching(false)

      window.axon && window.axon('track', 'page_view')
    }
  }, [
    arePricesReady,
    subscriptions.length,
    isPricesStartedFetching,
    screenName,
  ])

  const goToCancelOffer = () => {
    setIsSkipCoachingBlockShown(false)
    eventLogger.logCancelOfferPopupClose('great')

    goTo({
      pathname: PageId.COACHING_CANCEL_PAYWALL,
      search,
    })
  }

  const handleSkip = () => {
    if (isSkipCoachingBlockShown) {
      const currentUpsellPageIndex = UPSELL_PAGES_MAP[cohortToUse].findIndex(
        (page) => page === nextPagePath,
      )

      setIsSkipCoachingBlockShown(false)
      eventLogger.logCancelOfferPopupClose('skip')

      goTo({
        pathname:
          UPSELL_PAGES_MAP[cohortToUse][currentUpsellPageIndex + 1] ||
          PageId.FINISHING_TOUCHES,
        search,
      })
      return
    }

    eventLogger.logUpsellPurchaseClose(screenName)

    if (isCancelOffer) {
      goTo({
        pathname: nextPagePath || PageId.FINISHING_TOUCHES,
        search,
      })
      return
    }

    eventLogger.logCancelOfferPopupShow()
    setIsSkipCoachingBlockShown(true)
  }

  const handleMakePurchase = useCallback(() => {
    eventLogger.logConfirmationModalAction('confirm')
    dispatch(applySecondarySubscriptionAction(screenName))
    setIsModalShown(false)
  }, [dispatch, screenName])

  const handleContinueButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    const { buttonText, buttonOrder } = getButtonTapEventData(event)
    eventLogger.logUpsellPageButtonClicked({
      buttonText,
      buttonNumber: buttonOrder,
      screenName,
    })

    if (isPurchaseWithoutConfirmationActive) {
      dispatch(applySecondarySubscriptionAction(screenName))
      return
    }

    setIsModalShown(true)
  }

  const handleCloseConfirmModal = () => {
    setIsModalShown(false)
    eventLogger.logConfirmationModalAction('skip')

    if (!isCancelOffer) {
      goTo({
        pathname: PageId.COACHING_CANCEL_PAYWALL,
        search,
      })
    }
  }

  const renderCoachingDetails = () => {
    if (isCancelOffer) return null

    return (
      <>
        <UpsellCoachingFeatures />
        <UpsellCoachingSteps />

        <S.UpsellCoachingSubscriptionBlock>
          <UpsellCoachingSubscriptionBlock
            onSkipButtonClick={handleSkip}
            onContinueButtonClick={handleContinueButtonClick}
            buttonOrder={2}
          />
        </S.UpsellCoachingSubscriptionBlock>

        <UpsellCoachingBeforeAfter />
        <UpsellCoachingReviews />
        <SecurityInfo hasSecureBadge />
      </>
    )
  }

  const goToApp = () => {
    const customToken = localStorage.getItem(CUSTOM_TOKEN_LOCAL_STORAGE_KEY)

    const deepLink = getDeepLink(customToken, downloadLinks)

    window.location.href = deepLink
  }

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      {!isLuvlyEmailCoaching && (
        <S.SkipBtn onClick={handleSkip}>
          {t('actions.skip')}
          <SvgImage svg={arrowIcon} width={20} height={20} />
        </S.SkipBtn>
      )}

      <S.Root>
        {!isSkipCoachingBlockShown && (
          <Container fields={20}>
            <UpsellCoachingCancelOfferBanner />

            <UpsellCoachingResults />
            <UpsellCoachingBenefits />

            <UpsellCoachingSubscriptionBlock
              onSkipButtonClick={handleSkip}
              onContinueButtonClick={handleContinueButtonClick}
              buttonOrder={1}
            />
            {renderCoachingDetails()}
          </Container>
        )}

        {isSkipCoachingBlockShown && (
          <SkipUpsellCoachingBlock handleContinue={goToCancelOffer} />
        )}

        {isModalShown && (
          <ConfirmPurchaseModal
            closeModal={handleCloseConfirmModal}
            confirmPurchase={handleMakePurchase}
          />
        )}

        <PaymentWaitingModal
          isPaymentWaitingShown={isPaymentWaitingShown}
          setIsPaymentWaitingShown={setIsPaymentWaitingShown}
          buttonText={t`purchase1.paymentWaiting.proceedToPlan`}
          {...(isLuvlyEmailCoaching && { successCallback: goToApp })}
        />
      </S.Root>
    </>
  )
}
