import React from 'react'
import { useTranslation } from 'react-i18next'

import { Autoplay, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { StyledBeforeAfterUsersGallery as S } from './BeforeAfterUsersGallery.styles'

type TUserGallerySlide = {
  name: string
  img: string
}

type TBeforeAfterUsersGalleryProps = {
  gallery: TUserGallerySlide[]
  className?: string
}

export const BeforeAfterUsersGallery: React.FC<
  TBeforeAfterUsersGalleryProps
> = ({ gallery, className }: TBeforeAfterUsersGalleryProps) => {
  const { t } = useTranslation()

  return (
    <Carousel
      slidesPerView={1}
      loop
      pagination={{
        clickable: true,
      }}
      modules={[Pagination, Autoplay]}
      autoplay={{ delay: 3000, disableOnInteraction: false }}
    >
      {gallery.map(({ name, img }) => (
        <SwiperSlide key={name}>
          <S.ImageWrapper className={className}>
            <S.SlideImage src={img} alt="user-image-before-after" />
            <S.User>{t(name)}</S.User>
          </S.ImageWrapper>
        </SwiperSlide>
      ))}
    </Carousel>
  )
}
