import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledInfluencersDiscount = {
  Wrapper: styled.div`
    border-radius: 14px;
    border: 1px solid #f6ca66;
    background: #fef7e8;
    padding: 16px 42px;
    margin-bottom: 16px;
  `,
  Content: styled.p`
    text-align: center;
    font-family: Rubik;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.DARK};

    & strong {
      color: ${Color.RED};
    }
  `,
}
