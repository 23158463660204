import { DefaultTheme } from 'styled-components'

import { Color } from 'root-constants'

export const PURCHASE_VARIANT_7_THEME: DefaultTheme = {
  paywallContainer: {
    maxWidth: '360px',
  },
  userGoalsGallery: {
    bullets: Color.PRIMARY,
  },
  schedule: {
    subtitle: {
      color: Color.DARK,
      size: '28px',
    },
  },
  reviews: {
    subtitle: {
      color: Color.DARK,
      fontSize: '28px',
      lineHeight: '32px',
    },
    review: {
      color: '#000000',
    },
    author: {
      color: '#43598f',
    },
    viewMoreLink: {
      color: Color.PRIMARY,
      fontWeight: 500,
      lineHeight: '17px',
    },
  },
  gallery: {
    subtitle: {
      color: Color.DARK,
      size: '28px',
      lineHeight: '32px',
    },
    user: {
      fontSize: '20px',
      fontWeight: 500,
    },
    bullets: Color.PRIMARY,
  },
  moneyBack: {
    wrapper: {
      borderRadius: '14px',
      boxShadow: '0 8px 20px #dfe3f3',
      border: '1px solid #c4c4c4',
      padding: '14px',
    },
    content: {
      padding: '60px 5px 10px',
      border: '1px solid #c4c4c4',
      borderRadius: 'initial',
    },
    title: {
      fontSize: '24px',
      minWidth: '240px',
      lineHeight: '30px',
      fontWeight: 700,
      positionTop: '6px',
    },
    text: {
      color: Color.DARK,
      linkColor: Color.LIGHT,
    },
  },
  securityInfo: {
    wrapper: {
      borderRadius: '14px',
      boxShadow: '0 8px 20px #d1d6eb',
    },
    title: {
      color: Color.DARK,
      lineHeight: '26px',
      fontWeight: 500,
    },
    text: {
      color: Color.LIGHT,
      lineHeight: '20px',
    },
    link: {
      color: Color.PRIMARY,
      fontWeight: 500,
    },
  },
  userProfile: {
    subtitle: {
      fontWeight: 500,
      color: Color.DARK,
    },
  },
  userTarget: {
    background: '#e1dcfe',
    maxWidth: '360px',
    bottom: '0',
    subtitle: {
      color: Color.DARK,
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '28px',
      marginBottom: '4px',
    },
    goal: {
      color: Color.DARK,
      fontSize: '16px',
      fontWeight: '500',
      lineHeight: '18px',
    },
  },
  planPeriod: {
    fontWeight: '600',
    color: Color.LIGHT,
    activeColor: Color.DARK,
    fontSize: '16px',
    lineHeight: '16px',
    letterSpacing: 'initial',
  },
  previousPrice: {
    fontWeight: '500',
    color: Color.LIGHT,
  },
  currentPrice: {
    fontWeight: '500',
    color: Color.LIGHT,
  },
  priceValue: {
    fontWeight: '600',
  },
  oldestPrice: {
    fontWeight: '500',
    color: Color.LIGHT,
  },
  customPrice: {
    fontWeight: '600',
    color: Color.LIGHT,
  },
  disclaimer: {
    color: Color.LIGHT,
    fontSize: '9px',
    lineHeight: '12px',
  },
  appGalleryTitle: {
    color: Color.DARK,
    fontSize: '28px',
    lineHeight: '32px',
  },
  userBenefits: {
    title: {
      color: Color.DARK,
      fontSize: '28px',
      lineHeight: '32px',
    },
    benefit: {
      fontWeight: 500,
    },
  },
  timerText: {
    fontWeight: 700,
  },
  ticker: {
    fontWeight: 500,
  },
  staticBannerDiscountLabel: {
    fontWeight: 600,
  },
}

export const PURCHASE_VARIANT_8_THEME: DefaultTheme = {
  paywallContainer: {
    maxWidth: '375px',
  },
  schedule: {
    subtitle: {
      color: Color.BLUE_DARK,
      size: '24px',
    },
  },
  reviews: {
    subtitle: {
      color: Color.BLUE_DARK,
      fontSize: '32px',
      lineHeight: '40px',
    },
    review: {
      color: Color.BLUE_DARK,
    },
    author: {
      color: '#a569bd',
    },
    viewMoreLink: {
      color: Color.CORAL,
      fontWeight: 600,
      lineHeight: '18px',
    },
  },
  gallery: {
    subtitle: {
      color: Color.BLUE_DARK,
      size: '32px',
      lineHeight: '40px',
    },
    user: {
      fontSize: '18px',
      fontWeight: 700,
    },
    bullets: Color.CORAL,
  },
  moneyBack: {
    wrapper: {
      borderRadius: '24px',
      boxShadow: '0px 8px 20px 0px #e8e8e8',
      border: 'none',
      padding: '8px',
    },
    content: {
      padding: '48px 16px 20px',
      border: '1px solid #ff7262',
      borderRadius: '16px',
    },
    title: {
      fontSize: '17px',
      minWidth: '290px',
      lineHeight: '24px',
      fontWeight: 600,
      positionTop: '20px',
    },
    text: {
      color: Color.GRAY,
      linkColor: Color.GRAY,
    },
  },
  securityInfo: {
    wrapper: {
      borderRadius: '16px',
      boxShadow: '0px 8px 20px 0px #e8e8e8',
    },
    title: {
      color: Color.BLUE_DARK,
      lineHeight: '24px',
      fontWeight: 600,
    },
    text: {
      color: Color.GRAY,
      lineHeight: '18px',
    },
    link: {
      color: Color.CORAL,
      fontWeight: 600,
    },
  },
  userProfile: {
    subtitle: {
      fontWeight: 700,
      color: Color.BLUE_DARK,
    },
  },
  userTarget: {
    background: '#F0EEFD',
    maxWidth: '375px',
    bottom: '-18px',
    subtitle: {
      color: Color.BLUE_DARK,
      fontSize: '18px',
      fontWeight: '700',
      lineHeight: '24px',
      marginBottom: '8px',
    },
    goal: {
      color: Color.BLUE_DARK,
      fontSize: '17px',
      fontWeight: '700',
      lineHeight: '24px',
    },
  },
  planPeriod: {
    fontWeight: '800',
    color: Color.GRAY,
    activeColor: Color.BLUE_DARK,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
  previousPrice: {
    fontWeight: '600',
    color: Color.GRAY,
  },
  currentPrice: {
    fontWeight: '600',
    color: Color.GRAY,
  },
  oldestPrice: {
    fontWeight: '600',
    color: Color.GRAY,
  },
  priceValue: {
    fontWeight: '700',
  },
  customPrice: {
    fontWeight: '700',
    color: Color.GRAY,
  },
  disclaimer: {
    color: Color.GRAY,
    fontSize: '12px',
    lineHeight: '16px',
  },
  appGalleryTitle: {
    color: Color.BLUE_DARK,
    fontSize: '32px',
    lineHeight: '40px',
  },
  userBenefits: {
    title: {
      color: Color.BLUE_DARK,
      fontSize: '32px',
      lineHeight: '40px',
    },
    benefit: {
      fontWeight: 700,
    },
  },
  timerText: {
    fontWeight: 700,
  },
  ticker: {
    fontWeight: 600,
  },
  staticBannerDiscountLabel: {
    fontWeight: 700,
  },
}
