import React from 'react'

import { StyledSwitch as S } from './Switch.styles'

type TSwitchTypes = {
  isChecked: boolean
  onChange: (event) => void
  value: string
}

export const enum SwitchSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export type TInputTheme = {
  themeColor?: string
  size?: SwitchSize
  className?: string
}

export const Switch: React.FC<TSwitchTypes & TInputTheme> = ({
  isChecked = false,
  onChange,
  value,
  themeColor,
  size = SwitchSize.LARGE,
  className,
}) => (
  <S.Root size={size} className={className}>
    <S.Input
      themeColor={themeColor}
      value={value}
      checked={isChecked}
      onChange={onChange}
      type="checkbox"
    />
    <S.Button size={size} />
    <S.Track />
  </S.Root>
)
