import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import faceMassages from 'assets/images/face-massages.jpg'
import faceYogaExercises from 'assets/images/face-yoga-exercises.jpg'
import personalizedSkinCare from 'assets/images/personalized-skin-care.jpg'

import { StyledAllRoundApproach as S } from './AllRoundApproach.styles'
import { QUESTION } from './constants'

export const AllRoundApproach: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue('')

  return (
    <S.Root>
      <Container>
        <S.Title>{t('onboarding.allRoundApproach.question')}</S.Title>
        <S.List>
          <S.ListItem>
            <S.Image src={personalizedSkinCare} />
            <span>
              <Trans i18nKey="onboarding.allRoundApproach.approach1" />
            </span>
          </S.ListItem>
          <S.ListItem>
            <S.Image src={faceMassages} />
            <span>
              <Trans i18nKey="onboarding.allRoundApproach.approach2" />
            </span>
          </S.ListItem>
          <S.ListItem>
            <S.Image src={faceYogaExercises} />
            <span>
              <Trans i18nKey="onboarding.allRoundApproach.approach3" />
            </span>
          </S.ListItem>
        </S.List>
        <StickyButtonContainer>
          <Button onClick={handleNextClick}>{t('actions.next')}</Button>
        </StickyButtonContainer>
      </Container>
    </S.Root>
  )
}
