import React from 'react'

import { StyledAnswerButtonWithImage as S } from './AnswerButtonWithImage.styles'

type TProps = {
  imageSrc: string
  withCheckbox?: boolean
  className?: string
}

export const AnswerButtonWithImage: React.FC<TProps> = ({
  imageSrc,
  withCheckbox = true,
  className,
}) => (
  <S.Root withCheckbox={withCheckbox} className={className}>
    <S.ImageContainer>
      <S.Image src={imageSrc} alt="" />
    </S.ImageContainer>
  </S.Root>
)
