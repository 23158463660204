import { PageId } from 'page-constants'

import { useActiveCohortIdentifier } from './useActiveCohortIdentifier'

export const usePurchaseCancelOfferRoute = (): PageId => {
  const { isRefundCohort, isLuvlyChinCohort, isLuvlyGeneralCohort } =
    useActiveCohortIdentifier()

  if (isRefundCohort) return PageId.PURCHASE_CANCEL_REFUND
  if (isLuvlyChinCohort) return PageId.PURCHASE_CANCEL_CHIN
  if (isLuvlyGeneralCohort) return PageId.PURCHASE_CANCEL_GENERAL

  return PageId.PURCHASE_INTRO_CANCEL_OFFER
}
