import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Answer, answerTheme } from 'storybook-ui'

import { AdaptiveContainer } from 'components/AdaptiveContainer'

import { selectAnswers } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { PageId } from 'page-constants'
import { Gender, OptionType } from 'root-constants'

import { StyledAffectSkinCondition as S } from './AffectSkinCondition.styles'
import {
  ANSWERS_FEMALE,
  ANSWERS_MALE,
  QUESTION_FEMALE,
  QUESTION_MALE,
} from './constants'

export const AffectSkinCondition: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const userGender = userAnswers?.[PageId.GENDER]

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: userGender === Gender.MALE ? QUESTION_MALE : QUESTION_FEMALE,
    nextPagePath,
  })

  const handleAnswerChange = (value: string) => {
    handleChange(value, handleContinue)
    setIsAnswersDisabled(true)
  }

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    disabled: isAnswersDisabled,
    fontWeight: '600',
    maxWidth: '488px',
    onChange: handleAnswerChange,
  }

  const answers = userGender === Gender.MALE ? ANSWERS_MALE : ANSWERS_FEMALE

  return (
    <AdaptiveContainer>
      <S.Title>
        <Trans
          i18nKey="onboarding.affectSkinCondition.question"
          context={userGender}
        />
      </S.Title>
      {answers.map(({ value, label }) => (
        <Answer
          {...optionProps}
          key={value}
          value={value}
          theme={answerTheme.LUVLY_SOLID}
        >
          {t(label)}
        </Answer>
      ))}
    </AdaptiveContainer>
  )
}
