import { SubscriptionProduct, TimeInterval } from 'root-constants'

export const PERIOD_NAME_TRANSLATIONS_MAP: Record<TimeInterval, string> = {
  [TimeInterval.DAY]: 'commonComponents.timeInterval.day',
  [TimeInterval.WEEK]: 'commonComponents.timeInterval.week',
  [TimeInterval.MONTH]: 'commonComponents.timeInterval.month',
  [TimeInterval.YEAR]: 'commonComponents.timeInterval.year',
}

export const PURCHASE_INTRO_OFFER_DISCLAIMER = {
  [SubscriptionProduct.SEVEN_DAY]: 'purchaseIntroOffer.weeklySubscription',
  [SubscriptionProduct.ONE_MONTH]: 'purchaseIntroOffer.oneMonthSubscription',
  [SubscriptionProduct.TWO_MONTHS]:
    'purchaseIntroOffer.flexibleMonthsSubscription',
  [SubscriptionProduct.THREE_MONTHS]:
    'purchaseIntroOffer.threeMonthsSubscription',
  [SubscriptionProduct.FOUR_MONTHS]:
    'purchaseIntroOffer.flexibleMonthsSubscription',
  [SubscriptionProduct.SIX_MONTHS]: 'purchaseIntroOffer.sixMonthsSubscription',
}

export const PURCHASE_INTRO_OFFER_DISCLAIMER_WITHOUT_TAXES = {
  [SubscriptionProduct.SEVEN_DAY]:
    'purchaseIntroOffer.weeklySubscriptionWithoutTaxes',
  [SubscriptionProduct.ONE_MONTH]:
    'purchaseIntroOffer.oneMonthSubscriptionWithoutTaxes',
  [SubscriptionProduct.TWO_MONTHS]:
    'purchaseIntroOffer.flexibleMonthsSubscriptionWithoutTaxes',
  [SubscriptionProduct.THREE_MONTHS]:
    'purchaseIntroOffer.threeMonthsSubscriptionWithoutTaxes',
  [SubscriptionProduct.FOUR_MONTHS]:
    'purchaseIntroOffer.flexibleMonthsSubscriptionWithoutTaxes',
  [SubscriptionProduct.SIX_MONTHS]:
    'purchaseIntroOffer.sixMonthsSubscriptionWithoutTaxes',
}

export const PURCHASE_INTRO_SALE_DISCLAIMER = {
  [SubscriptionProduct.SEVEN_DAY]: 'purchaseIntroOffer.weeklySubscriptionSale',
  [SubscriptionProduct.ONE_MONTH]:
    'purchaseIntroOffer.oneMonthSubscriptionSale',
  [SubscriptionProduct.THREE_MONTHS]:
    'purchaseIntroOffer.threeMonthsSubscriptionSale',
  [SubscriptionProduct.SIX_MONTHS]:
    'purchaseIntroOffer.sixMonthsSubscriptionSale',
  [SubscriptionProduct.TWELVE_MONTHS]:
    'purchaseIntroOffer.threeMonthsSubscriptionSale',
  [SubscriptionProduct.THREE_YEARS]:
    'purchaseIntroOffer.lifetimeSubscriptionSale',
}

export const PURCHASE_INTRO_SALE_DISCLAIMER_WITHOUT_TAXES = {
  [SubscriptionProduct.SEVEN_DAY]:
    'purchaseIntroOffer.weeklySubscriptionWithoutTaxesSale',
  [SubscriptionProduct.ONE_MONTH]:
    'purchaseIntroOffer.oneMonthSubscriptionWithoutTaxesSale',
  [SubscriptionProduct.THREE_MONTHS]:
    'purchaseIntroOffer.threeMonthsSubscriptionWithoutTaxesSale',
  [SubscriptionProduct.SIX_MONTHS]:
    'purchaseIntroOffer.sixMonthsSubscriptionWithoutTaxesSale',
  [SubscriptionProduct.TWELVE_MONTHS]:
    'purchaseIntroOffer.threeMonthsSubscriptionWithoutTaxesSale',
  [SubscriptionProduct.THREE_YEARS]:
    'purchaseIntroOffer.lifetimeSubscriptionWithoutTaxesSale',
}

export const PURCHASE_INTRO_OFFER_DISCLAIMER_INCLUDING_TAXES = {
  [SubscriptionProduct.SEVEN_DAY]:
    'purchaseIntroOffer.weeklySubscriptionWithTaxes',
  [SubscriptionProduct.ONE_MONTH]:
    'purchaseIntroOffer.oneMonthSubscriptionWithTaxes',
  [SubscriptionProduct.THREE_MONTHS]:
    'purchaseIntroOffer.threeMonthsSubscriptionWithTaxes',
}

export const PURCHASE_INTRO_OFFER_DISCLAIMER_INCLUDING_TAXES_2 = {
  [SubscriptionProduct.SEVEN_DAY]:
    'purchaseIntroOffer.weeklySubscriptionWithTaxes2',
  [SubscriptionProduct.ONE_MONTH]:
    'purchaseIntroOffer.oneMonthSubscriptionWithTaxes2',
  [SubscriptionProduct.THREE_MONTHS]:
    'purchaseIntroOffer.threeMonthsSubscriptionWithTaxes2',
}
