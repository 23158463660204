import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ChatAnswerButtonMini } from 'components/ChatAnswerButtonMini'
import { ChatMessage } from 'components/ChatMessage'
import {
  ChatAuthor,
  ChatStep,
  DELAY_BEFORE_SHOW,
} from 'components/ChatMessage/ChatMessage'
import { Container } from 'components/Container'
import { Option } from 'components/Option'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Chat } from 'common-styles'
import { OptionType, SCALE_OPTION_VALUES } from 'root-constants'

import { StyledChatStatementQuestion as S } from './ChatStatementQuestion.styles'
import { CHAT_STATEMENT_QUESTIONS_MAP } from './constants'

export const ChatStatementQuestion: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const [chatStep, setChatStep] = useState<ChatStep>(ChatStep.EXPERT_QUESTION)

  const handleContinue = useNextStep({
    pageId,
    question: CHAT_STATEMENT_QUESTIONS_MAP[pageId],
    nextPagePath,
  })

  const handleAnswerChange = (value: string) => {
    handleChange(value, handleContinue)
    setIsAnswersDisabled(true)
  }

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: handleAnswerChange,
  }

  return (
    <Container fullHeight justifyContent="flex-end">
      <Chat>
        <ChatMessage
          author={ChatAuthor.EXPERT}
          delayBeforeShow={DELAY_BEFORE_SHOW}
          onTransitionEnd={() => setChatStep(ChatStep.SHOW_OPTIONS)}
          hasJustNowLabel
        >
          <S.Statement>{t('commonComponents.statementTitle')}</S.Statement>
          <p>{t(`onboarding.${pageId}.statement`)}</p>
        </ChatMessage>
      </Chat>
      {chatStep === ChatStep.SHOW_OPTIONS && (
        <div>
          <S.OptionsContainer>
            <Option
              {...optionProps}
              disabled={isAnswersDisabled}
              value={SCALE_OPTION_VALUES.ONE}
            >
              <ChatAnswerButtonMini>1</ChatAnswerButtonMini>
            </Option>
            <Option
              {...optionProps}
              disabled={isAnswersDisabled}
              value={SCALE_OPTION_VALUES.TWO}
            >
              <ChatAnswerButtonMini>2</ChatAnswerButtonMini>
            </Option>
            <Option
              {...optionProps}
              disabled={isAnswersDisabled}
              value={SCALE_OPTION_VALUES.THREE}
            >
              <ChatAnswerButtonMini>3</ChatAnswerButtonMini>
            </Option>
            <Option
              {...optionProps}
              disabled={isAnswersDisabled}
              value={SCALE_OPTION_VALUES.FOUR}
            >
              <ChatAnswerButtonMini>4</ChatAnswerButtonMini>
            </Option>
            <Option
              {...optionProps}
              disabled={isAnswersDisabled}
              value={SCALE_OPTION_VALUES.FIVE}
            >
              <ChatAnswerButtonMini>5</ChatAnswerButtonMini>
            </Option>
          </S.OptionsContainer>
          <S.Scale>
            <span>{t`commonComponents.lowestScaleValue`}</span>
            <span>{t`commonComponents.highestScaleValue`}</span>
          </S.Scale>
        </div>
      )}
    </Container>
  )
}
