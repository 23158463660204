import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button, buttonTheme } from 'storybook-ui'

import { AdaptiveContainer } from 'components/AdaptiveContainer'
import {
  DEFAULT_CUSTOMER_REVIEWS,
  DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS,
} from 'components/CustomerReviews/constants'
import { CustomerReviewsCoral } from 'components/CustomerReviewsCoral'
import { StickyButtonContainer } from 'components/StickyButtonContainer'
import { UserGoalAnimatedGraph } from 'components/UserGoalAnimatedGraph'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledShortProgramDescription2 as S } from './ShortProgramDescription2.styles'
import { QUESTIONS } from './constants'

export const ShortProgramDescription2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTIONS[pageId],
    nextPagePath,
  })

  const handleNextClick = () => handleContinue('')

  return (
    <AdaptiveContainer>
      <S.Title>
        <Trans i18nKey="onboarding.shortProgramDescription.question.default2" />
      </S.Title>
      <S.Subtitle>
        <Trans i18nKey="onboarding.shortProgramDescription.question.description" />
      </S.Subtitle>
      <UserGoalAnimatedGraph pageId={pageId} />
      <CustomerReviewsCoral
        customerReviews={DEFAULT_CUSTOMER_REVIEWS}
        viewMoreCustomerReviews={DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS}
      />
      <StickyButtonContainer centerMode>
        <Button
          theme={buttonTheme.LUVLY_SECONDARY}
          onClick={handleNextClick}
          maxWidth="488px"
        >
          {t('onboarding.shortProgramDescription.goToNextButtonText')}
        </Button>
      </StickyButtonContainer>
    </AdaptiveContainer>
  )
}
