import styled, { css } from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

import { TProps } from './AdaptiveContainer'

export const StyledAdaptiveContainer = {
  Root: styled.div<TProps>`
    min-height: calc(var(--full-height) - 56px);
    display: flex;
    flex-direction: column;

    ${({ mobileBackground }) =>
      mobileBackground &&
      css`
        background-image: url(${mobileBackground});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: bottom right;
      `}

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      ${({ desktopBackground }) =>
        desktopBackground &&
        css`
          background-image: url(${desktopBackground});
          background-size: contain;
          background-repeat: no-repeat;
          background-position: bottom right;
        `}
    }
  `,
  Content: styled.div`
    max-width: 375px;
    width: 100%;
    margin: 0 auto;
    padding: 0 16px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 520px;
    }
  `,
  Title: styled.h1`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;
    text-align: left;
    color: ${Color.BLUE_DARK};
    margin-top: 24px;

    & strong {
      color: ${Color.CORAL};
    }

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      margin-top: 48px;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
    }
  `,
  Subtitle: styled.h2`
    color: ${Color.GRAY};
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 8px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      margin-top: 16px;
      text-align: center;
    }
  `,
  OptionsContainer: styled.div<TProps>`
    max-width: ${({ fullWidth }) => (fullWidth ? '100%' : '256px')};
    margin-top: 24px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: unset;
      margin-top: 32px;
    }
  `,
}
