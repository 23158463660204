import styled from 'styled-components'

import chainLine from 'assets/images/chain-line.svg'

import { Color } from 'root-constants'

export const StyledFaceYogaCourseSteps = {
  StepsContainer: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 40px;
    padding: 0 16px;
  `,
  StepsTitle: styled.h2`
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: ${Color.DARK};
    text-align: center;
    margin-bottom: 24px;
  `,
  StepsList: styled.ul`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,
  ListItem: styled.li`
    display: flex;
    align-items: flex-start;

    :not(:last-of-type) {
      margin-bottom: 25px;

      div:first-child:after {
        content: url(${chainLine});
        position: absolute;
        bottom: 0;
        transform: translateY(100%);
      }
    }
  `,
  Marker: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${Color.PRIMARY};
    color: ${Color.WHITE};
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    position: relative;
    margin-right: 12px;
    padding: 12px;
  `,
  Content: styled.div`
    display: flex;
    flex-direction: column;
  `,
  ContentTitle: styled.h4`
    font-weight: 500;
    font-size: 17px;
    line-height: 26px;
    color: ${Color.DARK};
    margin-bottom: 12px;
  `,
  ContentDescription: styled.p`
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: ${Color.LIGHT};
  `,
}
