import styled from 'styled-components'
import { SwiperSlide } from 'swiper/react'

import { Color } from 'root-constants'

export const StyledUpsellCoaching = {
  Root: styled.div`
    padding-bottom: 24px;

    & .swiper {
      margin-bottom: 40px;
    }

    & .swiper-wrapper {
      margin-bottom: 24px;
    }

    & .swiper-pagination {
      bottom: 0;
    }

    & span.swiper-pagination-bullet {
      background-color: #cacaca;
      opacity: 1;
      margin: 0 8px !important;
    }

    & span.swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: ${Color.PRIMARY};
    }
  `,
  Review: styled.div`
    position: absolute;
    bottom: 12px;
    left: 8px;
    width: 304px;
    padding: 8px;
    border-radius: 8px;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    background: ${Color.WHITE};
    box-shadow: 0 4px 14px 0 rgba(8, 4, 23, 0.03);

    strong {
      color: ${Color.PRIMARY};
      font-weight: 500;
    }
  `,
  Hero: styled.div`
    margin-bottom: 24px;
    position: relative;
    border-radius: 24px;

    img {
      width: 320px;
      height: 300px;
    }
  `,
  Title: styled.h1<{ $marginBottom?: number }>`
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: ${({ $marginBottom }) =>
      $marginBottom ? `${$marginBottom}px` : '24px'};
    text-align: center;
  `,
  Subtitle: styled.p`
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    color: ${Color.LIGHT};
    text-align: center;
    margin-bottom: 24px;

    strong {
      font-weight: 600;
      color: ${Color.PRIMARY};
    }
  `,
  ReviewDisclaimer: styled.div`
    color: ${Color.LIGHT};
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-bottom: 32px;
  `,
  BenefitsContainer: styled.div`
    margin-bottom: 40px;
  `,
  BenefitRow: styled.div`
    display: flex;
    flex-direction: row;
    align-items: end;

    svg {
      margin-right: 12px;
      min-width: 40px;
    }

    &:not(:first-child) {
      p {
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid ${Color.CORAL_GRAY};
      }
    }
  `,
  Benefit: styled.p`
    color: ${Color.DARK};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  `,
  UpsellFeature: styled.div`
    border-radius: 24px;
    border: 1px solid ${Color.WHITE};
    margin-bottom: 24px;
    background: radial-gradient(
        153.63% 158.28% at 67.35% 70.09%,
        rgba(215, 1, 5, 0.12) 0%,
        rgba(255, 255, 255, 0) 48.5%
      ),
      radial-gradient(
        218.16% 126.86% at 26.09% 72.05%,
        rgba(161, 86, 0, 0.12) 0%,
        rgba(255, 255, 255, 0) 48.5%
      ),
      radial-gradient(
        135.34% 123.53% at 78.13% 33.95%,
        rgba(216, 119, 150, 0.12) 0%,
        rgba(255, 255, 255, 0) 48.5%
      ),
      radial-gradient(
        139.36% 103.44% at 29.3% 18.12%,
        rgba(197, 119, 216, 0.12) 0%,
        rgba(255, 255, 255, 0) 48.5%
      ),
      linear-gradient(0deg, #fcfcfc 0%, #fcfcfc 100%), rgba(255, 114, 98, 0.16);

    &:last-child {
      margin-bottom: 0;
    }
  `,
  FeatureTitle: styled.div`
    display: flex;
    padding: 24px 24px 8px;
    color: ${Color.DARK};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;

    svg {
      margin-right: 6px;
      min-width: 24px;
    }
  `,
  Description: styled.p`
    padding: 0 24px 16px;
    color: ${Color.GRAY};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  `,
  UpsellFeatureContainer: styled.div`
    margin-bottom: 40px;
  `,
  Step: styled.div`
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid ${Color.GRAY_LIGHT};

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 40px;
    }
  `,
  StepNumber: styled.h3`
    color: ${Color.PRIMARY};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-bottom: 8px;
  `,
  StepTitle: styled.div`
    color: ${Color.DARK};
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 8px;
  `,
  StepDescription: styled.p`
    color: ${Color.GRAY};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  `,
  UpsellCoachingSubscriptionBlock: styled.div`
    margin-bottom: 40px;
  `,
  BeforeAfterSubtitle: styled.p`
    color: ${Color.LIGHT};
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;
  `,
  CustomerReview: styled.div`
    margin-bottom: 12px;
    border-radius: 16px;
    background: ${Color.WHITE};
    box-shadow: 0 8px 20px 0 rgba(209, 214, 235, 0.5);
    padding: 16px;
  `,
  CustomerReviewTitle: styled.div`
    color: ${Color.DARK};
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    svg {
      margin-left: 6px;
    }
  `,
  CustomerReviewText: styled.p`
    color: ${Color.GRAY};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 8px;
  `,
  CustomerName: styled.p`
    color: ${Color.GRAY_SECONDARY};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  `,
  SecondaryTitle: styled.h2`
    color: ${Color.DARK};
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: 24px;
  `,
  SwiperSlide: styled(SwiperSlide)`
    max-height: 300px;

    & > div {
      margin-bottom: 0;
    }
  `,
  SkipBtn: styled.button`
    position: absolute;
    z-index: 3;
    top: 15px;
    right: 0;
    color: ${Color.PRIMARY};
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-transform: uppercase;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
  `,
}
