import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useVatInfo } from 'hooks/useHasVatInfo'
import { useIsCurrentRoute } from 'hooks/useIsCurrentRoute'

import { getCalculatedPriceWithoutVAT } from 'helpers/getCalculatedPriceWithoutVAT'

import {
  CANCEL_OFFER_ADDITIONAL_DISCOUNT,
  DECORATING_DISCOUNT,
  DEFAULT_DYNAMIC_DISCOUNT,
  INTRO_SALES_COHORTS_DISCOUNT_MAP,
  ONE_HUNDRED_PERCENTAGE,
} from 'modules/purchase/constants'
import { getDynamicDiscountText } from 'modules/purchase/helpers/rootHelpers'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import {
  selectDynamicDiscount,
  selectSubscription,
} from 'modules/purchase/redux/selects/common'

import { PageId } from 'page-constants'
import { SubscriptionProduct } from 'root-constants'

import { StyledCheckoutPurchaseDetails as S } from './CheckoutPurchaseDetails.styles'

type TCheckoutPurchaseDetails = {
  errorTheme?: boolean
}

export const CheckoutPurchaseDetails: React.FC<TCheckoutPurchaseDetails> = ({
  errorTheme = false,
}) => {
  const { t } = useTranslation()
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const selectedSubscription = useSelector(selectSubscription)
  const { isDynamicDiscountCohort, isRefundCohort } =
    useActiveCohortIdentifier()
  const cohortToUse = useCohortToUse()
  const hasVatInfo = useVatInfo()
  const { currency, taxAmount, discountAmount } = usePurchaseStore()
  const isCancelOfferRoute = useIsCurrentRoute(
    isRefundCohort
      ? PageId.PURCHASE_CANCEL_REFUND
      : PageId.PURCHASE_INTRO_CANCEL_OFFER,
  )

  if (!selectedSubscription) {
    return null
  }

  const getCalculatedPrice = (fullPrice: number) => {
    if (!hasVatInfo) return fullPrice

    return Number(getCalculatedPriceWithoutVAT(fullPrice, taxAmount))
  }

  const getPriceWithFormattedDiscount = (
    subscriptionPrice: number,
    discount: number,
  ) => {
    const calculatedOldPrice =
      subscriptionPrice /
      ((ONE_HUNDRED_PERCENTAGE - discount) / ONE_HUNDRED_PERCENTAGE)

    return (
      Math.ceil(getCalculatedPrice(calculatedOldPrice)) - DECORATING_DISCOUNT
    )
  }

  const currencyLabel = currency.toUpperCase()

  const getSummarizedSubscription = () => {
    if (isDynamicDiscountCohort) {
      const percentage =
        dynamicDiscount?.amount ||
        INTRO_SALES_COHORTS_DISCOUNT_MAP[cohortToUse] ||
        DEFAULT_DYNAMIC_DISCOUNT
      const totalAmount = getPriceWithFormattedDiscount(
        selectedSubscription.trialPrices.fullPrice,
        percentage,
      )

      return {
        percentage: isCancelOfferRoute
          ? percentage + CANCEL_OFFER_ADDITIONAL_DISCOUNT
          : percentage,
        totalAmount: totalAmount.toFixed(2),
        discountAmount: (
          totalAmount - selectedSubscription.trialPrices.fullPrice
        ).toFixed(2),
      }
    }

    const totalAmount =
      selectedSubscription.trialPrices.durationDays ===
      SubscriptionProduct.SEVEN_DAY
        ? getCalculatedPrice(
            selectedSubscription.trialPrices.oldPrices.fullPrice,
          )
        : getCalculatedPrice(selectedSubscription.mainPrices.fullPrice)

    return {
      percentage:
        selectedSubscription.mainPrices.oldPrices.percentOfDiscount ||
        DEFAULT_DYNAMIC_DISCOUNT,
      totalAmount,
      discountAmount,
    }
  }

  const summarizedSubscription = getSummarizedSubscription()

  const getDiscountLabel = () => {
    const discountValue = !isDynamicDiscountCohort
      ? getCalculatedPrice(discountAmount)
      : summarizedSubscription.discountAmount

    return `-${discountValue} ${currencyLabel}`
  }

  const totalAmountLabel = `${summarizedSubscription.totalAmount} ${currencyLabel}`

  const renderDiscountText = () => {
    if (isDynamicDiscountCohort) {
      return getDynamicDiscountText({
        discountAmount: summarizedSubscription.percentage,
        isCancelOffer: isCancelOfferRoute,
        cohort: cohortToUse,
        discountText: dynamicDiscount?.checkout,
      })
    }

    return (
      <Trans
        i18nKey="checkoutExtended.introductoryDiscount"
        values={{
          discountPercentage: summarizedSubscription.percentage,
        }}
      />
    )
  }

  const renderDailyText = () => {
    if (!errorTheme) {
      return (
        <S.DailyPayment>
          <S.DailyText>{t('checkoutExtended.totalPerDay')}</S.DailyText>
          <S.DailyAmount>
            {getCalculatedPrice(selectedSubscription.trialPrices.daily)}{' '}
            {currencyLabel}
          </S.DailyAmount>
        </S.DailyPayment>
      )
    }

    return null
  }

  const renderSavedText = () => {
    if (!errorTheme) {
      return (
        <S.SaveText>
          <Trans
            i18nKey="checkoutExtended.savedAmount"
            values={{
              savedAmount: summarizedSubscription.discountAmount,
              currency: currencyLabel,
              discountPercentage: summarizedSubscription.percentage,
            }}
          />
        </S.SaveText>
      )
    }
    return null
  }

  return (
    <>
      <S.PriceDescription>
        <S.PriceInfo>
          <S.PriceBlockText>
            {t('checkoutExtended.personalizedPlan')}
          </S.PriceBlockText>
          <S.PriceBlockText>{totalAmountLabel}</S.PriceBlockText>
        </S.PriceInfo>
        <S.PriceInfo>
          <S.PriceBlockText>{renderDiscountText()}</S.PriceBlockText>
          <S.TotalDiscount errorTheme={errorTheme}>
            {getDiscountLabel()}
          </S.TotalDiscount>
        </S.PriceInfo>
      </S.PriceDescription>

      {renderDailyText()}

      <S.TotalPayment>
        <S.TotalDescription>
          <S.TotalText>
            <Trans
              i18nKey={
                hasVatInfo
                  ? t('checkoutExtended.totalWithVat')
                  : t('checkoutExtended.total')
              }
              components={[<br />]}
            />
          </S.TotalText>
          <S.TotalAmount errorTheme={errorTheme}>
            <Trans
              i18nKey="checkoutExtended.totalAmount"
              values={{
                totalAmount: selectedSubscription.trialPrices.fullPrice,
                currency: currencyLabel,
                context: selectedSubscription.trialPrices.durationDays,
              }}
            />
          </S.TotalAmount>
        </S.TotalDescription>
        {renderSavedText()}
      </S.TotalPayment>
    </>
  )
}
