import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, buttonTheme } from 'storybook-ui'

import { Container } from 'components/Container'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { CustomBackgroundStyles, StickyButtonContainer } from 'common-styles'
import { PageId } from 'page-constants'
import { CDN_FOLDER_LINK, Gender } from 'root-constants'

import { StyledDoubleChinChanges as S } from './DoubleChinChanges.styles'
import { BEFORE_AFTER_IMAGE_PATH, QUESTION } from './constants'

export const DoubleChinChanges: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}: TPageProps) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)

  const userGender = userAnswers?.[PageId.GENDER]

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue('')

  const imagePath =
    userGender === Gender.MALE
      ? `${CDN_FOLDER_LINK}${BEFORE_AFTER_IMAGE_PATH}_male.png`
      : `${CDN_FOLDER_LINK}${BEFORE_AFTER_IMAGE_PATH}_female.png`

  return (
    <S.Root background={CustomBackgroundStyles.TRANSPARENT_PINK_TO_YELLOW}>
      <Container>
        <S.Title>{t('onboarding.doubleChinChanges.title')}</S.Title>
        <S.Description>
          {t('onboarding.doubleChinChanges.description')}
        </S.Description>
        <S.ImageContainer>
          <S.Image src={imagePath} alt="double-chin-before-after" />
        </S.ImageContainer>
        <StickyButtonContainer customBackground="transparent" centerMode>
          <Button onClick={handleNextClick} theme={buttonTheme.LUVLY_SECONDARY}>
            {t('onboarding.doubleChinChanges.soundsGood')}
          </Button>
        </StickyButtonContainer>
      </Container>
    </S.Root>
  )
}
