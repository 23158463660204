import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { SvgImage } from 'components/SvgImage'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { getSupportFormLink } from 'helpers/getSupportFormLink'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import {
  selectCurrency,
  selectSubscription,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import giftIcon from 'assets/images/sprite/gift-icon.svg'

import { goTo } from 'browser-history'
import { PageId } from 'page-constants'
import { ScreenName } from 'root-constants'

import { StyledUpsellCoachingSubscriptionBlock as S } from './UpsellCoachingSubscriptionBlock.styles'

const BONUS_OLD_PRICE = 19.99
const BONUS_CURRENT_PRICE = 0

export const UpsellCoachingSubscriptionBlock: React.FC<{
  className?: string
  onButtonClick: (event) => void
  nextPagePath?: string
  buttonOrder?: number
}> = ({ onButtonClick, className = '', nextPagePath, buttonOrder }) => {
  const { t } = useTranslation()
  const { search } = useLocation()

  const currency = useSelector(selectCurrency)
  const selectedSubscription = useSelector(selectSubscription)
  const contactFormLink = getSupportFormLink()

  const handleSkip = useCallback(() => {
    eventLogger.logUpsellPurchaseClose(ScreenName.UPSELL_COACHING)

    goTo({
      pathname: nextPagePath || PageId.FINISHING_TOUCHES,
      search,
    })
  }, [search, nextPagePath])

  return (
    <div className={className}>
      <S.Title>{t('upsellCoaching.refineRoutine')}</S.Title>
      <S.SubscriptionBlock>
        <S.SubscriptionItem>
          <S.PlanTitle>{t('upsellCoaching.planTitle')}</S.PlanTitle>
          <S.PlanPrice>
            <S.CurrentPrice>
              {CURRENCY_SYMBOLS[currency]}
              {selectedSubscription?.mainPrices.fullPrice}
            </S.CurrentPrice>
          </S.PlanPrice>
        </S.SubscriptionItem>
        <S.SubscriptionItem>
          <S.PlanTitle>
            <SvgImage svg={giftIcon} width={40} height={40} />
            {t('upsellCoaching.detoxGuide')}
          </S.PlanTitle>
          <S.PlanPrice>
            <S.OldPrice>
              {CURRENCY_SYMBOLS[currency]}
              {BONUS_OLD_PRICE}
            </S.OldPrice>
            <S.CurrentPrice>
              {CURRENCY_SYMBOLS[currency]}
              {BONUS_CURRENT_PRICE}
            </S.CurrentPrice>
          </S.PlanPrice>
        </S.SubscriptionItem>
      </S.SubscriptionBlock>
      <S.Button onClick={onButtonClick} data-order={buttonOrder}>
        {t('upsellCoaching.join', {
          currency: CURRENCY_SYMBOLS[currency],
          price: selectedSubscription?.mainPrices.fullPrice,
        })}
      </S.Button>
      <S.SkipButton onClick={handleSkip}>
        {t('upsellCoaching.continueWithoutPlan')}
      </S.SkipButton>
      <S.Disclaimer>
        {t('upsellCoaching.disclaimer', {
          currency: CURRENCY_SYMBOLS[currency],
          price: selectedSubscription?.mainPrices.fullPrice,
        })}{' '}
        <TermsOfUseLink /> {t`commonComponents.or`}{' '}
        <a target="_blank" rel="noopener noreferrer" href={contactFormLink}>
          {t`commonComponents.contactSupportTeam`}.
        </a>
      </S.Disclaimer>
    </div>
  )
}
