import React, {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import 'firebase/auth'
import { Button, buttonTheme } from 'storybook-ui'

import { DomainsContainer } from 'components/DomainsContainer'
import { Modal } from 'components/Modal'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { Spinner } from 'components/Spinner'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { resetErrorAction } from 'root-redux/actions/common'
import { selectError, selectIsFetching } from 'root-redux/selects/common'
import { selectUserOnboardingEmail } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useAuthObserver } from 'hooks/useAuthObserver'
import { useEmailInputField } from 'hooks/useEmailInputField'
import { useGetRedirectResult } from 'hooks/useGetRedirectResult'
import { useInitFirebase } from 'hooks/useInitFirebase'
import { usePasswordInputField } from 'hooks/usePasswordInputField'

import { IS_ACCOUNT_VISITED_SESSION_STORAGE_KEY } from 'modules/login/constants'
import {
  applyWellhubPromoCodeAction,
  registerWithEmailFirebaseAction,
  resetEmailErrorMessageAction,
  resetPasswordErrorMessageAction,
  selectEmailErrorMessage,
  selectPasswordErrorMessage,
} from 'modules/login/redux'

import { eventLogger } from 'services/eventLogger.service'

import { SandyBackgroundGlobalStyles } from 'common-styles'
import {
  Cohort,
  EMAIL_DOMAINS,
  EMAIL_DOMAIN_REGEXP,
  EMAIL_USERNAME_REGEXP,
  LoginMethod,
  ScreenName,
} from 'root-constants'

import { StyledWellhubLogin as S } from './WellhubLogin.styles'
import { WellhubEmailLogin } from './components/EmailLogin'

export const WellhubLogin: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()

  const error = useSelector(selectError)
  const isFetching = useSelector(selectIsFetching)
  const emailErrorMessage = useSelector(selectEmailErrorMessage)
  const passwordErrorMessage = useSelector(selectPasswordErrorMessage)
  const userOnboardingEmail = useSelector(selectUserOnboardingEmail)

  const [isModalShown, setIsModalShown] = useState(false)
  const [isFirebaseDataLoading, setIsFirebaseDataLoading] = useState(false)
  const [areEmailTipsVisible, setAreEmailTipsVisible] = useState(false)

  const [email, setEmail] = useEmailInputField(emailErrorMessage, () =>
    dispatch(resetEmailErrorMessageAction()),
  )
  const [password, setPassword] = usePasswordInputField(
    passwordErrorMessage,
    () => dispatch(resetPasswordErrorMessageAction()),
  )

  const deferredEmail = useDeferredValue(email.value)

  useAmplitudeInitialization(Cohort.LUVLY_WELLHUB, ScreenName.WELLHUB_LOGIN)

  const domainsList = useMemo(() => {
    const [, emailDomain] = EMAIL_DOMAIN_REGEXP.exec(deferredEmail) || []
    const [userName] = EMAIL_USERNAME_REGEXP.exec(deferredEmail) || []
    return EMAIL_DOMAINS.filter((domain) => domain.includes(emailDomain)).map(
      (filteredDomain) => `${userName}${filteredDomain}`,
    )
  }, [deferredEmail])

  const isComplete = useMemo(
    () =>
      email.isValid &&
      email.value !== '' &&
      password.isValid &&
      password.value !== '',
    [email.isValid, email.value, password.isValid, password.value],
  )

  useEffect(() => {
    window.axon && window.axon('track', 'page_view')

    const isAccountVisited = JSON.parse(
      sessionStorage.getItem(IS_ACCOUNT_VISITED_SESSION_STORAGE_KEY) || 'false',
    )

    if (isAccountVisited) return
    eventLogger.logCreateAccountShown()

    sessionStorage.setItem(IS_ACCOUNT_VISITED_SESSION_STORAGE_KEY, 'true')
  }, [])

  useEffect(() => {
    setEmail((prevState) => ({ ...prevState, value: userOnboardingEmail }))
  }, [setEmail, userOnboardingEmail])

  useEffect(() => {
    error && setIsModalShown(true)
  }, [error])

  const handleContinueWithEmail = useCallback(
    (e) => {
      e.preventDefault()

      eventLogger.logLoginMethodSelected({ method: LoginMethod.EMAIL })
      dispatch(
        registerWithEmailFirebaseAction({
          email: email.value,
          password: password.value,
        }),
      )
    },
    [dispatch, email.value, password.value],
  )

  const authStateChangeHandler = useCallback(
    (token: string) => {
      dispatch(applyWellhubPromoCodeAction(token))
    },
    [dispatch],
  )

  const handlePrefilledEmail = useCallback(
    ({ target }) => {
      setAreEmailTipsVisible(false)
      setEmail((prevState) => ({
        ...prevState,
        value: target.value,
      }))
    },
    [setEmail],
  )

  useInitFirebase()
  useGetRedirectResult(authStateChangeHandler, setIsFirebaseDataLoading)
  useAuthObserver(authStateChangeHandler)

  return (
    <S.CustomContainer fullHeight>
      {(isFetching || isFirebaseDataLoading) && <Spinner />}

      <S.Title>{t`login.title`}</S.Title>

      <S.Form onSubmit={handleContinueWithEmail}>
        <S.InputsContainer>
          <WellhubEmailLogin
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            setAreEmailTipsVisible={setAreEmailTipsVisible}
          />

          {areEmailTipsVisible && (
            <DomainsContainer>
              {domainsList.map((item) => (
                <button
                  type="button"
                  key={item}
                  value={item}
                  onClick={handlePrefilledEmail}
                >
                  {item}
                </button>
              ))}
            </DomainsContainer>
          )}
        </S.InputsContainer>
        <S.BottomContainer>
          <S.Disclaimer>
            {t`login.disclaimer`} <PrivacyPolicyLink /> {t`login.and`}{' '}
            <TermsOfUseLink />
          </S.Disclaimer>
          <Button
            type="submit"
            theme={buttonTheme.LUVLY_SECONDARY}
            disabled={!isComplete || isFetching}
            maxWidth="375px"
          >
            {t`actions.continue`}
          </Button>
        </S.BottomContainer>
      </S.Form>
      <SandyBackgroundGlobalStyles />

      <Modal
        onClose={() => {
          setIsModalShown(false)
          dispatch(resetErrorAction())
        }}
        isShown={isModalShown}
        error={error}
      />
    </S.CustomContainer>
  )
}
