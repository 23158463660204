import femaleIcon from 'assets/images/female-icon.png'
import maleIcon from 'assets/images/male-icon.png'
import nonBinary from 'assets/images/non-binary-icon.png'

import { Gender } from 'root-constants'

export const QUESTION = 'Gender'

export const OPTION_VALUES = {
  MALE: Gender.MALE,
  FEMALE: Gender.FEMALE,
  OTHER: Gender.NON_BINARY,
}

export const GENDER_ANSWERS = [
  {
    text: 'onboarding.genderV2.female',
    label: OPTION_VALUES.FEMALE,
    icon: femaleIcon,
  },
  {
    text: 'onboarding.genderV2.male',
    label: OPTION_VALUES.MALE,
    icon: maleIcon,
  },
  {
    text: 'onboarding.genderV2.other',
    label: OPTION_VALUES.OTHER,
    icon: nonBinary,
  },
]
