import doubleChinIcon from 'assets/images/double-chin-icon.png'
import faceDefinitionIcon from 'assets/images/face-definition.png'
import fineLinesIcon from 'assets/images/fine-lines.png'
import greenArrowDown from 'assets/images/green-arrow-down.png'
import greenArrowUp from 'assets/images/green-arrow-up.png'
import puffinessIcon from 'assets/images/puffiness.png'
import skinElasticityIcon from 'assets/images/skin-elasticity-icon.png'

export const QUESTION =
  'Building a consistent face yoga routine is life-changing'

export const ANSWERS = [
  {
    icon: doubleChinIcon,
    label: 'onboarding.faceYogaRoutine.option1',
    graph: greenArrowDown,
    percentage: '89%',
  },
  {
    icon: skinElasticityIcon,
    label: 'onboarding.faceYogaRoutine.option2',
    graph: greenArrowUp,
    percentage: '65%',
  },
  {
    icon: puffinessIcon,
    label: 'onboarding.faceYogaRoutine.option3',
    graph: greenArrowDown,
    percentage: '92%',
  },
  {
    icon: fineLinesIcon,
    label: 'onboarding.faceYogaRoutine.option4',
    graph: greenArrowUp,
    percentage: '71%',
  },
  {
    icon: faceDefinitionIcon,
    label: 'onboarding.faceYogaRoutine.option5',
    graph: greenArrowUp,
    percentage: '83%',
  },
]
