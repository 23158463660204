import styled, { css } from 'styled-components'

import { Color, MediaBreakpoint } from 'root-constants'

type TTagsProps = {
  isEnLocaleStyles: boolean
  fontSize?: string
}

export const StyledTags = {
  Tags: styled.div<TTagsProps>`
    display: flex;
    justify-content: left;
    flex-wrap: wrap;
    gap: 8px 12px;

    ${({ isEnLocaleStyles }) =>
      !isEnLocaleStyles &&
      css`
        display: grid;
        grid-template-columns: repeat(2, fit-content(100%));
      `}

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      justify-content: center;
    }
  `,
  Tag: styled.div<TTagsProps>`
    border-radius: 30px;
    box-shadow: 0px 4px 14px 0px rgba(8, 4, 23, 0.03);
    background: ${Color.WHITE};
    border: 2px solid ${Color.WHITE};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    cursor: pointer;
    padding: 14px;
    color: ${Color.GRAY};
    text-align: center;
    transition: 0.15s;
    letter-spacing: -0.15px;

    ${({ isEnLocaleStyles }) =>
      !isEnLocaleStyles &&
      css`
        height: 66px;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        line-height: 20px;
        text-wrap: pretty;
      `}

    input:checked + & {
      background: #ffefed;
      border-color: ${Color.CORAL};
      color: ${Color.BLUE_DARK};
    }

    @media (min-width: ${MediaBreakpoint.MEDIUM_PHONE}px) {
      font-size: ${({ fontSize }) => fontSize || '17px'};
    }
  `,
}
