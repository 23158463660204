import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

export const StyledLongPaywallUserGoalsGallery = {
  Container: styled.div`
    max-width: ${({ theme }) => theme.paywallContainer.maxWidth};
    margin: 0 auto 32px;
    background-color: ${Color.WHITE};

    & .swiper-wrapper {
      padding-bottom: 48px;
    }

    & span.swiper-pagination-bullet {
      background-color: ${Color.CREAMY};
      opacity: 1;
    }

    & span.swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: ${({ theme }) => theme.gallery.bullets};
    }
  `,
  Subtitle: styled.h2`
    text-align: center;
    font-weight: 700;
    margin-bottom: 24px;

    ${({ theme }) => css`
      color: ${theme.gallery.subtitle.color};
      font-size: ${theme.gallery.subtitle.size};
      line-height: ${theme.gallery.subtitle.lineHeight};
    `}
  `,
}
