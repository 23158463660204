import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledReduceYourDoubleChin = {
  Root: styled.div``,
  Title: styled.h1`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 24px;
    text-align: center;

    & strong {
      color: ${Color.CORAL};
    }
  `,
  Chart: styled.div`
    margin-bottom: 24px;
    min-height: 212px;
  `,
  Card: styled.div`
    display: flex;
    align-items: flex-start;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(202, 202, 202, 0.5);

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }

    & img {
      margin-right: 12px;
      width: 90px;
    }

    & p {
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: ${Color.GRAY};

      & strong {
        font-size: 17px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        color: ${Color.BLUE_DARK};
      }
    }
  `,
  ImageContainer: styled.div`
    width: 90px;
    flex-shrink: 0;
  `,
}
