import styled from 'styled-components'

import checkIcon from 'assets/images/check-icon.svg'

import { Color } from 'root-constants'

export const StyledWhatIsFaceYoga2 = {
  ListTitle: styled.h2`
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 16px;
  `,
  ListItem: styled.li`
    position: relative;
    padding-left: 36px;
    font-size: 17px;
    line-height: 24px;
    margin-bottom: 12px;
    color: ${Color.GRAY};

    & strong {
      color: ${Color.BLUE_DARK};
      font-weight: 600;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 24px;
      height: 24px;
      background-image: url(${checkIcon});
      background-size: 24px 24px;
      background-position: center;
      background-repeat: no-repeat;
    }
  `,
  SubListItem: styled.li`
    position: relative;
    margin-top: 5px;
    padding-left: 22px;
    font-size: 17px;
    line-height: 24px;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 8px;
      transform: translateY(-50%);
      width: 3px;
      height: 3px;
      border-radius: 50%;
      background-color: ${Color.GRAY};
    }
  `,
}
