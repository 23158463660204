import styled from 'styled-components'

import selectedIcon from 'assets/images/selected-option-icon.svg'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledFinalReview = {
  Root: styled.div`
    padding: 0 20px;
    margin: 0 auto;

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
    }
  `,
  Title: styled.h1`
    text-align: center;
    font-weight: ${({ theme }) => theme.common.semiBoldFontWeight};
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 15px;
  `,
  Subtitle: styled.p`
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    color: ${Color.LIGHT};
    text-align: center;
    margin-bottom: 16px;
  `,
  Name: styled.div`
    margin-bottom: 16px;
  `,
  SectionTitle: styled.h3`
    font-weight: ${({ theme }) => theme.common.semiBoldFontWeight};
    font-size: 17px;
    line-height: 22px;
    color: ${Color.DARK};
    margin-bottom: 12px;
  `,
  Gender: styled.div`
    margin-bottom: 24px;
  `,
  Label: styled.label`
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: ${Color.DARK};
    padding-left: 28px;
    position: relative;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  `,
  MarkIcon: styled.span`
    display: block;
    content: '';
    width: 20px;
    height: 20px;
    border-radius: 50%;
    opacity: 0.5;
    border: 1px solid #555965;
    position: absolute;
    top: calc(50% - 10px);
    left: 0;
  `,
  Radio: styled.input`
    width: 1px;
    height: 1px;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    z-index: -1;

    &:checked ~ span {
      background: url(${selectedIcon}) no-repeat center;
      background-size: contain;
      opacity: 1;
      border-color: transparent;
    }
  `,
  List: styled.ul``,
  Item: styled.li`
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  `,
  Action: styled.div`
    text-align: center;
    position: sticky;
    bottom: 0;
    padding: 40px 20px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.0001) 0.25%,
      #ffffff 26.14%
    );

    @media (min-width: ${MediaBreakpoint.MAX_PHONE}px) {
      max-width: 360px;
      margin: 0 auto;
    }
  `,
}
