import { ALTERNATIVE_GENDER, Gender } from 'root-constants'

export const QUESTION = 'Practice duration'

export const OPTION_VALUES = {
  UP_TO_TEN_MINUTES: 'up_to_ten_minutes',
  UP_TO_FIFTEEN_MINUTES: 'up_to_fifteen_minutes',
  UP_TO_THIRTY_MINUTES: 'up_to_thirty_minutes',
}

export const PRACTICE_DURATION_ANSWERS_MAP = {
  [OPTION_VALUES.UP_TO_TEN_MINUTES]:
    'onboarding.practiceDuration.upToTenMinutes',
  [OPTION_VALUES.UP_TO_FIFTEEN_MINUTES]:
    'onboarding.practiceDuration.upToFifteenMinutes',
  [OPTION_VALUES.UP_TO_THIRTY_MINUTES]:
    'onboarding.practiceDuration.upToThirtyMinutes',
}

export const USER_GENDER_TRANSLATIONS_MAP = {
  [Gender.MALE]: 'commonComponents.male',
  [Gender.FEMALE]: 'commonComponents.female',
  [ALTERNATIVE_GENDER]: 'commonComponents.person',
}

export const PRACTICE_DURATION_ANSWERS = [
  {
    value: OPTION_VALUES.UP_TO_TEN_MINUTES,
    text: 'onboarding.practiceDuration.tenMinutes',
  },
  {
    value: OPTION_VALUES.UP_TO_FIFTEEN_MINUTES,
    text: 'onboarding.practiceDuration.fifteenMinutes',
  },
  {
    value: OPTION_VALUES.UP_TO_THIRTY_MINUTES,
    text: 'onboarding.practiceDuration.thirtyMinutes',
  },
]
