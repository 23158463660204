import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import lottie from 'lottie-web/build/player/lottie_light'
import { Button, buttonTheme } from 'storybook-ui'

import { Container } from 'components/Container'

import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import stars from 'assets/images/review-stars-2.png'

import {
  SandyBackgroundGlobalStyles,
  StickyButtonContainer,
} from 'common-styles'
import { CDN_FOLDER_LINK } from 'root-constants'

import { StyledCreatingSculptingPlan as S } from './CreatingSculptingPlan.styles'
import {
  ANIMATION_PATH,
  MAX_COUNTER_VALUE,
  QUESTION,
  SCULPTING_PLAN_REVIEWS,
} from './constants'

export const CreatingSculptingPlan: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}: TPageProps) => {
  const { t } = useTranslation()
  const animationContainerRef = useRef<HTMLDivElement | null>(null)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const { counter } = useCounter(true, {
    duration: 3000,
    to: MAX_COUNTER_VALUE,
  })

  useEffect(() => {
    if (animationContainerRef && animationContainerRef.current) {
      lottie.loadAnimation({
        container: animationContainerRef.current,
        path: `${CDN_FOLDER_LINK}${ANIMATION_PATH}.json`,
        loop: true,
      })
    }

    return () => lottie.destroy()
  }, [])

  const handleNextClick = () => handleContinue('')

  return (
    <div>
      <Container>
        <S.Wrapper>
          <S.CounterValue>{counter}%</S.CounterValue>
          <S.AnimationContainer ref={animationContainerRef} />
        </S.Wrapper>
        <S.Subtitle>
          {t('onboarding.creatingSculptingPlan.subtitle')}
        </S.Subtitle>
        <S.Reviews>
          {SCULPTING_PLAN_REVIEWS.map(({ author, review }) => (
            <S.ReviewContainer key={author}>
              <S.ReviewAuthor>{t(author)}</S.ReviewAuthor>
              <S.StarsImage src={stars} alt="stars" />
              <S.Review>{t(review)}</S.Review>
            </S.ReviewContainer>
          ))}
        </S.Reviews>
      </Container>
      <StickyButtonContainer
        customBackground="linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.0001) 0.25%,
      #ffffff 26.14%
    )"
        centerMode
      >
        <Button
          onClick={handleNextClick}
          theme={buttonTheme.LUVLY_SECONDARY}
          disabled={counter < MAX_COUNTER_VALUE}
        >
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
      <SandyBackgroundGlobalStyles />
    </div>
  )
}
