export const FACE_YOGA_COURSE_STEPS = [
  {
    title: `onboarding.introV2.steps.first.stepTitle`,
    description: `onboarding.introV2.steps.first.stepDescription`,
  },
  {
    title: `onboarding.introV2.steps.second.stepTitle`,
    description: `onboarding.introV2.steps.second.stepDescription`,
  },
  {
    title: `onboarding.introV2.steps.third.stepTitle`,
    description: `onboarding.introV2.steps.third.stepDescription`,
  },
]
