import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { ChatAnswerButton } from 'components/ChatAnswerButton'
import { ChatMessage } from 'components/ChatMessage'
import {
  ChatAuthor,
  ChatStep,
  DELAY_BEFORE_SHOW,
} from 'components/ChatMessage/ChatMessage'
import { Container } from 'components/Container'
import { Option } from 'components/Option'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Chat, DelayedBottomContainer, DelayedContainer } from 'common-styles'
import { Cohort, I18N_CONTEXT_COHORT_MAP, OptionType } from 'root-constants'

import { StyledLikeChatMentalHealth as S } from './LikeChatMentalHealth.styles'
import {
  MENTAL_HEALTH_ANSWERS_MAP,
  MENTAL_HEALTH_OPTIONS,
  OPTION_VALUES,
  QUESTION,
} from './constants'

export const LikeChatMentalHealth: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])
  const [chatStep, setChatStep] = useState<ChatStep>(ChatStep.EXPERT_QUESTION)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => {
    const uniqueAnswers = [...new Set(answers)]
    handleContinue(uniqueAnswers)
  }

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    onChange: (value, isChecked: boolean) => {
      const isSingleOption =
        value === OPTION_VALUES.NONE_OF_THE_ABOVE ||
        value === OPTION_VALUES.PREFER_NOT_TO_ANSWER

      if (isChecked && isSingleOption) {
        setAnswers([value])

        return
      }

      if (isChecked) {
        setAnswers(
          [...answers, value].filter(
            (item) =>
              item !== OPTION_VALUES.NONE_OF_THE_ABOVE &&
              item !== OPTION_VALUES.PREFER_NOT_TO_ANSWER,
          ),
        )

        return
      }

      setAnswers(answers.filter((item) => item !== value))
    },
  }

  return (
    <Container fullHeight justifyContent="flex-end">
      <Chat>
        <ChatMessage
          author={ChatAuthor.EXPERT}
          delayBeforeShow={DELAY_BEFORE_SHOW}
          onTransitionEnd={() => setChatStep(ChatStep.SHOW_OPTIONS)}
          hasJustNowLabel={
            chatStep === ChatStep.EXPERT_QUESTION ||
            chatStep === ChatStep.SHOW_OPTIONS
          }
        >
          {t('onboarding.likeChatMentalHealth.title')}
        </ChatMessage>
        <ChatMessage
          author={ChatAuthor.USER}
          {...(chatStep === ChatStep.USER_ANSWER && {
            delayBeforeShow: DELAY_BEFORE_SHOW,
          })}
          onTransitionEnd={() => setChatStep(ChatStep.EXPERT_EXPRESSION)}
        >
          {answers.length &&
            answers.map((item, i) => (
              <S.Answer key={item}>
                <Trans
                  i18nKey={MENTAL_HEALTH_ANSWERS_MAP[item]}
                  context={I18N_CONTEXT_COHORT_MAP[Cohort.LUVLY_CHAT]}
                />
                {i !== answers.length - 1 ? ', ' : ''}
              </S.Answer>
            ))}
        </ChatMessage>
        <ChatMessage
          author={ChatAuthor.EXPERT}
          {...(chatStep === ChatStep.EXPERT_EXPRESSION && {
            delayBeforeShow: DELAY_BEFORE_SHOW,
          })}
          onTransitionEnd={() => setChatStep(ChatStep.FINISH)}
          hasJustNowLabel={
            chatStep === ChatStep.EXPERT_EXPRESSION ||
            chatStep === ChatStep.FINISH
          }
        >
          {t('onboarding.likeChatMentalHealth.expression')}
        </ChatMessage>
      </Chat>

      <DelayedContainer isShown={chatStep === ChatStep.SHOW_OPTIONS}>
        <S.OptionsContainer>
          {MENTAL_HEALTH_OPTIONS.map(({ value, text }) => (
            <Option
              {...optionProps}
              key={value}
              value={value}
              withoutMargin
              checked={answers.includes(value)}
            >
              <ChatAnswerButton withCheckbox>
                <Trans
                  i18nKey={text}
                  context={I18N_CONTEXT_COHORT_MAP[Cohort.LUVLY_CHAT]}
                />
              </ChatAnswerButton>
            </Option>
          ))}
        </S.OptionsContainer>
      </DelayedContainer>

      <DelayedBottomContainer isShown={chatStep === ChatStep.FINISH}>
        <Button onClick={handleNextClick}>{t('actions.continue')}</Button>
      </DelayedBottomContainer>

      <DelayedBottomContainer isShown={chatStep === ChatStep.SHOW_OPTIONS}>
        <Button
          onClick={() => setChatStep(ChatStep.USER_ANSWER)}
          disabled={!answers.length}
        >
          {t('actions.send')}
        </Button>
      </DelayedBottomContainer>
    </Container>
  )
}
