import styled from 'styled-components'

import { AdaptiveContainer } from 'components/AdaptiveContainer'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledFaceYogaExperts3 = {
  Title: styled(AdaptiveContainer.Title)`
    margin-bottom: 24px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 32px;
      text-align: left;
    }
  `,
  ImageContainer: styled.div`
    display: flex;
    justify-content: center;
    min-height: 44px;
    margin: 40px 0 16px;
  `,
  Icon: styled.img`
    display: block;
    max-width: 38px;
  `,
  Card: styled.div`
    display: flex;
    box-shadow: 0px 4px 14px 0px rgba(8, 4, 23, 0.03);
    border-radius: 16px;
    margin-bottom: 12px;
    background-color: ${Color.WHITE};

    &:last-child {
      margin-bottom: 0;
    }
  `,
  CardPictureContainer: styled.div`
    padding: 10px 0 10px 8px;
    display: flex;
    align-items: center;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      padding: 0;
    }
  `,
  CardPicture: styled.img`
    max-width: 100px;
    height: 100px;
    aspect-ratio: 1;
    border-radius: 16px;
  `,
  CardInfo: styled.div`
    flex-grow: 1;
    padding: 12px;
  `,
  ExpertName: styled.h3`
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 4px;
  `,
  CardDescription: styled.p`
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: ${Color.GRAY};
  `,
}
