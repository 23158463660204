import { useLayoutEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  getSubscriptionListAction,
  setScreenNameAction,
} from 'root-redux/actions/common'
import { selectUserAvailableSpins } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useABTest } from 'hooks/useABTest'
import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useVatInfo } from 'hooks/useHasVatInfo'
import { useIsCurrentRoute } from 'hooks/useIsCurrentRoute'
import { usePurchaseCancelOfferRoute } from 'hooks/usePurchaseCancelOfferRoute'

import {
  AvailableFortuneWheelAttempts,
  Cohort,
  ScreenName,
  SubscriptionListType,
  SubscriptionTagsType,
  UserAge,
} from 'root-constants'

import { usePurchaseStore } from './usePurchaseStore'

const HIGH_PRICES_AGES: UserAge[] = [
  UserAge.THIRTY,
  UserAge.FOURTY,
  UserAge.FIFTY,
]

export const useGetSubscriptionsList = () => {
  const dispatch: TAppDispatch = useDispatch()
  const availableSpins = useSelector(selectUserAvailableSpins)

  const hasVatInfo = useVatInfo()
  const cohortToUse = useCohortToUse()
  const cancelOfferRoute = usePurchaseCancelOfferRoute()
  const { userAge } = usePurchaseStore()
  const {
    areAgeHighPricesActive,
    isHigherPricingRefundActive,
    isRefundNormalizedPricingActive,
  } = useABTest()
  const { isLuvlyIntroFastCohort, isLuvlyWheelCohort, isRefundCohort } =
    useActiveCohortIdentifier()

  const isCancelOfferRoute = useIsCurrentRoute(cancelOfferRoute)

  const screenName = useMemo(() => {
    if (cohortToUse === Cohort.LUVLY_EMAIL_7) return ScreenName.EMAIL_OFFER

    return isCancelOfferRoute
      ? ScreenName.CANCEL_OFFER_LONG
      : ScreenName.PURCHASE
  }, [cohortToUse, isCancelOfferRoute])

  const hasIntroFastHighPrices =
    areAgeHighPricesActive &&
    isLuvlyIntroFastCohort &&
    HIGH_PRICES_AGES.includes(userAge)

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(screenName))

    const pricesTags: SubscriptionTagsType[] = []

    hasVatInfo
      ? pricesTags.push(SubscriptionTagsType.TAX)
      : pricesTags.push(SubscriptionTagsType.NO_TAX)

    isCancelOfferRoute && pricesTags.push(SubscriptionTagsType.CANCEL_OFFER)

    hasIntroFastHighPrices && pricesTags.push(SubscriptionTagsType.AGE_30_59)
    isHigherPricingRefundActive &&
      pricesTags.push(SubscriptionTagsType.HIGHER_PRICES)
    isRefundNormalizedPricingActive &&
      isRefundCohort &&
      pricesTags.push(SubscriptionTagsType.ROUNDED_TO_99)

    isLuvlyWheelCohort &&
      availableSpins === AvailableFortuneWheelAttempts.ONE &&
      pricesTags.push(SubscriptionTagsType.DISCOUNT_50_OFF)
    isLuvlyWheelCohort &&
      availableSpins === AvailableFortuneWheelAttempts.ZERO &&
      pricesTags.push(SubscriptionTagsType.DISCOUNT_65_OFF)

    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        pricesTags.join(','),
      ),
    )
  }, [
    dispatch,
    hasVatInfo,
    isCancelOfferRoute,
    isLuvlyWheelCohort,
    availableSpins,
    screenName,
    hasIntroFastHighPrices,
    isHigherPricingRefundActive,
    isRefundNormalizedPricingActive,
    isRefundCohort,
  ])
}
