import { AnimationState } from 'modules/purchase/constants'

import { TAnswers, TUtmTags } from 'models/common.model'
import {
  IAppConfig,
  IFirebaseConfig,
  IPaymentConfig,
  TGiaApiKeys,
} from 'models/config.model'
import { IAppState } from 'models/store.model'
import { ISubscription } from 'models/subscriptions.model'
import { IVariant } from 'models/variant.model'

import { PageId } from 'page-constants'
import { Cohort, ScreenName, UserAge, UserGoal } from 'root-constants'

export const selectIsFetching = (state: IAppState): boolean =>
  state.common.isFetching
export const selectActionList = (state: IAppState): string[] =>
  state.common.actionList
export const selectError = (state: IAppState): any => state.common.error
export const selectCurrentVariant = (state: IAppState): IVariant | null =>
  state.common.currentVariant || null
export const selectCurrentVariantCohort = (state: IAppState): Cohort | null =>
  state.common.currentVariant?.cohort || null
export const selectCurrentVariantParentCohort = (
  state: IAppState,
): string | null => state.common.currentVariant?.parentCohort || null
export const selectFacebookPixelIds = (state: IAppState): string[] =>
  state.common.currentVariant?.facebookPixelIds || []
export const selectOutbrainPixelId = (state: IAppState): string =>
  state.common.currentVariant?.outbrainPixelId || ''
export const selectTaboolaPixelId = (state: IAppState): string =>
  state.common.currentVariant?.taboolaPixelId || ''
export const selectRedditPixelId = (state: IAppState): string =>
  state.common.currentVariant?.redditPixelId || ''
export const selectHasCancelOffer = (state: IAppState): boolean =>
  state.common.currentVariant?.hasCancelOffer || false
export const selectHasUpsellOffer = (state: IAppState): boolean =>
  state.common.currentVariant?.hasUpsellOffer || false
export const selectSubscriptionList = (state: IAppState): ISubscription[] =>
  state.common.subscriptions || []
export const selectConfig = (state: IAppState): IAppConfig | null =>
  state.common.appConfig
export const selectAmplitudeApiKey = (state: IAppState): string | null =>
  state.common.appConfig?.amplitudeApiKey || null
export const selectGiaApiKeys = (state: IAppState): TGiaApiKeys | null =>
  state.common.appConfig?.giaApiKeys || null
export const selectFirebaseConfig = (
  state: IAppState,
): IFirebaseConfig | null => state.common.appConfig?.firebaseConfig || null
export const selectGoogleAnalyticsId = (state: IAppState): string | null =>
  state.common.appConfig?.googleAnalyticsId || null
export const selectLiveChatWorkingHours = (
  state: IAppState,
): { startTime: string; endTime: string } | null =>
  state.common.appConfig?.liveChatWorkingHours || null
export const selectDownloadLinks = (
  state: IAppState,
): { ios: string; android: string } | null =>
  state.common.appConfig?.downloadLinks || null
export const selectPaymentConfig = (state: IAppState): IPaymentConfig | null =>
  state.common.paymentConfig
export const selectStripeAccountId = (state: IAppState): string =>
  state.common.paymentConfig?.stripe.accountId || ''
export const selectStripeAccountName = (state: IAppState): string =>
  state.common.paymentConfig?.stripe.accountName || ''
export const selectStripePublicKey = (state: IAppState): string =>
  state.common.paymentConfig?.stripe.publicKey || ''
export const selectPayPalClientId = (state: IAppState): string =>
  state.common.paymentConfig?.paypal.clientId || ''
export const selectAnswers = (state: IAppState): Partial<TAnswers> | null =>
  state.common.answers
export const selectUserGoal = (state: IAppState): UserGoal | null =>
  state.common.answers?.[PageId.INTRO]
export const selectUserAge = (state: IAppState): UserAge =>
  state.common.answers?.[PageId.AGE] || ''
export const selectFaceScanBlobImage = (state: IAppState): Blob =>
  state.common.answers?.[PageId.FACE_SCAN]
export const selectFaceScanResults = (state: IAppState): string[] =>
  state.common.answers?.[PageId.FACE_SCAN_RESULTS] || []
export const selectGrowthBookExperiments = (
  state: IAppState,
): Record<string, Record<string, string>> | null =>
  state.common.currentVariant?.growthBookExperiments || null
export const selectLanguage = (state: IAppState): string =>
  state.common.language
export const selectScreenName = (state: IAppState): ScreenName =>
  state.common.screenName
export const selectTiktokPixelId = (state: IAppState): string =>
  state.common.currentVariant?.tiktokPixelId || ''
export const selectSnapPixelId = (state: IAppState): string =>
  state.common.currentVariant?.snapPixelId || ''
export const selectUtmTags = (state: IAppState): TUtmTags =>
  state.common.utmTags || {}
export const selectIsPaymentStatusShown = (state: IAppState): boolean =>
  state.common.isPaymentStatusShown || false
export const selectPaymentAnimationStatus = (
  state: IAppState,
): AnimationState | boolean => state.common.paymentAnimationStatus || false
export const selectIsPrimerRetryProcessing = (state: IAppState): boolean =>
  state.common.isPrimerRetryProcessing
export const selectIsPostPaymentProgressbarStatic = (
  state: IAppState,
): boolean => state.common.isPostPaymentProgressbarStatic
export const selectIsInContextShown = (state: IAppState): boolean =>
  state.common.isInContextShown
