import React from 'react'

import { HeaderGift } from 'components/HeaderGift'
import { HeaderMinimalistic } from 'components/HeaderMinimalistic'

import { Cohort } from 'root-constants'

export const HEADERS: Record<string, React.FC> = {
  [Cohort.LUVLY_12_SALE]: HeaderMinimalistic,
  [Cohort.LUVLY_CHAT]: HeaderMinimalistic,
  [Cohort.LUVLY_CHIN]: HeaderMinimalistic,
  [Cohort.LUVLY_GIFT]: HeaderGift,
  [Cohort.LUVLY_GENERAL]: HeaderMinimalistic,
  [Cohort.LUVLY_WELLHUB]: HeaderMinimalistic,
}
