import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  AnswerWithIconAndCheckbox,
  Button,
  answerWithIconAndCheckboxTheme,
  buttonTheme,
} from 'storybook-ui'

import { Container } from 'components/Container'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import optionIconUrl from 'assets/images/check-icon.svg'

import {
  DoubleChinTitle,
  SandyBackgroundGlobalStyles,
  StickyButtonContainer,
} from 'common-styles'
import { PageId } from 'page-constants'
import { OptionType } from 'root-constants'

import { QUESTION, SKIN_CONCERNS_VARIANT_2_ANSWERS } from './constants'

export const SkinConcernsVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])

  const userAnswers = useSelector(selectAnswers)

  const userSkinType = userAnswers?.[PageId.SKIN_TYPE]

  const handleContinue = useNextStep({
    pageId: PageId.SKIN_CONCERNS,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    theme: answerWithIconAndCheckboxTheme.LUVLY_SOLID,
    iconWidth: '24px',
    iconHeight: '24px',
    iconSrc: optionIconUrl,
    margin: '0 0 12px',
    fontWeight: '600',
    onChange: (value: string, isChecked: boolean) => {
      if (isChecked) {
        setAnswers([...answers, value])

        return
      }

      setAnswers(answers.filter((item) => item !== value))
    },
  }

  const handleNextClick = () => handleContinue(answers)

  return (
    <div>
      <Container>
        <DoubleChinTitle>
          <Trans
            i18nKey="onboarding.skinConcerns.questionV2"
            components={[<strong />]}
            values={{
              userSkinType,
            }}
          />
        </DoubleChinTitle>
        {SKIN_CONCERNS_VARIANT_2_ANSWERS.map(({ label, value, icon }) => {
          return (
            <AnswerWithIconAndCheckbox
              {...optionProps}
              key={label}
              value={value}
              imageSrc={icon}
              checked={answers.includes(value)}
            >
              {t(label)}
            </AnswerWithIconAndCheckbox>
          )
        })}

        <SandyBackgroundGlobalStyles />
      </Container>
      <StickyButtonContainer customBackground="transparent" centerMode>
        <Button
          onClick={handleNextClick}
          disabled={!answers.length}
          theme={buttonTheme.LUVLY_SECONDARY}
        >
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
    </div>
  )
}
