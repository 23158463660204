import styled from 'styled-components'

import { AdaptiveContainer } from 'components/AdaptiveContainer'

import { MediaBreakpoint } from 'root-constants'

export const StyledShortProgramDescription2 = {
  Title: styled(AdaptiveContainer.Title)`
    text-align: center;
  `,
  Subtitle: styled(AdaptiveContainer.Subtitle)`
    text-align: center;
    margin-bottom: 24px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      margin-bottom: 32px;
    }
  `,
}
