export const QUESTION = 'Why is having a double chin so frustrating for you??'

export const OPTION_VALUES = {
  MAKES_LOOK_OLDER: 'makes_look_older',
  DOESNT_RESPOND_DIET: 'doesnt_respond_diet',
  MAKES_LOOK_HEAVIER: 'makes_look_heavier',
  HIDES_JAWLINE: 'hides_jawline',
}

export const FRUSTRATING_PAGE_ANSWERS = [
  {
    text: 'onboarding.frustratingDoubleChin.option1',
    detailed: 'onboarding.frustratingDoubleChin.detailed1',
    label: OPTION_VALUES.MAKES_LOOK_OLDER,
  },
  {
    text: 'onboarding.frustratingDoubleChin.option2',
    detailed: 'onboarding.frustratingDoubleChin.detailed2',
    label: OPTION_VALUES.DOESNT_RESPOND_DIET,
  },
  {
    text: 'onboarding.frustratingDoubleChin.option3',
    detailed: 'onboarding.frustratingDoubleChin.detailed3',
    label: OPTION_VALUES.MAKES_LOOK_HEAVIER,
  },
  {
    text: 'onboarding.frustratingDoubleChin.option4',
    detailed: 'onboarding.frustratingDoubleChin.detailed4',
    label: OPTION_VALUES.HIDES_JAWLINE,
  },
]
