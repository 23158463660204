import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  AnswerWithCheckbox,
  Button,
  answerWithCheckboxTheme,
  buttonTheme,
} from 'storybook-ui'

import { Container } from 'components/Container'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import checkIcon from 'assets/images/check-white.svg'

import { SandyBackgroundGlobalStyles } from 'common-styles'
import { OptionType } from 'root-constants'

import { StyledCareCosmetics3 as S } from './CareCosmetics3.styles'
import {
  DEFAULT_CARE_COSMETICS_OPTIONS,
  OPTION_VALUES,
  QUESTION,
} from './constants'

export const CareCosmetics3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue(answers)

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    theme: answerWithCheckboxTheme.LUVLY,
    iconSrc: checkIcon,
    iconSize: '12px',
    margin: '0 0 12px 0',
    onChange: (value: string, isChecked: boolean) => {
      const isNoneOptionTouched = value === OPTION_VALUES.NONE

      if (isChecked && isNoneOptionTouched) {
        setAnswers([value])

        return
      }

      if (isChecked) {
        setAnswers(
          [...answers, value].filter((item) => item !== OPTION_VALUES.NONE),
        )

        return
      }

      setAnswers(answers.filter((item) => item !== value))
    },
  }

  return (
    <div>
      <Container>
        <S.Title>
          <Trans i18nKey="onboarding.careCosmetics.question2" />
        </S.Title>
        <S.Description>
          <Trans i18nKey="onboarding.careCosmetics.description" />
        </S.Description>
        {DEFAULT_CARE_COSMETICS_OPTIONS.map(({ value, text }) => (
          <AnswerWithCheckbox
            {...optionProps}
            value={value}
            key={value}
            checked={answers.includes(value)}
          >
            {t(text)}
          </AnswerWithCheckbox>
        ))}
        <SandyBackgroundGlobalStyles />
      </Container>
      <StickyButtonContainer centerMode>
        <Button
          theme={buttonTheme.LUVLY_SECONDARY}
          onClick={handleNextClick}
          disabled={!answers.length}
        >
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
    </div>
  )
}
