import styled, { css } from 'styled-components'

import { AdaptiveContainer } from 'components/AdaptiveContainer'

import { MediaBreakpoint } from 'root-constants'

type TTitleContainerProps = {
  isSmallSize?: boolean
}

type TOptionsContainerProps = {
  textAlign?: 'left' | 'center'
}

export const StyledSkinCareRoutineDescription = {
  TitleContainer: styled.header<TTitleContainerProps>`
    margin-bottom: 16px;
    font-weight: 500;
    text-align: center;

    ${({ isSmallSize }) =>
      isSmallSize
        ? css`
            font-size: 16px;
            line-height: 24px;
          `
        : css`
            font-size: 17px;
            line-height: 26px;
          `}
  `,
  OptionsContainer: styled.div<TOptionsContainerProps>`
    text-align: ${({ textAlign = 'center' }) => textAlign};
  `,
  Title: styled(AdaptiveContainer.Title)`
    font-size: 18px;
    margin-bottom: 24px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 32px;
      text-align: left;
    }
  `,
  OptionsContainer2: styled.div`
    max-width: 256px;
    margin-top: 24px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: unset;
      margin-top: 32px;
    }
  `,
}
