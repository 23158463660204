import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSavingMoney = {
  Root: styled.div`
    position: relative;
    border-radius: 24px;
    background-color: #ffeaec;
    margin-bottom: 48px;
    overflow: hidden;
  `,
  SaveImage: styled.img`
    position: absolute;
    top: 0;
    right: 0;
    max-width: 107px;
    height: auto;
    z-index: 1;
  `,
  AnimationContainer: styled.div`
    aspect-ratio: 343/208;
    width: 100%;
    max-width: 343px;
    height: auto;
    margin: 0 auto;
  `,
  Text: styled.p`
    color: ${Color.BLUE_DARK};
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    padding: 16px;

    & strong {
      color: ${Color.CORAL};
    }
  `,
}
