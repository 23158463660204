export const QUESTION =
  'What is your top priority in treating your double chin?'

const OPTION_VALUES = {
  FAST_FAT_REDUCTION: 'fast_fat_reduction',
  TIGHTENING_SKIN: 'tightening_skin',
  LONG_TERM_RESULTS: 'long_term_results',
  NON_INVASIVE_SOLUTIONS: 'non_invasive_solutions',
}

export const ANSWERS = [
  {
    value: OPTION_VALUES.FAST_FAT_REDUCTION,
    text: 'onboarding.doubleChinTreating.fatReduction',
  },
  {
    value: OPTION_VALUES.TIGHTENING_SKIN,
    text: 'onboarding.doubleChinTreating.tighteningSkin',
  },
  {
    value: OPTION_VALUES.LONG_TERM_RESULTS,
    text: 'onboarding.doubleChinTreating.longTermResults',
  },
  {
    value: OPTION_VALUES.NON_INVASIVE_SOLUTIONS,
    text: 'onboarding.doubleChinTreating.nonInvasiveSolutions',
  },
]
