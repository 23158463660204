import femaleGenderIcon from 'assets/images/female-gender-icon.png'
import maleGenderIcon from 'assets/images/male-gender-icon.png'
import nonBinaryGenderIcon from 'assets/images/non-binary-gender-icon.png'

import { Gender } from 'root-constants'

export const GENDER_IMAGE = {
  [Gender.FEMALE]: femaleGenderIcon,
  [Gender.MALE]: maleGenderIcon,
  [Gender.NON_BINARY]: nonBinaryGenderIcon,
}

export const USER_STORIES = [
  {
    review: 'onboarding.personalizedPlan.reviews.review1',
    name: 'onboarding.personalizedPlan.reviews.reviewer1',
  },
  {
    review: 'onboarding.personalizedPlan.reviews.review2',
    name: 'onboarding.personalizedPlan.reviews.reviewer2',
  },
  {
    review: 'onboarding.personalizedPlan.reviews.review3',
    name: 'onboarding.personalizedPlan.reviews.reviewer3',
  },
]

export const PAYWALL_HERO_WOMAN_IMAGE_PATH =
  '/images/personalized-plan-hero-female'
export const PAYWALL_HERO_MAN_IMAGE_PATH = '/images/personalized-plan-hero-male'
export const VISIBLE_RESULTS_WOMAN_IMAGE_PATH = '/images/visible-results-female'
export const VISIBLE_RESULTS_MAN_IMAGE_PATH = '/images/visible-results-male'
