/* eslint-disable max-lines */
import { ChatBotEmail } from 'pages/chatBotEmail'
import { Download, WellhubDownload } from 'pages/download'
import {
  Email,
  EmailConsent,
  EmailVariant2,
  EmailVariant3,
  LikeChatEmail,
  LikeChatEmailConsent,
} from 'pages/email'
import { FinishingTouches } from 'pages/finishingTouches'
import {
  IntroGiftCard,
  IntroInfluencers,
  IntroLikeChat,
  IntroVariant1,
  IntroVariant2,
  IntroVariantRefund,
  LoadingIntro,
  LoadingIntro2,
  LoadingIntro3,
} from 'pages/intro'
import {
  AffectSkinCondition,
  Age,
  AgeAsIntroProvider,
  AgeAsIntroWithAnimation,
  AgeRelatedChanges,
  AgeWithImages,
  AgeWithImages2,
  AllRoundApproach,
  AlmostThere,
  AnalyzingAnswers,
  AnalyzingAnswersVariant2,
  AnalyzingAnswersVariant3,
  BeautySolutionsConcerns,
  BiggestMotivation,
  CareCosmetics,
  CareCosmetics2,
  CareCosmetics3,
  ChatStatementQuestion,
  CosmetologistVsLuvly,
  CosmetologistVsLuvly2,
  CreatingSculptingPlan,
  CustomizeGiftCard,
  DailyWater,
  DailyWaterVariant2,
  DailyWaterVariant3,
  DailyYoga,
  Diet,
  DietVariant2,
  DietVariant3,
  DoubleChin,
  DoubleChinCause,
  DoubleChinChanges,
  DoubleChinConcerns,
  DoubleChinLifestyle,
  DoubleChinPeriod,
  DoubleChinReduction,
  DoubleChinTreating,
  EasyWithLuvly,
  EatingPatterns,
  EatingPatterns2,
  EmbracingAge,
  ExpertLoader,
  FaceChangesCauses,
  FaceShape,
  FaceShapeChange,
  FaceShapeV2,
  FaceYogaExperts,
  FaceYogaExpertsVariant2,
  FaceYogaExpertsVariant3,
  FaceYogaRoutine,
  FrustratingDoubleChin,
  FrustratingReasons,
  Gender,
  GenderV2,
  GenderV3,
  GettingRidDoubleChin,
  GiftCardPaywall,
  Goal,
  GoalVariant2,
  GoalVariant3,
  GoalVariant4,
  GoalVariant5,
  GoingToLoveIt,
  HaveSolution,
  HeardAboutYoga,
  HeardAboutYoga2,
  HolisticPlan,
  IdentifySkinColor,
  IntroSaleAgeAsIntro,
  LikeChatAge,
  LikeChatAnalyzingAnswers,
  LikeChatCareCosmetics,
  LikeChatChallenges1,
  LikeChatChallenges2,
  LikeChatDiet,
  LikeChatEatingPatterns,
  LikeChatExercises,
  LikeChatFaceYoga,
  LikeChatGoal,
  LikeChatMentalHealth,
  LikeChatMotivation,
  LikeChatNutritionFeedback,
  LikeChatPracticeDuration,
  LikeChatProblematicAreas,
  LikeChatSkinCareRoutine,
  LikeChatSkinConcerns,
  LikeChatSkinQuality,
  LikeChatSkinType,
  LikeChatSugar,
  LikeChatVegetables,
  LuvlyGuide,
  LuvlyUsers,
  ManageStressLevels,
  MenstrualCycle,
  MentalHealth,
  MentalHealthV2,
  Motivation,
  NaturalFacialPractice,
  ObTags,
  PersonalKoreanPlan,
  PersonalizedPlan,
  PersonalizedProgram,
  PhotoResult,
  PracticeDuration,
  PracticeDurationV3,
  PracticeDurationVariant2,
  PreferredFood,
  PreferredFoodV2,
  PreliminaryProgress,
  PreparePlan,
  PreparePlanV2,
  ProblematicAreas,
  ProblematicAreasV2,
  ProblematicAreasV3,
  ProductIngredientFeedback,
  RealTransformation,
  ReduceYourDoubleChin,
  RegularExercise,
  ReverseYourWrinkles,
  ReverseYourWrinkles2,
  ReverseYourWrinkles3,
  SatisfyWithSkinCondition,
  SatisfyWithSkinCondition2,
  ScannerFeedback,
  ShareGiftCard,
  ShortProgramDescription,
  ShortProgramDescription2,
  ShortProgramDescriptionAsResult,
  SignsOfAging,
  SkinCareAndDiet,
  SkinCareRoutine,
  SkinCareRoutine2,
  SkinCareRoutineDescription,
  SkinCareRoutineDescriptionV2,
  SkinConcerns,
  SkinConcernsVariant2,
  SkinConcernsVariant3,
  SkinConcernsVariant4,
  SkinSensitivity,
  SkinType,
  SkinTypeExplanation,
  SkinTypeV3,
  SkinTypeVariant2,
  SkincareChallenges,
  SleepHabits,
  SmallStepsHelp,
  SocialProof,
  StatementQuestion,
  StatementQuestionVariant2,
  StatementQuestionVariant3,
  StressLevel,
  StressLevels,
  Sugar,
  Sugar2,
  SunSafety,
  SunSafety2,
  Tags,
  ThankForSharing,
  TimeToInvest,
  TriedKoreanBeauty,
  TrustFeedback,
  UselessProducts,
  Vegetables,
  Vegetables2,
  VisitToCosmetologist,
  VisitToCosmetologist2,
  WeWantYouLookBest,
  WeWantYouLookBest2,
  WelcomeToLuvly,
  WhatIsFaceYoga,
  WhatIsFaceYoga2,
  WonderfulStart,
  YouAreBeautiful,
  YouAreInGoodHands,
  YouAreInGoodHandsVariant2,
  YouAreInGoodHandsVariant3,
} from 'pages/questions'
import {
  FaceScan,
  FaceScanPreview,
  FaceScanResults,
  Recommendations,
  SkinDiagnosisIntro,
} from 'pages/skinDiagnosis'

import { Login, WellhubLogin } from 'modules/login'
import {
  HolidaysPurchaseCancelOffer,
  PurchaseCosmeticScanner,
  PurchaseIntroFastProvider,
  PurchaseRefund,
  PurchaseUpgrade,
  PurchaseVariant2,
  PurchaseVariant4,
  PurchaseVariant8,
  UpsellCoaching,
  UpsellPaywallSwitchers,
  UpsellWellnessBundle,
} from 'modules/purchase/pages'
import { PurchaseUpgradePDF } from 'modules/purchase/pages/PurchaseUpgradePDF'

import { IDynamicPage, TIdToPageMap } from 'models/common.model'

import { Cohort, PINNED_PROGRESS_BAR_WITH_NAVIGATION } from 'root-constants'

export const enum PageId {
  INTRO = 'intro',
  GOAL = 'goal',
  GOAL_VARIANT_2 = 'goalVariant2',
  GOAL_VARIANT_3 = 'goalVariant3',
  GOAL_VARIANT_4 = 'goalVariant4',
  GOAL_VARIANT_5 = 'goalVariant5',
  HAVE_YOU_HEARD = 'haveYouHeard',
  HAVE_YOU_HEARD_2 = 'haveYouHeard2',
  WHAT_IS_FACE_YOGA = 'whatIsFaceYoga',
  WHAT_IS_FACE_YOGA_2 = 'whatIsFaceYoga2',
  SATISFY_WITH_SKIN_CONDITION = 'satisfyWithSkinCondition',
  SATISFY_WITH_SKIN_CONDITION_2 = 'satisfyWithSkinCondition2',
  SKIN_CARE_ROUTINE = 'skinCareRoutine',
  SKIN_CARE_ROUTINE_2 = 'skinCareRoutine2',
  SKIN_CARE_AND_DIET = 'skinCareAndDiet',
  CARE_COSMETICS = 'careCosmetics',
  CARE_COSMETICS_2 = 'careCosmetics2',
  CARE_COSMETICS_3 = 'careCosmetics3',
  SUN_SAFETY = 'sunSafety',
  SUN_SAFETY_2 = 'sunSafety2',
  VISIT_TO_COSMETOLOGIST = 'visitToCosmetologist',
  VISIT_TO_COSMETOLOGIST_2 = 'visitToCosmetologist2',
  COSMETOLOGIST_VS_LUVLY = 'cosmetologistVsLuvly',
  COSMETOLOGIST_VS_LUVLY_2 = 'cosmetologistVsLuvly2',
  TARGET_GOAL = 'targetGoal',
  OB_TAGS = 'obTags',
  TAGS = 'tags',
  EATING_PATTERNS = 'eatingPatterns',
  EATING_PATTERNS_2 = 'eatingPatterns2',
  DIET = 'diet',
  DIET_VARIANT_2 = 'dietVariant2',
  DIET_VARIANT_3 = 'dietVariant3',
  SUGAR = 'sugar',
  SUGAR_2 = 'sugar2',
  VEGETABLES = 'vegetables',
  VEGETABLES_2 = 'vegetables2',
  DAILY_WATER = 'dailyWater',
  DAILY_WATER_VARIANT_2 = 'dailyWaterVariant2',
  DAILY_WATER_VARIANT_3 = 'dailyWaterVariant3',
  GENDER = 'gender',
  GENDER_VARIANT_2 = 'genderVariant2',
  GENDER_VARIANT_3 = 'genderVariant3',
  PERSONALIZED_PROGRAM = 'personalizedProgram',
  USERS_STATEMENT = 'usersStatement',
  PHOTO_RESULT = 'photoResult',
  ALMOST_THERE = 'almostThere',
  WELCOME_TO_LUVLY = 'welcomeToLuvly',
  WONDERFUL_START = 'wonderfulStart',
  YOU_ARE_BEAUTIFUL = 'youAreBeautiful',
  THANK_FOR_SHARING = 'thankForSharing',
  NO_DESIRED_RESULT = 'noDesiredResult',
  NOT_GREAT_RESULTS = 'notGreatResults',
  MAKE_ME_LOOK_HEAVIER = 'makeMeLookHeavier',
  NOT_REFLECT_FEELINGS = 'notReflectFeeling',
  WE_WANT_YOU_LOOK_BEST = 'weWantYouLookBest',
  WE_WANT_YOU_LOOK_BEST_2 = 'weWantYouLookBest2',
  NATURAL_FACIAL_PRACTICE = 'naturalFacialPractice',
  SOCIAL_PROOF = 'socialProof',
  SKINCARE_CHALLENGES = 'skincareChallenges',
  AGE = 'age',
  AFFECT_SKIN_CONDITION = 'affectSkinCondition',
  AGE_WITH_IMAGES = 'ageWithImages',
  AGE_WITH_IMAGES_2 = 'ageWithImages2',
  DOUBLE_CHIN = 'doubleChin',
  DOUBLE_CHIN_PERIOD = 'doubleChinPeriod',
  DOUBLE_CHIN_CAUSE = 'doubleChinCause',
  FRUSTRATING_DOUBLE_CHIN = 'frustratingDoubleChin',
  FRUSTRATING_REASONS = 'frustratingReasons',
  IDENTIFY_SKIN_COLOR = 'identifySkinColor',
  DOUBLE_CHIN_CHANGES = 'doubleChinChanges',
  STRESS_LEVELS = 'stressLevels',
  DOUBLE_CHIN_LIFESTYLE = 'doubleChinLifestyle',
  REGULAR_EXERCISE = 'regularExercise',
  AGE_AS_INTRO = 'ageAsIntro',
  AGE_WITH_ANIMATION = 'ageAsIntroWithAnimation',
  LIKE_CHAT_INTRO = 'startChat',
  INTRO_REFUND = 'introRefund',
  INTRO_SALE_AGE = 'introSaleAge',
  LOADING_INTRO = 'loadingIntro',
  LOADING_INTRO_2 = 'loadingIntro2',
  LOADING_INTRO_3 = 'loadingIntro3',
  INTRO_INFLUENCERS = 'introInfluencers',
  INTRO_GIFT_CARD = 'giftCardIntro',
  GIFT_CARD_PAYWALL = 'giftCardPaywall',
  DAILY_YOGA = 'dailyYoga',
  PROBLEMATIC_AREA = 'problematicArea',
  PROBLEMATIC_AREA_V3 = 'problematicAreaV3',
  PROBLEMATIC_AREA_INTERACTIVE = 'problematicAreaInteractive',
  SKIN_TYPE = 'skinType',
  SKIN_TYPE_V3 = 'skinTypeV3',
  SKIN_TYPE_EXPLANATION = 'skinTypeExplanation',
  SKIN_TYPE_VARIANT_2 = 'skinTypeVariant2',
  SKIN_CONCERNS = 'skinConcerns',
  SKIN_CONCERNS_VARIANT_2 = 'skinConcernsVariant2',
  SKIN_CONCERNS_VARIANT_3 = 'skinConcernsVariant3',
  SKIN_CONCERNS_VARIANT_4 = 'skinConcernsVariant4',
  SIGNS_OF_AGING = 'signsOfAging',
  GETTING_RID_DOUBLE_CHIN = 'gettingRidDoubleChin',
  TIME_TO_INVEST = 'timeToInvest',
  DOUBLE_CHIN_TREATMENT = 'doubleChinTreatment',
  DOUBLE_CHIN_CONCERNS = 'doubleChinConcerns',
  DOUBLE_CHIN_REDUCTION = 'doubleChinReduction',
  SLEEP_HABITS = 'sleepHabits',
  AGE_RELATED_CHANGES = 'ageRelatedChanges',
  SKIN_SENSITIVITY = 'skinSensitivity',
  MANAGE_STRESS_LEVELS = 'manageStressLevels',
  PRACTICE_DURATION_VARIANT_2 = 'practiceDurationV2',
  CREATING_SCULPTING_PLAN = 'creatingSculptingPlan',
  HOLISTIC_PLAN = 'holisticPlan',
  MOTIVATION = 'motivation',
  SHORT_PROGRAM_DESCRIPTION = 'shortProgramDescription',
  SHORT_PROGRAM_DESCRIPTION_2 = 'shortProgramDescription2',
  SHORT_PROGRAM_DESCRIPTION_AS_RESULT = 'shortProgramDescriptionAsResult',
  SKIN_CARE_ROUTINE_DESCRIPTION = 'skinCareRoutineDescription',
  SKIN_CARE_ROUTINE_DESCRIPTION_V2 = 'skinCareRoutineDescriptionV2',
  PREFERRED_FOOD = 'preferredFood',
  PREFERRED_FOOD_V2 = 'preferredFoodV2',
  PRACTICE_DURATION = 'practiceDuration',
  PRACTICE_DURATION_V3 = 'practiceDurationV3',
  FACE_SHAPE = 'faceShape',
  FACE_SHAPE_V2 = 'faceShapeV2',
  BIGGEST_MOTIVATION = 'biggestMotivation',
  FACE_CHANGES_CAUSES = 'faceChangesCauses',
  EMBRACING_AGE = 'embracingAge',
  SKIN_REQUIRES_COMMITMENT = 'skinRequiresCommitment',
  HAPPINESS_IS_HABIT = 'happinessIsHabit',
  SKIN_DIAGNOSIS_INTRO = 'skinDiagnosisIntro',
  BEAUTY_SOLUTIONS_CONCERNS = 'beautySolutionsConcerns',
  FACE_SCAN_PREVIEW = 'faceScanPreview',
  FACE_SCAN = 'faceScan',
  FACE_SCAN_RESULTS = 'faceScanResults',
  FACE_YOGA_ROUTINE = 'faceYogaRoutine',
  ML_RECOMMENDATIONS = 'mlRecommendations',
  MIRROR_REFLECTION = 'mirrorReflection',
  MIRROR_REFLECTION_V2 = 'mirrorReflectionV2',
  COMPARE_MYSELF_TO_OTHERS = 'compareMyselfToOthers',
  COMPARE_MYSELF_TO_OTHERS_V2 = 'compareMyselfToOthersV2',
  APPEARANCE_EFFECT = 'appearanceEffect',
  APPEARANCE_EFFECT_V2 = 'appearanceEffectV2',
  AFRAID_TO_LOOK_OLDER = 'afraidToLookOlder',
  AFRAID_TO_LOOK_OLDER_V2 = 'afraidToLookOlderV2',
  EASY_WITH_LUVLY = 'easyWithLuvly',
  TO_BE_EXPERT = 'toBeExpert',
  COSMETICS_HELP = 'cosmeticsHelp',
  COSMETICS_HELP_V2 = 'cosmeticsHelpV2',
  PRELIMINARY_PROGRESS = 'preliminaryProgress',
  SHORT_PROGRAM_DESCRIPTION_SECOND = 'shortProgramDescriptionSecond',
  MENSTRUAL_CYCLE = 'menstrualCycle',
  MENTAL_HEALTH = 'mentalHealth',
  MENTAL_HEALTH_V2 = 'mentalHealthV2',
  PREPARE_PLAN = 'preparePlan',
  PERSONALIZED_PLAN = 'personalizedPlan',
  PREPARE_PLAN_V2 = 'preparePlanV2',
  YOU_ARE_IN_GOOD_HANDS = 'youAreInGoodHands',
  YOU_ARE_IN_GOOD_HANDS_VARIANT_2 = 'youAreInGoodHandsV2',
  YOU_ARE_IN_GOOD_HANDS_VARIANT_3 = 'youAreInGoodHandsV3',
  FACE_YOGA_EXPERTS = 'faceYogaExperts',
  FACE_YOGA_EXPERTS_VARIANT_2 = 'faceYogaExpertsVariant2',
  FACE_YOGA_EXPERTS_VARIANT_3 = 'faceYogaExpertsVariant3',
  REVERSE_YOUR_WRINKLES = 'reverseYourWrinkles',
  REVERSE_YOUR_WRINKLES_2 = 'reverseYourWrinkles2',
  REVERSE_YOUR_WRINKLES_3 = 'reverseYourWrinkles3',
  REDUCE_YOUR_DOUBLE_CHIN = 'reduceYourDoubleChin',
  ANALYZING_ANSWERS = 'analyzingAnswers',
  ANALYZING_ANSWERS_VARIANT_2 = 'analyzingAnswersVariant2',
  ANALYZING_ANSWERS_VARIANT_3 = 'analyzingAnswersVariant3',
  CUSTOMIZE_GIFT_CARD = 'customizeGiftCard',
  SHARE_GIFT_CARD = 'shareGiftCard',
  NAME = 'name',
  OB_LOADER = 'obLoader',
  OB_GOAL = 'obGoal',
  OB_AGE = 'obAge',
  OB_FACE_YOGA = 'obFaceYoga',
  OB_SKIN = 'obSkin',
  OB_AREAS = 'obAreas',
  OB_SKIN_TYPE = 'obSkinType',
  OB_ROUTINE = 'obRoutine',
  OB_PRODUCTS = 'obProducts',
  OB_CHALLENGES_1 = 'obChallenges1',
  OB_CHALLENGES_2 = 'obChallenges2',
  OB_SKIN_CONCERNS = 'obSkinConcerns',
  OB_EXERCISES = 'obExercises',
  OB_MEALS = 'obMeals',
  OB_DIET = 'obDiet',
  OB_SWEETS = 'obSweets',
  OB_VEGETABLES = 'obVegetables',
  OB_NUTRITION_FEEDBACK = 'obNutritionFeedback',
  OB_MENTAL_HEALTH = 'obMentalHealth',
  OB_STATEMENT_1 = 'obStatement1',
  OB_STATEMENT_2 = 'obStatement2',
  OB_STATEMENT_3 = 'obStatement3',
  OB_MOTIVATION = 'obMotivation',
  OB_SCHEDULE = 'obSchedule',
  OB_CREATING = 'obCreating',
  OB_EMAIL = 'obEmail',
  OB_EMAIL_CONSENT = 'obEmailConsent',
  OB_RESULT = 'obResult',
  ENTER_EMAIL = 'enterEmail',
  ENTER_EMAIL_VARIANT_2 = 'enterEmailV2',
  ENTER_EMAIL_VARIANT_3 = 'enterEmailV3',
  EMAIL_CONSENT = 'emailConsent',
  PURCHASE_EMAIL = 'purchaseEmail',
  PURCHASE_VARIANT_2 = 'purchaseVariant2',
  PURCHASE_VARIANT_4 = 'purchaseVariant4',
  PURCHASE = 'purchase',
  HOLIDAYS_PURCHASE_CANCEL_OFFER = 'holidaysPurchaseCancelOffer',
  PURCHASE_INTRO_CANCEL_OFFER = 'paywallCancelOffer',
  PURCHASE_INTRO_OFFER = 'purchaseIntroOffer',
  PURCHASE_CANCEL_REFUND = 'purchaseCancelRefund',
  PURCHASE_REFUND = 'purchaseRefund',
  PURCHASE_COSMETIC_SCANNER = 'purchaseCosmeticScanner',
  PURCHASE_VARIANT_8 = 'purchaseVariant8',
  PURCHASE_CANCEL_CHIN = 'purchaseCancelChin',
  PURCHASE_CANCEL_GENERAL = 'purchaseCancelGeneral',
  PURCHASE_UPGRADE = 'purchaseUpgrade',
  PURCHASE_UPGRADE_PDF = 'purchaseUpgradePDF',
  PURCHASE_RE_TARGETING = 'purchaseReTargeting',
  LIVECHAT_UPSELL_IN_APP = 'livechatUpsellInApp',
  LIVECHAT_UPSELL_CANCEL_IN_APP = 'livechatUpsellCancelInApp',
  UPSELL_PAYWALL = 'upsellPaywall',
  COACHING_PAYWALL = 'coachingPaywall',
  COACHING_CANCEL_PAYWALL = 'coachingCancelPaywall',
  WELLNESS_BUNDLE_PAYWALL = 'wellnessBundlePaywall',
  WELLNESS_BUNDLE_CANCEL_PAYWALL = 'wellnessBundleCancelPaywall',
  FINISHING_TOUCHES = 'finishingTouches',
  LOGIN = 'login',
  WELLHUB_LOGIN = 'wellhubLogin',
  DOWNLOAD = 'download',
  WELLHUB_DOWNLOAD = 'wellhubDownload',
  PROXY_TO_START = 'proxyToStart',
  PAYPAL_PROXY = 'paypalProxy',
  LUVLY_USERS = 'luvlyUsers',
  LUVLY_GUIDE = 'luvlyGuide',
  ALL_ROUND_APPROACH = 'allRoundApproach',
  FACE_SHAPE_CHANGE = 'faceShapeChange',
  PRODUCT_INGREDIENT_FEEDBACK = 'productIngredientFeedback',
  USELESS_PRODUCTS = 'uselessProducts',
  SCANNER_FEEDBACK = 'scannerFeedback',
  TRUST_FEEDBACK = 'trustFeedback',
  PERSONAL_KOREAN_PLAN = 'personalKoreanPlan',
  TRIED_KOREAN_BEAUTY = 'triedKoreanBeauty',
  HAVE_SOLUTION = 'haveSolution',
  REAL_TRANSFORMATION = 'realTransformation',
  SMALL_STEPS_HELP = 'smallStepsHelp',
  GOING_TO_LOVE_IT = 'goingToLoveIt',
  STRESS_LEVEL = 'stressLevel',
}

export const ID_TO_PAGE_MAP: TIdToPageMap = {
  personalKoreanPlan: PersonalKoreanPlan,
  triedKoreanBeauty: TriedKoreanBeauty,
  haveSolution: HaveSolution,
  realTransformation: RealTransformation,
  smallStepsHelp: SmallStepsHelp,
  stressLevel: StressLevel,
  goingToLoveIt: GoingToLoveIt,
  intro: IntroVariant1,
  introRefund: IntroVariantRefund,
  introSaleAge: IntroSaleAgeAsIntro,
  introInfluencers: IntroInfluencers,
  giftCardIntro: IntroGiftCard,
  luvlyUsers: LuvlyUsers,
  luvlyGuide: LuvlyGuide,
  allRoundApproach: AllRoundApproach,
  haveYouHeard: HeardAboutYoga,
  haveYouHeard2: HeardAboutYoga2,
  whatIsFaceYoga: WhatIsFaceYoga,
  whatIsFaceYoga2: WhatIsFaceYoga2,
  satisfyWithSkinCondition: SatisfyWithSkinCondition,
  satisfyWithSkinCondition2: SatisfyWithSkinCondition2,
  skinCareRoutine: SkinCareRoutine,
  skinCareRoutine2: SkinCareRoutine2,
  skinSensitivity: SkinSensitivity,
  careCosmetics: CareCosmetics,
  careCosmetics2: CareCosmetics2,
  careCosmetics3: CareCosmetics3,
  sunSafety: SunSafety,
  sunSafety2: SunSafety2,
  trustFeedback: TrustFeedback,
  visitToCosmetologist: VisitToCosmetologist,
  visitToCosmetologist2: VisitToCosmetologist2,
  cosmetologistVsLuvly: CosmetologistVsLuvly,
  cosmetologistVsLuvly2: CosmetologistVsLuvly2,
  eatingPatterns: EatingPatterns,
  eatingPatterns2: EatingPatterns2,
  diet: Diet,
  dietVariant2: DietVariant2,
  dietVariant3: DietVariant3,
  dailyYoga: DailyYoga,
  sugar: Sugar,
  sugar2: Sugar2,
  vegetables: Vegetables,
  vegetables2: Vegetables2,
  dailyWater: DailyWater,
  dailyWaterVariant2: DailyWaterVariant2,
  dailyWaterVariant3: DailyWaterVariant3,
  gender: Gender,
  genderVariant2: GenderV2,
  genderVariant3: GenderV3,
  almostThere: AlmostThere,
  welcomeToLuvly: WelcomeToLuvly,
  wonderfulStart: WonderfulStart,
  youAreBeautiful: YouAreBeautiful,
  thankForSharing: ThankForSharing,
  naturalFacialPractice: NaturalFacialPractice,
  socialProof: SocialProof,
  skincareChallenges: SkincareChallenges,
  skinCareAndDiet: SkinCareAndDiet,
  gettingRidDoubleChin: GettingRidDoubleChin,
  timeToInvest: TimeToInvest,
  doubleChinTreatment: DoubleChinTreating,
  doubleChinConcerns: DoubleChinConcerns,
  doubleChinReduction: DoubleChinReduction,
  sleepHabits: SleepHabits,
  practiceDurationV2: PracticeDurationVariant2,
  practiceDurationV3: PracticeDurationV3,
  photoResult: PhotoResult,
  productIngredientFeedback: ProductIngredientFeedback,
  age: Age,
  affectSkinCondition: AffectSkinCondition,
  ageAsIntroWithAnimation: AgeAsIntroWithAnimation,
  doubleChin: DoubleChin,
  doubleChinPeriod: DoubleChinPeriod,
  identifySkinColor: IdentifySkinColor,
  biggestMotivation: BiggestMotivation,
  faceChangesCauses: FaceChangesCauses,
  embracingAge: EmbracingAge,
  ageWithImages: AgeWithImages,
  ageWithImages2: AgeWithImages2,
  frustratingDoubleChin: FrustratingDoubleChin,
  frustratingReasons: FrustratingReasons,
  doubleChinCause: DoubleChinCause,
  doubleChinChanges: DoubleChinChanges,
  stressLevels: StressLevels,
  manageStressLevels: ManageStressLevels,
  ageRelatedChanges: AgeRelatedChanges,
  regularExercise: RegularExercise,
  doubleChinLifestyle: DoubleChinLifestyle,
  creatingSculptingPlan: CreatingSculptingPlan,
  weWantYouLookBest: WeWantYouLookBest,
  weWantYouLookBest2: WeWantYouLookBest2,
  beautySolutionsConcerns: BeautySolutionsConcerns,
  ageAsIntro: AgeAsIntroProvider,
  personalizedPlan: PersonalizedPlan,
  loadingIntro: LoadingIntro,
  loadingIntro2: LoadingIntro2,
  loadingIntro3: LoadingIntro3,
  startChat: IntroLikeChat,
  uselessProducts: UselessProducts,
  easyWithLuvly: EasyWithLuvly,
  goalVariant2: GoalVariant2,
  goalVariant3: GoalVariant3,
  goalVariant4: GoalVariant4,
  goalVariant5: GoalVariant5,
  problematicArea: ProblematicAreas,
  problematicAreaV3: ProblematicAreasV3,
  personalizedProgram: PersonalizedProgram,
  problematicAreaInteractive: ProblematicAreasV2,
  skinType: SkinType,
  skinTypeV3: SkinTypeV3,
  skinTypeVariant2: SkinTypeVariant2,
  skinTypeExplanation: SkinTypeExplanation,
  skinConcerns: SkinConcerns,
  skinConcernsVariant2: SkinConcernsVariant2,
  skinConcernsVariant3: SkinConcernsVariant3,
  skinConcernsVariant4: SkinConcernsVariant4,
  motivation: Motivation,
  shortProgramDescription: ShortProgramDescription,
  shortProgramDescription2: ShortProgramDescription2,
  shortProgramDescriptionAsResult: ShortProgramDescriptionAsResult,
  skinCareRoutineDescription: SkinCareRoutineDescription,
  skinCareRoutineDescriptionV2: SkinCareRoutineDescriptionV2,
  preferredFood: PreferredFood,
  preferredFoodV2: PreferredFoodV2,
  practiceDuration: PracticeDuration,
  faceShape: FaceShape,
  faceShapeV2: FaceShapeV2,
  faceYogaRoutine: FaceYogaRoutine,
  holisticPlan: HolisticPlan,
  faceShapeChange: FaceShapeChange,
  skinDiagnosisIntro: SkinDiagnosisIntro,
  faceScanPreview: FaceScanPreview,
  faceScan: FaceScan,
  faceScanResults: FaceScanResults,
  scannerFeedback: ScannerFeedback,
  signsOfAging: SignsOfAging,
  mlRecommendations: Recommendations,
  mirrorReflection: StatementQuestion,
  mirrorReflectionV2: StatementQuestionVariant3,
  skinRequiresCommitment: StatementQuestion,
  compareMyselfToOthers: StatementQuestion,
  compareMyselfToOthersV2: StatementQuestionVariant3,
  appearanceEffect: StatementQuestion,
  appearanceEffectV2: StatementQuestionVariant3,
  afraidToLookOlder: StatementQuestion,
  afraidToLookOlderV2: StatementQuestionVariant3,
  toBeExpert: StatementQuestion,
  cosmeticsHelp: StatementQuestion,
  cosmeticsHelpV2: StatementQuestionVariant3,
  happinessIsHabit: StatementQuestion,
  noDesiredResult: StatementQuestionVariant2,
  notGreatResults: StatementQuestionVariant2,
  makeMeLookHeavier: StatementQuestionVariant2,
  notReflectFeeling: StatementQuestionVariant2,
  obLoader: ExpertLoader,
  obGoal: LikeChatGoal,
  obAge: LikeChatAge,
  obFaceYoga: LikeChatFaceYoga,
  obSkin: LikeChatSkinQuality,
  obAreas: LikeChatProblematicAreas,
  obExercises: LikeChatExercises,
  obSkinType: LikeChatSkinType,
  obRoutine: LikeChatSkinCareRoutine,
  obProducts: LikeChatCareCosmetics,
  obChallenges1: LikeChatChallenges1,
  obChallenges2: LikeChatChallenges2,
  obSkinConcerns: LikeChatSkinConcerns,
  obMeals: LikeChatEatingPatterns,
  obDiet: LikeChatDiet,
  obSweets: LikeChatSugar,
  obVegetables: LikeChatVegetables,
  obNutritionFeedback: LikeChatNutritionFeedback,
  obMentalHealth: LikeChatMentalHealth,
  obStatement1: ChatStatementQuestion,
  obStatement2: ChatStatementQuestion,
  obStatement3: ChatStatementQuestion,
  obMotivation: LikeChatMotivation,
  obSchedule: LikeChatPracticeDuration,
  obCreating: LikeChatAnalyzingAnswers,
  preliminaryProgress: PreliminaryProgress,
  shortProgramDescriptionSecond: ShortProgramDescription,
  menstrualCycle: MenstrualCycle,
  mentalHealth: MentalHealth,
  mentalHealthV2: MentalHealthV2,
  preparePlan: PreparePlan,
  preparePlanV2: PreparePlanV2,
  youAreInGoodHands: YouAreInGoodHands,
  youAreInGoodHandsV2: YouAreInGoodHandsVariant2,
  youAreInGoodHandsV3: YouAreInGoodHandsVariant3,
  faceYogaExperts: FaceYogaExperts,
  faceYogaExpertsVariant2: FaceYogaExpertsVariant2,
  faceYogaExpertsVariant3: FaceYogaExpertsVariant3,
  reverseYourWrinkles: ReverseYourWrinkles,
  reverseYourWrinkles2: ReverseYourWrinkles2,
  reverseYourWrinkles3: ReverseYourWrinkles3,
  reduceYourDoubleChin: ReduceYourDoubleChin,
  obTags: ObTags,
  tags: Tags,
  analyzingAnswers: AnalyzingAnswers,
  analyzingAnswersVariant2: AnalyzingAnswersVariant2,
  analyzingAnswersVariant3: AnalyzingAnswersVariant3,
  customizeGiftCard: CustomizeGiftCard,
  giftCardPaywall: GiftCardPaywall,
  shareGiftCard: ShareGiftCard,
  obEmail: LikeChatEmail,
  obEmailConsent: LikeChatEmailConsent,
  enterEmail: Email,
  enterEmailV2: EmailVariant2,
  enterEmailV3: EmailVariant3,
  emailConsent: EmailConsent,
  chatBotEmail: ChatBotEmail,
  purchaseEmail: PurchaseVariant2,
  purchaseVariant2: PurchaseVariant2,
  purchaseVariant4: PurchaseVariant4,
  purchaseCosmeticScanner: PurchaseCosmeticScanner,
  purchaseRefund: PurchaseRefund,
  purchaseCancelRefund: PurchaseRefund,
  holidaysPurchaseCancelOffer: HolidaysPurchaseCancelOffer,
  paywallCancelOffer: PurchaseIntroFastProvider,
  purchaseIntroOffer: PurchaseIntroFastProvider,
  purchaseVariant8: PurchaseVariant8,
  purchaseCancelChin: PurchaseVariant8,
  purchaseCancelGeneral: PurchaseVariant8,
  purchaseUpgrade: PurchaseUpgrade,
  purchaseUpgradePDF: PurchaseUpgradePDF,
  upsellPaywall: UpsellPaywallSwitchers,
  coachingPaywall: UpsellCoaching,
  coachingCancelPaywall: UpsellCoaching,
  wellnessBundlePaywall: UpsellWellnessBundle,
  wellnessBundleCancelPaywall: UpsellWellnessBundle,
  finishingTouches: FinishingTouches,
  login: Login,
  wellhubLogin: WellhubLogin,
  download: Download,
  wellhubDownload: WellhubDownload,
}

export const ID_TO_PAGE_MAP_V3: TIdToPageMap = {
  ...ID_TO_PAGE_MAP,
  intro: IntroVariant2,
  goal: Goal,
  purchaseVariant4: PurchaseVariant4,
}

export const DYNAMIC_PAGES_SET_VARIANT_1: IDynamicPage[] = [
  { id: PageId.INTRO },
  { id: PageId.HAVE_YOU_HEARD },
  { id: PageId.WHAT_IS_FACE_YOGA },
  { id: PageId.SATISFY_WITH_SKIN_CONDITION },
  { id: PageId.SKIN_CARE_ROUTINE },
  { id: PageId.CARE_COSMETICS },
  { id: PageId.SUN_SAFETY },
  { id: PageId.VISIT_TO_COSMETOLOGIST },
  { id: PageId.COSMETOLOGIST_VS_LUVLY },
  { id: PageId.EATING_PATTERNS },
  { id: PageId.DIET },
  { id: PageId.SUGAR },
  { id: PageId.VEGETABLES },
  { id: PageId.DAILY_WATER },
  { id: PageId.GENDER },
  { id: PageId.PHOTO_RESULT },
  { id: PageId.AGE },
  { id: PageId.PROBLEMATIC_AREA },
  { id: PageId.SKIN_TYPE },
  { id: PageId.SKIN_TYPE_EXPLANATION },
  { id: PageId.SKIN_CONCERNS },
  { id: PageId.SKIN_DIAGNOSIS_INTRO },
  { id: PageId.FACE_SCAN },
  { id: PageId.ML_RECOMMENDATIONS },
  { id: PageId.MOTIVATION },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION },
  { id: PageId.SKIN_CARE_ROUTINE_DESCRIPTION },
  { id: PageId.PREFERRED_FOOD },
  { id: PageId.PRACTICE_DURATION },
  { id: PageId.FACE_SHAPE },
  { id: PageId.MIRROR_REFLECTION },
  { id: PageId.COMPARE_MYSELF_TO_OTHERS },
  { id: PageId.APPEARANCE_EFFECT },
  { id: PageId.AFRAID_TO_LOOK_OLDER },
  { id: PageId.COSMETICS_HELP },
  { id: PageId.PRELIMINARY_PROGRESS },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION_SECOND },
  { id: PageId.MENSTRUAL_CYCLE },
  { id: PageId.MENTAL_HEALTH },
  { id: PageId.PREPARE_PLAN },
  { id: PageId.YOU_ARE_IN_GOOD_HANDS },
  { id: PageId.FACE_YOGA_EXPERTS },
  { id: PageId.REVERSE_YOUR_WRINKLES },
  { id: PageId.ANALYZING_ANSWERS },
  { id: PageId.ENTER_EMAIL },
]

export const DYNAMIC_PAGES_SET_VARIANT_2: IDynamicPage[] = [
  { id: PageId.INTRO_REFUND },
  { id: PageId.HAVE_YOU_HEARD },
  { id: PageId.WHAT_IS_FACE_YOGA },
  { id: PageId.SATISFY_WITH_SKIN_CONDITION },
  { id: PageId.OB_TAGS },
  { id: PageId.SKIN_CARE_ROUTINE },
  { id: PageId.CARE_COSMETICS },
  { id: PageId.SUN_SAFETY },
  { id: PageId.VISIT_TO_COSMETOLOGIST },
  { id: PageId.COSMETOLOGIST_VS_LUVLY },
  { id: PageId.EATING_PATTERNS },
  { id: PageId.DIET },
  { id: PageId.SUGAR },
  { id: PageId.VEGETABLES },
  { id: PageId.DAILY_WATER },
  { id: PageId.GENDER },
  { id: PageId.PHOTO_RESULT },
  { id: PageId.AGE },
  { id: PageId.PROBLEMATIC_AREA },
  { id: PageId.SKIN_TYPE },
  { id: PageId.SKIN_TYPE_EXPLANATION },
  { id: PageId.SKIN_CONCERNS },
  { id: PageId.SKIN_DIAGNOSIS_INTRO },
  { id: PageId.FACE_SCAN },
  { id: PageId.ML_RECOMMENDATIONS },
  { id: PageId.MOTIVATION },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION },
  { id: PageId.SKIN_CARE_ROUTINE_DESCRIPTION },
  { id: PageId.PREFERRED_FOOD },
  { id: PageId.PRACTICE_DURATION },
  { id: PageId.FACE_SHAPE },
  { id: PageId.MIRROR_REFLECTION },
  { id: PageId.COMPARE_MYSELF_TO_OTHERS },
  { id: PageId.APPEARANCE_EFFECT },
  { id: PageId.AFRAID_TO_LOOK_OLDER },
  { id: PageId.COSMETICS_HELP },
  { id: PageId.PRELIMINARY_PROGRESS },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION_SECOND },
  { id: PageId.MENSTRUAL_CYCLE },
  { id: PageId.MENTAL_HEALTH },
  { id: PageId.PREPARE_PLAN },
  { id: PageId.YOU_ARE_IN_GOOD_HANDS },
  { id: PageId.FACE_YOGA_EXPERTS },
  { id: PageId.REVERSE_YOUR_WRINKLES },
  { id: PageId.ANALYZING_ANSWERS },
  { id: PageId.ENTER_EMAIL },
]

export const DYNAMIC_PAGES_SET_VARIANT_3: IDynamicPage[] = [
  { id: PageId.INTRO_INFLUENCERS },
  { id: PageId.AGE },
  { id: PageId.GOAL_VARIANT_2 },
  { id: PageId.HAVE_YOU_HEARD },
  { id: PageId.WHAT_IS_FACE_YOGA },
  { id: PageId.SATISFY_WITH_SKIN_CONDITION },
  { id: PageId.OB_TAGS },
  { id: PageId.SKIN_CARE_ROUTINE },
  { id: PageId.CARE_COSMETICS },
  { id: PageId.SUN_SAFETY },
  { id: PageId.VISIT_TO_COSMETOLOGIST },
  { id: PageId.COSMETOLOGIST_VS_LUVLY },
  { id: PageId.EATING_PATTERNS },
  { id: PageId.DIET },
  { id: PageId.SUGAR },
  { id: PageId.VEGETABLES },
  { id: PageId.DAILY_WATER },
  { id: PageId.GENDER },
  { id: PageId.PHOTO_RESULT },
  { id: PageId.PROBLEMATIC_AREA },
  { id: PageId.SKIN_TYPE },
  { id: PageId.SKIN_TYPE_EXPLANATION },
  { id: PageId.SKIN_CONCERNS },
  { id: PageId.SKIN_DIAGNOSIS_INTRO },
  { id: PageId.FACE_SCAN },
  { id: PageId.ML_RECOMMENDATIONS },
  { id: PageId.MOTIVATION },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION },
  { id: PageId.SKIN_CARE_ROUTINE_DESCRIPTION },
  { id: PageId.PREFERRED_FOOD },
  { id: PageId.PRACTICE_DURATION },
  { id: PageId.FACE_SHAPE },
  { id: PageId.MIRROR_REFLECTION },
  { id: PageId.COMPARE_MYSELF_TO_OTHERS },
  { id: PageId.APPEARANCE_EFFECT },
  { id: PageId.AFRAID_TO_LOOK_OLDER },
  { id: PageId.COSMETICS_HELP },
  { id: PageId.PRELIMINARY_PROGRESS },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION_SECOND },
  { id: PageId.MENSTRUAL_CYCLE },
  { id: PageId.MENTAL_HEALTH },
  { id: PageId.PREPARE_PLAN },
  { id: PageId.YOU_ARE_IN_GOOD_HANDS },
  { id: PageId.FACE_YOGA_EXPERTS },
  { id: PageId.REVERSE_YOUR_WRINKLES },
  { id: PageId.ANALYZING_ANSWERS },
  { id: PageId.ENTER_EMAIL },
]

export const DYNAMIC_PAGES_SET_VARIANT_4: IDynamicPage[] = [
  { id: PageId.INTRO_GIFT_CARD },
  {
    id: PageId.CUSTOMIZE_GIFT_CARD,
    hasProgressBar: true,
    progressValue: 1,
  },
  {
    id: PageId.GIFT_CARD_PAYWALL,
    hasProgressBar: true,
    progressValue: 2,
  },
  {
    id: PageId.SHARE_GIFT_CARD,
    hasProgressBar: true,
    progressValue: 3,
  },
]

export const DYNAMIC_PAGES_SET_VARIANT_5: IDynamicPage[] = [
  { id: PageId.INTRO },
  { id: PageId.GOAL },
  { id: PageId.HAVE_YOU_HEARD },
  { id: PageId.WHAT_IS_FACE_YOGA },
  { id: PageId.SATISFY_WITH_SKIN_CONDITION },
  { id: PageId.SKIN_CARE_ROUTINE },
  { id: PageId.CARE_COSMETICS },
  { id: PageId.SUN_SAFETY },
  { id: PageId.VISIT_TO_COSMETOLOGIST },
  { id: PageId.COSMETOLOGIST_VS_LUVLY },
  { id: PageId.EATING_PATTERNS },
  { id: PageId.DIET },
  { id: PageId.SUGAR },
  { id: PageId.VEGETABLES },
  { id: PageId.DAILY_WATER },
  { id: PageId.GENDER },
  { id: PageId.PHOTO_RESULT },
  { id: PageId.AGE },
  { id: PageId.PROBLEMATIC_AREA },
  { id: PageId.SKIN_TYPE },
  { id: PageId.SKIN_TYPE_EXPLANATION },
  { id: PageId.SKIN_CONCERNS },
  { id: PageId.SKIN_DIAGNOSIS_INTRO },
  { id: PageId.FACE_SCAN },
  { id: PageId.ML_RECOMMENDATIONS },
  { id: PageId.MOTIVATION },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION },
  { id: PageId.SKIN_CARE_ROUTINE_DESCRIPTION },
  { id: PageId.PREFERRED_FOOD },
  { id: PageId.PRACTICE_DURATION },
  { id: PageId.FACE_SHAPE },
  { id: PageId.MIRROR_REFLECTION },
  { id: PageId.COMPARE_MYSELF_TO_OTHERS },
  { id: PageId.APPEARANCE_EFFECT },
  { id: PageId.AFRAID_TO_LOOK_OLDER },
  { id: PageId.COSMETICS_HELP },
  { id: PageId.PRELIMINARY_PROGRESS },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION_SECOND },
  { id: PageId.MENSTRUAL_CYCLE },
  { id: PageId.MENTAL_HEALTH },
  { id: PageId.PREPARE_PLAN },
  { id: PageId.YOU_ARE_IN_GOOD_HANDS },
  { id: PageId.FACE_YOGA_EXPERTS },
  { id: PageId.REVERSE_YOUR_WRINKLES },
  { id: PageId.ANALYZING_ANSWERS },
  { id: PageId.ENTER_EMAIL },
]

export const DYNAMIC_PAGES_SET_VARIANT_6: IDynamicPage[] = [
  { id: PageId.LOADING_INTRO },
  {
    id: PageId.DOUBLE_CHIN,
    progressValue: 1,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.GENDER_VARIANT_2,
    progressValue: 2,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.AGE_WITH_IMAGES,
    progressValue: 3,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.SKIN_TYPE_VARIANT_2,
    progressValue: 4,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.IDENTIFY_SKIN_COLOR,
    progressValue: 5,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.AGE_RELATED_CHANGES,
    progressValue: 6,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.DOUBLE_CHIN_CAUSE,
    progressValue: 7,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.DIET_VARIANT_2,
    progressValue: 8,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.DAILY_WATER_VARIANT_2,
    progressValue: 9,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.SKIN_CONCERNS_VARIANT_2,
    progressValue: 10,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.SIGNS_OF_AGING,
    progressValue: 11,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.FACE_YOGA_ROUTINE,
    progressValue: 12,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.NO_DESIRED_RESULT,
    progressValue: 13,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.NOT_GREAT_RESULTS,
    progressValue: 14,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.MAKE_ME_LOOK_HEAVIER,
    progressValue: 15,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.NOT_REFLECT_FEELINGS,
    progressValue: 16,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.WE_WANT_YOU_LOOK_BEST,
    progressValue: 17,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.DOUBLE_CHIN_TREATMENT,
    progressValue: 18,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.GETTING_RID_DOUBLE_CHIN,
    progressValue: 19,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.DOUBLE_CHIN_CONCERNS,
    progressValue: 20,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.TIME_TO_INVEST,
    progressValue: 21,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.DOUBLE_CHIN_REDUCTION,
    progressValue: 22,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.MANAGE_STRESS_LEVELS,
    progressValue: 23,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.DOUBLE_CHIN_LIFESTYLE,
    progressValue: 24,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.SLEEP_HABITS,
    progressValue: 25,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.CREATING_SCULPTING_PLAN,
    progressValue: 26,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.PRACTICE_DURATION_VARIANT_2,
    progressValue: 27,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.YOU_ARE_IN_GOOD_HANDS_VARIANT_2,
    progressValue: 28,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.FACE_YOGA_EXPERTS_VARIANT_2,
    progressValue: 29,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.REDUCE_YOUR_DOUBLE_CHIN,
    progressValue: 30,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.ANALYZING_ANSWERS_VARIANT_2,
    progressValue: 31,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.ENTER_EMAIL_VARIANT_2,
    progressValue: 32,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
]

export const DYNAMIC_PAGES_SET_VARIANT_7: IDynamicPage[] = [
  { id: PageId.AGE_AS_INTRO },
  { id: PageId.GOAL_VARIANT_2 },
  { id: PageId.HAVE_YOU_HEARD },
  { id: PageId.WHAT_IS_FACE_YOGA },
  { id: PageId.SATISFY_WITH_SKIN_CONDITION },
  { id: PageId.OB_TAGS },
  { id: PageId.SKIN_CARE_ROUTINE },
  { id: PageId.CARE_COSMETICS },
  { id: PageId.SUN_SAFETY },
  { id: PageId.VISIT_TO_COSMETOLOGIST },
  { id: PageId.COSMETOLOGIST_VS_LUVLY },
  { id: PageId.EATING_PATTERNS },
  { id: PageId.DIET },
  { id: PageId.SUGAR },
  { id: PageId.VEGETABLES },
  { id: PageId.DAILY_WATER },
  { id: PageId.GENDER },
  { id: PageId.PHOTO_RESULT },
  { id: PageId.PROBLEMATIC_AREA },
  { id: PageId.SKIN_TYPE },
  { id: PageId.SKIN_TYPE_EXPLANATION },
  { id: PageId.SKIN_CONCERNS },
  { id: PageId.SKIN_DIAGNOSIS_INTRO },
  { id: PageId.FACE_SCAN },
  { id: PageId.ML_RECOMMENDATIONS },
  { id: PageId.MOTIVATION },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION },
  { id: PageId.SKIN_CARE_ROUTINE_DESCRIPTION },
  { id: PageId.PREFERRED_FOOD },
  { id: PageId.PRACTICE_DURATION },
  { id: PageId.FACE_SHAPE },
  { id: PageId.MIRROR_REFLECTION },
  { id: PageId.COMPARE_MYSELF_TO_OTHERS },
  { id: PageId.APPEARANCE_EFFECT },
  { id: PageId.AFRAID_TO_LOOK_OLDER },
  { id: PageId.COSMETICS_HELP },
  { id: PageId.PRELIMINARY_PROGRESS },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION_SECOND },
  { id: PageId.MENSTRUAL_CYCLE },
  { id: PageId.MENTAL_HEALTH },
  { id: PageId.PREPARE_PLAN },
  { id: PageId.YOU_ARE_IN_GOOD_HANDS },
  { id: PageId.FACE_YOGA_EXPERTS },
  { id: PageId.REVERSE_YOUR_WRINKLES },
  { id: PageId.ANALYZING_ANSWERS },
  { id: PageId.ENTER_EMAIL },
]

export const DYNAMIC_PAGES_SET_VARIANT_8: IDynamicPage[] = [
  { id: PageId.INTRO },
  { id: PageId.LUVLY_USERS },
  { id: PageId.GENDER, hasProgressBar: true, progressValue: 1 },
  { id: PageId.AGE, hasProgressBar: true, progressValue: 2 },
  { id: PageId.SKIN_TYPE, hasProgressBar: true, progressValue: 3 },
  {
    id: PageId.SATISFY_WITH_SKIN_CONDITION,
    hasProgressBar: true,
    progressValue: 4,
  },
  { id: PageId.SKIN_CONCERNS, hasProgressBar: true, progressValue: 5 },
  { id: PageId.LUVLY_GUIDE },
  { id: PageId.SKIN_CARE_ROUTINE, hasProgressBar: true, progressValue: 6 },
  { id: PageId.CARE_COSMETICS, hasProgressBar: true, progressValue: 7 },
  { id: PageId.SUN_SAFETY, hasProgressBar: true, progressValue: 8 },
  { id: PageId.PRODUCT_INGREDIENT_FEEDBACK },
  { id: PageId.USELESS_PRODUCTS, hasProgressBar: true, progressValue: 9 },
  { id: PageId.SCANNER_FEEDBACK },
  { id: PageId.TRUST_FEEDBACK },
  { id: PageId.FACE_SHAPE_CHANGE },
  { id: PageId.PROBLEMATIC_AREA, hasProgressBar: true, progressValue: 10 },
  { id: PageId.MOTIVATION, hasProgressBar: true, progressValue: 11 },
  { id: PageId.HAVE_YOU_HEARD },
  { id: PageId.ALL_ROUND_APPROACH },
  { id: PageId.MENSTRUAL_CYCLE },
  { id: PageId.MENTAL_HEALTH },
  { id: PageId.SKIN_REQUIRES_COMMITMENT },
  { id: PageId.COMPARE_MYSELF_TO_OTHERS },
  { id: PageId.TO_BE_EXPERT },
  { id: PageId.HAPPINESS_IS_HABIT },
  { id: PageId.COSMETICS_HELP },
  { id: PageId.PREPARE_PLAN },
  {
    id: PageId.SHORT_PROGRAM_DESCRIPTION,
    hasProgressBar: true,
    progressValue: 12,
  },
  { id: PageId.YOU_ARE_IN_GOOD_HANDS },
  { id: PageId.FACE_YOGA_EXPERTS },
  { id: PageId.REVERSE_YOUR_WRINKLES },
  { id: PageId.ANALYZING_ANSWERS },
  { id: PageId.ENTER_EMAIL },
]

export const DYNAMIC_PAGES_SET_VARIANT_9: IDynamicPage[] = [
  { id: PageId.LOADING_INTRO_2 },
  {
    id: PageId.GOAL_VARIANT_4,
    progressValue: 1,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.FRUSTRATING_REASONS,
    progressValue: 2,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.PERSONALIZED_PROGRAM,
    progressValue: 3,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.GENDER_VARIANT_2,
    progressValue: 4,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.AGE_WITH_IMAGES,
    progressValue: 5,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.SKIN_TYPE_VARIANT_2,
    progressValue: 6,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.IDENTIFY_SKIN_COLOR,
    progressValue: 7,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.AGE_RELATED_CHANGES,
    progressValue: 8,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.SKIN_SENSITIVITY,
    progressValue: 9,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.SKIN_CONCERNS_VARIANT_2,
    progressValue: 10,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.PROBLEMATIC_AREA_INTERACTIVE,
    progressValue: 11,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.BIGGEST_MOTIVATION,
    progressValue: 12,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.FACE_CHANGES_CAUSES,
    progressValue: 13,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.EMBRACING_AGE,
    progressValue: 14,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.DIET_VARIANT_2,
    progressValue: 15,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.DAILY_WATER_VARIANT_2,
    progressValue: 16,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.WE_WANT_YOU_LOOK_BEST_2,
    progressValue: 17,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.BEAUTY_SOLUTIONS_CONCERNS,
    progressValue: 18,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.DOUBLE_CHIN_REDUCTION,
    progressValue: 19,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.MANAGE_STRESS_LEVELS,
    progressValue: 20,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.SKIN_CARE_AND_DIET,
    progressValue: 21,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.REGULAR_EXERCISE,
    progressValue: 22,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.DAILY_YOGA,
    progressValue: 23,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.PREPARE_PLAN,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.YOU_ARE_IN_GOOD_HANDS_VARIANT_2,
    progressValue: 24,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.REDUCE_YOUR_DOUBLE_CHIN,
    progressValue: 25,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.ANALYZING_ANSWERS_VARIANT_2,
    progressValue: 26,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.ENTER_EMAIL_VARIANT_2,
    progressValue: 27,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
]

const DYNAMIC_PAGES_SET_VARIANT_10: IDynamicPage[] = [
  { id: PageId.AGE_WITH_ANIMATION },
  {
    id: PageId.GOAL_VARIANT_5,
    progressValue: 1,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.HAVE_YOU_HEARD_2,
    progressValue: 2,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.WHAT_IS_FACE_YOGA_2,
    progressValue: 3,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.SATISFY_WITH_SKIN_CONDITION_2,
    progressValue: 4,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.TAGS,
    progressValue: 5,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.SKIN_CARE_ROUTINE_2,
    progressValue: 6,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.CARE_COSMETICS_2,
    progressValue: 7,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.SUN_SAFETY_2,
    progressValue: 8,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.VISIT_TO_COSMETOLOGIST_2,
    progressValue: 9,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.COSMETOLOGIST_VS_LUVLY_2,
    progressValue: 10,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.EATING_PATTERNS_2,
    progressValue: 11,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.DIET_VARIANT_3,
    progressValue: 12,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.SUGAR_2,
    progressValue: 13,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.VEGETABLES_2,
    progressValue: 14,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.DAILY_WATER_VARIANT_3,
    progressValue: 15,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.GENDER_VARIANT_3,
    progressValue: 16,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.EASY_WITH_LUVLY,
    progressValue: 17,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.PROBLEMATIC_AREA_V3,
    progressValue: 18,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.SKIN_TYPE_V3,
    progressValue: 19,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.SKIN_CONCERNS_VARIANT_3,
    progressValue: 20,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.SHORT_PROGRAM_DESCRIPTION_2,
    progressValue: 21,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.SKIN_CARE_ROUTINE_DESCRIPTION_V2,
    progressValue: 22,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.PREFERRED_FOOD_V2,
    progressValue: 23,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.PRACTICE_DURATION_V3,
    progressValue: 24,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.FACE_SHAPE_V2,
    progressValue: 25,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.MIRROR_REFLECTION_V2,
    progressValue: 26,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.COMPARE_MYSELF_TO_OTHERS_V2,
    progressValue: 27,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.APPEARANCE_EFFECT_V2,
    progressValue: 28,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.AFRAID_TO_LOOK_OLDER_V2,
    progressValue: 29,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.COSMETICS_HELP_V2,
    progressValue: 30,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.AFFECT_SKIN_CONDITION,
    progressValue: 31,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.MENTAL_HEALTH_V2,
    progressValue: 32,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.PREPARE_PLAN_V2,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.YOU_ARE_IN_GOOD_HANDS_VARIANT_3,
    progressValue: 33,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.FACE_YOGA_EXPERTS_VARIANT_3,
    progressValue: 34,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.REVERSE_YOUR_WRINKLES_2,
    progressValue: 35,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.ANALYZING_ANSWERS_VARIANT_3,
    progressValue: 36,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.ENTER_EMAIL_VARIANT_3,
    progressValue: 37,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
]

export const DYNAMIC_PAGES_SET_VARIANT_11: IDynamicPage[] = [
  {
    id: PageId.LOADING_INTRO_3,
  },
  {
    id: PageId.GENDER_VARIANT_2,
    progressValue: 1,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.AGE_WITH_IMAGES_2,
    progressValue: 2,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.SKIN_TYPE_VARIANT_2,
    progressValue: 3,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.SKIN_CONCERNS_VARIANT_4,
    progressValue: 4,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.PROBLEMATIC_AREA_INTERACTIVE,
    progressValue: 5,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.CARE_COSMETICS_3,
    progressValue: 6,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.REVERSE_YOUR_WRINKLES_3,
    progressValue: 7,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.ANALYZING_ANSWERS_VARIANT_2,
    progressValue: 8,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.PERSONALIZED_PLAN,
    progressValue: 9,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.WELLHUB_LOGIN,
    progressValue: 10,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
]

export const DYNAMIC_PAGES_SET_VARIANT_13: IDynamicPage[] = [
  { id: PageId.AGE_AS_INTRO },
  {
    id: PageId.GENDER,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 2,
    hasMinimalisticHeader: true,
  },
  { id: PageId.SOCIAL_PROOF, hasMinimalisticHeader: true },
  { id: PageId.WELCOME_TO_LUVLY, hasMinimalisticHeader: true },
  {
    id: PageId.GOAL_VARIANT_2,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 5,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.OB_TAGS,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 6,
    hasMinimalisticHeader: true,
  },
  { id: PageId.WONDERFUL_START, hasMinimalisticHeader: true },
  {
    id: PageId.HAVE_YOU_HEARD,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 8,
    hasMinimalisticHeader: true,
  },
  { id: PageId.NATURAL_FACIAL_PRACTICE, hasMinimalisticHeader: true },
  {
    id: PageId.FACE_SHAPE,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 10,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.PROBLEMATIC_AREA,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 11,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.SATISFY_WITH_SKIN_CONDITION,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 12,
    hasMinimalisticHeader: true,
  },
  { id: PageId.YOU_ARE_BEAUTIFUL, hasMinimalisticHeader: true },
  {
    id: PageId.SKIN_TYPE,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 14,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.SKIN_TYPE_EXPLANATION,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 14,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.SKIN_CONCERNS,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 15,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.SKIN_CARE_ROUTINE,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 16,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.CARE_COSMETICS,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 17,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.SKINCARE_CHALLENGES,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 18,
    hasMinimalisticHeader: true,
  },
  { id: PageId.THANK_FOR_SHARING, hasMinimalisticHeader: true },
  {
    id: PageId.EATING_PATTERNS,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 20,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.DIET,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 21,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.SUGAR,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 22,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.VEGETABLES,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 23,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.PREFERRED_FOOD,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 24,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.DAILY_WATER,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 25,
    hasMinimalisticHeader: true,
  },
  { id: PageId.ALMOST_THERE, hasMinimalisticHeader: true },
  {
    id: PageId.MENTAL_HEALTH,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 27,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.SKIN_REQUIRES_COMMITMENT,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 28,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.COMPARE_MYSELF_TO_OTHERS,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 29,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.TO_BE_EXPERT,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 30,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.HAPPINESS_IS_HABIT,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 31,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.COSMETICS_HELP,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 32,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.MOTIVATION,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 33,
    hasMinimalisticHeader: true,
  },
  {
    id: PageId.PRACTICE_DURATION,
    hasNavigation: true,
    hasProgressBar: true,
    progressValue: 34,
    hasMinimalisticHeader: true,
  },
  { id: PageId.YOU_ARE_IN_GOOD_HANDS },
  { id: PageId.ANALYZING_ANSWERS },
  { id: PageId.ENTER_EMAIL },
]

export const DYNAMIC_PAGES_SET_VARIANT_18: IDynamicPage[] = [
  { id: PageId.LIKE_CHAT_INTRO },
  { id: PageId.OB_LOADER },
  { id: PageId.OB_GOAL, hasProgressBar: true, progressValue: 3 },
  { id: PageId.OB_AGE, hasProgressBar: true, progressValue: 4 },
  { id: PageId.OB_FACE_YOGA, hasProgressBar: true, progressValue: 5 },
  { id: PageId.OB_SKIN, hasProgressBar: true, progressValue: 6 },
  { id: PageId.OB_AREAS, hasProgressBar: true, progressValue: 7 },
  { id: PageId.OB_EXERCISES, hasProgressBar: true, progressValue: 8 },
  { id: PageId.OB_SKIN_TYPE, hasProgressBar: true, progressValue: 9 },
  { id: PageId.OB_SKIN_CONCERNS, hasProgressBar: true, progressValue: 10 },
  { id: PageId.OB_ROUTINE, hasProgressBar: true, progressValue: 11 },
  { id: PageId.OB_PRODUCTS, hasProgressBar: true, progressValue: 12 },
  { id: PageId.OB_CHALLENGES_1, hasProgressBar: true, progressValue: 13 },
  { id: PageId.OB_CHALLENGES_2, hasProgressBar: true, progressValue: 13 },
  { id: PageId.OB_MEALS, hasProgressBar: true, progressValue: 14 },
  { id: PageId.OB_DIET, hasProgressBar: true, progressValue: 15 },
  { id: PageId.OB_SWEETS, hasProgressBar: true, progressValue: 16 },
  { id: PageId.OB_VEGETABLES, hasProgressBar: true, progressValue: 17 },
  { id: PageId.OB_NUTRITION_FEEDBACK, hasProgressBar: true, progressValue: 18 },
  { id: PageId.OB_MENTAL_HEALTH, hasProgressBar: true, progressValue: 19 },
  { id: PageId.OB_STATEMENT_1, hasProgressBar: true, progressValue: 20 },
  { id: PageId.OB_STATEMENT_2, hasProgressBar: true, progressValue: 21 },
  { id: PageId.OB_STATEMENT_3, hasProgressBar: true, progressValue: 22 },
  { id: PageId.OB_MOTIVATION, hasProgressBar: true, progressValue: 23 },
  { id: PageId.OB_SCHEDULE, hasProgressBar: true, progressValue: 24 },
  { id: PageId.OB_CREATING, hasProgressBar: true, progressValue: 25 },
  { id: PageId.OB_EMAIL, hasProgressBar: true, progressValue: 26 },
]

export const DYNAMIC_PAGES_SET_VARIANT_19: IDynamicPage[] = [
  { id: PageId.INTRO_SALE_AGE },
  {
    id: PageId.DOUBLE_CHIN,
    progressValue: 1,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.GENDER_VARIANT_2,
    progressValue: 2,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.SKIN_TYPE_VARIANT_2,
    progressValue: 3,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.IDENTIFY_SKIN_COLOR,
    progressValue: 4,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.AGE_RELATED_CHANGES,
    progressValue: 5,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.DOUBLE_CHIN_CAUSE,
    progressValue: 6,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.DIET_VARIANT_2,
    progressValue: 7,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.DAILY_WATER_VARIANT_2,
    progressValue: 8,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.SKIN_CONCERNS_VARIANT_2,
    progressValue: 9,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.SIGNS_OF_AGING,
    progressValue: 10,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.FACE_YOGA_ROUTINE,
    progressValue: 11,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.NO_DESIRED_RESULT,
    progressValue: 12,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.NOT_GREAT_RESULTS,
    progressValue: 13,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.MAKE_ME_LOOK_HEAVIER,
    progressValue: 14,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.NOT_REFLECT_FEELINGS,
    progressValue: 15,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.WE_WANT_YOU_LOOK_BEST,
    progressValue: 16,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.DOUBLE_CHIN_TREATMENT,
    progressValue: 17,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.GETTING_RID_DOUBLE_CHIN,
    progressValue: 18,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.DOUBLE_CHIN_CONCERNS,
    progressValue: 19,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.TIME_TO_INVEST,
    progressValue: 20,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.DOUBLE_CHIN_REDUCTION,
    progressValue: 21,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.MANAGE_STRESS_LEVELS,
    progressValue: 22,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.DOUBLE_CHIN_LIFESTYLE,
    progressValue: 23,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.SLEEP_HABITS,
    progressValue: 24,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.CREATING_SCULPTING_PLAN,
    progressValue: 25,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.PRACTICE_DURATION_VARIANT_2,
    progressValue: 26,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.YOU_ARE_IN_GOOD_HANDS_VARIANT_2,
    progressValue: 27,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.FACE_YOGA_EXPERTS_VARIANT_2,
    progressValue: 28,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.REDUCE_YOUR_DOUBLE_CHIN,
    progressValue: 29,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.ANALYZING_ANSWERS_VARIANT_2,
    progressValue: 30,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
  {
    id: PageId.ENTER_EMAIL_VARIANT_2,
    progressValue: 31,
    ...PINNED_PROGRESS_BAR_WITH_NAVIGATION,
  },
]

export const DYNAMIC_PAGES_SET_VARIANT_20: IDynamicPage[] = [
  { id: PageId.PERSONAL_KOREAN_PLAN },
  { id: PageId.AGE_AS_INTRO },
  { id: PageId.TRIED_KOREAN_BEAUTY },
  { id: PageId.GOAL_VARIANT_2, hasProgressBar: true, progressValue: 4 },
  { id: PageId.HAVE_SOLUTION },
  {
    id: PageId.SATISFY_WITH_SKIN_CONDITION,
    hasProgressBar: true,
    progressValue: 6,
  },
  { id: PageId.SKIN_CARE_ROUTINE, hasProgressBar: true, progressValue: 7 },
  { id: PageId.CARE_COSMETICS, hasProgressBar: true, progressValue: 8 },
  { id: PageId.SUN_SAFETY, hasProgressBar: true, progressValue: 9 },
  {
    id: PageId.VISIT_TO_COSMETOLOGIST,
    hasProgressBar: true,
    progressValue: 10,
  },
  { id: PageId.REAL_TRANSFORMATION, hasProgressBar: true, progressValue: 11 },
  { id: PageId.EATING_PATTERNS, hasProgressBar: true, progressValue: 12 },
  { id: PageId.DIET, hasProgressBar: true, progressValue: 13 },
  { id: PageId.SUGAR, hasProgressBar: true, progressValue: 14 },
  { id: PageId.VEGETABLES, hasProgressBar: true, progressValue: 15 },
  { id: PageId.STRESS_LEVEL, hasProgressBar: true, progressValue: 16 },
  { id: PageId.SMALL_STEPS_HELP, hasProgressBar: true, progressValue: 17 },
  { id: PageId.DAILY_WATER, hasProgressBar: true, progressValue: 18 },
  { id: PageId.GENDER, hasProgressBar: true, progressValue: 19 },
  { id: PageId.PROBLEMATIC_AREA, hasProgressBar: true, progressValue: 20 },
  { id: PageId.SKIN_TYPE, hasProgressBar: true, progressValue: 21 },
  { id: PageId.SKIN_TYPE_EXPLANATION, hasProgressBar: true, progressValue: 21 },
  { id: PageId.SKIN_CONCERNS, hasProgressBar: true, progressValue: 22 },
  { id: PageId.GOING_TO_LOVE_IT, hasProgressBar: true, progressValue: 23 },
  { id: PageId.MOTIVATION, hasProgressBar: true, progressValue: 24 },
  {
    id: PageId.SHORT_PROGRAM_DESCRIPTION,
    hasProgressBar: true,
    progressValue: 25,
  },
  {
    id: PageId.SKIN_CARE_ROUTINE_DESCRIPTION,
    hasProgressBar: true,
    progressValue: 26,
  },
  { id: PageId.PREFERRED_FOOD, hasProgressBar: true, progressValue: 27 },
  { id: PageId.PRACTICE_DURATION, hasProgressBar: true, progressValue: 28 },
  { id: PageId.FACE_SHAPE, hasProgressBar: true, progressValue: 29 },
  { id: PageId.MIRROR_REFLECTION },
  { id: PageId.COMPARE_MYSELF_TO_OTHERS },
  { id: PageId.APPEARANCE_EFFECT },
  { id: PageId.AFRAID_TO_LOOK_OLDER },
  { id: PageId.COSMETICS_HELP },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION_SECOND },
  { id: PageId.MENSTRUAL_CYCLE },
  { id: PageId.MENTAL_HEALTH },
  { id: PageId.PREPARE_PLAN },
  { id: PageId.YOU_ARE_IN_GOOD_HANDS },
  { id: PageId.FACE_YOGA_EXPERTS },
  { id: PageId.REVERSE_YOUR_WRINKLES },
  { id: PageId.ANALYZING_ANSWERS },
  { id: PageId.ENTER_EMAIL },
]

export const DYNAMIC_PAGES_MAP = {
  [Cohort.QUIZ_2]: [DYNAMIC_PAGES_SET_VARIANT_1],
  [Cohort.LUVLY_INTRO_FAST]: [
    DYNAMIC_PAGES_SET_VARIANT_7,
    DYNAMIC_PAGES_SET_VARIANT_10,
  ],
  [Cohort.LUVLY_EMAIL_INTRO_FAST]: [DYNAMIC_PAGES_SET_VARIANT_7],
  [Cohort.LUVLY_CANCEL_INTRO_FAST]: [DYNAMIC_PAGES_SET_VARIANT_7],
  [Cohort.LUVLY_MARISSA]: [DYNAMIC_PAGES_SET_VARIANT_5],
  [Cohort.LUVLY_COSMETIC_SCANNER]: [DYNAMIC_PAGES_SET_VARIANT_8],
  [Cohort.LUVLY_CHAT]: [DYNAMIC_PAGES_SET_VARIANT_18],
  [Cohort.LUVLY_INTRO_SALE]: [DYNAMIC_PAGES_SET_VARIANT_7],
  [Cohort.LUVLY_INTRO3_SALE]: [DYNAMIC_PAGES_SET_VARIANT_7],
  [Cohort.LUVLY_INTRO5_SALE]: [DYNAMIC_PAGES_SET_VARIANT_7],
  [Cohort.LUVLY_INTRO6_SALE]: [DYNAMIC_PAGES_SET_VARIANT_7],
  [Cohort.LUVLY_12_SALE]: [DYNAMIC_PAGES_SET_VARIANT_13],
  [Cohort.LUVLY_REF]: [DYNAMIC_PAGES_SET_VARIANT_2],
  [Cohort.LUVLY_REF4]: [DYNAMIC_PAGES_SET_VARIANT_2],
  [Cohort.LUVLY_REF5]: [DYNAMIC_PAGES_SET_VARIANT_2],
  [Cohort.LUVLY_PRINTABLE]: [DYNAMIC_PAGES_SET_VARIANT_2],
  [Cohort.LUVLY_CANCEL_INTRO_1]: [DYNAMIC_PAGES_SET_VARIANT_7],
  [Cohort.LUVLY_CHIN]: [
    DYNAMIC_PAGES_SET_VARIANT_19,
    DYNAMIC_PAGES_SET_VARIANT_6,
  ],
  [Cohort.LUVLY_INFLUENCERS]: [DYNAMIC_PAGES_SET_VARIANT_3],
  [Cohort.LUVLY_GIFT]: [DYNAMIC_PAGES_SET_VARIANT_4],
  [Cohort.LUVLY_KOREAN]: [DYNAMIC_PAGES_SET_VARIANT_20],
  [Cohort.LUVLY_GENERAL]: [DYNAMIC_PAGES_SET_VARIANT_9],
  [Cohort.LUVLY_WELLHUB]: [DYNAMIC_PAGES_SET_VARIANT_11],
}

export const EMAIL_CONSENT_PAGES_MAP: Record<string, PageId> = {
  [Cohort.QUIZ_2]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_EMAIL_7]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_INTRO_FAST]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_EMAIL_INTRO_FAST]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_CANCEL_INTRO_FAST]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_EMAIL_8]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_EMAIL_COACHING]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_EMAIL_SALE]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_MARISSA]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_COSMETIC_SCANNER]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_CHAT]: PageId.OB_EMAIL_CONSENT,
  [Cohort.LUVLY_REF]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_REF4]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_CANCEL_INTRO_1]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_CHIN]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_INFLUENCERS]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_KOREAN]: PageId.EMAIL_CONSENT,
  [Cohort.LUVLY_GENERAL]: PageId.EMAIL_CONSENT,
}

export const EMAIL_CONSENT_PAGES: { id: PageId }[] = [
  { id: PageId.EMAIL_CONSENT },
  { id: PageId.OB_EMAIL_CONSENT },
]

export const PURCHASE_PAGES: { id: PageId; isProtected: boolean }[] = [
  { id: PageId.PURCHASE_EMAIL, isProtected: true },
  { id: PageId.PURCHASE_VARIANT_2, isProtected: true },
  { id: PageId.PURCHASE_INTRO_OFFER, isProtected: true },
  { id: PageId.HOLIDAYS_PURCHASE_CANCEL_OFFER, isProtected: true },
  { id: PageId.PURCHASE_INTRO_CANCEL_OFFER, isProtected: true },
  { id: PageId.PURCHASE_VARIANT_4, isProtected: true },
  { id: PageId.PURCHASE_COSMETIC_SCANNER, isProtected: true },
  { id: PageId.SHORT_PROGRAM_DESCRIPTION_AS_RESULT, isProtected: true },
  { id: PageId.PURCHASE_REFUND, isProtected: true },
  { id: PageId.PURCHASE_CANCEL_REFUND, isProtected: true },
  { id: PageId.PURCHASE_VARIANT_8, isProtected: true },
  { id: PageId.PURCHASE_CANCEL_CHIN, isProtected: true },
  { id: PageId.PURCHASE_CANCEL_GENERAL, isProtected: true },
]

export const PURCHASE_PAGES_MAP: Record<Cohort, PageId> = {
  [Cohort.QUIZ_2]: PageId.PURCHASE_VARIANT_2,
  [Cohort.LUVLY_EMAIL_7]: PageId.PURCHASE_EMAIL,
  [Cohort.LUVLY_INTRO_FAST]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_CHAT_UPGRADE_12_PDF]: PageId.PURCHASE_VARIANT_2,
  [Cohort.LUVLY_CHAT_UPGRADE_12_EX_PDF]: PageId.PURCHASE_VARIANT_2,
  [Cohort.LUVLY_CHAT_UPGRADE_BUNDLE_PDF]: PageId.PURCHASE_VARIANT_2,
  [Cohort.LUVLY_CHAT_UPGRADE_WELLNESS_BUNDLE]: PageId.PURCHASE_VARIANT_2,
  [Cohort.LUVLY_EMAIL_8]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_EMAIL_SALE]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_EMAIL_COACHING]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_EMAIL_INTRO_FAST]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_CANCEL_INTRO_FAST]: PageId.PURCHASE_INTRO_CANCEL_OFFER,
  [Cohort.LUVLY_MARISSA]: PageId.PURCHASE_VARIANT_4,
  [Cohort.LUVLY_COSMETIC_SCANNER]: PageId.PURCHASE_COSMETIC_SCANNER,
  [Cohort.LUVLY_CHAT]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_INTRO_SALE]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_INTRO3_SALE]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_INTRO5_SALE]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_INTRO6_SALE]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_12_SALE]: PageId.SHORT_PROGRAM_DESCRIPTION_AS_RESULT,
  [Cohort.LUVLY_REF]: PageId.PURCHASE_REFUND,
  [Cohort.LUVLY_REF4]: PageId.PURCHASE_REFUND,
  [Cohort.LUVLY_REF5]: PageId.PURCHASE_REFUND,
  [Cohort.LUVLY_PRINTABLE]: PageId.PURCHASE_REFUND,
  [Cohort.LUVLY_CANCEL_INTRO_1]: PageId.PURCHASE_INTRO_CANCEL_OFFER,
  [Cohort.LUVLY_CHIN]: PageId.PURCHASE_VARIANT_8,
  [Cohort.LUVLY_INFLUENCERS]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_GIFT]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_KOREAN]: PageId.PURCHASE_INTRO_OFFER,
  [Cohort.LUVLY_GENERAL]: PageId.PURCHASE_VARIANT_8,
  [Cohort.LUVLY_WELLHUB]: PageId.PURCHASE_INTRO_OFFER,
}

export const UPSELL_PAGES_MAP: Record<Cohort, PageId[]> = {
  [Cohort.QUIZ_2]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_EMAIL_7]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_INTRO_FAST]: [
    PageId.COACHING_PAYWALL,
    PageId.COACHING_CANCEL_PAYWALL,
    PageId.UPSELL_PAYWALL,
  ],
  [Cohort.LUVLY_EMAIL_8]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_EMAIL_SALE]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_EMAIL_INTRO_FAST]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_CANCEL_INTRO_FAST]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_EMAIL_COACHING]: [
    PageId.COACHING_PAYWALL,
    PageId.COACHING_CANCEL_PAYWALL,
  ],
  [Cohort.LUVLY_MARISSA]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_CHAT]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_REF]: [
    PageId.COACHING_PAYWALL,
    PageId.COACHING_CANCEL_PAYWALL,
    PageId.UPSELL_PAYWALL,
  ],
  [Cohort.LUVLY_REF4]: [
    PageId.COACHING_PAYWALL,
    PageId.COACHING_CANCEL_PAYWALL,
    PageId.UPSELL_PAYWALL,
  ],
  [Cohort.LUVLY_REF5]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_PRINTABLE]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_12_SALE]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_INTRO_SALE]: [
    PageId.COACHING_PAYWALL,
    PageId.COACHING_CANCEL_PAYWALL,
    PageId.UPSELL_PAYWALL,
  ],
  [Cohort.LUVLY_INTRO3_SALE]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_INTRO5_SALE]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_INTRO6_SALE]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_CHAT_UPGRADE_12_PDF]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_CHAT_UPGRADE_12_EX_PDF]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_CHAT_UPGRADE_BUNDLE_PDF]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_COSMETIC_SCANNER]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_CHAT_UPGRADE_WELLNESS_BUNDLE]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_CANCEL_INTRO_1]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_CHIN]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_INFLUENCERS]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_GIFT]: [],
  [Cohort.LUVLY_KOREAN]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_GENERAL]: [PageId.UPSELL_PAYWALL],
  [Cohort.LUVLY_WELLHUB]: [],
}
