import { Cohort } from 'root-constants'

export const QUESTION = 'How would you describe your skin?'

export const enum OPTION_VALUES {
  NORMAL = 'normal',
  COMBINATION = 'combination',
  DRY = 'dry',
  OILY = 'oily',
  DONT_KNOW = 'dont_know',
}

export const SKIN_TYPE_DEFAULT_OPTIONS = [
  {
    value: OPTION_VALUES.NORMAL,
    text: 'onboarding.skinType.normal',
  },
  {
    value: OPTION_VALUES.DRY,
    text: 'onboarding.skinType.dry',
  },
  {
    value: OPTION_VALUES.OILY,
    text: 'onboarding.skinType.oily',
  },
  {
    value: OPTION_VALUES.COMBINATION,
    text: 'onboarding.skinType.combination',
  },
]

export const SKIN_TYPE_COSMETIC_SCANNER_OPTIONS = [
  {
    value: OPTION_VALUES.NORMAL,
    text: 'onboarding.skinType.normal_cosmetic',
  },
  {
    value: OPTION_VALUES.DRY,
    text: 'onboarding.skinType.dry_cosmetic',
  },
  {
    value: OPTION_VALUES.OILY,
    text: 'onboarding.skinType.oily_cosmetic',
  },
  {
    value: OPTION_VALUES.COMBINATION,
    text: 'onboarding.skinType.combination_cosmetic',
  },
]

export const SKIN_TYPE_OPTIONS_MAP = {
  [Cohort.LUVLY_COSMETIC_SCANNER]: SKIN_TYPE_COSMETIC_SCANNER_OPTIONS,
}

export const SKIN_TYPE_ANSWERS_MAP = {
  [OPTION_VALUES.NORMAL]: 'onboarding.skinType.normal',
  [OPTION_VALUES.COMBINATION]: 'onboarding.skinType.combination',
  [OPTION_VALUES.DRY]: 'onboarding.skinType.dry',
  [OPTION_VALUES.OILY]: 'onboarding.skinType.oily',
}

export const SKIN_TYPE_VARIANT_2_ANSWERS = [
  {
    value: OPTION_VALUES.NORMAL,
    label: 'onboarding.skinType.normal',
    detailed: 'onboarding.skinType.normalExplanation',
  },
  {
    value: OPTION_VALUES.DRY,
    label: 'onboarding.skinType.dry',
    detailed: 'onboarding.skinType.dryExplanation',
  },
  {
    value: OPTION_VALUES.OILY,
    label: 'onboarding.skinType.oily',
    detailed: 'onboarding.skinType.oilyExplanation',
  },
  {
    value: OPTION_VALUES.COMBINATION,
    label: 'onboarding.skinType.combination',
    detailed: 'onboarding.skinType.combinationExplanation',
  },
]
