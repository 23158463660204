import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { setUtmTags, setWellhubPromoCode } from 'root-redux/actions/common'
import { sendUserConfigAction } from 'root-redux/actions/user'

export const useUtmTags = () => {
  const { search } = useLocation()
  const dispatch = useDispatch()
  const URLParams = new URLSearchParams(search)
  const utmTagsFromUrl = Object.fromEntries(URLParams.entries())

  useEffect(() => {
    if (utmTagsFromUrl.utm_source) {
      dispatch(setUtmTags(utmTagsFromUrl))
      dispatch(
        sendUserConfigAction({
          utm_tags: utmTagsFromUrl,
        }),
      )
    }

    if (utmTagsFromUrl.wellhub_code) {
      dispatch(setWellhubPromoCode(utmTagsFromUrl.wellhub_code))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch])
}
