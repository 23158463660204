import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'
import { Autoplay, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { IntroHeader } from 'components/IntroHeader'
import { IntroHeaderColor } from 'components/IntroHeader/constants'
import { Option } from 'components/Option'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { sendFacebookParamsAction } from 'root-redux/actions/user'
import { selectLanguage } from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'
import { useScrollToTarget } from 'hooks/useScrollToTarget'
import { useStartSession } from 'hooks/useStartSession'
import { useTitleFromUrl } from 'hooks/useTitleFromUrl'
import { useTrackPageScrollDepth } from 'hooks/useTrackPageScrollDepth'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import scrollDownAnimation from 'assets/animation/scrollDownAnimation.json'
import brands from 'assets/images/brands.png'

import { PageId } from 'page-constants'
import { CDN_FOLDER_LINK, Locale, OptionType, ScreenName } from 'root-constants'

import { StyledIntroVariant3 as S } from './IntroVariant3.styles'
import {
  AGE_OPTION_VALUES_MAP,
  ANSWERS,
  ANSWERS_ES,
  CREATORS,
  NUMBER_ONE_APP_IMAGE_PATH,
  QUESTION,
  ROADMAP,
  TRACKED_BREAKPOINTS,
  USERS_GALLERY,
} from './constants'

export const IntroVariant3: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  const language = useSelector(selectLanguage)
  useStartSession()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const { headline, subtext } = useTitleFromUrl()

  const { scrollToTarget } = useScrollToTarget()

  const heroBlock = useRef<HTMLDivElement | null>(null)

  const animationContainerRef = useRef<HTMLDivElement>(null)

  const handleContinue = useNextStep({
    pageId: PageId.AGE,
    question: QUESTION,
    nextPagePath,
  })

  const scrollEvent = (scrolledPart: number) => {
    eventLogger.logFirstPageScrolled({
      screenName: ScreenName.LONG_START_AGE,
      scrolledPart,
    })
  }

  const handleAnswerChange = useCallback(
    (value: string) => {
      eventLogger.logUserAgeSelected(AGE_OPTION_VALUES_MAP[value])
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
    [handleChange, handleContinue, setIsAnswersDisabled],
  )

  const optionProps = useMemo(
    () => ({
      name: PageId.AGE,
      type: OptionType.RADIO,
      disabled: isAnswersDisabled,
      onChange: (value: string) => {
        dispatch(sendFacebookParamsAction())

        window.fbq('track', 'CustomizeProduct', {}, { eventID: uuid })
        googleAnalyticsLogger.logFirstPageCompleted()
        eventLogger.logFirstPageCompleted({
          question: QUESTION,
          answers: value,
        })

        handleAnswerChange(value)
      },
    }),
    [handleAnswerChange, isAnswersDisabled, dispatch, uuid],
  )

  useTrackPageScrollDepth({
    trackedBreakpoints: TRACKED_BREAKPOINTS,
    eventCallback: scrollEvent,
  })

  useEffect(() => {
    if (animationContainerRef.current) {
      lottie.loadAnimation({
        container: animationContainerRef.current,
        animationData: scrollDownAnimation,
        loop: true,
        autoplay: true,
      })
    }

    return () => lottie.destroy()
  }, [])

  const options = language === Locale.SPANISH ? ANSWERS_ES : ANSWERS

  return (
    <S.Root>
      <IntroHeader colorTheme={IntroHeaderColor.DARK} />
      <S.Hero ref={heroBlock}>
        <Container fields={16}>
          <S.Title>{headline || t`onboarding.ageAsIntro.title`}</S.Title>
          <S.Subtitle>
            {subtext || <Trans i18nKey="onboarding.ageAsIntro.subtitle" />}
          </S.Subtitle>
          <S.CallToAction>{t`onboarding.ageAsIntro.CTA`}</S.CallToAction>
          <S.Actions>
            {options.map(({ value, range, img }) => {
              return (
                <Option {...optionProps} value={value} key={range}>
                  <S.Action>
                    <S.Image src={img} alt="" />
                    <S.Answer />
                    <S.Range>
                      <S.RangeValue>{range}</S.RangeValue>
                      <S.ChevronRight />
                    </S.Range>
                  </S.Action>
                </Option>
              )
            })}
          </S.Actions>
        </Container>
        <S.Terms>
          <p>{t`onboarding.intro.termsTitle`}</p>
          <TermsOfUseLink />
          <S.Separator />
          <PrivacyPolicyLink />
        </S.Terms>
      </S.Hero>
      <S.ScrollDown ref={animationContainerRef} />

      <Container fields={16}>
        <S.Roadmap>
          {ROADMAP.map(({ title, subtitle, img }) => {
            return (
              <S.RoadmapItem key={title}>
                <S.RoadmapTitle>
                  <Trans i18nKey={title} components={[<br />]} />
                </S.RoadmapTitle>
                <S.RoadmapSubtitle>{t(subtitle)}</S.RoadmapSubtitle>
                <S.RoadmapImage
                  loading="lazy"
                  src={`${CDN_FOLDER_LINK}${img}_${language}.png`}
                  alt={title}
                />
              </S.RoadmapItem>
            )
          })}
        </S.Roadmap>
        <S.NumberOneApp>
          <img
            src={`${CDN_FOLDER_LINK}${NUMBER_ONE_APP_IMAGE_PATH}_${language}.png`}
            alt=""
            loading="lazy"
          />
        </S.NumberOneApp>
        <S.ButtonContainer>
          <Button onClick={() => scrollToTarget(heroBlock)}>
            {t('actions.getStarted')}
          </Button>
        </S.ButtonContainer>
        <S.Brands>
          <img src={brands} alt="" loading="lazy" />
        </S.Brands>
      </Container>

      <S.Creators>
        <Container fields={16}>
          <S.CreatorsTitle>
            <Trans
              i18nKey="onboarding.introVariantLong.creators.title"
              components={[<br />]}
            />
          </S.CreatorsTitle>
          <S.CreatorsSubtitle>
            <Trans
              i18nKey="onboarding.introVariantLong.creators.subtitle"
              components={[<br />]}
            />
          </S.CreatorsSubtitle>
          <S.CreatorsList>
            {CREATORS.map(({ name, text, img }) => {
              return (
                <S.CreatorsItem key={name}>
                  <S.CreatorsImageContainer>
                    <S.CreatorsImage src={img} alt="" loading="lazy" />
                  </S.CreatorsImageContainer>
                  <S.CreatorsReview>
                    <S.CreatorsName>{t(name)}</S.CreatorsName>
                    <S.CreatorsText>{t(text)}</S.CreatorsText>
                  </S.CreatorsReview>
                </S.CreatorsItem>
              )
            })}
          </S.CreatorsList>
        </Container>
      </S.Creators>

      <S.Reviews>
        <Container fields={16}>
          <S.ReviewsTitle>{t`onboarding.introVariantLong.luvlyUsers`}</S.ReviewsTitle>
        </Container>
        <Carousel
          slidesPerView={1}
          loop
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
        >
          {USERS_GALLERY.map(({ name, img, text }) => (
            <SwiperSlide key={name}>
              <S.Review>
                <S.ReviewContainer>
                  <S.ReviewerImage src={img} alt="user-image-before-after" />
                  <S.ReviewerName>{t(name)}</S.ReviewerName>
                  <S.ReviewText>{t(text)}</S.ReviewText>
                </S.ReviewContainer>
              </S.Review>
            </SwiperSlide>
          ))}
        </Carousel>
      </S.Reviews>
      <Container fields={16}>
        <Button onClick={() => scrollToTarget(heroBlock)} marginBottom={32}>
          {t('actions.getStarted')}
        </Button>
        <S.Terms>
          <p>{t`onboarding.intro.termsTitle`}</p>
          <TermsOfUseLink />
          <S.Separator />
          <PrivacyPolicyLink />
        </S.Terms>
        <S.Footnote>{t('onboarding.introVariantLong.footnote')}</S.Footnote>
      </Container>
    </S.Root>
  )
}
