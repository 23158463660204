import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledGoal = {
  Root: styled.div`
    font-family: 'Open Sans';
  `,
  TitleContainer: styled.div`
    margin: 15px 0 16px;
  `,
  Title: styled.h1`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    text-align: left;
    color: ${Color.BLUE_DARK};
    margin: 24px 0;

    & strong {
      color: ${Color.PRIMARY};
    }
  `,
}
