import React, { useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { FeaturesReady } from '@growthbook/growthbook-react'
import { Button, buttonTheme } from 'storybook-ui'

import { Container } from 'components/Container'
import { Spinner } from 'components/Spinner'
import { Ticker } from 'components/Ticker'

import { startFetching } from 'root-redux/actions/common'
import { selectUserCountryCode } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useABTest } from 'hooks/useABTest'
import { useAxonPurchaseEvent } from 'hooks/useAxonPurchaseEvent'
import { useTrackPageScrollDepth } from 'hooks/useTrackPageScrollDepth'

import { getCountryNameFromCode } from 'helpers/getCountryName'

import { LongPaywallStaticBanner } from 'modules/purchase/components/LongPaywallStaticBanner'
import { PurchaseDisclaimer } from 'modules/purchase/components/PurchaseDisclaimer'
import { PurchaseRefundBanner } from 'modules/purchase/components/PurchaseRefundBanner'
import { PurchaseRefundInstructions } from 'modules/purchase/components/PurchaseRefundInstructions'
import { PurchaseTimer } from 'modules/purchase/components/PurchaseTimer'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'
import { SubscriptionsBlockV2 } from 'modules/purchase/components/SubscriptionsBlockV2'
import { TEN_MINUTES } from 'modules/purchase/constants'
import {
  useDefaultSubscription,
  usePricesStatus,
  usePurchaseAnalytics,
  useScrollEvent,
  useTimerForTarget,
} from 'modules/purchase/hooks'
import { useGetSubscriptionsList } from 'modules/purchase/hooks/useGetSubscriptionsList'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'
import { selectDynamicDiscount } from 'modules/purchase/redux/selects/common'

import awards from 'assets/images/award-logos.png'
import checkHexagon from 'assets/images/check-hexagon-solid.png'
import map from 'assets/images/luvly-users-map.png'

import { DynamicDiscountType, ScreenName } from 'root-constants'

import { CheckoutList } from '../CheckoutList'
import { LONG_PAYWALL_TRACKED_BREAKPOINTS } from '../PurchaseVariant7/constants'
import { StyledReTargetingPurchase as S } from './ReTargetingPurchase.styles'
import { Habits } from './components/Habits'
import { MoneyBackGuarantee } from './components/MoneyBackGuarantee'
import { ProgramDescription } from './components/ProgramDescription'
import { Reviews } from './components/Reviews'
import { UsersGallery } from './components/UsersGallery'

export const ReTargetingPurchase: React.FC = () => {
  const dispatch: TAppDispatch = useDispatch()
  const { t } = useTranslation()
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const userCountryCode = useSelector(selectUserCountryCode)
  const [isCheckoutShown, setIsCheckoutShown] = useState<boolean>(false)

  const { scrollEvent } = useScrollEvent(ScreenName.PURCHASE)
  const { isReTargetingPageMapVariantBActive } = useABTest()

  const isDynamicDiscountType =
    dynamicDiscount?.type !== DynamicDiscountType.STATIC

  const timerRefTop = useRef<HTMLDivElement | null>(null)
  const timerRefBottom = useRef<HTMLDivElement | null>(null)

  const { arePricesReady } = usePricesStatus()

  useTimerForTarget(timerRefTop, TEN_MINUTES.IN_SECONDS, [timerRefBottom])

  const { sendGeneralPurchaseEvents } = usePurchaseAnalytics(
    ScreenName.PURCHASE,
  )

  useDefaultSubscription()

  useAxonPurchaseEvent()

  useTrackPageScrollDepth({
    trackedBreakpoints: LONG_PAYWALL_TRACKED_BREAKPOINTS,
    eventCallback: scrollEvent,
    preventSendingEvents: isCheckoutShown,
  })

  useGetSubscriptionsList()

  const handleShowCheckout = () => {
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))

    setIsCheckoutShown(true)

    sendGeneralPurchaseEvents(false)
  }

  const renderBanner = (timerRef: React.RefObject<HTMLElement>) => {
    if (isDynamicDiscountType) {
      return <PurchaseTimer timerRef={timerRef} applyNewYearTheme={false} />
    }

    return <LongPaywallStaticBanner />
  }

  return arePricesReady ? (
    <>
      {!isCheckoutShown && (
        <S.Root>
          <S.TickerContainer>
            <Ticker />
          </S.TickerContainer>

          <S.Hero>
            <S.Title>{t('reTargetingPurchase.title')}</S.Title>
            <S.Subtitle>
              {t('reTargetingPurchase.subtitle')}{' '}
              {getCountryNameFromCode(userCountryCode)}
            </S.Subtitle>
          </S.Hero>
          <S.Plans>
            <Container>
              <S.SecondaryTitle>
                {t('reTargetingPurchase.plansTitle')}
              </S.SecondaryTitle>
              {isReTargetingPageMapVariantBActive && (
                <PurchaseRefundBanner isTopPosition />
              )}
              {!isReTargetingPageMapVariantBActive && (
                <S.TimerTop>{renderBanner(timerRefTop)}</S.TimerTop>
              )}
              <SubscriptionsBlockV2 />
              <S.MoneyBackGuarantee>
                <img src={checkHexagon} alt="" />
                <div>
                  <S.MoneyBackGuaranteeTitle>
                    {t('reTargetingPurchase.moneyBackGuaranteeTitle')}
                  </S.MoneyBackGuaranteeTitle>
                  <S.MoneyBackGuaranteeText>
                    {t('reTargetingPurchase.moneyBackGuaranteeText')}
                  </S.MoneyBackGuaranteeText>
                </div>
              </S.MoneyBackGuarantee>
              <Button
                theme={buttonTheme.LUVLY_SECONDARY}
                maxWidth="100%"
                onClick={handleShowCheckout}
              >
                {t('actions.claim')}
              </Button>
              {isReTargetingPageMapVariantBActive && (
                <S.TimerBottom>{renderBanner(timerRefTop)}</S.TimerBottom>
              )}
            </Container>

            {isReTargetingPageMapVariantBActive && (
              <PurchaseRefundInstructions
                handleOpenCheckout={handleShowCheckout}
              />
            )}
          </S.Plans>
          <ProgramDescription />
          <S.Map>
            <Container>
              <S.SecondaryTitle>
                <Trans
                  i18nKey="reTargetingPurchase.mapTitle"
                  components={[<br />]}
                />
              </S.SecondaryTitle>
              <img src={map} alt="" />
            </Container>
          </S.Map>
          <Habits />
          <Container>
            <S.SecondaryTitle>
              {t('reTargetingPurchase.users.title')}
            </S.SecondaryTitle>
          </Container>
          <UsersGallery />
          <S.Plans>
            <Container>
              <S.SecondaryTitle>
                {t('reTargetingPurchase.plansTitle')}
              </S.SecondaryTitle>
              <S.TimerTop>{renderBanner(timerRefBottom)}</S.TimerTop>
              <SubscriptionsBlockV2 />
              <S.MoneyBackGuarantee>
                <img src={checkHexagon} alt="" />
                <div>
                  <S.MoneyBackGuaranteeTitle>
                    {t('reTargetingPurchase.moneyBackGuaranteeTitle')}
                  </S.MoneyBackGuaranteeTitle>
                  <S.MoneyBackGuaranteeText>
                    {t('reTargetingPurchase.moneyBackGuaranteeText')}
                  </S.MoneyBackGuaranteeText>
                </div>
              </S.MoneyBackGuarantee>
              <Button
                theme={buttonTheme.LUVLY_SECONDARY}
                maxWidth="100%"
                onClick={handleShowCheckout}
                margin={`${isReTargetingPageMapVariantBActive && '0 0 40px 0'}`}
              >
                {t('actions.claim')}
              </Button>
              {isReTargetingPageMapVariantBActive && <PurchaseRefundBanner />}
            </Container>
          </S.Plans>
          <S.Awards>
            <Container>
              <S.SecondaryTitle>
                {t('reTargetingPurchase.awardTitle')}
              </S.SecondaryTitle>
              <img src={awards} alt="" />
            </Container>
          </S.Awards>
          <Container>
            <S.SecondaryTitle>
              {t('reTargetingPurchase.reviewsTitle')}
            </S.SecondaryTitle>
            <Reviews />
            <MoneyBackGuarantee />
            <SecurityInfo />
            <PurchaseDisclaimer
              style={{ fontSize: '12px', lineHeight: '16px' }}
            />
          </Container>
        </S.Root>
      )}
      {isCheckoutShown && (
        <FeaturesReady fallback={<Spinner />}>
          <CheckoutList
            setIsCheckoutShown={setIsCheckoutShown}
            isCancelOffer={false}
          />
        </FeaturesReady>
      )}
    </>
  ) : null
}
