import { useEffect } from 'react'

export const useTrackPageScrollDepth = ({
  trackedBreakpoints = [16, 33, 50, 66, 83, 100],
  eventCallback,
  preventSendingEvents = false,
}: {
  trackedBreakpoints: number[]
  eventCallback: (scrolledPart: number) => void
  preventSendingEvents?: boolean
}) => {
  useEffect(() => {
    const isBetweenRange = (
      currentPosition: number,
      min: number,
      max: number,
    ) => {
      return currentPosition >= min && currentPosition <= max
    }

    const scrolledTargets: number[] = []

    const handleScroll = () => {
      const currentEndScrollPosition =
        document.body.scrollTop + window.innerHeight
      const pageHeight = document.body.scrollHeight - 1
      const scrolledPercentage = (currentEndScrollPosition / pageHeight) * 100

      const isInTrackedRange =
        currentEndScrollPosition >= pageHeight ||
        isBetweenRange(
          scrolledPercentage,
          trackedBreakpoints[scrolledTargets.length],
          trackedBreakpoints[scrolledTargets.length + 1],
        )

      if (
        !preventSendingEvents &&
        isInTrackedRange &&
        scrolledTargets.length !== trackedBreakpoints.length
      ) {
        scrolledTargets.push(trackedBreakpoints[scrolledTargets.length])

        eventCallback(scrolledTargets[scrolledTargets.length - 1])
      }
    }

    window.addEventListener('scroll', handleScroll, true)

    return () => {
      window.removeEventListener('scroll', handleScroll, true)
    }
  }, [trackedBreakpoints, eventCallback, preventSendingEvents])
}
