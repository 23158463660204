import styled from 'styled-components'

import beforeAfterImage from 'assets/images/senior-before-after.png'

import { Color } from 'root-constants'

export enum LevelIndication {
  DEFAULT = 'default',
  CURRENT = 'current',
  GOAL = 'goal',
}

type TLevelIndicationUnitProps = {
  type: LevelIndication
}

const LEVEL_INDICATION_COLOR_MAP = {
  [LevelIndication.DEFAULT]: '#D6D7DB',
  [LevelIndication.CURRENT]: Color.PRIMARY,
  [LevelIndication.GOAL]: '#7DD523',
}

export const StyledLongPaywallUserTarget = {
  Root: styled.div`
    position: relative;
  `,
  AnimationContainer: styled.div<{
    isCancelOfferRoute: boolean
  }>`
    width: 100%;
    max-width: 375px;
    margin: 0 auto;
    height: 290px;
    overflow: hidden;
    border-radius: ${({ isCancelOfferRoute }) =>
      isCancelOfferRoute ? '20px 20px 0 0' : '0'};
  `,
  ImageContainer: styled.div`
    width: 375px;
    height: 290px;
    margin: auto;
    background-image: url(${beforeAfterImage});
    background-repeat: no-repeat;
    background-size: contain;
  `,
  Wrapper: styled.div`
    width: 100%;
    position: absolute;
    bottom: ${({ theme }) => theme.userTarget.bottom};
    left: 50%;
    transform: translate(-50%, 50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 20px 20px;
    border-top: 1px solid ${Color.WHITE};
    border-bottom: 1px solid ${Color.WHITE};
    background-color: ${({ theme }) => theme.userTarget.background};
    border-radius: 20px;
    max-width: ${({ theme }) => theme.userTarget.maxWidth};
  `,
  FacialCondition: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    &:first-of-type {
      margin-right: 8px;
    }
  `,
  Subtitle: styled.p`
    color: ${({ theme }) => theme.userTarget.subtitle.color};
    font-size: ${({ theme }) => theme.userTarget.subtitle.fontSize};
    font-weight: ${({ theme }) => theme.userTarget.subtitle.fontWeight};
    line-height: ${({ theme }) => theme.userTarget.subtitle.lineHeight};
    margin-bottom: ${({ theme }) => theme.userTarget.subtitle.marginBottom};
  `,
  LevelContent: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.75);
    border: 1px solid ${Color.WHITE};
    padding: 12px 16px 16px;
  `,
  UserGoal: styled.p`
    color: ${({ theme }) => theme.userTarget.goal.color};
    font-size: ${({ theme }) => theme.userTarget.goal.fontSize};
    font-weight: ${({ theme }) => theme.userTarget.goal.fontWeight};
    line-height: ${({ theme }) => theme.userTarget.goal.lineHeight};
    margin-bottom: 8px;
    text-align: center;
  `,
  LevelIndicationWrapper: styled.div`
    display: flex;
  `,
  LevelIndicationUnit: styled.div<TLevelIndicationUnitProps>`
    width: 22px;
    height: 4px;
    border-radius: 2px;
    background-color: ${({ type }) => LEVEL_INDICATION_COLOR_MAP[type]};

    &:not(:last-of-type) {
      margin-right: 3px;
    }
  `,
}
