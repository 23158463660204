import React, { useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import lottie from 'lottie-web/build/player/lottie_light'
import { Button, buttonTheme } from 'storybook-ui'

import { AdaptiveContainer } from 'components/AdaptiveContainer'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import skinConditionAnimation from 'assets/animation/skinConditionAnimation.json'
import harvardMedicalSchool from 'assets/images/harvard-medical-school-logo-v2.png'
import northwesternUniversity from 'assets/images/northwestern-university-logo-v2.png'

import { StyledReverseYourWrinkles2 as S } from './ReverseYourWrinkles2.styles'
import { QUESTION } from './constants'

export const ReverseYourWrinkles2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const animationContainerRef = useRef<HTMLDivElement | null>(null)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue('')

  useEffect(() => {
    if (animationContainerRef && animationContainerRef.current) {
      lottie.loadAnimation({
        container: animationContainerRef.current,
        animationData: skinConditionAnimation,
        loop: false,
      })
    }

    return () => lottie.destroy()
  }, [])

  return (
    <AdaptiveContainer title="onboarding.reverseYourWrinkles.question2">
      <S.GraphContainer ref={animationContainerRef} />
      <div>
        <S.Card>
          <S.ImageContainer>
            <img src={harvardMedicalSchool} alt="" />
          </S.ImageContainer>
          <p>
            <Trans i18nKey="onboarding.reverseYourWrinkles.item1" />
          </p>
        </S.Card>
        <S.Card>
          <S.ImageContainer>
            <img src={northwesternUniversity} alt="" />
          </S.ImageContainer>
          <p>
            <Trans i18nKey="onboarding.reverseYourWrinkles.item2" />
          </p>
        </S.Card>
      </div>

      <StickyButtonContainer centerMode>
        <Button
          onClick={handleNextClick}
          theme={buttonTheme.LUVLY_SECONDARY}
          maxWidth="488px"
        >
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
    </AdaptiveContainer>
  )
}
