import React, { FC, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'storybook-ui'

import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import afterBeforeImage from 'assets/images/after-before-second-result.png'

import { BUTTONS_PROPS } from '../TriedKoreanBeauty/constants'
import { StyledSmallStepsHelp as S } from './SmallStepsHelp.styles'
import { QUESTION } from './constants'

export const SmallStepsHelp: FC<TPageProps> = ({ nextPagePath, pageId }) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(''),
    [handleContinue],
  )

  return (
    <div>
      <S.Container>
        <S.PageTitle marginBottom={16}>
          <Trans i18nKey="onboarding.smallStepsHelp.title" />
        </S.PageTitle>

        <S.Image src={afterBeforeImage} alt="after/before" />

        <S.Review>
          <p>
            <Trans i18nKey="onboarding.smallStepsHelp.review" />
          </p>

          <span>
            <Trans i18nKey="onboarding.smallStepsHelp.author" />
          </span>
        </S.Review>
      </S.Container>

      <StickyButtonContainer centerMode>
        <Button {...BUTTONS_PROPS} onClick={handleNextClick}>
          {t`actions.continue`}
        </Button>
      </StickyButtonContainer>
    </div>
  )
}
