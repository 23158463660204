import { useLayoutEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import {
  getSubscriptionListAction,
  setScreenNameAction,
} from 'root-redux/actions/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useABTest } from 'hooks/useABTest'
import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useVatInfo } from 'hooks/useHasVatInfo'
import { useIsCurrentRoute } from 'hooks/useIsCurrentRoute'
import { usePurchaseCancelOfferRoute } from 'hooks/usePurchaseCancelOfferRoute'

import {
  Cohort,
  ScreenName,
  SubscriptionListType,
  SubscriptionTagsType,
} from 'root-constants'

export const useGetSubscriptionsList = () => {
  const dispatch: TAppDispatch = useDispatch()

  const hasVatInfo = useVatInfo()
  const cohortToUse = useCohortToUse()
  const cancelOfferRoute = usePurchaseCancelOfferRoute()
  const { isRefundNormalizedPricingActive } = useABTest()
  const { isRefundCohort } = useActiveCohortIdentifier()

  const isCancelOfferRoute = useIsCurrentRoute(cancelOfferRoute)

  const screenName = useMemo(() => {
    if (cohortToUse === Cohort.LUVLY_EMAIL_7) return ScreenName.EMAIL_OFFER

    return isCancelOfferRoute
      ? ScreenName.CANCEL_OFFER_LONG
      : ScreenName.PURCHASE
  }, [cohortToUse, isCancelOfferRoute])

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(screenName))

    const pricesTags: SubscriptionTagsType[] = []

    hasVatInfo
      ? pricesTags.push(SubscriptionTagsType.TAX)
      : pricesTags.push(SubscriptionTagsType.NO_TAX)

    isCancelOfferRoute && pricesTags.push(SubscriptionTagsType.CANCEL_OFFER)

    isRefundNormalizedPricingActive &&
      isRefundCohort &&
      pricesTags.push(SubscriptionTagsType.ROUNDED_TO_99)

    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.PURCHASE,
        pricesTags.join(','),
      ),
    )
  }, [
    dispatch,
    hasVatInfo,
    isCancelOfferRoute,
    isRefundCohort,
    isRefundNormalizedPricingActive,
    screenName,
  ])
}
