import { useCallback } from 'react'

import { eventLogger } from 'services/eventLogger.service'

import { ScreenName } from 'root-constants'

export const useUpsellScrollEvent = (screenName: ScreenName) => {
  const scrollEvent = useCallback(
    (scrolledPart: number) => {
      eventLogger.logUpsellPageViewScroll({
        screenName,
        scrolledPart,
      })
    },
    [screenName],
  )
  return { scrollEvent }
}
