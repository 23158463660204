import { Cohort } from 'root-constants'

export const QUESTION = 'Select your goal'
export const CHAT_QUESTION = 'Welcome to Luvly'

export const OPTION_VALUES = {
  GET_RID: 'get_rid_of_wrinkles',
  REDUCE_FAT: 'reduce_face_fat',
  BOTH_OPTIONS: 'both_options',
}

export const INTRO_PAGE_DEFAULT_TEXT = {
  title: 'onboarding.intro.title.default',
  subtitle: 'onboarding.intro.subtitle',
  firstAnswer: 'onboarding.intro.firstAnswer.default',
  secondAnswer: 'onboarding.intro.secondAnswer.default',
}

export const INTRO_PAGE_CONTEXT_TEXT_MAP = {
  [Cohort.LUVLY_COSMETIC_SCANNER]: {
    title: 'onboarding.intro.title.default_cosmetic',
    firstAnswer: 'onboarding.intro.firstAnswer.default_cosmetic',
    secondAnswer: 'onboarding.intro.secondAnswer.default_cosmetic',
  },
}
