export const QUESTION =
  'Stress can lead to weight gain and fat accumulation around the chin. How do you manage your stress levels?'

const OPTION_VALUES = {
  OFTEN_FEEL_STRESSED: 'often_feel_stressed',
  TRY_TO_MANAGE_STRESS: 'try_to_manage_stress',
  PRACTICE_STRESS_RELIEF: 'practice_stress_relief',
}

export const STRESS_LEVEL_ANSWERS = [
  {
    value: OPTION_VALUES.OFTEN_FEEL_STRESSED,
    text: 'onboarding.stressLevels.feelStressed',
  },
  {
    value: OPTION_VALUES.TRY_TO_MANAGE_STRESS,
    text: 'onboarding.stressLevels.manageStress',
  },
  {
    value: OPTION_VALUES.PRACTICE_STRESS_RELIEF,
    text: 'onboarding.stressLevels.practiceStressRelief',
  },
]
