import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import { resetErrorAction } from 'root-redux/actions/common'
import { getUserStatusAction } from 'root-redux/actions/user'
import {
  selectActionList,
  selectError,
  selectIsPrimerRetryProcessing,
} from 'root-redux/selects/common'
import { selectUUID } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import {
  AnimationState,
  DEFAULT_ERROR_DESCRIPTION,
} from 'modules/purchase/constants'

import paymentErrorAnimation from 'assets/animation/paymentErrorAnimation.json'
import paymentSuccessAnimation from 'assets/animation/paymentSuccessAnimation.json'
import paymentWaitingAnimation from 'assets/animation/paymentWaitingAnimation.json'

import {
  CHECK_3D_SECURE,
  PURCHASE,
  UPDATE_PRIMER_CLIENT_SESSION,
} from '../../redux/actions/common'
import { select3DSecureIframeUrl } from '../../redux/selects/common'
import { StyledPaymentWaitingModal as S } from './PaymentWaitingModal.styles'

type TProps = {
  isPaymentWaitingShown: boolean
  tryAgain?: () => void
  setIsPaymentWaitingShown: (value: boolean) => void
  buttonText?: string
}

export const PrimerPaymentWaitingModal: React.FC<TProps> = ({
  isPaymentWaitingShown,
  setIsPaymentWaitingShown,
  tryAgain,
  buttonText,
}) => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const wrapperRef = useRef<HTMLDivElement>(null)
  const uuid = useSelector(selectUUID)
  const fetchingActionsList = useSelector(selectActionList)
  const error = useSelector(selectError)
  const threeDSecureIframeURL = useSelector(select3DSecureIframeUrl)
  const isPrimerRetryProcessing = useSelector(selectIsPrimerRetryProcessing)
  const [animationState, setAnimationState] = useState<AnimationState>(
    AnimationState.LOADING,
  )

  const isPurchaseInProcess = useMemo(
    () =>
      fetchingActionsList.includes(PURCHASE) ||
      fetchingActionsList.includes(CHECK_3D_SECURE) ||
      fetchingActionsList.includes(UPDATE_PRIMER_CLIENT_SESSION),
    [fetchingActionsList],
  )

  const loadingAnimationRef = useRef<HTMLDivElement | null>(null)
  const completeAnimationRef = useRef<HTMLDivElement | null>(null)
  const errorAnimationRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (isPurchaseInProcess) {
      setIsPaymentWaitingShown(true)
    }

    if (isPurchaseInProcess && loadingAnimationRef.current) {
      lottie.loadAnimation({
        container: loadingAnimationRef.current,
        animationData: paymentWaitingAnimation,
        loop: true,
      })
    }

    if (
      !isPurchaseInProcess &&
      isPaymentWaitingShown &&
      error &&
      !isPrimerRetryProcessing
    ) {
      setAnimationState(AnimationState.ERROR)
    }

    if (
      !isPurchaseInProcess &&
      isPaymentWaitingShown &&
      !error &&
      !threeDSecureIframeURL &&
      !isPrimerRetryProcessing &&
      window.navigator.onLine
    ) {
      setAnimationState(AnimationState.SUCCESS)
    }

    return () => lottie.destroy()
  }, [
    error,
    isPurchaseInProcess,
    isPaymentWaitingShown,
    setIsPaymentWaitingShown,
    threeDSecureIframeURL,
    isPrimerRetryProcessing,
    dispatch,
  ])

  const handleResetError = useCallback(() => {
    if (window.navigator.onLine) {
      dispatch(getUserStatusAction(uuid))
      setIsPaymentWaitingShown(false)
      setAnimationState(AnimationState.LOADING)
      dispatch(resetErrorAction())

      tryAgain && tryAgain()
    }
  }, [setIsPaymentWaitingShown, dispatch, uuid, tryAgain])

  const handleSuccessButtonClick = useCallback(async () => {
    await dispatch(getUserStatusAction(uuid))

    setIsPaymentWaitingShown(false)
  }, [dispatch, uuid, setIsPaymentWaitingShown])

  useEffect(() => {
    switch (animationState) {
      case AnimationState.SUCCESS:
        if (completeAnimationRef.current) {
          lottie.loadAnimation({
            container: completeAnimationRef.current,
            animationData: paymentSuccessAnimation,
            loop: false,
          })
        }
        break
      case AnimationState.ERROR:
        if (errorAnimationRef.current) {
          lottie.loadAnimation({
            container: errorAnimationRef.current,
            animationData: paymentErrorAnimation,
            loop: false,
          })
        }
        break
      default:
        if (loadingAnimationRef.current) {
          lottie.loadAnimation({
            container: loadingAnimationRef.current,
            animationData: paymentWaitingAnimation,
            loop: true,
          })
        }
        break
    }

    return () => lottie.destroy()
  }, [animationState])

  useEffect(() => {
    if (isPaymentWaitingShown && wrapperRef.current) {
      wrapperRef.current.focus()
    }
  }, [isPaymentWaitingShown])

  return (
    <S.Wrapper isShown={isPaymentWaitingShown} ref={wrapperRef} tabIndex={-1}>
      <S.Content>
        <S.LottieContainer>
          {animationState === AnimationState.LOADING && (
            <>
              <S.Animation ref={loadingAnimationRef} />
              <S.Title>{t`purchase1.paymentWaiting.processing`}</S.Title>
              <S.Subtitle>
                {t`purchase1.paymentWaiting.processingPayment`}
              </S.Subtitle>
            </>
          )}
          {animationState === AnimationState.SUCCESS && (
            <>
              <S.Animation ref={completeAnimationRef} />
              <S.Title>
                {t`purchase1.paymentWaiting.paymentWasSuccessful`}
              </S.Title>
              <S.SuccessButton type="button" onClick={handleSuccessButtonClick}>
                {buttonText || t`purchase1.paymentWaiting.toLoginPage`}
              </S.SuccessButton>
            </>
          )}
          {animationState === AnimationState.ERROR && (
            <>
              <S.Animation ref={errorAnimationRef} />
              <S.Subtitle>
                {t`purchase1.paymentWaiting.errorDuringPayment`}
              </S.Subtitle>
              <S.Info>
                {error?.description || error || DEFAULT_ERROR_DESCRIPTION}
              </S.Info>
              <S.ErrorButton type="button" onClick={handleResetError}>
                {t`actions.tryAgain`}
              </S.ErrorButton>
            </>
          )}
        </S.LottieContainer>
      </S.Content>
    </S.Wrapper>
  )
}
