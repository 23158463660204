import { Cohort } from 'root-constants'

export const TINY_FIELDS = 16
export const DEFAULT_FIELDS = 20
export const MAX_WIDTH_360 = 360
export const MAX_WIDTH_375 = 375

export const TINY_FIELDS_COHORTS = {
  [Cohort.LUVLY_12]: TINY_FIELDS,
  [Cohort.LUVLY_CHAT]: TINY_FIELDS,
  [Cohort.LUVLY_CHIN]: TINY_FIELDS,
}

export const WIDE_CONTAINER_COHORTS = {
  [Cohort.LUVLY_CHIN]: MAX_WIDTH_375,
}
