import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, buttonTheme } from 'storybook-ui'

import { Container } from 'components/Container'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import doubleChinFemaleResult from 'assets/images/female-double-chin-result.png'
import doubleChinMaleResult from 'assets/images/male-double-chin-result.png'

import { CustomBackgroundStyles, StickyButtonContainer } from 'common-styles'
import { PageId } from 'page-constants'
import { Gender } from 'root-constants'

import { StyledWeWantYouLookBest as S } from './WeWantYouLookBest.styles'
import { QUESTION } from './constants'

export const WeWantYouLookBest: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const userGender = userAnswers?.[PageId.GENDER]

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue('')

  return (
    <S.Root background={CustomBackgroundStyles.PURPLE_TO_SANDY}>
      <Container>
        <S.Hero>
          <img
            src={
              userGender === Gender.MALE
                ? doubleChinMaleResult
                : doubleChinFemaleResult
            }
            alt=""
          />
        </S.Hero>
        <S.Title>{t('onboarding.weWantYouLookBest.title')}</S.Title>
        <S.Subtitle>{t('onboarding.weWantYouLookBest.subtitle')}</S.Subtitle>
      </Container>
      <StickyButtonContainer customBackground="transparent" centerMode>
        <Button onClick={handleNextClick} theme={buttonTheme.LUVLY_SECONDARY}>
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
    </S.Root>
  )
}
