import styled, { css } from 'styled-components'

import { DynamicDiscountTheme, Locale } from 'root-constants'

type TTheme = {
  theme: DynamicDiscountTheme
}

type TTextProps = {
  locale: Locale
}

export const StyledPurchaseTimer = {
  Container: styled.div<TTheme>`
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 10px;
    padding: 0 8px;
    align-items: center;
    height: 44px;

    & img {
      max-width: 49px;
      align-self: flex-end;
      margin-right: 2px;
    }

    ${({ theme }) => {
      switch (theme) {
        case DynamicDiscountTheme.CORAL:
          return css`
            background: linear-gradient(91deg, #ff4b4b -8.21%, #cf467b 95.97%);
          `
        case DynamicDiscountTheme.VIOLET:
          return css`
            background: linear-gradient(270deg, #7470ff 0%, #e2a3f8 103.03%);
          `
        case DynamicDiscountTheme.BLACK:
          return css`
            background: linear-gradient(91deg, #ff4b4b -8.21%, #cf467b 95.97%);
          `
        case DynamicDiscountTheme.BLUE:
          return css`
            background: linear-gradient(270deg, #554ff2 0%, #00c3ff 103.03%);
          `
        default:
          return css`
            background: linear-gradient(270deg, #7470ff 0%, #e2a3f8 103.03%);
          `
      }
    }}
  `,
  Text: styled.span<TTextProps>`
    color: #fffefe;
    line-height: 20px;
    font-weight: ${({ theme }) => theme.timerText.fontWeight};
    font-size: ${({ locale }) => (locale === Locale.ENGLISH ? '16px' : '14px')};

    strong {
      display: inline-block;
      font-size: 20px;
      font-weight: 700;
    }
  `,
}
