import styled, { css } from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import { TLocaleStylesProps } from 'modules/purchase/types'

import { Color } from 'root-constants'

type TTitleProps = {
  isSmallerTextSize: boolean
}

export const StyledMoneyBackGuarantee = {
  Wrapper: styled.div`
    position: relative;
    margin-bottom: 60px;
    background-color: ${Color.WHITE};
    box-sizing: border-box;

    ${({
      theme: {
        moneyBack: { wrapper },
      },
    }) => css`
      border: ${wrapper.border};
      box-shadow: ${wrapper.boxShadow};
      border-radius: ${wrapper.borderRadius};
      padding: ${wrapper.padding};
    `}
  `,
  Title: styled.h2<TTitleProps>`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    background-color: ${Color.WHITE};
    text-align: center;

    ${({
      theme: {
        moneyBack: { title },
      },
      isSmallerTextSize,
    }) => {
      if (isSmallerTextSize) {
        return css`
          font-size: 22px;
          min-width: 250px;
        `
      }

      return css`
        min-width: ${title.minWidth};
        font-size: ${title.fontSize};
        font-weight: ${title.fontWeight};
        line-height: ${title.lineHeight};
        top: ${title.positionTop};
      `
    }}
  `,
  Content: styled.div`
    ${({
      theme: {
        moneyBack: { content },
      },
    }) => css`
      padding: ${content.padding};
      border: ${content.border};
      border-radius: ${content.borderRadius};
    `}
  `,
  Text: styled.p<TLocaleStylesProps>`
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: ${({ isEnLocaleStyle }) =>
      isEnLocaleStyle ? 'center' : 'left'};
    color: ${({ theme }) => theme.moneyBack.text.color};

    & > a {
      color: ${({ theme }) => theme.moneyBack.text.linkColor};
      text-decoration: underline;
    }
  `,
  Badge: styled(SvgImage)`
    position: absolute;
    bottom: -68px;
    right: -12px;
    width: 92px;
  `,
}
