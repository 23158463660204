import faceFatMan1 from 'assets/images/face-fat-before-after-man-1.png'
import faceFatMan2 from 'assets/images/face-fat-before-after-man-2.png'
import faceFatWoman1 from 'assets/images/face-fat-before-after-woman-1.png'
import faceFatWoman2 from 'assets/images/face-fat-before-after-woman-2.png'
import tightenSkinMan1 from 'assets/images/tighten-skin-before-after-man-1.png'
import tightenSkinMan2 from 'assets/images/tighten-skin-before-after-man-2.png'
import tightenSkinWoman1 from 'assets/images/tighten-skin-before-after-woman-1.png'
import tightenSkinWoman2 from 'assets/images/tighten-skin-before-after-woman-2.png'
import wrinklesFreeMan1 from 'assets/images/wrinkles-free-before-after-man-1.png'
import wrinklesFreeMan2 from 'assets/images/wrinkles-free-before-after-man-2.png'
import wrinklesFreeWoman1 from 'assets/images/wrinkles-free-before-after-woman-1.png'
import wrinklesFreeWoman2 from 'assets/images/wrinkles-free-before-after-woman-2.png'

import { UserGoal } from 'root-constants'

export const QUESTIONS_MAP = {
  [UserGoal.TIGHTEN_SKIN]: 'I feel like I’m aging backwards',
  [UserGoal.REDUCE_FACE_FAT]: 'I’m literally a different person now',
  [UserGoal.WRINKLE_FREE_SKIN]: 'I’m not afraid of aging anymore',
}

export const EMBRACING_AGE_TEXT_MAP = {
  [UserGoal.TIGHTEN_SKIN]: {
    title: 'onboarding.embracingAge.title1',
    description: 'onboarding.embracingAge.description1',
    images: {
      man1: tightenSkinMan1,
      man2: tightenSkinMan2,
      woman1: tightenSkinWoman1,
      woman2: tightenSkinWoman2,
    },
  },
  [UserGoal.REDUCE_FACE_FAT]: {
    title: 'onboarding.embracingAge.title2',
    description: 'onboarding.embracingAge.description2',
    images: {
      man1: faceFatMan1,
      man2: faceFatMan2,
      woman1: faceFatWoman1,
      woman2: faceFatWoman2,
    },
  },
  [UserGoal.WRINKLE_FREE_SKIN]: {
    title: 'onboarding.embracingAge.title3',
    description: 'onboarding.embracingAge.description3',
    images: {
      man1: wrinklesFreeMan1,
      man2: wrinklesFreeMan2,
      woman1: wrinklesFreeWoman1,
      woman2: wrinklesFreeWoman2,
    },
  },
}
