import acneIcon from 'assets/images/acne-icon.png'
import dulnessIcon from 'assets/images/dulness-icon.png'
import pigmentationIcon from 'assets/images/pigmentation-icon.png'
import rednessIcon from 'assets/images/redness-icon.png'
import roughTextureIcon from 'assets/images/rough-texture-icon.png'
import unevenSkinToneIcon from 'assets/images/uneven-skin-tone-icon.png'

export const QUESTION = 'Do you have any of the following skin concerns?'

export const OPTION_VALUES = {
  ACNE: 'acne',
  REDNESS: 'redness',
  DULLNESS: 'dullness',
  ROUGH_TEXTURE: 'rough_texture',
  UNEVEN_SKIN_TONE: 'uneven_skin_tone',
  PIGMENTATION_CHANGES: 'pigmentation_changes',
  NOTHING_FROM_THE_ABOVE: 'nothing_from_the_above',
  DRYNESS: 'dryness',
  WRINKLES: 'wrinkles',
  SAGGING: 'sagging',
}

export const LIKE_CHAT_SKIN_CONCERNS_OPTIONS = [
  {
    value: OPTION_VALUES.ACNE,
    label: 'onboarding.skinConcerns.acne',
  },
  {
    value: OPTION_VALUES.REDNESS,
    label: 'onboarding.skinConcerns.redness',
  },
  {
    value: OPTION_VALUES.DULLNESS,
    label: 'onboarding.skinConcerns.dullness',
  },
  {
    value: OPTION_VALUES.UNEVEN_SKIN_TONE,
    label: 'onboarding.skinConcerns.unevenSkinTone',
  },
  {
    value: OPTION_VALUES.DRYNESS,
    label: 'onboarding.skinConcerns.dryness',
  },
  {
    value: OPTION_VALUES.WRINKLES,
    label: 'onboarding.skinConcerns.wrinkles',
  },
  {
    value: OPTION_VALUES.PIGMENTATION_CHANGES,
    label: 'onboarding.skinConcerns.pigmentationChanges',
  },
  {
    value: OPTION_VALUES.SAGGING,
    label: 'onboarding.skinConcerns.sagging',
  },
  {
    value: OPTION_VALUES.NOTHING_FROM_THE_ABOVE,
    label: 'onboarding.skinConcerns.nothingFromTheAbove',
  },
]

export const SKIN_CONCERNS_ANSWERS_MAP = {
  [OPTION_VALUES.ACNE]: 'onboarding.skinConcerns.acne',
  [OPTION_VALUES.REDNESS]: 'onboarding.skinConcerns.redness',
  [OPTION_VALUES.DULLNESS]: 'onboarding.skinConcerns.dullness',
  [OPTION_VALUES.UNEVEN_SKIN_TONE]: 'onboarding.skinConcerns.unevenSkinTone',
  [OPTION_VALUES.PIGMENTATION_CHANGES]:
    'onboarding.skinConcerns.pigmentationChanges',
  [OPTION_VALUES.DRYNESS]: 'onboarding.skinConcerns.dryness',
  [OPTION_VALUES.WRINKLES]: 'onboarding.skinConcerns.wrinkles',
  [OPTION_VALUES.SAGGING]: 'onboarding.skinConcerns.sagging',
  [OPTION_VALUES.NOTHING_FROM_THE_ABOVE]:
    'onboarding.skinConcerns.nothingFromTheAbove',
}

export const SKIN_CONCERNS_VARIANT_2_ANSWERS = [
  {
    label: 'onboarding.skinConcerns.acne',
    value: OPTION_VALUES.ACNE,
    icon: acneIcon,
  },
  {
    label: 'onboarding.skinConcerns.redness',
    value: OPTION_VALUES.REDNESS,
    icon: rednessIcon,
  },
  {
    label: 'onboarding.skinConcerns.dullness',
    value: OPTION_VALUES.DULLNESS,
    icon: dulnessIcon,
  },
  {
    label: 'onboarding.skinConcerns.roughTexture',
    value: OPTION_VALUES.ROUGH_TEXTURE,
    icon: roughTextureIcon,
  },
  {
    label: 'onboarding.skinConcerns.unevenSkinTone',
    value: OPTION_VALUES.UNEVEN_SKIN_TONE,
    icon: unevenSkinToneIcon,
  },
  {
    label: 'onboarding.skinConcerns.pigmentationChanges',
    value: OPTION_VALUES.PIGMENTATION_CHANGES,
    icon: pigmentationIcon,
  },
]
