export const QUESTION =
  'What concerns you the most about other double chin treatments?'

export const OPTION_VALUES = {
  TREATMENT_SAFETY: 'treatment_safety',
  EFFECTIVENESS_RESULTS: 'effectiveness_results',
  COST: 'cost',
  RECOVERY: 'recovery',
}

export const DOUBLE_CHIN_CONCERNS = [
  {
    text: 'onboarding.doubleChinConcerns.option1',
    detailed: 'onboarding.doubleChinConcerns.detailed1',
    label: OPTION_VALUES.TREATMENT_SAFETY,
  },
  {
    text: 'onboarding.doubleChinConcerns.option2',
    detailed: 'onboarding.doubleChinConcerns.detailed2',
    label: OPTION_VALUES.EFFECTIVENESS_RESULTS,
  },
  {
    text: 'onboarding.doubleChinConcerns.option3',
    detailed: 'onboarding.doubleChinConcerns.detailed3',
    label: OPTION_VALUES.COST,
  },
  {
    text: 'onboarding.doubleChinConcerns.option4',
    detailed: 'onboarding.doubleChinConcerns.detailed4',
    label: OPTION_VALUES.RECOVERY,
  },
]
