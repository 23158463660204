import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ChatAnswerButton } from 'components/ChatAnswerButton'
import { ChatMessage } from 'components/ChatMessage'
import {
  ChatAuthor,
  ChatStep,
  DELAY_BEFORE_SHOW,
} from 'components/ChatMessage/ChatMessage'
import { Container } from 'components/Container'
import { Option } from 'components/Option'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Chat, DelayedBottomContainer } from 'common-styles'
import {
  CHAT_PAGE_TRANSITION_DELAY,
  Cohort,
  I18N_CONTEXT_COHORT_MAP,
  OptionType,
} from 'root-constants'

import {
  EATING_PATTERNS_ANSWERS_MAP,
  OPTION_VALUES,
  QUESTION,
} from './constants'

export const LikeChatEatingPatterns: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answer, setAnswer] = useState<string>('')
  const [chatStep, setChatStep] = useState<ChatStep>(ChatStep.EXPERT_QUESTION)
  const { handleChange } = useDelayedContinue(CHAT_PAGE_TRANSITION_DELAY)
  const {
    isAnswersDisabled,
    handleChange: handleAnswerChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleSetAnswer = (value) => {
    setAnswer(value)
  }

  useEffect(() => {
    if (chatStep === ChatStep.FINISH) {
      handleChange(answer, handleContinue)
    }
  }, [answer, chatStep, handleChange, handleContinue])

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    disabled: isAnswersDisabled,
    onChange: (value: string) => {
      setIsAnswersDisabled(true)
      handleAnswerChange(value, handleSetAnswer)
    },
  }

  return (
    <Container fullHeight justifyContent="flex-end">
      <Chat>
        <ChatMessage
          author={ChatAuthor.EXPERT}
          delayBeforeShow={DELAY_BEFORE_SHOW}
          onTransitionEnd={() => setChatStep(ChatStep.USER_ANSWER)}
          hasJustNowLabel={
            chatStep === ChatStep.EXPERT_QUESTION ||
            (chatStep === ChatStep.USER_ANSWER && !answer)
          }
        >
          {t('onboarding.likeChatMeals.title')}
        </ChatMessage>
        <ChatMessage
          author={ChatAuthor.USER}
          {...(!!answer && {
            delayBeforeShow: DELAY_BEFORE_SHOW,
          })}
          onTransitionEnd={() => setChatStep(ChatStep.FINISH)}
        >
          {answer && (
            <Trans
              i18nKey={EATING_PATTERNS_ANSWERS_MAP[answer]}
              context={I18N_CONTEXT_COHORT_MAP[Cohort.LUVLY_CHAT]}
            />
          )}
        </ChatMessage>
      </Chat>
      <DelayedBottomContainer
        isShown={!answer && chatStep === ChatStep.USER_ANSWER}
      >
        <Option {...optionProps} value={OPTION_VALUES.LESS_THAN_THREE_TIMES}>
          <ChatAnswerButton>
            <Trans
              i18nKey="onboarding.eatingPatterns.lessThanThreeTimes"
              context={I18N_CONTEXT_COHORT_MAP[Cohort.LUVLY_CHAT]}
            />
          </ChatAnswerButton>
        </Option>
        <Option {...optionProps} value={OPTION_VALUES.AT_LEAST_THREE_TIMES}>
          <ChatAnswerButton>
            <Trans
              i18nKey="onboarding.eatingPatterns.atLeastThreeTimes"
              context={I18N_CONTEXT_COHORT_MAP[Cohort.LUVLY_CHAT]}
            />
          </ChatAnswerButton>
        </Option>
        <Option {...optionProps} value={OPTION_VALUES.DIFFERENTLY}>
          <ChatAnswerButton>
            <Trans
              i18nKey="onboarding.eatingPatterns.differently"
              context={I18N_CONTEXT_COHORT_MAP[Cohort.LUVLY_CHAT]}
            />
          </ChatAnswerButton>
        </Option>
      </DelayedBottomContainer>
    </Container>
  )
}
