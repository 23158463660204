import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { CompositeAnswerButton } from 'components/CompositeAnswerButton'
import { Container } from 'components/Container'
import { InputWithFloatPlaceholder } from 'components/InputWithFloatPlaceholder'
import { NavigationButtons } from 'components/NavigationButtons'
import { Option } from 'components/Option'
import { PageTitleDescription } from 'components/PageTitleDescription'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useIsNavigationOn } from 'hooks/useIsNavigationOn'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { goBack } from 'browser-history'
import { BottomButtonContainer } from 'common-styles'
import {
  I18N_CONTEXT_COHORT_MAP,
  ONBOARDING_INPUT_MIN_LENGTH,
  OptionType,
} from 'root-constants'

import { StyledDiet as S } from './Diet.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const Diet: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const [answer, setAnswer] = useState<string>('')
  const [diet, setDiet] = useState({ value: '', isValid: false })

  const cohortToUse = useCohortToUse()
  const includeNavigation = useIsNavigationOn()
  const { isSeniorCohort } = useActiveCohortIdentifier()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    onChange: (value: string) => {
      setAnswer(value)
      setDiet({
        value: '',
        isValid: false,
      })
    },
  }

  const handleNextClick = () => handleContinue(answer)

  const handleInputChange = ({ target: { value } }) => {
    const isInputValueValid = value.length >= ONBOARDING_INPUT_MIN_LENGTH

    setDiet({ value, isValid: isInputValueValid })
    setAnswer(isInputValueValid ? value : '')
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (!diet.isValid) return

    handleNextClick()
  }

  return (
    <div>
      <Container>
        <S.TitleContainer>
          <Trans
            i18nKey="onboarding.diet.question"
            components={[<br />]}
            context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
          />
        </S.TitleContainer>
        {!isSeniorCohort && (
          <S.DescriptionContainer>
            <PageTitleDescription>
              {t`onboarding.diet.description`}
            </PageTitleDescription>
          </S.DescriptionContainer>
        )}
        <S.OptionsContainer>
          <Option
            {...optionProps}
            value={OPTION_VALUES.NO_DIET}
            checked={answer === OPTION_VALUES.NO_DIET}
          >
            <CompositeAnswerButton title={t`onboarding.diet.noDiet.title`}>
              {t`onboarding.diet.noDiet.description`}
            </CompositeAnswerButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.VEGETARIAN}
            checked={answer === OPTION_VALUES.VEGETARIAN}
          >
            <CompositeAnswerButton title={t`onboarding.diet.vegetarian.title`}>
              <Trans
                i18nKey="onboarding.diet.vegetarian.description"
                context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
              />
            </CompositeAnswerButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.NO_GLUTEN_DIET}
            checked={answer === OPTION_VALUES.NO_GLUTEN_DIET}
          >
            <CompositeAnswerButton
              title={t('onboarding.diet.noGlutenDiet.title', {
                context: I18N_CONTEXT_COHORT_MAP[cohortToUse] || '',
              })}
            >
              <Trans
                i18nKey="onboarding.diet.noGlutenDiet.description"
                context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
              />
            </CompositeAnswerButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.VEGAN}
            checked={answer === OPTION_VALUES.VEGAN}
          >
            <CompositeAnswerButton title={t`onboarding.diet.vegan.title`}>
              <Trans
                i18nKey="onboarding.diet.vegan.description"
                context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
              />
            </CompositeAnswerButton>
          </Option>
        </S.OptionsContainer>
        <form onSubmit={handleSubmit}>
          <InputWithFloatPlaceholder
            value={diet.value}
            labelName={t`onboarding.motivation.other`}
            onChange={handleInputChange}
          />
        </form>
      </Container>
      {includeNavigation ? (
        <NavigationButtons
          onBackClick={goBack}
          onNextClick={handleNextClick}
          disabled={!answer}
        />
      ) : (
        <BottomButtonContainer>
          <Button onClick={handleNextClick} disabled={!answer}>
            {t`actions.continue`}
          </Button>
        </BottomButtonContainer>
      )}
    </div>
  )
}
