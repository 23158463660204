import styled from 'styled-components'

import chevronRight from 'assets/images/chevron-right-coral-2.png'

import { DoubleChinTitle } from 'common-styles'
import { Color } from 'root-constants'

export const StyledAgeWithImages2 = {
  Title: styled(DoubleChinTitle)`
    margin-bottom: 8px;
  `,
  Description: styled.p`
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.GRAY};
    margin-bottom: 32px;
  `,
  Actions: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    margin: 0 -4px;

    input:checked + div > div {
      border: 2px solid ${Color.CORAL};
    }

    & label {
      flex-basis: calc(50% - 8px);
      margin-bottom: 0;
    }
  `,
  Action: styled.div`
    display: flex;
    min-height: 176px;
    position: relative;
    padding-top: 11px;
  `,
  Image: styled.img`
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-height: 140px;
    z-index: 1;
  `,
  Answer: styled.div`
    flex-basis: 100%;
    background-color: ${Color.GRAY_LIGHT};
    border: 2px solid transparent;
    border-radius: 18px;
    transition: background-color 0.1s ease-out;
  `,
  AnswerWrapper: styled.span`
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.2s;
    border-radius: 16px;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
    font-weight: 600;
    color: ${Color.WHITE};
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translateX(-50%);
    height: 48px;
    width: calc(100% - 8px);
    padding: 12px;
    z-index: 2;
    background: ${Color.CORAL};
  `,
  AnswerValue: styled.span``,
  ChevronRight: styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: relative;
    background-color: ${Color.WHITE};
    &::before {
      content: '';
      position: absolute;
      width: 6px;
      height: 12px;
      background: url(${chevronRight}) no-repeat center;
      background-size: contain;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  `,
}
