export const QUESTION_FEMALE =
  'During menstruation, the levels of estrogen and progesterone may affect your skin’s condition. Is your menstrual cycle regular?'

export const OPTION_VALUES_FEMALE = {
  REGULAR: 'regular',
  IRREGULAR: 'irregular',
  DONT_HAVE_DUE_TO_AGE: 'dont_have_due_to_age',
  DONT_KNOW: 'dont_know',
}

export const ANSWERS_FEMALE = [
  {
    value: OPTION_VALUES_FEMALE.REGULAR,
    label: 'onboarding.affectSkinCondition.regular',
  },
  {
    value: OPTION_VALUES_FEMALE.IRREGULAR,
    label: 'onboarding.affectSkinCondition.irregular',
  },
  {
    value: OPTION_VALUES_FEMALE.DONT_HAVE_DUE_TO_AGE,
    label: 'onboarding.affectSkinCondition.dontHaveDueToAge',
  },
  {
    value: OPTION_VALUES_FEMALE.DONT_KNOW,
    label: 'onboarding.affectSkinCondition.dontKnow',
  },
]

export const QUESTION_MALE =
  'Testosterone levels and other biological factors can impact your skin’s condition. How frequently do you experience skin concerns such as breakouts, dryness, or irritation?'

export const OPTION_VALUES_MALE = {
  REGULAR: 'regular',
  IRREGULAR: 'irregular',
  NOT_EXPERIENCE_SKIN_CONCERNS: 'not_experience',
  DONT_KNOW: 'dont_know',
}

export const ANSWERS_MALE = [
  {
    value: OPTION_VALUES_MALE.REGULAR,
    label: 'onboarding.affectSkinCondition.regular',
  },
  {
    value: OPTION_VALUES_MALE.IRREGULAR,
    label: 'onboarding.affectSkinCondition.irregular',
  },
  {
    value: OPTION_VALUES_MALE.NOT_EXPERIENCE_SKIN_CONCERNS,
    label: 'onboarding.affectSkinCondition.notExperience',
  },
  {
    value: OPTION_VALUES_MALE.DONT_KNOW,
    label: 'onboarding.affectSkinCondition.dontKnow',
  },
]
