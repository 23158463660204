import { useLayoutEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import {
  selectActionList,
  selectIsPrimerRetryProcessing,
} from 'root-redux/selects/common'

import { PaymentMethod } from '../constants'
import {
  CHECK_3D_SECURE,
  PURCHASE,
  UPDATE_PRIMER_CLIENT_SESSION,
} from '../redux/actions/common'
import { selectPaymentMethod } from '../redux/selects/common'
import { useCancelPrimerDiscount } from './useCancelPrimerDiscount'

export const useSelectedPaymentMethod = ({
  hasErrorTheme,
  alternativePaymentMethods,
  setSelectedPaymentMethod,
}: {
  hasErrorTheme: boolean
  alternativePaymentMethods: PaymentMethod[]
  setSelectedPaymentMethod: (paymentMethod: PaymentMethod) => void
}) => {
  const isPrimerRetryProcessing = useSelector(selectIsPrimerRetryProcessing)
  const fetchingActionsList = useSelector(selectActionList)
  const currentPaymentMethod = useSelector(selectPaymentMethod)
  const { cancelDiscount } = useCancelPrimerDiscount()

  const isPurchaseInProcess = useMemo(
    () =>
      fetchingActionsList.includes(PURCHASE) ||
      fetchingActionsList.includes(CHECK_3D_SECURE) ||
      fetchingActionsList.includes(UPDATE_PRIMER_CLIENT_SESSION),
    [fetchingActionsList],
  )

  useLayoutEffect(() => {
    if (hasErrorTheme || isPrimerRetryProcessing || isPurchaseInProcess) return
    if (alternativePaymentMethods.includes(PaymentMethod.GOOGLE_PAY)) {
      setSelectedPaymentMethod(PaymentMethod.GOOGLE_PAY)
      return
    }

    if (alternativePaymentMethods.includes(PaymentMethod.APPLE_PAY)) {
      setSelectedPaymentMethod(PaymentMethod.APPLE_PAY)
      return
    }

    if (alternativePaymentMethods.includes(PaymentMethod.PAYPAL)) {
      setSelectedPaymentMethod(PaymentMethod.PAYPAL)
      return
    }
    setSelectedPaymentMethod(PaymentMethod.CREDIT_CARD)
  }, [
    alternativePaymentMethods,
    hasErrorTheme,
    isPrimerRetryProcessing,
    isPurchaseInProcess,
    setSelectedPaymentMethod,
  ])

  const setNextAvailablePaymentMethod = () => {
    if (
      currentPaymentMethod === PaymentMethod.APPLE_PAY ||
      currentPaymentMethod === PaymentMethod.GOOGLE_PAY
    ) {
      setSelectedPaymentMethod(PaymentMethod.CREDIT_CARD)
      return
    }

    if (
      alternativePaymentMethods.includes(PaymentMethod.GOOGLE_PAY) &&
      (currentPaymentMethod === PaymentMethod.CREDIT_CARD ||
        currentPaymentMethod === PaymentMethod.PAYPAL)
    ) {
      setSelectedPaymentMethod(PaymentMethod.GOOGLE_PAY)
      return
    }

    if (
      alternativePaymentMethods.includes(PaymentMethod.APPLE_PAY) &&
      (currentPaymentMethod === PaymentMethod.CREDIT_CARD ||
        currentPaymentMethod === PaymentMethod.PAYPAL)
    ) {
      setSelectedPaymentMethod(PaymentMethod.APPLE_PAY)
      return
    }

    if (
      alternativePaymentMethods.includes(PaymentMethod.PAYPAL) &&
      currentPaymentMethod === PaymentMethod.CREDIT_CARD
    ) {
      cancelDiscount()
      setSelectedPaymentMethod(PaymentMethod.PAYPAL)
    }
  }

  return { setNextAvailablePaymentMethod }
}
