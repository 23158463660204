import React from 'react'

import { Progress } from 'components/Progress'
import { SvgImage } from 'components/SvgImage'

import coralCheckIcon from 'assets/images/sprite/check-icon-coral.svg'

import { StyledProgressAnalyzer as S } from './ProgressAnalyzer.styles'
import { MAX_PROGRESS } from './constants'

type TProps = {
  value: number
  color: string
  isInProgress?: boolean
  isFinished?: boolean
  children?: React.ReactNode
}

export const ProgressAnalyzerV2: React.FC<TProps> = ({
  color,
  value,
  isInProgress,
  isFinished,
  children,
}) => {
  const renderPercentage = () => {
    if (value >= MAX_PROGRESS) {
      return <SvgImage svg={coralCheckIcon} width={16} height={16} />
    }

    return `${value}%`
  }

  return (
    <S.Wrapper>
      <S.DescriptionV2 isInProgress={isInProgress} isFinished={isFinished}>
        <S.Text>{children}</S.Text>
        <S.Value>{renderPercentage()}</S.Value>
      </S.DescriptionV2>
      <S.ProgressWrapper>
        <Progress background="#fff" color={color} value={value} max={100} />
      </S.ProgressWrapper>
    </S.Wrapper>
  )
}
