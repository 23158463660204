import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, buttonTheme } from 'storybook-ui'

import { AdaptiveContainer } from 'components/AdaptiveContainer'
import { Option } from 'components/Option'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { OptionType } from 'root-constants'

import { StyledFaceShape as S } from './FaceShape.styles'
import { FACES_OPTIONS, QUESTION } from './constants'

export const FaceShapeV2: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const [answer, setAnswer] = useState<string>('')

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue(answer)

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      setAnswer(value)
    },
  }

  return (
    <AdaptiveContainer title="onboarding.faceShape.questionV2">
      <S.OptionsContainerV2>
        {FACES_OPTIONS.map(({ value, image }) => {
          return (
            <Option {...optionProps} value={value} key={value}>
              <S.CustomAnswer imageSrc={image} />
            </Option>
          )
        })}
      </S.OptionsContainerV2>
      <StickyButtonContainer centerMode>
        <Button
          onClick={handleNextClick}
          disabled={!answer}
          theme={buttonTheme.LUVLY_SECONDARY}
          maxWidth="488px"
        >
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
    </AdaptiveContainer>
  )
}
