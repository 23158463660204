import React from 'react'

import { FeaturesReady } from '@growthbook/growthbook-react'

import { Spinner } from 'components/Spinner'

import { useCohortToUse } from 'hooks/useCohortToUse'

import { TPageProps } from 'models/common.model'

import { AgeAsIntro } from './AgeAsIntro'
import { AgeAsIntroWithBackground } from './AgeAsIntroWithBackground'
import { INTRO_PAGE_WITH_BACKGROUND_IMAGE_COHORTS } from './constants'

export const AgeAsIntroProvider: React.FC<TPageProps> = (props) => {
  const cohortToUse = useCohortToUse()

  return (
    <FeaturesReady fallback={<Spinner />}>
      {INTRO_PAGE_WITH_BACKGROUND_IMAGE_COHORTS.includes(cohortToUse) ? (
        <AgeAsIntroWithBackground {...props} />
      ) : (
        <AgeAsIntro {...props} />
      )}
    </FeaturesReady>
  )
}
