export const QUESTION = 'Description of daily skin care routine'

export const OPTION_VALUES = {
  IS_NOT_GREAT: 'is_not_great',
  CAN_BE_BETTER: 'can_be_better',
  PRACTICE_PROPER_SKIN_CARE_ROUTINE: 'practice_proper_skin_care_routine',
}

export const SKIN_CARE_ROUTINE_DESCRIPTION_ANSWERS = [
  {
    value: OPTION_VALUES.IS_NOT_GREAT,
    label: 'onboarding.skinCareRoutineDescription.isNotGreat',
  },
  {
    value: OPTION_VALUES.CAN_BE_BETTER,
    label: 'onboarding.skinCareRoutineDescription.canBeBetter',
  },
  {
    value: OPTION_VALUES.PRACTICE_PROPER_SKIN_CARE_ROUTINE,
    label:
      'onboarding.skinCareRoutineDescription.practiceProperSkinCareRoutine',
  },
]
