import styled from 'styled-components'

import earthIcon from 'assets/images/customer-review-earth-icon.png'
import messageIcon from 'assets/images/star-message.png'

import { Color } from 'root-constants'

export const StyledReviewCoral = {
  ReviewContainer: styled.div`
    margin-bottom: 8px;
    padding: 16px;
    background-color: ${Color.WHITE};
    border: 1px solid ${Color.GRAY_LIGHT};
    box-sizing: border-box;
    border-radius: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  `,
  CustomerInfoContainer: styled.div`
    position: relative;
    padding-left: 52px;
  `,
  CustomerImg: styled.img`
    position: absolute;
    top: 0;
    left: 0;
    max-width: 40px;
  `,
  CustomerName: styled.p`
    position: relative;
    display: inline-block;
    font-size: 17px;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.DARK_SECONDARY};

    &:before {
      content: '';
      position: absolute;
      top: 3px;
      right: -25px;
      width: 19px;
      height: 17px;
      background-image: url(${messageIcon});
      background-size: contain;
      background-repeat: no-repeat;
    }
  `,
  CustomerRecommendation: styled.p`
    position: relative;
    padding: 0 0 8px 16px;
    font-size: 12px;
    line-height: 16px;
    color: ${Color.GRAY};

    & strong {
      font-weight: 700;
    }

    &:before {
      content: '';
      position: absolute;
      top: 2px;
      left: 0;
      width: 12px;
      height: 12px;
      background-image: url(${earthIcon});
      background-size: contain;
      background-repeat: no-repeat;
    }
  `,
  CustomerReview: styled.p`
    font-size: 14px;
    line-height: 18px;
    color: ${Color.BLUE_DARK};
  `,
  EmojiContainer: styled.span`
    margin-left: 2px;
    vertical-align: middle;
  `,
  Emoji: styled.img`
    display: inline-block;
    width: 16px;
    height: 16px;
  `,
}
