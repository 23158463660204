import { useSelector } from 'react-redux'

import { Currency } from '../constants'
import { selectCurrency } from '../redux/selects/common'

export const useGetPriceWithCurrencyFormatting = () => {
  const currency = useSelector(selectCurrency)

  const getPriceWithCurrencyFormatting = (price: number | string) => {
    if (currency !== Currency.JPY) return price

    return new Intl.NumberFormat('ja-JP', {
      style: 'decimal',
      currency: 'JPY',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(Number(price))
  }

  return { getPriceWithCurrencyFormatting }
}
