export const QUESTION = 'How much time are you willing to invest?'

const OPTION_VALUES = {
  QUICK_RESULTS: 'quick_results',
  MODERATE_TIME: 'moderate_time',
  LONG_TERM: 'long_term',
}

export const ANSWERS = [
  {
    value: OPTION_VALUES.QUICK_RESULTS,
    text: 'onboarding.timeToInvest.quickResults',
  },
  {
    value: OPTION_VALUES.MODERATE_TIME,
    text: 'onboarding.timeToInvest.moderateTime',
  },
  {
    value: OPTION_VALUES.LONG_TERM,
    text: 'onboarding.timeToInvest.longTerm',
  },
]
