import React, { FC, useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'storybook-ui'

import { IntroHeader } from 'components/IntroHeader'
import { IntroHeaderColor } from 'components/IntroHeader/constants'
import { PageTitle } from 'components/PageTitle'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'
import { Ticker } from 'components/Ticker'

import { useNextStep } from 'hooks/useNextStep'
import { useSendIntroPageCompletedAnalytics } from 'hooks/useSendIntroPageCompletedAnalytics'
import { useStartSession } from 'hooks/useStartSession'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import personImage from 'assets/images/portrait-korean-woman.png'

import { BUTTONS_PROPS } from '../TriedKoreanBeauty/constants'
import { StyledPersonalKoreanPlan as S } from './PersonalKoreanPlan.styles'
import { BUNDLES_VALUES, QUESTION } from './constants'

export const PersonalKoreanPlan: FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const { sendIntroPageCompletedAnalytics } =
    useSendIntroPageCompletedAnalytics()

  useStartSession()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(() => {
    sendIntroPageCompletedAnalytics()

    eventLogger.logFirstPageCompleted({
      question: QUESTION,
      answers: '',
    })
    handleContinue('')
  }, [handleContinue, sendIntroPageCompletedAnalytics])

  return (
    <S.Root>
      <IntroHeader colorTheme={IntroHeaderColor.DARK} />

      <S.Container>
        <S.TextBlock>
          <PageTitle>
            <Trans i18nKey="onboarding.personalKoreanPlan.title" />
          </PageTitle>

          <S.Subtitle>
            <Trans i18nKey="onboarding.personalKoreanPlan.subtitle" />
          </S.Subtitle>

          <S.Subtitle>
            <Trans i18nKey="onboarding.personalKoreanPlan.startQuiz" />
          </S.Subtitle>
        </S.TextBlock>

        <S.PersonBlock>
          <S.PersonImage
            src={personImage}
            alt="Get your personal korean beauty plan"
          />

          {BUNDLES_VALUES.map((bundle) => (
            <S.Bundle key={bundle}>
              <Trans i18nKey={bundle} />
            </S.Bundle>
          ))}
        </S.PersonBlock>
      </S.Container>

      <S.Container>
        <S.Terms>
          <p>{t`onboarding.intro.termsTitle`}</p>
          <TermsOfUseLink />
          <S.Separator />
          <PrivacyPolicyLink />
        </S.Terms>

        <S.StickyButtonContainer customBackground="transparent" centerMode>
          <Button {...BUTTONS_PROPS} onClick={handleNextClick}>
            {t`actions.continue`}
          </Button>
        </S.StickyButtonContainer>
      </S.Container>

      <S.DiscountContainer>
        <Ticker />
      </S.DiscountContainer>
    </S.Root>
  )
}
