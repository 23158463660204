import balloons from 'assets/images/balloons-icon.png'
import camera from 'assets/images/camera-icon.png'
import face1 from 'assets/images/face-icon-1.png'
import face2 from 'assets/images/face-icon-2.png'

export const QUESTION =
  'What excites you the most about getting rid of your double chin?'

export const OPTION_VALUES = {
  FEELING_CONFIDENT: 'feeling_confident',
  LOOKING_YOUNGER: 'looking_younger',
  IMPROVE_FACIAL_PROFILE: 'improve_facial_profile',
  SPECIAL_EVENT: 'special_event',
}

export const ANSWERS = [
  {
    value: OPTION_VALUES.FEELING_CONFIDENT,
    text: 'onboarding.gettingRidDoubleChin.feelingConfident',
    icon: camera,
  },
  {
    value: OPTION_VALUES.LOOKING_YOUNGER,
    text: 'onboarding.gettingRidDoubleChin.lookYounger',
    icon: face1,
  },
  {
    value: OPTION_VALUES.IMPROVE_FACIAL_PROFILE,
    text: 'onboarding.gettingRidDoubleChin.improveProfile',
    icon: face2,
  },
  {
    value: OPTION_VALUES.SPECIAL_EVENT,
    text: 'onboarding.gettingRidDoubleChin.specialEvent',
    icon: balloons,
  },
]
