import styled from 'styled-components'

import { Option } from 'components/Option'

import checkIcon from 'assets/images/check-icon.svg'
import chevronDown from 'assets/images/chevron-down.svg'
import introDesktopBg from 'assets/images/intro-desktop-min.jpg'
import introBg from 'assets/images/intro-mobile-min.jpg'

import { Color, MediaBreakpoint } from 'root-constants'

type TRadioButtonProps = {
  isChecked: boolean
}

export const StyledIntroVariant1 = {
  Root: styled.div`
    position: relative;
    background: url(${introBg}) no-repeat;
    background-position: top center;
    background-size: cover;
    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      background: url(${introDesktopBg}) no-repeat;
      background-position: top center;
      background-size: cover;
    }
  `,
  Container: styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
    min-height: var(--full-height);
    height: 100%;
    padding: 0 16px;
  `,
  Subtitle: styled.p`
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    text-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
    color: ${Color.WHITE};
    text-align: center;
    margin-bottom: 16px;
  `,
  Title: styled.h1`
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    color: ${Color.WHITE};
    text-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
    margin-bottom: 46px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 560px;
      margin: 0 auto 16px auto;
    }
  `,
  ActionCall: styled.h3`
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    text-align: center;
    color: ${Color.WHITE};
    text-shadow: 0 4px 6px rgba(0, 0, 0, 0.25);
    margin-bottom: 55px;
    position: relative;
    cursor: pointer;

    &::after {
      display: block;
      content: '';
      width: 18px;
      height: 8px;
      background: url(${chevronDown}) no-repeat center;
      background-size: contain;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -28px;
    }
  `,
  Actions: styled.div`
    display: flex;
    margin-bottom: 16px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 618px;
      margin: 0 auto 16px auto;
    }
  `,
  StyledOption: styled(Option)<TRadioButtonProps>`
    flex-basis: calc(50% - 6px);
    border-radius: 12px;
    background-color: ${({ isChecked }) =>
      isChecked ? Color.PRIMARY : Color.WHITE};
    color: ${({ isChecked }) => (isChecked ? Color.WHITE : Color.PRIMARY)};

    &:first-child {
      margin-right: 6px;
    }

    &:last-child {
      margin-left: 6px;
    }
  `,
  RadioButton: styled(Option)`
    margin: 0 auto 16px;
    text-align: center;
  `,
  RadioButtonLabel: styled.span<TRadioButtonProps>`
    position: relative;
    padding-left: 28px;
    color: ${Color.WHITE};
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    text-decoration-line: underline;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      border: 1px solid ${Color.WHITE};
      border-radius: 50%;
      transition: all 0.2s ease-out;

      ${({ isChecked }) =>
        isChecked &&
        `background-image: url(${checkIcon});
         background-position: center center;
         background-repeat: no-repeat;
         `}
    }
  `,
  Action: styled.div`
    width: 100%;
    transition: all 0.2s ease-out;
    background-color: inherit;
    border-radius: 12px;
    padding: 13px 15px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    color: inherit;
    border: 0;
    cursor: pointer;
    height: 100%;
    display: flex;

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      font-size: 17px;
    }

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      justify-content: center;
    }
  `,
  Terms: styled.div`
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    color: ${Color.WHITE};
    margin: 0 auto 18px;

    a {
      color: #fff;
      text-decoration: underline;
    }

    @media (min-width: ${MediaBreakpoint.MIN_PHONE}px) {
      font-size: 12px;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: ${Color.WHITE};
    margin: 0 5px;
    vertical-align: middle;
  `,
  Footnote: styled.p`
    max-width: 318px;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    margin: 0 auto 27px;
  `,
}
