import { CDN_FOLDER_LINK, Cohort, UserAge } from 'root-constants'

const IMAGE_PATH = '/images/girl-age'

export const QUESTION = 'Age'

export const AGE_OPTION_VALUES_MAP = {
  [UserAge.TWENTY]: '18-29',
  [UserAge.THIRTY]: '30-39',
  [UserAge.FOURTY]: '40-49',
  [UserAge.FIFTY]: '50-59',
  [UserAge.SIXTY]: '60-69',
  [UserAge.SEVENTY]: '70-79',
}

export const AGE_OPTIONS = [
  {
    value: UserAge.TWENTY,
    text: '18-29',
    image: `${CDN_FOLDER_LINK}${IMAGE_PATH}_18-29.png`,
  },
  {
    value: UserAge.THIRTY,
    text: '30-39',
    image: `${CDN_FOLDER_LINK}${IMAGE_PATH}_30-39.png`,
  },
  {
    value: UserAge.FOURTY,
    text: '40-49',
    image: `${CDN_FOLDER_LINK}${IMAGE_PATH}_40-49.png`,
  },
  {
    value: UserAge.FIFTY,
    text: '50+',
    image: `${CDN_FOLDER_LINK}${IMAGE_PATH}_50.png`,
  },
]

export const AGE_OPTIONS_ES = [
  {
    value: UserAge.TWENTY,
    text: '18-29',
    image: `${CDN_FOLDER_LINK}${IMAGE_PATH}_18-29_es.png`,
  },
  {
    value: UserAge.THIRTY,
    text: '30-39',
    image: `${CDN_FOLDER_LINK}${IMAGE_PATH}_30-39_es.png`,
  },
  {
    value: UserAge.FOURTY,
    text: '40-49',
    image: `${CDN_FOLDER_LINK}${IMAGE_PATH}_40-49_es.png`,
  },
  {
    value: UserAge.FIFTY,
    text: '50+',
    image: `${CDN_FOLDER_LINK}${IMAGE_PATH}_50_es.png`,
  },
]

export const SENIOR_AGE_OPTIONS = [
  {
    value: UserAge.FOURTY,
    text: '35-44',
    image: `${CDN_FOLDER_LINK}${IMAGE_PATH}_35-44.png`,
  },
  {
    value: UserAge.FIFTY,
    text: '45-54',
    image: `${CDN_FOLDER_LINK}${IMAGE_PATH}_45-54.png`,
  },
  {
    value: UserAge.SIXTY,
    text: '55-64',
    image: `${CDN_FOLDER_LINK}${IMAGE_PATH}_55-64.png`,
  },
  {
    value: UserAge.SEVENTY,
    text: '65+',
    image: `${CDN_FOLDER_LINK}${IMAGE_PATH}_65.png`,
  },
]

export const LIKE_CHAT_AGE_OPTIONS = [
  {
    value: UserAge.TWENTY,
    text: '18-29',
  },
  {
    value: UserAge.THIRTY,
    text: '30-39',
  },
  {
    value: UserAge.FOURTY,
    text: '40-49',
  },
  {
    value: UserAge.FIFTY,
    text: '50-59',
  },
  {
    value: UserAge.SIXTY,
    text: '60-69',
  },
  {
    value: UserAge.SEVENTY,
    text: '70+',
  },
]

export const AGE_ANSWERS_MAP = {
  [UserAge.TWENTY]: '18-29',
  [UserAge.THIRTY]: '30-39',
  [UserAge.FOURTY]: '40-49',
  [UserAge.FIFTY]: '50-59',
  [UserAge.SIXTY]: '60-69',
  [UserAge.SEVENTY]: '70+',
}

export const INTRO_PAGE_WITH_BACKGROUND_IMAGE_COHORTS = [
  Cohort.LUVLY_INTRO_FAST,
  Cohort.LUVLY_INTRO_SALE,
  Cohort.LUVLY_14,
  Cohort.LUVLY_12,
  Cohort.LUVLY_INTRO3_SALE,
  Cohort.LUVLY_W2W,
]
