/* eslint-disable max-lines */
import { getCurrentOnboardingVariant } from 'helpers/getCurrentOnboardingVariant'
import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'

import { PaymentMethod, PaymentSystem } from 'modules/purchase/constants'

import { TAnswer } from 'models/common.model'
import { IEvent, IEventLogger, TQuestionPageParams } from 'models/events.model'
import { TProductId } from 'models/subscriptions.model'

import {
  CameraAccess,
  EXPERIMENT_ID_NOT_SET,
  EventLoggerInstanceName,
  FaceScanSkipScreen,
  LoginMethod,
  ML_NO_PROBLEM_AREAS,
  ScreenName,
  UPSELL_PRODUCT_EVENT_NAMES_MAP,
  UpsellProduct,
} from 'root-constants'

export const enum Events {
  SESSION_STARTED = 'session_start',
  FIRST_PAGE_COMPLETED = 'first_page_completed',
  FIRST_PAGE_SCROLLED = 'first_page_scrolled',
  AB_SEGMENT = 'ab_segment',
  QUESTION_COMPLETED = 'question_page_completed',
  EMAIL_PAGE_SHOW = 'email_page_show',
  EMAIL_PAGE_COMPLETED = 'email_page_completed',
  SALE_SCREEN_SHOW = 'plans_page_show',
  IN_APP_SALE_SCREEN_SHOW = 'upsell_guasha_checkout_show',
  TERMS_OF_USE = 'terms_of_use_tap',
  PRIVACY_POLICY = 'privacy_policy_tap',
  NEED_HELP = 'need_help_tap',
  TRIAL_PRICE_PAGE_SHOWN = 'trial_price_page_show',
  TRIAL_PRICE_PAGE_COMPLETED_SHOWN = 'trial_price_page_completed',
  PURCHASE_STARTED_PAYPAL = 'subs_purchase_started_paypal',
  PURCHASE_SHOW = 'subs_purchase_show',
  PAYMENT_METHOD_SELECTED = 'payment_method_selected',
  PURCHASE_STARTED = 'subs_purchase_started',
  PURCHASE_COMPLETED = 'subs_purchase_completed',
  PURCHASE_FAILED = 'subs_purchase_failed',
  PURCHASE_SCREEN_CLOSED = 'subs_purchase_screen_close',
  PLAN_SCREEN_VIDEO_PLAY_STARTED = 'plan_screen_video_play_started',
  PLAN_SCREEN_ARTICLE_SELECTED = 'plan_screen_article_selected',
  PLANS_PAGE_BUTTON_TAP = 'plans_page_button_tap',
  UPSELL_PAGE_BUTTON_TAP = 'upsell_page_button_tap',
  CONFIRMATION_MODAL_SHOWN = 'upsell_confirmation_popup_show',
  CONFIRMATION_MODAL_ACTION = 'upsell_confirmation_popup_answer',
  PLANS_PAGE_SCROLLED = 'plans_page_scrolled',
  UPSELL_PAGE_SCROLLED = 'upsell_page_scrolled',
  UPSELL_PURCHASE_SHOW = 'upsell_purchase_show',
  UPSELL_PURCHASE_CLOSE = 'upsell_purchase_close',
  UPSELL_SUBSCRIPTION_TOGGLE_ENABLE = 'upsell_subscription_toggle_enable',
  UPSELL_SUBSCRIPTION_TOGGLE_DISABLE = 'upsell_subscription_toggle_disable',
  CREATE_ACCOUNT_SHOW = 'create_account_page',
  LOGIN_METHOD_SELECTED = 'login_method_selected',
  ACCOUNT_CREATED = 'account_created',
  ACCOUNT_CREATION_FAILED = 'account_creation_failed',
  LIVE_CHAT_OPENED = 'live_chat_opened',
  LIVE_CHAT_CLOSED = 'live_chat_closed',
  FINISH_ACCOUNT_SCREEN_VIEW = 'finish_account_screen_view',
  DOWNLOAD_BTN_PRESSED = 'download_button_press',
  SUBSCRIPTION_DETAILS_SCREEN_SHOW = 'subscription_details_screen_show',
  CANCEL_INSTRUCTION_SELECTED = 'cancel_instruction_selected',
  CANCEL_REASON_SELECTED = 'cancel_reason_selected',
  SUBSCRIPTION_CANCELLED = 'subscription_cancelled',
  PAYPAL_PAYMENT_POPUP_CLOSE = 'paypal_payment_popup_close',
  CANCEL_OFFER_PAGE_SHOW = 'cancel_offer_page_show',
  CANCEL_OFFER_PAGE_CLOSE = 'cancel_offer_page_close',
  FINISHING_TOUCHES_SCREEN_SHOW = 'finishing_touches_screen_show',
  FINISHING_TOUCHES_SCREEN_CONFIRM = 'finishing_touches_screen_confirm',
  FACE_SETUP_SCREEN_SHOW = 'face_setup_screen_show',
  CAMERA_ACCESS = 'camera_access',
  TAKE_PHOTO_BUTTON_TAPPED = 'take_photo_button_tapped',
  ML_FACE_SCANNING_COMPLETED = 'ml_face_scanning_completed',
  FACE_SCANNING_SKIP = 'face_scanning_skip',
  USER_AGE = 'bi_user_age',
  USER_GENDER = 'bi_user_gender',
  COOKIES_BANNER_SHOW = 'cookies_banner_show',
  COOKIES_BANNER_SETTINGS_TAP = 'cookies_banner_settings_tap',
  COOKIES_ACCEPT_ALL_TAP = 'cookies_accept_all_tap',
  COOKIES_REJECT_ALL_TAP = 'cookies_reject_all_tap',
  COOKIES_SETTINGS_TOGGLE_ENABLE = 'cookies_settings_toggle_enable',
  COOKIES_SETTINGS_TOGGLE_DISABLE = 'cookies_settings_toggle_disable',
  COOKIES_SETTING_CONFIRM_CHOICES_TAP = 'cookies_setting_confirm_choices_tap',
  COOKIES_SETTINGS_SCREEN_CLOSE = 'cookies_settings_screen_close',
  COOKIES_SETTINGS_EXPAND_OPTION_TAP = 'cookies_settings_expand_option_tap',
  WELLNESS_BUNDLE_POPUP_SHOW = 'wellness_bundle_popup_show',
  WELLNESS_BUNDLE_POPUP_CLOSE = 'wellness_bundle_popup_close',
  PLANS_PAGE_CLOSE = 'plans_page_close',
  UPSELL_POPUP_SHOW = 'upsell_popup_show',
  UPSELL_POPUP_CLOSE = 'upsell_popup_close',
  GIFT_WELCOME_PAGE_OPENED = 'gift_welcome_page_opened',
  GIFT_WELCOME_PAGE_SCROLLED = 'gift_welcome_page_scrolled',
  GIFT_WELCOME_PAGE_CONTINUED = 'gift_welcome_page_continue_tap',
  GIFT_CUSTOMIZE_PAGE_CONTINUED = 'gift_customize_page_continue_tap',
  GIFT_PAYWALL_CONTINUED = 'gift_paywall_continue_tap',
  GIFT_PURCHASE_POPUP_CONFIRMED = 'gift_purchase_popup_confirmed',
  GIFT_SHARE_SCREEN_OPENED = 'gift_share_screen_opened',
  GIFT_SHARE_CARD_TAP = 'gift_share_card_tap',
  GIFT_CARD_SHARED = 'gift_card_shared',
  IN_APP_PURCHASE_FAILED = 'inapp_purchase_failed',
  INAPP_PURCHASE_STARTED = 'inapp_purchase_started',
  INAPP_PURCHASE_COMPLETED = 'inapp_purchase_completed',
  PLANS_PAGE_SCREEN_SPIN_TAP = 'plans_page_screen_spin_tap',
  SPIN_RESULTS_POPUP_CLOSE = 'spin_results_popup_close',
  CANCEL_OFFER_POPUP_SHOW = 'cancel_offer_popup_show',
  CANCEL_OFFER_POPUP_CLOSE = 'cancel_offer_popup_close',
}

class EventLoggerService {
  private loggers?: Map<EventLoggerInstanceName, IEventLogger>
  private eventsQueue: IEvent[] = []
  private eventsCache: Map<Events, string> = new Map()

  get isAmplitudeActive() {
    return this.loggers?.has(EventLoggerInstanceName.AMPLITUDE)
  }

  get isGIAActive() {
    return this.loggers?.has(EventLoggerInstanceName.GIA)
  }

  init(...loggers: IEventLogger[]): void {
    const entriesArr = loggers.map(
      (logger) =>
        [logger.name, logger] as [EventLoggerInstanceName, IEventLogger],
    )

    if (!this.loggers) {
      this.loggers = new Map(entriesArr)
      this.notifyInitFinished()

      return
    }

    if (this.loggers) {
      loggers.map((logger) =>
        this.loggers?.set(
          logger.name as EventLoggerInstanceName,
          logger as IEventLogger,
        ),
      )
    }

    this.notifyInitFinished()
  }

  logSessionStarted(linkLocale: string): void {
    const event = Events.SESSION_STARTED
    const eventProperty = {
      browser_locale_info: navigator.language,
      link_locale_info: linkLocale,
      device_type: getMobileOperatingSystem(),
    }
    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logFirstPageCompleted({ question, answers }: TQuestionPageParams): void {
    const eventProperty = {
      question,
      answer: Array.isArray(answers) ? answers.join(',') : answers,
    }
    this.logEventOrPushToQueue(
      Events.FIRST_PAGE_COMPLETED,
      eventProperty,
      false,
    )
  }

  logFirstPageScrolled({
    scrolledPart,
    screenName,
  }: {
    screenName?: ScreenName
    scrolledPart: number
  }): void {
    const event = Events.FIRST_PAGE_SCROLLED
    const eventProperty = {
      screen_name: screenName,
      scrolled_part: `${scrolledPart}%`,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logQuestion({ question, answers }: TQuestionPageParams): void {
    const event = Events.QUESTION_COMPLETED
    const eventProperty = {
      question,
      answer: Array.isArray(answers) ? answers.join(',') : answers,
    }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logGrowthBookAbSegmentName({
    variantId,
    experimentKey,
    variantName,
  }: {
    experimentKey: string
    variantId: string
    variantName: string
  }): void {
    const event = Events.AB_SEGMENT
    const eventProperty = {
      growthbook_feature_key: experimentKey || EXPERIMENT_ID_NOT_SET,
      ab_variant: getCurrentOnboardingVariant(variantId),
      ab_segment_name: variantName || 'no_name',
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPayPalPaymentPopupClose(): void {
    const event = Events.PAYPAL_PAYMENT_POPUP_CLOSE
    this.logEventOrPushToQueue(event)
  }

  // EmailWrapper Page Events
  logEmailPageShown(): void {
    const event = Events.EMAIL_PAGE_SHOW
    this.logEventOrPushToQueue(event, {}, false)
  }

  logEmailPageCompleted(eventProperty: { email: string }): void {
    const event = Events.EMAIL_PAGE_COMPLETED
    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  // Sale Page Events
  logSalePageShown({
    productIds,
    screenName = ScreenName.PURCHASE,
    isAmplitudeEvent = false,
  }: {
    productIds: string[]
    screenName?: ScreenName
    isAmplitudeEvent?: boolean
  }): void {
    const lastLoggedScreen = this.eventsCache.get(Events.SALE_SCREEN_SHOW)

    if (lastLoggedScreen !== screenName) {
      const eventProperty = {
        product_id: productIds.join(','),
        screen_name: screenName,
      }

      this.eventsCache.set(Events.SALE_SCREEN_SHOW, screenName)
      this.logEventOrPushToQueue(
        Events.SALE_SCREEN_SHOW,
        eventProperty,
        isAmplitudeEvent,
      )
    }
  }

  logInAppSalePageShown({ productId }: { productId: string }): void {
    const eventProperty = {
      product_id: productId,
    }

    this.logEventOrPushToQueue(
      Events.IN_APP_SALE_SCREEN_SHOW,
      eventProperty,
      true,
    )
  }

  logTermsOfUseClicked(): void {
    const event = Events.TERMS_OF_USE
    this.logEventOrPushToQueue(event)
  }

  logPrivacyPolicyClicked(): void {
    const event = Events.PRIVACY_POLICY
    this.logEventOrPushToQueue(event)
  }

  logNeedHelpClicked(): void {
    const event = Events.NEED_HELP
    this.logEventOrPushToQueue(event)
  }

  // Login Page events
  logCreateAccountShown(): void {
    const event = Events.CREATE_ACCOUNT_SHOW
    this.logEventOrPushToQueue(event)
  }

  logLoginMethodSelected(eventProperty: { method: LoginMethod }): void {
    const event = Events.LOGIN_METHOD_SELECTED
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logAccountCreated(eventProperty: { method: LoginMethod | null }): void {
    const event = Events.ACCOUNT_CREATED
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logAccountCreationFailed({ error }: { error: string }): void {
    const event = Events.ACCOUNT_CREATION_FAILED
    const eventProperty = {
      error_reason: error,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logFinishingTouchesScreenShow(): void {
    const event = Events.FINISHING_TOUCHES_SCREEN_SHOW
    this.logEventOrPushToQueue(event)
  }

  logFinishingTouchesScreenConfirm({
    name,
    age,
    skinType,
    targetAreas,
    sex,
  }: {
    name: TAnswer
    age: TAnswer
    skinType: TAnswer
    targetAreas: TAnswer
    sex?: TAnswer
  }): void {
    const event = Events.FINISHING_TOUCHES_SCREEN_CONFIRM
    const eventProperty = {
      name,
      age,
      skin_type: skinType,
      target_areas: targetAreas,
      ...(!!sex && { sex }),
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  // Getting App Page Events
  logGettingAppShown(): void {
    const event = Events.FINISH_ACCOUNT_SCREEN_VIEW
    this.logEventOrPushToQueue(event)
  }

  logDownloadClicked(callback: () => void): void {
    const event = Events.DOWNLOAD_BTN_PRESSED
    this.logEventOrPushToQueue(event, {}, true, callback)
  }

  logLiveChatOpened(): void {
    this.logEventOrPushToQueue(Events.LIVE_CHAT_OPENED)
  }

  logLiveChatClosed(): void {
    this.logEventOrPushToQueue(Events.LIVE_CHAT_CLOSED)
  }

  // Purchase Page events
  logCancelOfferPageShown(): void {
    const event = Events.CANCEL_OFFER_PAGE_SHOW
    this.logEventOrPushToQueue(event)
  }

  logCancelOfferPageClosed(): void {
    const event = Events.CANCEL_OFFER_PAGE_CLOSE
    this.logEventOrPushToQueue(event)
  }

  logVideoPlayStarted(): void {
    const event = Events.PLAN_SCREEN_VIDEO_PLAY_STARTED
    this.logEventOrPushToQueue(event)
  }

  logArticleSelected(articleName: string): void {
    const event = Events.PLAN_SCREEN_ARTICLE_SELECTED
    const eventProperty = {
      article_name: articleName,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPlanPageButtonClicked({
    screenName,
    buttonNumber,
    buttonText,
  }: {
    screenName: ScreenName
    buttonNumber: number
    buttonText: string
  }): void {
    const event = Events.PLANS_PAGE_BUTTON_TAP
    const eventProperty = {
      screen_name: screenName,
      button_number: buttonNumber,
      button_text: buttonText,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPlanPageViewScroll({
    screenName,
    scrolledPart,
  }: {
    screenName: ScreenName
    scrolledPart: number
  }): void {
    const event = Events.PLANS_PAGE_SCROLLED
    const eventProperty = {
      screen_name: screenName,
      scrolled_part: `${scrolledPart}%`,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logUpsellPageViewScroll({
    screenName,
    scrolledPart,
  }: {
    screenName: ScreenName
    scrolledPart: number
  }): void {
    const event = Events.UPSELL_PAGE_SCROLLED
    const eventProperty = {
      screen_name: screenName,
      scrolled_part: `${scrolledPart}%`,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPurchaseShown({
    productId,
    screenName = ScreenName.PURCHASE,
    stripeAccountId,
    stripeAccountName,
  }: {
    productId: TProductId
    screenName?: ScreenName
    stripeAccountId: string
    stripeAccountName: string
  }): void {
    const event = Events.PURCHASE_SHOW
    const eventProperty = {
      product_id: productId,
      screen_name: screenName,
      stripe_account_id: stripeAccountId,
      stripe_account_name: stripeAccountName,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPaymentMethodSelected(paymentMethod: PaymentMethod): void {
    const event = Events.PAYMENT_METHOD_SELECTED
    const eventProperty = {
      payment_method: paymentMethod,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logUserAgeSelected(age: string): void {
    const event = Events.USER_AGE
    const eventProperty = {
      age,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logUserGenderSelected(gender: string): void {
    const event = Events.USER_GENDER
    const eventProperty = {
      gender,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPurchaseStarted({
    productId,
    priceDetails: { price, trial = false, currency },
    paymentMethod,
    paymentSystem,
    screenName,
    stripeAccountName,
    stripeAccountId,
    isUpgraded = false,
    amountToPay,
    productName,
  }: {
    productId: TProductId
    priceDetails: { price: number; trial?: boolean; currency: string }
    paymentMethod: PaymentMethod
    paymentSystem?: PaymentSystem
    screenName: ScreenName
    stripeAccountName: string
    stripeAccountId: string
    isUpgraded?: boolean
    amountToPay?: string
    productName?: UpsellProduct
  }): void {
    const event = Events.PURCHASE_STARTED
    const eventProperty = {
      trial,
      price,
      currency,
      product_id: productId,
      payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
      screen_name: screenName,
      stripe_account_name: stripeAccountName,
      stripe_account_id: stripeAccountId,
      ...(paymentSystem && {
        payment_system: paymentSystem,
      }),
      ...(isUpgraded && {
        is_upgraded: isUpgraded,
        amount_to_pay: amountToPay,
      }),
      ...(productName && {
        product_name: UPSELL_PRODUCT_EVENT_NAMES_MAP[productName],
      }),
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPurchaseCompleted({
    productId,
    priceDetails: { price, trial = false, currency },
    paymentMethod,
    paymentSystem,
    discountApplied,
    screenName,
    stripeAccountName,
    stripeAccountId,
    utmSource,
    isUpgraded = false,
    amountToPay,
    productName,
  }: {
    productId: TProductId
    priceDetails: { price: number; trial?: boolean; currency: string }
    paymentMethod?: PaymentMethod
    discountApplied?: string
    screenName: ScreenName
    stripeAccountName: string
    stripeAccountId: string
    utmSource?: string
    isUpgraded?: boolean
    amountToPay?: string
    paymentSystem?: PaymentSystem
    productName?: UpsellProduct
  }): void {
    const event = Events.PURCHASE_COMPLETED
    const eventProperty = {
      trial,
      price,
      currency,
      product_id: productId,
      payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
      screen_name: screenName,
      stripe_account_name: stripeAccountName,
      stripe_account_id: stripeAccountId,
      ...(paymentSystem && {
        payment_system: paymentSystem,
      }),
      ...(discountApplied && { discount_applied: discountApplied }),
      ...(utmSource && { utm_source: utmSource }),
      ...(isUpgraded && {
        is_upgraded: isUpgraded,
        amount_to_pay: amountToPay,
      }),
      ...(productName && {
        product_name: UPSELL_PRODUCT_EVENT_NAMES_MAP[productName],
      }),
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPurchaseFailed({
    productId,
    priceDetails: { price, trial = false, currency },
    error: { description, type, code },
    paymentMethod,
    screenName,
    isUpgraded = false,
    amountToPay,
    stripeAccountName,
    stripeAccountId,
    paymentSystem,
    productName,
  }: {
    productId: TProductId
    priceDetails: { price: number; trial?: boolean; currency: string }
    error: { type: string; description?: string; code?: string }
    paymentMethod?: PaymentMethod
    screenName: ScreenName
    isUpgraded?: boolean
    amountToPay?: string
    stripeAccountName: string
    stripeAccountId: string
    paymentSystem?: PaymentSystem
    productName?: UpsellProduct
  }): void {
    const event = Events.PURCHASE_FAILED
    const eventProperty = {
      trial,
      price,
      currency,
      error_type: type,
      ...(description && { error_description: description }),
      ...(code && { error_code: code }),
      ...(paymentSystem && { payment_system: paymentSystem }),
      product_id: productId,
      screen_name: screenName,
      payment_method: paymentMethod || PaymentMethod.CREDIT_CARD,
      stripe_account_name: stripeAccountName,
      stripe_account_id: stripeAccountId,
      ...(isUpgraded && {
        is_upgraded: isUpgraded,
        amount_to_pay: amountToPay,
      }),
      ...(productName && {
        product_name: UPSELL_PRODUCT_EVENT_NAMES_MAP[productName],
      }),
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logPurchaseScreenClosed({
    productId,
    screenName,
  }: {
    productId: TProductId
    screenName: ScreenName
  }): void {
    const event = Events.PURCHASE_SCREEN_CLOSED
    const eventProperty = {
      product_id: productId,
      screen_name: screenName,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logTrialPricePageShown(): void {
    const event = Events.TRIAL_PRICE_PAGE_SHOWN
    this.logEventOrPushToQueue(event)
  }

  logTrialPricePageCompleted(trialPrice: number, currency: string): void {
    const event = Events.TRIAL_PRICE_PAGE_COMPLETED_SHOWN
    const eventProperty = {
      trial_price: trialPrice,
      currency,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logSpinAndWinClicked(attemptNumber: number): void {
    this.logEventOrPushToQueue(Events.PLANS_PAGE_SCREEN_SPIN_TAP, {
      attempt_number: attemptNumber,
    })
  }

  logSpinResultsPopupClose(attemptNumber: number): void {
    this.logEventOrPushToQueue(Events.SPIN_RESULTS_POPUP_CLOSE, {
      attempt_number: attemptNumber,
    })
  }

  logCancelOfferPopupShow(): void {
    const event = Events.CANCEL_OFFER_POPUP_SHOW
    this.logEventOrPushToQueue(event)
  }

  logCancelOfferPopupClose(action: 'great' | 'skip'): void {
    const event = Events.CANCEL_OFFER_POPUP_CLOSE
    const eventProperty = {
      answer: action,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  // Upsell flow events

  logUpsellPurchaseShown(screenName: ScreenName): void {
    const event = Events.UPSELL_PURCHASE_SHOW
    const eventProperty = {
      screen_name: screenName,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logUpsellPurchaseClose(screenName: ScreenName): void {
    const event = Events.UPSELL_PURCHASE_CLOSE
    const eventProperty = {
      screen_name: screenName,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logUpsellSubscriptionEnable(subscriptionName: string) {
    const event = Events.UPSELL_SUBSCRIPTION_TOGGLE_ENABLE
    const eventProperty = {
      subscription_name: subscriptionName,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logUpsellSubscriptionDisable(subscriptionName: string) {
    const event = Events.UPSELL_SUBSCRIPTION_TOGGLE_DISABLE
    const eventProperty = {
      subscription_name: subscriptionName,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logUpsellPageButtonClicked({
    screenName,
    buttonNumber,
    buttonText,
  }: {
    screenName: ScreenName
    buttonNumber: number
    buttonText: string
  }): void {
    const event = Events.UPSELL_PAGE_BUTTON_TAP
    const eventProperty = {
      screen_name: screenName,
      button_number: buttonNumber,
      button_text: buttonText,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logConfirmationModalShown(): void {
    const event = Events.CONFIRMATION_MODAL_SHOWN
    this.logEventOrPushToQueue(event)
  }

  logConfirmationModalAction(action: 'confirm' | 'skip'): void {
    const event = Events.CONFIRMATION_MODAL_ACTION
    const eventProperty = {
      answer: action,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  // Paypal events

  logPurchaseStartedPayPal({
    productId,
    priceDetails: { price, trial = false, currency },
    paymentMethod,
    screenName,
    isUpgraded = false,
    amountToPay,
    paymentSystem,
    accountId,
    accountName,
  }: {
    productId: TProductId
    priceDetails: {
      price: number
      trial?: boolean
      currency: string
    }
    paymentMethod: PaymentMethod
    screenName: ScreenName
    isUpgraded?: boolean
    amountToPay?: string
    paymentSystem: PaymentSystem
    accountId?: string
    accountName?: string
  }): void {
    const event = Events.PURCHASE_STARTED_PAYPAL
    const eventProperty = {
      trial,
      price,
      currency,
      product_id: productId,
      payment_method: paymentMethod || PaymentMethod.PAYPAL,
      payment_system: paymentSystem,
      ...(accountName && { stripe_account_name: accountName }),
      ...(accountId && { stripe_account_id: accountId }),
      screen_name: screenName,
      ...(isUpgraded && {
        is_upgraded: isUpgraded,
        amount_to_pay: amountToPay,
      }),
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  // In app events

  logWellnessBundleModalOpened() {
    this.logEventOrPushToQueue(Events.WELLNESS_BUNDLE_POPUP_SHOW)
  }

  logWellnessBundleModalClosed() {
    this.logEventOrPushToQueue(Events.WELLNESS_BUNDLE_POPUP_CLOSE)
  }

  logPlansPageClose(screenName: ScreenName) {
    this.logEventOrPushToQueue(Events.PLANS_PAGE_CLOSE, {
      screen_name: screenName,
    })
  }

  logUpsellModalOpened() {
    this.logEventOrPushToQueue(Events.UPSELL_POPUP_SHOW)
  }

  logUpsellModalClosed() {
    this.logEventOrPushToQueue(Events.UPSELL_POPUP_CLOSE)
  }

  // ML feature events
  logFaceSetupScreenShown(): void {
    this.logEventOrPushToQueue(Events.FACE_SETUP_SCREEN_SHOW, {}, false)
  }

  logCameraAccess(accessStatus: CameraAccess) {
    this.logEventOrPushToQueue(
      Events.CAMERA_ACCESS,
      {
        status: accessStatus,
      },
      false,
    )
  }

  logPhotoMade() {
    this.logEventOrPushToQueue(
      Events.TAKE_PHOTO_BUTTON_TAPPED,
      {
        attempt_result: 'success',
        attempt_count: 1,
        error: '',
      },
      false,
    )
  }

  logFaceScanCompleted(problematicAreas: string[]) {
    this.logEventOrPushToQueue(
      Events.ML_FACE_SCANNING_COMPLETED,
      {
        defects: !problematicAreas.length
          ? ML_NO_PROBLEM_AREAS
          : problematicAreas?.join(','),
      },
      false,
    )
  }

  logFaceScanSkipped(screen: FaceScanSkipScreen) {
    this.logEventOrPushToQueue(
      Events.FACE_SCANNING_SKIP,
      {
        screen,
      },
      false,
    )
  }

  // In app flow

  logInAppPurchaseStarted({
    price,
    currency,
    productId,
    screenName,
    paymentMethod = PaymentMethod.CREDIT_CARD,
  }: {
    price: number
    currency: string
    productId?: string
    screenName?: ScreenName
    paymentMethod?: PaymentMethod
  }): void {
    const event = Events.INAPP_PURCHASE_STARTED
    const eventProperty = {
      product_id: productId,
      screen_name: screenName,
      price,
      currency,
      payment_method: paymentMethod,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logInAppPurchaseCompleted({
    price,
    currency,
    productId,
    screenName,
    paymentMethod = PaymentMethod.CREDIT_CARD,
  }: {
    price: number
    currency: string
    productId?: string
    screenName?: ScreenName
    paymentMethod?: PaymentMethod
  }): void {
    const event = Events.INAPP_PURCHASE_COMPLETED
    const eventProperty = {
      product_id: productId,
      screen_name: screenName,
      price,
      currency,
      payment_method: paymentMethod,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logInAppPurchaseFailed({
    error: { description, type, code },
    paymentSystem = PaymentSystem.PRIMER,
    price,
    currency,
    productId,
    paymentMethod = PaymentMethod.CREDIT_CARD,
    screenName,
  }: {
    error: { description?: any; type?: string; code?: number }
    paymentSystem?: PaymentSystem
    price?: number
    currency: string
    productId?: string
    screenName?: ScreenName
    paymentMethod?: PaymentMethod
  }): void {
    const event = Events.IN_APP_PURCHASE_FAILED
    const eventProperty = {
      error_type: type,
      ...(description && { error_description: description }),
      ...(code && { error_code: code }),
      payment_system: paymentSystem,
      ...(price && { price }),
      currency,
      ...(productId && { product_id: productId }),
      payment_method: paymentMethod,
      screen_name: screenName,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  // Gift card flow

  logGiftWelcomePageOpened(): void {
    const event = Events.GIFT_WELCOME_PAGE_OPENED
    this.logEventOrPushToQueue(event)
  }

  logGiftWelcomePageScrolled({ scrolledPart }: { scrolledPart: number }): void {
    const event = Events.GIFT_WELCOME_PAGE_SCROLLED
    const eventProperty = {
      scrolled_part: `${scrolledPart}%`,
    }
    this.logEventOrPushToQueue(event, eventProperty)
  }

  logGiftWelcomePageContinued(): void {
    const event = Events.GIFT_WELCOME_PAGE_CONTINUED
    this.logEventOrPushToQueue(event)
  }

  logGiftCustomizePageContinued(): void {
    const event = Events.GIFT_CUSTOMIZE_PAGE_CONTINUED
    this.logEventOrPushToQueue(event)
  }

  logGiftPaywallContinued(): void {
    const event = Events.GIFT_PAYWALL_CONTINUED
    this.logEventOrPushToQueue(event)
  }

  logGiftPurchasePopupConfirmed(): void {
    const event = Events.GIFT_PURCHASE_POPUP_CONFIRMED
    this.logEventOrPushToQueue(event)
  }

  logGiftShareScreenOpened(): void {
    const event = Events.GIFT_SHARE_SCREEN_OPENED
    this.logEventOrPushToQueue(event)
  }

  logGiftShareCardTap(): void {
    const event = Events.GIFT_SHARE_CARD_TAP
    this.logEventOrPushToQueue(event)
  }

  logGiftCardShared(): void {
    const event = Events.GIFT_CARD_SHARED
    this.logEventOrPushToQueue(event)
  }
  // Cookie consent banner

  logCookiesConsentShown(): void {
    const event = Events.COOKIES_BANNER_SHOW
    this.logEventOrPushToQueue(event, {}, false)
  }

  logCookiesConsentSettingsTap(): void {
    const event = Events.COOKIES_BANNER_SETTINGS_TAP
    this.logEventOrPushToQueue(event, {}, false)
  }

  logCookiesConsentAcceptAllTap(source: string): void {
    const event = Events.COOKIES_ACCEPT_ALL_TAP
    const eventProperty = { source }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logCookiesConsentRejectAllTap(source: string): void {
    const event = Events.COOKIES_REJECT_ALL_TAP
    const eventProperty = { source }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logCookiesConsentToggleEnable(option: string): void {
    const event = Events.COOKIES_SETTINGS_TOGGLE_ENABLE
    const eventProperty = { option }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logCookiesConsentToggleDisable(option: string): void {
    const event = Events.COOKIES_SETTINGS_TOGGLE_DISABLE
    const eventProperty = { option }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logCookiesConsentSettingsConfirmChoice(options: string): void {
    const event = Events.COOKIES_SETTING_CONFIRM_CHOICES_TAP
    const eventProperty = { allowed_options: options }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  logCookiesConsentSettingsScreenClose(): void {
    const event = Events.COOKIES_SETTINGS_SCREEN_CLOSE

    this.logEventOrPushToQueue(event, {}, false)
  }

  logCookiesConsentExpandOptionTap(option: string): void {
    const event = Events.COOKIES_SETTINGS_EXPAND_OPTION_TAP
    const eventProperty = { option }

    this.logEventOrPushToQueue(event, eventProperty, false)
  }

  private logEventOrPushToQueue(
    event: Events,
    eventProperty?: Record<string, any>,
    isAmplitudeEvent = true,
    callback?: () => void,
  ): void {
    if (this.isGIAActive) {
      this.logEvent({
        event,
        isAmplitudeEvent,
        eventProperty,
        callback,
      })
    } else {
      this.eventsQueue.push({ event, eventProperty })
    }
  }

  private notifyInitFinished() {
    if (this.eventsQueue.length) {
      this.eventsQueue.forEach(({ event, eventProperty }) =>
        this.logEvent({
          event,
          eventProperty,
        }),
      )
      this.eventsQueue = []
    }
  }

  private logEvent({
    event,
    eventProperty,
    isAmplitudeEvent = true,
    callback,
  }: {
    event: Events
    eventProperty?: Record<string, any>
    isAmplitudeEvent?: boolean
    callback?: () => void
  }): void {
    if (!isAmplitudeEvent) {
      this.loggers
        ?.get(EventLoggerInstanceName.GIA)
        ?.log(event, eventProperty, callback)

      this.loggers
        ?.get(EventLoggerInstanceName.USER_FLOW_TRACKER)
        ?.log(event, eventProperty, callback)
      return
    }

    if (this.loggers) {
      const eventLoggers = [...this.loggers.values()]

      eventLoggers.forEach((logger, index, array) => {
        index === array.length - 1
          ? logger.log(event, eventProperty, callback)
          : logger.log(event, eventProperty)
      })
    }
  }
}

export const eventLogger = new EventLoggerService()
