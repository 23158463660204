import { DynamicTitles } from 'hooks/useTitleFromUrl'

import {
  COHORT_QUERY_PARAM,
  KEYWORD_PARAM,
  LANG_QUERY_PARAM,
  UUID_QUERY_PARAM,
} from 'root-constants'

export const getPathFromPageId = ({
  pageId,
  cohort,
  uuid,
  language,
  currentSearch,
}: {
  pageId: string
  cohort: string | null
  uuid: string
  language: string
  currentSearch: string
}): string => {
  const keyword = new URLSearchParams(document.location.search).get(
    KEYWORD_PARAM,
  )

  const searchParams = new URLSearchParams(currentSearch)
  const headline = searchParams.get(DynamicTitles.HEADLINE)
  const subtext = searchParams.get(DynamicTitles.SUBTEXT)
  const lang = searchParams.get(LANG_QUERY_PARAM)

  if (!lang && (headline || subtext)) {
    searchParams.delete(DynamicTitles.HEADLINE)
    searchParams.delete(DynamicTitles.SUBTEXT)
  }

  searchParams.delete(COHORT_QUERY_PARAM)
  searchParams.delete(UUID_QUERY_PARAM)
  searchParams.delete(LANG_QUERY_PARAM)

  keyword && searchParams.set(KEYWORD_PARAM, keyword)

  const restParams = searchParams.toString()
    ? `&${searchParams.toString()}`
    : ''

  return `${pageId}?uuid=${uuid}&cohort=${cohort}&lang=${language}${restParams}`
}
