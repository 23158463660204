import { useSelector } from 'react-redux'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useIsCurrentRoute } from 'hooks/useIsCurrentRoute'
import { usePurchaseCancelOfferRoute } from 'hooks/usePurchaseCancelOfferRoute'

import { SubscriptionProduct } from 'root-constants'

import {
  CANCEL_OFFER_ADDITIONAL_DISCOUNT,
  Currency,
  DECORATING_DISCOUNT,
  DEFAULT_DYNAMIC_DISCOUNT,
  INTRO_SALES_COHORTS_DISCOUNT_MAP,
  ONE_HUNDRED_PERCENTAGE,
} from '../constants'
import {
  selectDynamicDiscount,
  selectSubscription,
} from '../redux/selects/common'
import { usePriceWithVatAdjustment } from './usePriceWithVatAdjustment'
import { usePurchaseStore } from './usePurchaseStore'

const INITIAL_VALUE = 0

export const useSummarizedSubscriptionData = () => {
  const selectedSubscription = useSelector(selectSubscription)
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const cohortToUse = useCohortToUse()
  const cancelOfferRoutePageId = usePurchaseCancelOfferRoute()
  const isCancelOfferRoute = useIsCurrentRoute(cancelOfferRoutePageId)
  const { isDynamicDiscountCohort, isLuvlyWheelCohort } =
    useActiveCohortIdentifier()
  const { currency, discountAmount } = usePurchaseStore()
  const { getPriceWithVatAdjustment } = usePriceWithVatAdjustment()

  if (!selectedSubscription) {
    return {
      discountPercentage: INITIAL_VALUE,
      totalAmount: INITIAL_VALUE,
      discountAmount: INITIAL_VALUE,
    }
  }

  const getNormalizedPrice = (discount: number) => {
    const calculatedOldPrice =
      selectedSubscription.trialPrices.fullPrice /
      ((ONE_HUNDRED_PERCENTAGE - discount) / ONE_HUNDRED_PERCENTAGE)

    if (currency === Currency.JPY) {
      return Math.ceil(getPriceWithVatAdjustment(calculatedOldPrice))
    }

    return (
      Math.ceil(getPriceWithVatAdjustment(calculatedOldPrice)) -
      DECORATING_DISCOUNT
    )
  }

  if (isDynamicDiscountCohort && !isLuvlyWheelCohort) {
    const baseDiscountPercentage =
      dynamicDiscount?.amount ||
      INTRO_SALES_COHORTS_DISCOUNT_MAP[cohortToUse] ||
      DEFAULT_DYNAMIC_DISCOUNT
    const appliedDiscount = isCancelOfferRoute
      ? baseDiscountPercentage + CANCEL_OFFER_ADDITIONAL_DISCOUNT
      : baseDiscountPercentage

    const totalAmount = getNormalizedPrice(appliedDiscount)

    return {
      discountPercentage: appliedDiscount,
      totalAmount,
      discountAmount: Number(
        (totalAmount - selectedSubscription.trialPrices.fullPrice).toFixed(2),
      ),
    }
  }

  const totalAmount =
    selectedSubscription.trialPrices.durationDays ===
    SubscriptionProduct.SEVEN_DAY
      ? selectedSubscription.trialPrices.oldPrices.fullPrice
      : selectedSubscription.mainPrices.fullPrice

  const defaultDiscountPercentage = isCancelOfferRoute
    ? DEFAULT_DYNAMIC_DISCOUNT + CANCEL_OFFER_ADDITIONAL_DISCOUNT
    : DEFAULT_DYNAMIC_DISCOUNT

  return {
    discountPercentage:
      selectedSubscription.mainPrices.oldPrices.percentOfDiscount ||
      defaultDiscountPercentage,
    totalAmount: getPriceWithVatAdjustment(totalAmount),
    discountAmount,
  }
}
