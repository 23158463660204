import { IRequestOptions, IResponseResult } from 'models/api.model'

import { ApiService } from 'services/api.service'

import { APP_NAME, RequestMethod } from 'root-constants'

export class WellhubApi {
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  activatePromoCode({
    uuid,
    promoCode,
  }: {
    uuid: string
    promoCode: string
  }): Promise<IResponseResult<never>> {
    const options: IRequestOptions = {
      method: RequestMethod.POST,
      body: {
        code: promoCode,
      },
    }

    return this.api.makeRequest<never>(
      `v2/wellhub/${uuid}/${APP_NAME}/activate`,
      options,
    )
  }
}
