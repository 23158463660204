import { useMemo } from 'react'

import { Cohort, DYNAMIC_DISCOUNT_COHORTS } from 'root-constants'

import { useCohortToUse } from './useCohortToUse'

export const useActiveCohortIdentifier = () => {
  const cohortToUse = useCohortToUse()
  const {
    isCosmeticCohort,
    isLuvlyIntroFastCohort,
    isSeniorCohort,
    isWellnessBundleCohort,
    isLuvly14Cohort,
    isChatCohort,
    isUpgradeBundleCohort,
    isDynamicDiscountCohort,
    isIntro3SaleCohort,
    isRefundCohort,
    isLuvlyIntroSaleCohort,
    isLuvly12SaleCohort,
    isLuvly12Cohort,
    isLuvlyCancelIntro1Cohort,
    isLuvlyChinCohort,
    isLuvlyEmailSaleCohort,
    isLuvlyInfluencersCohort,
    isLuvlyW2WCohort,
  } = useMemo(
    () => ({
      isLuvlyIntroFastCohort: cohortToUse === Cohort.LUVLY_INTRO_FAST,
      isCosmeticCohort: cohortToUse === Cohort.LUVLY_COSMETIC_SCANNER,
      isSeniorCohort:
        cohortToUse === Cohort.LUVLY_12 || cohortToUse === Cohort.LUVLY_12_SALE,
      isWellnessBundleCohort:
        cohortToUse === Cohort.LUVLY_CHAT_UPGRADE_WELLNESS_BUNDLE,
      isLuvly14Cohort: cohortToUse === Cohort.LUVLY_14,
      isChatCohort: cohortToUse === Cohort.LUVLY_CHAT,
      isUpgradeBundleCohort:
        cohortToUse === Cohort.LUVLY_CHAT_UPGRADE_BUNDLE_PDF,
      isDynamicDiscountCohort: DYNAMIC_DISCOUNT_COHORTS.includes(cohortToUse),
      isLuvlyIntroSaleCohort: cohortToUse === Cohort.LUVLY_INTRO_SALE,
      isLuvly12Cohort: cohortToUse === Cohort.LUVLY_12,
      isLuvly12SaleCohort: cohortToUse === Cohort.LUVLY_12_SALE,
      isIntro3SaleCohort: cohortToUse === Cohort.LUVLY_INTRO3_SALE,
      isRefundCohort: cohortToUse === Cohort.LUVLY_REF,
      isLuvlyCancelIntro1Cohort: cohortToUse === Cohort.LUVLY_CANCEL_INTRO_1,
      isLuvlyChinCohort: cohortToUse === Cohort.LUVLY_CHIN,
      isLuvlyEmailSaleCohort: cohortToUse === Cohort.LUVLY_EMAIL_SALE,
      isLuvlyInfluencersCohort: cohortToUse === Cohort.LUVLY_INFLUENCERS,
      isLuvlyW2WCohort: cohortToUse === Cohort.LUVLY_W2W,
    }),
    [cohortToUse],
  )
  return {
    isCosmeticCohort,
    isLuvlyIntroFastCohort,
    isSeniorCohort,
    isWellnessBundleCohort,
    isLuvly14Cohort,
    isChatCohort,
    isUpgradeBundleCohort,
    isDynamicDiscountCohort,
    isIntro3SaleCohort,
    isRefundCohort,
    isLuvly12SaleCohort,
    isLuvly12Cohort,
    isLuvlyIntroSaleCohort,
    isLuvlyCancelIntro1Cohort,
    isLuvlyChinCohort,
    isLuvlyEmailSaleCohort,
    isLuvlyInfluencersCohort,
    isLuvlyW2WCohort,
  }
}
