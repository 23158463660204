import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledDoubleChin = {
  Root: styled.div``,
  Hero: styled.div`
    aspect-ratio: 343/188;
    margin-bottom: 16px;
  `,
  Title: styled.h1`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: 16px;
    color: ${Color.BLUE_DARK};
    font-family: 'Open Sans';
  `,
  Steps: styled.ul`
    margin-bottom: 31px;
  `,
  Step: styled.li`
    display: flex;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 16px;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }

    & svg {
      margin-right: 8px;
      flex-shrink: 0;
    }
  `,
}
