import React from 'react'

import { LinearProgressBar } from 'components/LinearProgressBar'
import { OnboardingProgressBar } from 'components/OnboardingProgressBar'
import { PostPaymentProgressBar } from 'components/PostPaymentProgressBar'

import { useABTest } from 'hooks/useABTest'
import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useGetPageInfo } from 'hooks/useGetPageInfo'

import { MINIMALISTIC_HEADER_COHORTS } from './constants'

export const ProgressBar: React.FC = () => {
  const { hasProgressBar, hasSubscription } = useGetPageInfo()
  const cohortToUse = useCohortToUse()
  const { isLuvlyIntroFastCohort, isLuvlyEmailCoaching } =
    useActiveCohortIdentifier()
  const { isIntroFastCoralThemeActive } = useABTest()

  const isMinimalisticHeaderCohort =
    MINIMALISTIC_HEADER_COHORTS.includes(cohortToUse) ||
    (isLuvlyIntroFastCohort && isIntroFastCoralThemeActive)

  if (isLuvlyEmailCoaching) return null

  if (isMinimalisticHeaderCohort) {
    return (
      <>
        {hasProgressBar && <LinearProgressBar />}
        {hasSubscription && <PostPaymentProgressBar />}
      </>
    )
  }

  return hasSubscription ? (
    <PostPaymentProgressBar />
  ) : (
    <OnboardingProgressBar />
  )
}
