import { useContext } from 'react'

import styled, { ThemeContext, css } from 'styled-components'

import { DynamicDiscountTheme } from 'root-constants'

type TTheme = {
  theme: DynamicDiscountTheme
}

export const StyledLongPaywallStaticBanner = {
  Root: styled.div<TTheme>`
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 16px;

    ${({ theme }) => {
      switch (theme) {
        case DynamicDiscountTheme.CORAL:
          return css`
            background: linear-gradient(
              91deg,
              rgba(255, 83, 67, 0.15) -8.21%,
              rgba(225, 34, 108, 0.15) 95.97%
            );
          `
        case DynamicDiscountTheme.VIOLET:
          return css`
            background: linear-gradient(
              269deg,
              rgba(116, 112, 255, 0.15) 0%,
              rgba(226, 163, 248, 0.15) 125.26%
            );
          `
        case DynamicDiscountTheme.BLACK:
          return css`
            background: linear-gradient(
              91deg,
              rgba(255, 83, 67, 0.15) -8.21%,
              rgba(225, 34, 108, 0.15) 95.97%
            );
          `
        case DynamicDiscountTheme.BLUE:
          return css`
            background: rgba(8, 185, 254, 0.15);
          `
        default:
          return css`
            background: linear-gradient(
              269deg,
              rgba(116, 112, 255, 0.15) 0%,
              rgba(226, 163, 248, 0.15) 125.26%
            );
          `
      }
    }}
  `,
  DiscountLabel: styled.span`
    color: #fffefe;
    text-align: center;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 18px;
    font-style: normal;
    font-weight: ${() =>
      useContext(ThemeContext).staticBannerDiscountLabel.fontWeight};
    line-height: 28px;
    text-transform: uppercase;
    padding: 4px 12px;
    border-radius: 10px;
    margin-right: 16px;
    min-width: 102px;

    ${({ theme }) => {
      switch (theme) {
        case DynamicDiscountTheme.CORAL:
          return css`
            background: linear-gradient(91deg, #ff5343 -8.21%, #e1226c 95.97%);
          `
        case DynamicDiscountTheme.BLACK:
          return css`
            background: linear-gradient(91deg, #ff5343 -8.21%, #e1226c 95.97%);
          `
        case DynamicDiscountTheme.VIOLET:
          return css`
            background: linear-gradient(269deg, #7470ff 0%, #e2a3f8 125.26%);
          `
        case DynamicDiscountTheme.BLUE:
          return css`
            background: linear-gradient(270deg, #554ff2 0%, #00c3ff 103.03%),
              linear-gradient(91deg, #ff5343 -8.21%, #e1226c 95.97%);
          `
        default:
          return css`
            background: linear-gradient(269deg, #7470ff 0%, #e2a3f8 125.26%);
          `
      }
    }}
  `,
  DiscountText: styled.p`
    -webkit-text-fill-color: transparent;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 18px;
    font-style: normal;
    font-weight: ${() =>
      useContext(ThemeContext).staticBannerDiscountLabel.fontWeight};
    line-height: 28px;

    ${({ theme }) => {
      switch (theme) {
        case DynamicDiscountTheme.CORAL:
          return css`
            background: -webkit-linear-gradient(
              91deg,
              #ff5343 -8.21%,
              #e1226c 95.97%
            );
            -webkit-background-clip: text;
            background-clip: text;
          `
        case DynamicDiscountTheme.BLACK:
          return css`
            background: -webkit-linear-gradient(
              91deg,
              #ff5343 -8.21%,
              #e1226c 95.97%
            );
            -webkit-background-clip: text;
            background-clip: text;
          `
        case DynamicDiscountTheme.VIOLET:
          return css`
            background: -webkit-linear-gradient(
              269deg,
              #7470ff 0%,
              #e2a3f8 125.26%
            );
            -webkit-background-clip: text;
            background-clip: text;
          `
        case DynamicDiscountTheme.BLUE:
          return css`
            background: #3280f8;
            -webkit-background-clip: text;
            background-clip: text;
          `
        default:
          return css`
            background: -webkit-linear-gradient(
              269deg,
              #7470ff 0%,
              #e2a3f8 125.26%
            );
            -webkit-background-clip: text;
            background-clip: text;
          `
      }
    }}
  `,
}
