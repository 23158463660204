import React from 'react'

import { useCohortToUse } from 'hooks/useCohortToUse'

import { TComponentProps } from 'models/common.model'

import { StyledContainer as S } from './Container.styles'
import {
  DEFAULT_FIELDS,
  TINY_FIELDS_COHORTS,
  WIDE_CONTAINER_COHORTS,
} from './constants'

export type TContainer = {
  fields?: number
  fullHeight?: boolean
  justifyContent?: string
  maxWidth?: number
}

export const Container: React.FC<TComponentProps & TContainer> = ({
  children,
  className = '',
  fields,
  fullHeight,
  justifyContent,
  maxWidth = 360,
}) => {
  const cohortToUse = useCohortToUse()

  return (
    <S.Container
      className={className}
      fields={fields || TINY_FIELDS_COHORTS[cohortToUse] || DEFAULT_FIELDS}
      fullHeight={fullHeight}
      justifyContent={justifyContent}
      maxWidth={WIDE_CONTAINER_COHORTS[cohortToUse] || maxWidth}
    >
      {children}
    </S.Container>
  )
}
