import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Answer, answerTheme } from 'storybook-ui'

import { Container } from 'components/Container'

import { selectAnswers } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { DoubleChinTitle, SandyBackgroundGlobalStyles } from 'common-styles'
import { PageId } from 'page-constants'
import { OptionType, UserGoal } from 'root-constants'

import { BIGGEST_MOTIVATION_ANSWERS_MAP, QUESTION } from './constants'

export const BiggestMotivation: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const userGoal = userAnswers?.[PageId.INTRO] || UserGoal.WRINKLE_FREE_SKIN

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    theme: answerTheme.LUVLY_SOLID,
    disabled: isAnswersDisabled,
    fontWeight: '600',
    onChange: (value: string) => {
      setIsAnswersDisabled(true)
      handleChange(value, handleContinue)
    },
  }

  return (
    <div>
      <Container>
        <DoubleChinTitle>
          {t('onboarding.biggestMotivation.question')}
        </DoubleChinTitle>
        {BIGGEST_MOTIVATION_ANSWERS_MAP[userGoal].map(({ text, label }) => (
          <Answer {...optionProps} key={label} value={label}>
            {t(text)}
          </Answer>
        ))}
        <SandyBackgroundGlobalStyles />
      </Container>
    </div>
  )
}
