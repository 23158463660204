import React, { useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import {
  selectAnswers,
  selectLanguage,
  selectUserGoal,
} from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useIsCurrentRoute } from 'hooks/useIsCurrentRoute'

import { PageId } from 'page-constants'
import {
  CDN_FOLDER_LINK,
  ES_IMAGES_COHORTS,
  Gender,
  I18N_CONTEXT_COHORT_MAP,
  Locale,
  UserGoal,
} from 'root-constants'

import {
  LevelIndication,
  StyledLongPaywallUserTarget as S,
} from './LongPaywallUserTarget.styles'
import {
  ANIMATION_DOUBLE_CHIN_FEMALE,
  ANIMATION_DOUBLE_CHIN_MALE,
  ANIMATION_PATH,
  LEVEL_INDICATION_BLANK,
  USER_GOAL_CONFIG_MAP,
} from './constants'

export const LongPaywallUserTarget: React.FC = () => {
  const { t } = useTranslation()
  const userGoal = useSelector(selectUserGoal)
  const language = useSelector(selectLanguage)
  const userAnswers = useSelector(selectAnswers)
  const isMale = userAnswers?.[PageId.GENDER] === Gender.MALE

  const {
    isLuvlyIntroFastCohort,
    isSeniorCohort,
    isLuvlyIntroSaleCohort,
    isLuvlyChinCohort,
  } = useActiveCohortIdentifier()
  const cohortToUse = useCohortToUse()

  const animationContainerRef = useRef<HTMLDivElement | null>(null)

  const isCancelOfferRoute = useIsCurrentRoute(
    PageId.PURCHASE_INTRO_CANCEL_OFFER,
  )

  const animationPath = useMemo(() => {
    const isEsLocale = language === Locale.SPANISH

    if (isLuvlyChinCohort) {
      return `${CDN_FOLDER_LINK}${
        isMale ? ANIMATION_DOUBLE_CHIN_MALE : ANIMATION_DOUBLE_CHIN_FEMALE
      }.json`
    }

    if (
      isEsLocale &&
      (isLuvlyIntroFastCohort ||
        isLuvlyIntroSaleCohort ||
        ES_IMAGES_COHORTS.includes(cohortToUse))
    ) {
      return `${CDN_FOLDER_LINK}${ANIMATION_PATH}_${language}.json`
    }

    return `${CDN_FOLDER_LINK}${ANIMATION_PATH}_en.json`
  }, [
    language,
    isLuvlyChinCohort,
    isLuvlyIntroFastCohort,
    isLuvlyIntroSaleCohort,
    cohortToUse,
    isMale,
  ])

  useEffect(() => {
    if (animationContainerRef && animationContainerRef.current) {
      lottie.loadAnimation({
        container: animationContainerRef.current,
        path: animationPath,
        loop: true,
      })
    }

    return () => lottie.destroy()
  }, [animationPath])

  const userGoalConfig =
    USER_GOAL_CONFIG_MAP[userGoal || UserGoal.WRINKLE_FREE_SKIN]

  const renderGoal = () => {
    if (isLuvlyChinCohort) {
      return t('purchase8.facialCondition.doubleChinGoal')
    }

    return (
      <Trans
        i18nKey={userGoalConfig.goalText}
        context={I18N_CONTEXT_COHORT_MAP[cohortToUse]}
      />
    )
  }

  return (
    <S.Root>
      {!isSeniorCohort ? (
        <S.AnimationContainer
          data-testid="paywall-target-img"
          ref={animationContainerRef}
          isCancelOfferRoute={isCancelOfferRoute}
        />
      ) : (
        <S.ImageContainer />
      )}
      <S.Wrapper>
        <S.FacialCondition>
          <S.Subtitle>{t('purchase7.facialCondition.nowLabel')}</S.Subtitle>
          <S.LevelContent>
            <S.UserGoal>{renderGoal()}</S.UserGoal>
            <S.LevelIndicationWrapper>
              {LEVEL_INDICATION_BLANK.map((item) => (
                <S.LevelIndicationUnit
                  key={item}
                  type={
                    userGoalConfig.currentLevelIndicator >= item
                      ? LevelIndication.CURRENT
                      : LevelIndication.DEFAULT
                  }
                />
              ))}
            </S.LevelIndicationWrapper>
          </S.LevelContent>
        </S.FacialCondition>
        <S.FacialCondition>
          <S.Subtitle>{t('purchase7.facialCondition.goalLabel')}</S.Subtitle>
          <S.LevelContent>
            <S.UserGoal>{renderGoal()}</S.UserGoal>
            <S.LevelIndicationWrapper>
              {LEVEL_INDICATION_BLANK.map((item) => (
                <S.LevelIndicationUnit
                  key={item}
                  type={
                    userGoalConfig.goalLevelIndicator >= item
                      ? LevelIndication.GOAL
                      : LevelIndication.DEFAULT
                  }
                />
              ))}
            </S.LevelIndicationWrapper>
          </S.LevelContent>
        </S.FacialCondition>
      </S.Wrapper>
    </S.Root>
  )
}
