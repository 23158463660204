import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useABTest } from 'hooks/useABTest'
import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useVatInfo } from 'hooks/useHasVatInfo'

import { getCalculatedPriceWithoutVAT } from 'helpers/getCalculatedPriceWithoutVAT'

import { selectTaxAmount } from '../redux/selects/common'

export const usePriceWithVatAdjustment = () => {
  const hasVatInfo = useVatInfo()
  const taxAmount = useSelector(selectTaxAmount)
  const { isRefundCohort } = useActiveCohortIdentifier()
  const { isRefundNormalizedPricingActive } = useABTest()

  const getPriceWithVatAdjustment = useCallback(
    (fullPrice: number) => {
      if (!hasVatInfo || (isRefundCohort && isRefundNormalizedPricingActive)) {
        return fullPrice
      }

      return Number(getCalculatedPriceWithoutVAT(fullPrice, taxAmount))
    },
    [hasVatInfo, isRefundCohort, isRefundNormalizedPricingActive, taxAmount],
  )

  return { getPriceWithVatAdjustment }
}
