import { Cohort } from 'root-constants'
import { SkinType as SkinTypeOption } from 'shared-options'

export const QUESTION = 'How would you describe your skin?'

export const SKIN_TYPE_DEFAULT_OPTIONS = [
  {
    value: SkinTypeOption.NORMAL,
    text: 'onboarding.skinType.normal',
  },
  {
    value: SkinTypeOption.DRY,
    text: 'onboarding.skinType.dry',
  },
  {
    value: SkinTypeOption.OILY,
    text: 'onboarding.skinType.oily',
  },
  {
    value: SkinTypeOption.COMBINATION,
    text: 'onboarding.skinType.combination',
  },
]

export const SKIN_TYPE_COSMETIC_SCANNER_OPTIONS = [
  {
    value: SkinTypeOption.NORMAL,
    text: 'onboarding.skinType.normal_cosmetic',
  },
  {
    value: SkinTypeOption.DRY,
    text: 'onboarding.skinType.dry_cosmetic',
  },
  {
    value: SkinTypeOption.OILY,
    text: 'onboarding.skinType.oily_cosmetic',
  },
  {
    value: SkinTypeOption.COMBINATION,
    text: 'onboarding.skinType.combination_cosmetic',
  },
]

export const SKIN_TYPE_OPTIONS_MAP = {
  [Cohort.LUVLY_COSMETIC_SCANNER]: SKIN_TYPE_COSMETIC_SCANNER_OPTIONS,
}

export const SKIN_TYPE_VARIANT_2_ANSWERS = [
  {
    value: SkinTypeOption.NORMAL,
    label: 'onboarding.skinType.normal',
    detailed: 'onboarding.skinType.normalExplanation',
  },
  {
    value: SkinTypeOption.DRY,
    label: 'onboarding.skinType.dry',
    detailed: 'onboarding.skinType.dryExplanation',
  },
  {
    value: SkinTypeOption.OILY,
    label: 'onboarding.skinType.oily',
    detailed: 'onboarding.skinType.oilyExplanation',
  },
  {
    value: SkinTypeOption.COMBINATION,
    label: 'onboarding.skinType.combination',
    detailed: 'onboarding.skinType.combinationExplanation',
  },
]
