import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { IntroHeader } from 'components/IntroHeader'
import { IntroHeaderColor } from 'components/IntroHeader/constants'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { useCohortToUse } from 'hooks/useCohortToUse'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'
import { useSendIntroPageCompletedAnalytics } from 'hooks/useSendIntroPageCompletedAnalytics'
import { useStartSession } from 'hooks/useStartSession'
import { useTitleFromUrl } from 'hooks/useTitleFromUrl'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { PageId } from 'page-constants'
import { I18N_CONTEXT_COHORT_MAP, OptionType } from 'root-constants'

import { OPTION_VALUES, QUESTION } from '../constants'
import { StyledIntroVariantRefund as S } from './IntroVariantRefund.styles'

export const IntroVariantRefund: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const cohortToUse = useCohortToUse()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const { sendIntroPageCompletedAnalytics } =
    useSendIntroPageCompletedAnalytics()

  useStartSession()
  const { headline } = useTitleFromUrl()

  const handleContinue = useNextStep({
    pageId: PageId.INTRO,
    question: QUESTION,
    nextPagePath,
  })

  const handleAnswerChange = useCallback(
    (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
    [handleChange, handleContinue, setIsAnswersDisabled],
  )

  const optionProps = useMemo(
    () => ({
      name: pageId,
      type: OptionType.RADIO,
      disabled: isAnswersDisabled,
      withoutMargin: true,
      onChange: (value: string) => {
        sendIntroPageCompletedAnalytics()

        eventLogger.logFirstPageCompleted({
          question: QUESTION,
          answers: value,
        })

        handleAnswerChange(value)
      },
    }),
    [
      pageId,
      isAnswersDisabled,
      sendIntroPageCompletedAnalytics,
      handleAnswerChange,
    ],
  )

  return (
    <S.Root>
      <IntroHeader colorTheme={IntroHeaderColor.TRANSPARENT} />
      <S.Container>
        <div>
          <S.Title>
            {headline || (
              <Trans
                i18nKey="onboarding.introRefund.title"
                context={I18N_CONTEXT_COHORT_MAP[cohortToUse]}
              />
            )}
          </S.Title>
          <S.Subtitle>{t`onboarding.introRefund.subtitle`}</S.Subtitle>
          <S.Actions>
            <S.StyledOption {...optionProps} value={OPTION_VALUES.GET_RID}>
              <S.Action>{t('onboarding.goal.getRid2')}</S.Action>
            </S.StyledOption>
            <S.StyledOption {...optionProps} value={OPTION_VALUES.REDUCE_FAT}>
              <S.Action>{t('onboarding.goal.reduceFat')}</S.Action>
            </S.StyledOption>
            <S.StyledOption {...optionProps} value={OPTION_VALUES.BOTH_OPTIONS}>
              <S.Action>
                {t('onboarding.goal.bothOptions', {
                  context: I18N_CONTEXT_COHORT_MAP[cohortToUse],
                })}
              </S.Action>
            </S.StyledOption>
          </S.Actions>
          <S.Terms>
            <p>{t`onboarding.intro.termsTitle`}</p>
            <TermsOfUseLink />
            <S.Separator />
            <PrivacyPolicyLink />
          </S.Terms>
        </div>
      </S.Container>
    </S.Root>
  )
}
