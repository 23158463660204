import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledCustomerReviewsCoral = {
  TitleContainer: styled.div`
    width: 100%;
    margin-bottom: 8px;
    padding: 12px;
    background-color: ${Color.GRAY_LIGHT};
    border-radius: 8px;
  `,
  Title: styled.h2`
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    color: ${Color.GRAY};
  `,
  ViewMoreLinkContainer: styled.div`
    width: 100%;
    text-align: right;
  `,
  ViewMoreLink: styled.span`
    font-size: 15px;
    line-height: 20px;
    font-weight: 700;
    color: ${Color.CORAL};
    letter-spacing: 0.5px;
    text-transform: uppercase;
    cursor: pointer;
  `,
}
