import styled from 'styled-components'

import { Color } from 'root-constants'

type TSplitProps = {
  isVertical?: boolean
}

export const StyledSubscriptionsIntroOfferBlock = {
  Wrapper: styled.div`
    margin-bottom: 30px;
  `,
  Title: styled.h2`
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: ${Color.BLUE_DARK};
  `,
  Split: styled.div<TSplitProps>`
    display: ${({ isVertical }) => (isVertical ? 'grid' : 'block')};
    grid-template-columns: 106px 106px;
    gap: 20px;
    justify-content: center;
  `,
}
