import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledSubscriptionsWeeklyBlock = {
  Root: styled.div`
    padding: 20px 16px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0px 8px 20px 0px rgba(209, 214, 235, 0.5);
    margin-bottom: 48px;
  `,
  List: styled.ul`
    margin-bottom: 20px;
  `,
  Item: styled.li`
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.DARK};
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    & strong {
      font-weight: 600;
    }

    & svg {
      margin-right: 8px;
      flex-shrink: 0;
    }
  `,
  PriceInfo: styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: ${Color.DARK};
    margin-bottom: 4px;
  `,
  Prices: styled.div`
    display: flex;
  `,
  OldPrice: styled.div`
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    text-decoration: line-through;
    color: ${Color.LIGHT};
    margin-right: 8px;
  `,
  Discount: styled.p`
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    color: ${Color.PRIMARY};
    margin-bottom: 20px;
  `,
  Disclaimer: styled.p`
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    color: ${Color.LIGHT};
    margin-bottom: 20px;
  `,
  Notification: styled.div`
    display: flex;
    align-items: center;
    border-radius: 8px;
    background: #f8f7ff;
    padding: 12px;
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 20px;
    }

    & img {
      margin-right: 12px;
      max-width: 24px;
      max-height: 24px;
    }
  `,
  NotificationTitle: styled.h4`
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    color: ${Color.DARK};
  `,
  NotificationText: styled.h4`
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    color: ${Color.LIGHT};
  `,
}
