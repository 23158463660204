import React from 'react'
import { Trans } from 'react-i18next'

import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { AdaptiveContainer } from 'components/AdaptiveContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import spinnerSvg from 'assets/images/sprite/spinner-gray.svg'

import { StyledPreparePlanV2 as S } from './PreparePlanV2.styles'
import { PREPARE_PLAN_SLIDES, QUESTION, REDIRECT_DELAY } from './constants'

export const PreparePlanV2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextPage = () =>
    setTimeout(() => handleContinue(''), REDIRECT_DELAY)

  return (
    <S.Root>
      <AdaptiveContainer fullWidth>
        <S.SpinnerContainer>
          <S.Circle svg={spinnerSvg} height={55} />
          <S.Text>
            <Trans i18nKey="onboarding.preparePlan.spinnerText" />
          </S.Text>
        </S.SpinnerContainer>
        <Carousel
          spaceBetween={20}
          slidesPerView={1}
          pagination={false}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          onReachEnd={handleNextPage}
        >
          {PREPARE_PLAN_SLIDES.map(({ id, imagePath, textPath }) => (
            <SwiperSlide key={id}>
              <S.ImageContainer>
                <S.Image src={imagePath} alt="slide" />
              </S.ImageContainer>
              <S.SlideText>
                <Trans i18nKey={textPath} />
              </S.SlideText>
            </SwiperSlide>
          ))}
        </Carousel>
      </AdaptiveContainer>
    </S.Root>
  )
}
