import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AnswerButton, AnswerButtonHeight } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { Option } from 'components/Option'
import { PageTitleDescription } from 'components/PageTitleDescription'

import { selectAnswers } from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { PageId } from 'page-constants'
import {
  ALTERNATIVE_GENDER,
  Gender,
  I18N_CONTEXT_COHORT_MAP,
  OptionType,
} from 'root-constants'

import { StyledPracticeDuration as S } from './PracticeDuration.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const PracticeDuration: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const cohortToUse = useCohortToUse()
  const { isSeniorCohort } = useActiveCohortIdentifier()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: (value: string) => {
      handleChange(value, handleContinue)
      setIsAnswersDisabled(true)
    },
  }

  const [gender, age] = useMemo(() => {
    const userGender =
      userAnswers?.[PageId.GENDER] === Gender.MALE
        ? t`commonComponents.male`
        : t`commonComponents.female`
    const userAge = userAnswers?.[PageId.AGE] || 'age'

    return [userGender, userAge]
  }, [t, userAnswers])

  return (
    <div>
      <Container>
        <S.TitleContainer isSeniorCohortStyles={isSeniorCohort}>
          <Trans
            i18nKey="onboarding.practiceDuration.question"
            values={{
              gender,
              age,
            }}
            components={[<br />]}
            context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
          />
        </S.TitleContainer>
        {isSeniorCohort && userAnswers?.[PageId.GENDER] !== ALTERNATIVE_GENDER && (
          <S.DescriptionContainer>
            <PageTitleDescription>
              <Trans
                i18nKey="onboarding.practiceDuration.description"
                values={{
                  gender,
                  age,
                }}
              />
            </PageTitleDescription>
          </S.DescriptionContainer>
        )}
        <S.OptionsContainer textAlign="center">
          <Option
            disabled={isAnswersDisabled}
            {...optionProps}
            value={OPTION_VALUES.UP_TO_TEN_MINUTES}
          >
            <AnswerButton
              {...(!isSeniorCohort && { height: AnswerButtonHeight.M })}
            >
              {t`onboarding.practiceDuration.upToTenMinutes`}
            </AnswerButton>
          </Option>
          <Option
            disabled={isAnswersDisabled}
            {...optionProps}
            value={OPTION_VALUES.UP_TO_FIFTEEN_MINUTES}
          >
            <AnswerButton
              {...(!isSeniorCohort && { height: AnswerButtonHeight.M })}
            >
              {t`onboarding.practiceDuration.upToFifteenMinutes`}
            </AnswerButton>
          </Option>
          <Option
            disabled={isAnswersDisabled}
            {...optionProps}
            value={OPTION_VALUES.UP_TO_THIRTY_MINUTES}
          >
            <AnswerButton
              {...(!isSeniorCohort && { height: AnswerButtonHeight.M })}
            >
              {t`onboarding.practiceDuration.upToThirtyMinutes`}
            </AnswerButton>
          </Option>
        </S.OptionsContainer>
      </Container>
    </div>
  )
}
