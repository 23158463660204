import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useGrowthBook } from '@growthbook/growthbook-react'
import { Autoplay, Pagination } from 'swiper'
import { Swiper as Carousel, SwiperSlide } from 'swiper/react'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Spinner } from 'components/Spinner'
import { SvgImage } from 'components/SvgImage'

import { getSubscriptionListAction } from 'root-redux/actions/common'
import { selectGeneralSubscriptionTrialPeriod } from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useABTest } from 'hooks/useABTest'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import { usePricesStatus, usePurchaseStore } from 'modules/purchase/hooks'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import {
  CHARGE_HAIR_GUIDE,
  makeUpsellHairCareAction,
} from 'modules/purchase/redux/actions/upsell'
import {
  selectCurrency,
  selectSubscription,
} from 'modules/purchase/redux/selects/common'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import afterHairCare from 'assets/images/after-hair-care.png'
import beforeHairCare from 'assets/images/before-hair-care.png'
import hero from 'assets/images/hair-care-hero.png'
import paymentImage from 'assets/images/hair-care-payment-image.png'
import guideLabel from 'assets/images/sprite/dizzy.svg'
import greenCheckboxIcon from 'assets/images/sprite/green-checkbox-v2.svg'
import greenStarSolid from 'assets/images/sprite/green-star-solid.svg'
import greenStarSquare from 'assets/images/sprite/green-star-square.svg'
import redCloseIcon from 'assets/images/sprite/solid-red-close.svg'

import { browserHistory, goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  Locale,
  ScreenName,
  SubscriptionListType,
  SubscriptionTagsType,
  TrialBillingCycle,
} from 'root-constants'

import { StyledUpsellHairCare as S } from './UpsellHairCare.styles'
import { GUIDE_PROGRAM_PICTURES, STORIES } from './constants'

export const UpsellHairCare: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch: TAppDispatch = useDispatch()
  const growthBook = useGrowthBook()

  const [isPricesStartedFetching, setIsPricesStartedFetching] = useState(false)

  const currency = useSelector(selectCurrency)
  const selectedSubscription = useSelector(selectSubscription)
  const trialPeriod = useSelector(selectGeneralSubscriptionTrialPeriod)

  const { isHairCareGuideActive } = useABTest()
  const { arePricesReady } = usePricesStatus()
  const { subscriptions, fetchingActionsList, language } = usePurchaseStore()

  const isEnglishLang = useMemo(() => language === Locale.ENGLISH, [language])

  const isUpsellInProgress = useMemo(
    () => fetchingActionsList?.includes(CHARGE_HAIR_GUIDE),
    [fetchingActionsList],
  )

  useLayoutEffect(() => {
    if (!growthBook?.ready) return

    if (
      !isEnglishLang ||
      !isHairCareGuideActive ||
      Number(trialPeriod) === TrialBillingCycle.SEVEN_DAYS
    ) {
      browserHistory.replace({
        pathname: nextPagePath || PageId.FINISHING_TOUCHES,
        search,
      })
    }
  }, [
    isHairCareGuideActive,
    search,
    nextPagePath,
    trialPeriod,
    growthBook?.ready,
    isEnglishLang,
  ])

  useLayoutEffect(() => {
    if (!isHairCareGuideActive) return

    dispatch(
      getSubscriptionListAction(
        SubscriptionListType.ONE_TIME,
        `${SubscriptionTagsType.HAIR_GUIDE},${SubscriptionTagsType.NO_TAX}`,
      ),
    )
    setIsPricesStartedFetching(true)
  }, [dispatch, isHairCareGuideActive])

  useEffect(() => {
    const guaShaSubscription =
      subscriptions.find(
        (subscription) => subscription?.type === SubscriptionListType.ONE_TIME,
      ) || subscriptions[0]

    dispatch(setSelectedSubscriptionAction(guaShaSubscription))
  }, [dispatch, subscriptions])

  useEffect(() => {
    if (isPricesStartedFetching && arePricesReady && !subscriptions.length) {
      goTo({
        pathname: nextPagePath || PageId.FINISHING_TOUCHES,
        search,
      })
    }
  }, [
    subscriptions,
    search,
    arePricesReady,
    nextPagePath,
    isPricesStartedFetching,
  ])

  useEffect(() => {
    if (isPricesStartedFetching && arePricesReady && !!subscriptions.length) {
      eventLogger.logUpsellPurchaseShown(ScreenName.UPSELL_HAIR_GUIDE)
    }
  }, [arePricesReady, subscriptions.length, isPricesStartedFetching])

  const handleSkip = useCallback(() => {
    eventLogger.logUpsellPurchaseClose(ScreenName.UPSELL_HAIR_GUIDE)
    goTo({
      pathname: nextPagePath || PageId.FINISHING_TOUCHES,
      search,
    })
  }, [search, nextPagePath])

  const makeUpsell = useCallback(() => {
    dispatch(makeUpsellHairCareAction(ScreenName.UPSELL_HAIR_GUIDE))
  }, [dispatch])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <S.Root>
      <Container fields={16}>
        <S.Hero>
          <img src={hero} alt="" />
        </S.Hero>
        <S.Title>{t('upsellHairCare.title')}</S.Title>
        <S.Subtitle>
          <Trans i18nKey="upsellHairCare.subtitle" components={[<br />]} />
        </S.Subtitle>
        <S.SubscriptionBlock>
          <img src={paymentImage} alt="" />
          <div>
            <S.SubscriptionBlockTitle>
              {t('upsellHairCare.subscriptionBlockTitle')}
            </S.SubscriptionBlockTitle>
            <S.Prices>
              <S.OldPrice>
                {CURRENCY_SYMBOLS[currency]}
                {selectedSubscription?.trialPrices.oldPrices.fullPrice}
              </S.OldPrice>
              <S.Price>
                {CURRENCY_SYMBOLS[currency]}
                {selectedSubscription?.trialPrices.fullPrice}
              </S.Price>
              <S.Discount>
                <Trans
                  i18nKey="upsellHairCare.off"
                  values={{
                    value: selectedSubscription?.trialPrices.discountPercentage,
                  }}
                />
              </S.Discount>
            </S.Prices>
          </div>
        </S.SubscriptionBlock>
        <S.ApprovedBy>{t('upsellHairCare.approvedByExperts')}</S.ApprovedBy>
        <Button onClick={makeUpsell}>{t('upsellHairCare.addToCart')}</Button>
        <S.Deny onClick={handleSkip}>{t('upsellHairCare.noThanks')}</S.Deny>

        <S.Guide>
          <S.GuideIcon>
            <SvgImage svg={guideLabel} width={72} height={72} />
          </S.GuideIcon>
          <S.GuideTitle>{t('upsellHairCare.guideTitle')}</S.GuideTitle>
          <S.GuideSubtitle>{t('upsellHairCare.guideSubtitle')}</S.GuideSubtitle>
          <Carousel
            slidesPerView={1}
            loop
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Autoplay]}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
          >
            {GUIDE_PROGRAM_PICTURES.map((img) => (
              <SwiperSlide key={img}>
                <img src={img} alt="" />
              </SwiperSlide>
            ))}
          </Carousel>
        </S.Guide>

        <S.Benefits>
          <S.BenefitsTitle>
            <Trans
              i18nKey="upsellHairCare.benefitsTitle"
              components={[<strong />]}
            />
          </S.BenefitsTitle>
          <S.BenefitsBeforeAfter>
            <S.BenefitsBefore>
              <S.BenefitsBeforeTitle>
                {t('upsellHairCare.benefitsBeforeTitle')}
              </S.BenefitsBeforeTitle>
              <img src={beforeHairCare} alt="" />
              <ul>
                <S.BenefitsBeforeItem>
                  <SvgImage svg={redCloseIcon} width={16} height={16} />
                  {t('upsellHairCare.benefitsBeforeCons1')}
                </S.BenefitsBeforeItem>
                <S.BenefitsBeforeItem>
                  <SvgImage svg={redCloseIcon} width={16} height={16} />
                  {t('upsellHairCare.benefitsBeforeCons2')}
                </S.BenefitsBeforeItem>
                <S.BenefitsBeforeItem>
                  <SvgImage svg={redCloseIcon} width={16} height={16} />
                  {t('upsellHairCare.benefitsBeforeCons3')}
                </S.BenefitsBeforeItem>
              </ul>
            </S.BenefitsBefore>

            <S.BenefitsAfter>
              <S.BenefitsAfterTitle>
                {t('upsellHairCare.benefitsAfterTitle')}
              </S.BenefitsAfterTitle>
              <img src={afterHairCare} alt="" />
              <ul>
                <S.BenefitsAfterItem>
                  <SvgImage svg={greenCheckboxIcon} width={16} height={16} />
                  {t('upsellHairCare.benefitsBeforePros1')}
                </S.BenefitsAfterItem>
                <S.BenefitsAfterItem>
                  <SvgImage svg={greenCheckboxIcon} width={16} height={16} />
                  {t('upsellHairCare.benefitsBeforePros2')}
                </S.BenefitsAfterItem>
                <S.BenefitsAfterItem>
                  <SvgImage svg={greenCheckboxIcon} width={16} height={16} />
                  {t('upsellHairCare.benefitsBeforePros3')}
                </S.BenefitsAfterItem>
              </ul>
            </S.BenefitsAfter>
          </S.BenefitsBeforeAfter>

          <Button onClick={makeUpsell}>{t('upsellHairCare.addToCart')}</Button>
          <S.Deny onClick={handleSkip}>{t('upsellHairCare.noThanks')}</S.Deny>
        </S.Benefits>
      </Container>
      <S.Stories>
        <Container fields={16}>
          <S.StoriesTitle>
            <Trans
              i18nKey="upsellHairCare.storiesTitle"
              components={[<br />]}
            />
          </S.StoriesTitle>
          <S.StoriesSubtitle>
            <SvgImage svg={greenStarSolid} width={14} height={14} />
            <span>{t('upsellHairCare.rater')}</span>
            <S.StoriesRating>
              <SvgImage svg={greenStarSquare} width={20} height={20} />
              <SvgImage svg={greenStarSquare} width={20} height={20} />
              <SvgImage svg={greenStarSquare} width={20} height={20} />
              <SvgImage svg={greenStarSquare} width={20} height={20} />
              <SvgImage svg={greenStarSquare} width={20} height={20} />
            </S.StoriesRating>
          </S.StoriesSubtitle>
        </Container>

        <Carousel
          slidesPerView={1}
          loop
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          spaceBetween={40}
        >
          {STORIES.map(({ name, date, title, text }) => (
            <SwiperSlide key={name}>
              <S.Story>
                <S.StoryCaption>
                  <div>
                    <SvgImage svg={greenStarSquare} width={20} height={20} />
                    <SvgImage svg={greenStarSquare} width={20} height={20} />
                    <SvgImage svg={greenStarSquare} width={20} height={20} />
                    <SvgImage svg={greenStarSquare} width={20} height={20} />
                    <SvgImage svg={greenStarSquare} width={20} height={20} />
                  </div>
                  <span>{t(date)}</span>
                </S.StoryCaption>
                <S.StoryTeller>{t(name)}</S.StoryTeller>
                <S.StoryTitle>{t(title)}</S.StoryTitle>
                <S.StoryText>{t(text)}</S.StoryText>
              </S.Story>
            </SwiperSlide>
          ))}
        </Carousel>
      </S.Stories>
      {isUpsellInProgress && <Spinner />}
    </S.Root>
  )
}
