import React from 'react'

import { useCohortToUse } from 'hooks/useCohortToUse'

import { StyledCompositeAnswerButton as S } from './CompositeAnswerButton.styles'

type TProps = {
  children?: React.ReactNode
  title: string
}

export const CompositeAnswerButton: React.FC<TProps> = ({
  children,
  title,
}) => {
  const cohortToUse = useCohortToUse()

  return (
    <S.Root cohort={cohortToUse}>
      <S.Content>
        <S.Title>{title}</S.Title>
        <S.Text>{children}</S.Text>
      </S.Content>
    </S.Root>
  )
}
