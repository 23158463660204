import styled from 'styled-components'

import { AnswerButtonWithImage } from 'components/AnswerButtonWithImage'

import checkIcon from 'assets/images/check-white.svg'

import { Color } from 'root-constants'

export const StyledFaceShape = {
  TitleContainer: styled.header`
    margin-bottom: 16px;
  `,
  OptionsContainer: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 16px;
  `,
  OptionsContainerV2: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 11px;
    margin-bottom: 69px;
    max-width: 343px;
    margin: 0 auto;
  `,
  CustomAnswer: styled(AnswerButtonWithImage)`
    padding: 8px 0 44px;
    border: 2px solid transparent;
    box-shadow: 0px 4px 14px 0px rgba(8, 4, 23, 0.03);

    input:checked + & {
      background: #ffefed;
      border: 2px solid ${Color.CORAL};
      border-radius: 20px;
    }

    input:checked + &::after {
      border-color: ${Color.CORAL};
      background: ${Color.CORAL} url(${checkIcon}) no-repeat center;
      background-size: 10px 8.5px;
    }
  `,
}
