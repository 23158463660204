import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

import { TStickyButtonContainer } from './StickyButtonContainer'

type TStickyButtonContainerProps = TStickyButtonContainer & {
  isStuck: boolean
}

export const StyledStickyButtonContainer = {
  Root: styled.div<TStickyButtonContainerProps>`
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    padding: 25px 0;
    background: ${({ customBackground, isStuck }) =>
      customBackground ||
      (isStuck
        ? `linear-gradient(
        180deg,
        rgba(251, 248, 243, 0) 0%,
        ${Color.WHITE} 25%)`
        : `transparent`)};

    z-index: 1;

    ${({ centerMode }) =>
      centerMode &&
      css`
        display: flex;
        justify-content: center;
      `}
  `,
}
