import personalizedFaceSculptingProgram from 'assets/images/personalized-face-sculpting-program.png'
import personalizedTightenSkinProgram from 'assets/images/personalized-tighten-skin-program.png'
import personalizedWrinkleCorrectionProgram from 'assets/images/personalized-wrinkle-correction-program.png'

import { UserGoal } from 'root-constants'

export const QUESTION = 'Welcome to your program'

export const CONTENT = {
  [UserGoal.REDUCE_FACE_FAT]: {
    image: personalizedFaceSculptingProgram,
    goalTitle: 'onboarding.personalizedProgram.titleFaceSculpting',
    goalDescription: 'onboarding.personalizedProgram.descriptionFaceSculpting',
  },
  [UserGoal.TIGHTEN_SKIN]: {
    image: personalizedTightenSkinProgram,
    goalTitle: 'onboarding.personalizedProgram.titleSkinTightening',
    goalDescription: 'onboarding.personalizedProgram.descriptionSkinTightening',
  },
  [UserGoal.WRINKLE_FREE_SKIN]: {
    image: personalizedWrinkleCorrectionProgram,
    goalTitle: 'onboarding.personalizedProgram.titleWrinkleCorrection',
    goalDescription:
      'onboarding.personalizedProgram.descriptionWrinkleCorrection',
  },
}
