import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'

import { TEN_MINUTES } from 'modules/purchase/constants'
import { selectDynamicDiscount } from 'modules/purchase/redux/selects/common'

import { DynamicDiscountTheme, Locale } from 'root-constants'

import { StyledPurchaseTimer as S } from './PurchaseTimer.styles'

type TPurchaseTimerProps = {
  timerRef: React.RefObject<HTMLElement>
}

export const PurchaseTimer: React.FC<TPurchaseTimerProps> = ({
  timerRef,
}: TPurchaseTimerProps) => {
  const { t } = useTranslation()
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const language = useSelector(selectLanguage) as Locale

  const { isDynamicDiscountCohort } = useActiveCohortIdentifier()

  return (
    <S.Container
      theme={
        !isDynamicDiscountCohort
          ? DynamicDiscountTheme.CORAL
          : dynamicDiscount?.theme
      }
    >
      <S.Text locale={language}>
        <Trans i18nKey="purchase7.duplicateTimer" />{' '}
        <strong ref={timerRef}>
          {!timerRef?.current?.hasAttribute('data-value') &&
            TEN_MINUTES.AS_STRING}
        </strong>{' '}
        {t('purchase7.min')}
      </S.Text>
    </S.Container>
  )
}
