import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { GROWTHBOOK_EXPERIMENT } from 'root-constants'

export const useABTest = () => {
  const isPrimerActive = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.LV_27)
  const isChinPageSetVariantBActive = useFeatureIsOn(
    GROWTHBOOK_EXPERIMENT.LV_1113,
  )
  const isRefundNormalizedPricingActive = useFeatureIsOn(
    GROWTHBOOK_EXPERIMENT.LV_1171,
  )
  const isIntroFastCoralThemeActive = useFeatureIsOn(
    GROWTHBOOK_EXPERIMENT.LV_1177,
  )
  const isPrimerFormActive = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.LV_804)
  const isCoachingUpsellActive = useFeatureIsOn(GROWTHBOOK_EXPERIMENT.LV_1274)
  const isPurchaseWithoutConfirmationActive = useFeatureIsOn(
    GROWTHBOOK_EXPERIMENT.LV_1275,
  )
  const isRefPaywallCoffeeBannerActive = useFeatureIsOn(
    GROWTHBOOK_EXPERIMENT.LV_1282,
  )

  return {
    isPrimerActive,
    isChinPageSetVariantBActive,
    isRefundNormalizedPricingActive,
    isIntroFastCoralThemeActive,
    isPrimerFormActive,
    isCoachingUpsellActive,
    isPurchaseWithoutConfirmationActive,
    isRefPaywallCoffeeBannerActive,
  }
}
