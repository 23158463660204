import React from 'react'

import { useABTest } from 'hooks/useABTest'
import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { useOnboardingProgressStepsLength } from 'hooks/useOnboardingProgressStepsLength'

import { StyledLinearProgressBar as S } from './LinearProgressBar.styles'

type TLinearProgressBarProps = {
  progressStatus?: number
}

export const LinearProgressBar: React.FC<TLinearProgressBarProps> = ({
  progressStatus,
}: TLinearProgressBarProps) => {
  const { progressValue } = useGetPageInfo()
  const cohortToUse = useCohortToUse()
  const progressStepsLength = useOnboardingProgressStepsLength()
  const { isLuvlyIntroFastCohort } = useActiveCohortIdentifier()
  const { isIntroFastCoralThemeActive } = useABTest()

  return (
    <S.Wrapper
      cohort={cohortToUse}
      applyCoralTheme={isLuvlyIntroFastCohort && isIntroFastCoralThemeActive}
    >
      <S.Progress
        value={progressValue ?? progressStatus}
        max={progressStepsLength}
        cohort={cohortToUse}
        applyCoralTheme={isLuvlyIntroFastCohort && isIntroFastCoralThemeActive}
      />
    </S.Wrapper>
  )
}
