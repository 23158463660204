import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Answer, answerTheme } from 'storybook-ui'

import { Container } from 'components/Container'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { SandyBackgroundGlobalStyles } from 'common-styles'
import { OptionType } from 'root-constants'

import { StyledStatementQuestion as S } from './StatementQuestion.styles'
import {
  ANSWERS,
  STATEMENT_CONTENT,
  STATEMENT_QUESTIONS_MAP,
} from './constants'

export const StatementQuestionVariant3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: STATEMENT_QUESTIONS_MAP[pageId],
    nextPagePath,
  })

  const handleAnswerChange = (value: string) => {
    handleChange(value, handleContinue)
    setIsAnswersDisabled(true)
  }

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: handleAnswerChange,
    theme: answerTheme.LUVLY_SOLID,
    fontWeight: '600',
  }

  return (
    <S.Root>
      <Container>
        <S.TitleContainer>
          <S.TitleVariant3>
            <Trans i18nKey="commonComponents.statementTitle" />
          </S.TitleVariant3>
          <S.QuestionVarian3>
            {t(STATEMENT_CONTENT[pageId].text)}
          </S.QuestionVarian3>
        </S.TitleContainer>
        <img src={STATEMENT_CONTENT[pageId].image} alt="" />
      </Container>
      <Container>
        <S.OptionsContainer>
          {ANSWERS.map(({ value, label }) => {
            return (
              <Answer
                key={value}
                {...optionProps}
                disabled={isAnswersDisabled}
                value={value}
                contentHorizontalAlign="center"
              >
                {label}
              </Answer>
            )
          })}
        </S.OptionsContainer>
        <S.Scale>
          <span>{t`commonComponents.lowestScaleValue`}</span>
          <span>{t`commonComponents.highestScaleValue`}</span>
        </S.Scale>
      </Container>
      <SandyBackgroundGlobalStyles />
    </S.Root>
  )
}
