import briannaImg from 'assets/images/brianna-before-after-min.jpg'
import jenniferImg from 'assets/images/jennifer-before-after-min.jpg'
import lindaImg from 'assets/images/linda-before-after-min.jpg'
import marissaImg from 'assets/images/marissa-before-after-min.jpg'

export const QUESTION = 'Save 50% with discount code'

export const BEFORE_AFTER_WOMEN_GALLERY = [
  {
    name: 'onboarding.introV2.reviews.marissa',
    img: marissaImg,
  },
  {
    name: 'onboarding.introV2.reviews.brianna',
    img: briannaImg,
  },
  {
    name: 'onboarding.introV2.reviews.linda',
    img: lindaImg,
  },
  {
    name: 'onboarding.introV2.reviews.jennifer',
    img: jenniferImg,
  },
]
