import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button, buttonTheme } from 'storybook-ui'

import { AdaptiveContainer } from 'components/AdaptiveContainer'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { StyledFaceYogaExperts3 as S } from './FaceYogaExpertsVariant3.styles'
import { EXPERTS, QUESTION } from './constants'

export const FaceYogaExpertsVariant3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue('')

  return (
    <AdaptiveContainer>
      <S.Title>
        <Trans i18nKey="onboarding.faceYogaExperts.questionVariant2" />
      </S.Title>
      {EXPERTS.map(({ image, name, description }) => (
        <S.Card>
          <S.CardPictureContainer>
            <S.CardPicture src={image} alt="expert" />
          </S.CardPictureContainer>

          <S.CardInfo>
            <S.ExpertName>{t(name)}</S.ExpertName>
            <S.CardDescription>
              <Trans i18nKey={description} />
            </S.CardDescription>
          </S.CardInfo>
        </S.Card>
      ))}
      <StickyButtonContainer centerMode>
        <Button
          onClick={handleNextClick}
          theme={buttonTheme.LUVLY_SECONDARY}
          maxWidth="488px"
        >
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
    </AdaptiveContainer>
  )
}
