import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button, buttonTheme } from 'storybook-ui'

import { Container } from 'components/Container'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import firstExpertPicture from 'assets/images/face-yoga-expert-1.png'
import secondExpertPicture from 'assets/images/face-yoga-expert-2-rect.png'
import thirdExpertPicture from 'assets/images/face-yoga-expert-3-rect.png'

import {
  DoubleChinTitle,
  SandyBackgroundGlobalStyles,
  StickyButtonContainer,
} from 'common-styles'

import { StyledFaceYogaExperts as S } from './FaceYogaExperts.styles'
import { QUESTION } from './constants'

export const FaceYogaExpertsVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue('')

  return (
    <div>
      <Container>
        <DoubleChinTitle>
          <Trans
            i18nKey="onboarding.faceYogaExperts.questionVariant2"
            components={[<strong />]}
          />
        </DoubleChinTitle>

        <S.Card>
          <S.CardPictureContainer>
            <S.CardPictureV2 src={firstExpertPicture} alt="" />
          </S.CardPictureContainer>

          <S.CardInfo>
            <S.CardTitleV2>{t`onboarding.faceYogaExperts.expertName1`}</S.CardTitleV2>
            <S.CardDescriptionV2>
              {t`onboarding.faceYogaExperts.expertDescription1`}
            </S.CardDescriptionV2>
          </S.CardInfo>
        </S.Card>
        <S.Card>
          <S.CardPictureContainer>
            <S.CardPictureV2 src={secondExpertPicture} alt="" />
          </S.CardPictureContainer>
          <S.CardInfo>
            <S.CardTitleV2>{t`onboarding.faceYogaExperts.expertName2`}</S.CardTitleV2>
            <S.CardDescriptionV2>
              {t`onboarding.faceYogaExperts.expertDescription2`}
            </S.CardDescriptionV2>
          </S.CardInfo>
        </S.Card>
        <S.Card>
          <S.CardPictureContainer>
            <S.CardPictureV2 src={thirdExpertPicture} alt="" />
          </S.CardPictureContainer>
          <S.CardInfo>
            <S.CardTitleV2>{t`onboarding.faceYogaExperts.expertName3`}</S.CardTitleV2>
            <S.CardDescriptionV2>
              <Trans
                i18nKey="onboarding.faceYogaExperts.expertDescription3"
                components={[<br />]}
              />
            </S.CardDescriptionV2>
          </S.CardInfo>
        </S.Card>
      </Container>
      <StickyButtonContainer
        centerMode
        customBackground="linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.0001) 0.25%,
      #ffffff 26.14%
    )"
      >
        <Button onClick={handleNextClick} theme={buttonTheme.LUVLY_SECONDARY}>
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
      <SandyBackgroundGlobalStyles />
    </div>
  )
}
