import faceChangesIcon1 from 'assets/images/face-changes-icon-1.png'
import faceChangesIcon2 from 'assets/images/face-changes-icon-2.png'
import faceChangesIcon3 from 'assets/images/face-changes-icon-3.png'
import faceChangesIcon4 from 'assets/images/face-changes-icon-4.png'
import faceChangesIcon5 from 'assets/images/face-changes-icon-5.png'
import faceChangesIcon6 from 'assets/images/face-changes-icon-6.png'
import faceChangesIcon7 from 'assets/images/face-changes-icon-7.png'
import faceChangesIcon8 from 'assets/images/face-changes-icon-8.png'
import faceChangesIcon9 from 'assets/images/face-changes-icon-9.png'
import faceChangesIcon10 from 'assets/images/face-changes-icon-10.png'
import faceChangesIcon11 from 'assets/images/face-changes-icon-11.png'

import { UserGoal } from 'root-constants'

const FACE_CHANGES_CAUSES_VALUES = {
  AGING_AND_LOSS_ELASTICITY: 'aging_and_loss_elasticity',
  LACK_OF_COLLAGEN: 'lack_of_collagen',
  STRESS_UNHEALTHY_HABITS: 'stress_unhealthy_habits',
  WEIGHT_FLUCTUATIONS: 'weight_fluctuations',
  GENETICS: 'genetics',
  GENETICS_FACIAL_STRUCTURE: 'genetics_facial_structure',
  UNHEALTHY_DIET: 'unhealthy_diet',
  HORMONAL_CHANGES: 'hormonal_changes',
  WEIGHT_GAIN: 'weight_gain',
  STRESS_SLEEP_DEPRIVATION: 'stress_sleep_deprivation',
  AGING: 'aging',
  STRESS_ANXIETY: 'stress_anxiety',
  DEHYDRATION: 'dehydration',
  LACK_OF_SLEEP: 'lack_of_sleep',
  BAD_SKINCARE_ROUTINE: 'bad_skincare_routine',
}

const TIGHTEN_SKIN_ANSWERS = [
  {
    text: 'onboarding.faceChangesCauses.option1',
    label: FACE_CHANGES_CAUSES_VALUES.AGING_AND_LOSS_ELASTICITY,
    icon: faceChangesIcon11,
  },
  {
    text: 'onboarding.faceChangesCauses.option2',
    label: FACE_CHANGES_CAUSES_VALUES.LACK_OF_COLLAGEN,
    icon: faceChangesIcon1,
  },
  {
    text: 'onboarding.faceChangesCauses.option3',
    label: FACE_CHANGES_CAUSES_VALUES.STRESS_UNHEALTHY_HABITS,
    icon: faceChangesIcon2,
  },
  {
    text: 'onboarding.faceChangesCauses.option4',
    label: FACE_CHANGES_CAUSES_VALUES.WEIGHT_FLUCTUATIONS,
    icon: faceChangesIcon3,
  },
  {
    text: 'onboarding.faceChangesCauses.option5',
    label: FACE_CHANGES_CAUSES_VALUES.GENETICS,
    icon: faceChangesIcon4,
  },
]

const REDUCE_FAT_ANSWERS = [
  {
    text: 'onboarding.faceChangesCauses.option6',
    label: FACE_CHANGES_CAUSES_VALUES.GENETICS_FACIAL_STRUCTURE,
    icon: faceChangesIcon5,
  },
  {
    text: 'onboarding.faceChangesCauses.option7',
    label: FACE_CHANGES_CAUSES_VALUES.UNHEALTHY_DIET,
    icon: faceChangesIcon6,
  },
  {
    text: 'onboarding.faceChangesCauses.option8',
    label: FACE_CHANGES_CAUSES_VALUES.HORMONAL_CHANGES,
    icon: faceChangesIcon7,
  },
  {
    text: 'onboarding.faceChangesCauses.option9',
    label: FACE_CHANGES_CAUSES_VALUES.WEIGHT_GAIN,
    icon: faceChangesIcon3,
  },
  {
    text: 'onboarding.faceChangesCauses.option10',
    label: FACE_CHANGES_CAUSES_VALUES.STRESS_SLEEP_DEPRIVATION,
    icon: faceChangesIcon2,
  },
]

const WRINKLES_FREE_ANSWERS = [
  {
    text: 'onboarding.faceChangesCauses.option11',
    label: FACE_CHANGES_CAUSES_VALUES.AGING,
    icon: faceChangesIcon11,
  },
  {
    text: 'onboarding.faceChangesCauses.option12',
    label: FACE_CHANGES_CAUSES_VALUES.STRESS_ANXIETY,
    icon: faceChangesIcon2,
  },
  {
    text: 'onboarding.faceChangesCauses.option13',
    label: FACE_CHANGES_CAUSES_VALUES.DEHYDRATION,
    icon: faceChangesIcon8,
  },
  {
    text: 'onboarding.faceChangesCauses.option14',
    label: FACE_CHANGES_CAUSES_VALUES.LACK_OF_SLEEP,
    icon: faceChangesIcon9,
  },
  {
    text: 'onboarding.faceChangesCauses.option15',
    label: FACE_CHANGES_CAUSES_VALUES.BAD_SKINCARE_ROUTINE,
    icon: faceChangesIcon10,
  },
]

export const FACE_CHANGES_QUESTIONS_MAP = {
  [UserGoal.TIGHTEN_SKIN]: 'What do you think is the cause of sagging for you?',
  [UserGoal.REDUCE_FACE_FAT]:
    'What do you think is the cause of face fat for you?',
  [UserGoal.WRINKLE_FREE_SKIN]: 'What do you think has caused your fine lines?',
}

export const FACE_CHANGES_TITLES_MAP = {
  [UserGoal.TIGHTEN_SKIN]: 'onboarding.faceChangesCauses.title1',
  [UserGoal.REDUCE_FACE_FAT]: 'onboarding.faceChangesCauses.title2',
  [UserGoal.WRINKLE_FREE_SKIN]: 'onboarding.faceChangesCauses.title3',
}

export const FACE_CHANGES_ANSWERS_MAP = {
  [UserGoal.TIGHTEN_SKIN]: TIGHTEN_SKIN_ANSWERS,
  [UserGoal.REDUCE_FACE_FAT]: REDUCE_FAT_ANSWERS,
  [UserGoal.WRINKLE_FREE_SKIN]: WRINKLES_FREE_ANSWERS,
}
