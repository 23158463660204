import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Container } from 'components/Container'
import { Header } from 'components/Header'
import { IntroHeader } from 'components/IntroHeader'
import { IntroHeaderColor } from 'components/IntroHeader/constants'
import { IntroOptionWithImage } from 'components/IntroOptionWithImage'
import { Option } from 'components/Option'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'
import { Ticker } from 'components/Ticker'

import { selectLanguage } from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'
import { useSendIntroPageCompletedAnalytics } from 'hooks/useSendIntroPageCompletedAnalytics'
import { useStartSession } from 'hooks/useStartSession'
import { useTitleFromUrl } from 'hooks/useTitleFromUrl'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { PageId } from 'page-constants'
import { Locale, OptionType } from 'root-constants'

import { StyledAge as S } from './Age.styles'
import {
  AGE_OPTIONS,
  AGE_OPTIONS_ES,
  AGE_OPTION_VALUES_MAP,
  QUESTION,
  SENIOR_AGE_OPTIONS,
} from './constants'

export const AgeAsIntro: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)
  const { sendIntroPageCompletedAnalytics } =
    useSendIntroPageCompletedAnalytics()

  useStartSession(false)

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const {
    isDynamicDiscountCohort,
    isSeniorCohort,
    isLuvlyIntroFastCohort,
    isLuvlyIntroSaleCohort,
    isLuvlyKoreanCohort,
  } = useActiveCohortIdentifier()

  const { headline, subtext } = useTitleFromUrl()

  const handleContinue = useNextStep({
    pageId: PageId.AGE,
    question: QUESTION,
    nextPagePath,
  })

  const handleAnswerChange = (value: string) => {
    eventLogger.logUserAgeSelected(AGE_OPTION_VALUES_MAP[value])
    handleChange(value, handleContinue)
    setIsAnswersDisabled(true)
  }

  const getPageData = () => {
    const isEsLocale = language === Locale.SPANISH

    if (isSeniorCohort) {
      return {
        options: SENIOR_AGE_OPTIONS,
        title: 'onboarding.ageAsIntro.title',
        subtitle: 'onboarding.ageAsIntro.subtitle_senior',
      }
    }

    if (isLuvlyKoreanCohort) {
      return {
        options: AGE_OPTIONS,
        title: 'onboarding.ageAsIntro.title_korean',
        subtitle: 'onboarding.ageAsIntro.subtitle_korean',
      }
    }

    return {
      options:
        isEsLocale && (isLuvlyIntroFastCohort || isLuvlyIntroSaleCohort)
          ? AGE_OPTIONS_ES
          : AGE_OPTIONS,
      title: 'onboarding.ageAsIntro.title',
      subtitle: 'onboarding.ageAsIntro.subtitle',
    }
  }

  const pageData = getPageData()

  const optionProps = {
    name: PageId.AGE,
    type: OptionType.RADIO,
    disabled: isAnswersDisabled,
    onChange: (value: string) => {
      if (!isLuvlyKoreanCohort) {
        sendIntroPageCompletedAnalytics()

        eventLogger.logFirstPageCompleted({
          question: QUESTION,
          answers: value,
        })
      }

      handleAnswerChange(value)
    },
  }

  const PageHeader = () => {
    if (isLuvlyKoreanCohort) {
      return <Header />
    }

    return <IntroHeader colorTheme={IntroHeaderColor.DARK} />
  }

  const DynamicDiscountComponents = () => {
    if (isLuvlyKoreanCohort || !isDynamicDiscountCohort) return null

    return <Ticker />
  }

  return (
    <S.Root>
      <PageHeader />

      <DynamicDiscountComponents />
      <Container>
        <S.Title isKorean={isLuvlyKoreanCohort}>
          {headline || <Trans i18nKey={pageData.title} />}
        </S.Title>
        <S.Subtitle isKorean={isLuvlyKoreanCohort}>
          {subtext || <Trans i18nKey={pageData.subtitle} />}
        </S.Subtitle>

        {!isLuvlyKoreanCohort && (
          <S.CallToAction>{t`onboarding.ageAsIntro.CTA`}</S.CallToAction>
        )}
        <S.Actions>
          {pageData.options.map(({ value, text, image }) => (
            <Option {...optionProps} value={value} key={value}>
              <IntroOptionWithImage image={image} text={text} />
            </Option>
          ))}
        </S.Actions>
      </Container>

      {!isLuvlyKoreanCohort && (
        <S.Terms>
          <p>{t`onboarding.intro.termsTitle`}</p>
          <TermsOfUseLink />
          <S.Separator />
          <PrivacyPolicyLink />
        </S.Terms>
      )}
    </S.Root>
  )
}
