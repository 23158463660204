import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { CURRENCY_SYMBOLS, Currency } from 'modules/purchase/constants'
import { useGetPriceWithCurrencyFormatting } from 'modules/purchase/hooks/useGetPriceWithCurrencyFormatting'
import { usePriceWithVatAdjustment } from 'modules/purchase/hooks/usePriceWithVatAdjustment'

import { ISubscription } from 'models/subscriptions.model'

import { Locale } from 'root-constants'

import { StyledSelectSubscriptionItemV3 as S } from './SelectSubscriptionItemV3.styles'

type TProps = {
  subscription: ISubscription
  isSelected: boolean
}
export const SelectSubscriptionItemV3: React.FC<TProps> = ({
  subscription: { id, isDefault, mainPrices, currency },
  isSelected,
}) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)
  const { getPriceWithCurrencyFormatting } = useGetPriceWithCurrencyFormatting()
  const { getPriceWithVatAdjustment } = usePriceWithVatAdjustment()

  const isEnLocale = language === Locale.ENGLISH
  const isMxnCurrency = currency === Currency.MXN
  const isJpyCurrency = currency === Currency.JPY

  const renderOldDailyPrice = () => {
    if (!isMxnCurrency && !isJpyCurrency) {
      return (
        <S.OldestPrice>
          <Trans
            i18nKey="purchaseIntroOffer.subscription.oldestPrice"
            values={{
              oldestPrice: getPriceWithCurrencyFormatting(
                mainPrices.oldPrices.daily,
              ),
              currency: CURRENCY_SYMBOLS[currency],
            }}
          />
        </S.OldestPrice>
      )
    }

    return null
  }

  return (
    <S.Wrapper isSelected={isSelected} key={id}>
      {isDefault && (
        <S.MostPopularBadge>
          {t`purchaseIntroOffer.subscription.mostPopularBadge`}
        </S.MostPopularBadge>
      )}
      <S.Content
        isSelected={isSelected}
        isDefault={isDefault}
        isEnLocaleStyle={isEnLocale}
      >
        <S.PlanContainer isEnLocaleStyle={isEnLocale}>
          <S.PlanPeriod isSelected={isSelected}>
            <Trans
              i18nKey="purchaseIntroOffer.monthlyPeriod"
              values={{ count: mainPrices.periodQuantity }}
            />
          </S.PlanPeriod>
          <S.PricesComparisonWrapper>
            <S.PreviousPrice isMxnCurrency={isMxnCurrency}>
              <Trans
                i18nKey="purchaseIntroOffer.subscription.oldPrice"
                values={{
                  oldPrice: getPriceWithCurrencyFormatting(
                    mainPrices.oldPrices.fullPrice,
                  ),
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            </S.PreviousPrice>
            <S.CurrentPrice isMxnCurrency={isMxnCurrency}>
              <Trans
                i18nKey="purchaseIntroOffer.subscription.price"
                values={{
                  price: getPriceWithCurrencyFormatting(
                    getPriceWithVatAdjustment(mainPrices.fullPrice),
                  ),
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            </S.CurrentPrice>
            {renderOldDailyPrice()}
          </S.PricesComparisonWrapper>
        </S.PlanContainer>
        <S.CustomPrice isSelected={isSelected} isMxnCurrency={isMxnCurrency}>
          <S.PriceValue isMxnCurrency={isMxnCurrency}>
            {t('purchaseIntroOffer.subscription.price', {
              currency: CURRENCY_SYMBOLS[currency],
              price: getPriceWithCurrencyFormatting(
                getPriceWithVatAdjustment(mainPrices.daily),
              ),
            })}
          </S.PriceValue>
          <S.Period>
            {t('purchase1.subscription.per', { context: 'day' })}
            {t`purchase1.subscription.day`}
          </S.Period>
        </S.CustomPrice>
      </S.Content>
    </S.Wrapper>
  )
}
