import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { selectLanguage } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { CDN_FOLDER_LINK } from 'root-constants'

import { StyledLuvlyGuide as S } from './LuvlyGuide.styles'
import { IMAGE_PATH, QUESTION } from './constants'

export const LuvlyGuide: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)

  const imageLink = `${CDN_FOLDER_LINK}${IMAGE_PATH}_${language}.png`

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue('')

  return (
    <S.Root>
      <Container>
        <S.Title>{t('onboarding.luvlyGuide.question')}</S.Title>
        <S.List>
          <S.ListItem>{t('onboarding.luvlyGuide.skinCareChoice1')}</S.ListItem>
          <S.ListItem>{t('onboarding.luvlyGuide.skinCareChoice2')}</S.ListItem>
          <S.ListItem>{t('onboarding.luvlyGuide.skinCareChoice3')}</S.ListItem>
        </S.List>
        <S.ImageContainer>
          <S.Image src={imageLink} alt="" />
        </S.ImageContainer>
        <StickyButtonContainer>
          <Button onClick={handleNextClick}>{t('actions.next')}</Button>
        </StickyButtonContainer>
      </Container>
    </S.Root>
  )
}
