import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { Trans } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Container } from 'components/Container'
import { Spinner } from 'components/Spinner'

import { getSubscriptionListAction } from 'root-redux/actions/common'
import {
  selectIsUpsellAvailable,
  selectUserCountryCode,
} from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { useABTest } from 'hooks/useABTest'
import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'

import { UpsellBenefits } from 'modules/purchase/components/UpsellBenefits'
import { UpsellSubscriptionBlockSwitchers } from 'modules/purchase/components/UpsellSubscriptionBlockSwitchers'
import { usePricesStatus, usePurchaseStore } from 'modules/purchase/hooks'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import {
  MAKE_UPSELL,
  makeUpsellAction,
} from 'modules/purchase/redux/actions/upsell'

import { TPageProps } from 'models/common.model'
import { ISubscription } from 'models/subscriptions.model'

import { eventLogger } from 'services/eventLogger.service'

import { goTo, replaceHistory } from 'browser-history'
import { PageId } from 'page-constants'
import {
  Country,
  ScreenName,
  SubscriptionListType,
  SubscriptionTagsType,
  UpsellProduct,
} from 'root-constants'

import { StyledUpsellPaywallSwitchers as S } from './UpsellPaywallSwitchers.styles'

export const UpsellPaywallSwitchers: React.FC<TPageProps> = ({
  nextPagePath,
}) => {
  const dispatch: TAppDispatch = useDispatch()
  const userCountryCode = useSelector(selectUserCountryCode)
  const isUpsellAvailable = useSelector(selectIsUpsellAvailable)

  const { search } = useLocation()
  const { fetchingActionsList, subscriptions } = usePurchaseStore()
  const { isRefundNormalizedPricingActive } = useABTest()
  const { isRefundCohort } = useActiveCohortIdentifier()

  const { arePricesReady } = usePricesStatus()

  const [isPricesStartedFetching, setIsPricesStartedFetching] = useState(false)

  const isUpsellInProgress = useMemo(
    () => fetchingActionsList.includes(MAKE_UPSELL),
    [fetchingActionsList],
  )

  const tags = useMemo(
    () => [
      SubscriptionTagsType.NO_TAX,
      userCountryCode === Country.USA
        ? SubscriptionTagsType.USD
        : SubscriptionTagsType.WORLDWIDE,
      ...(isRefundNormalizedPricingActive && isRefundCohort
        ? [SubscriptionTagsType.ROUNDED_TO_99]
        : []),
    ],
    [isRefundCohort, isRefundNormalizedPricingActive, userCountryCode],
  )

  useLayoutEffect(() => {
    if (isUpsellAvailable) {
      dispatch(
        getSubscriptionListAction(SubscriptionListType.UPSELL, tags.join(',')),
      )
      setIsPricesStartedFetching(true)
      return
    }

    goTo({ pathname: nextPagePath || PageId.FINISHING_TOUCHES, search })
  }, [search, isUpsellAvailable, dispatch, tags, nextPagePath])

  useEffect(() => {
    const upsellSubscription = subscriptions.find(
      (subscription) => subscription?.product === UpsellProduct.MIX,
    )
    dispatch(setSelectedSubscriptionAction(upsellSubscription as ISubscription))
  }, [dispatch, subscriptions])

  useEffect(() => {
    if (isPricesStartedFetching && arePricesReady && !subscriptions.length) {
      replaceHistory({
        pathname: nextPagePath || PageId.FINISHING_TOUCHES,
        search,
      })
    }
  }, [
    subscriptions,
    search,
    isPricesStartedFetching,
    arePricesReady,
    nextPagePath,
  ])

  useEffect(() => {
    if (isPricesStartedFetching && arePricesReady && !!subscriptions.length) {
      eventLogger.logUpsellPurchaseShown(ScreenName.UPSELL_SWITCHER)

      window.axon && window.axon('track', 'page_view')
    }
  }, [subscriptions, isPricesStartedFetching, arePricesReady])

  const makeUpsell = useCallback(() => {
    dispatch(makeUpsellAction(ScreenName.UPSELL_SWITCHER))
  }, [dispatch])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <S.Root>
      <Container>
        <S.Title>
          <Trans i18nKey="upsell.title" components={[<br />]} />
        </S.Title>
        <S.Subtitle>
          <Trans i18nKey="upsell.subtitle" components={[<br />]} />
        </S.Subtitle>

        <UpsellSubscriptionBlockSwitchers
          makeUpsell={makeUpsell}
          nextPagePath={nextPagePath}
        />

        <UpsellBenefits />
        {isUpsellInProgress && <Spinner />}
      </Container>
    </S.Root>
  )
}
