import React, { useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Answer, answerTheme } from 'storybook-ui'

import { AdaptiveContainer } from 'components/AdaptiveContainer'

import { selectAnswers } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import desktopBackground from 'assets/images/intro-fast-girl-9-desktop.png'
import mobileBackground from 'assets/images/intro-fast-girl-9-mobile.png'

import { PageId } from 'page-constants'
import { GENDER_TRANSLATIONS_MAP, Gender, OptionType } from 'root-constants'

import { StyledSkinCareRoutineDescription as S } from './SkinCareRoutineDescription.styles'
import { QUESTION, SKIN_CARE_ROUTINE_DESCRIPTION_ANSWERS } from './constants'

export const SkinCareRoutineDescriptionV2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const userAnswers = useSelector(selectAnswers)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    theme: answerTheme.LUVLY_SOLID,
    disabled: isAnswersDisabled,
    fontWeight: '600',
    maxWidth: '488px',
    height: 'fit-content',
    padding: '16px',
    onChange: (value: string) => {
      setIsAnswersDisabled(true)
      handleChange(value, handleContinue)
    },
  }

  const [gender, age] = useMemo(() => {
    const userGender = t(
      GENDER_TRANSLATIONS_MAP[
        userAnswers?.[PageId.GENDER] || Gender.NON_BINARY
      ],
    )
    const userAge = userAnswers?.[PageId.AGE] || 'age'

    return [userGender, userAge]
  }, [t, userAnswers])

  return (
    <AdaptiveContainer
      mobileBackground={mobileBackground}
      desktopBackground={desktopBackground}
      fullWidth
    >
      <S.Title>
        <Trans
          i18nKey="onboarding.skinCareRoutineDescription.question"
          values={{ age, gender }}
        />
      </S.Title>
      <S.OptionsContainer2>
        {SKIN_CARE_ROUTINE_DESCRIPTION_ANSWERS.map(({ value, label }) => {
          return (
            <Answer {...optionProps} key={value} value={value}>
              {t(label)}
            </Answer>
          )
        })}
      </S.OptionsContainer2>
    </AdaptiveContainer>
  )
}
