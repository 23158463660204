import React from 'react'
import { useTranslation } from 'react-i18next'

import { Answer, answerTheme } from 'storybook-ui'

import { AdaptiveContainer } from 'components/AdaptiveContainer'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import desktopBackground from 'assets/images/intro-fast-girl-3-desktop.png'
import mobileBackground from 'assets/images/intro-fast-girl-3-mobile.png'

import { OptionType } from 'root-constants'

import { QUESTION, SATISFY_SKIN_CONDITION_ANSWERS } from './constants'

export const SatisfyWithSkinCondition2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    theme: answerTheme.LUVLY_SOLID,
    disabled: isAnswersDisabled,
    fontWeight: '600',
    maxWidth: '488px',
    onChange: (value: string) => {
      setIsAnswersDisabled(true)
      handleChange(value, handleContinue)
    },
  }

  return (
    <AdaptiveContainer
      title="onboarding.satisfyWithSkinCondition.question2"
      mobileBackground={mobileBackground}
      desktopBackground={desktopBackground}
    >
      {SATISFY_SKIN_CONDITION_ANSWERS.map(({ text, label }) => {
        return (
          <Answer {...optionProps} key={text} value={label}>
            {t(text)}
          </Answer>
        )
      })}
    </AdaptiveContainer>
  )
}
