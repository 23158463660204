import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { useGrowthBook } from '@growthbook/growthbook-react'
import { Autoplay, Pagination } from 'swiper'
import { Swiper as Carousel } from 'swiper/react'

import { Container } from 'components/Container'
import { Spinner } from 'components/Spinner'
import { SvgImage } from 'components/SvgImage'

import { getSubscriptionListAction } from 'root-redux/actions/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useABTest } from 'hooks/useABTest'
import { useVatInfo } from 'hooks/useHasVatInfo'
import { useTrackPageScrollDepth } from 'hooks/useTrackPageScrollDepth'

import { getButtonTapEventData } from 'helpers/getGuashaButtonsEventData'

import { ConfirmPurchaseModal } from 'modules/purchase/components/ConfirmPurchaseModal'
import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'
import { UpsellCoachingSubscriptionBlock } from 'modules/purchase/components/UpsellCoachingSubscriptionBlock'
import { usePricesStatus, usePurchaseStore } from 'modules/purchase/hooks'
import { useUpsellScrollEvent } from 'modules/purchase/hooks/useUpsellScrollEvent'
import {
  BEFORE_AFTER_REVIEWS,
  CUSTOMER_REVIEWS,
  STEPS,
  UPSELL_FEATURES,
} from 'modules/purchase/pages/UpsellCoaching/constants'
import { setSelectedSubscriptionAction } from 'modules/purchase/redux/actions/common'
import { applySecondarySubscriptionAction } from 'modules/purchase/redux/actions/upsell'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import arrowIcon from 'assets/images/sprite/right-arrow.svg'
import benefitIcon from 'assets/images/sprite/upsell-benefit-icon.svg'
import hero from 'assets/images/upsell-coaching-hero.png'

import { browserHistory, goTo } from 'browser-history'
import { PageId } from 'page-constants'
import {
  ScreenName,
  SubscriptionListType,
  SubscriptionTagsType,
} from 'root-constants'

import { StyledUpsellCoaching as S } from './UpsellCoaching.styles'

export const UpsellCoaching: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const dispatch: TAppDispatch = useDispatch()
  const growthBook = useGrowthBook()

  const { isCoachingUpsellTestActive } = useABTest()

  const [isPricesStartedFetching, setIsPricesStartedFetching] = useState(false)
  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState(false)
  const [isModalShown, setIsModalShown] = useState<boolean>(false)

  const { arePricesReady } = usePricesStatus()
  const { subscriptions } = usePurchaseStore()
  const hasVatInfo = useVatInfo()

  const { scrollEvent } = useUpsellScrollEvent(ScreenName.UPSELL_COACHING)

  useTrackPageScrollDepth({
    trackedBreakpoints: [66, 100],
    eventCallback: scrollEvent,
  })

  useLayoutEffect(() => {
    if (!growthBook?.ready) return
    if (!isCoachingUpsellTestActive) {
      browserHistory.replace({
        pathname: nextPagePath || PageId.FINISHING_TOUCHES,
        search,
      })
      return
    }

    const pricesTags = `${
      hasVatInfo ? SubscriptionTagsType.TAX : SubscriptionTagsType.NO_TAX
    },${SubscriptionTagsType.COACHING}`

    dispatch(
      getSubscriptionListAction(SubscriptionListType.SECONDARY, pricesTags),
    )
    setIsPricesStartedFetching(true)
  }, [
    dispatch,
    growthBook?.ready,
    hasVatInfo,
    isCoachingUpsellTestActive,
    nextPagePath,
    search,
  ])

  useEffect(() => {
    const coachingSubscription =
      subscriptions.find(
        (subscription) => subscription?.type === SubscriptionListType.SECONDARY,
      ) || subscriptions[0]

    dispatch(setSelectedSubscriptionAction(coachingSubscription))
  }, [dispatch, subscriptions])

  useEffect(() => {
    if (isPricesStartedFetching && arePricesReady && !subscriptions.length) {
      goTo({
        pathname: nextPagePath || PageId.FINISHING_TOUCHES,
        search,
      })
    }
  }, [
    subscriptions,
    search,
    arePricesReady,
    nextPagePath,
    isPricesStartedFetching,
  ])

  useEffect(() => {
    if (isPricesStartedFetching && arePricesReady && !!subscriptions.length) {
      eventLogger.logUpsellPurchaseShown(ScreenName.UPSELL_COACHING)
    }
  }, [arePricesReady, subscriptions.length, isPricesStartedFetching])

  const handleSkip = useCallback(() => {
    eventLogger.logUpsellPurchaseClose(ScreenName.UPSELL_COACHING)

    goTo({
      pathname: nextPagePath || PageId.FINISHING_TOUCHES,
      search,
    })
  }, [search, nextPagePath])

  const handleMakePurchase = useCallback(() => {
    eventLogger.logConfirmationModalAction('confirm')
    dispatch(applySecondarySubscriptionAction(ScreenName.UPSELL_COACHING))
    setIsModalShown(false)
  }, [dispatch])

  const handleButtonClick = (event) => {
    setIsModalShown(true)
    const { buttonText, buttonOrder } = getButtonTapEventData(event)
    eventLogger.logUpsellPageButtonClicked({
      buttonText,
      buttonNumber: buttonOrder,
      screenName: ScreenName.UPSELL_COACHING,
    })
  }

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      <S.SkipBtn onClick={handleSkip}>
        {t('actions.skip')}
        <SvgImage svg={arrowIcon} width={20} height={20} />
      </S.SkipBtn>
      <S.Root>
        <Container fields={20}>
          <S.Title $marginBottom={16}>{t('upsellCoaching.title')}</S.Title>
          <S.Subtitle>
            <Trans i18nKey="upsellCoaching.subtitle" components={[<br />]} />
          </S.Subtitle>
          <S.Hero>
            <img src={hero} alt="alex-review" />
            <S.Review>
              <Trans i18nKey="upsellCoaching.alexReview" />
            </S.Review>
          </S.Hero>
          <S.ReviewDisclaimer>
            <Trans i18nKey="upsellCoaching.reviewDisclaimer" />
          </S.ReviewDisclaimer>
          <S.Title>{t('upsellCoaching.unlockCoaching')}</S.Title>
          <S.BenefitsContainer>
            <S.BenefitRow>
              <SvgImage svg={benefitIcon} width={40} height={40} />
              <S.Benefit>{t('upsellCoaching.oneOnOneApp')}</S.Benefit>
            </S.BenefitRow>
            <S.BenefitRow>
              <SvgImage svg={benefitIcon} width={40} height={40} />
              <S.Benefit>{t('upsellCoaching.skinDiagnostics')}</S.Benefit>
            </S.BenefitRow>
            <S.BenefitRow>
              <SvgImage svg={benefitIcon} width={40} height={40} />
              <S.Benefit>{t('upsellCoaching.dailySupport')}</S.Benefit>
            </S.BenefitRow>
          </S.BenefitsContainer>
          <UpsellCoachingSubscriptionBlock
            nextPagePath={nextPagePath}
            onButtonClick={handleButtonClick}
            buttonOrder={1}
          />
          <S.Title>{t('upsellCoaching.gameChanger')}</S.Title>
          <S.UpsellFeatureContainer>
            {UPSELL_FEATURES.map(({ icon, title, description, image }) => (
              <S.UpsellFeature key={title}>
                <S.FeatureTitle>
                  <SvgImage svg={icon} width={24} height={24} />
                  {t(title)}
                </S.FeatureTitle>
                <S.Description>{t(description)}</S.Description>
                <img src={image} alt="what-you-get" />
              </S.UpsellFeature>
            ))}
          </S.UpsellFeatureContainer>
          <S.Title>{t('upsellCoaching.howItWorks')}</S.Title>
          <div>
            {STEPS.map(({ id, title, description }) => (
              <S.Step key={id}>
                <S.StepNumber>{t('upsellCoaching.step', { id })}</S.StepNumber>
                <S.StepTitle>{t(title)}</S.StepTitle>
                <S.StepDescription>{t(description)}</S.StepDescription>
              </S.Step>
            ))}
          </div>
          <S.UpsellCoachingSubscriptionBlock>
            <UpsellCoachingSubscriptionBlock
              nextPagePath={nextPagePath}
              onButtonClick={handleButtonClick}
              buttonOrder={2}
            />
          </S.UpsellCoachingSubscriptionBlock>
          <S.Title $marginBottom={8}>{t('upsellCoaching.fastResults')}</S.Title>
          <S.BeforeAfterSubtitle>
            {t('upsellCoaching.getRidOfInsecurity')}
          </S.BeforeAfterSubtitle>
          <Carousel
            slidesPerView={1}
            loop
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Autoplay]}
            autoplay={{ delay: 3000, disableOnInteraction: false }}
          >
            {BEFORE_AFTER_REVIEWS.map(({ title, img }) => (
              <S.SwiperSlide key={title}>
                <S.Hero>
                  <img src={img} alt="user-before-after" />
                  <S.Review>
                    <Trans i18nKey={title} />
                  </S.Review>
                </S.Hero>
              </S.SwiperSlide>
            ))}
            <S.ReviewDisclaimer>
              <Trans i18nKey="upsellCoaching.reviewDisclaimer" />
            </S.ReviewDisclaimer>
          </Carousel>
          <S.SecondaryTitle>
            {t('upsellCoaching.alreadyWorking')}
          </S.SecondaryTitle>
          {CUSTOMER_REVIEWS.map(({ title, review, icon, name }) => (
            <S.CustomerReview key={name}>
              <S.CustomerReviewTitle>
                {t(title)}
                <SvgImage svg={icon} width={20} height={20} />
              </S.CustomerReviewTitle>
              <S.CustomerReviewText>{t(review)}</S.CustomerReviewText>
              <S.CustomerName>{t(name)}</S.CustomerName>
            </S.CustomerReview>
          ))}
          <SecurityInfo hasSecureBadge />
        </Container>

        {isModalShown && (
          <ConfirmPurchaseModal
            closeModal={() => {
              setIsModalShown(false)
              eventLogger.logConfirmationModalAction('skip')
            }}
            confirmPurchase={handleMakePurchase}
          />
        )}

        <PaymentWaitingModal
          isPaymentWaitingShown={isPaymentWaitingShown}
          setIsPaymentWaitingShown={setIsPaymentWaitingShown}
          buttonText={t`purchase1.paymentWaiting.proceedToPlan`}
        />
      </S.Root>
    </>
  )
}
