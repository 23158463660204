import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { getVariantAction } from 'root-redux/actions/common'
import { TAppDispatch } from 'root-redux/store/store'

import { Cohort } from 'root-constants'

export const useSetVariant = (): void => {
  const dispatch: TAppDispatch = useDispatch()
  const URLParams = new URLSearchParams(document.location.search)
  const cohortFromUrl = URLParams.get('cohort')

  useEffect(() => {
    dispatch(
      getVariantAction({ cohort: cohortFromUrl || Cohort.LUVLY_INTRO_FAST }),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
