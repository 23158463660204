import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  Button,
  answerWithIconAndCheckboxTheme,
  buttonTheme,
} from 'storybook-ui'

import { Container } from 'components/Container'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import checkIcon from 'assets/images/check-white.svg'

import { DoubleChinTitle, SandyBackgroundGlobalStyles } from 'common-styles'
import { PageId } from 'page-constants'
import { OptionType } from 'root-constants'

import { StyledSkinConcerns as S } from './SkinConcerns.styles'
import {
  OPTION_VALUES,
  QUESTION,
  SKIN_CONCERNS_VARIANT_4_ANSWERS,
} from './constants'

export const SkinConcernsVariant4: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])

  const handleContinue = useNextStep({
    pageId: PageId.SKIN_CONCERNS,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    theme: answerWithIconAndCheckboxTheme.LUVLY_SOLID,
    iconWidth: '24px',
    iconHeight: '24px',
    iconSrc: checkIcon,
    iconSize: '12px',
    margin: '0 0 12px',
    fontWeight: '600',
    onChange: (value: string, isChecked: boolean) => {
      const isNothingFromAboveOptionTouched =
        value === OPTION_VALUES.NOTHING_FROM_THE_ABOVE

      if (isChecked && isNothingFromAboveOptionTouched) {
        setAnswers([value])

        return
      }

      if (isChecked) {
        setAnswers(
          [...answers, value].filter(
            (item) => item !== OPTION_VALUES.NOTHING_FROM_THE_ABOVE,
          ),
        )

        return
      }

      setAnswers(answers.filter((item) => item !== value))
    },
  }

  const handleNextClick = () => handleContinue(answers)

  return (
    <div>
      <Container>
        <DoubleChinTitle>
          <Trans i18nKey="onboarding.skinConcerns.questionV3" />
        </DoubleChinTitle>
        {SKIN_CONCERNS_VARIANT_4_ANSWERS.map(({ label, value, icon }) => {
          return (
            <S.AnswerWithIcon
              {...optionProps}
              key={label}
              value={value}
              imageSrc={icon}
              checked={answers.includes(value)}
            >
              {t(label)}
            </S.AnswerWithIcon>
          )
        })}

        <SandyBackgroundGlobalStyles />
      </Container>
      <StickyButtonContainer centerMode>
        <Button
          onClick={handleNextClick}
          disabled={!answers.length}
          theme={buttonTheme.LUVLY_SECONDARY}
        >
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
    </div>
  )
}
