import styled, { css } from 'styled-components'

import closeSvg from 'assets/images/close-icon.svg'

import { CustomBackgroundStyles, customBackgroundStyles } from 'common-styles'
import { Color } from 'root-constants'

type TContentContainerProps = {
  isVisible: boolean
}

type TPaymentMethodProps = {
  isSelected: boolean
}

type TPayPalContainerProps = {
  hasNegativeOffset: boolean
}

type TErrorTheme = {
  errorTheme?: boolean
  background?: CustomBackgroundStyles
}

const hiddenStyles = css`
  position: absolute;
  z-index: -1;
  opacity: 0;
  transform: translateY(-100%);
  pointer-events: none;
`

export const StyledCheckoutSeparateMethods = {
  Root: styled.div<TErrorTheme>`
    ${({ errorTheme }) =>
      errorTheme &&
      css`
        ${customBackgroundStyles}
      `};
  `,
  Error: styled.h1`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 24px;
  `,
  ErrorDescription: styled.p`
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.GRAY};
    margin-bottom: 16px;
  `,
  Divider: styled.div`
    width: 100%;
    height: 1px;
    background-color: #cacaca;
    margin-bottom: 24px;
  `,
  ThreeDSecureIframe: styled.iframe`
    height: calc(var(--full-height, 100vh) - 50px) !important;
    padding: 15px;
  `,
  ContentContainer: styled.div<TContentContainerProps & TErrorTheme>`
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
    max-width: 375px;
    margin: 0 auto;
    padding: ${({ errorTheme }) => (errorTheme ? '0 16px 0' : '40px 16px 0')};
  `,
  CloseButton: styled.button`
    padding: 0;
    display: block;
    position: absolute;
    top: 69px;
    right: 15px;
    width: 24px;
    height: 24px;
    border: none;
    outline: none;
    cursor: pointer;
    background-size: 24px 24px;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${closeSvg});
  `,
  PaymentMethodsTitle: styled.h2<TErrorTheme>`
    color: ${Color.DARK};
    font-size: ${({ errorTheme }) => (errorTheme ? '18px' : '20px')};
    font-weight: ${({ theme }) => theme.common.semiBoldFontWeight};
    line-height: 32px;
    padding-bottom: 8px;
    text-align: ${({ errorTheme }) => (errorTheme ? 'left' : 'center')};
  `,
  PaymentMethodsWrapper: styled.div`
    display: flex;
    justify-content: center;
    margin: 16px auto 0;
    padding-bottom: 32px;
    border-bottom: 1px solid #e9eaed;

    & > div:first-of-type {
      margin-right: 12px;
    }
  `,
  PaymentMethodsWrapperV2: styled.div`
    display: flex;
    justify-content: space-between;
    gap: 8px;
    margin: 16px auto 0;
    padding-bottom: 32px;
    border-bottom: 1px solid #e9eaed;
  `,
  PaymentMethod: styled.div<TPaymentMethodProps>`
    width: 100%;
    max-width: 150px;
    height: 78px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: ${Color.WHITE};
    -webkit-tap-highlight-color: transparent;
    border: 1px solid
      ${({ isSelected }) => (isSelected ? Color.PRIMARY : '#c4c4c4')};
  `,
  PaymentMethodText: styled.p`
    color: ${Color.DARK};
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    margin-bottom: 8px;
    text-align: center;
  `,
  PaymentMethodImage: styled.img<TPaymentMethodProps>`
    max-width: 94px;
    max-height: 16px;
    width: 100%;
    transition: 0.15s;

    ${({ isSelected }) => !isSelected && `filter: grayscale(1)`};
  `,
  AlternativePaymentMethodImage: styled.img<TPaymentMethodProps>`
    width: 100%;
    max-width: 72px;
    max-height: 46px;
    transition: 0.15s;

    ${({ isSelected }) => !isSelected && `filter: grayscale(1)`}
  `,
  PaymentFormWrapper: styled.div`
    padding: 0 16px;
  `,

  StripePaymentWrapper: styled.div``,
  StripePaymentContainer: styled.div<TContentContainerProps>`
    ${({ isVisible }) => (isVisible ? 'position: static;' : hiddenStyles)}
  `,
  PaymentsSystemImage: styled.img`
    margin: 32px auto 16px;
    max-width: 256px;
  `,
  PaymentsSystemText: styled.p`
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: ${Color.LIGHT};
    text-align: center;
    padding-bottom: 32px;
  `,
  PayPalContainer: styled.div<TPayPalContainerProps>`
    ${({ hasNegativeOffset }) => hasNegativeOffset && 'margin-top: -32px;'}
  `,
}
