import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AdaptiveContainer } from 'components/AdaptiveContainer'

import { selectAnswers } from 'root-redux/selects/common'

import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import femaleFaceYoga from 'assets/images/female-face-yoga.gif'
import maleFaceYoga from 'assets/images/male-face-yoga.gif'

import { PageId } from 'page-constants'
import { Gender } from 'root-constants'

import { StyledEasyWithLuvly as S } from './EasyWithLuvly.styles'
import { QUESTION } from './constants'

export const EasyWithLuvly: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)

  const userGender = userAnswers?.[PageId.GENDER]

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = useCallback(
    () => handleContinue(''),
    [handleContinue],
  )

  const { counter } = useCounter(true, {
    duration: 10000,
    callback: handleNextClick,
  })

  return (
    <AdaptiveContainer title="onboarding.easyWithLuvly.question">
      <S.ImageContainer>
        <img
          src={userGender === Gender.MALE ? maleFaceYoga : femaleFaceYoga}
          alt="practice"
        />
      </S.ImageContainer>
      <S.Review>
        <S.Text>
          <Trans i18nKey="onboarding.easyWithLuvly.text" context={userGender} />
        </S.Text>
        <S.Author>
          <Trans
            i18nKey="onboarding.easyWithLuvly.author"
            context={userGender}
          />
        </S.Author>
      </S.Review>
      <S.ProgressBar value={counter} maxWidth="100%">
        {t`onboarding.photoResult.connectToDB`}
      </S.ProgressBar>
    </AdaptiveContainer>
  )
}
