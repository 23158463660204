import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'
import { Option } from 'components/Option'
import { PageTitle } from 'components/PageTitle'
import { PageTitleDescription } from 'components/PageTitleDescription'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useIsNavigationOn } from 'hooks/useIsNavigationOn'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { goBack } from 'browser-history'
import { I18N_CONTEXT_COHORT_MAP, OptionType } from 'root-constants'

import { StyledSugar as S } from './Sugar.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const Sugar: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const [answer, setAnswer] = useState<string>('')
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const { isSeniorCohort } = useActiveCohortIdentifier()
  const cohortToUse = useCohortToUse()
  const includeNavigation = useIsNavigationOn()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue(answer)

  const handleAnswerChange = (value: string) => {
    if (includeNavigation) {
      setAnswer(value)
      return
    }

    handleChange(value, handleContinue)
    setIsAnswersDisabled(true)
  }

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: handleAnswerChange,
  }

  return (
    <div>
      <S.TitleContainer>
        <PageTitle>
          <Trans
            i18nKey="onboarding.sugar.question"
            context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
            component={[<br />]}
          />
        </PageTitle>
      </S.TitleContainer>
      {!isSeniorCohort && (
        <S.DescriptionContainer>
          <PageTitleDescription>
            {t('onboarding.sugar.description')}
          </PageTitleDescription>
        </S.DescriptionContainer>
      )}
      <Container>
        <S.OptionsContainer>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.EVERYDAY}
          >
            <AnswerButton>{t`onboarding.sugar.everyday`}</AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.OFTEN}
          >
            <AnswerButton>
              <Trans
                i18nKey="onboarding.sugar.often"
                context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
              />
            </AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.SOMETIMES}
          >
            <AnswerButton>{t`onboarding.sugar.sometimes`}</AnswerButton>
          </Option>
          <Option
            {...optionProps}
            disabled={isAnswersDisabled}
            value={OPTION_VALUES.NEVER}
          >
            <AnswerButton>{t`onboarding.sugar.never`}</AnswerButton>
          </Option>
        </S.OptionsContainer>
      </Container>
      {includeNavigation && (
        <NavigationButtons
          onBackClick={goBack}
          onNextClick={handleNextClick}
          disabled={!answer}
        />
      )}
    </div>
  )
}
