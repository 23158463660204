import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  DEFAULT_CUSTOMER_REVIEWS,
  DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS,
} from 'components/CustomerReviews/constants'

import { StyledCustomerReviewsCoral as S } from './CustomerReviewsCoral.styles'
import { ReviewCoral } from './ReviewCoral'

export type TReview = {
  name: string
  icon: string
  review: string
  emoji?: string[]
}

type TCustomerReviewsProps = {
  customerReviews?: TReview[]
  viewMoreCustomerReviews?: TReview[]
}

export const CustomerReviewsCoral: React.FC<TCustomerReviewsProps> = ({
  customerReviews = DEFAULT_CUSTOMER_REVIEWS,
  viewMoreCustomerReviews = DEFAULT_VIEW_MORE_CUSTOMER_REVIEWS,
}) => {
  const { t } = useTranslation()
  const [areAdditionalReviewsShown, setAreAdditionalReviewsShown] =
    useState(false)

  const handleViewMoreClick = useCallback(
    () => setAreAdditionalReviewsShown(true),
    [],
  )

  const renderReviews = (reviews: TReview[]) =>
    reviews.map((review) => (
      <ReviewCoral
        key={review.name}
        icon={review.icon}
        name={review.name}
        review={review.review}
        emoji={review.emoji}
      />
    ))

  return (
    <>
      <S.TitleContainer>
        <S.Title>{t`onboarding.shortProgramDescription.customerReviewsTitle`}</S.Title>
      </S.TitleContainer>
      {renderReviews(customerReviews)}
      {!areAdditionalReviewsShown ? (
        <S.ViewMoreLinkContainer>
          <S.ViewMoreLink onClick={handleViewMoreClick}>
            {t`onboarding.shortProgramDescription.viewMoreLink`}
          </S.ViewMoreLink>
        </S.ViewMoreLinkContainer>
      ) : (
        renderReviews(viewMoreCustomerReviews)
      )}
    </>
  )
}
