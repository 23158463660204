import styled, { css } from 'styled-components'

import { Switch as DefaultSwitch } from 'components/Switch'

import { Color } from 'root-constants'

export const StyledPrintableFaceYogaPDF = {
  Root: styled.div<{ isEnabled: boolean }>`
    border-radius: 20px;
    background-color: #50ab64;
    display: flex;
    align-items: center;
    padding: 18px 14px;
    margin: -22px auto 32px;

    ${({ isEnabled }) =>
      isEnabled
        ? css`
            background-color: #50ab64;
            color: ${Color.WHITE};
          `
        : css`
            background-color: ${Color.GRAY_LIGHT};
            color: ${Color.LIGHT};
          `}
  `,
  Image: styled.img`
    width: 24px;
    height: 24px;
    margin-right: 10px;
  `,
  Content: styled.div``,
  GetPDF: styled.p`
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 4px;
  `,
  PrintableChallenge: styled.p`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
  `,
  Switch: styled(DefaultSwitch)`
    & div {
      background-color: #cacaca;
      border: 1px solid #cacaca;
    }

    & span {
      box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.04),
        0px 3px 8px 0px rgba(0, 0, 0, 0.15), 0px 3px 1px 0px rgba(0, 0, 0, 0.06);
    }
  `,
}
