import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledGender = {
  TitleContainer: styled.header`
    margin-bottom: 16px;
  `,
  Description: styled.p`
    color: ${Color.GRAY};
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: -16px 0 24px;
    text-align: left;
  `,
}
