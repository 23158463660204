import excessFaceFatChart from 'assets/images/excess-face-fat-chart.png'
import saggingSkinChart from 'assets/images/sagging-skin-chart.png'
import wrinklesDepthChart from 'assets/images/wrinkles-depth-chart.png'

import { UserGoal } from 'root-constants'

export const QUESTION = 'Reduce your double chin with science and self-care'

export const QUESTIONS_MAP = {
  [UserGoal.TIGHTEN_SKIN]: 'Reduce sagging with science and self-care',
  [UserGoal.REDUCE_FACE_FAT]: 'Reduce face fat with science and self-care',
  [UserGoal.WRINKLE_FREE_SKIN]: 'Reduce wrinkles with science and self-care',
}

export const TITLES_MAP = {
  [UserGoal.TIGHTEN_SKIN]: 'onboarding.reduceYourDoubleChin.titleTightenSkin',
  [UserGoal.REDUCE_FACE_FAT]: 'onboarding.reduceYourDoubleChin.titleReduceFat',
  [UserGoal.WRINKLE_FREE_SKIN]:
    'onboarding.reduceYourDoubleChin.titleWrinklesFree',
}

export const CHARTS_MAP = {
  [UserGoal.TIGHTEN_SKIN]: saggingSkinChart,
  [UserGoal.REDUCE_FACE_FAT]: excessFaceFatChart,
  [UserGoal.WRINKLE_FREE_SKIN]: wrinklesDepthChart,
}
