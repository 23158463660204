import { useLayoutEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectUUID, selectUserCountryCode } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'

export const useApplovinPixelInitialization = (): void => {
  const uuid = useSelector(selectUUID)
  const userCountryCode = useSelector(selectUserCountryCode)

  const {
    isEUUser,
    hasAdvertizingAndMarketingCookie,
    userCookieConsentAnswer,
  } = useCookieConsentAnswer()

  const shouldAxonPixelBePaused = useMemo(
    () =>
      (isEUUser && !userCookieConsentAnswer?.length) ||
      (isEUUser && !hasAdvertizingAndMarketingCookie),
    [
      isEUUser,
      userCookieConsentAnswer?.length,
      hasAdvertizingAndMarketingCookie,
    ],
  )

  useLayoutEffect(() => {
    if (!uuid || !userCountryCode || shouldAxonPixelBePaused) {
      return
    }

    const axonScript = document.createElement('script')

    axonScript.type = 'text/javascript'
    axonScript.text = `
    !function(e,r){var
    t=["https://s.axon.ai/pixel.js","https://c.albss.com/p/l/loader.iife.js"];if(!e
    .axon){var
    a=e.axon=function(){a.performOperation?a.performOperation.apply(a,arguments):a.
    operationQueue.push(arguments)};a.operationQueue=[],a.ts=Date.now();for(var
    n=r.getElementsByTagName("script")[0],o=0;o<t.length;o++){var
    i=r.createElement("script");i.async=!0,i.src=t[o],n.parentNode.insertBefore(i,n
    )}}}(window,document);
    axon('track', 'page_view')
    `
    document.body.appendChild(axonScript)
  }, [shouldAxonPixelBePaused, userCountryCode, uuid])
}
