export const QUESTION =
  'Regular exercise reduces stress and improves skin health. How often do you exercise?'

export const OPTION_VALUES = {
  THREE_TIMES: 'three_times_a_week',
  ONE_TWO_TIMES: 'one_two_times_a_week',
  DO_NOT_EXERCISE: 'do_not_exercise',
}

export const REGULAR_EXERCISE_ANSWERS = [
  {
    value: OPTION_VALUES.THREE_TIMES,
    text: 'onboarding.regularExercise.option1',
  },
  {
    value: OPTION_VALUES.ONE_TWO_TIMES,
    text: 'onboarding.regularExercise.option2',
  },
  {
    value: OPTION_VALUES.DO_NOT_EXERCISE,
    text: 'onboarding.regularExercise.option3',
  },
]
