import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import {
  AnswerWithCheckbox,
  Button,
  answerWithCheckboxTheme,
  buttonTheme,
} from 'storybook-ui'

import { AdaptiveContainer } from 'components/AdaptiveContainer'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import checkIcon from 'assets/images/check-white.svg'

import { PageId } from 'page-constants'
import { OptionType } from 'root-constants'

import { StyledSkinConcerns as S } from './SkinConcerns.styles'
import { OPTION_VALUES, QUESTION, SKIN_CONCERNS_ANSWERS } from './constants'

export const SkinConcernsVariant3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<string[]>([])

  const userAnswers = useSelector(selectAnswers)

  const userSkinType = userAnswers?.[PageId.SKIN_TYPE]

  const handleContinue = useNextStep({
    pageId: PageId.SKIN_CONCERNS,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    theme: answerWithCheckboxTheme.LUVLY,
    iconSize: '12px',
    iconSrc: checkIcon,
    margin: '0 0 12px',
    fontWeight: '600',
    onChange: (value, isChecked) => {
      const isNothingFromAboveOptionTouched =
        value === OPTION_VALUES.NOTHING_FROM_THE_ABOVE

      if (isChecked && isNothingFromAboveOptionTouched) {
        setAnswers([value])

        return
      }

      if (isChecked) {
        setAnswers(
          [...answers, value].filter(
            (item) => item !== OPTION_VALUES.NOTHING_FROM_THE_ABOVE,
          ),
        )

        return
      }

      setAnswers(answers.filter((item) => item !== value))
    },
  }

  const handleNextClick = () => handleContinue(answers)

  return (
    <AdaptiveContainer>
      <AdaptiveContainer.Title>
        <Trans
          i18nKey="onboarding.skinConcerns.questionV3"
          values={{
            userSkinType,
          }}
        />
      </AdaptiveContainer.Title>
      <S.OptionsContainer2>
        {SKIN_CONCERNS_ANSWERS.map(({ label, value }) => {
          return (
            <AnswerWithCheckbox
              {...optionProps}
              key={label}
              value={value}
              checked={answers.includes(value)}
            >
              {t(label)}
            </AnswerWithCheckbox>
          )
        })}
      </S.OptionsContainer2>

      <StickyButtonContainer centerMode>
        <Button
          onClick={handleNextClick}
          disabled={!answers.length}
          theme={buttonTheme.LUVLY_SECONDARY}
          maxWidth="488px"
        >
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
    </AdaptiveContainer>
  )
}
