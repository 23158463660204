import React from 'react'
import { useTranslation } from 'react-i18next'

import { AnswerWithIcon, answerWithIconTheme } from 'storybook-ui'

import { AdaptiveContainer } from 'components/AdaptiveContainer'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { PageId } from 'page-constants'
import { OptionType } from 'root-constants'

import { GENDER_ANSWERS, QUESTION } from './constants'

export const GenderV3: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: PageId.GENDER,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    theme: answerWithIconTheme.LUVLY_SOLID,
    disabled: isAnswersDisabled,
    fontWeight: '600',
    maxWidth: '488px',
    onChange: (value: string) => {
      eventLogger.logUserGenderSelected(value)
      setIsAnswersDisabled(true)
      handleChange(value, handleContinue)
    },
  }

  return (
    <AdaptiveContainer
      title="onboarding.genderV2.title"
      subtitle="onboarding.genderV2.description"
    >
      {GENDER_ANSWERS.map(({ text, label, icon }) => {
        return (
          <AnswerWithIcon
            {...optionProps}
            key={text}
            value={label}
            iconSrc={icon}
          >
            {t(text)}
          </AnswerWithIcon>
        )
      })}
    </AdaptiveContainer>
  )
}
