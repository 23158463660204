import styled from 'styled-components'

import optionIconUrl from 'assets/images/check-icon-rose.svg'

import { Color } from 'root-constants'

export const StyledPersonalizedProgram = {
  Hero: styled.div`
    margin-bottom: 24px;
  `,
  List: styled.ul`
    margin-bottom: 16px;
  `,
  Item: styled.li`
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 16px;
    position: relative;
    padding-left: 32px;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      background: url(${optionIconUrl}) center no-repeat;
      background-size: contain;
    }
  `,
}
