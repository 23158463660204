import styled from 'styled-components'

export const StyledLogin = {
  Wrapper: styled.div`
    max-width: 360px;
    margin: 0 auto;
    padding: 0 20px;
  `,
  Title: styled.h1`
    margin-bottom: 16px;
    font-weight: ${({ theme }) => theme.common.semiBoldFontWeight};
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  `,
  InputContainer: styled.div`
    position: relative;
  `,
  EmailLoginContainer: styled.div``,
  DisclaimerContainer: styled.div`
    position: relative;
    margin-top: 66px;
  `,
}
