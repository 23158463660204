import { ApiService } from 'services/api.service'

import { CommonApi } from './CommonApi'
import { ConfigApi } from './ConfigApi'
import { EventsApi } from './EventsApi'
import { FaceScanApi } from './FaceScanApi'
import { PaymentApi } from './PaymentApi'
import { SubscriptionsApi } from './SubscriptionsApi'
import { UserApi } from './UserApi'
import { VariantsApi } from './VariantsApi'
import { WellhubApi } from './WellhubApi'

/**
 * API_PREFIX - declare in webpack.config.js as a global variable
 */
declare const API_PREFIX: string

const FACE_SCAN_PREFIX = 'https://facescan.gismart.xyz'

export const baseApiService = new ApiService(API_PREFIX)
export const hooksApiService = new ApiService('/hooks')
export const commonApiService = new ApiService('/api')
export const faceScanApiService = new ApiService(FACE_SCAN_PREFIX)

export const paymentApi = new PaymentApi(baseApiService)
export const userApi = new UserApi(baseApiService)
export const eventsApi = new EventsApi(hooksApiService)
export const variantsApi = new VariantsApi(baseApiService)
export const configApi = new ConfigApi(baseApiService)
export const subscriptionsApi = new SubscriptionsApi(baseApiService)
export const commonApi = new CommonApi(commonApiService)
export const faceScanApi = new FaceScanApi(faceScanApiService)
export const wellhubApi = new WellhubApi(baseApiService)
