import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { PrimerPaymentForm } from 'components/PrimerPaymentForm'
import { Spinner } from 'components/Spinner'

import { selectActionList, selectScreenName } from 'root-redux/selects/common'

import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useScrollToTop } from 'hooks/useScrollToTop'

import { createProductId } from 'helpers/createProductId'

import { CardPaymentFormSeparateCheckout } from 'modules/purchase/components/CardPaymentFormSeparateCheckout'
import { CheckoutPurchaseDetails } from 'modules/purchase/components/CheckoutPurchaseDetails'
import {
  PaymentWaitingModal,
  PrimerPaymentWaitingModal,
} from 'modules/purchase/components/PaymentWaitingModal'
import { StripeSeparatePaymentRequestButton } from 'modules/purchase/components/StripeSeparatePaymentRequestButton'
import {
  PaymentMethod,
  PaymentSystem,
  PrimerFormVariants,
} from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'
import {
  select3DSecureIframeUrl,
  selectSubscription,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import SecuritySystems from 'assets/images/security-systems.png'

import { Cohort, ScreenName } from 'root-constants'

import { StyledCheckoutSeparateMethods as S } from './CheckoutSeparateMethods.styles'
import { PAYMENT_METHODS_IMAGES } from './constants'

type TCheckoutExtended = {
  paymentSystem?: PaymentSystem
  handleCloseCheckout: () => void
  hasPaypalButton?: boolean
}
type TAlternativePaymentMethod =
  | PaymentMethod.APPLE_PAY
  | PaymentMethod.GOOGLE_PAY

export const CheckoutSeparateMethods: React.FC<TCheckoutExtended> = ({
  paymentSystem = PaymentSystem.STRIPE,
  handleCloseCheckout,
  hasPaypalButton = false,
}: TCheckoutExtended) => {
  const { t } = useTranslation()
  const selectedSubscription = useSelector(selectSubscription)
  const fetchingActionsList = useSelector(selectActionList)
  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)
  const screenName = useSelector(selectScreenName)

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethod | null>(null)
  const [alternativePaymentMethod, setAlternativePaymentMethod] =
    useState<TAlternativePaymentMethod | null>(null)
  const [isPaymentWaitingShown, setIsPaymentWaitingShown] =
    useState<boolean>(false)
  useScrollToTop()
  const {
    periodName,
    periodQuantity,
    trialPrice,
    price,
    cohort,
    stripeAccountId,
    stripeAccountName,
  } = usePurchaseStore()

  const isCheckoutReady = useMemo(
    () => !fetchingActionsList.includes(CHECK_PAYMENT_REQUEST_BUTTON),
    [fetchingActionsList],
  )

  const isCardSelected = useMemo(
    () => selectedPaymentMethod === PaymentMethod.CREDIT_CARD,
    [selectedPaymentMethod],
  )

  const productId = useMemo(
    () =>
      createProductId({
        periodName,
        periodQuantity,
        price: trialPrice || price,
      }),
    [periodName, periodQuantity, trialPrice, price],
  )

  useLayoutEffect(() => {
    if (alternativePaymentMethod) {
      setSelectedPaymentMethod(alternativePaymentMethod)
      return
    }
    setSelectedPaymentMethod(PaymentMethod.CREDIT_CARD)
  }, [alternativePaymentMethod])

  useAmplitudeInitialization(cohort as Cohort, ScreenName.CHECKOUT)

  useEffect(() => {
    if (productId) {
      eventLogger.logPurchaseShown({
        productId,
        screenName,
        stripeAccountId,
        stripeAccountName,
      })
    }
  }, [productId, screenName, stripeAccountId, stripeAccountName])

  const setPaymentMethod = useCallback((paymentMethod: PaymentMethod) => {
    setSelectedPaymentMethod(paymentMethod)
    eventLogger.logPaymentMethodSelected(paymentMethod)
  }, [])

  if (threeDSecureIframeUrl) {
    return <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
  }

  return (
    selectedSubscription && (
      <S.Root>
        {!isCheckoutReady && <Spinner />}
        <S.ContentContainer isVisible={isCheckoutReady}>
          <S.PaymentMethodsTitle>
            {alternativePaymentMethod
              ? t('checkoutExtended.selectMethods')
              : t('checkoutExtended.checkoutTitle')}
          </S.PaymentMethodsTitle>
          <S.CloseButton
            data-testid="close-btn"
            onClick={handleCloseCheckout}
          />
          {alternativePaymentMethod && (
            <S.PaymentMethodsWrapper>
              <S.PaymentMethod
                isSelected={isCardSelected}
                onClick={() => setPaymentMethod(PaymentMethod.CREDIT_CARD)}
              >
                <S.PaymentMethodText>
                  {t('checkoutExtended.creditCard')}
                </S.PaymentMethodText>
                <S.PaymentMethodImage
                  isSelected={isCardSelected}
                  src={PAYMENT_METHODS_IMAGES[PaymentMethod.CREDIT_CARD]}
                  alt="credit-card-img"
                />
              </S.PaymentMethod>
              <S.PaymentMethod
                isSelected={!isCardSelected}
                onClick={() => setPaymentMethod(alternativePaymentMethod)}
              >
                <S.AlternativePaymentMethodImage
                  isSelected={!isCardSelected}
                  src={PAYMENT_METHODS_IMAGES[alternativePaymentMethod]}
                  alt="credit-card-img"
                />
              </S.PaymentMethod>
            </S.PaymentMethodsWrapper>
          )}

          <CheckoutPurchaseDetails />

          <S.PaymentFormWrapper>
            {paymentSystem === PaymentSystem.PRIMER && (
              <PrimerPaymentForm
                hasPayPalButton={hasPaypalButton}
                variant={PrimerFormVariants.SEPARATED}
                separatedVariantProps={{
                  isCreditCardSelected:
                    selectedPaymentMethod === PaymentMethod.CREDIT_CARD,
                  setAlternativePaymentMethodCallback:
                    setAlternativePaymentMethod,
                }}
              />
            )}
            {paymentSystem === PaymentSystem.STRIPE && (
              <S.StripePaymentWrapper>
                <S.StripePaymentContainer
                  isVisible={
                    selectedPaymentMethod === PaymentMethod.CREDIT_CARD
                  }
                >
                  <CardPaymentFormSeparateCheckout />
                </S.StripePaymentContainer>
                <S.StripePaymentContainer
                  isVisible={
                    selectedPaymentMethod !== PaymentMethod.CREDIT_CARD
                  }
                >
                  <StripeSeparatePaymentRequestButton
                    setAlternativePaymentMethodCallback={
                      setAlternativePaymentMethod
                    }
                  />
                </S.StripePaymentContainer>
              </S.StripePaymentWrapper>
            )}
          </S.PaymentFormWrapper>
          <S.PaymentsSystemImage src={SecuritySystems} alt="security-systems" />
          <S.PaymentsSystemText>{t`purchase2.checkout.moneyBackGuarantee`}</S.PaymentsSystemText>
        </S.ContentContainer>
        {paymentSystem === PaymentSystem.STRIPE && (
          <PaymentWaitingModal
            isPaymentWaitingShown={isPaymentWaitingShown}
            setIsPaymentWaitingShown={setIsPaymentWaitingShown}
            buttonText={t`purchase1.paymentWaiting.proceedToPlan`}
          />
        )}
        {paymentSystem === PaymentSystem.PRIMER && (
          <PrimerPaymentWaitingModal
            isPaymentWaitingShown={isPaymentWaitingShown}
            setIsPaymentWaitingShown={setIsPaymentWaitingShown}
            buttonText={t`purchase1.paymentWaiting.proceedToPlan`}
          />
        )}
      </S.Root>
    )
  )
}
