import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button, buttonTheme } from 'storybook-ui'

import { Container } from 'components/Container'
import { Glasses } from 'components/Glasses'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import drop from 'assets/images/water-drop.png'

import {
  DoubleChinTitle,
  SandyBackgroundGlobalStyles,
  StickyButtonContainer,
} from 'common-styles'

import { StyledDailyWaterVariant2 as S } from './DailyWaterVariant2.styles'
import { QUESTION } from './constants'

export const DailyWaterVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [selectedQuantity, setSelectedQuantity] = useState<string>('')

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue(selectedQuantity)

  return (
    <div>
      <Container>
        <DoubleChinTitle>
          <Trans
            i18nKey="onboarding.dailyWater.question2"
            components={[<strong />]}
          />
        </DoubleChinTitle>
        <S.DescriptionContainer>
          <S.Image src={drop} alt="drop" />
          <S.Description>{t`onboarding.dailyWater.description2`}</S.Description>
        </S.DescriptionContainer>
        <Glasses
          selectedQuantity={selectedQuantity}
          setSelectedQuantity={setSelectedQuantity}
        />
        <SandyBackgroundGlobalStyles />
      </Container>
      <StickyButtonContainer customBackground="transparent" centerMode>
        <Button
          onClick={handleNextClick}
          disabled={!selectedQuantity}
          theme={buttonTheme.LUVLY_SECONDARY}
        >
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
      <SandyBackgroundGlobalStyles />
    </div>
  )
}
