import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button, buttonTheme } from 'storybook-ui'

import { Container } from 'components/Container'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import cup from 'assets/images/cup-icon.png'
import graph from 'assets/images/double-chin-chart.png'

import {
  DoubleChinTitle,
  SandyBackgroundGlobalStyles,
  StickyButtonContainer,
} from 'common-styles'

import { StyledDoubleChinReduction as S } from './DoubleChinReduction.styles'
import { QUESTION } from './constants'

export const DoubleChinReduction: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}: TPageProps) => {
  const { t } = useTranslation()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue('')

  return (
    <div>
      <Container>
        <DoubleChinTitle>
          <Trans
            i18nKey="onboarding.doubleChinReduction.title"
            components={[<strong />]}
          />
        </DoubleChinTitle>
        <S.GraphContainer>
          <S.GraphTitle>
            {t('onboarding.doubleChinReduction.graphTitle')}
          </S.GraphTitle>
          <S.Graph src={graph} alt="graph" />
          <S.GraphNote>
            {t('onboarding.doubleChinReduction.graphNote')}
          </S.GraphNote>
        </S.GraphContainer>
        <S.NoteContainer>
          <S.Cup src={cup} alt="cup" />
          <S.NoteTitle>
            {t('onboarding.doubleChinReduction.noteTitle')}
          </S.NoteTitle>
          <S.NoteDescription>
            {t('onboarding.doubleChinReduction.noteDescription')}
          </S.NoteDescription>
        </S.NoteContainer>
      </Container>
      <StickyButtonContainer
        customBackground="linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.0001) 0.25%,
      #ffffff 26.14%
    )"
        centerMode
      >
        <Button onClick={handleNextClick} theme={buttonTheme.LUVLY_SECONDARY}>
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
      <SandyBackgroundGlobalStyles />
    </div>
  )
}
