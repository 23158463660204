import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ChatAnswerButton } from 'components/ChatAnswerButton'
import { ChatMessage } from 'components/ChatMessage'
import {
  ChatAuthor,
  ChatStep,
  DELAY_BEFORE_SHOW,
} from 'components/ChatMessage/ChatMessage'
import { Container } from 'components/Container'
import { Option } from 'components/Option'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { Chat, DelayedBottomContainer } from 'common-styles'
import { PageId } from 'page-constants'
import { CHAT_PAGE_TRANSITION_DELAY, OptionType } from 'root-constants'

import { StyledLikeChatAge as S } from './LikeChatAge.styles'
import {
  AGE_ANSWERS_MAP,
  AGE_OPTION_VALUES_MAP,
  LIKE_CHAT_AGE_OPTIONS,
  QUESTION,
} from './constants'

export const LikeChatAge: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const [answer, setAnswer] = useState<string>('')
  const [chatStep, setChatStep] = useState<ChatStep>(ChatStep.EXPERT_QUESTION)
  const { handleChange } = useDelayedContinue(CHAT_PAGE_TRANSITION_DELAY)
  const {
    isAnswersDisabled,
    handleChange: handleAnswerChange,
    setIsAnswersDisabled,
  } = useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: PageId.AGE,
    question: QUESTION,
    nextPagePath,
  })

  useEffect(() => {
    if (chatStep === ChatStep.FINISH) {
      handleChange(answer, handleContinue)
    }
  }, [answer, chatStep, handleChange, handleContinue])

  const handleSetAnswer = (value) => {
    setAnswer(value)
  }

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    disabled: isAnswersDisabled,
    onChange: (value: string) => {
      setIsAnswersDisabled(true)
      eventLogger.logUserAgeSelected(AGE_OPTION_VALUES_MAP[value])
      handleAnswerChange(value, handleSetAnswer)
    },
  }

  return (
    <Container fullHeight justifyContent="flex-end">
      <Chat>
        <ChatMessage
          author={ChatAuthor.EXPERT}
          delayBeforeShow={DELAY_BEFORE_SHOW}
          onTransitionEnd={() => setChatStep(ChatStep.USER_ANSWER)}
          hasJustNowLabel={
            chatStep === ChatStep.EXPERT_QUESTION ||
            (chatStep === ChatStep.USER_ANSWER && !answer)
          }
        >
          {t('onboarding.likeChatAge.title')}
        </ChatMessage>
        <ChatMessage
          author={ChatAuthor.USER}
          {...(!!answer && {
            delayBeforeShow: DELAY_BEFORE_SHOW,
          })}
          onTransitionEnd={() => setChatStep(ChatStep.FINISH)}
        >
          {answer && t(AGE_ANSWERS_MAP[answer])}
        </ChatMessage>
      </Chat>
      <DelayedBottomContainer
        isShown={!answer && chatStep === ChatStep.USER_ANSWER}
      >
        <S.AnswersWrapper>
          {LIKE_CHAT_AGE_OPTIONS.map(({ value, text }) => (
            <Option {...optionProps} value={value} key={value} withoutMargin>
              <ChatAnswerButton>{t(text)}</ChatAnswerButton>
            </Option>
          ))}
        </S.AnswersWrapper>
      </DelayedBottomContainer>
    </Container>
  )
}
