import styled from 'styled-components'

type TAreaProps = {
  color: string
  top: string
  left: string
}

export const StyledAnimatedFace = {
  ImageContainer: styled.div`
    position: relative;
    display: flex;
    max-width: 184px;
    position: absolute;
    left: 0;
    top: 180px;

    & svg {
      transition: 0.15s all;
    }
  `,
  Area: styled.div<TAreaProps>`
    position: absolute;
    display: flex;
    top: ${({ top }) => top};
    left: ${({ left }) => left};
    color: ${({ color }) => color};
  `,
}
