import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Answer, answerTheme } from 'storybook-ui'

import { Container } from 'components/Container'

import { selectAnswers, selectUserAge } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { DoubleChinTitle, SandyBackgroundGlobalStyles } from 'common-styles'
import { PageId } from 'page-constants'
import { OptionType } from 'root-constants'

import { DAILY_YOGA_ANSWERS, QUESTION } from './constants'

export const DailyYoga: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const userAnswers = useSelector(selectAnswers)
  const userGender = userAnswers?.[PageId.GENDER]
  const userAge = useSelector(selectUserAge)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleAnswerChange = (value: string) => {
    handleChange(value, handleContinue)
    setIsAnswersDisabled(true)
  }

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: handleAnswerChange,
    theme: answerTheme.LUVLY_SOLID,
    disabled: isAnswersDisabled,
    fontWeight: '600',
  }

  return (
    <Container>
      <DoubleChinTitle>
        <Trans
          i18nKey="onboarding.dailyYoga.question"
          context={userGender}
          values={{
            userAge,
          }}
        />
      </DoubleChinTitle>

      {DAILY_YOGA_ANSWERS.map(({ value, text }) => (
        <Answer key={value} {...optionProps} value={value}>
          {t(text)}
        </Answer>
      ))}
      <SandyBackgroundGlobalStyles />
    </Container>
  )
}
