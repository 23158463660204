export const QUESTION =
  'Skincare and diet are key to skin health. Which best describes you?'

export const OPTION_VALUES = {
  HEALTHY: 'healthy',
  COULD_IMPROVE: 'could_improve',
  NOT_FOLLOW: 'not_follow',
}

export const SKIN_CARE_AND_DIET_ANSWERS = [
  {
    value: OPTION_VALUES.HEALTHY,
    text: 'onboarding.skinCareAndDiet.option1',
  },
  {
    value: OPTION_VALUES.COULD_IMPROVE,
    text: 'onboarding.skinCareAndDiet.option2',
  },
  {
    value: OPTION_VALUES.NOT_FOLLOW,
    text: 'onboarding.skinCareAndDiet.option3',
  },
]
