import { Cohort } from 'root-constants'

export const SEPARATED_CHECKOUT_COHORTS: Cohort[] = [
  Cohort.LUVLY_INTRO_FAST,
  Cohort.LUVLY_CHAT,
  Cohort.LUVLY_INTRO3_SALE,
  Cohort.LUVLY_12_SALE,
  Cohort.LUVLY_REF,
  Cohort.LUVLY_EMAIL_INTRO_FAST,
  Cohort.LUVLY_CANCEL_INTRO_FAST,
  Cohort.LUVLY_CANCEL_INTRO_1,
  Cohort.LUVLY_EMAIL_SALE,
  Cohort.LUVLY_CHIN,
  Cohort.LUVLY_INFLUENCERS,
  Cohort.LUVLY_KOREAN,
  Cohort.LUVLY_WHEEL,
  Cohort.LUVLY_21,
]

export const CANCEL_OFFER_COHORTS: Cohort[] = [
  Cohort.LUVLY_CHAT,
  Cohort.LUVLY_INTRO3_SALE,
  Cohort.LUVLY_REF,
  Cohort.LUVLY_INTRO_FAST,
  Cohort.LUVLY_INTRO_SALE,
  Cohort.LUVLY_12_SALE,
  Cohort.LUVLY_KOREAN,
  Cohort.LUVLY_CHIN,
  Cohort.LUVLY_WHEEL,
  Cohort.LUVLY_GENERAL,
]
