import { useSelector } from 'react-redux'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useIsCurrentRoute } from 'hooks/useIsCurrentRoute'
import { usePurchaseCancelOfferRoute } from 'hooks/usePurchaseCancelOfferRoute'

import {
  MAX_SUBSCRIPTION_DURATION_YEARS,
  SubscriptionProduct,
  TimeInterval,
} from 'root-constants'

import {
  CANCEL_OFFER_ADDITIONAL_DISCOUNT,
  Currency,
  DECORATING_DISCOUNT,
  DEFAULT_DYNAMIC_DISCOUNT,
  ONE_HUNDRED_PERCENTAGE,
  SUBSCRIPTION_DISCOUNTS,
} from '../constants'
import { getDynamicCohortDiscountPercentage } from '../helpers/getDynamicCohortDiscountPercentage'
import {
  selectDynamicDiscount,
  selectSubscription,
} from '../redux/selects/common'
import { usePriceWithVatAdjustment } from './usePriceWithVatAdjustment'
import { usePurchaseStore } from './usePurchaseStore'

const INITIAL_VALUE = 0

export const useSummarizedSubscriptionData = () => {
  const selectedSubscription = useSelector(selectSubscription)
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const cohortToUse = useCohortToUse()
  const cancelOfferRoutePageId = usePurchaseCancelOfferRoute()
  const isCancelOfferRoute = useIsCurrentRoute(cancelOfferRoutePageId)
  const { isDynamicDiscountCohort, isIntro5SaleCohort, isIntro6SaleCohort } =
    useActiveCohortIdentifier()
  const { currency, discountAmount, periodName, periodQuantity } =
    usePurchaseStore()
  const { getPriceWithVatAdjustment } = usePriceWithVatAdjustment()

  if (!selectedSubscription) {
    return {
      discountPercentage: INITIAL_VALUE,
      totalAmount: INITIAL_VALUE,
      discountAmount: INITIAL_VALUE,
    }
  }

  const isLifetimeSubscription =
    isIntro6SaleCohort &&
    periodName === TimeInterval.YEAR &&
    periodQuantity === MAX_SUBSCRIPTION_DURATION_YEARS

  if (isLifetimeSubscription) {
    const defaultDiscountPercentage = isCancelOfferRoute
      ? SUBSCRIPTION_DISCOUNTS.INTRO_SALE + CANCEL_OFFER_ADDITIONAL_DISCOUNT
      : SUBSCRIPTION_DISCOUNTS.INTRO_SALE

    const lifetimeTotalAmount = getPriceWithVatAdjustment(
      selectedSubscription.mainPrices.oldPrices.fullPrice,
    )

    return {
      discountPercentage:
        selectedSubscription.mainPrices.oldPrices.percentOfDiscount ||
        defaultDiscountPercentage,
      totalAmount: lifetimeTotalAmount,
      discountAmount: Number(
        (
          lifetimeTotalAmount - selectedSubscription.mainPrices.fullPrice
        ).toFixed(2),
      ),
    }
  }

  const getNormalizedPrice = (discount: number) => {
    const calculatedOldPrice =
      selectedSubscription.trialPrices.fullPrice /
      ((ONE_HUNDRED_PERCENTAGE - discount) / ONE_HUNDRED_PERCENTAGE)

    if (currency === Currency.JPY) {
      return Math.ceil(getPriceWithVatAdjustment(calculatedOldPrice))
    }

    return (
      Math.ceil(getPriceWithVatAdjustment(calculatedOldPrice)) -
      DECORATING_DISCOUNT
    )
  }

  const introSale5NonDefaultOffer =
    isIntro5SaleCohort && !selectedSubscription.isDefault

  if (
    isDynamicDiscountCohort &&
    !introSale5NonDefaultOffer &&
    !isLifetimeSubscription
  ) {
    const appliedDiscount = getDynamicCohortDiscountPercentage({
      cohort: cohortToUse,
      isCancelOfferRoute,
      dynamicDiscountAmount: dynamicDiscount?.amount,
    })

    const totalAmount = getNormalizedPrice(appliedDiscount)

    return {
      discountPercentage: appliedDiscount,
      totalAmount,
      discountAmount: Number(
        (totalAmount - selectedSubscription.trialPrices.fullPrice).toFixed(2),
      ),
    }
  }

  const totalAmount =
    selectedSubscription.trialPrices.durationDays ===
    SubscriptionProduct.SEVEN_DAY
      ? selectedSubscription.trialPrices.oldPrices.fullPrice
      : selectedSubscription.mainPrices.fullPrice

  const defaultDiscountPercentage = isCancelOfferRoute
    ? DEFAULT_DYNAMIC_DISCOUNT + CANCEL_OFFER_ADDITIONAL_DISCOUNT
    : DEFAULT_DYNAMIC_DISCOUNT

  return {
    discountPercentage:
      selectedSubscription.mainPrices.oldPrices.percentOfDiscount ||
      defaultDiscountPercentage,
    totalAmount: getPriceWithVatAdjustment(totalAmount),
    discountAmount,
  }
}
