import styled from 'styled-components'

type TIconColor = {
  color: string
}

export const StyledProblematicAreasV2 = {
  Root: styled.div`
    position: relative;
    max-width: 430px;
    margin: 0 auto;
  `,
  Split: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;
  `,
  ImageContainer: styled.div`
    position: relative;
    display: flex;
    max-width: 184px;
    position: absolute;
    left: 0;
    top: 180px;

    & svg {
      transition: 0.15s all;
    }
  `,
  CheeksIcon: styled.div<TIconColor>`
    position: absolute;
    top: 156px;
    left: 41px;
    display: flex;
    color: ${({ color }) => color};
  `,
  ChinIcon: styled.div<TIconColor>`
    position: absolute;
    top: 214px;
    left: 119px;
    display: flex;
    color: ${({ color }) => color};
  `,
  EyesIcon: styled.div<TIconColor>`
    position: absolute;
    top: 104px;
    left: 59px;
    display: flex;
    color: ${({ color }) => color};
  `,
  ForeheadIcon: styled.div<TIconColor>`
    position: absolute;
    top: 51px;
    left: 44px;
    display: flex;
    color: ${({ color }) => color};
  `,
  JawlineIcon: styled.div<TIconColor>`
    position: absolute;
    top: 185px;
    left: 29px;
    display: flex;
    color: ${({ color }) => color};
  `,
  MouthIcon: styled.div<TIconColor>`
    position: absolute;
    top: 176px;
    left: 124px;
    display: flex;
    color: ${({ color }) => color};
  `,
  NeckIcon: styled.div<TIconColor>`
    position: absolute;
    top: 226px;
    left: 2px;
    display: flex;
    color: ${({ color }) => color};
  `,
}
