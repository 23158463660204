import { SKIN_COLOR_OPTION_VALUES } from 'shared-options'

export const QUESTION = 'How would you best identify your skin color?'

export const ANSWERS = [
  {
    value: SKIN_COLOR_OPTION_VALUES.SAND_OR_WARM_IVORY,
    text: 'onboarding.identifySkinColor.sandOrWarm',
    color: '#F5DFCD',
  },
  {
    value: SKIN_COLOR_OPTION_VALUES.FAIR_OR_PALE_IVORY,
    text: 'onboarding.identifySkinColor.fairOrPale',
    color: '#EDD5D3',
  },
  {
    value: SKIN_COLOR_OPTION_VALUES.OLIVE_OR_LIGHT_BROWN,
    text: 'onboarding.identifySkinColor.oliveOrLight',
    color: '#DBC1B3',
  },
  {
    value: SKIN_COLOR_OPTION_VALUES.BROWN_OR_BLACK_BROWN,
    text: 'onboarding.identifySkinColor.brownOrBlack',
    color: '#9C8A7B',
  },
]
