import { ALTERNATIVE_GENDER, Gender } from 'root-constants'

export const QUESTION = 'You’re in good hands'
export const QUESTION_2 = 'We value your trust'
export const ANIMATION_PATH = '/animations/animated-world-map'

export const GENDER_TRANSLATIONS_MAP = {
  [Gender.MALE]: 'commonComponents.males',
  [Gender.FEMALE]: 'commonComponents.females',
  [ALTERNATIVE_GENDER]: 'commonComponents.people',
}
