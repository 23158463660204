import styled from 'styled-components'

import { AdaptiveContainer } from 'components/AdaptiveContainer'

import map from 'assets/images/world-map-outline.png'

import { CustomBackgroundStyles, customBackgroundStyles } from 'common-styles'
import { Color } from 'root-constants'

export const StyledYouAreInGoodHandsVariant2 = {
  Root: styled.div<{ background: CustomBackgroundStyles }>`
    ${customBackgroundStyles}
  `,
  Title: styled.p`
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: 16px;
  `,
  AnimationContainer: styled.div`
    width: 343px;
    height: 262px;
    margin: 0 auto;
    margin-bottom: 20px;
    background-image: url(${map});
    background-size: contain;
    background-position: left -2px top 8px;
    background-repeat: no-repeat;
  `,
  Description: styled.p`
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 24px;

    & > strong {
      color: ${Color.CORAL};
    }
  `,
  Description2: styled(AdaptiveContainer.Title)`
    text-align: center;
  `,
}
