import React, { useCallback } from 'react'

export const useScrollToTarget = () => {
  const scrollToTarget = useCallback(
    (target: React.RefObject<HTMLDivElement>) => {
      if (target && target.current) {
        if ('scrollBehavior' in document.documentElement.style) {
          target.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        } else {
          target.current.scrollIntoView()
        }
      }
    },
    [],
  )

  return { scrollToTarget }
}
