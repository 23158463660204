import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import {
  AnswerAccordion,
  Button,
  answerAccordionTheme,
  buttonTheme,
} from 'storybook-ui'

import { Container } from 'components/Container'

import { setAnswersAction } from 'root-redux/actions/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import heartIcon from 'assets/images/heart-answer-icon.png'

import {
  DoubleChinTitle,
  SandyBackgroundGlobalStyles,
  StickyButtonContainer,
} from 'common-styles'
import { PageId } from 'page-constants'
import { OptionType } from 'root-constants'

import { StyledGoal as S } from './Goal.styles'
import { GOAL_V3_ANSWERS, OPTION_VALUES, QUESTION } from './constants'

export const GoalVariant3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [answer, setAnswer] = useState<{ value: string; label: string }>({
    value: '',
    label: '',
  })

  const handleContinue = useNextStep({
    pageId: PageId.INTRO,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => {
    dispatch(
      setAnswersAction({
        answers: answer.label,
        pageId: PageId.GOAL,
      }),
    )
    handleContinue(answer.value)
  }

  const handleAnswerChange = (labelAsValue: string) => {
    setAnswer({ value: OPTION_VALUES.REDUCE_FAT, label: labelAsValue })
  }

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    theme: answerAccordionTheme.LUVLY,
    iconSrc: heartIcon,
    iconWidth: '24px',
    iconHeight: '24px',
    spacingBetweenIconAndContent: '12px',
    onChange: handleAnswerChange,
  }

  return (
    <S.Root>
      <Container fields={16}>
        <DoubleChinTitle>
          <Trans
            i18nKey="onboarding.goalVariant3.question"
            components={[<strong />]}
          />
        </DoubleChinTitle>
        <div>
          {GOAL_V3_ANSWERS.map(({ detailed, text, label }) => {
            return (
              <AnswerAccordion
                {...optionProps}
                key={text}
                hiddenText={t(detailed)}
                value={label}
              >
                {t(text)}
              </AnswerAccordion>
            )
          })}
        </div>
      </Container>
      <StickyButtonContainer
        customBackground="linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.0001) 0.25%,
      #ffffff 26.14%
    )"
        centerMode
      >
        <Button
          onClick={handleNextClick}
          disabled={!answer.value}
          theme={buttonTheme.LUVLY_SECONDARY}
        >
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
      <SandyBackgroundGlobalStyles />
    </S.Root>
  )
}
