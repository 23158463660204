import React, { useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'
import { Button, buttonTheme } from 'storybook-ui'

import { Container } from 'components/Container'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { selectAnswers } from 'root-redux/selects/common'

import { useCohortToUse } from 'hooks/useCohortToUse'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { CustomBackgroundStyles } from 'common-styles'
import { PageId } from 'page-constants'
import {
  CDN_FOLDER_LINK,
  DEFAULT_USER_AGE,
  GENDER_TRANSLATIONS_MAP,
  Gender,
  I18N_CONTEXT_COHORT_MAP,
} from 'root-constants'

import { StyledYouAreInGoodHandsVariant2 as S } from './YouAreInGoodHandsVariant2.styles'
import { ANIMATION_PATH, QUESTION_2 } from './constants'

export const YouAreInGoodHandsVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}: TPageProps) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const animationContainerRef = useRef<HTMLDivElement | null>(null)

  const cohortToUse = useCohortToUse()

  const gender = userAnswers?.[PageId.GENDER] || Gender.NON_BINARY
  const age = userAnswers?.[PageId.AGE] || DEFAULT_USER_AGE

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION_2,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue('')

  useEffect(() => {
    if (animationContainerRef && animationContainerRef.current) {
      lottie.loadAnimation({
        container: animationContainerRef.current,
        path: `${CDN_FOLDER_LINK}${ANIMATION_PATH}.json`,
        loop: true,
      })
    }

    return () => lottie.destroy()
  }, [])

  return (
    <S.Root background={CustomBackgroundStyles.BLUE_TO_BEIGE}>
      <Container>
        <S.Title>{t('onboarding.youAreInGoodHands.question2')}</S.Title>
        <S.AnimationContainer ref={animationContainerRef} />
        <S.Description>
          <Trans
            i18nKey="onboarding.youAreInGoodHands.subtitle2"
            components={[<strong />]}
            values={{
              gender: t(GENDER_TRANSLATIONS_MAP[gender]),
              age,
            }}
            context={I18N_CONTEXT_COHORT_MAP[cohortToUse]}
          />
        </S.Description>
      </Container>
      <StickyButtonContainer centerMode>
        <Button onClick={handleNextClick} theme={buttonTheme.LUVLY_SECONDARY}>
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
    </S.Root>
  )
}
