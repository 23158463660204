import preparePlanImage1 from 'assets/images/prepare-plan-exercises-1.png'
import preparePlanImage2 from 'assets/images/prepare-plan-exercises-2.png'
import preparePlanImage3 from 'assets/images/prepare-plan-exercises-3.png'

export const QUESTION = 'Prepare Plan'
export const REDIRECT_DELAY = 3000

export const SLIDES_INFO = [
  {
    id: '1',
    imagePath: '/images/prepare-plan-img-first',
    textPath: 'onboarding.preparePlan.slideTextFirst',
  },
  {
    id: '2',
    imagePath: '/images/prepare-plan-img-second',
    textPath: 'onboarding.preparePlan.slideTextSecond',
  },
  {
    id: '3',
    imagePath: '/images/prepare-plan-img-third',
    textPath: 'onboarding.preparePlan.slideTextThird',
  },
  {
    id: '4',
    imagePath: '/images/prepare-plan-img-fourth',
    textPath: 'onboarding.preparePlan.slideTextFourth',
  },
  {
    id: '5',
    imagePath: '/images/prepare-plan-img-fifth',
    textPath: 'onboarding.preparePlan.slideTextFifth',
  },
  {
    id: '6',
    imagePath: '/images/prepare-plan-img-sixth',
    textPath: 'onboarding.preparePlan.slideTextSixth',
  },
]

export const PREPARE_PLAN_SLIDES = [
  {
    id: '1',
    imagePath: preparePlanImage1,
    textPath: 'onboarding.preparePlan.slideTextFirst',
  },
  {
    id: '2',
    imagePath: preparePlanImage1,
    textPath: 'onboarding.preparePlan.slideTextSecond',
  },
  {
    id: '3',
    imagePath: preparePlanImage2,
    textPath: 'onboarding.preparePlan.slideTextThird',
  },
  {
    id: '4',
    imagePath: preparePlanImage2,
    textPath: 'onboarding.preparePlan.slideTextFourth',
  },
  {
    id: '5',
    imagePath: preparePlanImage3,
    textPath: 'onboarding.preparePlan.slideTextFifth',
  },
  {
    id: '6',
    imagePath: preparePlanImage3,
    textPath: 'onboarding.preparePlan.slideTextSixth',
  },
]
