import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledEmail = {
  Container: styled.div`
    margin: 0 auto 16px;
    max-width: 360px;
    padding: 0 10px;
  `,
  TitleContainer: styled.header`
    margin-bottom: 16px;
  `,
  Title: styled.h1`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: 8px;
    text-align: center;
  `,
  Description: styled.p`
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.GRAY};
    margin-bottom: 24px;
    text-align: center;
  `,
  DescriptionContainer: styled.div`
    margin-bottom: 16px;
  `,
  InputContainer: styled.div`
    position: relative;
    margin: 40px 0 16px;
  `,
  InputContainerV2: styled.div`
    position: relative;
    margin: 0 0 16px;
  `,
  Disclaimer: styled.div`
    padding: 0 15px;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: ${Color.LIGHT};
  `,
  Error: styled.p`
    display: flex;
    align-items: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #f83b00;
    margin-top: 4px;
    padding-left: 16px;

    & svg {
      margin-right: 4px;
    }
  `,
  DisclaimerV2: styled.div`
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.BLUE_GRAY};

    & strong {
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      color: ${Color.BLUE_DARK};
      margin-bottom: 4px;
    }

    & img {
      width: 24px;
      margin-right: 6px;
    }
  `,
  ButtonContainer: styled.div`
    position: relative;
    padding: 24px 0 40px 0;
  `,
}
