import styled from 'styled-components'

import { TContainer } from './Container'

export const StyledContainer = {
  Container: styled.div<TContainer>`
    max-width: ${({ maxWidth }) => `${maxWidth}px`};
    margin: 0 auto;
    padding: ${({ fields }) => `0 ${fields}px`};

    ${({ fullHeight, justifyContent }) =>
      fullHeight &&
      `
        min-height: calc(var(--full-height) - 65px);
        display: flex;
        flex-direction: column;
        justify-content: ${justifyContent || 'flex-start'}
    `}
  `,
}
