import React, { useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { setAnswersAction } from 'root-redux/actions/common'
import {
  sendFacebookParamsAction,
  sendUserAnswersAction,
} from 'root-redux/actions/user'
import { selectUUID } from 'root-redux/selects/user'

import { useCohortToUse } from 'hooks/useCohortToUse'
import { useStartSession } from 'hooks/useStartSession'

import { getSupportFormLink } from 'helpers/getSupportFormLink'

import { FaceYogaCourseSteps } from 'modules/purchase/components/FaceYogaCourseSteps'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import logo from 'assets/images/luvly-logo-black.png'

import { goTo } from 'browser-history'
import { I18N_CONTEXT_COHORT_MAP } from 'root-constants'

import { StyledIntroVariant2 as S } from './IntroVariant2.styles'
import {
  BLOGGERS_INTRO_PAGE_MAP,
  OBSERVABLE_BLOCKS,
  REVIEWS,
  fixedButtonParams,
  intersectionOptions,
  scrolledValues,
} from './constants'

export const IntroVariant2: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)
  const cohortToUse = useCohortToUse()

  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false)
  const contactFormLink = getSupportFormLink()

  useStartSession()
  const toggleMenuVisibility = useCallback(() => {
    setIsMenuVisible(!isMenuVisible)
  }, [isMenuVisible])

  const handleContinue = useCallback(() => {
    dispatch(sendFacebookParamsAction())
    window.fbq('track', 'CustomizeProduct', {}, { eventID: uuid })
    googleAnalyticsLogger.logFirstPageCompleted()
    eventLogger.logFirstPageCompleted({
      question: BLOGGERS_INTRO_PAGE_MAP[cohortToUse].question,
      answers: '',
    })

    dispatch(
      setAnswersAction({
        answers: '',
        pageId: BLOGGERS_INTRO_PAGE_MAP[cohortToUse].pageId,
      }),
    )
    dispatch(sendUserAnswersAction())
    eventLogger.logQuestion({
      question: BLOGGERS_INTRO_PAGE_MAP[cohortToUse].question,
      answers: '',
    })

    goTo(nextPagePath)
  }, [dispatch, uuid, cohortToUse, nextPagePath])

  const handleHelpClick = useCallback(() => {
    eventLogger.logNeedHelpClicked()
  }, [])

  const handleReachingElement = useCallback((entries, observer) => {
    const [entry] = entries
    if (entry.isIntersecting) {
      eventLogger.logFirstPageScrolled({
        scrolledPart: scrolledValues[entry.target.id],
      })
      observer.unobserve(entry.target)
    }
  }, [])

  useEffect(() => {
    const observers = Object.values(OBSERVABLE_BLOCKS)
      .map((block) => {
        const targetBlock = document.getElementById(block)
        let blockObserver
        if (targetBlock) {
          blockObserver = new IntersectionObserver(
            (entries, observer) => handleReachingElement(entries, observer),
            intersectionOptions,
          )
          blockObserver.observe(targetBlock)
        }

        return blockObserver
      })
      .filter((observer) => observer)

    return () => {
      observers.forEach((observer) => {
        observer.disconnect()
      })
    }
  }, [handleReachingElement])

  return (
    <S.Root>
      <S.Header>
        <S.Logo src={logo} alt="" />
        <S.SupportAction onClick={handleHelpClick}>
          <a href={contactFormLink} target="_blank" rel="noopener noreferrer">
            {t`onboarding.intro.supportAction`}
          </a>
        </S.SupportAction>
        <S.Burger type="button" onClick={toggleMenuVisibility}>
          <S.BurgerLine />
          <S.BurgerLine />
          <S.BurgerLine />
          <S.BurgerLine />
        </S.Burger>
      </S.Header>

      <S.Backdrop
        isMenuVisible={isMenuVisible}
        onClick={toggleMenuVisibility}
      />

      <S.Menu isMenuVisible={isMenuVisible}>
        <S.MenuCloseButton type="button" onClick={toggleMenuVisibility} />
        <S.StyledMenuLinkContainer>
          <TermsOfUseLink />
        </S.StyledMenuLinkContainer>
        <S.StyledMenuLinkContainer>
          <PrivacyPolicyLink />
        </S.StyledMenuLinkContainer>
      </S.Menu>

      <S.BannerContainer>
        <S.Banner
          src={BLOGGERS_INTRO_PAGE_MAP[cohortToUse].banner}
          alt="banner"
        />
      </S.BannerContainer>
      <S.Container>
        <S.Title>
          <Trans
            i18nKey="onboarding.introV2.title"
            components={[<strong />]}
            context={I18N_CONTEXT_COHORT_MAP[cohortToUse]}
          />
        </S.Title>
        <S.Subtitle>{t(`onboarding.introV2.subtitle`)}</S.Subtitle>
        <S.AmbassadorCard>
          <S.AmbassadorPhoto>
            <img
              src={BLOGGERS_INTRO_PAGE_MAP[cohortToUse].avatar}
              alt="ambassador"
            />
          </S.AmbassadorPhoto>
          <S.AmbassadorInfo>
            <S.AmbassadorTitle>
              <Trans
                i18nKey="onboarding.introV2.ambassadorTitle"
                context={I18N_CONTEXT_COHORT_MAP[cohortToUse]}
              />
            </S.AmbassadorTitle>
            <S.AmbassadorResult>
              <Trans
                i18nKey="onboarding.introV2.ambassadorResult"
                context={I18N_CONTEXT_COHORT_MAP[cohortToUse]}
              />
            </S.AmbassadorResult>
          </S.AmbassadorInfo>
        </S.AmbassadorCard>
        <S.FixedButton
          onClick={handleContinue}
          scrollParams={fixedButtonParams}
        >
          <S.ButtonContent>{t(`onboarding.introV2.takeAQuiz`)}</S.ButtonContent>
        </S.FixedButton>
        <S.ReviewsContainer id={OBSERVABLE_BLOCKS.REVIEWS_BLOCK}>
          <S.ReviewsTitle>
            {t(`onboarding.introV2.reviewsTitle`)}
          </S.ReviewsTitle>
          <S.ReviewsSubtitle>
            <Trans
              i18nKey="onboarding.introV2.reviewsSubtitle"
              components={[<strong />]}
            />
          </S.ReviewsSubtitle>
          <S.Reviews hasTitle={false} reviews={REVIEWS} />
        </S.ReviewsContainer>
        <S.PartnersContainer />
        <FaceYogaCourseSteps />
        <S.Terms id={OBSERVABLE_BLOCKS.TERMS_BLOCK}>
          <p>{t`onboarding.introV2.termsTitle`}</p>
          <TermsOfUseLink />
          <S.Separator />
          <PrivacyPolicyLink />
        </S.Terms>
      </S.Container>
    </S.Root>
  )
}
