import styled, { css } from 'styled-components'

import { Container } from 'components/Container'

import bgDesktop from 'assets/images/general-intro-desktop.png'
import bg from 'assets/images/general-intro.png'

import { Color, MediaBreakpoint } from 'root-constants'

const progressValueStyles = css`
  background: ${Color.CORAL};
  transition: all 0.2s linear;
`

export const StyledLoadingIntro2 = {
  Root: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #fff6f2;
  `,
  Container: styled(Container)`
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 48px);
    min-height: calc(100vh - 48px);

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 820px;
    }
  `,
  Title: styled.h1`
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    color: ${Color.BLUE_DARK};
    text-align: center;
    margin-top: 16px;
    margin-bottom: 20px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      margin-top: 48px;
    }
  `,
  ImageContainer: styled.div`
    min-height: 264px;
    background: url(${bg}) no-repeat center;
    background-size: cover;
    margin-bottom: 24px;
    width: 100%;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      background: url(${bgDesktop}) no-repeat center;
      background-size: cover;
      flex: 1;
    }
  `,
  Note: styled.p`
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: ${Color.BLUE_DARK};
    margin-bottom: 10px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      margin-bottom: 24px;
    }
  `,
  Progress: styled.progress`
    appearance: none;
    border: none;
    display: block;
    width: 100%;
    max-width: 343px;
    height: 8px;
    background: ${Color.CREAMY};
    border-radius: 18px;
    margin: 0 auto 10px;

    &::-webkit-progress-bar {
      background: ${Color.CREAMY};
      border-radius: 18px;
      overflow: hidden;
    }
    &::-webkit-progress-value {
      ${progressValueStyles}
    }
    &::-moz-progress-bar {
      ${progressValueStyles}
    }
    &::-ms-fill {
      ${progressValueStyles}
    }
  `,
  AnalyzingProfile: styled.p`
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 20px;
    text-align: center;
    color: ${Color.GRAY};
    flex: 1;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      flex: unset;
    }
  `,
  Terms: styled.div`
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.5px;
    color: #a9a9a9;
    margin: 24px auto 16px;
    text-align: center;

    & > span,
    p,
    a {
      font-family: 'Open Sans';
    }

    a {
      color: #a9a9a9;
      text-decoration: underline;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: #a9a9a9;
    margin: 0 5px;
    vertical-align: middle;
  `,
}
