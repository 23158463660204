import React, { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { SvgImage } from 'components/SvgImage'

import {
  selectUserCountryCode,
  selectUserOnboardingEmail,
} from 'root-redux/selects/user'

import { CURRENCY_SYMBOLS } from 'modules/purchase/constants'
import {
  selectCurrency,
  selectSubscription,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import closeIcon from 'assets/images/sprite/close-icon.svg'

import { StyledConfirmPurchaseModal as S } from './ConfirmPurchaseModal.styles'
import { INCLUDE_TEXES } from './constants'

type TModalProps = {
  closeModal: () => void
  confirmPurchase: () => void
}

export const ConfirmPurchaseModal: React.FC<TModalProps> = ({
  closeModal,
  confirmPurchase,
}: TModalProps) => {
  const { t } = useTranslation()
  const currency = useSelector(selectCurrency)
  const email = useSelector(selectUserOnboardingEmail)
  const selectedSubscription = useSelector(selectSubscription)
  const userCountryCode = useSelector(selectUserCountryCode)

  useEffect(() => {
    eventLogger.logConfirmationModalShown()
  }, [])

  return (
    <S.Root>
      <S.Content>
        <S.CloseButton onClick={closeModal}>
          <SvgImage svg={closeIcon} width={16} height={16} />
        </S.CloseButton>
        <S.Title>{t('upsellCoaching.confirmPurchase')}</S.Title>
        <S.Subtitle>
          <Trans
            i18nKey="upsellCoaching.confirmDisclaimer"
            values={{
              currency: CURRENCY_SYMBOLS[currency],
              price: selectedSubscription?.mainPrices.fullPrice,
              email,
              taxes: INCLUDE_TEXES[userCountryCode] || '',
            }}
          />
        </S.Subtitle>
        <Button onClick={confirmPurchase}>
          {t('finishingTouches.action')}
        </Button>
      </S.Content>
    </S.Root>
  )
}
