import { UserGoal } from 'root-constants'

const FRUSTRATING_REASONS_VALUES = {
  LOOKS_OLD_TIRED: 'looks_old_tired',
  SKIN_NOT_FIRM: 'skin_not_firm',
  LOST_YOUTHFUL_LIFT: 'lost_youthful_lift',
  SKIN_DROOPING: 'skin_drooping',
  LOOKS_HEAVIER: 'looks_heavier',
  FACE_DOESNT_CHANGE: 'face_doesnt_change',
  FACE_TOO_BIG: 'face_too_big',
  PUFFY_IN_PICTURES: 'puffy_in_pictures',
  LOOKS_OLDER_THAN_FEEL: 'looks_older_than_feel',
  WRINKLES_DRY_DULL: 'wrinkles_dry_dull',
  MAKEUP_SETTLES_IN_WRINKLES: 'makeup_settles_in_wrinkles',
  ROUGH_SKIN: 'rough_skin',
}

const TIGHTEN_SKIN_ANSWERS = [
  {
    text: 'onboarding.frustratingReasons.option1',
    label: FRUSTRATING_REASONS_VALUES.LOOKS_OLD_TIRED,
  },
  {
    text: 'onboarding.frustratingReasons.option2',
    label: FRUSTRATING_REASONS_VALUES.SKIN_NOT_FIRM,
  },
  {
    text: 'onboarding.frustratingReasons.option3',
    label: FRUSTRATING_REASONS_VALUES.LOST_YOUTHFUL_LIFT,
  },
  {
    text: 'onboarding.frustratingReasons.option4',
    label: FRUSTRATING_REASONS_VALUES.SKIN_DROOPING,
  },
]

const REDUCE_FAT_ANSWERS = [
  {
    text: 'onboarding.frustratingReasons.option5',
    label: FRUSTRATING_REASONS_VALUES.LOOKS_HEAVIER,
  },
  {
    text: 'onboarding.frustratingReasons.option6',
    label: FRUSTRATING_REASONS_VALUES.FACE_DOESNT_CHANGE,
  },
  {
    text: 'onboarding.frustratingReasons.option7',
    label: FRUSTRATING_REASONS_VALUES.FACE_TOO_BIG,
  },
  {
    text: 'onboarding.frustratingReasons.option8',
    label: FRUSTRATING_REASONS_VALUES.PUFFY_IN_PICTURES,
  },
]

const WRINKLES_FREE_ANSWERS = [
  {
    text: 'onboarding.frustratingReasons.option9',
    label: FRUSTRATING_REASONS_VALUES.LOOKS_OLDER_THAN_FEEL,
  },
  {
    text: 'onboarding.frustratingReasons.option10',
    label: FRUSTRATING_REASONS_VALUES.WRINKLES_DRY_DULL,
  },
  {
    text: 'onboarding.frustratingReasons.option11',
    label: FRUSTRATING_REASONS_VALUES.MAKEUP_SETTLES_IN_WRINKLES,
  },
  {
    text: 'onboarding.frustratingReasons.option12',
    label: FRUSTRATING_REASONS_VALUES.ROUGH_SKIN,
  },
]

export const QUESTIONS_MAP = {
  [UserGoal.TIGHTEN_SKIN]: 'Why is having loose skin so frustrating for you?',
  [UserGoal.REDUCE_FACE_FAT]: 'Why is having face fat so frustrating for you?',
  [UserGoal.WRINKLE_FREE_SKIN]:
    'Why is having wrinkles and face fat so frustrating for you?',
}

export const FRUSTRATING_REASONS_TITLES_MAP = {
  [UserGoal.TIGHTEN_SKIN]: 'onboarding.frustratingReasons.title1',
  [UserGoal.REDUCE_FACE_FAT]: 'onboarding.frustratingReasons.title2',
  [UserGoal.WRINKLE_FREE_SKIN]: 'onboarding.frustratingReasons.title3',
}

export const FRUSTRATING_REASONS_ANSWERS_MAP = {
  [UserGoal.TIGHTEN_SKIN]: TIGHTEN_SKIN_ANSWERS,
  [UserGoal.REDUCE_FACE_FAT]: REDUCE_FAT_ANSWERS,
  [UserGoal.WRINKLE_FREE_SKIN]: WRINKLES_FREE_ANSWERS,
}
