import styled from 'styled-components'

import { FixedButton } from 'components/FixedButton'

import { FaceYogaCourseSteps } from 'modules/purchase/components/FaceYogaCourseSteps'

import rightArrow from 'assets/images/right-arrow.svg'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledIntroInfluencers = {
  Root: styled.div`
    position: relative;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;

    & .swiper-wrapper {
      padding-bottom: 48px;
    }

    & span.swiper-pagination-bullet {
      background-color: ${Color.CREAMY};
      opacity: 1;
      width: 12px;
      height: 12px;
    }

    & span.swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: ${Color.PRIMARY};
    }
  `,
  Hero: styled.div`
    max-width: ${MediaBreakpoint.TABLET}px;
    margin: 0 auto 2px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 360px;
    }
  `,
  Title: styled.h1`
    font-size: 28px;
    font-family: 'Libre Bodoni', sans-serif;
    line-height: 34px;
    text-align: center;
    margin: 0 0 12px 0;
    padding: 0 16px;

    & > strong {
      color: ${Color.PRIMARY};
      font-family: 'Libre Bodoni', sans-serif;
    }
  `,
  Banner: styled.img`
    min-width: 360px;
    min-height: 204px;
    height: auto;
  `,
  Subtitle: styled.p`
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: ${Color.LIGHT};
    margin: 0 0 21px 0;
    padding: 0 16px;
  `,
  AmbassadorCard: styled.div`
    display: flex;
    gap: 12px;
    align-items: center;
    margin-bottom: 17px;
  `,
  AmbassadorPhoto: styled.div`
    width: 166px;
    height: auto;
  `,
  AmbassadorInfo: styled.div`
    max-width: 170px;
    text-align: left;
  `,
  AmbassadorTitle: styled.h2`
    font-size: 18px;
    line-height: 22px;
    color: ${Color.DARK};
    margin: 0 0 8px 0;
  `,
  AmbassadorResult: styled.p`
    font-size: 13px;
    line-height: 18px;
    color: ${Color.LIGHT};
  `,
  FixedButton: styled(FixedButton)`
    max-width: 320px;
    width: 100%;
    margin: 0 auto 48px;
    padding: 14px 16px;
    background-color: ${Color.PRIMARY};
  `,
  ButtonContent: styled.span`
    color: ${Color.WHITE};
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.2px;
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;

    &:after {
      position: absolute;
      content: url(${rightArrow});
      height: 24px;
      width: 24px;
      right: 0;
    }
  `,

  GalleryContainer: styled.div`
    margin: 0 auto 24px;
    max-width: 360px;
  `,
  GalleryTitle: styled.h2`
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: ${Color.BLUE_DARK};
    margin-bottom: 16px;
  `,
  GallerySubtitle: styled.p`
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    color: ${Color.LIGHT};
    margin-bottom: 24px;
    padding: 0 16px;

    & > strong {
      color: ${Color.PRIMARY};
    }
  `,
  FaceYogaCourse: styled(FaceYogaCourseSteps)`
    padding: 0;
  `,

  Terms: styled.div`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    color: ${Color.LIGHT};
    margin: 8px auto 40px;
    text-align: center;

    a {
      color: ${Color.LIGHT};
      text-decoration: underline;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: ${Color.LIGHT};
    margin: 0 5px;
    vertical-align: middle;
  `,
}
