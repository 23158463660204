import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

export const StyledBeforeAfterUsersGallery = {
  ImageWrapper: styled.div`
    border-radius: 16px;
    background: ${Color.WHITE};
    box-shadow: 0px 8px 20px 0px rgba(209, 214, 235, 0.5);
    max-width: 320px;
    margin: 0 auto;
  `,
  SlideImage: styled.img`
    display: block;
    width: auto;
    height: 208px;
    border-radius: 16px 16px 0 0;
  `,
  User: styled.div`
    padding: 16px 0 16px;
    width: 100%;
    text-align: center;
    color: ${Color.BLUE_DARK};
    ${({ theme }) => css`
      font-size: ${theme.gallery.user.fontSize};
      font-weight: ${theme.gallery.user.fontWeight};
    `}
    line-height: 24px;
  `,
}
