import { Cohort } from 'root-constants'

export const SEPARATED_CHECKOUT_COHORTS: Cohort[] = [
  Cohort.LUVLY_INTRO_FAST,
  Cohort.LUVLY_CHAT,
  Cohort.LUVLY_INTRO3_SALE,
  Cohort.LUVLY_INTRO5_SALE,
  Cohort.LUVLY_INTRO6_SALE,
  Cohort.LUVLY_12_SALE,
  Cohort.LUVLY_REF,
  Cohort.LUVLY_REF4,
  Cohort.LUVLY_REF5,
  Cohort.LUVLY_PRINTABLE,
  Cohort.LUVLY_EMAIL_INTRO_FAST,
  Cohort.LUVLY_CANCEL_INTRO_FAST,
  Cohort.LUVLY_CANCEL_INTRO_1,
  Cohort.LUVLY_EMAIL_SALE,
  Cohort.LUVLY_INFLUENCERS,
  Cohort.LUVLY_KOREAN,
  Cohort.LUVLY_INTRO_SALE,
  Cohort.LUVLY_INTRO3_SALE,
]

export const CANCEL_OFFER_COHORTS: Cohort[] = [
  Cohort.LUVLY_CHAT,
  Cohort.LUVLY_INTRO3_SALE,
  Cohort.LUVLY_INTRO5_SALE,
  Cohort.LUVLY_REF,
  Cohort.LUVLY_REF4,
  Cohort.LUVLY_PRINTABLE,
  Cohort.LUVLY_INTRO_FAST,
  Cohort.LUVLY_INTRO_SALE,
  Cohort.LUVLY_12_SALE,
  Cohort.LUVLY_KOREAN,
  Cohort.LUVLY_CHIN,
  Cohort.LUVLY_GENERAL,
]

export const HIGH_PRICES_CHILD_COHORTS: string[] = [
  'luvly_ref4',
  'luvly_refund3',
  'luvly_intro4_sale',
  'luvly_intro3_sale',
]
