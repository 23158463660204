import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { AnswerWithIcon, answerWithIconTheme } from 'storybook-ui'

import { Container } from 'components/Container'

import { selectAnswers } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { DoubleChinTitle, SandyBackgroundGlobalStyles } from 'common-styles'
import { PageId } from 'page-constants'
import { OptionType, UserGoal } from 'root-constants'

import {
  FACE_CHANGES_ANSWERS_MAP,
  FACE_CHANGES_QUESTIONS_MAP,
  FACE_CHANGES_TITLES_MAP,
} from './constants'

export const FaceChangesCauses: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const userGoal = userAnswers?.[PageId.INTRO] || UserGoal.WRINKLE_FREE_SKIN

  const handleContinue = useNextStep({
    pageId,
    question: FACE_CHANGES_QUESTIONS_MAP[userGoal],
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    theme: answerWithIconTheme.LUVLY_SOLID,
    iconWidth: '40px',
    iconHeight: '40px',
    fontWeight: '600',
    spacingBetweenIconAndContent: '12px',
    disabled: isAnswersDisabled,
    onChange: (value: string) => {
      setIsAnswersDisabled(true)
      handleChange(value, handleContinue)
    },
  }

  return (
    <div>
      <Container>
        <DoubleChinTitle>
          {t(FACE_CHANGES_TITLES_MAP[userGoal])}
        </DoubleChinTitle>
        {FACE_CHANGES_ANSWERS_MAP[userGoal].map(({ text, label, icon }) => (
          <AnswerWithIcon
            {...optionProps}
            key={label}
            value={label}
            iconSrc={icon}
          >
            {t(text)}
          </AnswerWithIcon>
        ))}

        <SandyBackgroundGlobalStyles />
      </Container>
    </div>
  )
}
