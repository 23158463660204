import React, { useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useIsCurrentRoute } from 'hooks/useIsCurrentRoute'
import { usePurchaseCancelOfferRoute } from 'hooks/usePurchaseCancelOfferRoute'

import {
  CANCEL_OFFER_ADDITIONAL_DISCOUNT,
  CURRENCY_SYMBOLS,
  Currency,
  DECORATING_DISCOUNT,
  ONE_HUNDRED_PERCENTAGE,
} from 'modules/purchase/constants'
import { useGetPriceWithCurrencyFormatting } from 'modules/purchase/hooks/useGetPriceWithCurrencyFormatting'
import { usePriceWithVatAdjustment } from 'modules/purchase/hooks/usePriceWithVatAdjustment'
import { selectDynamicDiscountPercentage } from 'modules/purchase/redux/selects/common'

import { ISubscription } from 'models/subscriptions.model'

import {
  Locale,
  MAX_SUBSCRIPTION_DURATION_YEARS,
  SubscriptionProduct,
  TimeInterval,
} from 'root-constants'

import { StyledIntroOfferSelectSubscriptionItem as S } from './IntroOfferSelectSubscriptionItem.styles'

type TProps = {
  subscription: ISubscription
  isSelected: boolean
}

export const IntroOfferSelectSubscriptionItem: React.FC<TProps> = ({
  subscription: { id, isDefault, mainPrices, trialPrices, currency },
  isSelected,
}) => {
  const { t } = useTranslation()
  const {
    isDynamicDiscountCohort,
    isIntro3SaleCohort,
    isRefundCohort,
    isIntro5SaleCohort,
    isIntro6SaleCohort,
  } = useActiveCohortIdentifier()
  const dynamicDiscountPercentage = useSelector(selectDynamicDiscountPercentage)
  const language = useSelector(selectLanguage)

  const cancelOfferRoute = usePurchaseCancelOfferRoute()
  const isCancelOfferRoute = useIsCurrentRoute(cancelOfferRoute)
  const { getPriceWithCurrencyFormatting } = useGetPriceWithCurrencyFormatting()

  const isEnLocale = language === Locale.ENGLISH
  const isMxnCurrency = currency === Currency.MXN
  const isJpyCurrency = currency === Currency.JPY

  const { getPriceWithVatAdjustment } = usePriceWithVatAdjustment()

  const isLifetimeSubscription =
    isIntro6SaleCohort &&
    mainPrices.periodName === TimeInterval.YEAR &&
    mainPrices.periodQuantity === MAX_SUBSCRIPTION_DURATION_YEARS

  const getOldPrice = useCallback(
    ({
      price,
      isFormattedDiscount,
    }: {
      price: number
      isFormattedDiscount?: boolean
    }) => {
      const discount = isCancelOfferRoute
        ? dynamicDiscountPercentage + CANCEL_OFFER_ADDITIONAL_DISCOUNT
        : dynamicDiscountPercentage
      const calculatedOldPrice =
        price / ((ONE_HUNDRED_PERCENTAGE - discount) / ONE_HUNDRED_PERCENTAGE)

      if (!isFormattedDiscount || currency === Currency.JPY) {
        return getPriceWithVatAdjustment(calculatedOldPrice).toFixed(2)
      }

      return (
        Math.ceil(getPriceWithVatAdjustment(calculatedOldPrice)) -
        DECORATING_DISCOUNT
      ).toFixed(2)
    },
    [
      currency,
      dynamicDiscountPercentage,
      getPriceWithVatAdjustment,
      isCancelOfferRoute,
    ],
  )

  const oldPrices = useMemo(() => {
    if (isLifetimeSubscription) {
      return {
        oldDailyPrice: mainPrices.oldPrices.daily,
        oldSubscriptionPrice: mainPrices.oldPrices.fullPrice,
      }
    }

    const introSale5NonDefaultOffer = isIntro5SaleCohort && !isDefault

    if (
      isDynamicDiscountCohort &&
      !isIntro3SaleCohort &&
      !introSale5NonDefaultOffer
    ) {
      return {
        oldDailyPrice: getOldPrice({ price: trialPrices.daily }),
        oldSubscriptionPrice: getOldPrice({
          price: trialPrices.fullPrice,
          isFormattedDiscount: true,
        }),
      }
    }

    return {
      oldDailyPrice: getPriceWithVatAdjustment(trialPrices.oldPrices.daily),
      oldSubscriptionPrice:
        trialPrices.durationDays === SubscriptionProduct.SEVEN_DAY
          ? getPriceWithVatAdjustment(trialPrices.oldPrices.fullPrice)
          : getPriceWithVatAdjustment(mainPrices.fullPrice),
    }
  }, [
    isLifetimeSubscription,
    isDynamicDiscountCohort,
    isIntro3SaleCohort,
    isIntro5SaleCohort,
    getPriceWithVatAdjustment,
    trialPrices,
    mainPrices,
    getOldPrice,
    isDefault,
  ])

  const getPlanPeriodLabel = () => {
    if (trialPrices.durationDays === SubscriptionProduct.SEVEN_DAY) {
      return t('purchaseIntroOffer.weeklyPeriod', {
        trialPeriodDays: trialPrices.durationDays,
      })
    }

    if (isLifetimeSubscription) {
      return (
        <Trans
          i18nKey="purchaseIntroOffer.lifetimePlan"
          components={{
            emoji: <S.Emoji />,
          }}
        />
      )
    }

    return t('purchaseIntroOffer.monthlyPeriod', {
      count: mainPrices.periodQuantity,
    })
  }

  const renderOldDailyPrice = () => {
    if (!isMxnCurrency && !isJpyCurrency) {
      return (
        <S.OldestPrice isSmallerFontSize={isIntro6SaleCohort}>
          <Trans
            i18nKey="purchaseIntroOffer.subscription.oldestPrice"
            values={{
              oldestPrice: getPriceWithCurrencyFormatting(
                oldPrices.oldDailyPrice,
              ),
              currency: CURRENCY_SYMBOLS[currency],
            }}
          />
        </S.OldestPrice>
      )
    }

    return null
  }

  const renderDailyPriceValue = () => {
    if (isRefundCohort) {
      return (
        <S.PriceValue2 language={language} currency={currency}>
          {t('purchaseIntroOffer.subscription.price', {
            currency: CURRENCY_SYMBOLS[currency],
            price: getPriceWithVatAdjustment(trialPrices.daily),
          })}
        </S.PriceValue2>
      )
    }

    return (
      <S.PriceValue currency={currency}>
        {t('purchaseIntroOffer.subscription.price', {
          currency: CURRENCY_SYMBOLS[currency],
          price: getPriceWithVatAdjustment(
            isLifetimeSubscription ? mainPrices.daily : trialPrices.daily,
          ),
        })}
      </S.PriceValue>
    )
  }

  return (
    <S.Wrapper isSelected={isSelected} key={id}>
      {isDefault && (
        <S.MostPopularBadge isCoralTheme={isRefundCohort}>
          {t`purchaseIntroOffer.subscription.mostPopularBadge`}
        </S.MostPopularBadge>
      )}
      <S.Content
        isSelected={isSelected}
        isDefault={isDefault}
        isEnLocaleStyle={isEnLocale}
        isCoralTheme={isRefundCohort}
      >
        <S.PlanContainer isEnLocaleStyle={isEnLocale}>
          <S.PlanPeriod isSelected={isSelected}>
            {getPlanPeriodLabel()}
          </S.PlanPeriod>
          <S.PricesComparisonWrapper>
            <S.PreviousPrice isMxnCurrency={isMxnCurrency}>
              <Trans
                i18nKey="purchaseIntroOffer.subscription.oldPrice"
                values={{
                  oldPrice: getPriceWithCurrencyFormatting(
                    oldPrices.oldSubscriptionPrice,
                  ),
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            </S.PreviousPrice>
            <S.CurrentPrice isMxnCurrency={isMxnCurrency}>
              <Trans
                i18nKey="purchaseIntroOffer.subscription.price"
                values={{
                  price: getPriceWithCurrencyFormatting(
                    getPriceWithVatAdjustment(
                      isLifetimeSubscription
                        ? mainPrices.fullPrice
                        : trialPrices.fullPrice,
                    ),
                  ),
                  currency: CURRENCY_SYMBOLS[currency],
                }}
              />
            </S.CurrentPrice>
            {renderOldDailyPrice()}
          </S.PricesComparisonWrapper>
        </S.PlanContainer>
        <S.CustomPrice
          isSelected={isSelected}
          isMxnCurrency={isMxnCurrency}
          isCoralTheme={isRefundCohort}
        >
          {renderDailyPriceValue()}
          <S.Period>
            {t('purchase1.subscription.per', { context: 'day' })}
            {t`purchase1.subscription.day`}
          </S.Period>
        </S.CustomPrice>
      </S.Content>
    </S.Wrapper>
  )
}
