import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Button, buttonTheme } from 'storybook-ui'

import { AdaptiveContainer } from 'components/AdaptiveContainer'
import { Option } from 'components/Option'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { selectLanguage } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { Locale, OptionType } from 'root-constants'

import { StyledTags as S } from './Tags.styles'
import { QUESTION, TAGS_2 } from './constants'

export const Tags: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)

  const [answers, setAnswers] = useState<string[]>([])

  const isEnLocale = language === Locale.ENGLISH

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue(answers)

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    onChange: (value: string, isChecked: boolean) => {
      if (isChecked) {
        setAnswers([...answers, value])

        return
      }

      setAnswers(answers.filter((item) => item !== value))
    },
  }

  return (
    <AdaptiveContainer title="onboarding.obTags.title2">
      <S.Tags isEnLocaleStyles={isEnLocale}>
        {TAGS_2.map(({ value, label }) => {
          return (
            <Option
              {...optionProps}
              key={value}
              value={value}
              checked={answers.includes(value)}
            >
              <S.Tag isEnLocaleStyles={isEnLocale}>{t(label)}</S.Tag>
            </Option>
          )
        })}
      </S.Tags>
      <StickyButtonContainer centerMode>
        <Button
          theme={buttonTheme.LUVLY_SECONDARY}
          onClick={handleNextClick}
          disabled={!answers.length}
          maxWidth="488px"
        >
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
    </AdaptiveContainer>
  )
}
