import React, { useCallback } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { Button as ButtonUI, buttonTheme } from 'storybook-ui'

import { Button } from 'components/Button'
import { Container } from 'components/Container'

import { sendUserEmailConsentAction } from 'root-redux/actions/user'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'

import { eventLogger } from 'services/eventLogger.service'

import { SandyBackgroundGlobalStyles } from 'common-styles'

import { StyledEmailConsent as S } from './EmailConsent.styles'
import { QUESTION, USER_ANSWERS } from './constants'

export const EmailConsent = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const { isCoralThemeCohort } = useActiveCohortIdentifier()

  const handleClick = useCallback(
    async (event) => {
      eventLogger.logQuestion({
        question: QUESTION,
        answers: event.target.dataset.value
          ? USER_ANSWERS.YES
          : USER_ANSWERS.NO,
      })
      dispatch(
        sendUserEmailConsentAction({ consented: !!event.target.dataset.value }),
      )
    },
    [dispatch],
  )

  const renderButton = () => {
    if (isCoralThemeCohort) {
      return (
        <ButtonUI
          margin="0 auto 24px auto"
          data-value=" "
          theme={buttonTheme.LUVLY_SECONDARY}
          onClick={handleClick}
        >
          {t('actions.yesIDo')}
        </ButtonUI>
      )
    }

    return (
      <Button marginBottom={24} data-value=" " onClick={handleClick}>
        {t('actions.yesIDo')}
      </Button>
    )
  }

  const renderTitle = () => {
    if (isCoralThemeCohort) {
      return (
        <S.TitleV2>
          <Trans
            i18nKey="onboarding.emailConsent.title"
            components={[<strong />]}
          />
        </S.TitleV2>
      )
    }

    return (
      <S.Title>
        <Trans
          i18nKey="onboarding.emailConsent.title"
          components={[<strong />]}
        />
      </S.Title>
    )
  }

  const renderBackground = () => {
    if (isCoralThemeCohort) {
      return <SandyBackgroundGlobalStyles />
    }

    return null
  }

  return (
    <Container>
      {renderTitle()}
      {renderButton()}
      <S.Skip onClick={handleClick} data-value="">
        {t('actions.noIDont')}
      </S.Skip>
      {renderBackground()}
    </Container>
  )
}
