import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledConfirmPurchaseModal = {
  Root: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    display: flex;
    flex-direction: column;
    background-color: rgba(107, 114, 128, 0.75);
  `,
  Content: styled.div`
    position: relative;
    width: calc(100% - 40px);
    max-width: 320px;
    margin: auto;
    padding: 64px 24px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background-color: ${Color.WHITE};
  `,
  Title: styled.p`
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.DARK};
    margin-bottom: 24px;
  `,
  Subtitle: styled.p`
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${Color.LIGHT};
    margin-bottom: 24px;
    overflow-wrap: break-word;
    max-width: 100%;
  `,
  CloseButton: styled.div`
    position: absolute;
    display: flex;
    cursor: pointer;
    top: 16px;
    right: 16px;
    border-radius: 20px;
    background: ${Color.CORAL_GRAY};
    padding: 8px;
    height: 32px;
  `,
}
