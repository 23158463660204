export const QUESTION = 'Creating your chin sculpting plan'

export const ANIMATION_PATH = '/animations/creating-plan-loader'
export const MAX_COUNTER_VALUE = 100

export const SCULPTING_PLAN_REVIEWS = [
  {
    author: 'onboarding.creatingSculptingPlan.reviews.author1',
    review: 'onboarding.creatingSculptingPlan.reviews.review1',
  },
  {
    author: 'onboarding.creatingSculptingPlan.reviews.author2',
    review: 'onboarding.creatingSculptingPlan.reviews.review2',
  },
]
