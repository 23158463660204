import styled from 'styled-components'

import { Color } from 'root-constants'

export const SkinSensitivityStyled = {
  Description: styled.p`
    color: ${Color.GRAY};
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;
    text-align: left;
  `,
}
