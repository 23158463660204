export const QUESTION =
  'Stress can lead to weight gain and fat accumulation around the chin. How do you manage your stress levels?'

const OPTION_VALUES = {
  LONG_SITTING: 'long_sitting',
  MINDFUL_OF_POSTURE: 'mindful_of_posture',
  REGULARLY_EXERCISES: 'regularly_exercises',
}

export const LIFESTYLE_ANSWERS = [
  {
    value: OPTION_VALUES.LONG_SITTING,
    text: 'onboarding.doubleChinLifestyle.longSitting',
  },
  {
    value: OPTION_VALUES.MINDFUL_OF_POSTURE,
    text: 'onboarding.doubleChinLifestyle.mindfulOfPosture',
  },
  {
    value: OPTION_VALUES.REGULARLY_EXERCISES,
    text: 'onboarding.doubleChinLifestyle.regularlyExercises',
  },
]
