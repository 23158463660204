import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { selectLanguage } from 'root-redux/selects/common'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'

import { selectDynamicDiscount } from 'modules/purchase/redux/selects/common'

import { DynamicDiscountTheme, Locale } from 'root-constants'

import { StyledPurchaseTimer as S } from './PurchaseTimer.styles'

type TPurchaseTimerProps = {
  timerRef: React.RefObject<HTMLElement>
}

export const PurchaseTimer: React.FC<TPurchaseTimerProps> = ({
  timerRef,
}: TPurchaseTimerProps) => {
  const { t } = useTranslation()
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const language = useSelector(selectLanguage)

  const isEnLocale = language === Locale.ENGLISH

  const { isDynamicDiscountCohort } = useActiveCohortIdentifier()

  return (
    <S.Container
      theme={
        !isDynamicDiscountCohort
          ? DynamicDiscountTheme.CORAL
          : dynamicDiscount?.theme
      }
    >
      <S.Text isEnLocaleStyle={isEnLocale}>
        <Trans i18nKey="purchase7.duplicateTimer" components={[<br />]} />{' '}
        <strong ref={timerRef} /> {t('purchase7.min')}
      </S.Text>
    </S.Container>
  )
}
