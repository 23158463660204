import React from 'react'
import { Trans } from 'react-i18next'
import { useSelector } from 'react-redux'

import { BeforeAfterUsersGallery } from 'components/BeforeAfterUsersGallery'

import { selectAnswers } from 'root-redux/selects/common'

import { useABTest } from 'hooks/useABTest'
import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'

import { PageId } from 'page-constants'
import { Gender, I18N_CONTEXT_COHORT_MAP } from 'root-constants'

import { StyledLongPaywallUserGoalsGallery as S } from './LongPaywallUserGoalsGallery.styles'
import {
  DOUBLE_CHIN_FEMALE_GALLERY,
  DOUBLE_CHIN_MALE_GALLERY,
  FEMALE_GALLERY,
  MALE_GALLERY,
  USERS_GALLERY,
} from './constants'

export const LongPaywallUserGoalsGallery: React.FC = () => {
  const userAnswers = useSelector(selectAnswers)
  const cohortToUse = useCohortToUse()
  const { isCoralThemeCohort, isLuvlyIntroFastCohort } =
    useActiveCohortIdentifier()
  const { isIntroFastCoralThemeActive } = useABTest()

  const userGender = userAnswers?.[PageId.GENDER]

  const usersGallery = () => {
    if (isLuvlyIntroFastCohort && isIntroFastCoralThemeActive) {
      return userGender === Gender.MALE ? MALE_GALLERY : FEMALE_GALLERY
    }

    if (!isCoralThemeCohort) return USERS_GALLERY

    return userGender === Gender.MALE
      ? DOUBLE_CHIN_MALE_GALLERY
      : DOUBLE_CHIN_FEMALE_GALLERY
  }

  return (
    <S.Container>
      <S.Subtitle>
        <Trans
          i18nKey="purchase7.userGoalsGallery.subtitle"
          context={I18N_CONTEXT_COHORT_MAP[cohortToUse]}
        />
      </S.Subtitle>
      <BeforeAfterUsersGallery gallery={usersGallery()} />
    </S.Container>
  )
}
