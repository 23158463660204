import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useIsCurrentRoute } from 'hooks/useIsCurrentRoute'

import {
  CANCEL_OFFER_ADDITIONAL_DISCOUNT,
  DEFAULT_DYNAMIC_DISCOUNT,
} from 'modules/purchase/constants'
import { selectDynamicDiscount } from 'modules/purchase/redux/selects/common'

import { PageId } from 'page-constants'

import { StyledLongPaywallStaticBanner as S } from './LongPaywallStaticBanner.styles'

export const LongPaywallStaticBanner: React.FC = () => {
  const { t } = useTranslation()
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const isCancelOfferRoute = useIsCurrentRoute(
    PageId.PURCHASE_INTRO_CANCEL_OFFER,
  )

  const discount = dynamicDiscount?.amount || DEFAULT_DYNAMIC_DISCOUNT
  const staticBannerDiscount = isCancelOfferRoute
    ? discount + CANCEL_OFFER_ADDITIONAL_DISCOUNT
    : discount

  return (
    <S.Root theme={dynamicDiscount?.theme}>
      <S.DiscountLabel theme={dynamicDiscount?.theme}>
        <Trans
          i18nKey="purchase7.off"
          values={{ value: staticBannerDiscount }}
        />
      </S.DiscountLabel>
      <S.DiscountText theme={dynamicDiscount?.theme}>
        {t('purchase7.limitedDeal')}
      </S.DiscountText>
    </S.Root>
  )
}
