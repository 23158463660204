export const QUESTION = 'Diets'

export const OPTION_VALUES = {
  NO_DIET: 'no_specific_diet',
  VEGETARIAN: 'vegetarian',
  NO_GLUTEN_DIET: 'no_gluten_diet',
  VEGAN: 'vegan',
  OTHER: 'other',
}

export const DIET_ANSWERS_MAP = {
  [OPTION_VALUES.NO_DIET]: 'onboarding.diet.noDiet.title',
  [OPTION_VALUES.VEGETARIAN]: 'onboarding.diet.vegetarian.title',
  [OPTION_VALUES.NO_GLUTEN_DIET]: 'onboarding.diet.noGlutenDiet.title',
  [OPTION_VALUES.VEGAN]: 'onboarding.diet.vegan.title',
  [OPTION_VALUES.OTHER]: 'onboarding.diet.other.title',
}

export const DIET_OPTIONS = [
  {
    value: OPTION_VALUES.NO_DIET,
    text: 'onboarding.diet.noDiet.title',
    description: 'onboarding.diet.noDiet.description',
  },
  {
    value: OPTION_VALUES.VEGETARIAN,
    text: 'onboarding.diet.vegetarian.title',
    description: 'onboarding.diet.vegetarian.description',
  },
  {
    value: OPTION_VALUES.NO_GLUTEN_DIET,
    text: 'onboarding.diet.noGlutenDiet.title',
    description: 'onboarding.diet.noGlutenDiet.description',
  },
  {
    value: OPTION_VALUES.VEGAN,
    text: 'onboarding.diet.vegan.title',
    description: 'onboarding.diet.vegan.description',
  },
  {
    value: OPTION_VALUES.OTHER,
    text: 'onboarding.diet.other.title',
  },
]

export const DIET2_OPTIONS = [
  {
    text: 'onboarding.diet.noDiet.title',
    detailed: 'onboarding.diet.noDiet.description',
    label: OPTION_VALUES.NO_DIET,
  },
  {
    text: 'onboarding.diet.vegetarian.title',
    detailed: 'onboarding.diet.vegetarian.description',
    label: OPTION_VALUES.VEGETARIAN,
  },
  {
    text: 'onboarding.diet.noGlutenDiet.title2',
    detailed: 'onboarding.diet.noGlutenDiet.description',
    label: OPTION_VALUES.NO_GLUTEN_DIET,
  },
  {
    text: 'onboarding.diet.vegan.title',
    detailed: 'onboarding.diet.vegan.description',
    label: OPTION_VALUES.VEGAN,
  },
]
