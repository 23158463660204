import styled, { css } from 'styled-components'

import bullets from 'assets/images/visible-results-bullets.png'

import { Color, Gender, MediaBreakpoint } from 'root-constants'

type TJoinLuvlyBannerProps = {
  gender: string
}

export const StyledPersonalizedPlan = {
  Root: styled.main`
    padding-bottom: 32px;
    background-color: ${Color.LIGHT_SANDY};
  `,
  Hero: styled.div`
    max-width: 430px;
    margin: 0 auto 8px;
    aspect-ratio: 375/308;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 375px;
    }
  `,
  Title: styled.h1`
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    text-align: center;
    margin-bottom: 18px;
    text-align: center;
    color: ${Color.BLUE_DARK};
  `,
  Subtitle: styled.p`
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-bottom: 24px;
    color: ${Color.GRAY};
  `,
  UserInfo: styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 16px;
    margin-bottom: 8px;
  `,
  UserInfoCard: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    &:nth-child(even) {
      &::before {
        display: block;
        content: '';
        width: 1px;
        height: 68px;
        background-color: #cacaca;
        position: absolute;
        left: -16px;
        top: 50%;
        transform: translateY(-50%);
      }
      &::after {
        display: block;
        content: '';
        width: 1px;
        height: 68px;
        background-color: #cacaca;
        position: absolute;
        right: -16px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    & img {
      max-width: 48px;
      margin-bottom: 8px;
    }

    & span:last-child {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: ${Color.GRAY};
    }
  `,
  UserAnswer: styled.span`
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 4px;
    text-transform: capitalize;
  `,
  SecondaryTitle: styled.h2`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 24px;
    text-align: center;
  `,
  ResultsImage: styled.img`
    margin-bottom: 24px;
  `,
  List: styled.ul`
    padding-left: 36px;
    background: url(${bullets}) no-repeat left center;
    background-size: contain;
    margin-bottom: 40px;

    & li {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  `,
  Duration: styled.h3`
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 4px;
  `,
  Description: styled.p`
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.GRAY};
  `,
  UnlockList: styled.ul`
    margin-bottom: 40px;
  `,
  UnlockOption: styled.li`
    border-radius: 16px;
    border: 1px solid ${Color.WHITE};
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0px 4px 14px 0px rgba(8, 4, 23, 0.03);
    margin-bottom: 12px;
    padding: 0 16px 0 8px;
    display: flex;
    align-items: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    color: ${Color.BLUE_DARK};

    & img {
      max-width: 100px;
      margin-right: 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  `,
  JoinLuvlyBanner: styled.div<TJoinLuvlyBannerProps>`
    border-radius: 36px;
    position: relative;
    margin-bottom: 40px;
    aspect-ratio: 343/320;

    ${({ gender }) =>
      gender === Gender.MALE
        ? css`
            border: 1px solid #6ab3a1;

            & > div {
              border: 1px solid #6ab3a1;
              box-shadow: 0px -16px 48px 0px #346458;
              background-color: #5da392;
            }
          `
        : css`
            border: 1px solid #d4a9ac;

            & > div {
              border: 1px solid rgba(255, 255, 255, 0.24);
              box-shadow: 0px -16px 48px 0px #a6676c;
              background-color: #c78e92;
            }
          `}
  `,
  JoinLuvlyImage: styled.img`
    border-radius: 32px 32px 0 0;
  `,
  PseudoButton: styled.div`
    position: absolute;
    bottom: 0;
    border-radius: 36px;
    padding: 20px 10px;
    width: 100%;
    backdrop-filter: blur(16px);
    color: ${Color.WHITE};
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    text-align: center;
  `,
  Reviews: styled.ul`
    margin-bottom: 32px;
  `,
  Review: styled.li`
    border-radius: 16px;
    background: ${Color.WHITE};
    box-shadow: 0px 8px 20px 0px #e8e8e8;
    margin-bottom: 12px;
    padding: 12px 16px;

    &:last-child {
      margin-bottom: 0;
    }
  `,
  ReviewText: styled.p`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 8px;
  `,
  Reviewer: styled.h4`
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    color: #a569bd;
  `,
  Rating: styled.div`
    display: flex;
    margin-bottom: 8px;

    & svg {
      margin-right: 2px;

      &:last-child {
        margin-right: 0;
      }
    }
  `,
}
