import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AnswerWithCheckbox,
  Button,
  answerWithCheckboxTheme,
  buttonTheme,
} from 'storybook-ui'

import { Container } from 'components/Container'
import { SvgImage } from 'components/SvgImage'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import checkIcon from 'assets/images/check-white.svg'
import problematicAreasSource from 'assets/images/problematic-area-source.png'
import cheeksIcon from 'assets/images/sprite/cheeks.svg'
import chinIcon from 'assets/images/sprite/chin.svg'
import eyesIcon from 'assets/images/sprite/eyes.svg'
import foreheadIcon from 'assets/images/sprite/forehead.svg'
import jawlineIcon from 'assets/images/sprite/jawline.svg'
import mouthIcon from 'assets/images/sprite/mouth.svg'
import neckIcon from 'assets/images/sprite/neck.svg'

import {
  DoubleChinTitle,
  SandyBackgroundGlobalStyles,
  StickyButtonContainer,
} from 'common-styles'
import { PageId } from 'page-constants'
import { Color, OptionType, PROBLEMATIC_AREAS_MAP } from 'root-constants'

import { StyledProblematicAreasV2 as S } from './ProblematicAreasV2.styles'
import {
  GENERAL_PROBLEMATIC_AREAS_OPTIONS,
  OPTION_VALUES,
  QUESTION,
} from './constants'

export const ProblematicAreasV2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<{ value: string; label: string }[]>([])

  const handleContinue = useNextStep({
    pageId: PageId.PROBLEMATIC_AREA,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => {
    const uniqueAnswers = answers.map((answer) => answer.label)
    handleContinue(answers, uniqueAnswers)
  }

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    onChange: (value, isChecked) => {
      if (isChecked) {
        const userAnswer = PROBLEMATIC_AREAS_MAP.find(
          (item) => item.label === value,
        )
        userAnswer && setAnswers([...answers, userAnswer])
      } else {
        setAnswers(answers.filter((item) => item.label !== value))
      }
    },
  }

  const getIconCurrentColor = (value: OPTION_VALUES) => {
    return answers.some((answer) => answer.label === value)
      ? Color.CORAL
      : 'transparent'
  }

  return (
    <S.Root>
      <Container>
        <DoubleChinTitle>
          {t('onboarding.problematicAreas.question')}
        </DoubleChinTitle>
        <S.Split>
          <div>
            <AnswerWithCheckbox
              value={GENERAL_PROBLEMATIC_AREAS_OPTIONS[0].value}
              {...optionProps}
              key={GENERAL_PROBLEMATIC_AREAS_OPTIONS[0].value}
              iconSrc={checkIcon}
              iconSize="12px"
              margin="0 0 8px 0"
              theme={answerWithCheckboxTheme.LUVLY}
            >
              {t(GENERAL_PROBLEMATIC_AREAS_OPTIONS[0].text)}
            </AnswerWithCheckbox>
          </div>
          <div>
            {GENERAL_PROBLEMATIC_AREAS_OPTIONS.map(({ value, text }, index) => {
              if (index) {
                return (
                  <AnswerWithCheckbox
                    value={value}
                    {...optionProps}
                    key={value}
                    iconSrc={checkIcon}
                    margin="0 0 8px 0"
                    iconSize="12px"
                    theme={answerWithCheckboxTheme.LUVLY}
                  >
                    {t(text)}
                  </AnswerWithCheckbox>
                )
              }

              return null
            })}
          </div>
        </S.Split>

        <S.ImageContainer>
          <img src={problematicAreasSource} alt="" />
          <S.CheeksIcon color={getIconCurrentColor(OPTION_VALUES.CHEEKS)}>
            <SvgImage svg={cheeksIcon} width={78} />
          </S.CheeksIcon>
          <S.ChinIcon color={getIconCurrentColor(OPTION_VALUES.CHIN)}>
            <SvgImage svg={chinIcon} width={43} />
          </S.ChinIcon>
          <S.EyesIcon color={getIconCurrentColor(OPTION_VALUES.EYES)}>
            <SvgImage svg={eyesIcon} width={56} />
          </S.EyesIcon>
          <S.ForeheadIcon color={getIconCurrentColor(OPTION_VALUES.FOREHEAD)}>
            <SvgImage svg={foreheadIcon} width={69} />
          </S.ForeheadIcon>
          <S.JawlineIcon color={getIconCurrentColor(OPTION_VALUES.JAWLINE)}>
            <SvgImage svg={jawlineIcon} width={84} />
          </S.JawlineIcon>
          <S.MouthIcon color={getIconCurrentColor(OPTION_VALUES.MOUTH)}>
            <SvgImage svg={mouthIcon} width={39} />
          </S.MouthIcon>
          <S.NeckIcon color={getIconCurrentColor(OPTION_VALUES.NECK)}>
            <SvgImage svg={neckIcon} width={72} />
          </S.NeckIcon>
        </S.ImageContainer>
      </Container>
      <SandyBackgroundGlobalStyles />
      <StickyButtonContainer customBackground="transparent" centerMode>
        <Button
          onClick={handleNextClick}
          disabled={!answers.length}
          theme={buttonTheme.LUVLY_SECONDARY}
        >
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
    </S.Root>
  )
}
