/* eslint-disable max-lines */
import * as Sentry from '@sentry/react'
import { commonApi, faceScanApi, userApi } from 'api'
import i18n from 'i18next'

import {
  setAnswersAction,
  setAnswersFromBackendAction,
  setErrorAction,
  setUtmTags,
  startFetching,
  stopFetching,
} from 'root-redux/actions/common'
import { selectAnswers, selectUtmTags } from 'root-redux/selects/common'
import { selectAuthToken, selectUUID } from 'root-redux/selects/user'

import { getCookie } from 'helpers/getCookie'
import { getUserStatusFromRawUserStatus } from 'helpers/getUserStatusFromRawUserStatus'

import { SET_IS_SUBSCRIPTION_UPGRADED } from 'modules/purchase/redux/actions/common'

import { TAnswers, TUtmTags } from 'models/common.model'
import {
  IAction,
  IAppState,
  TAppActionThunk,
  TAppDispatchThunk,
} from 'models/store.model'
import { IGetUUIDResponseRaw, IUserStatus } from 'models/user.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import { PageId } from 'page-constants'
import {
  AvailableFortuneWheelAttempts,
  CUSTOM_TOKEN_LOCAL_STORAGE_KEY,
} from 'root-constants'

const MODULE_NAME = 'USER'

export const SET_UUID = `${MODULE_NAME}/SET_UUID`
export const SET_SECRET = `${MODULE_NAME}/SET_SECRET`
export const GET_STATUS = `${MODULE_NAME}/GET_STATUS`
export const SET_STATUS = `${MODULE_NAME}/SET_STATUS`
export const SET_AUTH_TOKEN = `${MODULE_NAME}/SET_AUTH_TOKEN`
export const SET_USER_SUBSCRIPTION_INFO = `${MODULE_NAME}/SET_USER_SUBSCRIPTION_INFO`
export const SET_USER_CONTACT_EMAIL = `${MODULE_NAME}/SET_USER_CONTACT_EMAIL`
export const SET_DELETED_SUBSCRIPTION_ID = `${MODULE_NAME}/SET_DELETED_SUBSCRIPTION_ID`
export const SET_TEST_ENVIRONMENT_QUERY_PARAM = `${MODULE_NAME}/SET_TEST_ENVIRONMENT_QUERY_PARAM`
export const SEND_USER_ANSWERS = `${MODULE_NAME}/SEND_USER_ANSWERS`
export const SEND_USER_EMAIL = `${MODULE_NAME}/SEND_USER_EMAIL`
export const SEND_USER_EMAIL_CONSENT = `${MODULE_NAME}/SEND_USER_EMAIL_CONSENT`
export const SEND_USER_CONFIG = `${MODULE_NAME}/SEND_USER_CONFIG`
export const SET_IS_PERSONAL_DATA_ALLOWED = `${MODULE_NAME}/SET_IS_PERSONAL_DATA_ALLOWED`
const BIND_USER = `${MODULE_NAME}/BIND_USER`
const SAVE_PLAN_ADDITIONS = `${MODULE_NAME}/SAVE_PLAN_ADDITIONS`
const SEND_USER_TTCLID = `${MODULE_NAME}/SEND_USER_TTCLID`
const SEND_USER_SCCID = `${MODULE_NAME}/SEND_USER_SCCID`
const SEND_USER_RDTCID = `${MODULE_NAME}/SEND_USER_RDTCID`
export const SET_USER_AVAILABLE_SPINS = `${MODULE_NAME}/SET_USER_AVAILABLE_SPINS`

export function setUUIDAction(uuid: string): IAction<string> {
  return {
    type: SET_UUID,
    payload: uuid,
  }
}

export function setUserStatusAction(
  userStatus: IUserStatus,
): IAction<IUserStatus> {
  return {
    type: SET_STATUS,
    payload: userStatus,
  }
}

export function setSecretAction(secret: string): IAction<string> {
  return {
    type: SET_SECRET,
    payload: secret,
  }
}

export function setAuthTokenAction(authToken: string): IAction<string> {
  return {
    type: SET_AUTH_TOKEN,
    payload: authToken,
  }
}

export function setUserContactEmailAction(
  customerContactEmail: string,
): IAction<string> {
  return {
    type: SET_USER_CONTACT_EMAIL,
    payload: customerContactEmail,
  }
}

export function setIsPersonalDataAllowedAction(
  payload: boolean,
): IAction<boolean> {
  return {
    type: SET_IS_PERSONAL_DATA_ALLOWED,
    payload,
  }
}

export function setDeletedSubscriptionIdAction(
  subscriptionId: string,
): IAction<string> {
  return {
    type: SET_DELETED_SUBSCRIPTION_ID,
    payload: subscriptionId,
  }
}

export function setTestEnvironmentQueryParamAction(
  testEnvironmentQueryParam: string,
): IAction<string> {
  return {
    type: SET_TEST_ENVIRONMENT_QUERY_PARAM,
    payload: testEnvironmentQueryParam,
  }
}

export function setIsSubscriptionUpgradedAction(
  isUpgraded: boolean,
): IAction<boolean> {
  return {
    type: SET_IS_SUBSCRIPTION_UPGRADED,
    payload: isUpgraded,
  }
}

export function setUserAvailableSpinsAction(
  spins: AvailableFortuneWheelAttempts,
): IAction<number> {
  return {
    type: SET_USER_AVAILABLE_SPINS,
    payload: spins,
  }
}

export const setUpUUIDAction =
  ({
    cohort,
    uuid: uuidFromUrl,
    utmTags: utmTagsFromUrl,
    giaApiKey,
    language,
  }: {
    cohort: string
    uuid: string | null
    utmTags: TUtmTags | null
    giaApiKey: string
    language: string
  }): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuidFromStore = selectUUID(state)
    const uuid = uuidFromUrl || uuidFromStore
    const utmTagsFromStore = selectUtmTags(state)
    const utmTags = utmTagsFromUrl || utmTagsFromStore

    if (uuid) {
      dispatch(setUUIDAction(uuid))
      return
    }

    const response = await userApi.getUUID({
      cohort,
      appId: giaApiKey,
      locale: language,
      utm: utmTags,
    })

    if (response.success) {
      const responseUUID = (response.data as IGetUUIDResponseRaw).value
      dispatch(setUUIDAction(responseUUID))
    } else {
      console.error('error')
    }
  }

export const sendFacebookParamsAction =
  (): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)
    const fbp = getCookie('_fbp')
    const fbc = getCookie('_fbc')

    await userApi.saveFacebookParams({
      uuid,
      fbp,
      fbc,
    })
  }

export const sendGoogleAdsParamsAction =
  (): any =>
  async (_, getState: () => IAppState): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)

    const urlParams = new URLSearchParams(window.location.search)
    const gclid = urlParams.get('gclid') || ''
    const gaid = getCookie('_ga')

    await userApi.saveGoogleAdsParams({
      uuid,
      gclid,
      gaid,
    })
  }

export function getCustomerInfoAction(): TAppActionThunk<any> {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const authToken = selectAuthToken(state)

    const response = await commonApi.getCustomerInfo(authToken)

    if (response.success && response.data) {
      dispatch(setUserContactEmailAction(response.data.customer.contact_email))
      dispatch(setUUIDAction(response.data.customer.public_id))
    }
  }
}

export function bindUserAction(token: string): TAppActionThunk<any> {
  return async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ) => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(BIND_USER))

    try {
      const response = await userApi.bindUser({ token, uuid })

      if (response.success && response.data) {
        response.data.custom_token &&
          localStorage.setItem(
            CUSTOM_TOKEN_LOCAL_STORAGE_KEY,
            response.data.custom_token,
          )

        const userStatusResponse = await userApi.getUserStatus(uuid)
        const userStatus = getUserStatusFromRawUserStatus(
          userStatusResponse.data.state,
        )

        eventLogger.logAccountCreated({
          method: userStatus.account.loginMethod,
        })
        googleAnalyticsLogger.logAccountCreated()
        dispatch(setUserStatusAction(userStatus))
        return
      }
      eventLogger.logAccountCreationFailed({ error: response?.data?.error })

      if (response.status === 409) {
        const errorMessage = response?.data?.error || ''
        const message = errorMessage.toLowerCase().includes('subscription')
          ? i18n.t('login.refundSubscriptionError')
          : i18n.t('login.haveAccountError')

        dispatch(setErrorAction(message))
        dispatch(stopFetching(BIND_USER))
        return
      }

      dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
    } catch (err) {
      Sentry.captureMessage(
        `[FACETORY ACCOUNT CREATION FAILED]: User ${uuid} NOT created account in facetory using google, facebook or apple sign up method with error. Error: ${err}`,
      )
    }

    dispatch(stopFetching(BIND_USER))
  }
}

export function getUserStatusAction(uuid: string): any {
  return async (dispatch) => {
    dispatch(startFetching(GET_STATUS))

    const response = await userApi.getUserStatus(uuid)
    if (response.success && response.data) {
      const { state } = response.data

      const userStatus = getUserStatusFromRawUserStatus(state)
      dispatch(setUserStatusAction(userStatus))
      dispatch(setAnswersFromBackendAction(userStatus.onboarding))
      dispatch(setUtmTags(userStatus.config?.utmTags))
    }

    if (!response.success && response.status === 404) {
      const { search } = window.location
      const urlParams = new URLSearchParams(search)
      urlParams.delete('uuid')

      // use window.location.search to reload the page with updated query params
      window.location.search = `?${urlParams}`
    }

    dispatch(stopFetching(GET_STATUS))
  }
}

export const sendUserAnswersAction =
  (unsuccessCallback?: (() => void) | null, isFinished = false): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const answers = selectAnswers(state) as TAnswers
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_USER_ANSWERS))

    const response = await userApi.saveUserAnswers({
      uuid,
      answers,
      isFinished,
    })

    if (!response.success) {
      dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))

      unsuccessCallback && unsuccessCallback()
    }

    dispatch(stopFetching(SEND_USER_ANSWERS))
  }

export const sendUserConfigAction =
  (
    config: Record<
      string,
      string | boolean | number | string[] | Record<string, string>
    >,
    unsuccessCallback?: (() => void) | null,
  ): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_USER_CONFIG))

    const response = await userApi.saveUserConfig({
      uuid,
      config,
    })

    if (!response.success) {
      dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))

      unsuccessCallback && unsuccessCallback()
    }

    dispatch(stopFetching(SEND_USER_CONFIG))
  }

export const sendUserEmailAction =
  ({
    email,
    unsuccessCallback,
    isConsentRequired,
  }: {
    email: string
    unsuccessCallback?: (() => void) | null
    isConsentRequired?: boolean
  }): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_USER_EMAIL))

    const response = await userApi.saveUserEmail({
      uuid,
      email,
      consentRequired: isConsentRequired,
    })

    if (!response.success) {
      dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))

      unsuccessCallback && unsuccessCallback()
      dispatch(stopFetching(SEND_USER_ANSWERS))
      return
    }

    dispatch(getUserStatusAction(uuid))

    dispatch(stopFetching(SEND_USER_EMAIL))
  }

export const sendUserEmailConsentAction =
  ({ consented }: { consented: boolean }): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_USER_EMAIL_CONSENT))

    const response = await userApi.saveUserEmailConsent({
      uuid,
      consented,
    })

    if (!response.success) {
      dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))

      dispatch(stopFetching(SEND_USER_ANSWERS))
      return
    }

    dispatch(getUserStatusAction(uuid))

    dispatch(stopFetching(SEND_USER_EMAIL_CONSENT))
  }

export const analyseFacePictureAction =
  (image: Blob) =>
  async (dispatch: TAppDispatchThunk<any>): Promise<void> => {
    const response = await faceScanApi.sendCameraImage(image)
    const id = response?.data?.task_id

    if (id) {
      const getMask = async (imgId: string) => {
        const result = await faceScanApi.getAnalysisResultImage(imgId)

        if (result.status === 200) {
          dispatch(
            setAnswersAction({
              answers: result.data,
              pageId: PageId.FACE_SCAN,
            }),
          )

          const analysisResponse = await faceScanApi.getAnalysisResultData(id)
          const plainResults = Object.keys(analysisResponse.data).filter(
            (key) => analysisResponse.data[key],
          )

          eventLogger.logFaceScanCompleted(plainResults)
          dispatch(
            setAnswersAction({
              answers: plainResults,
              pageId: PageId.FACE_SCAN_RESULTS,
            }),
          )
        } else {
          setTimeout(() => getMask(imgId), 500)
        }
      }

      await getMask(id)
    }
  }

export const savePlanAdditionsAction =
  (planAdditions: string[], unsuccessCallback?: (() => void) | null): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)

    if (!planAdditions.length) return

    dispatch(startFetching(SAVE_PLAN_ADDITIONS))

    const response = await userApi.savePlanAdditions({
      uuid,
      planAdditions,
    })

    if (!response.success) {
      const errorMessage =
        response?.data?.error || i18n.t('login.somethingWentWrongError')

      dispatch(setErrorAction(errorMessage))

      unsuccessCallback && unsuccessCallback()
      dispatch(stopFetching(SAVE_PLAN_ADDITIONS))
      return
    }

    dispatch(stopFetching(SAVE_PLAN_ADDITIONS))
  }

export const sendUserRDTCID =
  (rdtcid: string): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_USER_RDTCID))

    try {
      const response = await userApi.saveUserRDTCID({ rdtcid, uuid })

      if (!response.success) {
        dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
      }

      dispatch(stopFetching(SEND_USER_RDTCID))
    } catch (error: unknown) {
      if (error instanceof Error) {
        dispatch(setErrorAction(error.toString()))
        dispatch(stopFetching(SEND_USER_RDTCID))

        return
      }

      dispatch(setErrorAction(String(error)))
      dispatch(stopFetching(SEND_USER_RDTCID))
    }
  }

export const sendUserTTCLID =
  (ttclid: string): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_USER_TTCLID))

    try {
      const response = await userApi.saveUserTTCLID({ ttclid, uuid })

      if (!response.success) {
        dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
      }

      dispatch(stopFetching(SEND_USER_TTCLID))
    } catch (error: unknown) {
      if (error instanceof Error) {
        dispatch(setErrorAction(error.toString()))
        dispatch(stopFetching(SEND_USER_TTCLID))

        return
      }

      dispatch(setErrorAction(String(error)))
      dispatch(stopFetching(SEND_USER_TTCLID))
    }
  }

export const sendUserSCCID =
  (sccid: string, scid: string, unsuccessCallback?: () => void): any =>
  async (
    dispatch: TAppDispatchThunk<any>,
    getState: () => IAppState,
  ): Promise<void> => {
    const state = getState()
    const uuid = selectUUID(state)

    dispatch(startFetching(SEND_USER_SCCID))

    const response = await userApi.saveUserSCCID({ sccid, uuid, scid })

    if (!response.success) {
      dispatch(setErrorAction(i18n.t('login.somethingWentWrongError')))
      unsuccessCallback && unsuccessCallback()
      dispatch(stopFetching(SEND_USER_SCCID))
      return
    }

    dispatch(stopFetching(SEND_USER_SCCID))
  }
