import { useMemo } from 'react'

import { Cohort, DYNAMIC_DISCOUNT_COHORTS } from 'root-constants'

import { useCohortToUse } from './useCohortToUse'

export const useActiveCohortIdentifier = () => {
  const cohortToUse = useCohortToUse()

  return useMemo(
    () => ({
      isLuvlyIntroFastCohort: cohortToUse === Cohort.LUVLY_INTRO_FAST,
      isCosmeticCohort: cohortToUse === Cohort.LUVLY_COSMETIC_SCANNER,
      isSeniorCohort: cohortToUse === Cohort.LUVLY_12_SALE,
      isWellnessBundleCohort:
        cohortToUse === Cohort.LUVLY_CHAT_UPGRADE_WELLNESS_BUNDLE,
      isChatCohort: cohortToUse === Cohort.LUVLY_CHAT,
      isUpgradeBundleCohort:
        cohortToUse === Cohort.LUVLY_CHAT_UPGRADE_BUNDLE_PDF,
      isDynamicDiscountCohort: DYNAMIC_DISCOUNT_COHORTS.includes(cohortToUse),
      isLuvlyIntroSaleCohort: cohortToUse === Cohort.LUVLY_INTRO_SALE,
      isLuvly12SaleCohort: cohortToUse === Cohort.LUVLY_12_SALE,
      isIntro3SaleCohort: cohortToUse === Cohort.LUVLY_INTRO3_SALE,
      isIntro5SaleCohort: cohortToUse === Cohort.LUVLY_INTRO5_SALE,
      isIntro6SaleCohort: cohortToUse === Cohort.LUVLY_INTRO6_SALE,
      isRefundCohort:
        cohortToUse === Cohort.LUVLY_REF ||
        cohortToUse === Cohort.LUVLY_REF4 ||
        cohortToUse === Cohort.LUVLY_REF5 ||
        cohortToUse === Cohort.LUVLY_PRINTABLE,
      isLuvlyCancelIntro1Cohort: cohortToUse === Cohort.LUVLY_CANCEL_INTRO_1,
      isLuvlyChinCohort: cohortToUse === Cohort.LUVLY_CHIN,
      isLuvlyEmailSaleCohort: cohortToUse === Cohort.LUVLY_EMAIL_SALE,
      isLuvlyInfluencersCohort: cohortToUse === Cohort.LUVLY_INFLUENCERS,
      isLuvlyGiftCardCohort: cohortToUse === Cohort.LUVLY_GIFT,
      isLuvlyKoreanCohort: cohortToUse === Cohort.LUVLY_KOREAN,
      isLuvlyGeneralCohort: cohortToUse === Cohort.LUVLY_GENERAL,
      isCoralThemeCohort:
        cohortToUse === Cohort.LUVLY_GENERAL ||
        cohortToUse === Cohort.LUVLY_CHIN,
      isLuvlyWellhubCohort: cohortToUse === Cohort.LUVLY_WELLHUB,
      isLuvlyEmailCoaching: cohortToUse === Cohort.LUVLY_EMAIL_COACHING,
      isLuvlyRef5Cohort: cohortToUse === Cohort.LUVLY_REF5,
      isLuvlyPrintableCohort: cohortToUse === Cohort.LUVLY_PRINTABLE,
    }),
    [cohortToUse],
  )
}
