import styled from 'styled-components'

import greenCheckbox from 'assets/images/check-icon-full-green.svg'

import { Color } from 'root-constants'

type TProps = {
  isSelected: boolean
}

export const StyledSelectUpgradeItem = {
  Wrapper: styled.div<TProps>`
    margin-bottom: 8px;
    border-radius: 20px;
    padding: 16px;
    background: ${Color.WHITE};
    box-shadow: ${({ isSelected }) =>
      isSelected ? `none` : `0px 8px 20px 0px #D1D6EB`};
    border: ${({ isSelected }) =>
      isSelected ? `2px solid #89CC45;` : `2px solid transparent`};
  `,
  Content: styled.div<TProps>`
    padding-left: 32px;
    position: relative;

    &:after {
      content: '';
      width: 24px;
      height: 24px;
      mix-blend-mode: normal;
      border: 1px solid #555965;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      transition: opacity 0.5s ease-out;
      ${({ isSelected }) =>
        isSelected &&
        `
          background: url(${greenCheckbox}) no-repeat center;
          border: none;
          background-size: cover;
        `}
    }
  `,

  PriceRow: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  `,

  PlanPeriod: styled.span<TProps>`
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: ${Color.DARK_SECONDARY};
  `,
  Discount: styled.div`
    border-radius: 35px;
    background: linear-gradient(97deg, #70cd1c 3.15%, #a2ea35 88.83%);
    padding: 4px 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.2px;
    color: ${Color.WHITE};
  `,
  PreviousPrice: styled.span<TProps>`
    text-align: center;
    text-decoration: line-through;
    text-decoration-color: #e65c3e;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.2px;
    color: #e74c3c;
  `,
  CurrentPrice: styled.p<TProps>`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    min-width: 98px;
    object-fit: contain;
    color: ${Color.LIGHT};
    transition: background 0.5s ease-out;
    background-repeat: no-repeat;
    background-position: center;
  `,
  PriceValue: styled.span`
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.2px;
    color: ${Color.DARK_SECONDARY};
  `,
  Period: styled.span`
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.2px;
    color: ${Color.DARK_SECONDARY};
  `,
  BonusContainer: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: ${Color.PRIMARY};
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    padding-top: 12px;
    border-top: 1px solid #e6e9f6;
    margin-top: 12px;

    > :not(:last-of-type) {
      margin: 0 4px 0 0;
    }
  `,
  BonusTitle: styled.p`
    font-weight: 600;
  `,
  BonusDescription: styled.p`
    max-width: 144px;
    font-size: 12px;
    line-height: 16px;
  `,
  BonusPrice: styled.p`
    align-self: center;
  `,
}
