import React, { useCallback, useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { BeforeAfterUsersGallery } from 'components/BeforeAfterUsersGallery'
import { Container } from 'components/Container'
import { IntroHeader } from 'components/IntroHeader'
import { IntroHeaderColor } from 'components/IntroHeader/constants'
import { PartnersContainer } from 'components/PartnersContainer'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { sendFacebookParamsAction } from 'root-redux/actions/user'
import { selectUUID } from 'root-redux/selects/user'

import { useNextStep } from 'hooks/useNextStep'
import { useStartSession } from 'hooks/useStartSession'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import ambassadorHero from 'assets/images/ambassador-intro-main.png'
import ambassadorRounded from 'assets/images/ambassador-rounded.png'

import {
  OBSERVABLE_BLOCKS,
  fixedButtonParams,
  intersectionOptions,
  scrolledValues,
} from '../IntroVariant2/constants'
import { StyledIntroInfluencers as S } from './IntroInfluencers.styles'
import { BEFORE_AFTER_WOMEN_GALLERY, QUESTION } from './constants'

export const IntroInfluencers: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const uuid = useSelector(selectUUID)

  useStartSession()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => {
    dispatch(sendFacebookParamsAction())
    window.fbq('track', 'CustomizeProduct', {}, { eventID: uuid })
    googleAnalyticsLogger.logFirstPageCompleted()
    eventLogger.logFirstPageCompleted({
      question: QUESTION,
    })

    handleContinue('')
  }

  const handleReachingElement = useCallback(
    (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
      const [entry] = entries
      if (entry.isIntersecting) {
        eventLogger.logFirstPageScrolled({
          scrolledPart: scrolledValues[entry.target.id],
        })
        observer.unobserve(entry.target)
      }
    },
    [],
  )

  useEffect(() => {
    const observers = Object.values(OBSERVABLE_BLOCKS)
      .map((block) => {
        const targetBlock = document.getElementById(block)

        if (!targetBlock) {
          return null
        }

        const blockObserver = new IntersectionObserver(
          (entries, observer) => handleReachingElement(entries, observer),
          intersectionOptions,
        )
        blockObserver.observe(targetBlock)

        return blockObserver
      })
      .filter((observer) => !!observer)

    return () => {
      observers.forEach((observer) => {
        observer?.disconnect()
      })
    }
  }, [handleReachingElement])

  return (
    <S.Root>
      <IntroHeader colorTheme={IntroHeaderColor.DARK} />
      <S.Hero>
        <S.Banner src={ambassadorHero} alt="banner" />
      </S.Hero>
      <Container>
        <S.Title>
          <Trans
            i18nKey="onboarding.introAmbassador.title"
            components={[<strong />]}
          />
        </S.Title>
        <S.Subtitle>{t(`onboarding.introV2.subtitle`)}</S.Subtitle>
        <S.AmbassadorCard>
          <S.AmbassadorPhoto>
            <img src={ambassadorRounded} alt="ambassador" />
          </S.AmbassadorPhoto>
          <S.AmbassadorInfo>
            <S.AmbassadorTitle>
              {t('onboarding.introAmbassador.ambassadorTitle')}
            </S.AmbassadorTitle>
            <S.AmbassadorResult>
              {t('onboarding.introAmbassador.ambassadorResult')}
            </S.AmbassadorResult>
          </S.AmbassadorInfo>
        </S.AmbassadorCard>
        <S.FixedButton
          onClick={handleNextClick}
          scrollParams={fixedButtonParams}
        >
          <S.ButtonContent>{t(`onboarding.introV2.takeAQuiz`)}</S.ButtonContent>
        </S.FixedButton>
      </Container>
      <S.GalleryContainer id={OBSERVABLE_BLOCKS.REVIEWS_BLOCK}>
        <S.GalleryTitle>{t(`onboarding.introV2.reviewsTitle`)}</S.GalleryTitle>
        <S.GallerySubtitle>
          <Trans
            i18nKey="onboarding.introV2.reviewsSubtitle"
            components={[<strong />]}
          />
        </S.GallerySubtitle>
        <BeforeAfterUsersGallery gallery={BEFORE_AFTER_WOMEN_GALLERY} />
      </S.GalleryContainer>
      <Container>
        <PartnersContainer />
        <S.FaceYogaCourse />
        <S.Terms id={OBSERVABLE_BLOCKS.TERMS_BLOCK}>
          <p>{t`onboarding.intro.termsTitle`}</p>
          <TermsOfUseLink />
          <S.Separator />
          <PrivacyPolicyLink />
        </S.Terms>
      </Container>
    </S.Root>
  )
}
