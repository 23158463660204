import styled, { css } from 'styled-components'

import { Button } from 'components/Button'
import { Container as BaseContainer } from 'components/Container'

import { TLocaleStylesProps } from 'modules/purchase/types'

import { Color, MediaBreakpoint } from 'root-constants'

export const StyledPurchaseVariant8 = {
  Wrapper: styled.div`
    padding-bottom: 40px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      max-width: 375px;
      margin: 0 auto;
    }
  `,
  NegativeOffsetContainer: styled.div`
    margin: -15px auto 0;
  `,
  TimerContainer: styled.div<{ hasShadow?: boolean }>`
    width: 100%;
    background-color: ${Color.WHITE};
    position: sticky;
    top: 0;
    z-index: 2;
    ${({ hasShadow }) =>
      hasShadow &&
      css`
        box-shadow: 0px 8px 20px 0px rgba(209, 214, 235, 0.75);
      `}
  `,
  TimerContent: styled.div`
    margin: 0 auto;
    max-width: 375px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
  `,
  TimerText: styled.div`
    color: #252d48;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    margin-right: 14px;
  `,
  CountdownTime: styled.p`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    height: 28px;
  `,
  TopButton: styled(Button)`
    width: 50%;
    font-size: 17px;
    font-weight: 600;
    background-color: ${Color.CORAL};
  `,
  ContainerWrapper: styled.div<TLocaleStylesProps>`
    max-width: 375px;
    margin: 96px auto 0;
    padding: 0 16px;
  `,
  SubscriptionsContainer: styled.div`
    margin-top: 24px;

    &:last-of-type {
      padding-bottom: 16px;
    }
  `,
  ButtonContainer: styled.div`
    margin: -6px 0 24px;
  `,
  NumberOneImage: styled.img`
    width: 288px;
    margin: 0 auto 24px;
  `,
  Subtitle: styled.h2`
    color: ${Color.DARK};
    text-align: center;
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
  `,
  Container: styled(BaseContainer)<TLocaleStylesProps>`
    padding: 0 16px;
  `,
}
