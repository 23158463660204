import React, { useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'
import { Button, buttonTheme } from 'storybook-ui'

import { AdaptiveContainer } from 'components/AdaptiveContainer'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { CustomBackgroundStyles } from 'common-styles'
import { PageId } from 'page-constants'
import {
  CDN_FOLDER_LINK,
  DEFAULT_USER_AGE,
  GENDER_TRANSLATIONS_MAP,
  Gender,
} from 'root-constants'

import { StyledYouAreInGoodHandsVariant2 as S } from './YouAreInGoodHandsVariant2.styles'
import { ANIMATION_PATH, QUESTION_2 } from './constants'

export const YouAreInGoodHandsVariant3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}: TPageProps) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const animationContainerRef = useRef<HTMLDivElement | null>(null)

  const gender = userAnswers?.[PageId.GENDER] || Gender.NON_BINARY
  const age = userAnswers?.[PageId.AGE] || DEFAULT_USER_AGE

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION_2,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue('')

  useEffect(() => {
    if (animationContainerRef && animationContainerRef.current) {
      lottie.loadAnimation({
        container: animationContainerRef.current,
        path: `${CDN_FOLDER_LINK}${ANIMATION_PATH}.json`,
        loop: true,
      })
    }

    return () => lottie.destroy()
  }, [])

  return (
    <S.Root background={CustomBackgroundStyles.BLUE_TO_BEIGE}>
      <AdaptiveContainer hasCustomBackground>
        <S.Description2>
          {t('onboarding.youAreInGoodHands.question2')}
        </S.Description2>
        <S.AnimationContainer ref={animationContainerRef} />
        <S.Description2>
          <Trans
            i18nKey="onboarding.youAreInGoodHands.subtitle3"
            values={{
              gender: t(GENDER_TRANSLATIONS_MAP[gender]),
              age,
            }}
          />
        </S.Description2>
        <StickyButtonContainer centerMode>
          <Button
            onClick={handleNextClick}
            theme={buttonTheme.LUVLY_SECONDARY}
            maxWidth="488px"
          >
            {t('actions.next')}
          </Button>
        </StickyButtonContainer>
      </AdaptiveContainer>
    </S.Root>
  )
}
