import { useLayoutEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import {
  selectAmplitudeApiKey,
  selectGiaApiKeys,
  selectStripeAccountId,
  selectStripeAccountName,
  selectUtmTags,
} from 'root-redux/selects/common'
import { selectUUID, selectUserStatus } from 'root-redux/selects/user'

import { getCurrentEnv } from 'helpers/getCurrentEnv'
import { getQueryParamsFromSearchString } from 'helpers/getQueryParamsFromSearchString'

import { TGiaApiKeys } from 'models/config.model'

import { Amplitude } from 'services/amplitude.service'
import { eventLogger } from 'services/eventLogger.service'

import {
  CurrentEnvironment,
  EventLoggerInstanceName,
  ScreenName,
  TEST_ENV_QUERY_PARAM,
} from 'root-constants'

import { useCookieConsentAnswer } from './useCookieConsentAnswer'

export function useAmplitudeInitialization(
  cohort = '',
  screenName?: ScreenName,
): Amplitude | null {
  const { search } = useLocation()
  const uuid = useSelector(selectUUID)
  const amplitudeApiKey = useSelector(selectAmplitudeApiKey)
  const userStatus = useSelector(selectUserStatus)
  const giaApiKeys = useSelector(selectGiaApiKeys) as TGiaApiKeys
  const stripeAccountId = useSelector(selectStripeAccountId)
  const stripeAccountName = useSelector(selectStripeAccountName)
  const utmTags = useSelector(selectUtmTags)

  const {
    hasAnalyticsAndPerformanceCookie,
    userCookieConsentAnswer,
    isEUUser,
  } = useCookieConsentAnswer()

  const [amplitudeInstance, setAmplitudeInstance] = useState<Amplitude | null>(
    null,
  )

  const shouldAmplitudeBePaused = useMemo(() => {
    return (
      (isEUUser && !userCookieConsentAnswer?.length) ||
      (isEUUser &&
        !!userCookieConsentAnswer?.length &&
        !hasAnalyticsAndPerformanceCookie)
    )
  }, [hasAnalyticsAndPerformanceCookie, userCookieConsentAnswer, isEUUser])

  const URLParams = new URLSearchParams(search)
  const isTestEnvironment = URLParams.has(TEST_ENV_QUERY_PARAM)
  const isDevEnvironment = getCurrentEnv() !== CurrentEnvironment.PROD

  useLayoutEffect(() => {
    if (eventLogger.isAmplitudeActive || shouldAmplitudeBePaused) return

    const isAmplitudeTriggerScreen =
      screenName === ScreenName.CHECKOUT ||
      screenName === ScreenName.WELLHUB_LOGIN

    if (
      uuid &&
      amplitudeApiKey &&
      giaApiKeys &&
      (userStatus?.hasSubscription || isAmplitudeTriggerScreen)
    ) {
      const amplitudeService = new Amplitude({
        apiKey: amplitudeApiKey,
        instanceName: EventLoggerInstanceName.AMPLITUDE,
        userId: uuid,
        utmTags: Object.entries(utmTags).length
          ? utmTags
          : getQueryParamsFromSearchString(search),
        isTestEnvironment,
        isDevEnvironment,
      })
      amplitudeService.configure({ cohort })

      if (stripeAccountId && stripeAccountName) {
        amplitudeService?.updateUserProperties(
          stripeAccountId,
          stripeAccountName,
        )
      }

      eventLogger.init(amplitudeService)
      setAmplitudeInstance(amplitudeService)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    uuid,
    amplitudeApiKey,
    giaApiKeys,
    stripeAccountId,
    stripeAccountName,
    userStatus,
    screenName,
    shouldAmplitudeBePaused,
  ])

  return amplitudeInstance
}
