import { GOAL_V3_OPTION_LABELS } from '../Goal/constants'

export const QUESTION = 'Welcome to your chin sculpting program!'

export const PROGRAM_STEPS = {
  [GOAL_V3_OPTION_LABELS.MORE_CONFIDENT]: [
    'onboarding.doubleChin.step1',
    'onboarding.doubleChin.step2',
    'onboarding.doubleChin.step3',
  ],
  [GOAL_V3_OPTION_LABELS.LOOK_YOUNG]: [
    'onboarding.doubleChin.step1',
    'onboarding.doubleChin.step4',
    'onboarding.doubleChin.step5',
  ],
  [GOAL_V3_OPTION_LABELS.MORE_SCULPTED]: [
    'onboarding.doubleChin.step1',
    'onboarding.doubleChin.step2',
    'onboarding.doubleChin.step6',
  ],
  [GOAL_V3_OPTION_LABELS.REDUCE_FACE_FAT]: [
    'onboarding.doubleChin.step1',
    'onboarding.doubleChin.step7',
    'onboarding.doubleChin.step8',
  ],
}
