import React from 'react'

import { useABTest } from 'hooks/useABTest'
import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'

import { PurchaseVariant7 } from '../PurchaseVariant7'
import { PurchaseVariant8 } from '../PurchaseVariant8'

export const PurchaseIntroFastProvider: React.FC = () => {
  const { isIntroFastCoralThemeActive } = useABTest()
  const { isLuvlyIntroFastCohort } = useActiveCohortIdentifier()

  if (isLuvlyIntroFastCohort && isIntroFastCoralThemeActive) {
    return <PurchaseVariant8 />
  }

  return <PurchaseVariant7 />
}
