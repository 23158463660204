import React, { useEffect, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'
import { Button, buttonTheme } from 'storybook-ui'

import { Container } from 'components/Container'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { selectLanguage } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import harvardMedicalSchool from 'assets/images/harvard-medical-school-logo-v2.png'
import northwesternUniversity from 'assets/images/northwestern-university-logo-v2.png'

import { SandyBackgroundGlobalStyles } from 'common-styles'
import { CDN_FOLDER_LINK } from 'root-constants'

import { StyledReverseYourWrinkles3 as S } from './ReverseYourWrinkles3.styles'
import { ANIMATION_PATH, QUESTION } from './constants'

export const ReverseYourWrinkles3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const animationContainerRef = useRef<HTMLDivElement | null>(null)
  const language = useSelector(selectLanguage)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue('')

  useEffect(() => {
    if (animationContainerRef && animationContainerRef.current) {
      lottie.loadAnimation({
        container: animationContainerRef.current,
        path: `${CDN_FOLDER_LINK}${ANIMATION_PATH}_${language}.json`,
        loop: false,
      })
    }

    return () => lottie.destroy()
  }, [language])

  return (
    <Container>
      <S.Title>
        <Trans i18nKey="onboarding.reverseYourWrinkles.question3" />
      </S.Title>
      <S.GraphContainer ref={animationContainerRef} />
      <div>
        <S.Card>
          <S.ImageContainer>
            <img src={harvardMedicalSchool} alt="" />
          </S.ImageContainer>
          <p>
            <Trans i18nKey="onboarding.reverseYourWrinkles.item1" />
          </p>
        </S.Card>
        <S.Card>
          <S.ImageContainer>
            <img src={northwesternUniversity} alt="" />
          </S.ImageContainer>
          <p>
            <Trans i18nKey="onboarding.reverseYourWrinkles.item2" />
          </p>
        </S.Card>
      </div>
      <SandyBackgroundGlobalStyles />

      <StickyButtonContainer centerMode>
        <Button
          onClick={handleNextClick}
          theme={buttonTheme.LUVLY_SECONDARY}
          maxWidth="488px"
        >
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
    </Container>
  )
}
