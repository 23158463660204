import styled from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import warningIcon from 'assets/images/red-warning.png'

import { Color } from 'root-constants'

export const StyledInputWithFloatPlaceholder2 = {
  Wrapper: styled.div<{ marginBottom: number }>`
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: ${({ marginBottom }) => `${marginBottom}px`};

    &::after {
      display: block;
      position: absolute;
      left: 16px;
      color: ${Color.ERROR};
      font-size: 13px;
      line-height: 20px;
    }

    &[data-valid='false']::after {
      content: attr(data-validation-text);
    }
  `,
  Input: styled.input`
    display: block;
    width: 100%;
    height: 64px;
    padding: 32px 52px 8px 16px;
    color: ${Color.BLUE_DARK};
    font-size: 15px;
    font-weight: 400;
    line-height: 1;
    border-radius: 16px;
    border: 1px solid #cacaca;
    background-color: ${Color.WHITE};
    outline: none;
    transition: border 0.2s ease-out;
    &:valid,
    &:focus {
      & + span {
        top: 15px;
        color: #a9a9a9;
        font-size: 14px;
        line-height: 1;
      }
    }

    &:not(:focus) {
      ~ span + div {
        display: flex;
      }
    }
  `,
  Label: styled.span`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 16px;
    color: ${Color.GRAY};
    font-size: 17px;
    transition-property: top, color, font-size, line-height;
    transition-duration: 0.2s;
    transition-timing-function: ease;
    pointer-events: none;
    cursor: text;
  `,
  SvgIcon: styled(SvgImage)`
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
  `,
  Error: styled.div`
    display: none;
    position: relative;
    padding-left: 36px;
    margin-top: 4px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: #f83b00;
    align-items: center;

    &::before {
      content: '';
      position: absolute;
      left: 16px;
      width: 16px;
      height: 16px;
      background-image: url(${warningIcon});
      background-size: 16px;
      background-position: center;
      background-repeat: no-repeat;
    }
  `,
}
