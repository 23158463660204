import { UserGoal } from 'root-constants'

export const QUESTION =
  'What is your biggest motivation on your skincare journey?'

const BIGGEST_MOTIVATION_VALUES = {
  YOUTHFUL_LIFTED_FACE: 'youthful_lifted_face',
  FIRM_SKIN_NO_SAGGING: 'firm_skin_no_sagging',
  REGAIN_NATURAL_TIGHTNESS: 'regain_natural_tightness',
  CONFIDENT_IN_LOOK: 'confident_in_look',
  REVERSE_AGING_DROOP: 'reverse_aging_droop',
  SLIMMER_SCULPTED_FACE: 'slimmer_sculpted_face',
  DEFINE_CHEEKBONES_JAWLINE: 'define_cheekbones_jawline',
  LOSE_PUFFINESS_LOOK_HEAVY: 'lose_puffiness_look_heavy',
  SCULPTED_CHISELED_LOOK: 'sculpted_chiseled_look',
  MATCH_BODY_FACE: 'match_body_face',
  YOUNGER_LOOK_REFRESHED: 'younger_look_refreshed',
  SMOOTH_EVEN_SKIN: 'smooth_even_skin',
  REDUCE_WRINKLES_EYES_MOUTH: 'reduce_wrinkles_eyes_mouth',
  CONFIDENT_SKIN_LOOK: 'confident_skin_look',
  PREVENT_AGE_WRINKLES: 'prevent_age_wrinkles',
}

const TIGHTEN_SKIN_ANSWERS = [
  {
    text: 'onboarding.biggestMotivation.option1',
    label: BIGGEST_MOTIVATION_VALUES.YOUTHFUL_LIFTED_FACE,
  },
  {
    text: 'onboarding.biggestMotivation.option2',
    label: BIGGEST_MOTIVATION_VALUES.FIRM_SKIN_NO_SAGGING,
  },
  {
    text: 'onboarding.biggestMotivation.option3',
    label: BIGGEST_MOTIVATION_VALUES.REGAIN_NATURAL_TIGHTNESS,
  },
  {
    text: 'onboarding.biggestMotivation.option4',
    label: BIGGEST_MOTIVATION_VALUES.CONFIDENT_IN_LOOK,
  },
  {
    text: 'onboarding.biggestMotivation.option5',
    label: BIGGEST_MOTIVATION_VALUES.REVERSE_AGING_DROOP,
  },
]

const REDUCE_FAT_ANSWERS = [
  {
    text: 'onboarding.biggestMotivation.option6',
    label: BIGGEST_MOTIVATION_VALUES.SLIMMER_SCULPTED_FACE,
  },
  {
    text: 'onboarding.biggestMotivation.option7',
    label: BIGGEST_MOTIVATION_VALUES.DEFINE_CHEEKBONES_JAWLINE,
  },
  {
    text: 'onboarding.biggestMotivation.option8',
    label: BIGGEST_MOTIVATION_VALUES.LOSE_PUFFINESS_LOOK_HEAVY,
  },
  {
    text: 'onboarding.biggestMotivation.option9',
    label: BIGGEST_MOTIVATION_VALUES.SCULPTED_CHISELED_LOOK,
  },
  {
    text: 'onboarding.biggestMotivation.option10',
    label: BIGGEST_MOTIVATION_VALUES.MATCH_BODY_FACE,
  },
]

const WRINKLES_FREE_ANSWERS = [
  {
    text: 'onboarding.biggestMotivation.option11',
    label: BIGGEST_MOTIVATION_VALUES.YOUNGER_LOOK_REFRESHED,
  },
  {
    text: 'onboarding.biggestMotivation.option12',
    label: BIGGEST_MOTIVATION_VALUES.SMOOTH_EVEN_SKIN,
  },
  {
    text: 'onboarding.biggestMotivation.option13',
    label: BIGGEST_MOTIVATION_VALUES.REDUCE_WRINKLES_EYES_MOUTH,
  },
  {
    text: 'onboarding.biggestMotivation.option14',
    label: BIGGEST_MOTIVATION_VALUES.CONFIDENT_SKIN_LOOK,
  },
  {
    text: 'onboarding.biggestMotivation.option15',
    label: BIGGEST_MOTIVATION_VALUES.PREVENT_AGE_WRINKLES,
  },
]

export const BIGGEST_MOTIVATION_ANSWERS_MAP = {
  [UserGoal.TIGHTEN_SKIN]: TIGHTEN_SKIN_ANSWERS,
  [UserGoal.REDUCE_FACE_FAT]: REDUCE_FAT_ANSWERS,
  [UserGoal.WRINKLE_FREE_SKIN]: WRINKLES_FREE_ANSWERS,
}
