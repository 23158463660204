import briannaImg from 'assets/images/brianna-before-after-min.jpg'
import jenniferImg from 'assets/images/jennifer-before-after-min.jpg'
import lindaImg from 'assets/images/linda-before-after-min.jpg'
import man1Img from 'assets/images/man-1-before-after.png'
import man3Img from 'assets/images/man-3-before-after.png'
import man4Img from 'assets/images/man-4-before-after.png'
import man5Img from 'assets/images/man-5-before-after.png'
import man6Img from 'assets/images/man-6-before-after.png'
import melissaImg from 'assets/images/melissa-before-after-min.png'
import naomiImg from 'assets/images/naomi-before-after.png'
import sarahImg from 'assets/images/sarah-before-after.png'

export const USERS_GALLERY = [
  {
    name: 'purchase7.userGoalsGallery.user1',
    img: melissaImg,
  },
  {
    name: 'purchase7.userGoalsGallery.user2',
    img: briannaImg,
  },
  {
    name: 'purchase7.userGoalsGallery.user3',
    img: lindaImg,
  },
  {
    name: 'purchase7.userGoalsGallery.user4',
    img: jenniferImg,
  },
]

export const DOUBLE_CHIN_FEMALE_GALLERY = [
  {
    name: 'purchase7.userGoalsGallery.user5',
    img: naomiImg,
  },
  {
    name: 'purchase7.userGoalsGallery.user3',
    img: lindaImg,
  },
  {
    name: 'purchase7.userGoalsGallery.user2',
    img: briannaImg,
  },
  {
    name: 'purchase7.userGoalsGallery.user1',
    img: melissaImg,
  },
  {
    name: 'purchase7.userGoalsGallery.user6',
    img: sarahImg,
  },
]

export const DOUBLE_CHIN_MALE_GALLERY = [
  {
    name: 'purchase7.userGoalsGallery.man3',
    img: man3Img,
  },
  {
    name: 'purchase7.userGoalsGallery.man5',
    img: man5Img,
  },
  {
    name: 'purchase7.userGoalsGallery.man4',
    img: man4Img,
  },
  {
    name: 'purchase7.userGoalsGallery.man6',
    img: man6Img,
  },
  {
    name: 'purchase7.userGoalsGallery.man1',
    img: man1Img,
  },
]

export const MALE_GALLERY = [
  {
    name: 'purchase7.userGoalsGallery.man3',
    img: man3Img,
  },
  {
    name: 'purchase7.userGoalsGallery.man5',
    img: man5Img,
  },
  {
    name: 'purchase7.userGoalsGallery.man4',
    img: man4Img,
  },
  {
    name: 'purchase7.userGoalsGallery.man6',
    img: man6Img,
  },
]

export const FEMALE_GALLERY = [
  {
    name: 'purchase7.userGoalsGallery.user4',
    img: jenniferImg,
  },
  {
    name: 'purchase7.userGoalsGallery.user3',
    img: lindaImg,
  },
  {
    name: 'purchase7.userGoalsGallery.user2',
    img: briannaImg,
  },
  {
    name: 'purchase7.userGoalsGallery.user1',
    img: melissaImg,
  },
]
