import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SvgImage } from 'components/SvgImage'

import { useVatInfo } from 'hooks/useHasVatInfo'

import { getCalculatedPriceWithoutVAT } from 'helpers/getCalculatedPriceWithoutVAT'

import {
  CURRENCY_SYMBOLS,
  Currency,
  DECORATING_DISCOUNT,
  DEFAULT_DYNAMIC_DISCOUNT,
  ONE_HUNDRED_PERCENTAGE,
} from 'modules/purchase/constants'
import {
  selectDynamicDiscount,
  selectSubscription,
  selectTaxAmount,
} from 'modules/purchase/redux/selects/common'

import securityIcon from 'assets/images/security-hexagon-icon.png'
import calendarIcon from 'assets/images/spiral-calendar.png'
import greenCheckIcon from 'assets/images/sprite/green-empty-checkbox.svg'

import { StyledSubscriptionsWeeklyBlock as S } from './SubscriptionsWeeklyBlock.styles'
import { SUBSCRIPTION_BENEFITS } from './constants'

type TProps = {
  children?: React.ReactNode
}

export const SubscriptionsWeeklyBlock: React.FC<TProps> = ({ children }) => {
  const { t } = useTranslation()
  const selectedSubscription = useSelector(selectSubscription)
  const dynamicDiscount = useSelector(selectDynamicDiscount)

  const hasVatInfo = useVatInfo()

  const taxAmount = useSelector(selectTaxAmount)

  const getCalculatedPrice = (fullPrice: number) => {
    if (!hasVatInfo) return fullPrice

    return Number(getCalculatedPriceWithoutVAT(fullPrice, taxAmount))
  }

  const getOldPrice = ({ price }: { price: number }) => {
    const discount = dynamicDiscount?.amount || DEFAULT_DYNAMIC_DISCOUNT
    const calculatedOldPrice =
      price / ((ONE_HUNDRED_PERCENTAGE - discount) / ONE_HUNDRED_PERCENTAGE)

    return (
      Math.ceil(getCalculatedPrice(calculatedOldPrice)) - DECORATING_DISCOUNT
    ).toFixed(2)
  }

  return (
    <S.Root>
      <S.List>
        {SUBSCRIPTION_BENEFITS.map(({ id, label }) => {
          return (
            <S.Item key={id}>
              <SvgImage svg={greenCheckIcon} width={24} />
              <p>
                <Trans i18nKey={label} components={[<br />, <strong />]} />
              </p>
            </S.Item>
          )
        })}
      </S.List>
      <S.PriceInfo>
        <span>{t('purchase7.subscriptionsWeekly.total')}</span>
        <S.Prices>
          <S.OldPrice>
            {CURRENCY_SYMBOLS[selectedSubscription?.currency || Currency.USD]}
            {getOldPrice({
              price: selectedSubscription?.trialPrices.fullPrice || 0,
            })}
          </S.OldPrice>
          <span>
            {CURRENCY_SYMBOLS[selectedSubscription?.currency || Currency.USD]}
            {selectedSubscription?.trialPrices.fullPrice}
          </span>
        </S.Prices>
      </S.PriceInfo>
      <S.Discount>
        {t('purchase7.subscriptionsWeekly.save')}{' '}
        {dynamicDiscount?.amount || DEFAULT_DYNAMIC_DISCOUNT}%
      </S.Discount>
      <S.Disclaimer>
        <Trans
          i18nKey="purchase7.subscriptionsWeekly.disclaimer"
          values={{
            oldPrice: selectedSubscription?.trialPrices.fullPrice,
            currency:
              CURRENCY_SYMBOLS[selectedSubscription?.currency || Currency.USD],
            fullPrice: selectedSubscription?.mainPrices.fullPrice || 0,
          }}
        />
        /week.
      </S.Disclaimer>
      <S.Notification>
        <img src={calendarIcon} alt="" />
        <div>
          <S.NotificationTitle>
            {t('purchase7.subscriptionsWeekly.notificationTitle1')}
          </S.NotificationTitle>
          <S.NotificationText>
            <Trans i18nKey="purchase7.subscriptionsWeekly.notificationText1" />
          </S.NotificationText>
        </div>
      </S.Notification>
      <S.Notification>
        <img src={securityIcon} alt="" />
        <div>
          <S.NotificationTitle>
            {t('purchase7.subscriptionsWeekly.notificationTitle2')}
          </S.NotificationTitle>
          <S.NotificationText>
            <Trans i18nKey="purchase7.subscriptionsWeekly.notificationText2" />
          </S.NotificationText>
        </div>
      </S.Notification>
      {children}
    </S.Root>
  )
}
