import React from 'react'
import { useTranslation } from 'react-i18next'

import { Option } from 'components/Option'

import { UserAnswer } from 'root-constants'

import { StyledAnalyzingAnswersModal as S } from './AnalyzingAnswersModal.styles'

type TAnalyzingAnswersModal = {
  isModalVisible: boolean
  disabled?: boolean
  onChange?: (event?: any) => void
  answers?: string[]
  setAnswers?: () => void
  children?: React.ReactNode
  roundedButtons?: boolean
}

export const AnalyzingAnswersModal: React.FC<TAnalyzingAnswersModal> = ({
  isModalVisible,
  disabled,
  onChange,
  children,
  roundedButtons = false,
  ...rest
}) => {
  const { t } = useTranslation()

  const renderYesButton = () => {
    if (!roundedButtons) return t`onboarding.analyzingAnswers.yes`

    return (
      <S.BoldModalAnswer>{t`onboarding.analyzingAnswers.yes`}</S.BoldModalAnswer>
    )
  }

  const renderNoButton = () => {
    if (!roundedButtons) return t`onboarding.analyzingAnswers.no`

    return (
      <S.BoldModalAnswer>{t`onboarding.analyzingAnswers.no`}</S.BoldModalAnswer>
    )
  }

  return (
    <S.Modal isVisible={isModalVisible}>
      <S.ModalContent>
        <S.ModalTitle>{children}</S.ModalTitle>
        <Option
          value={UserAnswer.YES}
          {...rest}
          disabled={disabled}
          onChange={() => onChange && onChange(UserAnswer.YES)}
        >
          <S.ModalButton disabled={disabled} roundedButtons={roundedButtons}>
            {renderYesButton()}
          </S.ModalButton>
        </Option>
        <Option
          value={UserAnswer.NO}
          {...rest}
          disabled={disabled}
          onChange={() => onChange && onChange(UserAnswer.NO)}
        >
          <S.ModalButton disabled={disabled} roundedButtons={roundedButtons}>
            {renderNoButton()}
          </S.ModalButton>
        </Option>
      </S.ModalContent>
    </S.Modal>
  )
}
