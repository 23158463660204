import styled, { css } from 'styled-components'

import { datesAnimation } from 'common-styles'
import { Color } from 'root-constants'

const goalTextStyles = css`
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  background: linear-gradient(92deg, #5a9060 0%, #8bab79 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export const StyledUserGoalAnimatedGraph = {
  GoalDateContainer: styled.div`
    text-align: center;
    margin-bottom: 10px;
  `,
  Goal: styled.p`
    position: relative;
    ${goalTextStyles}
  `,
  AnimationContainer: styled.div`
    height: 28px;
    overflow: hidden;
  `,
  GoalDate: styled.p`
    height: 45px;
    ${goalTextStyles}

    &:first-child {
      animation: ${datesAnimation} 5s linear;
    }
  `,
  ProgramDescription: styled.p`
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    color: ${Color.GRAY};
    margin-bottom: 24px;
  `,
  GraphContainer: styled.div`
    position: relative;
    aspect-ratio: 343/176;
    width: 100%;
    max-width: 343px;
    height: auto;
    margin: 0 auto 24px;
  `,
}
