import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { IntroHeader } from 'components/IntroHeader'
import { IntroHeaderColor } from 'components/IntroHeader/constants'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'
import { Ticker } from 'components/Ticker'

import { selectLanguage } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'
import { useSendIntroPageCompletedAnalytics } from 'hooks/useSendIntroPageCompletedAnalytics'
import { useStartSession } from 'hooks/useStartSession'
import { useTitleFromUrl } from 'hooks/useTitleFromUrl'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import girl2 from 'assets/images/introsale2-center-image-mobile.png'
import girl from 'assets/images/introsale2-center-image.png'

import { PageId } from 'page-constants'
import { Locale, OptionType } from 'root-constants'

import { StyledIntroSaleAgeAsIntro as S } from './IntroSaleAgeAsIntro.styles'
import { AGE_OPTIONS, AGE_OPTION_VALUES_MAP, QUESTION } from './constants'

export const IntroSaleAgeAsIntro: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const language = useSelector(selectLanguage)
  const { sendIntroPageCompletedAnalytics } =
    useSendIntroPageCompletedAnalytics()

  useStartSession()

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const { headline } = useTitleFromUrl()

  const handleContinue = useNextStep({
    pageId: PageId.AGE,
    question: QUESTION,
    nextPagePath,
  })

  const isEnLocale = language === Locale.ENGLISH

  const handleAnswerChange = (value: string) => {
    eventLogger.logUserAgeSelected(AGE_OPTION_VALUES_MAP[value])
    handleChange(value, handleContinue)
    setIsAnswersDisabled(true)
  }

  const optionProps = {
    name: PageId.AGE,
    type: OptionType.RADIO,
    disabled: isAnswersDisabled,
    onChange: (value: string) => {
      sendIntroPageCompletedAnalytics()

      eventLogger.logFirstPageCompleted({
        question: QUESTION,
        answers: value,
      })

      handleAnswerChange(value)
    },
  }

  return (
    <S.Root>
      <IntroHeader
        colorTheme={IntroHeaderColor.DARK}
        isSupportActionVisible={false}
      />
      <Ticker />
      <S.Container>
        <S.ImageContainer>
          <S.FlowersImage src={girl2} alt="girl" />
          <S.GirlImage src={girl} alt="girl" />
        </S.ImageContainer>
        <S.Title isEnLocaleStyles={isEnLocale}>
          {headline || t('onboarding.introSaleAgeAsIntro.titleV2')}
        </S.Title>
        <S.Subtitle>
          {t('onboarding.introSaleAgeAsIntro.description')}
        </S.Subtitle>
        <S.Actions>
          {AGE_OPTIONS.map(({ value, text }) => (
            <S.StyledOption {...optionProps} value={value} key={value}>
              <S.Action>
                <S.AnswerWrapper>
                  <S.AnswerValue>{text}</S.AnswerValue>
                  <S.ChevronRight />
                </S.AnswerWrapper>
              </S.Action>
            </S.StyledOption>
          ))}
        </S.Actions>
        <S.Terms>
          <p>{t`onboarding.intro.termsTitle`}</p>
          <TermsOfUseLink />
          <S.Separator />
          <PrivacyPolicyLink />
        </S.Terms>
      </S.Container>
    </S.Root>
  )
}
