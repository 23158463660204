import styled, { css } from 'styled-components'

import { TLocaleStylesProps } from 'modules/purchase/types'

import { Color } from 'root-constants'

export const StyledLongPaywallCustomerReviews = {
  Wrapper: styled.div<TLocaleStylesProps>`
    max-width: ${({ theme }) => theme.paywallContainer.maxWidth};
    margin: 0 auto 45px;
    padding: ${({ isEnLocaleStyle }) =>
      isEnLocaleStyle ? '0 20px' : '0 16px'};
  `,
  Title: styled.h2<TLocaleStylesProps>`
    text-align: center;
    color: ${({ theme }) => theme.reviews.subtitle.color};
    font-weight: 700;
    margin-bottom: 24px;

    ${({ theme, isEnLocaleStyle }) => {
      if (!isEnLocaleStyle) {
        return css`
          font-size: 24px;
          line-height: 30px;
        `
      }

      return css`
        font-size: ${theme.reviews.subtitle.fontSize};
        line-height: ${theme.reviews.subtitle.lineHeight};
      `
    }}
  `,
  Review: styled.div`
    width: 100%;
    padding: 12px 16px;
    border-radius: 16px;
    background: ${Color.WHITE};
    box-shadow: 0px 8px 20px 0px rgba(209, 214, 235, 0.5);
  `,
  ViewMoreLinkContainer: styled.div`
    width: 100%;
    text-align: right;
  `,
  ViewMoreLink: styled.span`
    font-size: 14px;
    font-style: normal;
    cursor: pointer;

    ${({
      theme: {
        reviews: { viewMoreLink },
      },
    }) => css`
      color: ${viewMoreLink.color};
      font-weight: ${viewMoreLink.fontWeight};
      line-height: ${viewMoreLink.lineHeight};
    `}
  `,
}
