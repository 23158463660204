import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useVatInfo } from 'hooks/useHasVatInfo'
import { useIsCurrentRoute } from 'hooks/useIsCurrentRoute'
import { usePurchaseCancelOfferRoute } from 'hooks/usePurchaseCancelOfferRoute'

import { getDynamicDiscountText } from 'modules/purchase/helpers/rootHelpers'
import { useGetPriceWithCurrencyFormatting } from 'modules/purchase/hooks/useGetPriceWithCurrencyFormatting'
import { usePriceWithVatAdjustment } from 'modules/purchase/hooks/usePriceWithVatAdjustment'
import { usePurchaseStore } from 'modules/purchase/hooks/usePurchaseStore'
import { useSummarizedSubscriptionData } from 'modules/purchase/hooks/useSummarizedSubscriptionData'
import {
  selectDynamicDiscount,
  selectSubscription,
} from 'modules/purchase/redux/selects/common'

import { TrialBillingCycle } from 'root-constants'

import { StyledCheckoutPurchaseDetails as S } from './CheckoutPurchaseDetails.styles'

export const CheckoutPurchaseDetails: React.FC = () => {
  const { t } = useTranslation()
  const dynamicDiscount = useSelector(selectDynamicDiscount)
  const selectedSubscription = useSelector(selectSubscription)
  const { isDynamicDiscountCohort, isLuvlyWheelCohort, isLuvlyChinCohort } =
    useActiveCohortIdentifier()
  const cohortToUse = useCohortToUse()
  const hasVatInfo = useVatInfo()
  const { currency, trialPrice } = usePurchaseStore()
  const cancelOfferRoute = usePurchaseCancelOfferRoute()
  const isCancelOfferRoute = useIsCurrentRoute(cancelOfferRoute)

  const { getPriceWithVatAdjustment } = usePriceWithVatAdjustment()
  const { getPriceWithCurrencyFormatting } = useGetPriceWithCurrencyFormatting()
  const summarizedSubscription = useSummarizedSubscriptionData()

  if (!selectedSubscription) {
    return null
  }

  const isLuvlyWheelInitialOffer = isLuvlyWheelCohort && !trialPrice

  const currencyLabel = currency.toUpperCase()

  const renderDiscountText = () => {
    if (isLuvlyWheelCohort) {
      return (
        <Trans
          i18nKey="purchase7.off"
          values={{
            value: summarizedSubscription.discountPercentage,
          }}
        />
      )
    }

    if (isDynamicDiscountCohort) {
      return getDynamicDiscountText({
        discountPercentage: summarizedSubscription.discountPercentage,
        isCancelOffer: isCancelOfferRoute,
        cohort: cohortToUse,
        discountText: dynamicDiscount?.checkout,
      })
    }

    return (
      <Trans
        i18nKey="checkoutExtended.introductoryDiscount"
        values={{
          discountPercentage: summarizedSubscription.discountPercentage,
        }}
      />
    )
  }

  const getFormattedAmountLabel = (amount: number) => {
    const formattedValue = getPriceWithCurrencyFormatting(amount.toFixed(2))

    return `${formattedValue} ${currencyLabel}`
  }

  const getDailyPriceLabel = () => {
    const dailyPrices = isLuvlyWheelInitialOffer
      ? selectedSubscription.mainPrices.daily
      : selectedSubscription.trialPrices.daily

    return `${getPriceWithVatAdjustment(dailyPrices)} ${currencyLabel}`
  }

  const getSubscriptionDuration = () => {
    if (isLuvlyWheelInitialOffer) {
      return selectedSubscription.mainPrices.periodQuantity ===
        TrialBillingCycle.SEVEN_DAYS
        ? selectedSubscription.mainPrices.periodQuantity
        : selectedSubscription.mainPrices.periodQuantity *
            TrialBillingCycle.ONE_MONTH
    }

    return selectedSubscription.trialPrices.durationDays
  }

  const getTotalAmountLabel = () => {
    if (!hasVatInfo) return t('checkoutExtended.total')

    return isLuvlyChinCohort
      ? t('checkoutExtended.totalWithVat2')
      : t('checkoutExtended.totalWithVat')
  }

  return (
    <>
      <S.PriceDescription>
        <S.PriceInfo>
          <S.PriceBlockText>
            {t('checkoutExtended.personalizedPlan')}
          </S.PriceBlockText>
          <S.PriceBlockText>
            {getFormattedAmountLabel(summarizedSubscription.totalAmount)}
          </S.PriceBlockText>
        </S.PriceInfo>
        {!isLuvlyWheelInitialOffer && (
          <S.PriceInfo>
            <S.PriceBlockText>{renderDiscountText()}</S.PriceBlockText>
            <S.TotalDiscount>
              -{getFormattedAmountLabel(summarizedSubscription.discountAmount)}
            </S.TotalDiscount>
          </S.PriceInfo>
        )}
      </S.PriceDescription>

      <S.DailyPayment>
        <S.DailyText>{t('checkoutExtended.totalPerDay')}</S.DailyText>
        <S.DailyAmount>{getDailyPriceLabel()}</S.DailyAmount>
      </S.DailyPayment>

      <S.TotalPayment>
        <S.TotalDescription>
          <S.TotalText>
            <Trans
              i18nKey={getTotalAmountLabel()}
              components={[<br />, <strong />]}
            />
          </S.TotalText>
          <S.TotalAmount>
            <Trans
              i18nKey="checkoutExtended.totalAmount"
              values={{
                totalAmount: getPriceWithCurrencyFormatting(
                  selectedSubscription.trialPrices.fullPrice ||
                    selectedSubscription.mainPrices.fullPrice,
                ),
                currency: currencyLabel,
                context: getSubscriptionDuration(),
              }}
            />
          </S.TotalAmount>
        </S.TotalDescription>
        {!isLuvlyWheelInitialOffer && (
          <S.SaveText>
            <Trans
              i18nKey="checkoutExtended.savedAmount"
              values={{
                savedAmount: getPriceWithCurrencyFormatting(
                  summarizedSubscription.discountAmount.toFixed(2),
                ),
                currency: currencyLabel,
                discountPercentage: summarizedSubscription.discountPercentage,
              }}
            />
          </S.SaveText>
        )}
      </S.TotalPayment>
    </>
  )
}
