import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'
import { Option } from 'components/Option'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useIsNavigationOn } from 'hooks/useIsNavigationOn'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { goBack } from 'browser-history'
import { OptionType } from 'root-constants'
import { SCALE_OPTION_VALUES } from 'shared-options'

import { StyledStatementQuestion as S } from './StatementQuestion.styles'
import {
  STATEMENT_QUESTIONS_CONTEXT_MAP,
  STATEMENT_QUESTIONS_DEFAULT_TEXT,
  STATEMENT_QUESTIONS_MAP,
} from './constants'

export const StatementQuestion: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answer, setAnswer] = useState<string>('')
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const includeNavigation = useIsNavigationOn()
  const cohortToUse = useCohortToUse()
  const { isSeniorCohort } = useActiveCohortIdentifier()

  const handleContinue = useNextStep({
    pageId,
    question: STATEMENT_QUESTIONS_MAP[pageId],
    nextPagePath,
  })

  const handleNextClick = () => handleContinue(answer)

  const handleAnswerChange = (value: string) => {
    if (includeNavigation) {
      setAnswer(value)
      return
    }

    handleChange(value, handleContinue)
    setIsAnswersDisabled(true)
  }

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    disabled: isAnswersDisabled,
    onChange: handleAnswerChange,
  }

  const statementQuestionText =
    STATEMENT_QUESTIONS_CONTEXT_MAP[cohortToUse] ||
    STATEMENT_QUESTIONS_DEFAULT_TEXT

  return (
    <div>
      <Container>
        <S.TitleContainer>
          <S.Title isLargeSize={isSeniorCohort}>
            <Trans i18nKey="commonComponents.statementTitle" />
          </S.Title>
          <S.Question isLargeSize={isSeniorCohort}>
            {t(statementQuestionText[pageId])}
          </S.Question>
        </S.TitleContainer>
        <S.OptionsContainer>
          <Option {...optionProps} value={SCALE_OPTION_VALUES.ONE}>
            <S.AnswerButtonMini isLargeSize={isSeniorCohort}>
              1
            </S.AnswerButtonMini>
          </Option>
          <Option {...optionProps} value={SCALE_OPTION_VALUES.TWO}>
            <S.AnswerButtonMini isLargeSize={isSeniorCohort}>
              2
            </S.AnswerButtonMini>
          </Option>
          <Option {...optionProps} value={SCALE_OPTION_VALUES.THREE}>
            <S.AnswerButtonMini isLargeSize={isSeniorCohort}>
              3
            </S.AnswerButtonMini>
          </Option>
          <Option {...optionProps} value={SCALE_OPTION_VALUES.FOUR}>
            <S.AnswerButtonMini isLargeSize={isSeniorCohort}>
              4
            </S.AnswerButtonMini>
          </Option>
          <Option {...optionProps} value={SCALE_OPTION_VALUES.FIVE}>
            <S.AnswerButtonMini isLargeSize={isSeniorCohort}>
              5
            </S.AnswerButtonMini>
          </Option>
        </S.OptionsContainer>
        <S.Scale isLargeSize={isSeniorCohort}>
          <span>{t`commonComponents.lowestScaleValue`}</span>
          <span>{t`commonComponents.highestScaleValue`}</span>
        </S.Scale>
      </Container>
      {includeNavigation && (
        <NavigationButtons
          onBackClick={goBack}
          onNextClick={handleNextClick}
          disabled={!answer}
        />
      )}
    </div>
  )
}
