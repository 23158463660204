import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Button } from 'components/Button'
import { ChatMessage } from 'components/ChatMessage'
import {
  ChatAuthor,
  ChatStep,
  DELAY_BEFORE_SHOW,
} from 'components/ChatMessage/ChatMessage'
import { Container } from 'components/Container'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import nutritionFeedback from 'assets/images/like-chat-nutrition-feedback.png'

import { DelayedBottomContainer } from 'common-styles'

import { QUESTION } from './constants'

export const LikeChatNutritionFeedback: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [chatStep, setChatStep] = useState<ChatStep>(ChatStep.EXPERT_QUESTION)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue('')

  return (
    <Container fullHeight>
      <ChatMessage
        author={ChatAuthor.EXPERT}
        delayBeforeShow={DELAY_BEFORE_SHOW}
        hasJustNowLabel={chatStep === ChatStep.EXPERT_QUESTION}
      >
        {t('onboarding.likeChatNutritionFeedback.nutritionRole')}
      </ChatMessage>
      <ChatMessage
        author={ChatAuthor.EXPERT}
        delayBeforeShow={2000}
        isImage
        hasHeader={false}
        onTransitionEnd={() => setChatStep(ChatStep.EXPERT_EXPRESSION)}
      >
        <img src={nutritionFeedback} alt="introduce-expert" />
      </ChatMessage>
      <ChatMessage
        author={ChatAuthor.EXPERT}
        delayBeforeShow={3500}
        onTransitionEnd={() => setChatStep(ChatStep.FINISH)}
        hasJustNowLabel
      >
        <Trans
          i18nKey="onboarding.likeChatNutritionFeedback.answerQuestions"
          components={[<strong />, <br />]}
        />
      </ChatMessage>
      <DelayedBottomContainer
        isShown={chatStep === ChatStep.FINISH}
        pinedToBottom
      >
        <Button onClick={handleNextClick}>{t('actions.continue')}</Button>
      </DelayedBottomContainer>
    </Container>
  )
}
