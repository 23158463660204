import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AnswerWithIcon, answerWithIconTheme } from 'storybook-ui'

import { Container } from 'components/Container'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { DoubleChinTitle, SandyBackgroundGlobalStyles } from 'common-styles'
import { PageId } from 'page-constants'
import { OptionType } from 'root-constants'

import { StyledGoal as S } from './Goal.styles'
import { GOAL_V4_ANSWERS, QUESTION } from './constants'

export const GoalVariant4: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: PageId.INTRO,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    theme: answerWithIconTheme.LUVLY_SOLID,
    iconWidth: '32px',
    iconHeight: '32px',
    disabled: isAnswersDisabled,
    fontWeight: '600',
    spacingBetweenIconAndContent: '12px',
    onChange: (value: string) => {
      setIsAnswersDisabled(true)
      handleChange(value, handleContinue)
    },
  }

  return (
    <S.Root>
      <Container>
        <DoubleChinTitle>
          <Trans i18nKey="onboarding.goalVariant4.question" />
        </DoubleChinTitle>
        <div>
          {GOAL_V4_ANSWERS.map(({ icon, text, label }) => {
            return (
              <AnswerWithIcon
                {...optionProps}
                key={text}
                value={label}
                iconSrc={icon}
              >
                {t(text)}
              </AnswerWithIcon>
            )
          })}
        </div>
      </Container>
      <SandyBackgroundGlobalStyles />
    </S.Root>
  )
}
