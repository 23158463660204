import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { PrimerPaymentFormV2 } from 'components/PrimerPaymentForm'
import { Spinner } from 'components/Spinner'

import { startFetching } from 'root-redux/actions/common'
import {
  selectActionList,
  selectIsPrimerRetryProcessing,
  selectScreenName,
} from 'root-redux/selects/common'

import { useABTest } from 'hooks/useABTest'
import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useScrollToTop } from 'hooks/useScrollToTop'

import { createProductId } from 'helpers/createProductId'

import { CheckoutPurchaseDetails } from 'modules/purchase/components/CheckoutPurchaseDetails'
import {
  PrimerPaymentWaitingModal,
  PrimerPaymentWaitingModalNoError,
} from 'modules/purchase/components/PaymentWaitingModal'
import { StripeSeparatePaymentRequestButton } from 'modules/purchase/components/StripeSeparatePaymentRequestButton'
import { PaymentMethod } from 'modules/purchase/constants'
import { usePurchaseStore } from 'modules/purchase/hooks'
import { useSelectedPaymentMethod } from 'modules/purchase/hooks/'
import { useCancelPrimerDiscount } from 'modules/purchase/hooks/useCancelPrimerDiscount'
import {
  CHECK_3D_SECURE,
  CHECK_PAYMENT_REQUEST_BUTTON,
  CHECK_PAYPAL_REQUEST_BUTTON,
  PURCHASE,
  UPDATE_PRIMER_CLIENT_SESSION,
} from 'modules/purchase/redux/actions/common'
import {
  select3DSecureIframeUrl,
  selectSubscription,
} from 'modules/purchase/redux/selects/common'

import { eventLogger } from 'services/eventLogger.service'

import SecuritySystems from 'assets/images/security-systems.png'

import { CustomBackgroundStyles } from 'common-styles'
import { Cohort, ScreenName } from 'root-constants'

import { StyledCheckoutSeparateMethods as S } from './CheckoutSeparateMethods.styles'
import { PAYMENT_METHODS_IMAGES } from './constants'

type TCheckoutExtended = {
  handleCloseCheckout: () => void
  hasPaypalButton?: boolean
}

export const CheckoutSeparateMethodsMix: React.FC<TCheckoutExtended> = ({
  handleCloseCheckout,
  hasPaypalButton = false,
}: TCheckoutExtended) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const selectedSubscription = useSelector(selectSubscription)
  const fetchingActionsList = useSelector(selectActionList)
  const threeDSecureIframeUrl = useSelector(select3DSecureIframeUrl)
  const screenName = useSelector(selectScreenName)
  const isPrimerRetryProcessing = useSelector(selectIsPrimerRetryProcessing)

  const { isCheckoutErrorThemeActive } = useABTest()

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<PaymentMethod | null>(null)
  const [alternativePaymentMethods, setAlternativePaymentMethods] = useState<
    PaymentMethod[]
  >([])
  const [isErrorTheme, setIsErrorTheme] = useState(false)
  const [isPaymentWaitingShown, setIsPaymentWaitingShown] =
    useState<boolean>(false)

  useScrollToTop()

  const {
    periodName,
    periodQuantity,
    trialPrice,
    price,
    cohort,
    stripeAccountId,
    stripeAccountName,
  } = usePurchaseStore()
  const { cancelDiscount } = useCancelPrimerDiscount()

  const isCheckoutReady = useMemo(
    () =>
      !fetchingActionsList.includes(CHECK_PAYMENT_REQUEST_BUTTON) &&
      !fetchingActionsList.includes(CHECK_PAYPAL_REQUEST_BUTTON),
    [fetchingActionsList],
  )

  const isPurchaseInProcess = useMemo(
    () =>
      fetchingActionsList.includes(PURCHASE) ||
      fetchingActionsList.includes(CHECK_3D_SECURE) ||
      fetchingActionsList.includes(UPDATE_PRIMER_CLIENT_SESSION),
    [fetchingActionsList],
  )

  const hasErrorTheme = useMemo(() => {
    return (
      !isPrimerRetryProcessing &&
      !isPurchaseInProcess &&
      isCheckoutErrorThemeActive &&
      isErrorTheme
    )
  }, [
    isPrimerRetryProcessing,
    isPurchaseInProcess,
    isCheckoutErrorThemeActive,
    isErrorTheme,
  ])

  const isCardSelected = useMemo(
    () => selectedPaymentMethod === PaymentMethod.CREDIT_CARD,
    [selectedPaymentMethod],
  )

  const productId = useMemo(
    () =>
      createProductId({
        periodName,
        periodQuantity,
        price: trialPrice || price,
      }),
    [periodName, periodQuantity, trialPrice, price],
  )

  const { setNextAvailablePaymentMethod } = useSelectedPaymentMethod({
    hasErrorTheme,
    alternativePaymentMethods,
    setSelectedPaymentMethod,
  })

  useAmplitudeInitialization(cohort as Cohort, ScreenName.CHECKOUT)

  useEffect(() => {
    if (productId) {
      eventLogger.logPurchaseShown({
        productId,
        screenName,
        stripeAccountId,
        stripeAccountName,
      })
    }
  }, [productId, screenName, stripeAccountId, stripeAccountName])

  useEffect(() => {
    dispatch(startFetching(CHECK_PAYPAL_REQUEST_BUTTON))
  }, [dispatch])

  useEffect(() => {
    if (isPurchaseInProcess) {
      setIsPaymentWaitingShown(true)
    }
  }, [isPurchaseInProcess])

  const setPaymentMethod = useCallback(
    (paymentMethodFromUser: PaymentMethod) => {
      setSelectedPaymentMethod(paymentMethodFromUser)
      eventLogger.logPaymentMethodSelected(paymentMethodFromUser)
    },
    [],
  )

  const renderErrorHeader = () => {
    if (hasErrorTheme) {
      return (
        <>
          <S.Error>{t('cancelCheckout.title')}</S.Error>
          <S.CloseButton
            data-testid="close-btn"
            onClick={handleCloseCheckout}
          />
          <S.ErrorDescription>{t('cancelCheckout.problem')}</S.ErrorDescription>
          <S.ErrorDescription>
            {t('cancelCheckout.solution')}
          </S.ErrorDescription>
          <S.Divider />
        </>
      )
    }

    return null
  }

  if (threeDSecureIframeUrl) {
    return <S.ThreeDSecureIframe title="3DSecure" src={threeDSecureIframeUrl} />
  }

  return (
    selectedSubscription && (
      <S.Root
        errorTheme={hasErrorTheme}
        background={CustomBackgroundStyles.LIGHT_GRAY}
      >
        {!isCheckoutReady && <Spinner />}
        <S.ContentContainer
          isVisible={isCheckoutReady}
          errorTheme={hasErrorTheme}
        >
          {renderErrorHeader()}
          <S.PaymentMethodsTitle errorTheme={hasErrorTheme}>
            {alternativePaymentMethods.length
              ? t('checkoutExtended.selectMethods')
              : t('checkoutExtended.checkoutTitle')}
          </S.PaymentMethodsTitle>
          <S.CloseButton
            data-testid="close-btn"
            onClick={handleCloseCheckout}
          />
          {!!alternativePaymentMethods.length && (
            <S.PaymentMethodsWrapperV2>
              <S.PaymentMethod
                isSelected={isCardSelected}
                onClick={() => setPaymentMethod(PaymentMethod.CREDIT_CARD)}
              >
                <S.PaymentMethodText>
                  {t('checkoutExtended.creditCard')}
                </S.PaymentMethodText>
                <S.PaymentMethodImage
                  isSelected={isCardSelected}
                  src={PAYMENT_METHODS_IMAGES[PaymentMethod.CREDIT_CARD]}
                  alt="credit-card-img"
                />
              </S.PaymentMethod>
              {alternativePaymentMethods.includes(PaymentMethod.PAYPAL) && (
                <S.PaymentMethod
                  isSelected={selectedPaymentMethod === PaymentMethod.PAYPAL}
                  onClick={() => {
                    setPaymentMethod(PaymentMethod.PAYPAL)
                    cancelDiscount()
                  }}
                >
                  <S.AlternativePaymentMethodImage
                    isSelected={selectedPaymentMethod === PaymentMethod.PAYPAL}
                    src={PAYMENT_METHODS_IMAGES[PaymentMethod.PAYPAL]}
                    alt=""
                  />
                </S.PaymentMethod>
              )}
              {alternativePaymentMethods.includes(PaymentMethod.GOOGLE_PAY) && (
                <S.PaymentMethod
                  isSelected={
                    selectedPaymentMethod === PaymentMethod.GOOGLE_PAY
                  }
                  onClick={() => setPaymentMethod(PaymentMethod.GOOGLE_PAY)}
                >
                  <S.AlternativePaymentMethodImage
                    isSelected={
                      selectedPaymentMethod === PaymentMethod.GOOGLE_PAY
                    }
                    src={PAYMENT_METHODS_IMAGES[PaymentMethod.GOOGLE_PAY]}
                    alt=""
                  />
                </S.PaymentMethod>
              )}
              {alternativePaymentMethods.includes(PaymentMethod.APPLE_PAY) && (
                <S.PaymentMethod
                  isSelected={selectedPaymentMethod === PaymentMethod.APPLE_PAY}
                  onClick={() => setPaymentMethod(PaymentMethod.APPLE_PAY)}
                >
                  <S.AlternativePaymentMethodImage
                    isSelected={
                      selectedPaymentMethod === PaymentMethod.APPLE_PAY
                    }
                    src={PAYMENT_METHODS_IMAGES[PaymentMethod.APPLE_PAY]}
                    alt=""
                  />
                </S.PaymentMethod>
              )}
            </S.PaymentMethodsWrapperV2>
          )}

          <CheckoutPurchaseDetails errorTheme={hasErrorTheme} />

          <PrimerPaymentFormV2
            hasPayPalButton={hasPaypalButton}
            selectedPaymentMethod={
              selectedPaymentMethod || PaymentMethod.CREDIT_CARD
            }
            setAlternativePaymentMethodsCallback={setAlternativePaymentMethods}
          />
          <S.StripePaymentContainer
            isVisible={
              selectedPaymentMethod === PaymentMethod.GOOGLE_PAY ||
              selectedPaymentMethod === PaymentMethod.APPLE_PAY
            }
          >
            <StripeSeparatePaymentRequestButton
              key={selectedSubscription.id}
              setAlternativePaymentMethodsCallback={
                setAlternativePaymentMethods
              }
            />
          </S.StripePaymentContainer>

          <S.PaymentsSystemImage src={SecuritySystems} alt="security-systems" />
          <S.PaymentsSystemText>{t`purchase2.checkout.moneyBackGuarantee`}</S.PaymentsSystemText>
        </S.ContentContainer>

        {!isCheckoutErrorThemeActive && (
          <PrimerPaymentWaitingModal
            isPaymentWaitingShown={isPaymentWaitingShown}
            setIsPaymentWaitingShown={setIsPaymentWaitingShown}
            buttonText={t`purchase1.paymentWaiting.proceedToPlan`}
          />
        )}
        {isCheckoutErrorThemeActive && isPaymentWaitingShown && (
          <PrimerPaymentWaitingModalNoError
            setIsErrorTheme={setIsErrorTheme}
            isErrorTheme={isErrorTheme}
            setNextAvailablePaymentMethod={setNextAvailablePaymentMethod}
            setIsPaymentWaitingShown={setIsPaymentWaitingShown}
            buttonText={t`purchase1.paymentWaiting.proceedToPlan`}
          />
        )}
      </S.Root>
    )
  )
}
