import { Cohort } from 'root-constants'

export const MINIMALISTIC_HEADER_COHORTS = [
  Cohort.LUVLY_12_SALE,
  Cohort.LUVLY_CHAT,
  Cohort.LUVLY_CHIN,
  Cohort.LUVLY_GIFT,
  Cohort.LUVLY_GENERAL,
  Cohort.LUVLY_WELLHUB,
]
