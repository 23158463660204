import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { CustomerReviews } from 'components/CustomerReviews'
import { UserGoalGraph } from 'components/UserGoalGraph'

import { resetErrorAction, startFetching } from 'root-redux/actions/common'
import { selectUserGoal } from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store/store'

import { createProductId } from 'helpers/createProductId'

import { LongPaywallAppGallery } from 'modules/purchase/components/LongPaywallAppGallery'
import { LuvlyBenefitsCosmeticScanner } from 'modules/purchase/components/LuvlyBenefitsCosmeticScanner'
import { MoneyBackGuarantee } from 'modules/purchase/components/MoneyBackGuarantee'
import { PersonalizedPlan } from 'modules/purchase/components/PersonalizedPlan'
import { PurchaseDisclaimer } from 'modules/purchase/components/PurchaseDisclaimer'
import { SecurityInfo } from 'modules/purchase/components/SecurityInfo'
import { SubscriptionsIntroOfferBlock } from 'modules/purchase/components/SubscriptionsIntroOfferBlock'
import {
  useDefaultSubscription,
  usePricesStatus,
  usePurchaseAnalytics,
  usePurchaseStore,
} from 'modules/purchase/hooks'
import { useGetSubscriptionsList } from 'modules/purchase/hooks/useGetSubscriptionsList'
import { CHECK_PAYMENT_REQUEST_BUTTON } from 'modules/purchase/redux/actions/common'

import { eventLogger } from 'services/eventLogger.service'
import { googleAnalyticsLogger } from 'services/googleAnalytics.service'

import dailyScheduleImage from 'assets/images/daily-schedule.png'

import { PageId } from 'page-constants'
import {
  ScreenName,
  SubscriptionProduct,
  TimeInterval,
  UserGoal,
} from 'root-constants'

import { Checkout } from '../Checkout'
import { StyledPurchaseCosmeticScanner as S } from './PurchaseCosmeticScanner.styles'
import { PURCHASE_SUBTITLE_MAP, PURCHASE_TITLE_MAP } from './constants'

export const PurchaseCosmeticScanner: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const { arePricesReady } = usePricesStatus()
  const { pathname } = useLocation()
  const { cohort, periodName, periodQuantity, price, trialPeriodDays } =
    usePurchaseStore()
  const { sendGeneralPurchaseEvents } = usePurchaseAnalytics(
    ScreenName.PURCHASE,
  )
  const userGoal = useSelector(selectUserGoal)
  const elemForComparisonRef = useRef<HTMLHeadingElement | null>(null)

  const [isCheckoutShown, setIsCheckoutShown] = useState<boolean>(false)

  useDefaultSubscription()

  useGetSubscriptionsList()

  const productId = useMemo(
    () => createProductId({ periodName, periodQuantity, price }),
    [periodName, periodQuantity, price],
  )

  const handleShowCheckout = useCallback(() => {
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))
    setIsCheckoutShown(true)

    sendGeneralPurchaseEvents(false)
  }, [sendGeneralPurchaseEvents, dispatch])

  const handleCloseCheckout = useCallback(() => {
    dispatch(resetErrorAction())

    googleAnalyticsLogger.logPageView(`${pathname}/${cohort}`)
    eventLogger.logPurchaseScreenClosed({
      productId,
      screenName: ScreenName.PURCHASE,
    })
    setIsCheckoutShown(false)
  }, [dispatch, setIsCheckoutShown, pathname, cohort, productId])

  return arePricesReady ? (
    <>
      {!isCheckoutShown && (
        <S.Wrapper>
          <Container>
            <S.Title>
              <Trans
                i18nKey={
                  PURCHASE_TITLE_MAP[userGoal || UserGoal.WRINKLE_FREE_SKIN]
                }
                values={{
                  count:
                    trialPeriodDays === SubscriptionProduct.SEVEN_DAY
                      ? trialPeriodDays
                      : periodQuantity,
                  period:
                    trialPeriodDays === SubscriptionProduct.SEVEN_DAY
                      ? TimeInterval.DAY
                      : TimeInterval.MONTH,
                }}
              />
            </S.Title>
            <S.GoalSubtitle>
              {t(PURCHASE_SUBTITLE_MAP[userGoal || UserGoal.WRINKLE_FREE_SKIN])}
            </S.GoalSubtitle>
            <PersonalizedPlan elemForComparisonRef={elemForComparisonRef} />
            <S.GoalSubtitle ref={elemForComparisonRef}>
              {t('purchase1.goalSubtitle')}
            </S.GoalSubtitle>
            <UserGoalGraph pageId={PageId.PURCHASE} />
            <LuvlyBenefitsCosmeticScanner />
            <S.OfferSubtitle>
              {t('purchase1.subscription.unleashBeauty')}
            </S.OfferSubtitle>
            <S.SubscriptionsContainer>
              <SubscriptionsIntroOfferBlock hasTitle={false} />
            </S.SubscriptionsContainer>
            <S.ButtonContainer>
              <Button onClick={handleShowCheckout}>
                {t`actions.getMyPlan`}
              </Button>
            </S.ButtonContainer>
            <PurchaseDisclaimer />
          </Container>
          <LongPaywallAppGallery />
          <Container>
            <S.Subtitle>{t('purchase7.dailyRitualsSubtitle')}</S.Subtitle>
            <S.DailyScheduleImage
              src={dailyScheduleImage}
              alt="daily-schedule"
            />
            <CustomerReviews />
            <S.StartPlanButtonContainer>
              <Button data-testid="start-plan-btn" onClick={handleShowCheckout}>
                {t`actions.startMyPlan`}
              </Button>
            </S.StartPlanButtonContainer>
            <MoneyBackGuarantee />
            <SecurityInfo />
          </Container>
        </S.Wrapper>
      )}
      {isCheckoutShown && (
        <Checkout
          handleCloseCheckout={handleCloseCheckout}
          hasTotalAmount={false}
        />
      )}
    </>
  ) : null
}
