import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { Spinner } from 'components/Spinner'

import {
  getSubscriptionListAction,
  setScreenNameAction,
  startFetching,
} from 'root-redux/actions/common'
import {
  savePlanAdditionsAction,
  sendUserConfigAction,
} from 'root-redux/actions/user'
import { selectCurrentVariantCohort } from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'

import { CancelOfferWhatYouGet } from 'modules/purchase/components/CancelOfferWhatYouGet'
import { HolidaysCancelOfferTestimonials } from 'modules/purchase/components/HolidaysCancelOfferTestimonials'
import { PaymentWaitingModal } from 'modules/purchase/components/PaymentWaitingModal'
import { PurchaseUpgradeBenefits } from 'modules/purchase/components/PurchaseUpgradeBenefits'
import { PurchaseUpgradeShortDisclaimer } from 'modules/purchase/components/PurchaseUpgradeShortDisclaimer'
import { UpgradeSubscriptionItem } from 'modules/purchase/components/UpgradeSubscriptionItem'
import { UpgradeSubscriptionsBlock } from 'modules/purchase/components/UpgradeSubscriptionsBlock'
import { CURRENCY_SYMBOLS, MONTHS_IN_A_YEAR } from 'modules/purchase/constants'
import {
  useDefaultSubscription,
  usePricesStatus,
  usePurchaseStore,
} from 'modules/purchase/hooks'
import { usePurchaseUpgradeAnalytics } from 'modules/purchase/hooks/usePurchaseUpgradeAnalytics'
import {
  DISCLAIMERS,
  YEARLY_SUBSCRIPTIONS_DISCLAIMERS,
} from 'modules/purchase/pages/PurchaseUpgrade/constants'
import {
  CHECK_PAYMENT_REQUEST_BUTTON,
  purchaseUpgrade,
} from 'modules/purchase/redux/actions/common'
import {
  selectUpgradeSubscriptionFullPrice,
  selectUpgradeSubscriptionPrice,
} from 'modules/purchase/redux/selects/common'

import {
  CDN_FOLDER_LINK,
  CENTS_IN_DOLLAR,
  Cohort,
  PlanAddition,
  ScreenName,
  SubscriptionListType,
  TimeInterval,
} from 'root-constants'

import { StyledPurchaseUpgradePDF as S } from './PurchaseUpgradePDF.styles'

export const PurchaseUpgradePDF: React.FC = () => {
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const amountToPay = useSelector(selectUpgradeSubscriptionPrice)
  const fullPrice = useSelector(selectUpgradeSubscriptionFullPrice)

  const cohort = useSelector(selectCurrentVariantCohort)

  const { isUpgradeBundleCohort } = useActiveCohortIdentifier()

  const [isPaymentWaitingShown, setIsPaymentWaitingShown] = useState(false)

  const { currency, periodQuantity, periodName, language } = usePurchaseStore()

  const { sendGeneralPurchaseEvents } = usePurchaseUpgradeAnalytics()

  const { arePricesReady } = usePricesStatus()

  useDefaultSubscription()

  const isShortDisclaimerVisible = useMemo(() => {
    return (
      cohort === Cohort.LUVLY_CHAT_UPGRADE_12_PDF ||
      cohort === Cohort.LUVLY_CHAT_UPGRADE_BUNDLE_PDF
    )
  }, [cohort])

  useLayoutEffect(() => {
    dispatch(setScreenNameAction(ScreenName.LIVECHAT_UPGRADE))
    dispatch(getSubscriptionListAction(SubscriptionListType.UPGRADE))
  }, [dispatch])

  useEffect(() => {
    if (amountToPay && periodQuantity) {
      dispatch(
        sendUserConfigAction({
          subscription_upgrade_price: amountToPay / CENTS_IN_DOLLAR,
          subscription_upgrade_duration:
            periodName === TimeInterval.YEAR
              ? periodQuantity * MONTHS_IN_A_YEAR
              : periodQuantity,
        }),
      )
    }
  }, [amountToPay, dispatch, periodName, periodQuantity])

  const addToCart = useCallback(() => {
    dispatch(purchaseUpgrade())
  }, [dispatch])

  const upgradePlan = useCallback(() => {
    dispatch(startFetching(CHECK_PAYMENT_REQUEST_BUTTON))

    dispatch(savePlanAdditionsAction([PlanAddition.BONUS]))

    sendGeneralPurchaseEvents(false)

    addToCart()
  }, [addToCart, sendGeneralPurchaseEvents, dispatch])

  return !arePricesReady ? (
    <Spinner />
  ) : (
    <>
      <S.Root>
        <S.Hero
          src={`${CDN_FOLDER_LINK}/images/upgrade-bg_${language}.png`}
          alt="upgrade-bg"
        />
        <Container>
          {!isUpgradeBundleCohort && <UpgradeSubscriptionItem hasBonus />}
          {isUpgradeBundleCohort && <UpgradeSubscriptionsBlock />}
          <S.ButtonContainer>
            <Button type="button" onClick={upgradePlan}>
              {t('actions.getStartedNow')}
            </Button>
            {isShortDisclaimerVisible && <PurchaseUpgradeShortDisclaimer />}
          </S.ButtonContainer>
        </Container>

        <Container>
          <PurchaseUpgradeBenefits />
        </Container>

        <CancelOfferWhatYouGet
          buttonTitle={t('actions.getStartedNow')}
          handleShowCheckout={upgradePlan}
        />
        <S.HolidaysCancelOfferReviews />

        <Container>
          <S.ButtonContainer>
            <Button type="button" onClick={upgradePlan}>
              {t('actions.getStartedNow')}
            </Button>
          </S.ButtonContainer>
        </Container>

        <HolidaysCancelOfferTestimonials />

        <Container>
          {!isUpgradeBundleCohort && <UpgradeSubscriptionItem hasBonus />}
          {isUpgradeBundleCohort && <UpgradeSubscriptionsBlock />}
          <S.ButtonContainer>
            <Button type="button" onClick={upgradePlan}>
              {t('actions.getStartedNow')}
            </Button>
            {isShortDisclaimerVisible && <PurchaseUpgradeShortDisclaimer />}
          </S.ButtonContainer>
          <S.SecurityInfo
            withPricing
            disclaimerText={t(
              periodName === TimeInterval.YEAR
                ? YEARLY_SUBSCRIPTIONS_DISCLAIMERS[Number(periodQuantity)]
                : DISCLAIMERS[Number(periodQuantity)],
              {
                amountToPay: amountToPay / CENTS_IN_DOLLAR,
                fullPrice,
                currency: CURRENCY_SYMBOLS[currency],
              },
            )}
            hasTermsOfUse
          />
        </Container>
      </S.Root>
      <PaymentWaitingModal
        resetErrorCallback={addToCart}
        isPaymentWaitingShown={isPaymentWaitingShown}
        setIsPaymentWaitingShown={setIsPaymentWaitingShown}
        buttonText={t`purchase1.paymentWaiting.proceedToPlan`}
      />
    </>
  )
}
