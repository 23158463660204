import styled from 'styled-components'

import { DoubleChinTitle } from 'common-styles'
import { Color } from 'root-constants'

export const StyledCareCosmetics3 = {
  Title: styled(DoubleChinTitle)`
    margin-bottom: 8px;
  `,
  Description: styled.p`
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${Color.GRAY};
    margin-bottom: 24px;
  `,
}
