import styled from 'styled-components'

import { Color } from 'root-constants'

type TModalProps = {
  isVisible?: boolean
}

type TButtons = {
  disabled?: boolean
  roundedButtons?: boolean
}

export const StyledAnalyzingAnswersModal = {
  Modal: styled.div<TModalProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: ${({ isVisible }) => (isVisible ? 2 : -1)};
    visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    transition: 0.25s opacity;
    padding: 20px;
  `,
  ModalContent: styled.div`
    width: 100%;
    max-width: 320px;
    background: #ffffff;
    border-radius: 20px;
    padding: 24px 20px 32px;
  `,
  ModalTitle: styled.h3`
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-bottom: 32px;
    color: ${Color.DARK};
  `,
  ModalButton: styled.div<TButtons>`
    background: ${Color.CORAL_GRAY};
    border-radius: ${({ roundedButtons }) =>
      roundedButtons ? '30px' : '12px'};
    padding: 15px;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: ${Color.DARK};
    width: 100%;
    border: 0;
    margin-bottom: 16px;
    pointer-events: ${({ disabled }) => disabled && 'none'};
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }

    input:checked + & {
      color: #fff;
      background-color: ${Color.PRIMARY};
      transition: 0.25s;
      pointer-events: none;
    }
  `,
  BoldModalAnswer: styled.span`
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.BLUE_DARK};
  `,
}
