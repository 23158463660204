import React from 'react'
import { useTranslation } from 'react-i18next'

import { StyledFaceYogaCourseSteps as S } from './FaceYogaCourseSteps.styles'
import { FACE_YOGA_COURSE_STEPS } from './constants'

type TFaceYogaCourseStepsProps = {
  className?: string
}

export const FaceYogaCourseSteps: React.FC<TFaceYogaCourseStepsProps> = ({
  className,
}: TFaceYogaCourseStepsProps) => {
  const { t } = useTranslation()

  return (
    <S.StepsContainer className={className}>
      <S.StepsTitle>{t(`onboarding.introV2.stepsTitle`)}</S.StepsTitle>
      <S.StepsList>
        {FACE_YOGA_COURSE_STEPS.map((item, index) => (
          <S.ListItem key={item.title}>
            <S.Marker>{index + 1}</S.Marker>
            <S.Content>
              <S.ContentTitle>{t(item.title)}</S.ContentTitle>
              <S.ContentDescription>{t(item.description)}</S.ContentDescription>
            </S.Content>
          </S.ListItem>
        ))}
      </S.StepsList>
    </S.StepsContainer>
  )
}
