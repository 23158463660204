import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Container } from 'components/Container'
import { Option } from 'components/Option'

import { selectAnswers } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { PageId } from 'page-constants'
import { Gender, OptionType } from 'root-constants'

import { StyledAgeWithImages2 as S } from './AgeWithImages2.styles'
import {
  AGE_OPTION_VALUES_MAP,
  QUESTION,
  WELLHUB_AGE_OPTIONS,
} from './constants'

export const AgeWithImages2: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const userGender = userAnswers?.[PageId.GENDER]

  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: PageId.AGE,
    question: QUESTION,
    nextPagePath,
  })

  const handleAnswerChange = (value: string) => {
    eventLogger.logUserAgeSelected(AGE_OPTION_VALUES_MAP[value])
    handleChange(value, handleContinue)
    setIsAnswersDisabled(true)
  }

  const optionProps = {
    name: PageId.AGE,
    type: OptionType.RADIO,
    disabled: isAnswersDisabled,
    onChange: handleAnswerChange,
  }

  return (
    <Container>
      <S.Title>
        <Trans i18nKey="onboarding.ageWithImages2.question" />
      </S.Title>
      <S.Description>
        {t('onboarding.ageWithImages2.description')}
      </S.Description>
      <S.Actions>
        {WELLHUB_AGE_OPTIONS.map(({ value, text, man, woman }) => (
          <Option {...optionProps} value={value} key={value}>
            <S.Action>
              <S.Image src={userGender === Gender.MALE ? man : woman} alt="" />
              <S.Answer />
              <S.AnswerWrapper>
                <S.AnswerValue>
                  {t('onboarding.ageAsIntro.ageLabel')} {text}
                </S.AnswerValue>
                <S.ChevronRight />
              </S.AnswerWrapper>
            </S.Action>
          </Option>
        ))}
      </S.Actions>
    </Container>
  )
}
