import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AnswerButton } from 'components/AnswerButton'
import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'
import { Option } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useCohortToUse } from 'hooks/useCohortToUse'
import { useIsNavigationOn } from 'hooks/useIsNavigationOn'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { goBack } from 'browser-history'
import { BottomButtonContainer } from 'common-styles'
import { I18N_CONTEXT_COHORT_MAP, OptionType } from 'root-constants'
import { PROBLEMATIC_AREAS } from 'shared-options'

import { StyledProblematicAreas as S } from './ProblematicAreas.styles'
import {
  OPTION_VALUES,
  PROBLEMATIC_AREAS_DEFAULT_OPTIONS,
  PROBLEMATIC_AREAS_OPTIONS_MAP,
  QUESTION,
} from './constants'

export const ProblematicAreas: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answers, setAnswers] = useState<{ value: string; label: string }[]>([])
  const includeNavigation = useIsNavigationOn()
  const cohortToUse = useCohortToUse()

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => {
    const uniqueAnswers = answers.map((answer) => answer.label)
    handleContinue(answers, uniqueAnswers)
  }

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    onChange: (value, isChecked) => {
      if (isChecked) {
        const userAnswer = PROBLEMATIC_AREAS.find(
          (item) => item.label === value,
        )
        userAnswer && setAnswers([...answers, userAnswer])
      } else {
        setAnswers(answers.filter((item) => item.label !== value))
      }
    },
  }

  const problematicAreasOptions: {
    value: OPTION_VALUES
    text: string
    image?: string
  }[] =
    PROBLEMATIC_AREAS_OPTIONS_MAP[cohortToUse] ||
    PROBLEMATIC_AREAS_DEFAULT_OPTIONS

  return (
    <div>
      <S.TitleContainer>
        <PageTitle>
          <Trans
            i18nKey="onboarding.problematicAreas.question"
            components={[<br />]}
            context={I18N_CONTEXT_COHORT_MAP[cohortToUse] || ''}
          />
        </PageTitle>
      </S.TitleContainer>
      <Container>
        {problematicAreasOptions.map(({ value, text, image }) => (
          <Option {...optionProps} value={value} key={value}>
            <AnswerButton withCheckbox>
              <S.OptionContentWrapper>
                {image && <S.OptionImage src={image} />}
                {t(text)}
              </S.OptionContentWrapper>
            </AnswerButton>
          </Option>
        ))}
      </Container>
      {includeNavigation ? (
        <NavigationButtons
          onBackClick={goBack}
          onNextClick={handleNextClick}
          disabled={!answers.length}
        />
      ) : (
        <BottomButtonContainer>
          <Button onClick={handleNextClick} disabled={!answers.length}>
            {t`actions.continue`}
          </Button>
        </BottomButtonContainer>
      )}
    </div>
  )
}
