import React from 'react'
import { useTranslation } from 'react-i18next'

import { Answer, answerTheme } from 'storybook-ui'

import { AdaptiveContainer } from 'components/AdaptiveContainer'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import desktopBackground from 'assets/images/intro-fast-girl-8-desktop.png'
import mobileBackground from 'assets/images/intro-fast-girl-8-mobile.png'

import { PageId } from 'page-constants'
import { OptionType } from 'root-constants'

import { QUESTION, SKIN_TYPE_DEFAULT_OPTIONS } from './constants'

export const SkinTypeV3: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const handleContinue = useNextStep({
    pageId: PageId.SKIN_TYPE,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    theme: answerTheme.LUVLY_SOLID,
    disabled: isAnswersDisabled,
    fontWeight: '600',
    maxWidth: '488px',
    onChange: (value: string) => {
      setIsAnswersDisabled(true)
      handleChange(value, handleContinue)
    },
  }

  return (
    <AdaptiveContainer
      title="onboarding.skinType.question"
      mobileBackground={mobileBackground}
      desktopBackground={desktopBackground}
    >
      {SKIN_TYPE_DEFAULT_OPTIONS.map(({ text, value }) => {
        return (
          <Answer {...optionProps} key={text} value={value}>
            {t(text)}
          </Answer>
        )
      })}
    </AdaptiveContainer>
  )
}
