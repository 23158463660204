import amelia from 'assets/images/amelia-before-after.png'
import charlotte from 'assets/images/charlotte-before-after.png'
import coachRecommendation from 'assets/images/coach-recommendation.png'
import diana from 'assets/images/diana-before-after.png'
import faceYogaCoach from 'assets/images/face-yoga-coach.png'
import greatProgress from 'assets/images/great-progress.png'
import marissa from 'assets/images/marissa-before-after.png'
import diagnosticIcon from 'assets/images/sprite/diagnostic-icon.svg'
import faceWithHearts from 'assets/images/sprite/face-with-hearts.svg'
import okaySign from 'assets/images/sprite/okay-sign.svg'
import privateChatIcon from 'assets/images/sprite/private-chat.svg'
import programEffectiveness from 'assets/images/sprite/program-effectiveness.svg'
import thumbUp from 'assets/images/sprite/thumb-up.svg'

export const UPSELL_FEATURES = [
  {
    icon: diagnosticIcon,
    title: 'upsellCoaching.upsellFeatures.option1.title',
    description: 'upsellCoaching.upsellFeatures.option1.description',
    image: faceYogaCoach,
  },
  {
    icon: privateChatIcon,
    title: 'upsellCoaching.upsellFeatures.option2.title',
    description: 'upsellCoaching.upsellFeatures.option2.description',
    image: coachRecommendation,
  },
  {
    icon: programEffectiveness,
    title: 'upsellCoaching.upsellFeatures.option3.title',
    description: 'upsellCoaching.upsellFeatures.option3.description',
    image: greatProgress,
  },
]

export const STEPS = [
  {
    id: 1,
    title: 'upsellCoaching.steps.first.title',
    description: 'upsellCoaching.steps.first.description',
  },
  {
    id: 2,
    title: 'upsellCoaching.steps.second.title',
    description: 'upsellCoaching.steps.second.description',
  },
  {
    id: 3,
    title: 'upsellCoaching.steps.third.title',
    description: 'upsellCoaching.steps.third.description',
  },
]

export const CUSTOMER_REVIEWS = [
  {
    title: 'upsellCoaching.customerReviews.first.title',
    review: 'upsellCoaching.customerReviews.first.review',
    icon: faceWithHearts,
    name: 'upsellCoaching.customerReviews.first.name',
  },
  {
    title: 'upsellCoaching.customerReviews.second.title',
    review: 'upsellCoaching.customerReviews.second.review',
    icon: okaySign,
    name: 'upsellCoaching.customerReviews.second.name',
  },
  {
    title: 'upsellCoaching.customerReviews.third.title',
    review: 'upsellCoaching.customerReviews.third.review',
    icon: thumbUp,
    name: 'upsellCoaching.customerReviews.third.name',
  },
]

export const BEFORE_AFTER_REVIEWS = [
  {
    img: diana,
    title: 'upsellCoaching.beforeAfter.diana',
  },
  {
    img: marissa,
    title: 'upsellCoaching.beforeAfter.marissa',
  },
  {
    img: amelia,
    title: 'upsellCoaching.beforeAfter.amelia',
  },
  {
    img: charlotte,
    title: 'upsellCoaching.beforeAfter.charlotte',
  },
]
