import styled, { css } from 'styled-components'

import { Color } from 'root-constants'

export const StyledSecurityInfo = {
  Wrapper: styled.div<{ hasSecureBadge: boolean }>`
    margin: ${({ hasSecureBadge }) =>
      hasSecureBadge ? '66px 0 30px' : '30px 0'};
    padding: ${({ hasSecureBadge }) =>
      hasSecureBadge ? '46px 15px 0' : '16px 15px 0'};
    background: ${Color.WHITE};
    position: relative;
    ${({ theme }) => css`
      box-shadow: ${theme.securityInfo.wrapper.boxShadow};
      border-radius: ${theme.securityInfo.wrapper.borderRadius};
    `}
  `,
  Title: styled.h2`
    padding-bottom: 10px;
    font-size: 17px;
    ${({ theme }) => css`
      color: ${theme.securityInfo.title.color};
      font-weight: ${theme.securityInfo.title.fontWeight};
      line-height: ${theme.securityInfo.title.lineHeight};
    `}
  `,
  Text: styled.p`
    padding-bottom: 20px;
    font-weight: normal;
    font-size: 14px;

    ${({ theme }) => css`
      line-height: ${theme.securityInfo.text.lineHeight};
      color: ${theme.securityInfo.text.color};

      a {
        font-weight: ${theme.securityInfo.link.fontWeight};
        color: ${theme.securityInfo.link.color};
      }
    `}
  `,
  SecureBadgeContainer: styled.div`
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    display: grid;
    place-content: center;
  `,
  SecureBadgeImg: styled.img`
    max-width: 156px;
  `,
}
