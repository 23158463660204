import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AnswerButton } from 'components/AnswerButton'
import { Container } from 'components/Container'
import { NavigationButtons } from 'components/NavigationButtons'
import { Option } from 'components/Option'
import { PageTitle } from 'components/PageTitle'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useIsNavigationOn } from 'hooks/useIsNavigationOn'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import fireIcon from 'assets/images/fire-emoji.png'
import girlSkinIcon from 'assets/images/girl-skin-emoji.png'
import sparklesIcon from 'assets/images/sparkles-emoji.png'

import { goBack } from 'browser-history'
import { PageId } from 'page-constants'
import { I18N_CONTEXT_COHORT_MAP, OptionType } from 'root-constants'

import { StyledGoal as S } from './Goal.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const GoalVariant2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { isSeniorCohort } = useActiveCohortIdentifier()
  const includeNavigation = useIsNavigationOn()
  const cohortToUse = useCohortToUse()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const [answer, setAnswer] = useState('')

  const handleContinue = useNextStep({
    pageId: PageId.INTRO,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => handleContinue(answer)

  const handleAnswerChange = (value: string) => {
    if (!isSeniorCohort) {
      setAnswer(value)
      return
    }

    handleChange(value, handleContinue)
    setIsAnswersDisabled(true)
  }

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: handleAnswerChange,
  }

  return (
    <div>
      <Container>
        <S.TitleContainer>
          <PageTitle>{t`onboarding.goal.question2`}</PageTitle>
        </S.TitleContainer>
        <div>
          <Option
            {...optionProps}
            value={OPTION_VALUES.TIGHTEN_SKIN}
            disabled={isAnswersDisabled}
          >
            <AnswerButton iconSrc={girlSkinIcon}>
              {t`onboarding.goal.tightenSkin`}
            </AnswerButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.REDUCE_FAT}
            disabled={isAnswersDisabled}
          >
            <AnswerButton iconSrc={fireIcon}>
              {t('onboarding.goal.loseFat', {
                context: I18N_CONTEXT_COHORT_MAP[cohortToUse] || '',
              })}
            </AnswerButton>
          </Option>
          <Option
            {...optionProps}
            value={OPTION_VALUES.GET_RID}
            disabled={isAnswersDisabled}
          >
            <AnswerButton iconSrc={sparklesIcon}>
              {t('onboarding.goal.getRid2', {
                context: I18N_CONTEXT_COHORT_MAP[cohortToUse] || '',
              })}
            </AnswerButton>
          </Option>
        </div>
      </Container>
      {includeNavigation && (
        <NavigationButtons
          onBackClick={goBack}
          onNextClick={handleNextClick}
          disabled={!answer}
        />
      )}
    </div>
  )
}
