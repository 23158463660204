import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Answer, answerTheme } from 'storybook-ui'

import { Container } from 'components/Container'

import { selectAnswers } from 'root-redux/selects/common'

import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { DoubleChinTitle, SandyBackgroundGlobalStyles } from 'common-styles'
import { PageId } from 'page-constants'
import { OptionType, UserGoal } from 'root-constants'

import {
  FRUSTRATING_REASONS_ANSWERS_MAP,
  FRUSTRATING_REASONS_TITLES_MAP,
  QUESTIONS_MAP,
} from './constants'

export const FrustratingReasons: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()

  const userGoal = userAnswers?.[PageId.INTRO] || UserGoal.WRINKLE_FREE_SKIN

  const handleContinue = useNextStep({
    pageId,
    question: QUESTIONS_MAP[userGoal],
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    theme: answerTheme.LUVLY_SOLID,
    disabled: isAnswersDisabled,
    fontWeight: '600',
    onChange: (value: string) => {
      setIsAnswersDisabled(true)
      handleChange(value, handleContinue)
    },
  }

  return (
    <div>
      <Container>
        <DoubleChinTitle>
          <Trans
            i18nKey={FRUSTRATING_REASONS_TITLES_MAP[userGoal]}
            components={[<strong />]}
          />
        </DoubleChinTitle>
        {FRUSTRATING_REASONS_ANSWERS_MAP[userGoal].map(({ text, label }) => (
          <Answer {...optionProps} key={label} value={label}>
            {t(text)}
          </Answer>
        ))}

        <SandyBackgroundGlobalStyles />
      </Container>
    </div>
  )
}
