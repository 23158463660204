import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledAffectSkinCondition = {
  Title: styled.h1`
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 24px auto;
    color: ${Color.BLUE_DARK};
  `,
}
