import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { MAX_COUNTER_VALUE } from 'pages/questions/CreatingSculptingPlan/constants'

import { IntroHeader } from 'components/IntroHeader'
import { IntroHeaderColor } from 'components/IntroHeader/constants'
import { PrivacyPolicyLink } from 'components/PrivacyPolicyLink'
import { TermsOfUseLink } from 'components/TermsOfUseLink'

import { selectUserConfigData } from 'root-redux/selects/user'

import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useCounter } from 'hooks/useCounter'
import { useNextStep } from 'hooks/useNextStep'
import { useSendIntroPageCompletedAnalytics } from 'hooks/useSendIntroPageCompletedAnalytics'
import { useStartSession } from 'hooks/useStartSession'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import { PageId } from 'page-constants'

import { StyledLoadingIntro2 as S } from './LoadingIntro2.styles'

export const LoadingIntro2: React.FC<TPageProps> = ({ nextPagePath }) => {
  const { t } = useTranslation()
  const config = useSelector(selectUserConfigData)

  const { sendIntroPageCompletedAnalytics } =
    useSendIntroPageCompletedAnalytics()
  const { isEUUser } = useCookieConsentAnswer()
  const [isAnimationActive, setIsAnimationActive] = useState(false)
  const userCookieConsentAnswer = config?.cookieConsent

  useStartSession()

  const handleContinue = useNextStep({
    pageId: PageId.INTRO,
    question: '',
    nextPagePath,
  })

  const handleNext = useCallback(() => {
    sendIntroPageCompletedAnalytics()

    eventLogger.logFirstPageCompleted({
      question: '',
    })

    handleContinue('')
  }, [handleContinue, sendIntroPageCompletedAnalytics])

  useEffect(() => {
    const handleAnimationStart = () => {
      setIsAnimationActive(true)
    }
    window.addEventListener('consent_submitted', handleAnimationStart)

    return () =>
      window.removeEventListener('consent_submitted', handleAnimationStart)
  }, [])

  const { counter } = useCounter(
    !isEUUser || !!userCookieConsentAnswer || isAnimationActive,
    {
      duration: 5000,
      to: MAX_COUNTER_VALUE,
      callback: handleNext,
    },
  )

  return (
    <div>
      <IntroHeader
        colorTheme={IntroHeaderColor.DARK}
        isSupportActionVisible={false}
      />
      <S.Root>
        <S.Container>
          <S.Title>{t('onboarding.loadingIntro2.title')}</S.Title>
          <S.ImageContainer />
          <S.Note>{t('onboarding.loadingIntro2.note')}</S.Note>
          <S.Progress value={counter} max={MAX_COUNTER_VALUE} />
          <S.AnalyzingProfile>
            {t('onboarding.loadingIntro2.analyzingProfile')}
          </S.AnalyzingProfile>
          <S.Terms>
            <p>{t`onboarding.loadingIntro2.termsTitle`}</p>
            <TermsOfUseLink />
            <S.Separator />
            <PrivacyPolicyLink />
          </S.Terms>
        </S.Container>
      </S.Root>
    </div>
  )
}
