import styled from 'styled-components'

import { DoubleChinTitle } from 'common-styles'
import { Color, MediaBreakpoint } from 'root-constants'

export const StyledReverseYourWrinkles3 = {
  Title: styled(DoubleChinTitle)`
    text-align: center;
  `,
  GraphContainer: styled.div`
    aspect-ratio: 343/176;
    width: 100%;
    max-width: 343px;
    height: auto;
    margin: 0 auto 20px;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      margin: 0 auto 32px;
    }
  `,
  Card: styled.div`
    display: flex;
    align-items: flex-start;
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(202, 202, 202, 0.5);

    &:last-child {
      padding-bottom: 0;
      margin-bottom: 0;
      border-bottom: none;
    }

    & img {
      margin-right: 12px;
      width: 80px;
    }

    & p {
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.5);

      & strong {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 18px;
        color: ${Color.BLUE_DARK};
      }
    }
  `,
  ImageContainer: styled.div`
    width: 80px;
    flex-shrink: 0;
    margin-top: -6px;
  `,
}
