import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { OPTION_VALUES as USER_ANSWERS } from 'pages/questions/UselessProducts/constants'

import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { selectAnswers } from 'root-redux/selects/common'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import progress20 from 'assets/images/scanner-feedback-20.png'
import progress80 from 'assets/images/scanner-feedback-80.png'

import { PageId } from 'page-constants'

import { StyledScannerFeedback as S } from './ScannerFeedback.styles'
import { QUESTION } from './constants'

export const ScannerFeedback: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const userAnswers = useSelector(selectAnswers)

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const isProductFitUser =
    userAnswers?.[PageId.USELESS_PRODUCTS] === USER_ANSWERS.NO

  const handleNextClick = () => handleContinue('')

  return (
    <S.Root>
      <Container>
        <S.Title>
          <Trans
            i18nKey="onboarding.scannerFeedback.question"
            context={isProductFitUser ? USER_ANSWERS.NO : USER_ANSWERS.YES}
          />
        </S.Title>
        <S.Description>
          <Trans
            i18nKey="onboarding.scannerFeedback.description"
            context={isProductFitUser ? USER_ANSWERS.NO : USER_ANSWERS.YES}
            components={[<br />]}
          />
        </S.Description>
        <S.ImageContainer>
          {isProductFitUser ? (
            <S.Image src={progress20} alt="" />
          ) : (
            <S.Image src={progress80} alt="" />
          )}
        </S.ImageContainer>
        <StickyButtonContainer>
          <Button onClick={handleNextClick}>{t('actions.next')}</Button>
        </StickyButtonContainer>
      </Container>
    </S.Root>
  )
}
