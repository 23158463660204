import React from 'react'
import { Trans } from 'react-i18next'

import { StyledInfluencersDiscount as S } from './InfluencersDiscount.styles'

export const InfluencersDiscount: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Content>
        <Trans i18nKey="purchase7.userProfile.ambassadorDiscount" />
      </S.Content>
    </S.Wrapper>
  )
}
