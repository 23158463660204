import { Cohort } from 'root-constants'

export const TINY_FIELDS = 16
export const DEFAULT_FIELDS = 20
export const MAX_WIDTH_375 = 375

export const TINY_FIELDS_COHORTS = {
  [Cohort.LUVLY_CHAT]: TINY_FIELDS,
  [Cohort.LUVLY_CHIN]: TINY_FIELDS,
  [Cohort.LUVLY_GENERAL]: TINY_FIELDS,
  [Cohort.LUVLY_WELLHUB]: TINY_FIELDS,
}

export const WIDE_CONTAINER_COHORTS = {
  [Cohort.LUVLY_CHIN]: MAX_WIDTH_375,
  [Cohort.LUVLY_GIFT]: MAX_WIDTH_375,
  [Cohort.LUVLY_GENERAL]: MAX_WIDTH_375,
  [Cohort.LUVLY_WELLHUB]: MAX_WIDTH_375,
}
