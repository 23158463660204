import styled from 'styled-components'

import { Option } from 'components/Option'

import chevronRightCoral from 'assets/images/chevron-right-coral.png'
import variant2DesktopBg from 'assets/images/introsale2-intro-desktop.jpg'
import variant2MobileBg from 'assets/images/introsale2-intro-mobile.jpg'

import { Color, MediaBreakpoint } from 'root-constants'

type TLocaleProps = {
  isEnLocaleStyles: boolean
}

export const StyledIntroSaleAgeAsIntro = {
  Root: styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-image: url(${variant2MobileBg});
    background-position: top 88px center;
    background-repeat: no-repeat;
    background-size: cover;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      background-image: url(${variant2DesktopBg});
      background-position: top center;
    }
  `,
  ImageContainer: styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    max-height: calc(var(--full-height) - 446px);
  `,
  Container: styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-end;
    min-height: calc(var(--full-height) - 88px);
    padding: 0 12px;
  `,
  Title: styled.h1<TLocaleProps>`
    color: ${Color.BLUE_DARK};
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    max-width: 300px;
    margin: 16px auto;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      margin: 0 auto 16px;
      max-width: 520px;
    }
  `,
  Subtitle: styled.p`
    color: ${Color.LIGHT};
    text-align: center;
    font-family: 'Open Sans';
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  `,
  GirlImage: styled.img`
    display: none;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      display: block;
      max-height: 331px;
      width: auto;
      object-fit: contain;
    }
  `,
  FlowersImage: styled.img`
    display: block;
    height: auto;
    max-width: 100%;
    object-fit: contain;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      display: none;
    }
  `,
  Actions: styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 48px 48px;
    gap: 16px;
    margin-top: 24px;

    & label {
      flex-basis: calc(50% - 8px);
      margin-bottom: 0;
    }

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      width: 360px;
      margin: 16px auto 0;
    }
  `,
  Action: styled.div`
    display: flex;
  `,
  AnswerWrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.2s;
    border-radius: 16px;
    font-size: 15px;
    line-height: 20px;
    font-style: normal;
    font-weight: 600;
    font-family: 'Open Sans';
    height: 48px;
    width: 100%;
    padding: 12px;
    color: ${Color.WHITE};
    background-color: ${Color.PRIMARY};
  `,
  AnswerValue: styled.span``,
  ChevronRight: styled.div`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    position: relative;
    background-color: ${Color.WHITE};

    &::before {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      background-image: url(${chevronRightCoral});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  `,
  StyledOption: styled(Option)`
    border-radius: 16px;

    input:checked + div > div {
      background: ${Color.RED};
    }
  `,
  Terms: styled.div`
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: 'Open Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.5px;
    color: ${Color.GRAY_SECONDARY};
    margin: 24px auto 16px;
    text-align: center;

    a {
      color: ${Color.GRAY_SECONDARY};
      text-decoration: underline;
    }
  `,
  Separator: styled.span`
    display: inline-flex;
    height: 13px;
    width: 1px;
    background-color: ${Color.GRAY_SECONDARY};
    margin: 0 5px;
    vertical-align: middle;
  `,
}
