import React, { Dispatch, SetStateAction, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { InputWithFloatPlaceholder2 } from 'components/InputWithFloatPlaceholder'

import { IInputFieldState } from 'models/inputField.model'

type TProps = {
  email: IInputFieldState
  setEmail: Dispatch<SetStateAction<IInputFieldState>>
  password: IInputFieldState
  setPassword: Dispatch<SetStateAction<IInputFieldState>>
  setAreEmailTipsVisible: Dispatch<SetStateAction<boolean>>
}

export const WellhubEmailLogin: React.FC<TProps> = ({
  email,
  setEmail,
  password,
  setPassword,
  setAreEmailTipsVisible,
}) => {
  const { t } = useTranslation()

  const handleChange = useCallback(
    (
      stateCallback: Dispatch<SetStateAction<IInputFieldState>>,
      newData: Partial<IInputFieldState>,
    ) => {
      stateCallback((prevState) => ({ ...prevState, ...newData }))
    },
    [],
  )

  return (
    <>
      <InputWithFloatPlaceholder2
        value={email.value}
        isValid={email.isValid}
        labelName={t`login.emailPlaceholder`}
        errorText={email.firebaseError || email.validationText}
        hasValidationIcon
        onChange={(e) => {
          setAreEmailTipsVisible(true)
          return handleChange(setEmail, { value: e.target.value.trim() })
        }}
        onFocus={() => handleChange(setEmail, { isFocused: true })}
        onBlur={() => handleChange(setEmail, { isFocused: false })}
      />

      <InputWithFloatPlaceholder2
        value={password.value}
        isValid={password.isValid}
        labelName={t`login.passwordPlaceholder`}
        errorText={password.firebaseError || password.validationText}
        type="password"
        hasValidationIcon
        onChange={(e) => handleChange(setPassword, { value: e.target.value })}
        onFocus={() => handleChange(setPassword, { isFocused: true })}
        onBlur={() => handleChange(setPassword, { isFocused: false })}
      />
    </>
  )
}
