import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledManageStressLevels = {
  Description: styled.p`
    color: ${Color.GRAY};
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin: -16px auto 24px;
  `,
  OptionsContainer: styled.div`
    display: flex;
    justify-content: space-between;
    gap: 2px;
  `,
  Content: styled.div`
    margin: 0 auto;
  `,
  Icon: styled.img`
    max-width: 36px;
    height: auto;
    margin-bottom: 4px;
  `,
  Text: styled.p`
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 12px;
    text-align: center;
  `,
}
