import React, { useEffect, useRef } from 'react'
import { Trans } from 'react-i18next'

import lottie from 'lottie-web/build/player/lottie_light'

import savedCoinsAnimation from 'assets/animation/savedCoinsAnimation.json'
import savedMoney from 'assets/images/saved-money.png'

import { StyledSavingMoney as S } from './SavingMoney.styles'

export const SavingMoney: React.FC = () => {
  const animationContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (animationContainerRef && animationContainerRef.current) {
      lottie.loadAnimation({
        container: animationContainerRef.current,
        animationData: savedCoinsAnimation,
        loop: true,
      })
    }

    return () => lottie.destroy()
  }, [])

  return (
    <S.Root>
      <S.AnimationContainer ref={animationContainerRef} />
      <S.SaveImage src={savedMoney} alt="saved-money" />
      <S.Text>
        <Trans i18nKey="purchase8.savingMoney.text" />
      </S.Text>
    </S.Root>
  )
}
