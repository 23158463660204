import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { AnswerWithIcon, answerWithIconTheme } from 'storybook-ui'

import { Container } from 'components/Container'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useDelayedContinue } from 'hooks/useDelayedContinue'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import { eventLogger } from 'services/eventLogger.service'

import femaleIcon from 'assets/images/female-icon.png'
import maleIcon from 'assets/images/male-icon.png'
import nonBinary from 'assets/images/non-binary-icon.png'

import { DoubleChinTitle, SandyBackgroundGlobalStyles } from 'common-styles'
import { PageId } from 'page-constants'
import { OptionType } from 'root-constants'

import { StyledGender as S } from './Gender.styles'
import { OPTION_VALUES, QUESTION } from './constants'

export const GenderV2: React.FC<TPageProps> = ({ pageId, nextPagePath }) => {
  const { t } = useTranslation()
  const { isAnswersDisabled, handleChange, setIsAnswersDisabled } =
    useDelayedContinue()
  const { isLuvlyGeneralCohort, isLuvlyWellhubCohort } =
    useActiveCohortIdentifier()

  const handleContinue = useNextStep({
    pageId: PageId.GENDER,
    question: QUESTION,
    nextPagePath,
  })

  const handleAnswerChange = (value: string) => {
    eventLogger.logUserGenderSelected(value)
    handleChange(value, handleContinue)
    setIsAnswersDisabled(true)
  }

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    onChange: handleAnswerChange,
    theme: answerWithIconTheme.LUVLY_SOLID,
    disabled: isAnswersDisabled,
    iconMinWidth: '40px',
    fontWeight: '600',
  }

  return (
    <Container>
      <DoubleChinTitle>
        <Trans i18nKey="onboarding.genderV2.title" />
      </DoubleChinTitle>
      {(isLuvlyGeneralCohort || isLuvlyWellhubCohort) && (
        <S.Description>{t('onboarding.genderV2.description')}</S.Description>
      )}
      <AnswerWithIcon
        {...optionProps}
        value={OPTION_VALUES.FEMALE}
        iconSrc={femaleIcon}
      >
        {t('onboarding.genderV2.female')}
      </AnswerWithIcon>
      <AnswerWithIcon
        {...optionProps}
        value={OPTION_VALUES.MALE}
        iconSrc={maleIcon}
      >
        {t('onboarding.genderV2.male')}
      </AnswerWithIcon>
      <AnswerWithIcon
        {...optionProps}
        value={OPTION_VALUES.OTHER}
        iconSrc={nonBinary}
      >
        {t('onboarding.genderV2.other')}
      </AnswerWithIcon>
      <SandyBackgroundGlobalStyles />
    </Container>
  )
}
