import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledCreatingSculptingPlan = {
  Wrapper: styled.div`
    position: relative;
    height: 176px;
    margin-bottom: 24px;
  `,
  CounterValue: styled.p`
    height: 28px;
    width: 100px;
    color: ${Color.BLUE_DARK};
    display: flex;
    justify-content: center;
    font-family: 'Rozha One';
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.5px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
  `,
  AnimationContainer: styled.div`
    width: 100%;
    margin: 0 auto;
  `,
  Subtitle: styled.p`
    color: ${Color.DARK};
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    margin-bottom: 24px;
  `,
  Reviews: styled.div`
    display: flex;
    gap: 12px;
    margin-bottom: 16px;
  `,
  ReviewContainer: styled.div`
    background-color: ${Color.WHITE};
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    border-radius: 16px;
    box-shadow: 0px 4px 14px 0px rgba(8, 4, 23, 0.03);
  `,
  ReviewAuthor: styled.p`
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    color: ${Color.MIDNIGHT_BLUE};
  `,
  StarsImage: styled.img`
    width: 83px;
    height: auto;
  `,
  Review: styled.p`
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.GRAY};
  `,
}
