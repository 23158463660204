import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AnswerAccordion,
  Button,
  answerAccordionTheme,
  buttonTheme,
} from 'storybook-ui'

import { AdaptiveContainer } from 'components/AdaptiveContainer'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import coralMessage from 'assets/images/coral-message.png'

import { ONBOARDING_INPUT_MIN_LENGTH, OptionType } from 'root-constants'

import { StyledDietVariant2 as S } from './DietVariant2.styles'
import { DIET2_OPTIONS, QUESTION } from './constants'

export const DietVariant3: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()

  const [answer, setAnswer] = useState<string>('')
  const [diet, setDiet] = useState({ value: '', isValid: false })

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    theme: answerAccordionTheme.LUVLY,
    iconSrc: coralMessage,
    iconWidth: '24px',
    iconHeight: '24px',
    spacingBetweenIconAndContent: '12px',
    fontWeight: '600',
    maxWidth: '488px',
    onChange: (value: string) => {
      setAnswer(value)
      setDiet({
        value: '',
        isValid: false,
      })
    },
  }

  const handleInputChange = ({ target: { value } }) => {
    const isInputValueValid = value.length >= ONBOARDING_INPUT_MIN_LENGTH

    setDiet({ value, isValid: isInputValueValid })
    setAnswer(isInputValueValid ? value : '')
  }

  const handleNextClick = () => handleContinue(answer)

  const handleSubmit = (event) => {
    event.preventDefault()
    if (!diet.isValid) return

    handleNextClick()
  }

  return (
    <AdaptiveContainer title="onboarding.diet.title">
      {DIET2_OPTIONS.map(({ detailed, text, label }) => (
        <AnswerAccordion
          {...optionProps}
          key={label}
          hiddenText={t(detailed)}
          value={label}
          checked={label === answer}
        >
          {t(text)}
        </AnswerAccordion>
      ))}
      <form onSubmit={handleSubmit}>
        <S.Input
          value={diet.value}
          labelName={t`onboarding.motivation.other`}
          onChange={handleInputChange}
        />
      </form>
      <StickyButtonContainer centerMode>
        <Button
          onClick={handleNextClick}
          disabled={!answer}
          theme={buttonTheme.LUVLY_SECONDARY}
          maxWidth="488px"
        >
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
    </AdaptiveContainer>
  )
}
