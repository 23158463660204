import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import i18n from 'i18next'

import { CookieConsent } from 'components/CookieConsent'
import { Header } from 'components/Header'
import { HeaderMinimalistic } from 'components/HeaderMinimalistic'
import { InContextControlPanel } from 'components/InContextControlPanel'
import { LiveChat } from 'components/LiveChat'
import { Theme } from 'components/Theme'

import {
  selectCurrentVariantCohort,
  selectLanguage,
} from 'root-redux/selects/common'
import {
  selectUserConfigData,
  selectUserCountryCode,
} from 'root-redux/selects/user'

import { useABTest } from 'hooks/useABTest'
import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useAmplitudeInitialization } from 'hooks/useAmplitudeInitialization'
import { useChatBotEmail } from 'hooks/useChatBotEmail'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useCookieConsentAnswer } from 'hooks/useCookieConsentAnswer'
import { useDynamicDiscount } from 'hooks/useDynamicDiscount'
import { useEventLoggerInitialization } from 'hooks/useEventLoggerInitialization'
import { useGAConversions } from 'hooks/useGAConversions'
import { useGAPageTracker } from 'hooks/useGAPageTracker'
import { useGetPageInfo } from 'hooks/useGetPageInfo'
import { growthBook, useGrowthBook } from 'hooks/useGrowthBook'
import { useLiveChatAvailability } from 'hooks/useLiveChatAvailability'
import { useScrollToTop } from 'hooks/useScrollToTop'
import { useTestEnvQueryParameter } from 'hooks/useTestEnvQueryParameter'
import { useUtmTags } from 'hooks/useUtmTags'

import { getCurrentEnv } from 'helpers/getCurrentEnv'

import { OpenSansGlobalStyles } from 'common-styles'
import {
  Cohort,
  CurrentEnvironment,
  GROWTHBOOK_EXPERIMENT,
} from 'root-constants'

import { RouteList } from '../../RouteList'
import { ProgressBar } from './components'
import { HEADERS } from './constants'

export const LayoutProvider: React.FC = () => {
  const cohort = useSelector(selectCurrentVariantCohort)
  const language = useSelector(selectLanguage)
  const userCountryCode = useSelector(selectUserCountryCode)
  const config = useSelector(selectUserConfigData)

  const { hasLiveChat } = useLiveChatAvailability()
  const { isEUUser } = useCookieConsentAnswer()
  const { hasHeader, applyOpenSans } = useGetPageInfo()
  const { search, pathname } = useLocation()
  const cohortToUse = useCohortToUse()
  const { isLuvlyIntroFastCohort } = useActiveCohortIdentifier()
  const { isIntroFastCoralThemeActive } = useABTest()

  const isTestEnvironment = getCurrentEnv() !== CurrentEnvironment.PROD

  const userCookieConsentAnswer = useMemo(() => {
    return config?.cookieConsent
  }, [config])

  const isCookieConsentAvailable = useMemo(() => {
    return !userCookieConsentAnswer && isEUUser
  }, [isEUUser, userCookieConsentAnswer])

  useEffect(() => {
    i18n.changeLanguage(language)
  }, [language])

  useUtmTags()
  useDynamicDiscount()
  useGrowthBook()
  useGAPageTracker()
  useGAConversions()
  useEventLoggerInitialization(cohort as Cohort)
  useAmplitudeInitialization(cohort as Cohort)
  useScrollToTop()
  useTestEnvQueryParameter()
  useChatBotEmail()

  const isCoachingUpsellTestActive = growthBook.getFeatureValue(
    GROWTHBOOK_EXPERIMENT.LV_1274,
    false,
  )

  useEffect(() => {
    growthBook.setAttributes({
      ...growthBook.getAttributes(),
      url: `${pathname}${search}`,
      country: userCountryCode,
      cohort,
      language,
      coachUpsell: cohort === Cohort.LUVLY_REF || isCoachingUpsellTestActive,
    })
  }, [
    cohort,
    isCoachingUpsellTestActive,
    language,
    pathname,
    search,
    userCountryCode,
  ])

  const CurrentHeader = useMemo(() => {
    if (isLuvlyIntroFastCohort && isIntroFastCoralThemeActive) {
      return HeaderMinimalistic
    }

    return HEADERS[cohortToUse] || Header
  }, [cohortToUse, isIntroFastCoralThemeActive, isLuvlyIntroFastCohort])

  return (
    <Theme>
      {isTestEnvironment && <InContextControlPanel />}
      {hasHeader && <CurrentHeader />}
      <ProgressBar />
      {hasLiveChat && <LiveChat />}
      {isCookieConsentAvailable && <CookieConsent />}
      {applyOpenSans && <OpenSansGlobalStyles />}
      <RouteList />
    </Theme>
  )
}
