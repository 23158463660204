import styled from 'styled-components'

import leftArrow from 'assets/images/swiper-left-arrow.svg'
import rightArrow from 'assets/images/swiper-right-arrow.svg'

import { Color } from 'root-constants'

export const StyledLongPaywallAppGallery = {
  Container: styled.div`
    background-color: ${Color.WHITE};
    margin-bottom: 20px;

    & .swiper-wrapper {
      padding-bottom: 56px;
    }
    & span.swiper-pagination-bullet {
      background-color: ${Color.CREAMY};
      opacity: 1;
    }

    & span.swiper-pagination-bullet.swiper-pagination-bullet-active {
      background-color: ${Color.PRIMARY};
    }

    .swiper-button-prev,
    .swiper-button-next {
      height: 32px;
      width: 32px;
      border-radius: 22px;
      background-color: ${Color.WHITE};
      box-shadow: 0 1px 7px 0 #d1d6eb;
      top: unset;
      bottom: 4px;
      margin: 0;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
      z-index: 11;
    }

    .swiper-button-prev {
      left: 102px;
      &:after {
        content: url(${leftArrow});
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        line-height: 0;
      }
    }
    .swiper-button-next {
      right: 102px;
      &:after {
        content: url(${rightArrow});
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        line-height: 0;
      }
    }
  `,
  Subtitle: styled.h2`
    text-align: center;
    color: ${({ theme }) => theme.appGalleryTitle.color};
    font-size: ${({ theme }) => theme.appGalleryTitle.fontSize};
    font-weight: 700;
    line-height: ${({ theme }) => theme.appGalleryTitle.lineHeight};
    margin: 0 auto 20px;
  `,
  ImageWrapper: styled.div``,
  SlideImage: styled.img`
    display: block;
    width: 100%;
    height: 100%;
  `,
}
