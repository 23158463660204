import styled, { css } from 'styled-components'

import { TLocaleStylesProps } from 'modules/purchase/types'

import { Color } from 'root-constants'

export const StyledLongPaywallShortUserProfile = {
  Wrapper: styled.div`
    margin-bottom: 24px;
  `,
  Title: styled.h2`
    text-align: center;
    color: ${Color.DARK};
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 16px;
  `,
  ContentWrapper: styled.div<TLocaleStylesProps>`
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 1px;
      background-color: #ccdce5;

      ${({ isEnLocaleStyle }) =>
        isEnLocaleStyle
          ? css`
              height: 36px;
              right: 178px;
            `
          : css`
              height: 100%;
              left: 50%;
              transform: translateX(-50%);
            `}
    }
  `,
  ContentImage: styled.img`
    width: 38px;
    height: 38px;
    margin-right: 8px;
  `,
  ContentBlock: styled.div<TLocaleStylesProps>`
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ isEnLocaleStyle }) =>
      isEnLocaleStyle
        ? css`
            flex-direction: row;

            &:last-of-type {
              width: 176px;
            }
          `
        : css`
            flex-direction: column;
            text-align: center;
            width: calc(50% - 4px);
          `}
  `,
  LevelContentWrapper: styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  `,
  ContentSubtitle: styled.p<TLocaleStylesProps>`
    color: ${Color.LIGHT};
    font-weight: 400;
    line-height: 16px;

    ${({ isEnLocaleStyle }) =>
      isEnLocaleStyle
        ? css`
            font-size: 14px;
          `
        : css`
            font-size: 12px;
            margin-top: 8px;
          `};
  `,
  UserProfileText: styled.p<TLocaleStylesProps>`
    color: ${({ theme }) => theme.userProfile.subtitle.color};
    font-weight: ${({ theme }) => theme.userProfile.subtitle.fontWeight};

    ${({ isEnLocaleStyle }) =>
      isEnLocaleStyle
        ? css`
            font-size: 18px;
            line-height: 20px;
          `
        : css`
            font-size: 16px;
            line-height: 24px;
          `};
  `,
}
