import React, { useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import lottie from 'lottie-web/build/player/lottie_light'

import {
  selectAnswers,
  selectLanguage,
  selectUserGoal,
} from 'root-redux/selects/common'

import { useABTest } from 'hooks/useABTest'
import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useCohortToUse } from 'hooks/useCohortToUse'
import { useIsCurrentRoute } from 'hooks/useIsCurrentRoute'

import { PageId } from 'page-constants'
import {
  CDN_FOLDER_LINK,
  Cohort,
  ES_IMAGES_COHORTS,
  Gender,
  I18N_CONTEXT_COHORT_MAP,
  Locale,
  UserGoal,
} from 'root-constants'
import { SKIN_COLOR_OPTION_VALUES } from 'shared-options'

import {
  LevelIndication,
  StyledLongPaywallUserTarget as S,
} from './LongPaywallUserTarget.styles'
import {
  ANIMATION_DOUBLE_CHIN_FEMALE,
  ANIMATION_DOUBLE_CHIN_FEMALE_2,
  ANIMATION_DOUBLE_CHIN_MALE,
  ANIMATION_DOUBLE_CHIN_MALE_2,
  ANIMATION_PATH,
  LEVEL_INDICATION_BLANK,
  USER_GOAL_CONFIG_MAP,
} from './constants'

export const LongPaywallUserTarget: React.FC = () => {
  const { t } = useTranslation()
  const userGoal = useSelector(selectUserGoal)
  const language = useSelector(selectLanguage)
  const userAnswers = useSelector(selectAnswers)

  const {
    isLuvlyChinCohort,
    isLuvlyGeneralCohort,
    isSeniorCohort,
    isLuvlyIntroFastCohort,
    isLuvlyPrintableCohort,
  } = useActiveCohortIdentifier()
  const { isIntroFastCoralThemeActive } = useABTest()
  const cohortToUse = useCohortToUse()

  const animationContainerRef = useRef<HTMLDivElement | null>(null)

  const isCancelOfferRoute = useIsCurrentRoute(
    PageId.PURCHASE_INTRO_CANCEL_OFFER,
  )

  const animationPath = useMemo(() => {
    const isMale = userAnswers?.[PageId.GENDER] === Gender.MALE
    const isDarkSkin =
      userAnswers?.[PageId.IDENTIFY_SKIN_COLOR] ===
      SKIN_COLOR_OPTION_VALUES.BROWN_OR_BLACK_BROWN

    if (isLuvlyChinCohort && isDarkSkin) {
      return `${CDN_FOLDER_LINK}${
        isMale ? ANIMATION_DOUBLE_CHIN_MALE_2 : ANIMATION_DOUBLE_CHIN_FEMALE_2
      }.json`
    }

    if (isLuvlyChinCohort) {
      return `${CDN_FOLDER_LINK}${
        isMale ? ANIMATION_DOUBLE_CHIN_MALE : ANIMATION_DOUBLE_CHIN_FEMALE
      }.json`
    }

    const isIntroFastCoralFlow =
      isLuvlyIntroFastCohort && isIntroFastCoralThemeActive

    if ((isLuvlyGeneralCohort || isIntroFastCoralFlow) && isMale) {
      return `${CDN_FOLDER_LINK}${ANIMATION_PATH}_male.json`
    }

    const isEsLocale = language === Locale.SPANISH
    const isEsTargeted =
      isEsLocale &&
      [
        ...ES_IMAGES_COHORTS,
        Cohort.LUVLY_INTRO_SALE,
        Cohort.LUVLY_INTRO_FAST,
      ].includes(cohortToUse)

    if (isEsTargeted) {
      return `${CDN_FOLDER_LINK}${ANIMATION_PATH}_${language}.json`
    }

    return `${CDN_FOLDER_LINK}${ANIMATION_PATH}_en.json`
  }, [
    userAnswers,
    isLuvlyChinCohort,
    isLuvlyIntroFastCohort,
    isIntroFastCoralThemeActive,
    isLuvlyGeneralCohort,
    language,
    cohortToUse,
  ])

  useEffect(() => {
    if (animationContainerRef && animationContainerRef.current) {
      lottie.loadAnimation({
        container: animationContainerRef.current,
        path: animationPath,
        loop: true,
      })
    }

    return () => lottie.destroy()
  }, [animationPath])

  const userGoalConfig =
    USER_GOAL_CONFIG_MAP[userGoal || UserGoal.WRINKLE_FREE_SKIN]

  const renderGoal = (label: 'now' | 'goal') => {
    if (isLuvlyPrintableCohort) {
      return (
        <Trans
          i18nKey={
            label === 'now' ? userGoalConfig.goalText : userGoalConfig.goalText2
          }
          context={I18N_CONTEXT_COHORT_MAP[cohortToUse]}
        />
      )
    }

    if (isLuvlyChinCohort) {
      return t('purchase8.facialCondition.doubleChinGoal')
    }

    return (
      <Trans
        i18nKey={userGoalConfig.goalText}
        context={I18N_CONTEXT_COHORT_MAP[cohortToUse]}
      />
    )
  }

  const { currentIndicator, goalIndicator } = useMemo(() => {
    const isReversedIndication =
      isLuvlyPrintableCohort && userGoal !== UserGoal.BOTH_OPTIONS

    if (isReversedIndication) {
      return {
        currentIndicator: userGoalConfig.goalLevelIndicator,
        goalIndicator: userGoalConfig.currentLevelIndicator,
      }
    }

    return {
      currentIndicator: userGoalConfig.currentLevelIndicator,
      goalIndicator: userGoalConfig.goalLevelIndicator,
    }
  }, [isLuvlyPrintableCohort, userGoal, userGoalConfig])

  return (
    <S.Root>
      {!isSeniorCohort ? (
        <S.AnimationContainer
          data-testid="paywall-target-img"
          ref={animationContainerRef}
          isCancelOfferRoute={isCancelOfferRoute}
        />
      ) : (
        <S.ImageContainer />
      )}
      <S.Wrapper>
        <S.FacialCondition>
          <S.Subtitle>{t('purchase7.facialCondition.nowLabel')}</S.Subtitle>
          <S.LevelContent>
            <S.UserGoal>{renderGoal('now')}</S.UserGoal>
            <S.LevelIndicationWrapper>
              {LEVEL_INDICATION_BLANK.map((item) => (
                <S.LevelIndicationUnit
                  key={item}
                  type={
                    currentIndicator >= item
                      ? LevelIndication.CURRENT
                      : LevelIndication.DEFAULT
                  }
                />
              ))}
            </S.LevelIndicationWrapper>
          </S.LevelContent>
        </S.FacialCondition>
        <S.FacialCondition>
          <S.Subtitle>{t('purchase7.facialCondition.goalLabel')}</S.Subtitle>
          <S.LevelContent>
            <S.UserGoal>{renderGoal('goal')}</S.UserGoal>
            <S.LevelIndicationWrapper>
              {LEVEL_INDICATION_BLANK.map((item) => (
                <S.LevelIndicationUnit
                  key={item}
                  type={
                    goalIndicator >= item
                      ? LevelIndication.GOAL
                      : LevelIndication.DEFAULT
                  }
                />
              ))}
            </S.LevelIndicationWrapper>
          </S.LevelContent>
        </S.FacialCondition>
      </S.Wrapper>
    </S.Root>
  )
}
