import briannaImg from 'assets/images/brianna-before-after-min.jpg'
import jenniferImg from 'assets/images/jennifer-before-after-min.jpg'
import lindaImg from 'assets/images/linda-before-after-min.jpg'
import man1Img from 'assets/images/man-1-before-after.png'
import man2Img from 'assets/images/man-2-before-after.png'
import man3Img from 'assets/images/man-3-before-after.png'
import man4Img from 'assets/images/man-4-before-after.png'
import melissaImg from 'assets/images/melissa-before-after-min.png'

export const USERS_GALLERY = [
  {
    name: 'purchase7.userGoalsGallery.user1',
    img: melissaImg,
  },
  {
    name: 'purchase7.userGoalsGallery.user2',
    img: briannaImg,
  },
  {
    name: 'purchase7.userGoalsGallery.user3',
    img: lindaImg,
  },
  {
    name: 'purchase7.userGoalsGallery.user4',
    img: jenniferImg,
  },
]

export const DOUBLE_CHIN_FEMALE_GALLERY = [
  {
    name: 'purchase7.userGoalsGallery.user2',
    img: briannaImg,
  },
  {
    name: 'purchase7.userGoalsGallery.user4',
    img: jenniferImg,
  },
  {
    name: 'purchase7.userGoalsGallery.user3',
    img: lindaImg,
  },
  {
    name: 'purchase7.userGoalsGallery.user1',
    img: melissaImg,
  },
]

export const DOUBLE_CHIN_MALE_GALLERY = [
  {
    name: 'purchase7.userGoalsGallery.man1',
    img: man1Img,
  },
  {
    name: 'purchase7.userGoalsGallery.man2',
    img: man2Img,
  },
  {
    name: 'purchase7.userGoalsGallery.man3',
    img: man3Img,
  },
  {
    name: 'purchase7.userGoalsGallery.man4',
    img: man4Img,
  },
]
