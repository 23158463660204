import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import {
  AnswerAccordion,
  Button,
  answerAccordionTheme,
  buttonTheme,
} from 'storybook-ui'

import { Container } from 'components/Container'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import stars from 'assets/images/coral-stars.png'

import { DoubleChinTitle, SandyBackgroundGlobalStyles } from 'common-styles'
import { OptionType } from 'root-constants'

import { DOUBLE_CHIN_CAUSE_ANSWERS, QUESTION } from './constants'

export const DoubleChinCause: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const [answer, setAnswer] = useState<string>('')

  const handleContinue = useNextStep({
    pageId,
    question: QUESTION,
    nextPagePath,
  })

  const optionProps = {
    name: pageId,
    type: OptionType.RADIO,
    theme: answerAccordionTheme.LUVLY,
    iconSrc: stars,
    iconWidth: '24px',
    iconHeight: '24px',
    spacingBetweenIconAndContent: '12px',
    fontWeight: '600',
    onChange: (value: string) => {
      setAnswer(value)
    },
  }

  const handleNextClick = () => handleContinue(answer)

  return (
    <div>
      <Container>
        <DoubleChinTitle>
          <Trans
            i18nKey="onboarding.doubleChinCause.title"
            components={[<strong />]}
          />
        </DoubleChinTitle>
        {DOUBLE_CHIN_CAUSE_ANSWERS.map(({ detailed, text, label }) => (
          <AnswerAccordion
            {...optionProps}
            key={label}
            value={label}
            hiddenText={t(detailed)}
          >
            {t(text)}
          </AnswerAccordion>
        ))}
        <SandyBackgroundGlobalStyles />
      </Container>
      <StickyButtonContainer centerMode>
        <Button
          onClick={handleNextClick}
          disabled={!answer}
          theme={buttonTheme.LUVLY_SECONDARY}
        >
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
    </div>
  )
}
