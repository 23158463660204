import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledUpsellGuashaReviews = {
  Card: styled.div`
    background: ${Color.WHITE};
    border-radius: 16px;
    padding: 12px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0px 8px 20px 0px rgba(209, 214, 235, 0.5);

    &:not(:last-of-type) {
      margin-bottom: 12px;
    }
  `,
  CardRating: styled.img`
    width: 88px;
    margin-bottom: 8px;
  `,
  CustomerReview: styled.p`
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.reviews.review.color};
    line-height: 18px;
    margin-bottom: 8px;
  `,
  EmojiContainer: styled.span`
    margin-left: 2px;
    vertical-align: middle;
  `,
  Emoji: styled.img`
    display: inline-block;
    width: 16px;
    height: 16px;
  `,
  Reviewer: styled.p`
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: ${({ theme }) => theme.reviews.author.color};
  `,
}
