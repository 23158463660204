import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  AnswerWithCheckbox,
  Button,
  answerWithCheckboxTheme,
  buttonTheme,
} from 'storybook-ui'

import { Container } from 'components/Container'
import { StickyButtonContainer } from 'components/StickyButtonContainer'

import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'
import { useNextStep } from 'hooks/useNextStep'

import { TPageProps } from 'models/common.model'

import checkIcon from 'assets/images/check-white.svg'

import { DoubleChinTitle, SandyBackgroundGlobalStyles } from 'common-styles'
import { PageId } from 'page-constants'
import { Color, OptionType } from 'root-constants'
import { PROBLEMATIC_AREAS } from 'shared-options'

import { StyledProblematicAreasV2 as S } from './ProblematicAreasV2.styles'
import { AnimatedFace } from './components'
import {
  GENERAL_PROBLEMATIC_AREAS_OPTIONS,
  OPTION_VALUES,
  QUESTION,
  WELLHUB_PROBLEMATIC_AREAS_OPTIONS,
} from './constants'

export const ProblematicAreasV2: React.FC<TPageProps> = ({
  pageId,
  nextPagePath,
}) => {
  const { t } = useTranslation()
  const { isLuvlyWellhubCohort } = useActiveCohortIdentifier()
  const [answers, setAnswers] = useState<{ value: string; label: string }[]>([])

  const handleContinue = useNextStep({
    pageId: PageId.PROBLEMATIC_AREA,
    question: QUESTION,
    nextPagePath,
  })

  const handleNextClick = () => {
    const uniqueAnswers = answers.map((answer) => answer.label)
    handleContinue(answers, uniqueAnswers)
  }

  const optionProps = {
    name: pageId,
    type: OptionType.CHECKBOX,
    iconSrc: checkIcon,
    margin: '0 0 8px 0',
    iconSize: '12px',
    theme: answerWithCheckboxTheme.LUVLY,
    onChange: (value, isChecked) => {
      if (isChecked) {
        const userAnswer = PROBLEMATIC_AREAS.find(
          (item) => item.label === value,
        )
        userAnswer && setAnswers([...answers, userAnswer])
      } else {
        setAnswers(answers.filter((item) => item.label !== value))
      }
    },
  }

  const getIconCurrentColor = (value: OPTION_VALUES) => {
    return answers.some((answer) => answer.label === value)
      ? Color.CORAL
      : 'transparent'
  }

  const problematicAreasOptions = isLuvlyWellhubCohort
    ? WELLHUB_PROBLEMATIC_AREAS_OPTIONS
    : GENERAL_PROBLEMATIC_AREAS_OPTIONS

  return (
    <S.Root>
      <Container>
        <DoubleChinTitle>
          {t('onboarding.problematicAreas.question')}
        </DoubleChinTitle>
        <S.SplittedOptionsContainer>
          {problematicAreasOptions.map(({ value, text }) => {
            return (
              <AnswerWithCheckbox {...optionProps} value={value} key={value}>
                {t(text)}
              </AnswerWithCheckbox>
            )
          })}
        </S.SplittedOptionsContainer>

        <AnimatedFace getIconCurrentColor={getIconCurrentColor} />
      </Container>
      <SandyBackgroundGlobalStyles />
      <StickyButtonContainer centerMode>
        <Button
          onClick={handleNextClick}
          disabled={!answers.length}
          theme={buttonTheme.LUVLY_SECONDARY}
        >
          {t('actions.next')}
        </Button>
      </StickyButtonContainer>
    </S.Root>
  )
}
