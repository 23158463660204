import heartFaceShape from 'assets/images/heart-face-shape.png'
import ovalFaceShape from 'assets/images/oval-face-shape.png'
import rectangleFaceShape from 'assets/images/rectangle-face-shape.png'
import roundFaceShape from 'assets/images/round-face-shape.png'
import squareFaceShape from 'assets/images/square-face-shape.png'
import triangleFaceShape from 'assets/images/triangle-face-shape.png'

export const QUESTION = 'What is your face shape?'

export const OPTION_VALUES = {
  OVAL: 'oval',
  SQUARE: 'square',
  ROUND: 'round',
  TRIANGLE: 'triangle',
  HEART: 'heart',
  RECTANGLE: 'rectangle',
}

export const OPTIONS = [
  {
    image: ovalFaceShape,
    value: OPTION_VALUES.OVAL,
  },
  {
    image: squareFaceShape,
    value: OPTION_VALUES.SQUARE,
  },
  {
    image: roundFaceShape,
    value: OPTION_VALUES.ROUND,
  },
  {
    image: triangleFaceShape,
    value: OPTION_VALUES.TRIANGLE,
  },
  {
    image: heartFaceShape,
    value: OPTION_VALUES.HEART,
  },
  {
    image: rectangleFaceShape,
    value: OPTION_VALUES.RECTANGLE,
  },
]
