import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledDoubleChinReduction = {
  GraphContainer: styled.div`
    aspect-ratio: 343/211;
    border-radius: 16px;
    margin-bottom: 16px;
    padding: 12px;
    border-radius: 16px;
    border: 1px solid ${Color.GRAY_LIGHT};
    background-color: ${Color.WHITE};
    position: relative;
  `,
  GraphTitle: styled.p`
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    position: absolute;
    top: 19px;
    left: 32px;
  `,
  Graph: styled.img`
    width: 100%;
    height: auto;
  `,
  GraphNote: styled.p`
    color: ${Color.GRAY};
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    position: absolute;
    bottom: 12px;
    left: 32px;
  `,
  NoteContainer: styled.div`
    background-color: #fff3d8;
    border-radius: 16px;
    padding: 16px 8px;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${Color.BLUE_DARK};
  `,
  Cup: styled.img`
    width: 60px;
    height: 60px;
    margin-bottom: 6px;
  `,
  NoteTitle: styled.p`
    font-weight: 700;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-bottom: 16px;
  `,
  NoteDescription: styled.p`
    font-weight: 400;
    margin-bottom: 16px;
  `,
}
