import { Cohort } from 'root-constants'

import {
  CANCEL_OFFER_ADDITIONAL_DISCOUNT,
  DEFAULT_DYNAMIC_DISCOUNT,
  INTRO_SALES_COHORTS_DISCOUNT_MAP,
} from '../constants'

export const getDynamicCohortDiscountPercentage = ({
  cohort,
  isCancelOfferRoute,
  dynamicDiscountAmount,
}: {
  cohort: Cohort
  isCancelOfferRoute: boolean
  dynamicDiscountAmount?: number
}) => {
  const baseDiscountPercentage =
    dynamicDiscountAmount ||
    INTRO_SALES_COHORTS_DISCOUNT_MAP[cohort] ||
    DEFAULT_DYNAMIC_DISCOUNT
  const appliedDiscount = isCancelOfferRoute
    ? baseDiscountPercentage + CANCEL_OFFER_ADDITIONAL_DISCOUNT
    : baseDiscountPercentage

  return appliedDiscount
}
