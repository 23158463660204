import styled, { css } from 'styled-components'

import { Cohort, Color } from 'root-constants'

type TProps = {
  cohort: Cohort
}

const defaultWrapperStyles = css`
  max-width: 360px;
  margin: 0 auto 32px;
  padding: 0 16px;
`

const chatCohortWrapperStyles = css`
  position: absolute;
  padding: 0;
  width: 100%;
  left: 0;
  top: 50px;
`

const coralThemeWrapperStyles = css`
  max-width: var(--container-width);
  margin: 0 auto 32px;
  padding: 0 8px;
  position: relative;
  z-index: 3;
`

const giftCohortWrapperStyles = css`
  ${coralThemeWrapperStyles};
  margin: 0 auto 24px;
`

const WRAPPER_STYLES_MAP = {
  [Cohort.LUVLY_CHAT]: chatCohortWrapperStyles,
  [Cohort.LUVLY_CHIN]: coralThemeWrapperStyles,
  [Cohort.LUVLY_GIFT]: giftCohortWrapperStyles,
  [Cohort.LUVLY_GENERAL]: coralThemeWrapperStyles,
}

const progressValueStyles = css`
  background: #e6e9f6;
  border-radius: 5px;

  &::-webkit-progress-bar {
    background: #e6e9f6;
    border-radius: 5px;
  }

  &::-webkit-progress-value {
    background: ${Color.PRIMARY};
    transition: all 0.2s ease-out;
    border-radius: 5px;
  }
  &::-moz-progress-bar {
    background: ${Color.PRIMARY};
    transition: all 0.2s ease-out;
    border-radius: 5px;
  }
  &::-ms-fill {
    background: ${Color.PRIMARY};
    transition: all 0.2s ease-out;
    border-radius: 5px;
  }
`

const defaultStyles = css`
  height: 10px;
  ${progressValueStyles};
`

const chatCohortStyles = css`
  height: 2px;
  ${progressValueStyles};
`

const coralThemeStyles = css`
  height: 4px;
  background-color: ${Color.CREAMY};
  border-radius: 18px;

  &::-webkit-progress-bar {
    background: ${Color.CREAMY};
    border-radius: 18px;
  }

  &::-webkit-progress-value {
    background: ${Color.CORAL};
    transition: all 0.2s ease-out;
    border-radius: 18px;
  }
  &::-moz-progress-bar {
    background: ${Color.CORAL};
    transition: all 0.2s ease-out;
    border-radius: 18px;
  }
  &::-ms-fill {
    background: ${Color.CORAL};
    transition: all 0.2s ease-out;
    border-radius: 18px;
  }
`

const PROGRESS_STYLES_MAP = {
  [Cohort.LUVLY_CHAT]: chatCohortStyles,
  [Cohort.LUVLY_CHIN]: coralThemeStyles,
  [Cohort.LUVLY_GIFT]: coralThemeStyles,
  [Cohort.LUVLY_GENERAL]: coralThemeStyles,
}

export const StyledLinearProgressBar = {
  Wrapper: styled.div<TProps>`
    ${({ cohort }) => WRAPPER_STYLES_MAP[cohort] || defaultWrapperStyles}
  `,
  Progress: styled.progress<TProps>`
    appearance: none;
    border: none;
    display: block;
    width: 100%;
    ${({ cohort }) => PROGRESS_STYLES_MAP[cohort] || defaultStyles}
  `,
}
