import React from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { TReview } from 'components/CustomerReviews/CustomerReviews'

import { StyledReviewCoral as S } from './ReviewCoral.styles'

type TReviewProps = TReview

export const ReviewCoral: React.FC<TReviewProps> = ({
  icon,
  name,
  review,
  emoji,
}) => {
  const { t } = useTranslation()

  return (
    <S.ReviewContainer>
      <S.CustomerInfoContainer>
        <S.CustomerImg src={icon} alt="customer" />
        <S.CustomerName>{t(name)}</S.CustomerName>
        <S.CustomerRecommendation>
          <Trans i18nKey="onboarding.shortProgramDescription.recommendLuvly" />
        </S.CustomerRecommendation>
      </S.CustomerInfoContainer>
      <S.CustomerReview>
        <Trans
          i18nKey={review}
          components={{
            emoji: (
              <S.EmojiContainer>
                {emoji?.map((el) => (
                  <S.Emoji key={el} src={el} alt="emoji" />
                ))}
              </S.EmojiContainer>
            ),
          }}
        />
      </S.CustomerReview>
    </S.ReviewContainer>
  )
}
