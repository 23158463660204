import styled, { css } from 'styled-components'

import bgDesktop from 'assets/images/lose-double-chin-desktop.jpg'
import bg from 'assets/images/lose-double-chin.jpg'

import { Color, MediaBreakpoint } from 'root-constants'

type TProgressProps = {
  value: number
}

const progressValueStyles = css`
  background-color: ${Color.CORAL};
  transition: all 0.2s linear;
`

export const StyledLoadingIntro = {
  Root: styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: calc(100vh - 48px);
    height: calc(100dvh - 48px);
    background: url(${bg}) no-repeat center top 40px;
    background-size: cover;

    @media (min-width: ${MediaBreakpoint.TABLET}px) {
      background: url(${bgDesktop}) no-repeat;
      background-position: top 40px center;
      background-size: cover;
    }
  `,
  Title: styled.h1`
    font-family: 'Open Sans';
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    color: ${Color.WHITE};
    text-align: center;
    margin-bottom: 24px;
  `,
  Subtitle: styled.div`
    font-family: 'Open Sans';
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 123.077% */
    letter-spacing: 0.5px;
    color: ${Color.WHITE};
    margin-bottom: 16px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    & div {
      margin-left: 8px;
      height: 16px;
      width: 16px;
      -webkit-animation: loader-1-1 4.8s linear infinite;
      animation: loader-1-1 4.8s linear infinite;
    }
    @-webkit-keyframes loader-1-1 {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }
    @keyframes loader-1-1 {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    span {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      height: 16px;
      width: 16px;
      clip: rect(0, 16px, 16px, 8px);
      -webkit-animation: loader-1-2 1.2s linear infinite;
      animation: loader-1-2 1.2s linear infinite;
    }
    @-webkit-keyframes loader-1-2 {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(220deg);
      }
    }
    @keyframes loader-1-2 {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(220deg);
      }
    }

    span::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      height: 16px;
      width: 16px;
      clip: rect(0, 16px, 16px, 8px);
      border: 3px solid #fff;
      border-radius: 50%;
      -webkit-animation: loader-1-3 1.2s linear infinite;
      animation: loader-1-3 1.2s linear infinite;
    }
    @-webkit-keyframes loader-1-3 {
      0% {
        -webkit-transform: rotate(-140deg);
      }
      50% {
        -webkit-transform: rotate(-160deg);
      }
      100% {
        -webkit-transform: rotate(140deg);
      }
    }
    @keyframes loader-1-3 {
      0% {
        transform: rotate(-140deg);
      }
      50% {
        transform: rotate(-160deg);
      }
      100% {
        transform: rotate(140deg);
      }
    }
  `,
  Loader: styled.div`
    position: relative;

    & span {
      font-family: 'Open Sans';
      font-size: 17px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  `,
  Progress: styled.progress<TProgressProps>`
    appearance: none;
    border: none;
    display: block;
    width: 100%;
    height: 40px;
    padding: 4px;
    background: ${Color.WHITE};
    border-radius: 32px;
    margin-bottom: 24px;

    &::-webkit-progress-bar {
      background: ${Color.WHITE};
      border-radius: 32px;
      overflow: hidden;
    }
    &::-webkit-progress-value {
      ${progressValueStyles}
    }
    &::-moz-progress-bar {
      ${progressValueStyles}
    }
    &::-ms-fill {
      ${progressValueStyles}
    }
  `,
}
