import { IRequestOptions, IResponseResult } from 'models/api.model'
import {
  ICustomerInfoRaw,
  IGetUserSubscriptionsInfoRaw,
} from 'models/commonApi.model'

import { ApiService } from 'services/api.service'

import { APP_NAME, RequestMethod } from 'root-constants'

export class CommonApi {
  private api: ApiService

  constructor(api: ApiService) {
    this.api = api
  }

  getUserSubscriptionsInfo(
    token: string,
  ): Promise<IResponseResult<IGetUserSubscriptionsInfoRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
      params: {
        token,
        app_name: APP_NAME,
      },
    }

    return this.api.makeRequest<IGetUserSubscriptionsInfoRaw>(
      `v1/subscriptions`,
      options,
    )
  }

  getCustomerInfo(token: string): Promise<IResponseResult<ICustomerInfoRaw>> {
    const options: IRequestOptions = {
      method: RequestMethod.GET,
      params: {
        token,
        app_name: APP_NAME,
      },
    }

    return this.api.makeRequest<ICustomerInfoRaw>(`v1/customers`, options)
  }
}
