import styled, { css, keyframes } from 'styled-components'

import { SvgImage } from 'components/SvgImage'

import { Color } from 'root-constants'

const spinKeyframes = keyframes`
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
`

type TSlideTextProps = {
  isCoralTheme?: boolean
}

export const StyledPreparePlan = {
  Root: styled.div<TSlideTextProps>`
    ${({ isCoralTheme }) =>
      isCoralTheme &&
      css`
        border-top: 1px solid rgba(0, 0, 0, 0.25);
      `}
  `,
  SpinnerContainer: styled.div`
    margin: 25px 0 23px;
    text-align: center;
  `,
  Text: styled.p<TSlideTextProps>`
    margin-top: -10px;
    font-weight: normal;

    ${({ isCoralTheme }) =>
      isCoralTheme
        ? css`
            color: ${Color.GRAY};
            font-size: 15px;
            line-height: 20px;
          `
        : css`
            font-size: 14px;
            line-height: 19px;
          `}
  `,
  Circle: styled(SvgImage)`
    animation-name: ${spinKeyframes};
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  `,
  ImageContainer: styled.div`
    aspect-ratio: 320/320;
  `,
  Image: styled.img`
    max-width: 100%;
  `,
  SlideText: styled.p<TSlideTextProps>`
    margin-top: 30px;
    line-height: 24px;

    text-align: center;
    ${({ isCoralTheme }) =>
      isCoralTheme
        ? css`
            color: ${Color.BLUE_DARK};
            font-size: 18px;
            font-weight: 700;
            padding-bottom: 40px;
          `
        : css`
            font-size: 20px;
            font-weight: 500;
            padding: 0 15px 40px;
          `}
  `,
}
