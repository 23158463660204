import React, { useMemo } from 'react'

import { ThemeProvider } from 'styled-components'

import { useABTest } from 'hooks/useABTest'
import { useActiveCohortIdentifier } from 'hooks/useActiveCohortIdentifier'

import {
  CHECKOUT_CORAL_THEME,
  CHECKOUT_DEFAULT_THEME,
} from '../../styles/themes/checkout'
import { CORAL_THEME, DEFAULT_THEME } from '../../styles/themes/common'
import {
  PURCHASE_VARIANT_7_THEME,
  PURCHASE_VARIANT_8_THEME,
} from '../../styles/themes/paywall'

type TProps = {
  children: React.ReactNode
}

export const Theme: React.FC<TProps> = ({ children }) => {
  const { isCoralThemeCohort, isLuvlyIntroFastCohort } =
    useActiveCohortIdentifier()
  const { isIntroFastCoralThemeActive } = useABTest()

  const currentTheme = useMemo(() => {
    if (
      isCoralThemeCohort ||
      (isLuvlyIntroFastCohort && isIntroFastCoralThemeActive)
    ) {
      return {
        ...CORAL_THEME,
        ...PURCHASE_VARIANT_8_THEME,
        checkout: CHECKOUT_CORAL_THEME,
      }
    }

    return {
      ...DEFAULT_THEME,
      ...PURCHASE_VARIANT_7_THEME,
      checkout: CHECKOUT_DEFAULT_THEME,
    }
  }, [isCoralThemeCohort, isIntroFastCoralThemeActive, isLuvlyIntroFastCohort])

  return <ThemeProvider theme={currentTheme}>{children}</ThemeProvider>
}
