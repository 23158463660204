export const QUESTION = 'Have you heard about Face Yoga before?'

export const OPTION_VALUES = {
  NOT_SURE: 'not_sure',
  KNOW_FEW_THINGS: 'know_few_things',
  KNOW_EVERYTHING: 'know_everything',
}

export const HAVE_YOU_HEARD_ANSWERS = [
  {
    text: 'onboarding.heardAbout.notSure',
    label: OPTION_VALUES.NOT_SURE,
  },
  {
    text: 'onboarding.heardAbout.knowFewThings',
    label: OPTION_VALUES.KNOW_FEW_THINGS,
  },
  {
    text: 'onboarding.heardAbout.knowEverything',
    label: OPTION_VALUES.KNOW_EVERYTHING,
  },
]
