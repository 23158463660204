import styled from 'styled-components'

import { Color } from 'root-constants'

export const StyledEmailConsent = {
  Title: styled.h1`
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 48px;
    text-align: center;
    & strong {
      background: linear-gradient(272deg, #d46eab 19.48%, #e4909f 54.27%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  `,
  TitleV2: styled.h1`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: ${Color.BLUE_DARK};
    margin-bottom: 48px;
    text-align: center;

    & strong {
      color: ${Color.CORAL};
    }
  `,
  Skip: styled.button`
    all: unset;
    display: block;
    margin: 0 auto;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: ${Color.LIGHT};
    text-align: center;
    cursor: pointer;
  `,
}
